import React, { Fragment, useEffect, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { FaChevronCircleRight, FaBell } from "react-icons/fa";
import ApplicationService from "../../services/ApplicationService";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import NotificationService from "../../services/NotificationService";
import { number } from "yup";

const Notifications = (props) => {
  const history = useHistory();
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  let userConnectInfo = localStorage.getItem("userInfo");
  let userConnected = JSON.parse(userConnectInfo);

  const [testNotifications, setTestNotifications] = useState([]);

  useEffect(() => {
    const eventSource = new EventSource(
      `${process.env.REACT_APP_API_URL}/api/notifications/${userConnected.id}/`
    );
    eventSource.addEventListener("notification", (event) => {
      // console.log(event.data)
      try {
        const newNotification = JSON.parse(event.data);
        setTestNotifications(newNotification);
        // console.log(newNotification)
      } catch (error) {
        console.error("Error parsing JSON:", error);
        if (error.response.status === 401) {
          // Redirect to the login page
          history.push('/login');
        }    
      }
    });

    return () => {
      eventSource.close();
    };
  }, []);

  // console.log(testNotifications)

  return (
    <>
      {(testNotifications?.normalReview?.length > 0 ) &&
      (testNotifications?.normalReview[0]?.dataFirstReview.length > 0 ||
        testNotifications?.normalReview[0]?.dataSiteVisite.length > 0 ||
        // testNotifications.normalReview[1]?.cHEADecionData.length > 0 ||
        testNotifications.normalReview[2]?.appealDataGot.length > 0
        ) ? (
        <Menu as="div" className="relative inline-block text-left">
          <div>
            <Menu.Button
              className="
                 inline-flex 
                 w-full 
                 "
            >
              <div className="p-0">
                <strong
                  className="
                       relative 
                       inline-flex 
                       items-center  
                       px-2.5 py-2.5 text-xs font-medium"
                >
                  <span
                    className={`absolute 
                         -top-2 -right-2 h-5 w-5 
                         rounded-full 
                         ${
                           (testNotifications.normalReview[0]?.dataFirstReview
                             ?.length ?? 0) +
                             (testNotifications.normalReview[0]?.dataSiteVisite
                               ?.length ?? 0) +
                             
                             (testNotifications.normalReview[1]?.cHEADecionData
                               ?.length ?? 0) +
                             (testNotifications.normalReview[2]?.appealDataGot
                               ?.length ?? 0) >
                           0
                             ? "bg-red-600 "
                             : "bg-gray-600 "
                         }
                         flex justify-center items-center items`}
                  >
                    <span className="text-white">
                      {(testNotifications?.normalReview[0]?.dataFirstReview
                        ?.length ?? 0) +
                        (testNotifications?.normalReview[0]?.dataSiteVisite
                          ?.length ?? 0) +
                        // (testNotifications.normalReview[1]?.cHEADecionData
                        //   ?.length ?? 0) +
                        (testNotifications.normalReview[2]?.appealDataGot
                          ?.length ?? 0)}
                    </span>
                  </span>
                  <span className="ml-1.5 text-gray-700">
                    {" "}
                    <FaBell size={20} />{" "}
                  </span>
                </strong>
              </div>
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                {testNotifications.normalReview[0]?.dataFirstReview?.length > 0
                  ? testNotifications.normalReview[0]?.dataFirstReview?.map(
                      (notif, index) => (
                        <Menu.Item key={`K${index}`}>
                          {({ active }) => (
                            <Link
                              key={index}
                              to={{
                                pathname:
                                  "/ExpertReview/" + notif.id_expertReview,
                                data: {
                                  currentApplication: notif.application,
                                  typeOperation: "Add First Review ",
                                  notifID: notif.id_application,
                                },
                              }}
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm"
                              )}
                            >
                              <span
                                className={`${"text-red-600 block w-full  bg-red-100  px-2 py-1`"}`}
                              > 
                                {`${"Review of: "}`} {notif.application.title_program_application}
                              </span>
                            </Link>
                          )}
                        </Menu.Item>
                      )
                    )
                  : ""}

                {testNotifications.normalReview[0]?.dataSiteVisite?.length > 0
                  ? testNotifications.normalReview[0]?.dataSiteVisite.map(
                      (notif, index) => (
                        <Menu.Item key={`K${index}`}>
                          {({ active }) => (
                            <Link
                              key={index}
                              to={{
                                pathname:`/siteVisite/${notif.application.id_application}${Number(notif.status) === 0 ? '' : '/edit'}`,
 
                                data: {
                                  currentApplication: notif.application, 
                                  typeOperation: "Add Site visit ",
                                  id_siteVisitAffectationExpert: notif.id_siteVisitAffectationExpert,
                                  dataCollected: notif.dataColectedSiteVisite,
                                  constantProvided: notif.constantProvided,
                                },
                              }}
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm"
                              )}
                            >
                              <span
                                className={`text-red-900  bg-red-200 block w-full px-2 py-1`}
                              >
                                {` Site visit:`}
                                {notif.application.title_program_application}
                              </span>
                            </Link>
                          )}
                        </Menu.Item>
                      )
                    )
                  : ""}
{/* 
                {testNotifications.normalReview[1]?.cHEADecionData?.length > 0
                  ? testNotifications.normalReview[1]?.cHEADecionData.map(
                      (notif, index) => (
                        <Menu.Item key={`KL${index}`}>
                          {({ active }) => (
                            <Link
                              key={index}
                              to={{
                                pathname:
                                  "/cheaReport/" +
                                  notif.id_CHEADecisionAffectationExpert,
                                state: {
                                  application:
                                    notif.cHEADecionDataGot[0],
                                  typeOperation: "AddCHEADecisionReport",
                                  notifID:
                                    notif.id_CHEADecisionAffectationExpert,
                                    dateSubmission:notif.dateSubmission,
                                },
                              }}
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm"
                              )}
                            >
                              <span
                                className={`text-green-900 bg-green-200 block w-full px-2 py-1`}
                              >
                                {` CHEA Decision:`}
                                {
                                  notif.cHEADecionDataGot[0].applicationData
                                    .title_program_application
                                }
                              </span>
                            </Link>
                          )}
                        </Menu.Item>
                      )
                    )
                  : ""} */}
                {testNotifications.normalReview[2]?.appealDataGot?.length > 0
                  ? testNotifications.normalReview[2]?.appealDataGot.map(
                      (notif, index) => (
                        <Menu.Item key={`KL${index}`}>
                          {({ active }) => (
                            <Link
                              key={index}
                              to={{
                                pathname:
                                  "/appealFeedBackExpert/" +
                                  notif.id_appealAffectationExpert +
                                  "/view",
                                state: {
                                  currentApplication: notif.appealDataGot[0],
                                  typeOperation: "ReviewExpert",
                                  notifID:
                                    notif.id_appealAffectationExpert,
                                  dateSubmission:notif.dateSubmission,
                                },
                              }}
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm"
                              )}
                            >
                              <span
                                className={`bg-gray-700 text-white block w-full px-2 py-1`}
                              >
                                {` Appeal Report:`}
                                {
                                  notif.appealDataGot[0]
                                  .applicationData.title_program_application
                                }
                              </span>
                            </Link>
                          )}
                        </Menu.Item>
                      )
                    )
                  : ""}
{/* 
                {testNotifications?.appealReview.length > 0
                  ? testNotifications?.appealReview.map((notif, index) => (
                      <Menu.Item key={`K${index}`}>
                        {({ active }) => (
                          <Link
                            key={index}
                            to={{
                              pathname:
                                "/appealAddExpert/" +
                                notif.appealExpertID +
                                "/view",
                              state: {
                                currentApplication: notif,
                                typeOperation: "AppealReview",
                              },
                            }}
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block px-4 py-2 text-sm"
                            )}
                          >
                            <span className={`text-yellow-750`}>
                              {` Appeal Review:`}
                              {
                                notif.CHEADecision.title_program_application
                                  .title
                              }
                            </span>
                          </Link>
                        )}
                      </Menu.Item>
                    ))
                  : ""} */}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      ) : (
        ""
      )}
    </>
  );
};

export default Notifications;
