import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom"; 
import { connect } from "react-redux";
import Loading from "../../eventsHandler/Loading";
import LevelService from "../../../services/LevelService"; 

const LevelAdd = (props) => {
  let editing = false;
  let initialdesignation_level = ""; 

  const history = useHistory(); 

  
  if (props.location.state && props.location.state.currentUser) {
    editing = true;
    initialdesignation_level = props.location.state.currentUser.designation_level; 
  }


  let access = localStorage.getItem("access");

  const [buttonDisabled, setButtonDisabled] = useState(false);

  const [designation_level, setdesignation_level] = useState(
    initialdesignation_level
  ); 
  let univData = JSON.parse(localStorage.getItem("universityData"));
  // console.log("LevelData: ", univData);

  const saveUser = () => {
    setButtonDisabled(true);
    const newLevel = new FormData();
    newLevel.append("designation_level", designation_level); 

    console.log(newLevel);
    if (!editing) {
      // addiing

      LevelService.createlevel(
        newLevel,
        localStorage.getItem("access")
      )
        .then((response) => {
          let data = {
            status: 1,
            title: "Okay",
            message: "Level added successFully ...",
          };
          history.push("/level/list", { data: data });
        })
        .catch((error) => {
          console.log("Carmel Error: ");
          let data = {
            status: 0,
            title: "Error",
            message: "An error happened ...",
          };
          history.push("/level/list", { data: data });
        });
    } else {
      // edit user
      console.log(newLevel);
      LevelService.updatelevel(
        props.location.state.currentUser.id_level,
        newLevel,
        localStorage.getItem("access")
      )
        .then((response) => {
          let data = {
            status: 1,
            title: "Okay",
            message: "Level edit successFully ...",
          };
          history.push("/level/list", { data: data });
        })
        .catch((e) => {
          let data = {
            status: 0,
            title: "Error",
            message: "An Error happened !",
          };
          history.push("/level/list", { data: data });
        });
    }
    setButtonDisabled(false);
  };
 
  const [isValideName, setIsValideName] = useState(true);
 
   
  const handleInputChangeName = (e) => {
    const name = e.target.value;
    setdesignation_level(name);
    const nameRegex = /^[a-zA-Z-0-9 -]+$/;
    setIsValideName(nameRegex.test(name));
  };
 
  return (
    <div>
      <div className="w-full p-8">
        {/* {buttonDisabled && <Loading />} */}

        <div className="w-full px-3 mb-4">
          <label
            className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-2"
            htmlFor="grid-password"
          >
            Designation 
          <span className="text-red-600">*</span>
          </label>
          <input
            className="appearance-none block w-full dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            id="grid-password"
            type="text"
            value={designation_level}
            onChange={(event) => handleInputChangeName(event)}
            placeholder="university name"
          />
          {!isValideName && <p style={{ color: "red" }}>Enter a valid name.</p>}
        </div>
       </div>

      <div className="text-center p-5">
        <button
          variant="info"
          className="dark:bg-[var(--color-dark-buttonBackground)]  bg-[var(--color-light-buttonBackground)] dark:text-[var(--color-dark-fontButton)] text-[var(--color-light-fontButton)]  w-[130px]  py-3 rounded-md font-medium"
          onClick={() => {
            saveUser();
          }}
        >
          {" "}
          {editing ? "Edit" : "Add"} Level
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  userConnected: state.auth.user,
});

export default connect(mapStateToProps)(LevelAdd);
