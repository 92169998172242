import React, { useState } from "react";

const containerStyle = {
  display: "flex",
  justifyContent: "center", // Center horizontally
  alignItems: "center", // Center vertically
  height: "80%", // Make the container take up the full height
};

const checkboxStyle = {
  width: "22px", // Adjust the width as needed
  height: "22px",
};

const InputCheckboxNA = (props) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    const newValue = event.target.value;
    setIsChecked(!isChecked); // Toggle the checked state
    const value = props.value; // Get the value of the checkbox
    if (!isChecked) {
    props.onChangeNumber(event.target.name,newValue); 
    }else{
      props.onChangeNumber(event.target.name,0); 
    } 
    // You can perform any actions with the value here
  };

  return (
    <div style={containerStyle}>
      <input
        style={checkboxStyle}
        type="checkbox"
        name={`${props.name}`}
        value={`${props.value}`}
        id={`${props.name}`}
        checked={isChecked}
        onChange={handleCheckboxChange}
      />{" "}
    </div>
  );
};

export default InputCheckboxNA;
