import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import UserService from "../../../services/UserService";
import { connect } from "react-redux";
import FooterLoggedIn from "../../general/FooterLoggedIn";
import { signup } from "../../actions/auth";
import { bindActionCreators } from "redux";
import { userRegSchemasValidation } from "../../alerts/Validations";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const UserRegister = ({ signup, userConnected, dataProps }) => {
  const [errorsGot, setErrorsGot] = useState("");
  const [successGot, setSuccessGot] = useState("");
  const [unversityInput, setUnversityInput] = useState("");
  const [expertInput, setExpertInput] = useState("hidden");
  const [thematic, setThematic] = useState([]);
  const [universityData, setUniversityData] = useState([]);
  const [errorsMessages, setErrorsMessages] = useState();
  const history = useHistory();
  const [flexSwitchCheckDefault, setFlexSwitchCheckDefault] = useState(
    "University registering"
  );
  const [statusCheckboxSelect, setStatusCheckboxSelect] = useState(false);

  const onSubmit = (values, { resetForm }) => {
    console.log("Status: ", statusCheckboxSelect);
    let newEmail = "";
    if (statusCheckboxSelect === "1") {
      newEmail = values.emailpersonal;

      let convertThematic = values.thematic;
      UserService.createUser({
        name: values.name,
        email: newEmail,
        university: values.university,
        is_staff : 1,
        university: values.university,
        password: values.password,
        re_password: values.re_password,
      })
        .then((response) => {
          console.log("OK");
          setErrorsGot("");
          console.log("Oyaaa ");
          setSuccessGot(
            "Congratulation, You have to wait the admin will activate your account withIn 24h!"
          );

          resetForm();

          setTimeout(() => (window.location.href = "/"), 6000);
        })
        .catch((error) => {
          let messageGot = JSON.parse(error.request.response);
          if (messageGot.email.toString()) {
            // evalynetwesiga@gmail.com
            alert("Email: " + messageGot.email.toString());
          }
        });
    } else {
      newEmail = values.emailuniversity;
      if (isGoodEmail(newEmail)) {
        if (isDataSelect(values.university)) {
          UserService.createUser({
            name: values.name,
            email: newEmail,
            university: values.university,
            thematicArea: "-",
            is_staff : 0,
            password: values.password,
            re_password: values.re_password,
          })
            .then((response) => {
              console.log("OK");
              setErrorsGot("");
              setSuccessGot(
                "Congratulation, You have to wait the admin will activate your account withIn 24h!"
              );

              resetForm();

              setTimeout(() => (window.location.href = "/"), 6000);
            })
            .catch((error) => {
              let messageGot = JSON.parse(error.request.response);
              if (error.response.status === 401) {
                // Redirect to the login page
                history.push('/login');
              }    
              if (messageGot.email.toString()) {
                // evalynetwesiga@gmail.com
                alert("Email: " + messageGot.email.toString());
              }
            });
        } else {
          console.log("Wrong select please");
        }
      }
    }

    console.log("NEw email: ");
  };

  useEffect(() => {
    UserService.getUniversity()
      .then((response) => {
        console.log("response");
        console.log(response.data);
        setUniversityData(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 401) {
          // Redirect to the login page
          history.push('/login');
        }    
      });
  }, []);

  useEffect(() => {
    // getThematic
    let url = `${process.env.REACT_APP_API_URL}/api/thematicArea/`;
    console.log(url);
    UserService.getThematic(url)
      .then((response) => {
        console.log("response");
        console.log(response.data);
        setThematic(response.data);
      })
      .catch((errors) => {
        console.log("errors");
        console.log(errors);
        if (errors.response.status === 401) {
          // Redirect to the login page
          history.push('/login');
        }    
      });
  }, []);

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      name: "",
      emailpersonal: "",
      emailuniversity: "",
      university: "",
      thematic: "",
      password: "",
      re_password: "",
    },

    validationSchema: userRegSchemasValidation,
    onSubmit,
  });

  const changeStatus = (status) => {
    setStatusCheckboxSelect(status.target.value);
    if (status.target.value === "1") {
      setFlexSwitchCheckDefault("Expert registering");
      setUnversityInput("hidden");
      setExpertInput("");
      setStatusCheckboxSelect(status.target.value);
    } else {
      setFlexSwitchCheckDefault("University registering");
      setUnversityInput("");
      setExpertInput("hidden");
      setStatusCheckboxSelect(status.target.value);
    }
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const isGoodEmail = (email) => {
    if (validateEmail(email)) {
      if (
        /(aol|gmail|yahoo|hotmail)\.com$/.test(email) ||
        /(aol|gmail|yahoo|hotmail)\.co$/.test(email) ||
        /(aol|gmail|yahoo|hotmail)\.fr$/.test(email)
      ) {
        console.log(
          " valid email, but not for this site.  No free service emails!"
        );
        setErrorsMessages("Valid Email but not allowed for universities ...");
        return false;
      } else {
        console.log("good gmail...");
        setErrorsMessages("");
        return true;
      }
    } else {
      console.log(" not good email");
      setErrorsMessages(
        "Not email, please make sure you provide a corrected email"
      );
      return false;
    }
  };

  const isDataSelect = (dataSelected) => {
    console.log(dataSelected);
    if (dataSelected) {
      setErrorsMessages("");
      return true;
    } else {
      setErrorsMessages("Please select a value");
      return false;
    }
  };

  // Filter the university options based on the search query

  const options = [
    { label: "Grapes 🍇", value: "grapes" },
    { label: "Mango 🥭", value: "mango" },
    { label: "Strawberry 🍓", value: "strawberry" },
  ];

  const [selected, setSelected] = useState([]);

  console.log(errors);

  return (
    <div>
      <div className="w-full p-8">
        <Link to={"/"}>
          <button className="mb-5 space-x-2 items-center px-3 py-2 bg-blue-500 hover:bg-blue-800 rounded-md drop-shadow-md">
            <span className="text-white">Home</span>
          </button>
        </Link>
        {errorsGot ? (
          <p className="capitalize text-red-500 text-sm italic">{errorsGot}</p>
        ) : (
          ""
        )}
        {errorsMessages ? (
          <p className="capitalize text-red-500 text-sm italic">
            {errorsMessages}
          </p>
        ) : (
          ""
        )}
        {successGot ? (
          <p className="capitalize text-green-500 text-sm italic">
            {successGot}
          </p>
        ) : (
          ""
        )}
        <form onSubmit={handleSubmit} autoComplete="off">
          <div className="w-full px-3 mb-4">
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2"
              htmlFor="grid-password"
            >
              Full Name
            </label>
            <input
              className={`
                                ${
                                  errors.name && touched.name
                                    ? "input-error"
                                    : ""
                                }
                                appearance-none 
                                block 
                                w-full 
                                dark:bg-[var(--color-dark-backgroundMain)] 
                                bg-[var(--color-light-backgroundMain)] 
                                dark:text-[var(--color-dark-font)] 
                                text-[var(--color-light-font)] 
                                border 
                                border-gray-200 
                                ounded 
                                py-3 
                                px-4 
                                mb-3 
                                leading-tight 
                                focus:outline-none
                                focus:bg-white 
                                focus:border-gray-500`}
              id="name"
              type="text"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Name and Last name"
            />
            {errors.name && touched.name ? (
              <p className="text-red-500 text-sm italic">{errors.name}</p>
            ) : (
              ""
            )}
          </div>

          <div className="w-full px-3 mb-4">
            <div className="" onChange={(e) => changeStatus(e)}>
              <div className="mb-[0.125rem] mr-4 inline-block min-h-[1.5rem] pl-[1.5rem]">
                <input
                  className="relative 
                      float-left 
                      -ml-[1.5rem] 
                      mr-1 mt-0.5 
                      h-5 w-5  
                      rounded-full 
                      border-2 
                      border-solid 
                      border-blue-300 
                      before:pointer-events-none 
                      before:absolute 
                      before:h-4 
                      before:w-4 
                      before:scale-0 
                      before:rounded-full 
                      before:bg-transparent 
                      before:opacity-0 
                      before:shadow-[0px_0px_0px_13px_transparent] 
                      before:content-[''] 
                      after:absolute after:z-[1] 
                      after:block after:h-4 
                      after:w-4 after:rounded-full 
                      after:content-[''] 
                      checked:border-primary 
                      checked:before:opacity-[0.16] 
                      checked:after:absolute 
                      checked:after:left-1/2 
                      checked:after:top-1/2 
                      checked:after:h-[0.625rem] 
                      checked:after:w-[0.625rem] 
                      checked:after:rounded-full 
                      checked:after:border-primary 
                      checked:after:bg-primary 
                      checked:after:content-[''] 
                      checked:after:[transform:translate(-50%,-50%)] 
                      hover:cursor-pointer hover:before:opacity-[0.04] 
                      hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] 
                      focus:shadow-none 
                      focus:outline-none 
                      focus:ring-0 
                      focus:before:scale-100 
                      focus:before:opacity-[0.12] 
                      focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] 
                      focus:before:transition-[box-shadow_0.2s,transform_0.2s] 
                      checked:focus:border-primary 
                      checked:focus:before:scale-100 
                      checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] 
                      checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] 
                      dark:border-neutral-600 
                      dark:checked:border-primary 
                      dark:checked:after:border-primary 
                      dark:checked:after:bg-primary 
                      dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] 
                      dark:checked:focus:border-primary 
                      dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio1"
                  value={"0"}
                  checked={statusCheckboxSelect === "0"}
                />
                <label
                  className="mt-px inline-block pl-[0.15rem] hover:cursor-pointer"
                  for="inlineRadio1"
                >
                  University registering
                </label>
              </div>

              <div className="mb-[0.125rem] mr-4 inline-block min-h-[1.5rem] pl-[1.5rem]">
                <input
                  className="relative 
                  float-left 
                  -ml-[1.5rem] 
                  mr-1 mt-0.5 
                  h-5 w-5  
                  rounded-full 
                  border-2 
                  border-solid 
                  border-blue-300 
                  before:pointer-events-none 
                  before:absolute 
                  before:h-4 
                  before:w-4 
                  before:scale-0 
                  before:rounded-full 
                  before:bg-transparent 
                  before:opacity-0 
                  before:shadow-[0px_0px_0px_13px_transparent] 
                  before:content-[''] 
                  after:absolute after:z-[1] 
                  after:block after:h-4 
                  after:w-4 after:rounded-full 
                  after:content-[''] 
                  checked:border-primary 
                  checked:before:opacity-[0.16] 
                  checked:after:absolute 
                  checked:after:left-1/2 
                  checked:after:top-1/2 
                  checked:after:h-[0.625rem] 
                  checked:after:w-[0.625rem] 
                  checked:after:rounded-full 
                  checked:after:border-primary 
                  checked:after:bg-primary 
                  checked:after:content-[''] 
                  checked:after:[transform:translate(-50%,-50%)] 
                  hover:cursor-pointer hover:before:opacity-[0.04] 
                  hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] 
                  focus:shadow-none 
                  focus:outline-none 
                  focus:ring-0 
                  focus:before:scale-100 
                  focus:before:opacity-[0.12] 
                  focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] 
                  focus:before:transition-[box-shadow_0.2s,transform_0.2s] 
                  checked:focus:border-primary 
                  checked:focus:before:scale-100 
                  checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] 
                  checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] 
                  dark:border-blue-600 
                  dark:checked:border-primary 
                  dark:checked:after:border-primary 
                  dark:checked:after:bg-primary 
                  dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] 
                  dark:checked:focus:border-primary 
                  dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio2"
                  value={"1"}
                  checked={statusCheckboxSelect === "1"}
                />
                <label
                  className="mt-px inline-block pl-[0.15rem] hover:cursor-pointer"
                  for="inlineRadio2"
                >
                  Expert registering
                </label>
              </div>
            </div>
          </div>

          <div className={`flex ${unversityInput}`}>
            <div className="w-full px-3 mb-4">
              <label
                className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2"
                htmlFor="grid-password"
              >
                University E-mail
              </label>
              <input
                className={`
                                  ${
                                    errors.emailuniversity &&
                                    touched.emailuniversity
                                      ? "input-error"
                                      : ""
                                  }
                                  appearance-none 
                                  block w-full 
                                  dark:bg-[var(--color-dark-backgroundMain)] 
                                  bg-[var(--color-light-backgroundMain)] 
                                  dark:text-[var(--color-dark-font)] 
                                  text-[var(--color-light-font)] 
                                  border 
                                  border-gray-200 
                                  rounded py-3 px-4 
                                  mb-3 
                                  leading-tight 
                                  focus:outline-none 
                                  focus:bg-white 
                                  focus:border-gray-500"`}
                id="emailuniversity"
                type="email"
                autoComplete="off"
                value={values.emailuniversity}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="University E-mail"
              />
              {errors.emailuniversity && touched.emailuniversity ? (
                <p className="text-red-500 text-sm italic">
                  {errors.emailuniversity}
                </p>
              ) : (
                ""
              )}
            </div>

            <div className="w-full px-3 mb-4">
              <label
                className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2"
                htmlFor="grid-password"
              >
                Select University
              </label>
              <select
                className={`${
                  errors.university && touched.university ? "input-error" : ""
                }
                                appearance-none 
                                block w-full 
                                dark:bg-[var(--color-dark-backgroundMain)] 
                                bg-[var(--color-light-backgroundMain)] 
                                dark:text-[var(--color-dark-font)] 
                                text-[var(--color-light-font)] 
                                border 
                                border-gray-200 
                                rounded py-3 px-4 
                                mb-3 
                                leading-tight 
                                focus:outline-none 
                                focus:bg-white 
                                focus:border-gray-500"`}
                id="university"
                type="text"
                autoComplete="off"
                value={values.university}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Select University"
              >
                <option value="" disabled selected="True">
                  Select university please
                </option>
                {universityData.map((univ) => (
                  <option value={`${univ.id}`}>{univ.university_name}</option>
                ))}
              </select>
            </div>
          </div>

          <div className={`flex ${expertInput}`}>
            <div className="w-full px-3 mb-4">
              <label
                className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2"
                htmlFor="grid-password"
              >
                E-mail
              </label>
              <input
                className={`
                                 ${
                                   errors.emailpersonal && touched.emailpersonal
                                     ? "input-error"
                                     : ""
                                 }
                                appearance-none 
                                block w-full 
                                dark:bg-[var(--color-dark-backgroundMain)] 
                                bg-[var(--color-light-backgroundMain)] 
                                dark:text-[var(--color-dark-font)] 
                                text-[var(--color-light-font)] 
                                border 
                                border-gray-200 
                                rounded py-3 px-4 
                                mb-3 
                                leading-tight 
                                focus:outline-none 
                                focus:bg-white 
                                focus:border-gray-500"`}
                id="emailpersonal"
                type="email"
                autoComplete="off"
                value={values.emailpersonal}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Personal E-mail"
              />
              {errors.emailpersonal && touched.emailpersonal ? (
                <p className="text-red-500 text-sm italic">
                  {errors.emailpersonal}
                </p>
              ) : (
                ""
              )}
            </div>

            <div className="w-full px-3 mb-4">
              <label
                className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2"
                htmlFor="grid-password"
              >
                Select University
              </label>
              <select
                className={`${
                  errors.university && touched.university ? "input-error" : ""
                }
                                appearance-none 
                                block w-full 
                                dark:bg-[var(--color-dark-backgroundMain)] 
                                bg-[var(--color-light-backgroundMain)] 
                                dark:text-[var(--color-dark-font)] 
                                text-[var(--color-light-font)] 
                                border 
                                border-gray-200 
                                rounded py-3 px-4 
                                mb-3 
                                leading-tight 
                                focus:outline-none 
                                focus:bg-white 
                                focus:border-gray-500"`}
                id="university"
                type="text"
                autoComplete="off"
                value={values.university}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Select University"
              >
                <option value="" disabled selected="True">
                  Select university please
                </option>
                {universityData.map((univ) => (
                  <option value={`${univ.id}`}>{univ.university_name}</option>
                ))}
              </select>
            </div>
          </div>

          <div className="w-full px-3 mb-4">
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2"
              htmlFor="grid-password"
            >
              Password
            </label>
            <input
              className={`
                                 ${
                                   errors.password && touched.password
                                     ? "input-error"
                                     : ""
                                 }
                                appearance-none 
                                block w-full 
                                dark:bg-[var(--color-dark-backgroundMain)] 
                                bg-[var(--color-light-backgroundMain)] 
                                dark:text-[var(--color-dark-font)] 
                                text-[var(--color-light-font)] 
                                border 
                                border-gray-200 
                                rounded py-3 px-4 
                                mb-3 
                                leading-tight 
                                focus:outline-none 
                                focus:bg-white 
                                focus:border-gray-500"`}
              id="password"
              type="password"
              autoComplete="off"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Password"
            />
            {errors.password && touched.password ? (
              <p className="text-red-500 text-sm italic">{errors.password}</p>
            ) : (
              ""
            )}
          </div>

          <div className="w-full px-3 mb-4">
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2"
              htmlFor="grid-password"
            >
              Confirm Password
            </label>
            <input
              className={`
                                 ${
                                   errors.re_password && touched.re_password
                                     ? "input-error"
                                     : ""
                                 }
                                appearance-none 
                                block w-full 
                                dark:bg-[var(--color-dark-backgroundMain)] 
                                bg-[var(--color-light-backgroundMain)] 
                                dark:text-[var(--color-dark-font)] 
                                text-[var(--color-light-font)] 
                                border 
                                border-gray-200 
                                rounded py-3 px-4 
                                mb-3 
                                leading-tight 
                                focus:outline-none 
                                focus:bg-white 
                                focus:border-gray-500"`}
              id="re_password"
              type="password"
              autoComplete="off"
              value={values.re_password}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Confirm your Password"
            />
            {errors.re_password && touched.re_password ? (
              <p className="text-red-500 text-sm italic">
                {errors.re_password}
              </p>
            ) : (
              ""
            )}
          </div>

          <div className="text-center p-5">
            <button
              variant="info"
              type="submit"
              className="dark:bg-[var(--color-dark-buttonBackground)]  bg-[var(--color-light-buttonBackground)] dark:text-[var(--color-dark-fontButton)] text-[var(--color-light-fontButton)]  w-[100px]  py-3 rounded-md font-medium"
              // disabled={isSubmitting}
            >
              {" "}
              Register{" "}
            </button>
          </div>
        </form>
      </div>

      <FooterLoggedIn />
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  isAuthenticated: state.auth.isAuthenticated,
  userConnected: state.auth.user,
  actions: props.actions,
});

function mapDispatchToProps(dispatch, props) {
  return {
    signup: bindActionCreators(signup, dispatch),
    dataProps: props,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserRegister);
