import InputNumber1 from "../smallComponents/InputNumber1";
import InputNumber2 from "../smallComponents/InputNumber2";
import InputRadio from "../smallComponents/InputRadio";
import InputRadioFor5Input from "../smallComponents/InputRadioFor5Input";

export const preliminaryAssessment = (dataSent, numberSubmission) => ({
  point1: [
    {
      bigHeader:
        "PART I – PRELIMIMARY ASSESSMENT (REVIEW OF PROGRAMME DOCUMENT)",
      content: [
        {
          subData: [
            {
              number: `1`,
              subtitleStandard: `Accreditation status of the programme`,
              subcontent: [
                {
                  subtitleNorm: `Programme should have been accredited by the National Commissions/Councils or by any National accreditation body`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `1.1.1 Evidence of accreditation`,
                      column5: `Accreditation
                letter, listing of
                programme on
                accreditation
                body website`,
                      column6: ( 
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.evidenceOfAccreditation}
                            ViewDisable={"disabled"}
                            name={"evidenceOfAccreditation"}
                          />
                        </>
                      ),
                      column7: `Availability of
                Accreditation is score
                of (5) and not available
                (0).`,
                    },
                    {
                      column4: `1.1.2
          Evidence
          of recent
          audit of the
          programme`,
                      column5: `Programme
          audit report`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.evidenceOfOfRecentAuditOfTheProgram}
                            ViewDisable={"disabled"}
                            name={"evidenceOfOfRecentAuditOfTheProgram"}
                          />
                        </>
                      ),
                      column7: `The score is either 5
          for available and 0 for
          not no evidenc`,
                    },
                  ], // data content tag
                },
              ], // sub content
            },
          ],
        },
        // end of 1
        {
          subData: [
            {
              number: `2`,
              subtitleStandard: `Curriculum
          development
          Process`,
              subcontent: [
                // 2.1
                {
                  subtitleNorm: `2.1. Stakeholder
                involvement
                in curriculum
                development
                process`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `2.1.1. Needs
                    assessment/
                    market survey
                    report`,
                      column5: `Evidence
                    provided in
                    form of an
                    assessment/
                    market survey
                    report`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.needAssessmentMarketSutveyReport}
                            ViewDisable={"disabled"}
                            name={"needAssessmentMarketSutveyReport"}
                          />
                        </>
                      ),
                      column7: `Needs assessment
                    done score of (5) and
                    no report/not done
                    score of (0)`,
                    },
                    {
                      column4: `2.1.2.
                    Evidence of
                    participation
                    (i.e. Signed
                    Attendance
                    list and
                    approved
                    minutes)`,
                      column5: `Signed
                    attendance lists
                    and approved
                    minutes availed`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.evidennceOfParticipation}
                            ViewDisable={"disabled"}
                            name={"evidennceOfParticipation"}
                          />
                        </>
                      ),
                      column7: `Score of (5) for
                    available and (0) for
                    not being available`,
                    },
                    {
                      column4: `2.1.3
                      Confirmation
                      letter from key
                      stakeholders
                      on their
                      participation`,
                      column5: `Evidence of
                      confirmation
                      letter from key
                      stakeholders`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.confirmaitonLetterFromKeyStakeHolders}
                            ViewDisable={"disabled"}
                            name={"confirmaitonLetterFromKeyStakeHolders"}
                          />
                        </>
                      ),
                      column7: `Score of (5) for
                      available and (0) for
                      not being available`,
                    },
                    {
                      column4: `2.1.4
                      Stakeholder's`,
                      column5: `Evidence
                      stakeholder’s`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.stakeHoldersEvidence}
                            ViewDisable={"disabled"}
                            name={"stakeHoldersEvidence"}
                          />
                        </>
                      ),
                      column7: `Score of (5) for
                      available and (0) for
                      not being available`,
                    },
                    {
                      column4: `2.1.5
                      Curriculum
                      development
                      and review
                      policy and
                      guidelines`,
                      column5: `Availability of
                      the curriculum
                      development
                      and review
                      policy and
                      guidelines`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.CurriculumDevelopmentAndReviewPolicyAndGuidelines}
                            ViewDisable={"disabled"}
                            name={
                              "CurriculumDevelopmentAndReviewPolicyAndGuidelines"
                            }
                          />
                        </>
                      ),
                      column7: `Score of (5) for
                      available and (0) for
                      not being available`,
                    },
                    {
                      column4: `2.1.6
                      Evidence of
                      institutional
                      approval
                      for regional
                      accreditation`,
                      column5: `Minutes of
                      approval,
                      resolution from
                      the decision-
                      making organs
                      of the Institution`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.evidenceOfInstitutionalApprovalForRegionalAccreditation}
                            ViewDisable={"disabled"}
                            name={
                              "evidenceOfInstitutionalApprovalForRegionalAccreditation"
                            }
                          />
                        </>
                      ),
                      column7: `Score of (5) for
                      available and (0) for
                      not being available`,
                    },
                  ], // end sub content
                },

                // 2.2
                {
                  subtitleNorm: `2.2 Alignment
                of programme
                to national,
                regional,
                continental,
                or global
                development
                needs`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `2.2.1
                    Justification
                    part of the
                    programme
                    indicates
                    incorporation
                    of National,
                    regional,
                    continental,
                    or global
                    development
                    goals.`,
                      column5: `Evidence as
                    provided in the
                    programme`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.justificationPartOfTheProgrammeIndicatesIncorporationOfNationalRegion}
                            ViewDisable={"disabled"}
                            name={
                              "justificationPartOfTheProgrammeIndicatesIncorporationOfNationalRegion"
                            }
                          />
                        </>
                      ),
                      column7: `Score of (5) for
                    available and (0) for
                    not being available`,
                    },
                  ], // end sub content
                },

                // 2.3
                {
                  subtitleNorm: `2.3
                Endorsement
                of professional
                bodies (where
                applicable)`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `2.3.1
                    Evidence of
                    endorsement
                    of
                    professional
                    bodies`,
                      column5: `Endorsement
                    letters, minutes
                    etc`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.evidenceOfEndorsementOfProfessionalBodies}
                            ViewDisable={"disabled"}
                            name={"evidenceOfEndorsementOfProfessionalBodies"}
                          />
                        </>
                      ),
                      column7: `Score of (5) for
                    available and (0) for
                    not being available`,
                    },
                  ], // end sub content
                },
              ],
            },
          ],
        },
        // end of 2
        {
          subData: [
            {
              number: `3`,
              subtitleStandard: `Curriculum
            document`,
              subcontent: [
                // 3.1
                {
                  subtitleNorm: `3.1 Sufficient/
                adequate
                content of the
                curriculum`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `3.1.1
                    Coverage
                    of the
                    programme in
                    relation to the
                    award level`,
                      column5: `Sufficient
                    coverage
                    provided in the
                    programme`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.coverageOfThePRogrammeInRelationtotheAwardLevel}
                            ViewDisable={"disabled"}
                            name={
                              "coverageOfThePRogrammeInRelationtotheAwardLevel"
                            }
                          />
                        </>
                      ),
                      column7: `Score of (5) for
                    available and (0) for
                    not being available`,
                    },
                    {
                      column4: `3.1.2
                    Conformity
                    to available
                    benchmarks
                    and minimum
                    standards`,
                      column5: `Evidence of
                    conformity to
                    the benchmarks`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.conformityToAvailableBenchmarksAndMinimumStandards}
                            ViewDisable={"disabled"}
                            name={
                              "conformityToAvailableBenchmarksAndMinimumStandards"
                            }
                          />
                        </>
                      ),
                      column7: `Score of (5) for
                    available and (0) for
                    not being available`,
                    }, //end 3.1
                  ], // end sub content
                },

                // 3.2
                {
                  subtitleNorm: `3.2 Admission
                criteria`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `3.2.1
                    Admission
                    requirements
                    specific to the
                    program`,
                      column5: `Evidence of
                    admissions
                    guidelines`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.admissionRequirementsSpecificToTheProgram}
                            ViewDisable={"disabled"}
                            name={"admissionRequirementsSpecificToTheProgram"}
                          />
                        </>
                      ),
                      column7: `Score of (5) for
                    available and (0) for
                    not being available`,
                    },
                    {
                      column4: `3.2.2 Should
                    provide
                    for diverse
                    avenues of
                    entry and
                    should be
                    consistent
                    with National,
                    regional and
                    international`,
                      column5: `Evidence of
                    diversity in
                    admissions
                    provided`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.ShouldPRovideForDiverseAvenuewOfEntryAndShouldBeConsistentWithNationalRegionalAndInternational}
                            ViewDisable={"disabled"}
                            name={
                              "ShouldPRovideForDiverseAvenuewOfEntryAndShouldBeConsistentWithNationalRegionalAndInternational"
                            }
                          />
                        </>
                      ),
                      column7: `Highest level of
                    diversity is score of
                    (5) and no diversity
                    or alignment to
                    national, regional and
                    international standards
                    in (0)`,
                    },
                  ], // end sub content
                },

                // 3.3
                {
                  subtitleNorm: `3.3 Structure of
                the programme`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `3.3.1 Clear
                    structure
                    of the
                    programme
                    programme
                    provided`,
                      column5: `Structure of
                    the programme
                    provided and
                    detailed`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.clearStructureOgTheProgrammeProvided}
                            ViewDisable={"disabled"}
                            name={"clearStructureOgTheProgrammeProvided"}
                          />
                        </>
                      ),
                      column7: `Score of (5) for
                    available and (0) for
                    not being available`,
                    },
                    {
                      column4: `3.3.2 Logical
                    flow and
                    sequencing of
                    courses within
                    the entire
                    programme`,
                      column5: `Flow of courses
                    clear`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.logicalFlowSequenceOfCoursesWithinTheEntireProgramme}
                            ViewDisable={"disabled"}
                            name={
                              "logicalFlowSequenceOfCoursesWithinTheEntireProgramme"
                            }
                          />
                        </>
                      ),
                      column7: `Score of (5) for
                    available and (0) for
                    not being available`,
                    },
                    {
                      column4: `3.3.3 Courses
                    are aligned
                    to allow
                    progression
                    from one
                    semester/
                    trimester to
                    another`,
                      column5: `Aligned from
                    basic to applied`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.courseAreAlignedToAllowProgressionFromOneSemesterTrimesterToAnother}
                            ViewDisable={"disabled"}
                            name={
                              "courseAreAlignedToAllowProgressionFromOneSemesterTrimesterToAnother"
                            }
                          />
                        </>
                      ),
                      column7: `Score of (5) for
                    available and (0) for
                    not being available`,
                    },
                    {
                      column4: ` Cross-
                    cutting and
                    elective
                    courses are
                    relevant to the
                    programme`,
                      column5: `Evidence of
                    relevant cross-
                    cutting courses
                    provided`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.crossCuttingAndElectiveCoursesAreRelevantToTheProgramme}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={
                              "crossCuttingAndElectiveCoursesAreRelevantToTheProgramme"
                            }
                          />
                        </>
                      ),
                      column7: `For appropriate
                    cross-cutting electives
                    courses relevant to
                    the programme score
                    is (5) and reduced to
                    (4), (3), (2), (1) and (0)
                    depending on diversity
                    and relevancy`,
                    },
                    {
                      column4: `3.3.5 Credits/
                    weights
                    assigned
                    to courses
                    depict the
                    rigor and
                    relevance of
                    the course to
                    the program`,
                      column5: `Credits
                    indicated reflect
                    the rigor`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.creditWeightsAssignedToCoursesDepictTheRigorAndRelevanceOfTheCourseToTheProgram}
                            ViewDisable={"disabled"}
                            name={
                              "creditWeightsAssignedToCoursesDepictTheRigorAndRelevanceOfTheCourseToTheProgram"
                            }
                          />
                        </>
                      ),
                      column7: `Credits/weights well
                    structured and depict
                    the rigor and relevance
                    is at (5) and degree of
                    it varies with no rigor
                    being (0)`,
                    },
                  ], // end sub content
                },

                // 3.4
                {
                  subtitleNorm: `3.4 Learning
                Outcomes`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `3.4.1
                    Learning
                    outcomes
                    should be
                    measurable`,
                      column5: `Measurable
                    learning
                    outcomes have
                    been provided`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.learningOutComesShouldBeMeasurable}
                            ViewDisable={"disabled"}
                            name={"learningOutComesShouldBeMeasurable"}
                          />
                        </>
                      ),
                      column7: `Properly stated
                    Learning Outcome
                    is (5) with degree of
                    change to (0)`,
                    },
                    {
                      column4: `3.4.2
                    Learning
                    outcomes
                    should depict
                    the three
                    domains of
                    learning`,
                      column5: `Learning
                    outcomes
                    provided depict
                    the three
                    domains of
                    learning`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.learningOutComesShouldDepictTheThreeDomainsOfLearning}
                            ViewDisable={"disabled"}
                            name={
                              "learningOutComesShouldDepictTheThreeDomainsOfLearning"
                            }
                          />
                        </>
                      ),
                      column7: `Score of (5) for
                    available and (0) for
                    not being available`,
                    },
                    {
                      column4: `3.4.3 Learning
                    outcomes are
                    relevant to the
                    contemporary
                    world of work`,
                      column5: `Learning
                    outcomes
                    provided match
                    the aspiration
                    of the world of
                    work`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.learningOutComesAreRelevantToTheContemporaryWorlOfWork}
                            ViewDisable={"disabled"}
                            name={
                              "learningOutComesAreRelevantToTheContemporaryWorlOfWork"
                            }
                          />
                        </>
                      ),
                      column7: `Score of (5) for
                    available and (0) for
                    not being available`,
                    },
                  ], // end sub content
                }, // end of 3.4

                // 3.5
                {
                  subtitleNorm: `3.5 Programme
                consistent to
                EAQFHE`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `3.5.1
                    Qualification
                    level should
                    be consistent
                    with the
                    descriptor
                    levels in the
                    EAQFHE`,
                      column5: `Verify against
                    the level as
                    detailed in the
                    EAQFHE`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.QualificationShouldBeConsistentWithTheDescriptorLevelInTheEAQFHE}
                            ViewDisable={"disabled"}
                            name={
                              "QualificationShouldBeConsistentWithTheDescriptorLevelInTheEAQFHE"
                            }
                          />
                        </>
                      ),
                      column7: `Properly stated
                    Learning Outcome
                    is (5) with degree of
                    change to (0)`,
                    },
                  ], // end sub content
                }, // end of 3.5

                // 3.6
                {
                  subtitleNorm: `3.6 Credits/
                Hours`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `3.6.1 The
                    credit hours
                    and notional
                    hours of
                    engagement
                    of learners
                    should be
                    consistent
                    with the
                    volume of
                    learning
                    and level of
                    qualification`,
                      column5: `Credit and or
                    notion hours
                    provided`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.theCreditHoursOfENgagementOfLearnersShouldConsistentWithTheVoulumeOfLearningandLevelOfQualification}
                            ViewDisable={"disabled"}
                            name={
                              "theCreditHoursOfENgagementOfLearnersShouldConsistentWithTheVoulumeOfLearningandLevelOfQualification"
                            }
                          />
                        </>
                      ),
                      column7: `Properly stated
                    Learning Outcome
                    is (5) with degree of
                    change to (0)`,
                    },
                    {
                      column4: `3.6.2
                    Should have
                    provision
                    for credit
                    transfer and
                    transferability
                    of students`,
                      column5: `Evidence of
                    credit transfer
                    provided in the
                    programme`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.shouldHaveProvisionForCreditTransferAndTransferabilityOfStudents}
                            ViewDisable={"disabled"}
                            name={
                              "shouldHaveProvisionForCreditTransferAndTransferabilityOfStudents"
                            }
                          />
                        </>
                      ),
                      column7: `Score of (5) for
                    available and (0) for
                    not being available`,
                    },
                  ], // end sub content
                }, // end of 3.6

                // 3.7
                {
                  subtitleNorm: `3.7 Minimum
                duration`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `3.7.1 The
                    minimum
                    duration
                    for the
                    programme
                    should be
                    consistent
                    with the
                    volume of
                    learning
                    and rigor of
                    training`,
                      column5: `Minimum
                    duration
                    provided in line
                    with volume of
                    learning and
                    rigor of training`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.theMinimumDurationForTheProgramShouldBeConsistentWithTheVolumeOfLearningAndRigorOfTraining}
                            ViewDisable={"disabled"}
                            name={
                              "theMinimumDurationForTheProgramShouldBeConsistentWithTheVolumeOfLearningAndRigorOfTraining"
                            }
                          />
                        </>
                      ),
                      column7: `Properly stated
                    Learning Outcome
                    is (5) with degree of
                    change to (0)`,
                    },
                    {
                      column4: `3.7.2 The
                    minimum
                    duration
                    should be
                    in line with
                    the National
                    Policies and
                    Guidelines`,
                      column5: `Minimum
                    duration is
                    in line with
                    National
                    requirements`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.theMinimumDurationShouldBeInLineWithTheNationalPoliciesAndGuideLines}
                            ViewDisable={"disabled"}
                            name={
                              "theMinimumDurationShouldBeInLineWithTheNationalPoliciesAndGuideLines"
                            }
                          />
                        </>
                      ),
                      column7: `Score of (5) for
                    available and (0) for
                    not being available`,
                    },
                  ], // end sub content
                }, // end of 3.7

                // 3.8
                {
                  subtitleNorm: `3.8 Provision for
                RPL`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `3.8.1 Should
                    provide for
                    provision
                    of RPL in
                    admission,
                    waivers and
                    exemptions
                    to increase
                    access`,
                      column5: `RPL provided as
                    alternative for
                    admission`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.shouldProvideForProvisionOfRPLInAdmissionWaiversAndExempltionsToIncreaseAccess}
                            ViewDisable={"disabled"}
                            name={
                              "shouldProvideForProvisionOfRPLInAdmissionWaiversAndExempltionsToIncreaseAccess"
                            }
                          />
                        </>
                      ),
                      column7: `Properly stated
                    Learning Outcome
                    is (5) with degree of
                    change to (0)`,
                    },
                    {
                      column4: `3.8.2
                    Implement
                    inclusivity`,
                      column5: `Should be open
                    to everybody
                    who meet the
                    requirements`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.implementInclusivity}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={"implementInclusivity"}
                          />
                        </>
                      ),
                      column7: `Varied score from 5-0
                    depending on the level
                    of inclusivity`,
                    },
                  ], // end sub content
                }, // end of 3.8
              ],
            },
          ],
        },
        // end of 3
        {
          subData: [
            {
              number: `4`,
              subtitleStandard: `Modes of
              Teaching and
              Learning`,
              subcontent: [
                // 4.1
                {
                  subtitleNorm: `4.1 Method
                  of delivery is
                  clearly identified
                  and appropriate.`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `4.1.1
                      Appropriate
                      modes of
                      delivery
                      indicated`,
                      column5: `The programme
                      document
                      provides
                      for range of
                      approaches of
                      delivery`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.appropriateModeOfDeliveryIndicated}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={"appropriateModeOfDeliveryIndicated"}
                          />
                        </>
                      ),
                      column7: ` `,
                    },
                    {
                      column4: `4.1.2 75%
                      or above
                      alignment
                      of delivery
                      methods with
                      ELOs`,
                      column5: `Method of
                      delivery aligned
                      to ELOs in the
                      programme
                      document`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent._75ourcentOrAboveAlignmentPfDeliveryMethodsWihtElos}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={
                              "_75ourcentOrAboveAlignmentPfDeliveryMethodsWihtElos"
                            }
                          />
                        </>
                      ),
                      column7: `Alignment of 75% and
                      above score of (5),
                      65% (4), 55% (3), 45%
                      (2) and 35% (1)`,
                    },
                    {
                      column4: `4.1.3
                      Availability of
                      appropriate
                      teaching aids/
                      materials`,
                      column5: `Provision
                      of relevant
                      teaching aids/
                      materials and
                      resources in
                      the programme
                      document`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.avalabilityOfappropriateTeachingAidsMaterials}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={
                              "avalabilityOfappropriateTeachingAidsMaterials"
                            }
                          />
                        </>
                      ),
                      column7: `Sufficiency of teaching
                      aid of 75% (5), 65%
                      (4), 55% (3), 45% (2)
                      and 35% (1)`,
                    },
                  ], // end sub content
                },

                // 4.2
                {
                  subtitleNorm: `4.2 Flexibility
                  in mode of
                  learning`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `4.2.1 Options
                      provided for
                      learning.`,
                      column5: `Provision of
                      different modes
                      of learning
                      provided`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.optionProvidedForLearning}
                            ViewDisable={"disabled"}
                            name={"optionProvidedForLearning"}
                          />
                        </>
                      ),
                      column7: `Score of (5) for
                      evidence provision
                      of flexibility of modes
                      of learning provides
                      and (0) for not being
                      available`,
                    },
                  ], // end sub content
                },

                // 4.3
                {
                  subtitleNorm: `4.3 Student-
                  centered
                  approaches of
                  learning`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `4.3.1 75% of
                      the learning
                      is student-
                      centred`,
                      column5: `Count the
                      approaches as
                      provided in the
                      programme`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent._75PercentOfTheLearningIsStudentCentred}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={"_75PercentOfTheLearningIsStudentCentred"}
                          />
                        </>
                      ),
                      column7: `75% of approaches
                      student-centred (5),
                      65% (4), 55% (3), 45%
                      (2), 35% (1)`,
                    },
                    {
                      column4: `4.3.2 varied
                      strategies
                      for provision
                      of learner
                      centred
                      experiences
                      and support`,
                      column5: `Different
                      approaches
                      ranging from
                      discussion,
                      self – study,
                      problem-based
                      etc`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.variedStrategiesForPRovisionOfLearnerCentredExperiencesAndSupport}
                            ViewDisable={"disabled"}
                            name={
                              "variedStrategiesForPRovisionOfLearnerCentredExperiencesAndSupport"
                            }
                          />
                        </>
                      ),
                      column7: `Score of (5) for
                      evidence provision
                      of flexibility of modes
                      of learning provides
                      and (0) for not being
                      available`,
                    },
                    {
                      column4: `4.3.3 Tools
                      for problem-
                      based,
                      project-
                      based and
                      inquiry-based
                      learning
                      provided.`,
                      column5: `Availability of
                      tools, adequacy
                      and relevance`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.toolsForProbelmBasedPRojectBasedAndInquiryBasedLearningPRovided}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={
                              "toolsForProbelmBasedPRojectBasedAndInquiryBasedLearningPRovided"
                            }
                          />
                        </>
                      ),
                      column7: `Varied score from
                      5-0 depending on
                      availability, adequacy,
                      and relevance`,
                    },
                  ], // end sub content
                },
                // end of 4.3
                // 4.4
                {
                  subtitleNorm: `4.4 Programme
                  provides for
                  blended system`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `4.4.1
                      Evidence
                      of blended
                      methods`,
                      column5: `Programme
                      document
                      stipulates
                      for blended
                      approaches`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.evidenceOfBlendedMEthos}
                            ViewDisable={"disabled"}
                            name={"evidenceOfBlendedMEthos"}
                          />
                        </>
                      ),
                      column7: `Score of (5) for
                      available and (0) for
                      not being available`,
                    },
                    {
                      column4: `4.4.2
                      Availability of
                      appropriate
                      techniques
                      for blended
                      approach`,
                      column5: `The techniques
                      available
                      for blended
                      approach are
                      ideal`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.availabilityOfAppropriateTechniquesForBlendedApproach}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={
                              "availabilityOfAppropriateTechniquesForBlendedApproach"
                            }
                          />
                        </>
                      ),
                      column7: `Varied score from
                      5-0 depending on
                      appropriateness of
                      techniques`,
                    },
                    {
                      column4: `4.4.3
                      Where the
                      programme
                      shall be
                      taught
                      through
                      blended
                      approach, the
                      programme
                      has been
                      aligned
                      accordingly`,
                      column5: `Availability of
                      tools, adequacy
                      and relevancef taught through
                      blended, ensure
                      that it is well
                      aligned`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.whereTheProgrammeShallBeTaughtBlendedApproachTheProgrammeHasBeenAlignedAccordingly}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={
                              "whereTheProgrammeShallBeTaughtBlendedApproachTheProgrammeHasBeenAlignedAccordingly"
                            }
                          />
                        </>
                      ),
                      column7: `Varied score from 5-0
                      depending on the level
                      of alignment`,
                    },
                  ], // end sub content
                },
                // end of 4.4
              ],
            },
          ],
        },
        // end of 4
      ], // end content
    },

    //  end of big part 1 preliminary
    {
      bigHeader: "PART II – PHYSICAL VERIFICATION OF FACILITIES AND STAFFING",
      content: [
        {
          subData: [
            {
              number: `5`,
              subtitleStandard: `Academic
              Staffing`,
              subcontent: [
                // 2.1
                {
                  subtitleNorm: `5.1 Staff
                  students ration`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `5.1.1
                      Education
                      1:30-40`,
                      column5: `Establish the
                      number of
                      students on
                      the programme
                      against the
                      available total
                      number of staff`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.education13040}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={"education13040"}
                          />
                        </>
                      ),
                      column7: `Ratio of 1:30 is for
                      Science Education.
                      Score of 1:30 is (5),
                      1:40 (4), 1:50 (3), 1:60
                      (2) and 1:70 (1) for
                      undergraduate
                      Ratio of 1:10 for PGD
                      and Masters as ideal
                      and score of (5), 1:15
                      (4), 1:20 (3), 1:25 (2),
                      1:30 (1)
                      PhD ration of 1:5 ideal
                      with score of (5), 1:7
                      (4), 1:9 (3), 1:11 (2)
                      and 1:13 (1)`,
                    },
                    {
                      column4: `5.1.2 Arts and
                      Humanities
                      1:40`,
                      column5: `Establish the
                      number of
                      students on
                      the programme
                      against the
                      available total
                      number of staff`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.artAndHumanities140}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={"artAndHumanities140"}
                          />
                        </>
                      ),
                      column7: `Ratio of 1:40 for
                      arts and humanities
                      education. Score of
                      1:40 (5), 1:50 (4), 1:60
                      (3), 1:70 (2), 1:80 (1)
                      Ratio of 1:10 for PGD
                      and Masters as ideal
                      and score of (5), 1:15
                      (4), 1:20 (3), 1:25 (2),
                      1:30 (1)
                      PhD ration of 1:5 ideal
                      with score of (5), 1:7
                      (4), 1:9 (3), 1:11 (2)
                      and 1:13 (1)`,
                    },
                    {
                      column4: `5.1.3 Social
                      Sciences,
                      Journalism
                      `,
                      column5: `Establish the
                      number of
                      students on
                      the programme
                      against the
                      available total
                      number of staff`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.socialScienceJournalism}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={"socialScienceJournalism"}
                          />
                        </>
                      ),
                      column7: `Ratio of 1:40 for
                      arts and humanities
                      education. Score of
                      1:40 (5), 1:50 (4), 1:60
                      (3), 1:70 (2), 1:80 (1)
                      Ratio of 1:10 for PGD
                      and Masters as ideal
                      and score of (5), 1:15
                      (4), 1:20 (3), 1:25 (2),
                      1:30 (1)
                      PhD ration of 1:5 ideal
                      with score of (5), 1:7
                      (4), 1:9 (3), 1:11 (2)
                      and 1:13 (1)`,
                    },
                    {
                      column4: `5.1.4
                      Business,
                      Administration
                      and Law`,
                      column5: `Establish the
                      number of
                      students on
                      the programme
                      against the
                      available total
                      number of staff`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.businessAdministrationAndLaw}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={"businessAdministrationAndLaw"}
                          />
                        </>
                      ),
                      column7: `Ratio of 1:40 for
                      arts and humanities
                      education. Score of
                      1:40 (5), 1:50 (4), 1:60
                      (3), 1:70 (2), 1:80 (1)
                      Ratio of 1:10 for PGD
                      and Masters as ideal
                      and score of (5), 1:15
                      (4), 1:20 (3), 1:25 (2),
                      1:30 (1)
                      PhD ration of 1:5 ideal
                      with score of (5), 1:7
                      (4), 1:9 (3), 1:11 (2)
                      and 1:13 (1)`,
                    },
                    {
                      column4: `5.1.5 Natural
                      Sciences,
                      Mathematics
                      and Statistics`,
                      column5: `Establish the
                      number of
                      students on
                      the programme
                      against the
                      available total
                      number of staff`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.naturalSciencesMathematicsAndStatistics}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={"naturalSciencesMathematicsAndStatistics"}
                          />
                        </>
                      ),
                      column7: `Ratio of 1:40 for
                      arts and humanities
                      education. Score of
                      1:40 (5), 1:50 (4), 1:60
                      (3), 1:70 (2), 1:80 (1)
                      Ratio of 1:10 for PGD
                      and Masters as ideal
                      and score of (5), 1:15
                      (4), 1:20 (3), 1:25 (2),
                      1:30 (1)
                      PhD ration of 1:5 ideal
                      with score of (5), 1:7
                      (4), 1:9 (3), 1:11 (2)
                      and 1:13 (1)`,
                    },
                    {
                      column4: `5.1.6
                      Information
                      and`,
                      column5: `Establish the
                      number of
                      students on
                      the programme
                      against the
                      available total
                      number of staff`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent._516informationsAnd}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={"_516informationsAnd"}
                          />
                        </>
                      ),
                      column7: `Ratio of 1:40 for
                      arts and humanities
                      education. Score of
                      1:40 (5), 1:50 (4), 1:60
                      (3), 1:70 (2), 1:80 (1)
                      Ratio of 1:10 for PGD
                      and Masters as ideal
                      and score of (5), 1:15
                      (4), 1:20 (3), 1:25 (2),
                      1:30 (1)
                      PhD ration of 1:5 ideal
                      with score of (5), 1:7
                      (4), 1:9 (3), 1:11 (2)
                      and 1:13 (1)`,
                    },
                    {
                      column4: `5.1.7
                      Engineering,
                      Manufacturing
                      and
                      Construction`,
                      column5: `Establish the
                      number of
                      students on
                      the programme
                      against the
                      available total
                      number of staff`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.engineeringManufacturingAndConstruction}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={"engineeringManufacturingAndConstruction"}
                          />
                        </>
                      ),
                      column7: `Ratio of 1:40 for
                      arts and humanities
                      education. Score of
                      1:40 (5), 1:50 (4), 1:60
                      (3), 1:70 (2), 1:80 (1)
                      Ratio of 1:10 for PGD
                      and Masters as ideal
                      and score of (5), 1:15
                      (4), 1:20 (3), 1:25 (2),
                      1:30 (1)
                      PhD ration of 1:5 ideal
                      with score of (5), 1:7
                      (4), 1:9 (3), 1:11 (2)
                      and 1:13 (1)`,
                    },
                    {
                      column4: `5.1.8
                      Agriculture,
                      Forestry,
                      Fisheries and
                      Veterinary
                      1:25
                      Agriculture,
                      Forestry,
                      Fisheries and
                      Veterinary
                      (Masters)
                      1:10
                      Agriculture,
                      Forestry,
                      Fisheries and
                      Veterinary
                      (PhD) 1:5`,
                      column5: `Establish the
                      number of
                      students on
                      the programme
                      against the
                      available total
                      number of staff`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.agricultureForestryAndVeterinaryAndOthers}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={"agricultureForestryAndVeterinaryAndOthers"}
                          />
                        </>
                      ),
                      column7: `Ratio of 1:40 for
                      arts and humanities
                      education. Score of
                      1:40 (5), 1:50 (4), 1:60
                      (3), 1:70 (2), 1:80 (1)
                      Ratio of 1:10 for PGD
                      and Masters as ideal
                      and score of (5), 1:15
                      (4), 1:20 (3), 1:25 (2),
                      1:30 (1)
                      PhD ration of 1:5 ideal
                      with score of (5), 1:7
                      (4), 1:9 (3), 1:11 (2)
                      and 1:13 (1)`,
                    },

                    {
                      column4: `5.1.9 Health
                      and Welfare`,
                      column5: `Establish the
                      number of
                      students on
                      the programme
                      against the
                      available total
                      number of staff`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.healthAndWelfare}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={"healthAndWelfare"}
                          />
                        </>
                      ),
                      column7: `Ratio of 1:40 for
                      arts and humanities
                      education. Score of
                      1:40 (5), 1:50 (4), 1:60
                      (3), 1:70 (2), 1:80 (1)
                      Ratio of 1:10 for PGD
                      and Masters as ideal
                      and score of (5), 1:15
                      (4), 1:20 (3), 1:25 (2),
                      1:30 (1)
                      PhD ration of 1:5 ideal
                      with score of (5), 1:7
                      (4), 1:9 (3), 1:11 (2)
                      and 1:13 (1)`,
                    },
                    {
                      column4: `5.1.10
                      Services`,
                      column5: `Establish the
                      number of
                      students on
                      the programme
                      against the
                      available total
                      number of staff`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent._5110services}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={"_5110services"}
                          />
                        </>
                      ),
                      column7: `Ratio of 1:40 for
                      arts and humanities
                      education. Score of
                      1:40 (5), 1:50 (4), 1:60
                      (3), 1:70 (2), 1:80 (1)
                      Ratio of 1:10 for PGD
                      and Masters as ideal
                      and score of (5), 1:15
                      (4), 1:20 (3), 1:25 (2),
                      1:30 (1)
                      PhD ration of 1:5 ideal
                      with score of (5), 1:7
                      (4), 1:9 (3), 1:11 (2)
                      and 1:13 (1)`,
                    },
                  ], // end sub content
                },

                // 5.2
                {
                  subtitleNorm: `5.2 Minimum
                  number of
                  academic staff
                  for a programme`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `5.2.1 - 5 full
                      time and core
                      academic staff
                      for singled
                      disciplined
                      programme`,
                      column5: `Evidence of
                      appointment
                      letters`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.fullTimeAndScoreAcademicStaffForSingledDisciplinedProgramme}
                            ViewDisable={"disabled"}
                            name={
                              "fullTimeAndScoreAcademicStaffForSingledDisciplinedProgramme"
                            }
                          />
                        </>
                      ),
                      column7: `Core staff are those
                      who has undertaken
                      Bachelor and Masters
                      training in that
                      particular discipline of
                      the programme
                      The score shall be
                      either available (5) or
                      not available (0)`,
                    },
                    {
                      column4: `5.2.2 - 3 full
                      time and core
                      academic staff
                      from each of
                      the multiple
                      disciplined
                      programme`,
                      column5: `Appointment
                      letter, evidence
                      of salary
                      payment,
                      allocation,
                      timetable`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.fullTimeAndScoreAcademicStaffFromEachOfTheMultipleDisciplinedProgramme}
                            ViewDisable={"disabled"}
                            name={
                              "fullTimeAndScoreAcademicStaffFromEachOfTheMultipleDisciplinedProgramme"
                            }
                          />
                        </>
                      ),
                      column7: `Core staff are those
                      who has undertaken
                      Bachelor and Masters
                      training in that
                      particular discipline of
                      the programme
                      The score shall be
                      either available (5) or
                      not available (0)`,
                    },
                  ], // end sub content
                },

                // 5.3
                {
                  subtitleNorm: `5.3
                  Qualifications
                  of core staff
                  related to the
                  programme
                  (Proportions)`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `5.3.1 100%
                      PhD holders
                      for Masters
                      and PhD
                      Programme`,
                      column5: `Verifying
                      availability
                      of PhD
                      qualifications for
                      all staff teaching
                      on PhD and
                      Masters
                      programme`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent._100PourcentHoPhdHolerForMasterAndPhdProgramme}
                            ViewDisable={"disabled"}
                            name={
                              "_100PourcentHoPhdHolerForMasterAndPhdProgramme"
                            }
                          />
                        </>
                      ),
                      column7: `To teach on PhD and
                      Master programme, the
                      staff should possess
                      PhD qualification.
                      The score shall be (5)
                      for 100% PhD and (0)
                      for less than 100%
                      PhD holders`,
                    },
                    {
                      column4: `5.3.2 100%
                      Master’s
                      degree
                      holders for
                      Bachelor’s
                      degree
                      programme
                      with PhD as
                      additional
                      advantage`,
                      column5: `Verifying
                      availability of
                      Master’s degree
                      relevant to the
                      programme`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent._100PourcentMastersDegreeHolderForBachelorsDegreeProgrammeWihtPhdAsAdditionalAdvantage}
                            ViewDisable={"disabled"}
                            name={
                              "_100PourcentMastersDegreeHolderForBachelorsDegreeProgrammeWihtPhdAsAdditionalAdvantage"
                            }
                          />
                        </>
                      ),
                      column7: `To teach on Bachelor
                      programme, the
                      minimum qualification
                      is a Masters.
                      The score shall be (5)
                      for 100% Masters or
                      above and 0 for less
                      than 100%`,
                    },
                  ], // end sub content
                },
                // end of 5.3
                // 5.4
                {
                  subtitleNorm: `5.4 Proportion of
                  Full-time to Part-
                  Time Staff`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `5.4.1 The
                      proportion of
                      Full-time to
                      Part-time staff
                      shall be at
                      70% to 30%
                      as ideal`,
                      column5: `Appointment
                      letters,
                      timetable,
                      interaction with
                      staff`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.theProportionOfFullTimeToPartTimeStaffShallBeAt70to30asIdeal}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={
                              "theProportionOfFullTimeToPartTimeStaffShallBeAt70to30asIdeal"
                            }
                          />
                        </>
                      ),
                      column7: `The score of 70-30 is
                      (5), 60-40 (4), 50-50
                      (3), 40 -60 (2), 30-70
                      (1)`,
                    },
                  ], // end sub content
                },
                // end of 5.4

                // 5.5
                {
                  subtitleNorm: `5.5 Teaching
                  Experience`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `5.5.1 75% of
                      the core staff
                      should have
                      a minimum
                      of Three
                      (3) years
                      teaching
                      experience`,
                      column5: `Appointment
                      letters, years
                      of completion
                      of study
                      programmes`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent._75PourcentOfTheCoreStaffHaveAMinimumOfThreeYearsTeachingExperience}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={
                              "_75PourcentOfTheCoreStaffHaveAMinimumOfThreeYearsTeachingExperience"
                            }
                          />
                        </>
                      ),
                      column7: `Teaching experience
                      is about years of
                      teaching. 75% of core
                      staff score is (5), 65%
                      (4), 55% (3), 45% (2),
                      35% (1), less than 35%
                      (0)`,
                    },
                  ], // end sub content
                },
                // end of 5.5

                // 5.6
                {
                  subtitleNorm: `5.6 Workload/
                  Contact hours`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `5.6.1 - 75% of
                      core staff have
                      workload of
                      8-10 hours
                      per week`,
                      column5: `Time-table
                      schedules,
                      interviews with
                      staff`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent._75PourcentOfTheCoreStaffHaveWorkLoadOf8to10HoursPerWeek}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={
                              "_75PourcentOfTheCoreStaffHaveWorkLoadOf8to10HoursPerWeek"
                            }
                          />
                        </>
                      ),
                      column7: `Scores for workload
                      is 75% of staff on
                      8-10hours (5),
                      11-13hours (4), 14-16
                      (3), 17-19 (2), 20-22(1)`,
                    },
                  ], // end sub content
                },
                // end of 5.6

                // 5.7
                {
                  subtitleNorm: `5.7 Training in
                  pedagogy`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `5.7.1 - 75%
                      of the core
                      must have
                      undertaken
                      Pedagogical
                      training`,
                      column5: `Evidence
                      of training
                      conducted.
                      Training
                      manuals,
                      certificates,
                      attendance list
                      etc`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent._75PourcentOfTheCoreUstHaveUndertakenPedagogicalTraining}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={
                              "_75PourcentOfTheCoreUstHaveUndertakenPedagogicalTraining"
                            }
                          />
                        </>
                      ),
                      column7: `Training in pedagogy
                      is in different
                      approaches/methods
                      of teaching. Score of
                      75% of Core staff is
                      (5), 65% (4), 55% (3),
                      45% (2), 35% (1) and
                      less than 35% (0)`,
                    },
                  ], // end sub content
                },
                // end of 5.7

                // 5.8
                {
                  subtitleNorm: `5.8 Provision
                  of regular
                  CPD (Where
                  applicable)`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `5.8.1 - Where
                      applicable ,
                      75% of the
                      core staff
                      should have
                      undertaken
                      CPD within
                      the last 3
                      years`,
                      column5: `Evidence
                      of training
                      conducted.
                      Training
                      manuals,
                      certificates,
                      attendance list
                      etc`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.whereApplicable75PourcentOfTheCoreStaffshouldhaveUndertakenCPDWithinTheLast3Years}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={
                              "whereApplicable75PourcentOfTheCoreStaffshouldhaveUndertakenCPDWithinTheLast3Years"
                            }
                          />
                        </>
                      ),
                      column7: `Where applicable, 75%
                      of staff score is (5),
                      65% (4), 55%(3), 45%
                      (2), 35% (1), less than
                      35% (0)`,
                    },
                  ], // end sub content
                },
                // end of 5.8

                // 5.9
                {
                  subtitleNorm: `5.9 Deployment
                  and allocation of
                  staff`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `5.9.1
                      Evidence of
                      recruitment`,
                      column5: `Appointment
                      letters,
                      recruitment plan
                      implemented`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.evidenceOfRecruitment}
                            ViewDisable={"disabled"}
                            name={"evidenceOfRecruitment"}
                          />
                        </>
                      ),
                      column7: `Availability of the
                      evidence score is (5)
                      and not available is (0)`,
                    },
                    {
                      column4: `5.9.2
                      Evidence
                      of staff
                      recruitment
                      and
                      development
                      Policy`,
                      column5: `Proof of
                      recruitment
                      and staff
                      development
                      policy`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.evidenceOfStaffRecruitmentOfDevelopmemntPolicy}
                            ViewDisable={"disabled"}
                            name={
                              "evidenceOfStaffRecruitmentOfDevelopmemntPolicy"
                            }
                          />
                        </>
                      ),
                      column7: `Availability of the
                      evidence score is (5)
                      and not available is (0)`,
                    },
                    {
                      column4: `5.9.3
                      Evidence of
                      deployment
                      and allocation
                      of faculties
                      according to
                      their area of
                      specialisation`,
                      column5: `Timetable,
                      deployment
                      schedules`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.evidenceOfDeploymentAndAllocationOfFacultiesAccordingToTheirAreaOfSpecialisation}
                            ViewDisable={"disabled"}
                            name={
                              "evidenceOfDeploymentAndAllocationOfFacultiesAccordingToTheirAreaOfSpecialisation"
                            }
                          />
                        </>
                      ),
                      column7: `Deployment
                      according to areas of
                      specialization, training
                      and competences
                      score is (5) and where
                      the former is not
                      followed, the score is
                      (0)`,
                    },
                    {
                      column4: `5.9.4
                      Evidence of
                      three months
                      (3) payroll of
                      core staff`,
                      column5: `Pay roll, and
                      evidence
                      of bank
                      transmissions`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.evidenceOfThreeMonthsPayrollOfCoreStaff}
                            ViewDisable={"disabled"}
                            name={"evidenceOfThreeMonthsPayrollOfCoreStaff"}
                          />
                        </>
                      ),
                      column7: `Sufficient evidence of
                      payment of salaries
                      which is correct and
                      verified is Score of (5),
                      evidence lacking for 3
                      months score is (0)`,
                    },
                  ], // end sub content
                },
                // end of 5.9

                // 5.10
                {
                  subtitleNorm: `5.10 Staff
                  Publications`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `5.10.1 - 75%
                      of the core
                      staff in the
                      programme
                      should have
                      published
                      at least 2
                      papers in a
                      peer reviewed
                      journal within
                      the last 3
                      years`,
                      column5: `Evidence of
                      publication in
                      peer reviewed
                      journal`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent._75PourcentOfTheCoreStaffInTheProgrammeShouldHavePublishedAtLeast2PapersInAPeerReviewedJournalWithinTheLast3Years}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={
                              "_75PourcentOfTheCoreStaffInTheProgrammeShouldHavePublishedAtLeast2PapersInAPeerReviewedJournalWithinTheLast3Years"
                            }
                          />
                        </>
                      ),
                      column7: `Where 75% of core
                      staff has atleast 2
                      publications, score is
                      (5), 65% (4), 55% (3),
                      45% (2), 35% (1), less
                      than 35% (0)`,
                    },
                  ], // end sub content
                },
                // end of 5.10

                // 5.11
                {
                  subtitleNorm: `5.11 Innovations
                  by Staff`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `5.11.1 – At
                      least 10%
                      of the core
                      programme
                      staff should
                      have had
                      innovations
                      within the last
                      5 years`,
                      column5: `Proof of
                      existence of
                      innovations
                      related to the
                      programme`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.atLeast10PourcentofTheCoreProgrammeStaffShouldHaveHadInnovationsWithinTheLast5YEars}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={
                              "atLeast10PourcentofTheCoreProgrammeStaffShouldHaveHadInnovationsWithinTheLast5YEars"
                            }
                          />
                        </>
                      ),
                      column7: `Where 10% of core
                      staff has innovation is
                      (5), 8% (4), 6% (3), 4%
                      (2) and 2% (1)`,
                    },
                  ], // end sub content
                },
                // end of 5.11

                // 5.12
                {
                  subtitleNorm: `5.12 Patents
                  and copy rights`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `5.12.1 –30%
                      of the core
                      staff should
                      have obtained
                      patents and
                      copy rights
                      within the last
                      5 years`,
                      column5: `Registered
                      patents and
                      copy rights`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent._30PourcentOfTheCoreStaffShouldHaveObtainedPatentsAndCopyRightsWithinTheLast5Years}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={
                              "_30PourcentOfTheCoreStaffShouldHaveObtainedPatentsAndCopyRightsWithinTheLast5Years"
                            }
                          />
                        </>
                      ),
                      column7: `Where 30% of core
                      staff have registered
                      patents and copy rights
                      (5), 25% (4), 20% (3),
                      15% (2), 10% (1)`,
                    },
                  ], // end sub content
                },
                // end of 5.12
              ],
            },
          ],
        },
        // end of 5
        {
          subData: [
            {
              number: `6`,
              subtitleStandard: `Governance
              and
              Administration`,
              subcontent: [
                // 6.1
                {
                  subtitleNorm: `6.1 Head of
                  Department`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `6.1.1 Head of
                      Department
                      with PhD`,
                      column5: `Evidence of
                      appointment
                      of Head of
                      Department for
                      the Department
                      in which the
                      programme is
                      hosted`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.headOfDepartmentWithPhD}
                            ViewDisable={"disabled"}
                            name={"headOfDepartmentWithPhD"}
                          />
                        </>
                      ),
                      column7: `Head of Department in
                      place and holds a PhD
                      score is (5) and not in
                      place or in place but no
                      PhD score is (0)`,
                    },
                    {
                      column4: `6.1.2
                      Organogram
                      of the
                      Department
                      within the
                      Faculty/
                      School/
                      Institute`,
                      column5: `Clear
                      organogram
                      detailing
                      reporting
                      structure`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.organogramOfTheDepartmentWithinTheFacultySchoolInstitute}
                            ViewDisable={"disabled"}
                            name={
                              "organogramOfTheDepartmentWithinTheFacultySchoolInstitute"
                            }
                          />
                        </>
                      ),
                      column7: `Clear organogram
                      score is (5) and varied
                      according to how
                      the organogram is
                      described and thus
                      from 5-0`,
                    }, //end 6.1.2
                  ], // end sub content
                },
                //  end of 6.1

                // 3.2
                {
                  subtitleNorm: `6.2.
                  Coordination of
                  the programme
                  Academic
                  Leader /
                  Coordinator`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `6.2.1
                      Qualifications
                      of Academic
                      Leader/
                      Coordinator
                      - Bachelor’s
                      programme
                      - minimum
                      of Masters in
                      the relevant
                      discipline`,
                      column5: `Evidence of
                      appointment
                      and qualification
                      being a
                      minimum of
                      Bachelors in
                      the discipline/
                      programme
                      in which
                      accreditation is
                      sought`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.qualificationsOfAcademicLeaderCoordinatorBachelorsProgrammeMinimumOfMastersInTheRelevantDiscipline}
                            ViewDisable={"disabled"}
                            name={
                              "qualificationsOfAcademicLeaderCoordinatorBachelorsProgrammeMinimumOfMastersInTheRelevantDiscipline"
                            }
                          />
                        </>
                      ),
                      column7: `Available of requisite
                      qualification in the
                      discipline area is
                      a score of (5) and
                      absence of that score
                      is (0)`,
                    },
                    {
                      column4: `6.2.2
                      Qualifications
                      of Academic
                      Leader/
                      Coordinator
                      - Masters
                      Programme
                      - PhD in
                      the relevant
                      discipline`,
                      column5: `Evidence of
                      appointment
                      and qualification
                      being a
                      minimum of PhD
                      in the discipline/
                      programme
                      in which
                      accreditation is
                      sought`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.qualificationsOfAcademicLeaderCoodinatorMastersProgrammePhDInTheRelevanteDiscipline}
                            ViewDisable={"disabled"}
                            name={
                              "qualificationsOfAcademicLeaderCoodinatorMastersProgrammePhDInTheRelevanteDiscipline"
                            }
                          />
                        </>
                      ),
                      column7: `Available of requisite
                      qualification in the
                      discipline area is
                      a score of (5) and
                      absence of that score
                      is (0)`,
                    },
                    // end 6.2.2
                    {
                      column4: `6.2.3
                      Qualifications
                      of Academic
                      Leader/
                      Coordinator
                      - PhD
                      programmes
                      - PhD in
                      the relevant
                      discipline`,
                      column5: `Evidence of
                      appointment
                      and qualification
                      being a
                      minimum of PhD
                      in the discipline/
                      programme
                      in which
                      accreditation is
                      sought`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.qualificationsOfAcademicLeaderCoodinatorPhdProgrammesInTheRelevantDiscipline}
                            ViewDisable={"disabled"}
                            name={
                              "qualificationsOfAcademicLeaderCoodinatorPhdProgrammesInTheRelevantDiscipline"
                            }
                          />
                        </>
                      ),
                      column7: `Available of requisite
                      qualification in the
                      discipline area is
                      a score of (5) and
                      absence of that score
                      is (0)`,
                    },
                  ], // end sub content
                },

                // 6.3
                {
                  subtitleNorm: `6.3 Quality
                  Assurance
                  Policy`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `6.3.1
                      Availability
                      of the
                      approved and
                      implemented
                      Quality
                      Assurance
                      policy.`,
                      column5: `Evidence
                      that Quality
                      Assurance
                      Policy exists
                      are approved
                      and being
                      implemented`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.availabilityOfTheApprovedAndImplementedQualityAssurancePolicy}
                            ViewDisable={"disabled"}
                            name={
                              "availabilityOfTheApprovedAndImplementedQualityAssurancePolicy"
                            }
                          />
                        </>
                      ),
                      column7: `Score of (5) for
                      available and (0) for
                      not being available`,
                    },
                    {
                      column4: `6.3.2
                      Availability of
                      procedures on
                      supervision
                      of projects,
                      thesis, and
                      dissertations`,
                      column5: `Evidence of
                      availability of
                      procedures and
                      guidelines for
                      supervision of
                      projects, thesis
                      and dissertation`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.availabilityOfPRoceduresOnSupervisionOfTheProjectsThesisAndDissertations}
                            ViewDisable={"disabled"}
                            name={
                              "availabilityOfPRoceduresOnSupervisionOfTheProjectsThesisAndDissertations"
                            }
                          />
                        </>
                      ),
                      column7: `Score of (5) for
                    available and (0) for
                    not being available`,
                    },
                  ], // end sub content
                },

                // 6.4
                {
                  subtitleNorm: `6.4 Institutional
                  programme
                  quality
                  assurance
                  mechanisms`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `6.4.1
                      Availability
                      and
                      appointment
                      of QA
                      committee
                      through
                      which quality
                      assurance
                      of the
                      programme
                      will be
                      conducted`,
                      column5: `Evidence of
                      existence of
                      Committee,
                      appointment
                      letters`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.availabilityAndAppointmentOfQACommitteeThroughWhichQualityAssuranceOfTheProgrammeWillBeConducted}
                            ViewDisable={"disabled"}
                            name={
                              "availabilityAndAppointmentOfQACommitteeThroughWhichQualityAssuranceOfTheProgrammeWillBeConducted"
                            }
                          />
                        </>
                      ),
                      column7: `Score of (5) for
                      available and (0) for
                      not being available`,
                    },
                  ], // end sub content
                }, // end of 6.4

                // 6.5
                {
                  subtitleNorm: `6.5 Quality
                  Assurance
                  Policy`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `6.5.1
                      Availability
                      of the
                      approved and
                      implemented
                      Quality
                      Assurance
                      policy.`,
                      column5: `Evidence of
                      availability and
                      implementation`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.avalabilityOfTheApprovedAndImplementedQualityAssurancePolicy}
                            ViewDisable={"disabled"}
                            name={
                              "avalabilityOfTheApprovedAndImplementedQualityAssurancePolicy"
                            }
                          />
                        </>
                      ),
                      column7: `Score of (5) for
                      available and (0) for
                      not being available`,
                    },
                    {
                      column4: `6.5.2 Reports
                      of academic
                      staff
                      performance
                      evaluation by
                      students at
                      the end of the
                      semester`,
                      column5: `Evidence of
                      evaluation
                      report done`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.reportOfAcademicStaffPreformanceEvaluationByStudentAtTheEndOfTheSemester}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={
                              "reportOfAcademicStaffPreformanceEvaluationByStudentAtTheEndOfTheSemester"
                            }
                          />
                        </>
                      ),
                      column7: `Report available on
                      100% of core staff (5),
                      70% (4), 60% (3), 50%
                      (2), 40% (1), < 40% (0)`,
                    },

                    {
                      column4: `6.5.3
                      Students
                      satisfaction
                      rate 80% or
                      above on
                      feedback of
                      assessment
                      of academic
                      staff`,
                      column5: `Evidence of
                      report showing
                      level of
                      satisfaction`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.studentsSatissfactionRate80PercentORAboveOnFeedbackOfAssessmentOfAcademicStaff}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={
                              "studentsSatissfactionRate80PercentORAboveOnFeedbackOfAssessmentOfAcademicStaff"
                            }
                          />
                        </>
                      ),
                      column7: `Satisfaction rate of
                      80% score of (5), 70%
                      (4), 60% (3), 50% (2)
                      and 40% (1),`,
                    },

                    {
                      column4: `6.5.4
                      students’
                      completion
                      rate of the
                      programme
                      - 75% and
                      above of the
                      most recent
                      cohort`,
                      column5: `Graduation rate
                      in terms of a
                      particular cohort`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.studentsCompletionRateOfTheProgramme75PercentAndAboveOfTheMostRecentCohort}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={
                              "studentsCompletionRateOfTheProgramme75PercentAndAboveOfTheMostRecentCohort"
                            }
                          />
                        </>
                      ),
                      column7: `Completion rate of
                      75% score of (5), 65%
                      (4), 55% (3), 45% (2),
                      35% (1) and <35%(0)`,
                    },

                    {
                      column4: `6.5.5
                      Students
                      transition rate
                      – 75% and
                      above of the
                      most recent
                      cohort`,
                      column5: `Rate of
                      transition from
                      one semester to
                      another`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.studentsTransitionRate75PercentAndAboveOfTheMostRecentCohort}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={
                              "studentsTransitionRate75PercentAndAboveOfTheMostRecentCohort"
                            }
                          />
                        </>
                      ),
                      column7: `Transition rate of 75%
                      score of (5), 65% (4),
                      55% (3), 45% (2), 35%
                      (1) and <35% (0)`,
                    },
                    {
                      column4: `6.5.6
                      programme
                      quality
                      improvement
                      plans through
                      appropriate
                      mechanisms
                      or plans.`,
                      column5: `Evidence of
                      progamme
                      quality
                      improvement
                      plan provided
                      highlighting
                      the areas of
                      improvement`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.programmeQualityImprovementPlansThroughAppropriateMechanismsOrPlans}
                            ViewDisable={"disabled"}
                            name={
                              "programmeQualityImprovementPlansThroughAppropriateMechanismsOrPlans"
                            }
                          />
                        </>
                      ),
                      column7: `Detailed and
                      comprehensive quality
                      assurance plan
                      available score of (5)
                      and not available (0)`,
                    },
                  ], // end sub content
                }, // end of 6.5

                // 6.6
                {
                  subtitleNorm: `6.6 Institutional
                  policy on
                  programme
                  connectedness
                  with the world of
                  practice/work`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `6.6.1. - 75%
                      of students on
                      attachment /
                      placement.`,
                      column5: `Evidence of
                      attachment/
                      deployment and
                      reports`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent._75PercentOfStudentsOnAttachmentPlacement}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={"_75PercentOfStudentsOnAttachmentPlacement"}
                          />
                        </>
                      ),
                      column7: `75% of students
                      attached score of (5),
                      65% (4), 55%(3), 45%
                      (2), 35% (1)`,
                    },
                    {
                      column4: `6.6.2. Number
                      of projects/
                      MoUs /
                      MoAs under
                      University-
                      Industry
                      linkages
                      related to the
                      programme`,
                      column5: `Evidence of
                      signed MoUs/
                      MoA`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.numberOfProjectsMoUsMoAsUnderUniversityIndustryLinkageRelatedToTheProgramme}
                            ViewDisable={"disabled"}
                            name={
                              "numberOfProjectsMoUsMoAsUnderUniversityIndustryLinkageRelatedToTheProgramme"
                            }
                          />
                        </>
                      ),
                      column7: `Atleast 1 MoU within
                      the cycle of the
                      programme score of
                      (5) and zero (0)`,
                    },
                    {
                      column4: `6.6.3
                      Evidence of`,
                      column5: `Activities being
                      implemented in
                      the MoU/MoA,
                      reports etc`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.evidenceOf}
                            ViewDisable={"disabled"}
                            name={"evidenceOf"}
                          />
                        </>
                      ),
                      column7: `Score of (5) for
                      available and (0) for
                      not being available`,
                    },
                    {
                      column4: `6.6.4.`,
                      column5: `Feedback from
                      stakeholders on
                      connectedness
                      with World of
                      Work`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent._664WithoutAnyTitle}
                            ViewDisable={"disabled"}
                            name={"_664WithoutAnyTitle"}
                          />
                        </>
                      ),
                      column7: `Score of (5) for
                      available and (0) for
                      not being available`,
                    },
                  ], // end sub content
                }, // end of 3.6
              ],
            },
          ],
        },
        // end of 6

        {
          subData: [
            {
              number: `7`,
              subtitleStandard: `Infrastructure/
              Facilities`,
              subcontent: [
                // 7.1
                {
                  subtitleNorm: `7.1 Lecture
                  room space.`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `7.1.1 As
                      provided in
                      appendix 8.1
                      appended to
                      these norms`,
                      column5: `Measurement of
                      available space
                      fits within the
                      expected norms`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.asProvidedInAppendix81AppendToTheseNorms}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={"asProvidedInAppendix81AppendToTheseNorms"}
                          />
                        </>
                      ),
                      column7: `Lecture space of
                      1.2m2 per student for
                      graduate programmes
                      and 1m2 for
                      undergraduate score
                      of (5)`,
                    },
                    {
                      column4: `7.1.2`,
                      column5: `Lecture rooms
                      are sufficient
                      in terms of
                      number,
                      ambience,
                      furniture,
                      adequacy of
                      lecture facilities`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent._712WithoutTitle}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={"_712WithoutTitle"}
                          />
                        </>
                      ),
                      column7: `Varied score from
                      5-0 depending on
                      sufficiency, ambience`,
                    },
                    {
                      column4: `7.1.3 Lecture
                      theatre space`,
                      column5: `Measurement of
                      lecture theatre
                      as provide for in
                      Appendix 8.1`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.lectureTheatreSpace}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={"lectureTheatreSpace"}
                          />
                        </>
                      ),
                      column7: `Lecture theatre
                      space of 1.2m2 per
                      student for graduate
                      programmes and 1m2
                      for undergraduate
                      score of (5)`,
                    },
                    {
                      column4: `7.1.4`,
                      column5: `Lecture
                      theatre has the
                      appropriate
                      furniture,
                      ambiance`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent._714WithoutTitle}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={"_714WithoutTitle"}
                          />
                        </>
                      ),
                      column7: `Varied score from
                      5-0 depending on
                      sufficiency, ambience`,
                    },
                  ], // end sub content
                },

                // 7.2
                {
                  subtitleNorm: `7.2 Library
                  space`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `7.2.1 2m2 per
                      student/ As
                      provided in
                      appendix 8.1
                      appended to
                      these norms`,
                      column5: `Measurement of
                      available space`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent._2m2perStudentAsProvidedInAppendix81ToTheseNorme}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={
                              "_2m2perStudentAsProvidedInAppendix81ToTheseNorme"
                            }
                          />
                        </>
                      ),
                      column7: `Varied score from
                      5-0 depending on
                      availability of space`,
                    },
                    {
                      column4: `7.2.2`,
                      column5: `Number of
                      library chairs
                      and reading
                      table. Library
                      resources
                      available,
                      whether 30%
                      of the student
                      population can
                      sit in the library.`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent._722WithoutTitle}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={"_722WithoutTitle"}
                          />
                        </>
                      ),
                      column7: `Varied score from 5-0`,
                    },
                  ], // end sub content
                },

                // 7.3
                {
                  subtitleNorm: `7.3 ICT
                  Computer
                  Laboratories
                  space`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `7.3.1 2.5m2
                      per student.`,
                      column5: `Measurement of
                      available space`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent._2_5m2PerStudent}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={"_2_5m2PerStudent"}
                          />
                        </>
                      ),
                      column7: `Varied score from
                      5-0 depending on
                      availability of space`,
                    },
                    {
                      column4: `7.3.2`,
                      column5: `Number of
                      computers,
                      availability
                      of internet
                      connectivity,
                      lay-out of the
                      computer
                      laboratory`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent._732WithoutTitle}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={"_732WithoutTitle"}
                          />
                        </>
                      ),
                      column7: `Varied score from
                      5-0 adequacy of
                      computers, provision
                      of internet, lay-out of
                      laboratory etc`,
                    },
                  ], // end sub content
                },
                // end of 7.3
                // 7.4
                {
                  subtitleNorm: `7.4 Seminar
                  rooms`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `7.4.1 2m2 per
                      student. / As
                      provided in
                      appendix 8.1
                      appended to
                      these norms`,
                      column5: `Measurement of
                      space available
                      against the
                      number of
                      students`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent._2m2PerStudentAsProvidedInAppendix8_1AppendedToTheseNorms}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={
                              "_2m2PerStudentAsProvidedInAppendix8_1AppendedToTheseNorms"
                            }
                          />
                        </>
                      ),
                      column7: `Varied score from
                      5-0 depending on
                      availability of space`,
                    },
                    {
                      column4: `7.4.2`,
                      column5: `Sitting facilities,
                      ambience,
                      aeration etc`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent._742WithoutTitle}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={"_742WithoutTitle"}
                          />
                        </>
                      ),
                      column7: `Varied score from 5-0
                      depending on facilities
                      and ambience`,
                    },
                  ], // end sub content
                },
                // end 7.4

                // 7.5
                {
                  subtitleNorm: `7.5 Studios
                  space`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `7.5.1 As
                      provided in
                      appendix 8.1
                      appended to
                      these norms`,
                      column5: `Measurement of
                      available space`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent._751asProvidedInAppendix8_1AppendedToTheseNorms}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={
                              "_751asProvidedInAppendix8_1AppendedToTheseNorms"
                            }
                          />
                        </>
                      ),
                      column7: `Varied score from
                      5-0 depending on
                      availability of space`,
                    },
                    {
                      column4: `7.5.2`,
                      column5: `Availability
                      of relevant
                      facilities for
                      the studio and
                      ambience`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent._752WithoutTitle}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={"_752WithoutTitle"}
                          />
                        </>
                      ),
                      column7: `Varied score from
                      5-0 depending on
                      relevance of facilities
                      and ambience`,
                    },
                  ], // end sub content
                },
                // end of 7.5

                // 7.6
                {
                  subtitleNorm: `7.6 Offices for
                  staff space`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `7.6.1 As
                      provided in
                      appendix 8.1
                      appended to
                      these norms`,
                      column5: `Availability of
                      space for staff in
                      the offices`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent._761asProvidedInAppendix8_1AppendedToTheseNorms}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={
                              "_761asProvidedInAppendix8_1AppendedToTheseNorms"
                            }
                          />
                        </>
                      ),
                      column7: `Varied score from
                      5-0 depending on
                      availability of space`,
                    },
                    {
                      column4: `7.6.2`,
                      column5: `Furnishing of
                      the offices,
                      ambience etc`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent._762WithoutTitle}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={"_762WithoutTitle"}
                          />
                        </>
                      ),
                      column7: `Varied score from
                      5-0 depending on
                      availability of space
                      and ambience of
                      offices`,
                    },
                  ], // end sub content
                },
                // end of 7.6

                // 7.7
                {
                  subtitleNorm: `7.7 Spaces for
                  Basic Science
                  Laboratories`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `7.7.1 As
                      provided in
                      appendix 8.1
                      appended to
                      these norms`,
                      column5: `Availability of
                      space as per
                      ratio`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent._771asProvidedInAppendix8_1AppendedToTheseNorms}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={
                              "_771asProvidedInAppendix8_1AppendedToTheseNorms"
                            }
                          />
                        </>
                      ),
                      column7: `Varied score from
                      5-0 depending on
                      availability of space`,
                    },
                    {
                      column4: `7.7.2
                      Availability of
                      Laboratory
                      equipment
                      and
                      consumables`,
                      column5: `Existence
                      and number
                      of laboratory
                      equipment and
                      consumables,
                      relevance,
                      adequacy`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.availabilityOfLaboratoryEquipmentAndConsumables}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={
                              "availabilityOfLaboratoryEquipmentAndConsumables"
                            }
                          />
                        </>
                      ),
                      column7: `Varied score from
                      5-0 depending on
                      sufficiency, relevance
                      and ambience`,
                    },
                  ], // end sub content
                },
                // end of 7.7

                // 7.8
                {
                  subtitleNorm: `7.8 Human
                  Medicine
                  Laboratories`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `7.8.1
                      Availability
                      of space for
                      Anatomy
                      Laboratory`,
                      column5: `Availability
                      of Anatomy
                      laboratory`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.availabilityOfSpaceForAnatomyLaboratory}
                            ViewDisable={"disabled"}
                            name={"availabilityOfSpaceForAnatomyLaboratory"}
                          />
                        </>
                      ),
                      column7: `Available is score of 5
                      and not available score
                      of 0`,
                    },
                    {
                      column4: `7.8.2
                      Availability
                      and number
                      of cadavers`,
                      column5: `Availability of
                      cadaver and
                      student to
                      cadaver ratio`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.availabilityAndNumberOfCadavers}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={"availabilityAndNumberOfCadavers"}
                          />
                        </>
                      ),
                      column7: `The ratio of students
                      to cadaver with varied
                      score from 5-0`,
                    },
                    {
                      column4: `7.8.3`,
                      column5: `Existence of
                      anatomy tank,
                      working tables,
                      preparatory
                      room, ambience
                      etc`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent._783WithoutTitle}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={"_783WithoutTitle"}
                          />
                        </>
                      ),
                      column7: `Varied score from
                      5-0 depending on
                      sufficiency, relevance
                      and ambience`,
                    },
                    {
                      column4: `7.8.4
                      Availability of
                      physiology
                      Laboratory`,
                      column5: `Existence of
                      physiology
                      laboratory`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.availabilityOfPhylosophyLaboratory}
                            ViewDisable={"disabled"}
                            name={"availabilityOfPhylosophyLaboratory"}
                          />
                        </>
                      ),
                      column7: `Available is score of 5
                      and not available score
                      of 0`,
                    },
                    {
                      column4: `7.8.5`,
                      column5: `Equipment
                      are available
                      and adequate,
                      drainage,
                      ambience`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent._785WithoutTitle}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={"_785WithoutTitle"}
                          />
                        </>
                      ),
                      column7: `Varied score from
                      5-0 depending on
                      sufficiency, relevance
                      and ambience`,
                    },
                    {
                      column4: `7.8.6
                      Availability of
                      Biochemistry
                      laboratory`,
                      column5: `Availability and
                      adequate space
                      for Biochemistry
                      laboratory`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.availabilityOfBioChemistryLaboratory}
                            ViewDisable={"disabled"}
                            name={"availabilityOfBioChemistryLaboratory"}
                          />
                        </>
                      ),
                      column7: `Available is score of 5
                      and not available score
                      of 0`,
                    },
                    {
                      column4: `7.8.7`,
                      column5: `Availability
                      and number
                      of equipment,
                      ambience and
                      relevance of
                      equipment`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent._787WithoutTitle}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={"_787WithoutTitle"}
                          />
                        </>
                      ),
                      column7: `Varied score from
                      5-0 depending on
                      sufficiency, relevance
                      and ambience`,
                    },
                    {
                      column4: `7.8.8
                      Availability
                      of histology
                      laboratory`,
                      column5: `Availability
                      of histology
                      laboratory,
                      ambience etc`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.availabilityOfHistoryLaboratory}
                            ViewDisable={"disabled"}
                            name={"availabilityOfHistoryLaboratory"}
                          />
                        </>
                      ),
                      column7: `Available is score of 5
                      and not available score
                      of 0`,
                    },
                    {
                      column4: `7.8.9`,
                      column5: `Appropriateness
                      in terms of
                      space, storage
                      facilities,
                      records,
                      materials and
                      ambience`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent._789WithoutTitle}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={"_789WithoutTitle"}
                          />
                        </>
                      ),
                      column7: `Varied score from
                      5-0 depending on
                      sufficiency, relevance
                      and ambience`,
                    },
                  ], // end sub content
                },
                // end of 7.8

                // 7.9
                {
                  subtitleNorm: `7.9 Dental
                  Medicine
                  laboratories`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `7.9.1
                      Availability of
                      fathom room`,
                      column5: `Space available
                      for fathom room`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.availabilityOfFathomRoom}
                            ViewDisable={"disabled"}
                            name={"availabilityOfFathomRoom"}
                          />
                        </>
                      ),
                      column7: `Available is score of 5
                      and not available score
                      of 0`,
                    },
                    {
                      column4: `7.9.2`,
                      column5: `Fathom room
                      with the basic
                      requirements
                      and ambience`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent._792WithoutTitle}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={"_792WithoutTitle"}
                          />
                        </>
                      ),
                      column7: `Varied score from
                      5-0 depending on
                      sufficiency, relevance
                      and ambience`,
                    },
                    {
                      column4: `7.9.3
                      Availability
                      of dental
                      laboratory`,
                      column5: `Availability
                      of dental
                      laboratory with
                      the required
                      space`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.avalabilityOfDentalLaboratory}
                            ViewDisable={"disabled"}
                            name={"avalabilityOfDentalLaboratory"}
                          />
                        </>
                      ),
                      column7: `Available is score of 5
                      and not available score
                      of 0`,
                    },
                    {
                      column4: `7.9.4`,
                      column5: `Dental
                      laboratory
                      with dental
                      chairs, relevant
                      equipment and
                      ambience is
                      good`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent._794withoutTitle}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={"_794withoutTitle"}
                          />
                        </>
                      ),
                      column7: `Varied score from
                      5-0 depending on
                      sufficiency, relevance
                      and ambience`,
                    },
                  ], // end sub content
                },
                // end of 7.9

                // 7.10
                {
                  subtitleNorm: `7.10 Skills
                  Laboratory
                  for Nursing/
                  Midwifery`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `7.10.1
                      Availability of
                      laboratory for
                      Nursing and
                      midwifery`,
                      column5: `Availability of
                      skills laboratory
                      with the
                      requisite space`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.availabilityOfLaboratoryForNursingAndMidwifery}
                            ViewDisable={"disabled"}
                            name={
                              "availabilityOfLaboratoryForNursingAndMidwifery"
                            }
                          />
                        </>
                      ),
                      column7: `Available is score of 5
                      and not available score
                      of 0`,
                    },
                    {
                      column4: `7.10.2`,
                      column5: `Skills laboratory
                      with the relevant
                      equipment and
                      ambience`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent._7102WithoutTitle}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={"_7102WithoutTitle"}
                          />
                        </>
                      ),
                      column7: `Varied score from
                      5-0 depending on
                      sufficiency, relevance
                      and ambience`,
                    },
                  ], // end sub content
                },
                // end of 7.10

                // 7.11
                {
                  subtitleNorm: `7.11 Teaching
                  hospital`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `7.11.1
                      Availability
                      and`,
                      column5: `Teaching
                      hospital with
                      facilities as
                      provided in
                      Annex 8.1`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent._7111availabilityAnd}
                            ViewDisable={"disabled"}
                            name={"_7111availabilityAnd"}
                          />
                        </>
                      ),
                      column7: `Available is score of 5
                      and not available score
                      of 0`,
                    },
                  ], // end sub content
                },
                // end of 7.11

                // 7.12
                {
                  subtitleNorm: `7.12 Agricultural
                  Sciences
                  laboratories`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `7.12.1
                      Availability of
                      Crop Science
                      laboratory`,
                      column5: `Availability of
                      crop science
                      laboratory with
                      available space`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.availabilityOfCropScienceLaboratory}
                            ViewDisable={"disabled"}
                            name={"availabilityOfCropScienceLaboratory"}
                          />
                        </>
                      ),
                      column7: `Available is score of 5
                      and not available score
                      of 0`,
                    },
                    {
                      column4: `7.12.2`,
                      column5: `Appropriateness
                      in terms of
                      equipment for
                      soil analysis,
                      chemicals,
                      consumables
                      and ambience`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent._7122WithoutTitle}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={"_7122WithoutTitle"}
                          />
                        </>
                      ),
                      column7: `Varied score from
                      5-0 depending on
                      sufficiency, relevance
                      and ambience`,
                    },
                    {
                      column4: `7.12.3
                      Availability
                      of Animal
                      Science
                      laboratory`,
                      column5: `Available and
                      space for
                      Animal Science
                      laboratory`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.availabilityOfAnimalScienceLaboratory}
                            ViewDisable={"disabled"}
                            name={"availabilityOfAnimalScienceLaboratory"}
                          />
                        </>
                      ),
                      column7: `Available is score of 5
                      and not available score
                      of 0`,
                    },
                    {
                      column4: `7.12.4`,
                      column5: `Appropriateness
                      in terms of
                      facilities,
                      equipment,
                      drugs,
                      demonstration
                      facilities etc`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent._7124WithoutTitle}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={"_7124WithoutTitle"}
                          />
                        </>
                      ),
                      column7: `Varied score from
                      5-0 depending on
                      sufficiency, relevance
                      and ambience`,
                    },
                  ], // end sub content
                },

                // 7.13
                {
                  subtitleNorm: `7.13
                  Engineering
                  laboratories`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `7.13.1
                      Availability of
                      laboratory for
                      materials`,
                      column5: `Materials
                      laboratory
                      available`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.availabilityOfLaboratoryForMaterials}
                            ViewDisable={"disabled"}
                            name={"availabilityOfLaboratoryForMaterials"}
                          />
                        </>
                      ),
                      column7: `Available is score of 5
                      and not available score
                      of 0`,
                    },
                    {
                      column4: `7.13.2`,
                      column5: `Materials
                      laboratory with
                      basic equipment
                      and is functional`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent._7132WithoutTitle}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={"_7132WithoutTitle"}
                          />
                        </>
                      ),
                      column7: `Varied score from
                      5-0 depending on
                      sufficiency, relevance
                      and ambience`,
                    },
                    {
                      column4: `7.13.3
                      Availability
                      of laboratory
                      for electrical
                      engineering`,
                      column5: `Availability
                      of laboratory
                      for electrical
                      engineering`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.availabilityOfLaboratoryForEngineering}
                            ViewDisable={"disabled"}
                            name={"availabilityOfLaboratoryForEngineering"}
                          />
                        </>
                      ),
                      column7: `Available is score of 5
                      and not available score
                      of 0`,
                    },
                    {
                      column4: `7.13.4`,
                      column5: `Relevant
                      equipment,
                      space and
                      ambience`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent._7134WithoutTitle}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={"_7134WithoutTitle"}
                          />
                        </>
                      ),
                      column7: `Varied score from
                      5-0 depending on
                      sufficiency, relevance
                      and ambience`,
                    },
                    {
                      column4: `7.15.3 Availability
                      of laboratory
                      for renewable
                      energy`,
                      column5: `Availability
                      of laboratory
                      for renewable
                      energy`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.availabilityOfLaboratoryForRenewableEnergy}
                            ViewDisable={"disabled"}
                            name={"availabilityOfLaboratoryForRenewableEnergy"}
                          />
                        </>
                      ),
                      column7: `Available is score of 5
                      and not available score
                      of 0`,
                    },
                    {
                      column4: `7.13.6`,
                      column5: `Laboratory has
                      the requisite
                      equipment and
                      facilities`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent._7136WithoutTitle}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={"_7136WithoutTitle"}
                          />
                        </>
                      ),
                      column7: `Varied score from
                      5-0 depending on
                      sufficiency, relevance
                      and ambience`,
                    },
                  ], // end sub content
                },
                // end of 7.13

                // 7.14
                {
                  subtitleNorm: `7.14 Workshops`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `7.14.1
                      Availability
                      of carpentry
                      workshop`,
                      column5: `Availability
                      of carpentry
                      workshop`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.availabilityOfCarpentryWorkshop}
                            ViewDisable={"disabled"}
                            name={"availabilityOfCarpentryWorkshop"}
                          />
                        </>
                      ),
                      column7: `Available is score of 5
                      and not available score
                      of 0`,
                    },
                    {
                      column4: `7.14.2`,
                      column5: `Adequacy of
                      equipment and
                      tools, ambience
                      etc`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent._7142WithoutTitle}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={"_7142WithoutTitle"}
                          />
                        </>
                      ),
                      column7: `Varied score from
                      5-0 depending on
                      sufficiency, relevance
                      and ambience`,
                    },
                    {
                      column4: `7.14.3
                      Availability
                      of metal
                      fabrication
                      workshop`,
                      column5: `Metal fabrication
                      workshop with
                      the required
                      space`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.availabilityOfMetalFabricationWorkshop}
                            ViewDisable={"disabled"}
                            name={"availabilityOfMetalFabricationWorkshop"}
                          />
                        </>
                      ),
                      column7: `Available is score of 5
                      and not available score
                      of 0`,
                    },
                    {
                      column4: `7.14.4`,
                      column5: `Relevant tools
                      and equipment
                      and ambience`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent._7144WithoutTitle}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={"_7144WithoutTitle"}
                          />
                        </>
                      ),
                      column7: `Varied score from
                      5-0 depending on
                      sufficiency, relevance
                      and ambience`,
                    },
                    {
                      column4: `7.14.5
                      Availability of
                      motor vehicle
                      maintenance
                      workshop`,
                      column5: `Evidence of
                      motor vehicle
                      lube/workshop
                      with the basic
                      equipment`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.availabilityOfMotorVehicleMaintenanceWorkShop}
                            ViewDisable={"disabled"}
                            name={
                              "availabilityOfMotorVehicleMaintenanceWorkShop"
                            }
                          />
                        </>
                      ),
                      column7: `Available is score of 5
                      and not available score
                      of 0`,
                    },
                  ], // end sub content
                },
                // end of 7.14

                // 7.15
                {
                  subtitleNorm: `7.15 Teachers
                  resource rooms`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `7.15.1
                      Availability
                      of Teachers
                      resource
                      room`,
                      column5: `Availability of
                      a teacher’s
                      resource room`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.availabilityOfTeacherResourceRoom}
                            ViewDisable={"disabled"}
                            name={"availabilityOfTeacherResourceRoom"}
                          />
                        </>
                      ),
                      column7: `Available is score of 5
                      and not available score
                      of 0`,
                    },
                    {
                      column4: `7.15.2`,
                      column5: `Teachers
                      resource room
                      with appropriate
                      materials,
                      designs and
                      ambience for
                      learning`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent._7152WithoutTitle}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={"_7152WithoutTitle"}
                          />
                        </>
                      ),
                      column7: `Varied score from
                      5-0 depending on
                      sufficiency, relevance
                      and ambience`,
                    },
                  ], // end sub content
                },
                // end of 7.15

                // 7.16
                {
                  subtitleNorm: `7.16 Education
                  Technology
                  laboratories`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `7.16.1
                      Availability
                      of Education
                      Technology
                      laboratory`,
                      column5: `Evidence of
                      existence of
                      Education
                      Technology
                      laboratory`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.availabilityOfEducationTechnologyLaboratory}
                            ViewDisable={"disabled"}
                            name={"availabilityOfEducationTechnologyLaboratory"}
                          />
                        </>
                      ),
                      column7: `Available is score of 5
                      and not available score
                      of 0`,
                    },
                    {
                      column4: `7.16.2`,
                      column5: `Appropriate
                      in variety of
                      equipment,
                      ambience for
                      learning etc`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent._7162WithoutTitle}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={"_7162WithoutTitle"}
                          />
                        </>
                      ),
                      column7: `Varied score from
                      5-0 depending on
                      sufficiency, relevance
                      and ambience`,
                    },
                  ], // end sub content
                },
                // end of 7.16

                // 7.17
                {
                  subtitleNorm: `7.17 Food
                  and catering
                  laboratories`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `7.17.1
                      Availability
                      of Food and
                      catering
                      laboratories`,
                      column5: `Evidence of
                      existence of
                      Food and
                      Catering
                      laboratory`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.availabilityOfFoodAndCateringLaboratories}
                            ViewDisable={"disabled"}
                            name={"availabilityOfFoodAndCateringLaboratories"}
                          />
                        </>
                      ),
                      column7: `Available is score of 5
                      and not available score
                      of 0`,
                    },
                    {
                      column4: `7.17.2`,
                      column5: `Relevant
                      equipment,
                      work stations,
                      facilities,
                      drainage, refuse
                      collection`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent._7172WithoutTitle}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={"_7172WithoutTitle"}
                          />
                        </>
                      ),
                      column7: `Varied score from
                      5-0 depending on
                      sufficiency, relevance
                      and ambience`,
                    },
                  ], // end sub content
                },
                // end of 7.17

                // 7.18
                {
                  subtitleNorm: `7.18 Garment
                  cutting`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `7.18.1
                      Availability
                      of laboratory
                      for garment
                      cutting`,
                      column5: `Availability
                      of garment
                      cutting design
                      laboratory`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.availabilityOfLaboratoryForGarmentCutting}
                            ViewDisable={"disabled"}
                            name={"availabilityOfLaboratoryForGarmentCutting"}
                          />
                        </>
                      ),
                      column7: `Available is score of 5
                      and not available score
                      of 0`,
                    },
                    {
                      column4: `7.18.2`,
                      column5: `Number of
                      equipment,
                      relevance
                      adequacies, etc`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent._7182WithoutTitle}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={"_7182WithoutTitle"}
                          />
                        </>
                      ),
                      column7: `Varied score from
                      5-0 depending on
                      sufficiency, relevance
                      and ambience`,
                    },
                  ], // end sub content
                },
                // end of 7.18

                // 7.19
                {
                  subtitleNorm: `7.19
                  Cosmetology`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `7.19.1
                      Availability of
                      cosmetology
                      laboratory`,
                      column5: `Evidence of
                      existence of
                      cosmetology
                      laboratory`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.availabilityOfCosmetologyLaboratory}
                            ViewDisable={"disabled"}
                            name={"availabilityOfCosmetologyLaboratory"}
                          />
                        </>
                      ),
                      column7: `Available is score of 5
                      and not available score
                      of 0`,
                    },
                    {
                      column4: `7.19.2`,
                      column5: `Availability
                      of facilities,
                      number of
                      equipment,
                      ambience etc`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent._7192WithoutTitle}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={"_7192WithoutTitle"}
                          />
                        </>
                      ),
                      column7: `Varied score from
                      5-0 depending on
                      sufficiency, relevance
                      and ambience`,
                    },
                  ], // end sub content
                },
                // end of 7.19

                // 7.20
                {
                  subtitleNorm: `7.20 Geology
                  laboratory`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `7.20.1
                      Availability of
                      survey and
                      geological
                      laboratory`,
                      column5: `Evidence
                      of a survey/
                      geological
                      laboratory`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.availabilityOfSurveyAndGeologicalLaboratory}
                            ViewDisable={"disabled"}
                            name={"availabilityOfSurveyAndGeologicalLaboratory"}
                          />
                        </>
                      ),
                      column7: `Available is score of 5
                      and not available score
                      of 0`,
                    },
                    {
                      column4: `7.20.2`,
                      column5: `Geological
                      laboratory with
                      the requisite
                      equipment,
                      ambience etc`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent._7202WithoutTitle}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={"_7202WithoutTitle"}
                          />
                        </>
                      ),
                      column7: `Varied score from
                      5-0 depending on
                      sufficiency, relevance
                      and ambience`,
                    },
                  ], // end sub content
                },
                // end of 7.20

                // 7.21
                {
                  subtitleNorm: `7.21 Museum`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `7.21.1
                      Availability of
                      Museum`,
                      column5: `Museum
                      available for the
                      purpose`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.availabilityOfMuseum}
                            ViewDisable={"disabled"}
                            name={"availabilityOfMuseum"}
                          />
                        </>
                      ),
                      column7: `Available is score of 5
                      and not available score
                      of 0`,
                    },
                    {
                      column4: `7.21.2`,
                      column5: `Museum with
                      relevant artifacts
                      and aspects to
                      be in a museum.
                      Ambience is
                      befitting`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent._7212WithoutTitle}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={"_7212WithoutTitle"}
                          />
                        </>
                      ),
                      column7: `Varied score from
                      5-0 depending on
                      sufficiency, relevance
                      and ambience`,
                    },
                  ], // end sub content
                },
                // end of 7.21
              ],
            },
          ],
        }, // end of 7

        {
          subData: [
            {
              number: `8`,
              subtitleStandard: `Teaching
              and Learning
              Resources`,
              subcontent: [
                // 7.1
                {
                  subtitleNorm: `8.1 Smart
                  Boards`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `8.1.1
                      Provision of
                      smart boards`,
                      column5: `Number
                      of smart
                      boards and
                      appropriateness`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.provisionOfSmartBoards}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={"provisionOfSmartBoards"}
                          />
                        </>
                      ),
                      column7: `Smart boards in all
                      lecture rooms (5), 65%
                      (4), 55% (3), 45% (2),
                      35% (1)`,
                    },
                  ], // end sub content
                },

                // 8.2
                {
                  subtitleNorm: `8.2 Internet
                  Availability`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `8.2.1 Internet
                      available`,
                      column5: `Evidence
                      of internet
                      availability`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.internetAvailability}
                            ViewDisable={"disabled"}
                            name={"internetAvailability"}
                          />
                        </>
                      ),
                      column7: `Internet available (5)
                      and not available is (0)`,
                    },
                    {
                      column4: `8.2.2 Internet
                      connectivity of
                      not less than
                      5mbps per
                      student`,
                      column5: `Check the
                      speed of the
                      internet`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.internetConnectivityOfNotLessThan5MBPSPerStudent}
                            ViewDisable={"disabled"}
                            name={
                              "internetConnectivityOfNotLessThan5MBPSPerStudent"
                            }
                          />
                        </>
                      ),
                      column7: `5mbps and above is
                      (5) and less than is (0)`,
                    },
                    {
                      column4: `8.2.3
                      Provides for
                      wifi and LAN`,
                      column5: `Evidence of
                      LAN and Wifi
                      connectivity`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.providesForWifiAndLAN}
                            ViewDisable={"disabled"}
                            name={"providesForWifiAndLAN"}
                          />
                        </>
                      ),
                      column7: `Existence of Wifi and
                      LAN connectivity is (5)
                      and none is (0)`,
                    },
                    {
                      column4: `8.2.4
                      Availability of
                      Computers
                      and ICT
                      equipment`,
                      column5: `Evidence and
                      number of
                      computers and
                      ICT equipment`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.availabilityOfComputersAndICTEquipment}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={"availabilityOfComputersAndICTEquipment"}
                          />
                        </>
                      ),
                      column7: `Acceptable ratio of
                      Computers to students
                      of 1:5 for (5), 1:8 (4),
                      1: 12 (3), 1:15 (2), 1:18
                      (1)`,
                    },
                  ], // end sub content
                },

                // 8.3
                {
                  subtitleNorm: `8.3 Books
                  and Library
                  resources
                  relevant to the
                  programme`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `8.3.1 Hard
                      copy books
                      relevant to the
                      programme`,
                      column5: `Number of
                      relevant book
                      titles and book
                      numbers. Ratio
                      of books to
                      students`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.hardCopyBooksRelevantToTheProgramme}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={"hardCopyBooksRelevantToTheProgramme"}
                          />
                        </>
                      ),
                      column7: `Minimum acceptable
                      ratio of 1:30. Adequate
                      and relevant books
                      Score of 0-5`,
                    },
                    {
                      column4: `8.3.2 Current
                      subscription
                      to
                      E-resources
                      databases`,
                      column5: `Count of
                      number of links
                      and databases
                      to e-resources`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.currentSubscriptionToERessourceDatabases}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={"currentSubscriptionToERessourceDatabases"}
                          />
                        </>
                      ),
                      column7: `Atleast 5 links score of
                      (5), 4 (4), 3 (3), 2(2),
                      1(1)`,
                    },
                    {
                      column4: `8.3.3 data
                      on statistics
                      of students
                      who access
                      e-resources`,
                      column5: `Establish
                      proportion
                      of students
                      who access
                      e-resources`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.dataOnStatisticsOfStudentsWhoAccessEResources}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={
                              "dataOnStatisticsOfStudentsWhoAccessEResources"
                            }
                          />
                        </>
                      ),
                      column7: `50% of student score
                      of (5), 40% (4), 30%
                      (3), 20% (2), 10% (1)`,
                    },
                  ], // end sub content
                },
                // end of 8.3
                // 8.4
                {
                  subtitleNorm: `8.4 University
                  Learning
                  Management
                  System`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `8.4.1
                      Availability
                      and functional
                      LMS`,
                      column5: `Evidence of
                      a customized
                      Learner
                      Management
                      System`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.availabilityandFunctionalLMS}
                            ViewDisable={"disabled"}
                            name={"availabilityandFunctionalLMS"}
                          />
                        </>
                      ),
                      column7: `Evidence of
                      customized LMS (5)
                      and not available is (0)`,
                    },
                    {
                      column4: `8.4.2
                      Availability
                      and
                      accessibility
                      of interactive
                      learning
                      content`,
                      column5: `Evidence
                      that the LMS
                      provides for
                      interactive
                      learning content`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.availabilityAndAccessibilityOfInteractiveLearningContent}
                            ViewDisable={"disabled"}
                            name={
                              "availabilityAndAccessibilityOfInteractiveLearningContent"
                            }
                          />
                        </>
                      ),
                      column7: `Evidence that LMS
                      provides for interactive
                      learning is (5) and not
                      available is (0)`,
                    },
                    {
                      column4: `8.4.3
                      Availability of
                      mechanism
                      to monitor
                      the online
                      assessment`,
                      column5: `Evidence of
                      monitoring
                      mechanism
                      for online
                      assessment`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.availabilityofMechanismToMonitorOnlineAssessment}
                            ViewDisable={"disabled"}
                            name={
                              "availabilityofMechanismToMonitorOnlineAssessment"
                            }
                          />
                        </>
                      ),
                      column7: `Evidence of monitoring
                      mechanism for online
                      assessment is (5) and
                      not available is (0)`,
                    },
                  ], // end sub content
                },
                // end 8.4

                // 8.5
                {
                  subtitleNorm: `8.5  Facilities
                  available
                  supports
                  blended learning`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `8.5.1
                      Appropriate
                      equipment
                      for blended
                      learning`,
                      column5: `List and count
                      the equipment
                      available for
                      blended learning
                      and how
                      appropriate they
                      are in terms of
                      relevance`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.appropriateEquipementForBlendedLearning}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={"appropriateEquipementForBlendedLearning"}
                          />
                        </>
                      ),
                      column7: `Varied score from
                      5-0 depending
                      number of equipment,
                      its adequacy and
                      relevance`,
                    },
                    {
                      column4: `8.5.2`,
                      column5: `Evidence of the
                      policy and its
                      implementation`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent._852withoutTitle}
                            ViewDisable={"disabled"}
                            name={"_852withoutTitle"}
                          />
                        </>
                      ),
                      column7: `Score of (5) for
                      available and being
                      implemented and (0)
                      for not being available`,
                    },
                    {
                      column4: `8.5.3
                      Availability
                      of ICT
                      infrastructure`,
                      column5: `Evidence of
                      availability,
                      variety and
                      relevance`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.availabilityOfICTInfrastructure}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={"availabilityOfICTInfrastructure"}
                          />
                        </>
                      ),
                      column7: `Varied score from
                      5-0 depending on
                      availability, variety,
                      adequacy, and
                      relevance`,
                    },
                    {
                      column4: `8.5.4
                      Availability of
                      ICT support
                      team.`,
                      column5: `Appointment
                      letter, evidence
                      of deployment,
                      office or
                      workspace`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.availabilityOfICTSupportTeam}
                            ViewDisable={"disabled"}
                            name={"availabilityOfICTSupportTeam"}
                          />
                        </>
                      ),
                      column7: `Score of (5) for
                      evidence available and
                      being implemented
                      and (0) for not being
                      available`,
                    },
                    {
                      column4: `8.5.5. Training
                      of the staff
                      pedagogically
                      in blended
                      learning.`,
                      column5: `Evidence
                      of training
                      provided,
                      attendance list,
                      training manual,
                      certificates`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.trainingOfTheStaffPedagogicallyInBlendedLearning}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={
                              "trainingOfTheStaffPedagogicallyInBlendedLearning"
                            }
                          />
                        </>
                      ),
                      column7: `75% of staff trained (5),
                      65% (4), 55% (3), 45%
                      (2), 35% (1)`,
                    },
                    {
                      column4: `8.5.6
                      Induction
                      of students
                      on Blended
                      learning`,
                      column5: `Evidence of
                      induction,
                      attendance
                      list, induction
                      materials/
                      manuals`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.inductionOfStudentOnBlendedLearning}
                            ViewDisable={"disabled"}
                            name={"inductionOfStudentOnBlendedLearning"}
                          />
                        </>
                      ),
                      column7: `Score of (5) for
                      evidence of induction
                      and (0) for not being
                      available`,
                    },
                  ], // end sub content
                },
                // end of 8.5
              ],
            },
          ],
        }, // end of 8

        {
          subData: [
            {
              number: `9`,
              subtitleStandard: `Students
              Assessment`,
              subcontent: [
                // 9.1
                {
                  subtitleNorm: `9.1 Approaches
                  adopted in
                  continuous
                  (formative) and
                  summative
                  assessment`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `9.1.1
                      Evidence of`,
                      column5: `Availability and
                      evidence of
                      implementation
                      of policy`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent._911AvailabilityOf}
                            ViewDisable={"disabled"}
                            name={"_911AvailabilityOf"}
                          />
                        </>
                      ),
                      column7: `Evidence of policy and
                      its implementation (5)
                      and not available is (0)`,
                    },
                    {
                      column4: `9.1.2
                      Assessment
                      methods
                      aligned to
                      the expected
                      learning
                      outcomes`,
                      column5: `Assessment
                      scripts,
                      verification of
                      ELOs`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.assementMethodsAlignedToTheExpectedLearningOutcomes}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={
                              "assementMethodsAlignedToTheExpectedLearningOutcomes"
                            }
                          />
                        </>
                      ),
                      column7: ` `,
                    },
                    {
                      column4: `9.1.3
                      Availability
                      of field work/
                      practical work/
                      simulation
                      work
                      assessment
                      rubrics`,
                      column5: `Evidence of
                      assessment
                      rubrics for field
                      work/simulation
                      work`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.availabilityOfFieldWorkPractivalWorkSimulationWorkAssessmentRubrics}
                            ViewDisable={"disabled"}
                            name={
                              "availabilityOfFieldWorkPractivalWorkSimulationWorkAssessmentRubrics"
                            }
                          />
                        </>
                      ),
                      column7: `Evidence of
                      assessment rubrics is
                      (5) and not available
                      is (0)`,
                    },
                  ], // end sub content
                },

                // 9.2
                {
                  subtitleNorm: `9.2 Approaches
                  to assessment
                  of digital/online
                  learning`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `9.2.1
                      Provision for
                      formative and
                      summative
                      assessment
                      online`,
                      column5: `Evidence
                      of provision
                      made for how
                      formative and
                      summative
                      assessment will
                      be conducted
                      online`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.provisionFormativeAndSummativeAssessmentOnline}
                            ViewDisable={"disabled"}
                            name={
                              "provisionFormativeAndSummativeAssessmentOnline"
                            }
                          />
                        </>
                      ),
                      column7: `Evidence of provision
                      made for formative
                      and summative
                      assessment online (5)
                      and not available is (0)`,
                    },
                    {
                      column4: `9.2.2
                      Provision for
                      integrity of the
                      assessment`,
                      column5: `Evidence of
                      provision made
                      to ensure
                      integrity of the
                      assessment,
                      say, face
                      identity, log -in
                      requirements,
                      random
                      questions, open
                      book, take
                      home etc`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.provisionForIntegrityOfTheAssessment}
                            ViewDisable={"disabled"}
                            name={"provisionForIntegrityOfTheAssessment"}
                          />
                        </>
                      ),
                      column7: `Evidence of provision
                      for integrity of
                      assessment (5) and
                      not available is (0)`,
                    },
                    {
                      column4: `9.2.3
                      Provision of
                      tool(s) that
                      measure
                      achievement
                      of ELOs`,
                      column5: `Evidence of
                      tools provided in
                      the programme
                      document`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.providesofToolsThatMeasureAchievementOfELOS}
                            ViewDisable={"disabled"}
                            name={"providesofToolsThatMeasureAchievementOfELOS"}
                          />
                        </>
                      ),
                      column7: `Evidence of tools (5)
                      and not available is (0)`,
                    },
                  ], // end sub content
                },

                // 9.3
                {
                  subtitleNorm: `9.3 Internal
                  and external
                  moderations of
                  examinations
                  and results`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `9.3.1 Availability of
                      guidelines on
                      moderations
                      of
                      examinations`,
                      column5: `Evidence of
                      guidelines on
                      moderations of
                      examinations`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.availabilityOfGuidelinesOnModerationsOfExaminantions}
                            ViewDisable={"disabled"}
                            name={
                              "availabilityOfGuidelinesOnModerationsOfExaminantions"
                            }
                          />
                        </>
                      ),
                      column7: `Evidence of guidelines
                      (5) and not available
                      is (0)`,
                    },
                    {
                      column4: `9.3.2
                      Availability of
                      moderation
                      reports
                      (internal and
                      external)`,
                      column5: `Evidence in
                      form of internal
                      and external
                      examinations
                      reports`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.availabilityOfModerationReportsInternalAndExternal}
                            ViewDisable={"disabled"}
                            name={
                              "availabilityOfModerationReportsInternalAndExternal"
                            }
                          />
                        </>
                      ),
                      column7: `Availability of reports
                      (5) and not available
                      is (0)`,
                    },
                  ], // end sub content
                },
                // end of 9.3
                // 9.4
                {
                  subtitleNorm: `9.4 Security of
                  examination`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `9.4.1`,
                      column5: `Evidence of
                      policy/guidelines
                      on management
                      of examinations`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent._941withoutTitle}
                            ViewDisable={"disabled"}
                            name={"_941withoutTitle"}
                          />
                        </>
                      ),
                      column7: `Availability of policy/
                      guidelines (5) and not
                      available is (0)`,
                    },
                  ], // end sub content
                },
                // end 8.4

                // 9.5
                {
                  subtitleNorm: `9.5 Internship/
                  Industrial
                  attachment`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `9.5.1`,
                      column5: `Evidence of
                      existence and
                      implementation
                      policy/guidelines
                      on internship/
                      industrial
                      attachment`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent._951withoutTitle}
                            ViewDisable={"disabled"}
                            name={"_951withoutTitle"}
                          />
                        </>
                      ),
                      column7: `Availability of policy/
                      guidelines (5) and not
                      available is (0)`,
                    },
                    {
                      column4: `9.5.2
                      Availability
                      of rubrics to
                      monitor and
                      assess the
                      internship/
                      industrial
                      attachment`,
                      column5: `Evidence
                      of rubric for
                      monitoring
                      and assessing
                      internship/
                      industrial
                      attachment`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.availabilityOfRubricsToMonitorAndAssessTheInternshipIndustrialAttachment}
                            ViewDisable={"disabled"}
                            name={
                              "availabilityOfRubricsToMonitorAndAssessTheInternshipIndustrialAttachment"
                            }
                          />
                        </>
                      ),
                      column7: `Availability of rubric (5)
                      and not available is (0)`,
                    },
                  ], // end sub content
                },
                // end of 9.5
              ],
            },
          ],
        }, // end of 9

        {
          subData: [
            {
              number: `10`,
              subtitleStandard: `Students
              Support
              System`,
              subcontent: [
                // 9.1
                {
                  subtitleNorm: `10.1 Provision
                  for students
                  tutor/mentor/
                  advisor`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `10.1.1
                      Availability of
                      the Policy and
                      procedures
                      on students’
                      support`,
                      column5: `Evidence of
                      policy and its
                      implementation`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.availabilityOfThePolicyAndProceduresOnStudentsSupport}
                            ViewDisable={"disabled"}
                            name={
                              "availabilityOfThePolicyAndProceduresOnStudentsSupport"
                            }
                          />
                        </>
                      ),
                      column7: `Availability of policy (5)
                      and not available is (0)`,
                    },
                    {
                      column4: `10.1.2 Time
                      (hours)
                      academic
                      staff have
                      allocated to
                      advisor`,
                      column5: `Amount of
                      time allocated
                      for advisory/
                      tutorship`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.timeHoursAcademicStaffHaveAllocatedToAdvisor}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={
                              "timeHoursAcademicStaffHaveAllocatedToAdvisor"
                            }
                          />
                        </>
                      ),
                      column7: ` `,
                    },
                    {
                      column4: `10.1.3
                      Appointment
                      of Student’s
                      tutor/mentor/
                      advisor`,
                      column5: `Appointment
                      letter/
                      deployment
                      letter etc`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.appoitmentOfStudentsTutorMentorAdvisor}
                            ViewDisable={"disabled"}
                            name={"appoitmentOfStudentsTutorMentorAdvisor"}
                          />
                        </>
                      ),
                      column7: `Availability of
                      appointment letters (5)
                      and not available is (0)`,
                    },
                  ], // end sub content
                },

                // 10.2
                {
                  subtitleNorm: `10.2 Programme
                  orientation `,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `10.2.1.`,
                      column5: `Evidence of
                      existence of
                      policy and its
                      implementation
                      on students’
                      orientation`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent._1021withoutTitle}
                            ViewDisable={"disabled"}
                            name={"_1021withoutTitle"}
                          />
                        </>
                      ),
                      column7: `Availability of policy (5)
                      and not available is (0)`,
                    },
                    {
                      column4: `10.2.2
                      Availability
                      of orientation
                      manual`,
                      column5: `Evidence of
                      orientation
                      manual`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.availabilityOfOrientationManual}
                            ViewDisable={"disabled"}
                            name={"availabilityOfOrientationManual"}
                          />
                        </>
                      ),
                      column7: `Availability of
                      orientation manual (5)
                      and not available is (0)`,
                    },
                  ], // end sub content
                },

                // 9.3
                {
                  subtitleNorm: `10.3 Co-
                  curriculum
                  Activities`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `10.3.1
                      Availability of
                      facilities for
                      co-curricular
                      activities.`,
                      column5: `Number of
                      facilities and
                      variety for
                      co-curriculum
                      activities`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.availabilityOfFacilitieForCoCurricularActivities}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={
                              "availabilityOfFacilitieForCoCurricularActivities"
                            }
                          />
                        </>
                      ),
                      column7: ` `,
                    },
                  ], // end sub content
                },
                // end of 9.3
                // 9.4
                {
                  subtitleNorm: `10.4 Counselling
                  services `,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `10.4.1`,
                      column5: `Evidence of
                      implementation
                      Counselling
                      policy`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent._1041withoutTitle}
                            ViewDisable={"disabled"}
                            name={"_1041withoutTitle"}
                          />
                        </>
                      ),
                      column7: `Availability of policy (5)
                      and not available is (0)`,
                    },
                    {
                      column4: `10.4.2
                      Evidence
                      of cases
                      handled per
                      semester.`,
                      column5: `Number of
                      cases handled
                      per semester`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.evidenceOfCasesHandledPerSemester}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={"evidenceOfCasesHandledPerSemester"}
                          />
                        </>
                      ),
                      column7: ` Atleast 5 cases (5), 4
                      (4), 3(3), 2(2) and 1(1)`,
                    },
                    {
                      column4: `10.4.3
                      Availability of
                      Counselling
                      services`,
                      column5: `Evidence of
                      counselling
                      services`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.availabilityOfCounsellingServices}
                            ViewDisable={"disabled"}
                            name={"availabilityOfCounsellingServices"}
                          />
                        </>
                      ),
                      column7: `Availability of
                      counselling services
                      (5) and not available
                      is (0)`,
                    },
                  ], // end sub content
                },
                // end 10.4

                // 10.5
                {
                  subtitleNorm: `10.5 Mentorship
                  programme`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `10.5.1
                      Availability of
                      mentorship
                      programme.`,
                      column5: `Evidence of
                      mentorship
                      programmes`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.availabilityOfMentorshipProgramme}
                            ViewDisable={"disabled"}
                            name={"availabilityOfMentorshipProgramme"}
                          />
                        </>
                      ),
                      column7: `Availability of
                      mentorship programme
                      (5) and not available
                      is (0)`,
                    },
                    {
                      column4: `10.5.2
                      Evidence of
                      mentorships
                      activities
                      conducted per
                      semester.`,
                      column5: `Number of
                      mentorship
                      activities
                      conducted`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.evidenceOfMentorshipActivitiesConductedPerSemester}
                            ViewDisable={"disabled"}
                            name={
                              "evidenceOfMentorshipActivitiesConductedPerSemester"
                            }
                          />
                        </>
                      ),
                      column7: ` `,
                    },
                  ], // end sub content
                },
                // end of 10.5

                // 10.6
                {
                  subtitleNorm: `10.6 Provision
                  of social
                  presence for
                  digital learners`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `10.6.1
                      Evidence of
                      past tasks/
                      activities
                      that promote
                      interaction
                      and sense of
                      community
                      among
                      learners`,
                      column5: `Activities
                      conducted
                      within the
                      community
                      to promote
                      community
                      transformation`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.evidenceOfPastTasksActivitiesThatPromiteInteractionAndSenseOfCommunityAMongLearners}
                            ViewDisable={"disabled"}
                            name={
                              "evidenceOfPastTasksActivitiesThatPromiteInteractionAndSenseOfCommunityAMongLearners"
                            }
                          />
                        </>
                      ),
                      column7: `Availability of reports
                      on activities (5) and not
                      available is (0)`,
                    },
                    {
                      column4: `10.5.2
                      Evidence of
                      mentorships
                      activities
                      conducted per
                      semester.`,
                      column5: `Number of
                      mentorship
                      activities
                      conducted`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.evidenceOfMentorshipActivitiesConductedPerSemester}
                            ViewDisable={"disabled"}
                            name={
                              "evidenceOfMentorshipActivitiesConductedPerSemester"
                            }
                          />
                        </>
                      ),
                      column7: ` `,
                    },
                  ], // end sub content
                },
                // end of 10.5

                // 10.7
                {
                  subtitleNorm: `10.7 Information
                    on students’
                    support made
                    available`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `10.7.1
                        Availability
                        of students’
                        handbook
                        on the
                        programme`,
                      column5: `Evidence of
                        handbook on
                        the programme`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.availabilityofStudentsHandBookOnTheProgramme}
                            ViewDisable={"disabled"}
                            name={
                              "availabilityofStudentsHandBookOnTheProgramme"
                            }
                          />
                        </>
                      ),
                      column7: `Availability of
                        handbook on
                        programmes (5) and
                        not available is (0)`,
                    },
                    {
                      column4: `10.7.2`,
                      column5: `Evidence of
                        communication
                        to students
                        using diverse
                        means`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent._1072withoutTitle}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={"_1072withoutTitle"}
                          />
                        </>
                      ),
                      column7: ` `,
                    },
                  ], // end sub content
                },
                // end of 10.7

                // 10.8
                {
                  subtitleNorm: `10.8
                  Scholarship/
                  financial support
                  services to
                  needy students`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `10.8.1
                      Availability
                      of financial
                      support
                      services`,
                      column5: `Evidence of
                      financial support
                      to needy
                      students`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.availabilityofFinancialSupportServices}
                            ViewDisable={"disabled"}
                            name={"availabilityofFinancialSupportServices"}
                          />
                        </>
                      ),
                      column7: `Availability of financial
                      support (5) and not
                      available is (0)`,
                    },
                    {
                      column4: `10.8.2
                      Procedure for
                      identification
                      and award`,
                      column5: `Clear procedure
                      for identification
                      of those who will
                      benefit`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.procedureForIndentificationAndAward}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={"procedureForIndentificationAndAward"}
                          />
                        </>
                      ),
                      column7: ` `,
                    },
                    {
                      column4: `10.8.3
                      Evidence
                      of students
                      benefiting`,
                      column5: `Number/list of
                      students that
                      have benefited`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.evidenceOfStudentsBenefiting}
                            ViewDisable={"disabled"}
                            name={"evidenceOfStudentsBenefiting"}
                          />
                        </>
                      ),
                      column7: `Availability of list of
                      beneficiaries (5) and
                      not available is (0)`,
                    },
                  ], // end sub content
                },
                // end of 10.8
              ],
            },
          ],
        }, // end of 10

        {
          subData: [
            {
              number: `11`,
              subtitleStandard: `Community
              services`,
              subcontent: [
                // 9.1
                {
                  subtitleNorm: `11.1 The
                  program has
                  policies and
                  strategies for
                  lecturers to
                  involve students
                  in community
                  service`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `11.1.1
                      Evidence of`,
                      column5: `Availability
                      of and
                      implementation
                      of Community
                      Services Policy`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent._1111evidenceOf}
                            ViewDisable={"disabled"}
                            name={"_1111evidenceOf"}
                          />
                        </>
                      ),
                      column7: `Availability of policy (5)
                      and not available is (0)`,
                    },
                    {
                      column4: `11.1.2
                      Availability of
                      community
                      services
                      report for the
                      last three
                      years`,
                      column5: `Evidence of
                      report(s)`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.availabilityOfCommunitySevicesRepportForLastThreeYears}
                            ViewDisable={"disabled"}
                            name={
                              "availabilityOfCommunitySevicesRepportForLastThreeYears"
                            }
                          />
                        </>
                      ),
                      column7: `Availability of reports
                      (5) and not available
                      is (0) `,
                    },
                  ], // end sub content
                },

                // 10.2
                {
                  subtitleNorm: `11.2 The
                  strategies are
                  reviewed and
                  improved every
                  five years `,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `11.2.1
                      Availability
                      of the last
                      review report`,
                      column5: `Evidence of
                      review reports`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.availabilityOfTheLAstReviewReport}
                            ViewDisable={"disabled"}
                            name={"availabilityOfTheLAstReviewReport"}
                          />
                        </>
                      ),
                      column7: `Availability of policy (5)
                      and not available is (0)`,
                    },
                  ], // end sub content
                },

                // 11.3
                {
                  subtitleNorm: `11.3 Reports
                  on impacts and
                  benefits of the
                  programmes
                  to the relevant
                  community/ies`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `11.3.1
                      Availability
                      of reports on
                      community
                      engagement`,
                      column5: `Reports on
                      community
                      engagement`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.availabilityOfReportsOnCommunityEngagement}
                            ViewDisable={"disabled"}
                            name={"availabilityOfReportsOnCommunityEngagement"}
                          />
                        </>
                      ),
                      column7: `Availability of reports
                      (5) and not available
                      is (0) `,
                    },
                    {
                      column4: `11.3.2
                      Availability
                      of a tool for
                      collecting
                      feedback from
                      communities
                      on the
                      programme`,
                      column5: `Evidence of
                      tools used to
                      obtain feedback`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.availabilityOfAToolForCollectingFeedbackFromCommunitiesAndTheProgramme}
                            ViewDisable={"disabled"}
                            name={
                              "availabilityOfAToolForCollectingFeedbackFromCommunitiesAndTheProgramme"
                            }
                          />
                        </>
                      ),
                      column7: `Availability of tools (5)
                      and not available is (0)`,
                    },
                    {
                      column4: `11.3.3
                      Availability
                      of feedback
                      reports
                      from the
                      community`,
                      column5: `Number of
                      feedback
                      reports`,
                      column6: (
                        <>
                          <InputNumber2
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.availabilityOfFeedbackReportsFromTheCommunity}
                            ViewDisable={"disabled"}
                            MessagePrint0={"0"}
                            MessagePrint1={"1"}
                            value0={0}
                            value1={1}
                            MessagePrint2={"2"}
                            MessagePrint3={"3"}
                            value2={2}
                            value3={3}
                            MessagePrint4={"4"}
                            MessagePrint5={"5"}
                            value4={4}
                            value5={5}
                            name={
                              "availabilityOfFeedbackReportsFromTheCommunity"
                            }
                          />
                        </>
                      ),
                      column7: ``,
                    },
                  ], // end sub content
                },
                // end of 11.3
              ],
            },
          ],
        }, // end of 11

        {
          subData: [
            {
              number: `12`,
              subtitleStandard: `Programme
              Review`,
              subcontent: [
                // 9.1
                {
                  subtitleNorm: `12.1 Processes
                  and procedures
                  of programme
                  review.`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `12.1.1`,
                      column5: `Evidence of
                      implementation
                      of programme
                      review`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent._1211withotTitle}
                            ViewDisable={"disabled"}
                            name={"_1211withotTitle"}
                          />
                        </>
                      ),
                      column7: `Availability of policy (5)
                      and not available is (0)`,
                    },
                    {
                      column4: `12.1.2
                      Utilization
                      of staff and
                      student
                      evaluation
                      reports`,
                      column5: `Evidence of
                      utilization of
                      staff and student
                      evaluation
                      reports`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.utilizationOfStaffAndStudentEvaluationReports}
                            ViewDisable={"disabled"}
                            name={
                              "utilizationOfStaffAndStudentEvaluationReports"
                            }
                          />
                        </>
                      ),
                      column7: `Availability of reports
                      (5) and not available
                      is (0) `,
                    },
                    {
                      column4: `12.1.3
                      Availability
                      of staff and
                      students'
                      evaluation
                      reports`,
                      column5: `Evidence of
                      evaluation
                      reports from
                      staff and
                      students on the
                      programme`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.availabilityOfStaffAndStudentsEvaluationReports}
                            ViewDisable={"disabled"}
                            name={
                              "availabilityOfStaffAndStudentsEvaluationReports"
                            }
                          />
                        </>
                      ),
                      column7: `Availability of reports
                      (5) and not available
                      is (0) `,
                    },
                  ], // end sub content
                },

                // 12.2
                {
                  subtitleNorm: `
                  12.2 Periodic
                  review of
                  programme`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `12.2.1
                      availability
                      Minutes/
                      reports of
                      programme
                      review.`,
                      column5: `Evidence of
                      minutes/reports`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.availabilityminutesReportsOfPRogrammeReview}
                            ViewDisable={"disabled"}
                            name={"availabilityminutesReportsOfPRogrammeReview"}
                          />
                        </>
                      ),
                      column7: `Availability of policy (5)
                      and not available is (0)`,
                    },
                    {
                      column4: `12.2.2
                      Reviewed and
                      approved/
                      accredited
                      programme`,
                      column5: `Evidence of
                      approval/
                      accreditation`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.reviewedAndApprouvedAccreditedProgramme}
                            ViewDisable={"disabled"}
                            name={"reviewedAndApprouvedAccreditedProgramme"}
                          />
                        </>
                      ),
                      column7: `Availability of policy (5)
                      and not available is (0)`,
                    },
                  ], // end sub content
                },

                // 12.3
                {
                  subtitleNorm: `12.3 Regular
                  tracer studies `,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `12.3.1`,
                      column5: `Evidence of
                      availability and
                      implementation
                      of tracer studies
                      policy/guidelines`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent._1231withoutTitle}
                            ViewDisable={"disabled"}
                            name={"_1231withoutTitle"}
                          />
                        </>
                      ),
                      column7: `Availability of reports
                      (5) and not available
                      is (0) `,
                    },
                    {
                      column4: `12.3.2
                      Availability of
                      tracer studies
                      survey reports`,
                      column5: `Tracer studies
                      reports`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.availabityOfTracerStudiesSurveyReports}
                            ViewDisable={"disabled"}
                            name={"availabityOfTracerStudiesSurveyReports"}
                          />
                        </>
                      ),
                      column7: `Availability of tools (5)
                      and not available is (0)`,
                    },
                    {
                      column4: `12.3.3`,
                      column5: `Evidence of
                      implementation
                      of`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent._1233withoutTitle}
                            ViewDisable={"disabled"}
                            name={"_1233withoutTitle"}
                          />
                        </>
                      ),
                      column7: `Availability of evidence
                      of implementation of
                      tracer reports (5) and
                      not available is (0)`,
                    },
                  ], // end sub content
                },
                // end of 12.3

                // 12.3
                {
                  subtitleNorm: `12.4 Procedures
                  and plans
                  for staff
                  development
                  based on
                  identified needs
                  and employer
                  satisfaction`,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Indicator</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Means of verification
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Level of Competence
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `12.4.1`,
                      column5: `Availability and
                      implementation
                      of staff
                      development
                      policy/guidelines`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent._1241withoutTitle}
                            ViewDisable={"disabled"}
                            name={"_1241withoutTitle"}
                          />
                        </>
                      ),
                      column7: `Availability of policy/
                      guidelines and its
                      implementation (5) and
                      not available is (0)`,
                    },
                    {
                      column4: `12.4.2.
                      Availability of
                      staff needs
                      assessment
                      reports`,
                      column5: `Evidence of
                      staff needs
                      assessment
                      report`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.availabityOfStaffNeedsAssessementReports}
                            ViewDisable={"disabled"}
                            name={"availabityOfStaffNeedsAssessementReports"}
                          />
                        </>
                      ),
                      column7: `Availability of tools (5)
                                  and not available is (0)`,
                    },
                    {
                      column4: `12.4.3
                      Employer
                      satisfaction
                      reports and
                      rates at 75 %
                      and above.`,
                      column5: `Evidence
                      of employer
                      satisfaction
                      report`,
                      column6: (
                        <>
                          <InputNumber1
                            MessagePrint1={"0"}
                            MessagePrint2={"5"}
                            value1={0}
                            value2={5}
                            numberSubmission = {numberSubmission}
                            ckecked={dataSent.employerSatisfactionReportsAndRatesAt75PercentAndAbove}
                            ViewDisable={"disabled"}
                            name={
                              "employerSatisfactionReportsAndRatesAt75PercentAndAbove"
                            }
                          />
                        </>
                      ),
                      column7: `Availability of reports
                      (5) and not available
                      is (0)`,
                    },
                  ], // end sub content
                },
                // end of 12.4
              ],
            },
          ],
        }, // end of 12
      ], // end content
    },
    // End of part 2
  ],
});
