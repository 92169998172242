import InputNumberForScoreReviewAssessors from "../smallComponents/InputNumberForScoreReviewAssessors";

export const siteVisiteInformationDataInputPreviewVizualization = (
  dataSent,
  numberSubmission
) => ({
  point1: [
    {
      bigHeader: "Site visit Comments",
      content: [
        {
          subData: [
            {
              number: ``,
              subtitleStandard: ``,
              subcontent: [
                {
                  subtitleNorm: ``,
                  dataContent: [ 
                    {  
                      column4: (
                        <span className="font-bold text-black">
                          Designation
                        </span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Marks
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">N/A</span>
                      ),
                         
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                      dataSent?.academicStaffingRatioNA ?  null : (
                    {  
                      column4: `5.1 Academic Staffing: Staff
                      Student ratio - Max 5`,
                      column5: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={5}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={dataSent.academicStaffingRatio}
                            placeholder={
                              "What is the Academic Staffing: Staff Student ratio ?"
                            }
                            name={"academicStaffingRatio"}
                          />
                        </>
                      ), 
                        column6: (
                        <span className="font-bold text-black">
                          {" "}
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${
                                (dataSent?.academicStaffingRatioNA ? 'N/A' : 'Applicable') }`,
                            }}
                          />
                        </span>
                      ),
                      column7: (
                        <label
                          className="block mb-2"
                          dangerouslySetInnerHTML={{
                            __html: `${dataSent?.commentacademicStaffingRatio?.replace(
                              /\n/g,
                              "<br />"
                            ) || 'No comment'}`,
                          }}
                        />
                      ),
                    }),

                      dataSent?.academicStaffingMinimumNumberOfAcademicStaffForAProgrammeNA ?  null : (
                    {  
                      column4: `5.2 Academic Staffing
                      Minimum number of
                      academic staff for a
                      programme – Max 10`,
                      column5: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={10}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={
                              dataSent.academicStaffingMinimumNumberOfAcademicStaffForAProgramme
                            }
                            placeholder={
                              "What Is the Academic Staffing Minimum number of academic staff for a programme ?"
                            }
                            name={
                              "academicStaffingMinimumNumberOfAcademicStaffForAProgramme"
                            }
                          />
                        </>
                      ),
                        column6: (
                        <span className="font-bold text-black">
                          {" "}
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${
                                (dataSent?.academicStaffingMinimumNumberOfAcademicStaffForAProgrammeNA ? 'N/A' : 'Applicable') }`,
                            }}
                          />
                        </span>
                      ),
                      column7: (
                        <label
                          className="block mb-2"
                          dangerouslySetInnerHTML={{
                            __html: `${dataSent?.commentacademicStaffingMinimumNumberOfAcademicStaffForAProgramme?.replace(
                              /\n/g,
                              "<br />"
                            ) || 'No comment'}`,
                          }}
                        />
                      ),
                    }),

                      dataSent?.academicStaffingQualificationsOfCoreStaffRelatedToTheProgrammeNA ?  null : (
                    {  
                      column4: `5.3 Academic Staffing
                      Qualifications of core staff
                      related to the programme –
                      Max 10`,
                      column5: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={10}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={
                              dataSent.academicStaffingQualificationsOfCoreStaffRelatedToTheProgramme
                            }
                            placeholder={
                              "Is the academic staffing qualitfications of core staff related to the programme?"
                            }
                            name={
                              "academicStaffingQualificationsOfCoreStaffRelatedToTheProgramme"
                            }
                          />
                        </>
                      ),
                        column6: (
                        <span className="font-bold text-black">
                          {" "}
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${
                                (dataSent?.academicStaffingQualificationsOfCoreStaffRelatedToTheProgrammeNA ? 'N/A' : 'Applicable') }`,
                            }}
                          />
                        </span>
                      ),
                      column7: (
                        <label
                          className="block mb-2"
                          dangerouslySetInnerHTML={{
                            __html: `${dataSent?.commentacademicStaffingQualificationsOfCoreStaffRelatedToTheProgramme?.replace(
                              /\n/g,
                              "<br />"
                            ) || 'No comment'}`,
                          }}
                        />
                      ),
                    }),
                      dataSent?.AcademicStaffingProportionofFullTimetoPartTimeStaffNA ?  null : (
                    {  
                      column4: `5.4 Academic Staffing
                      Proportion of Full Time to
                      Part Time Staff – Max 5`,
                      column5: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={5}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={
                              dataSent.AcademicStaffingProportionofFullTimetoPartTimeStaff
                            }
                            placeholder={
                              "Is the Academic Staffing Proportion of Full Time to Part Time Staff ?"
                            }
                            name={
                              "AcademicStaffingProportionofFullTimetoPartTimeStaff"
                            }
                          />
                        </>
                      ),
                        column6: (
                        <span className="font-bold text-black">
                          {" "}
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${
                                (dataSent?.AcademicStaffingProportionofFullTimetoPartTimeStaffNA ? 'N/A' : 'Applicable') }`,
                            }}
                          />
                        </span>
                      ),
                      column7: (
                        <label
                          className="block mb-2"
                          dangerouslySetInnerHTML={{
                            __html: `${dataSent?.commentAcademicStaffingProportionofFullTimetoPartTimeStaff?.replace(
                              /\n/g,
                              "<br />"
                            ) || 'No comment'}`,
                          }}
                        />
                      ),
                    }),

                      dataSent?.academicStaffingTeachingExperienceNA ?  null : (
                    {  
                      column4: `5.5 Academic Staffing
                      Teaching experience – Max
                      5`,
                      column5: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={5}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={
                              dataSent.academicStaffingTeachingExperience
                            }
                            placeholder={
                              "What Is the Academic Staffing Teaching experience ?"
                            }
                            name={"academicStaffingTeachingExperience"}
                          />
                        </>
                      ),
                        column6: (
                        <span className="font-bold text-black">
                          {" "}
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${
                                (dataSent?.academicStaffingTeachingExperienceNA ? 'N/A' : 'Applicable') }`,
                            }}
                          />
                        </span>
                      ),
                      column7: (
                        <label
                          className="block mb-2"
                          dangerouslySetInnerHTML={{
                            __html: `${dataSent?.commentAcademicStaffingTeachingExperience?.replace(
                              /\n/g,
                              "<br />"
                            ) || 'No comment'}`,
                          }}
                        />
                      ),
                    }),

                      dataSent?.academicStaffingWorkloadNA ?  null : (
                    {  
                      column4: `5.6 Academic Staffing
                      Workload/Contact hours –
                      Max 5`,
                      column5: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={5}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={dataSent.academicStaffingWorkload}
                            placeholder={
                              "What Is the Academic Staffing Workload ?"
                            }
                            name={"academicStaffingWorkload"}
                          />
                        </>
                      ),
                        column6: (
                        <span className="font-bold text-black">
                          {" "}
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${
                                (dataSent?.academicStaffingWorkloadNA ? 'N/A' : 'Applicable') }`,
                            }}
                          />
                        </span>
                      ),
                      column7: (
                        <label
                          className="block mb-2"
                          dangerouslySetInnerHTML={{
                            __html: `${dataSent?.commentacademicStaffingWorkload?.replace(
                              /\n/g,
                              "<br />"
                            ) || 'No comment'}`,
                          }}
                        />
                      ),
                    }),

                      dataSent?.AcademicStaffingTrainingInPedagogyNA ?  null : (
                    {  
                      column4: `5.7 Academic Staffing
                      Training in pedagogy – Max
                      5`,
                      column5: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={5}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={
                              dataSent.AcademicStaffingTrainingInPedagogy
                            }
                            placeholder={
                              "What Is the Academic Staffing Training in pedagogy?"
                            }
                            name={"AcademicStaffingTrainingInPedagogy"}
                          />
                        </>
                      ),
                        column6: (
                        <span className="font-bold text-black">
                          {" "}
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${
                                (dataSent?.AcademicStaffingTrainingInPedagogyNA ? 'N/A' : 'Applicable') }`,
                            }}
                          />
                        </span>
                      ),
                      column7: (
                        <label
                          className="block mb-2"
                          dangerouslySetInnerHTML={{
                            __html: `${dataSent?.commentAcademicStaffingTrainingInPedagogy?.replace(
                              /\n/g,
                              "<br />"
                            ) || 'No comment'}`,
                          }}
                        />
                      ),
                    }),

                      dataSent?.AcademicStaffingProvisionofRegularCPDNA ?  null : (
                    {  
                      column4: `5.8 Academic Staffing
                      Provision of regular CPD
                      (where applicable) – Max 5`,
                      column5: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={10}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={
                              dataSent.AcademicStaffingProvisionofRegularCPD
                            }
                            placeholder={
                              "What Is the Academic Staffing Provision of regular CPD ?"
                            }
                            name={"AcademicStaffingProvisionofRegularCPD"}
                          />
                        </>
                      ),
                        column6: (
                        <span className="font-bold text-black">
                          {" "}
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${
                                (dataSent?.AcademicStaffingProvisionofRegularCPDNA ? 'N/A' : 'Applicable') }`,
                            }}
                          />
                        </span>
                      ),
                      column7: (
                        <label
                          className="block mb-2"
                          dangerouslySetInnerHTML={{
                            __html: `${dataSent?.commentAcademicStaffingProvisionofRegularCPD?.replace(
                              /\n/g,
                              "<br />"
                            )|| 'No comment'}`,
                          }}
                        />
                      ),
                    }),

                      dataSent?.AcademicStaffingDeploymentallocationOfStaffNA ?  null : (
                    {  
                      column4: `5.9 Academic Staffing
                      Deployment && allocation of
                      staff – Max 20`,
                      column5: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={20}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={
                              dataSent.AcademicStaffingDeploymentallocationOfStaff
                            }
                            placeholder={
                              "What is the Academic Staffing Deployment allocation of staff  ?"
                            }
                            name={"AcademicStaffingDeploymentallocationOfStaff"}
                          />
                        </>
                      ),
                        column6: (
                        <span className="font-bold text-black">
                          {" "}
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${
                                (dataSent?.AcademicStaffingDeploymentallocationOfStaffNA ? 'N/A' : 'Applicable') }`,
                            }}
                          />
                        </span>
                      ),
                      column7: (
                        <label
                          className="block mb-2"
                          dangerouslySetInnerHTML={{
                            __html: `${dataSent?.commentAcademicStaffingDeploymentallocationOfStaff?.replace(
                              /\n/g,
                              "<br />"
                            ) || 'No comment'}`,
                          }}
                        />
                      ),
                    }),

                      dataSent?.AcademicStaffingStaffPublicationNA ?  null : (
                    {  
                      column4: `5.10 Academic Staffing
                      Staff publication – Max 5`,
                      column5: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={5}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={dataSent.AcademicStaffingStaffPublication}
                            placeholder={"Academic Staffing Staff publication?"}
                            name={"AcademicStaffingStaffPublication"}
                          />
                        </>
                      ),
                        column6: (
                        <span className="font-bold text-black">
                          {" "}
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${
                                (dataSent?.AcademicStaffingStaffPublicationNA ? 'N/A' : 'Applicable') }`,
                            }}
                          />
                        </span>
                      ),
                      column7: (
                        <label
                          className="block mb-2"
                          dangerouslySetInnerHTML={{
                            __html: `${dataSent?.commentAcademicStaffingStaffPublication?.replace(
                              /\n/g,
                              "<br />"
                            ) || 'No comment'}`,
                          }}
                        />
                      ),
                    }),

                      dataSent?.InnovationsbyStaffNA ?  null : (
                    {  
                      column4: `5.11 Innovations by staff –
                      Max 5`,
                      column5: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={5}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={dataSent.InnovationsbyStaff}
                            placeholder={"What Is the Innovations by staff?"}
                            name={"InnovationsbyStaff"}
                          />
                        </>
                      ),
                        column6: (
                        <span className="font-bold text-black">
                          {" "}
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${
                                (dataSent?.InnovationsbyStaffNA ? 'N/A' : 'Applicable') }`,
                            }}
                          />
                        </span>
                      ),
                      column7: (
                        <label
                          className="block mb-2"
                          dangerouslySetInnerHTML={{
                            __html: `${dataSent?.commentInnovationsbyStaff?.replace(
                              /\n/g,
                              "<br />"
                            ) || 'No comment'}`,
                          }}
                        />
                      ),
                    }),

                      dataSent?.AcademicStaffingPatentcopyRightsNA ?  null : (
                    {  
                      column4: `5.12 Academic Staffing
                      Patent && copy rights – Max 5`,
                      column5: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={15}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={dataSent.AcademicStaffingPatentcopyRights}
                            placeholder={
                              "Academic Staffing  Patent  copy rights?"
                            }
                            name={"AcademicStaffingPatentcopyRights"}
                          />
                        </>
                      ),
                        column6: (
                        <span className="font-bold text-black">
                          {" "}
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${
                                (dataSent?.AcademicStaffingPatentcopyRightsNA ? 'N/A' : 'Applicable') }`,
                            }}
                          />
                        </span>
                      ),
                      column7: (
                        <label
                          className="block mb-2"
                          dangerouslySetInnerHTML={{
                            __html: `${dataSent?.commentAcademicStaffingPatentcopyRights?.replace(
                              /\n/g,
                              "<br />"
                            ) || 'No comment'}`,
                          }}
                        />
                      ),
                    }),

                      dataSent?.GovernanceAdministrationHeadofDepartmentNA ?  null : (
                    {  
                      column4: `6.1 Governance &&
                      Administration
                      Head of Department – Max
                      10`,
                      column5: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={10}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={
                              dataSent.GovernanceAdministrationHeadofDepartment
                            }
                            placeholder={
                              "Governance && Administration Head of Department?"
                            }
                            name={"GovernanceAdministrationHeadofDepartment"}
                          />
                        </>
                      ),
                        column6: (
                        <span className="font-bold text-black">
                          {" "}
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${
                                (dataSent?.GovernanceAdministrationHeadofDepartmentNA ? 'N/A' : 'Applicable') }`,
                            }}
                          />
                        </span>
                      ),
                      column7: (
                        <label
                          className="block mb-2"
                          dangerouslySetInnerHTML={{
                            __html: `${dataSent?.commentGovernanceAdministrationHeadofDepartment?.replace(
                              /\n/g,
                              "<br />"
                            ) || 'No comment'}`,
                          }}
                        />
                      ),
                    }),
                      dataSent?.GovernanceAdministrationCoordinationoftheprogrammeacademicleadercoordinatorNA ?  null : (
                    {  
                      column4: `6.2 Governance &&
                      Administration
                      Coordination of the programme academic
                      leader/coordinator – Max 10`,
                      column5: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={10}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={
                              dataSent.GovernanceAdministrationCoordinationoftheprogrammeacademicleadercoordinator
                            }
                            placeholder={
                              "Governance && Administration Coordination of the programme academic leader/coordinator?"
                            }
                            name={
                              "GovernanceAdministrationCoordinationoftheprogrammeacademicleadercoordinator"
                            }
                          />
                        </>
                      ),
                        column6: (
                        <span className="font-bold text-black">
                          {" "}
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${
                                (dataSent?.GovernanceAdministrationCoordinationoftheprogrammeacademicleadercoordinatorNA ? 'N/A' : 'Applicable') }`,
                            }}
                          />
                        </span>
                      ),
                      column7: (
                        <label
                          className="block mb-2"
                          dangerouslySetInnerHTML={{
                            __html: `${dataSent?.commentGovernanceAdministrationCoordinationoftheprogrammeacademicleadercoordinator?.replace(
                              /\n/g,
                              "<br />"
                            ) || 'No comment'}`,
                          }}
                        />
                      ),
                    }),
                      dataSent?.GovernanceAdministrationQualityAssurancePolicyNA ?  null : (
                    {  
                      column4: `6.3 Governance &&
                      Administration
                      Quality Assurance Policy –
                      Max 10`,
                      column5: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={10}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={
                              dataSent.GovernanceAdministrationQualityAssurancePolicy
                            }
                            placeholder={
                              "Governance &&  Administration Quality Assurance Policy?"
                            }
                            name={
                              "GovernanceAdministrationQualityAssurancePolicy"
                            }
                          />
                        </>
                      ),
                        column6: (
                        <span className="font-bold text-black">
                          {" "}
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${
                                (dataSent?.GovernanceAdministrationQualityAssurancePolicyNA ? 'N/A' : 'Applicable') }`,
                            }}
                          />
                        </span>
                      ),
                      column7: (
                        <label
                          className="block mb-2"
                          dangerouslySetInnerHTML={{
                            __html: `${dataSent?.commentGovernanceAdministrationQualityAssurancePolicy?.replace(
                              /\n/g,
                              "<br />"
                            ) || 'No comment'}`,
                          }}
                        />
                      ),
                    }),
                      dataSent?.GovernanceAdministrationInstitutionalprogrammequalityAssuranceMechanismNA ?  null : (
                    {  
                      column4: `6.4 Governance &&
                      Administration
                      Institutional programme
                      quality Assurance Mechanism
                      – Max 10`,
                      column5: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={10}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={
                              dataSent.GovernanceAdministrationInstitutionalprogrammequalityAssuranceMechanism
                            }
                            placeholder={
                              "Governance && Administration Institutional programme quality Assurance Mechanism ?"
                            }
                            name={
                              "GovernanceAdministrationInstitutionalprogrammequalityAssuranceMechanism"
                            }
                          />
                        </>
                      ),
                        column6: (
                        <span className="font-bold text-black">
                          {" "}
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${
                                (dataSent?.GovernanceAdministrationInstitutionalprogrammequalityAssuranceMechanismNA ? 'N/A' : 'Applicable') }`,
                            }}
                          />
                        </span>
                      ),
                      column7: (
                        <label
                          className="block mb-2"
                          dangerouslySetInnerHTML={{
                            __html: `${dataSent?.commentGovernanceAdministrationInstitutionalprogrammequalityAssuranceMechanism?.replace(
                              /\n/g,
                              "<br />"
                            ) || 'No comment'}`,
                          }}
                        />
                      ),
                    }),
                      dataSent?.GovernanceAdministrationQualityAssurancePolicyNA ?  null : (
                    {  
                      column4: `6.5 Governance &&
                      Administration Quality Assurance Policy –
                      Max 30`,
                      column5: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={15}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={
                              dataSent.GovernanceAdministrationQualityAssurancePolicy
                            }
                            placeholder={
                              "Governance && Administration Quality Assurance Policy ?"
                            }
                            name={
                              "GovernanceAdministrationQualityAssurancePolicy"
                            }
                          />
                        </>
                      ),
                        column6: (
                        <span className="font-bold text-black">
                          {" "}
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${
                                (dataSent?.GovernanceAdministrationQualityAssurancePolicyNA ? 'N/A' : 'Applicable') }`,
                            }}
                          />
                        </span>
                      ),
                      column7: (
                        <label
                          className="block mb-2"
                          dangerouslySetInnerHTML={{
                            __html: `${dataSent?.commentGovernanceAdministrationQualityAssurancePolicy?.replace(
                              /\n/g,
                              "<br />"
                            ) || 'No comment'}`,
                          }}
                        />
                      ),
                    }),
                      dataSent?.GovernanceAdministrationInstitutionalpolicyonprogrammeconnectednesswiththeworldofpracticewiththeworldofworkNA ?  null : (
                    {  
                      column4: `6.6 Governance &&
                      Administration
                      Institutional policy on
                      programme connectedness
                      with the world of practice
                      with the world of work –
                      Max 20`,
                      column5: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={20}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={
                              dataSent.GovernanceAdministrationInstitutionalpolicyonprogrammeconnectednesswiththeworldofpracticewiththeworldofwork
                            }
                            placeholder={
                              "Governance && Administration Institutional policy on programme connectedness  with the world of practice with the world of work"
                            }
                            name={
                              "GovernanceAdministrationInstitutionalpolicyonprogrammeconnectednesswiththeworldofpracticewiththeworldofwork"
                            }
                          />
                        </>
                      ),
                        column6: (
                        <span className="font-bold text-black">
                          {" "}
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${
                                (dataSent?.GovernanceAdministrationInstitutionalpolicyonprogrammeconnectednesswiththeworldofpracticewiththeworldofworkNA ? 'N/A' : 'Applicable') }`,
                            }}
                          />
                        </span>
                      ),
                      column7: (
                        <label
                          className="block mb-2"
                          dangerouslySetInnerHTML={{
                            __html: `${dataSent?.commentGovernanceAdministrationInstitutionalpolicyonprogrammeconnectednesswiththeworldofpracticewiththeworldofwork?.replace(
                              /\n/g,
                              "<br />"
                            ) || 'No comment'}`,
                          }}
                        />
                      ),
                    }),
                      dataSent?.InfrastructurefacilitiesLectureroomspaceNA ?  null : (
                    {  
                      column4: `7.1 Infrastructure facilities:
                      Lecture room space – Max
                      15`,
                      column5: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={15}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={
                              dataSent.InfrastructurefacilitiesLectureroomspace
                            }
                            placeholder={
                              "Infrastructure facilities: Lecture room space?"
                            }
                            name={"InfrastructurefacilitiesLectureroomspace"}
                          />
                        </>
                      ),
                        column6: (
                        <span className="font-bold text-black">
                          {" "}
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${
                                (dataSent?.InfrastructurefacilitiesLectureroomspaceNA ? 'N/A' : 'Applicable') }`,
                            }}
                          />
                        </span>
                      ),
                      column7: (
                        <label
                          className="block mb-2"
                          dangerouslySetInnerHTML={{
                            __html: `${dataSent?.commentInfrastructurefacilitiesLectureroomspace?.replace(
                              /\n/g,
                              "<br />"
                            ) || 'No comment'}`,
                          }}
                        />
                      ),
                    }),
                      dataSent?.InfrastructureFacilitiesLibraryspaceNA ?  null : (
                    {  
                      column4: `7.2 Infrastructure facilities:
                      Library space – Max 10`,
                      column5: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={10}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={
                              dataSent.InfrastructureFacilitiesLibraryspace
                            }
                            placeholder={
                              "Infrastructure facilities:  Library space?"
                            }
                            name={"InfrastructureFacilitiesLibraryspace"}
                          />
                        </>
                      ),
                        column6: (
                        <span className="font-bold text-black">
                          {" "}
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${
                                (dataSent?.InfrastructureFacilitiesLibraryspaceNA ? 'N/A' : 'Applicable') }`,
                            }}
                          />
                        </span>
                      ),
                      column7: (
                        <label
                          className="block mb-2"
                          dangerouslySetInnerHTML={{
                            __html: `${dataSent?.commentInfrastructureFacilitiesLibraryspace?.replace(
                              /\n/g,
                              "<br />"
                            ) || 'No comment'}`,
                          }}
                        />
                      ),
                    }),
                      dataSent?.InfrastructureFacilitiesICTLaboratoiresspaceNA ?  null : (
                    {  
                      column4: `7.3 Infrastructure Facilities:
                      ICT Laboratoires space –
                      Max 10`,
                      column5: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={10}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={
                              dataSent.InfrastructureFacilitiesICTLaboratoiresspace
                            }
                            placeholder={
                              "Infrastructure Facilities:  ICT Laboratoires space?"
                            }
                            name={
                              "InfrastructureFacilitiesICTLaboratoiresspace"
                            }
                          />
                        </>
                      ),
                        column6: (
                        <span className="font-bold text-black">
                          {" "}
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${
                                (dataSent?.InfrastructureFacilitiesICTLaboratoiresspaceNA ? 'N/A' : 'Applicable') }`,
                            }}
                          />
                        </span>
                      ),
                      column7: (
                        <label
                          className="block mb-2"
                          dangerouslySetInnerHTML={{
                            __html: `${dataSent?.commentInfrastructureFacilitiesICTLaboratoiresspace?.replace(
                              /\n/g,
                              "<br />"
                            ) || 'No comment'}`,
                          }}
                        />
                      ),
                    }),
                      dataSent?.InfrastructurefacilitiesSeminarRoomsNA ?  null : (
                    {  
                      column4: `7.4 Infrastructure facilities:
                      Seminar rooms – Max 10`,
                      column5: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={10}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={
                              dataSent.InfrastructurefacilitiesSeminarRooms
                            }
                            placeholder={
                              "Infrastructure facilities:  Seminar rooms?"
                            }
                            name={"InfrastructurefacilitiesSeminarRooms"}
                          />
                        </>
                      ),
                        column6: (
                        <span className="font-bold text-black">
                          {" "}
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${
                                (dataSent?.InfrastructurefacilitiesSeminarRoomsNA ? 'N/A' : 'Applicable') }`,
                            }}
                          />
                        </span>
                      ),
                      column7: (
                        <label
                          className="block mb-2"
                          dangerouslySetInnerHTML={{
                            __html: `${dataSent?.commentInfrastructurefacilitiesSeminarRooms?.replace(
                              /\n/g,
                              "<br />"
                            ) || 'No comment'}`,
                          }}
                        />
                      ),
                    }),
                      dataSent?.InfrastructurefacilitiesStudiospaceNA ?  null : (
                    {  
                      column4: `7.5 ** Infrastructure
                      facilities: Studio space – Max
                      10`,
                      column5: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={10}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={
                              dataSent.InfrastructurefacilitiesStudiospace
                            }
                            placeholder={
                              "Infrastructure facilities: Studio space?"
                            }
                            name={"InfrastructurefacilitiesStudiospace"}
                          />
                        </>
                      ),
                        column6: (
                        <span className="font-bold text-black">
                          {" "}
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${
                                (dataSent?.InfrastructurefacilitiesStudiospaceNA ? 'N/A' : 'Applicable') }`,
                            }}
                          />
                        </span>
                      ),
                      column7: (
                        <label
                          className="block mb-2"
                          dangerouslySetInnerHTML={{
                            __html: `${dataSent?.commentInfrastructurefacilitiesStudiospace?.replace(
                              /\n/g,
                              "<br />"
                            ) || 'No comment'}`,
                          }}
                        />
                      ),
                    }),
                      dataSent?.InfrastructurefacilitiesOfficespaceNA ?  null : (
                    {  
                      column4: `7.6 Infrastructure facilities:
                      Office space – Max 10`,
                      column5: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={15}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={
                              dataSent.InfrastructurefacilitiesOfficespace
                            }
                            placeholder={
                              "Infrastructure facilities: Office space?"
                            }
                            name={"InfrastructurefacilitiesOfficespace"}
                          />
                        </>
                      ),
                        column6: (
                        <span className="font-bold text-black">
                          {" "}
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${
                                (dataSent?.InfrastructurefacilitiesOfficespaceNA ? 'N/A' : 'Applicable') }`,
                            }}
                          />
                        </span>
                      ),
                      column7: (
                        <label
                          className="block mb-2"
                          dangerouslySetInnerHTML={{
                            __html: `${dataSent?.commentInfrastructurefacilitiesOfficespace?.replace(
                              /\n/g,
                              "<br />"
                            ) || 'No comment'}`,
                          }}
                        />
                      ),
                    }),
                      dataSent?.InfrastructurefacilitiesSpaceforbasicsciencelaboratoriesNA ?  null : (
                    {  
                      column4: `7.7 Infrastructure facilities:
                      Space for basic science
                      laboratories – Max 10`,
                      column5: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={10}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={
                              dataSent.InfrastructurefacilitiesSpaceforbasicsciencelaboratories
                            }
                            placeholder={
                              "Infrastructure facilities: Space for basic science laboratories?"
                            }
                            name={
                              "InfrastructurefacilitiesSpaceforbasicsciencelaboratories"
                            }
                          />
                        </>
                      ),
                        column6: (
                        <span className="font-bold text-black">
                          {" "}
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${
                                (dataSent?.InfrastructurefacilitiesSpaceforbasicsciencelaboratoriesNA ? 'N/A' : 'Applicable') }`,
                            }}
                          />
                        </span>
                      ),
                      column7: (
                        <label
                          className="block mb-2"
                          dangerouslySetInnerHTML={{
                            __html: `${dataSent?.commentInfrastructurefacilitiesSpaceforbasicsciencelaboratories?.replace(
                              /\n/g,
                              "<br />"
                            ) || 'No comment'}`,
                          }}
                        />
                      ),
                    }),
                      dataSent?.InfrastructurefacilitiesHumanMedicinelaboratoriesNA ?  null : (
                    {  
                      column4: `7.8 Infrastructure facilities:
                      Human Medicine laboratories 
                      – Max 45`,
                      column5: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={45}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={
                              dataSent.InfrastructurefacilitiesHumanMedicinelaboratories
                            }
                            placeholder={
                              "Infrastructure facilities: Human Medicine laboratories?"
                            }
                            name={
                              "InfrastructurefacilitiesHumanMedicinelaboratories"
                            }
                          />
                        </>
                      ),
                        column6: (
                        <span className="font-bold text-black">
                          {" "}
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${
                                (dataSent?.InfrastructurefacilitiesHumanMedicinelaboratoriesNA ? 'N/A' : 'Applicable') }`,
                            }}
                          />
                        </span>
                      ),
                      column7: (
                        <label
                          className="block mb-2"
                          dangerouslySetInnerHTML={{
                            __html: `${dataSent?.commentInfrastructurefacilitiesHumanMedicinelaboratories?.replace(
                              /\n/g,
                              "<br />"
                            ) || 'No comment'}`,
                          }}
                        />
                      ),
                    }),
                      dataSent?.InfrastructurefacilitiesDentalMedicineLaboratoriesNA ?  null : (
                    {  
                      column4: `7.9 Infrastructure facilities:
                      Dental Medicine Laboratories
                      – Max 20`,
                      column5: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={20}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={
                              dataSent.InfrastructurefacilitiesDentalMedicineLaboratories
                            }
                            placeholder={
                              "Infrastructure facilities: Dental Medicine Laboratories?"
                            }
                            name={
                              "InfrastructurefacilitiesDentalMedicineLaboratories"
                            }
                          />
                        </>
                      ),
                        column6: (
                        <span className="font-bold text-black">
                          {" "}
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${
                                (dataSent?.InfrastructurefacilitiesDentalMedicineLaboratoriesNA ? 'N/A' : 'Applicable') }`,
                            }}
                          />
                        </span>
                      ),
                      column7: (
                        <label
                          className="block mb-2"
                          dangerouslySetInnerHTML={{
                            __html: `${dataSent?.commentInfrastructurefacilitiesDentalMedicineLaboratories?.replace(
                              /\n/g,
                              "<br />"
                            ) || 'No comment'}`,
                          }}
                        />
                      ),
                    }),
                      dataSent?.InfrastructurefacilitiesSkillsLaboratoriesforNursingMidwiferyNA ?  null : (
                    {  
                      column4: `7.10 Infrastructure facilities:
                      Skills Laboratories for
                      Nursing/Midwifery – Max 10`,
                      column5: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={10}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={
                              dataSent.InfrastructurefacilitiesSkillsLaboratoriesforNursingMidwifery
                            }
                            placeholder={
                              "Infrastructure facilities: Skills Laboratories for Nursing/Midwifery?"
                            }
                            name={
                              "InfrastructurefacilitiesSkillsLaboratoriesforNursingMidwifery"
                            }
                          />
                        </>
                      ),
                        column6: (
                        <span className="font-bold text-black">
                          {" "}
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${
                                (dataSent?.InfrastructurefacilitiesSkillsLaboratoriesforNursingMidwiferyNA ? 'N/A' : 'Applicable') }`,
                            }}
                          />
                        </span>
                      ),
                      column7: (
                        <label
                          className="block mb-2"
                          dangerouslySetInnerHTML={{
                            __html: `${dataSent?.commentInfrastructurefacilitiesSkillsLaboratoriesforNursingMidwifery?.replace(
                              /\n/g,
                              "<br />"
                            ) || 'No comment'}`,
                          }}
                        />
                      ),
                    }),
                      dataSent?.InfrastructurefacilitiesTeachinghospitalNA ?  null : (
                    {  
                      column4: `7.11 Infrastructure facilities:
                      Teaching hospital – Max 5`,
                      column5: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={5}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={
                              dataSent.InfrastructurefacilitiesTeachinghospital
                            }
                            placeholder={
                              "Infrastructure facilities:  Teaching hospital?"
                            }
                            name={"InfrastructurefacilitiesTeachinghospital"}
                          />
                        </>
                      ),
                        column6: (
                        <span className="font-bold text-black">
                          {" "}
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${
                                (dataSent?.InfrastructurefacilitiesTeachinghospitalNA ? 'N/A' : 'Applicable') }`,
                            }}
                          />
                        </span>
                      ),
                      column7: (
                        <label
                          className="block mb-2"
                          dangerouslySetInnerHTML={{
                            __html: `${dataSent?.commentInfrastructurefacilitiesTeachinghospital?.replace(
                              /\n/g,
                              "<br />"
                            ) || 'No comment'}`,
                          }}
                        />
                      ),
                    }),
                      dataSent?.InfrastructurefacilitiesAgriculturalScienceslaboratoiresNA ?  null : (
                    {  
                      column4: `7.12 Infrastructure facilities:
                      Agricultural Sciences
                      laboratoires – Max 20`,
                      column5: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={20}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={
                              dataSent.InfrastructurefacilitiesAgriculturalScienceslaboratoires
                            }
                            placeholder={
                              "Infrastructure facilities:  Agricultural Sciences laboratoires?"
                            }
                            name={
                              "InfrastructurefacilitiesAgriculturalScienceslaboratoires"
                            }
                          />
                        </>
                      ),
                        column6: (
                        <span className="font-bold text-black">
                          {" "}
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${
                                (dataSent?.InfrastructurefacilitiesAgriculturalScienceslaboratoiresNA ? 'N/A' : 'Applicable') }`,
                            }}
                          />
                        </span>
                      ),
                      column7: (
                        <label
                          className="block mb-2"
                          dangerouslySetInnerHTML={{
                            __html: `${dataSent?.commentInfrastructurefacilitiesAgriculturalScienceslaboratoires?.replace(
                              /\n/g,
                              "<br />"
                            ) || 'No comment'}`,
                          }}
                        />
                      ),
                    }),
                      dataSent?.InfrastructurefacilitiesEngineeringlaboratoriesNA ?  null : (
                    {  
                      column4: `7.13 Infrastructure facilities:
                      Engineering laboratories –
                      Max 30`,
                      column5: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={30}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={
                              dataSent.InfrastructurefacilitiesEngineeringlaboratories
                            }
                            placeholder={
                              "Infrastructure facilities: Engineering laboratories?"
                            }
                            name={
                              "InfrastructurefacilitiesEngineeringlaboratories"
                            }
                          />
                        </>
                      ),
                        column6: (
                        <span className="font-bold text-black">
                          {" "}
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${
                                (dataSent?.InfrastructurefacilitiesEngineeringlaboratoriesNA ? 'N/A' : 'Applicable') }`,
                            }}
                          />
                        </span>
                      ),
                      column7: (
                        <label
                          className="block mb-2"
                          dangerouslySetInnerHTML={{
                            __html: `${dataSent?.commentInfrastructurefacilitiesEngineeringlaboratories?.replace(
                              /\n/g,
                              "<br />"
                            ) || 'No comment'}`,
                          }}
                        />
                      ),
                    }),
                      dataSent?.InfrastructurefacilitiesWorkshopsNA ?  null : (
                    {  
                      column4: `7.14 Infrastructure facilities:
                      Workshops – Max 35`,
                      column5: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={35}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={
                              dataSent.InfrastructurefacilitiesWorkshops
                            }
                            placeholder={
                              "Infrastructure facilities: Workshops?"
                            }
                            name={"InfrastructurefacilitiesWorkshops"}
                          />
                        </>
                      ),
                        column6: (
                        <span className="font-bold text-black">
                          {" "}
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${
                                (dataSent?.InfrastructurefacilitiesWorkshopsNA ? 'N/A' : 'Applicable') }`,
                            }}
                          />
                        </span>
                      ),
                      column7: (
                        <label
                          className="block mb-2"
                          dangerouslySetInnerHTML={{
                            __html: `${dataSent?.commentInfrastructurefacilitiesWorkshops?.replace(
                              /\n/g,
                              "<br />"
                            ) || 'No comment'}`,
                          }}
                        />
                      ),
                    }),
                      dataSent?.InfrastructurefacilitiesTeachersResourceRoomNA ?  null : (
                    {  
                      column4: `7.15 Infrastructure facilities:
                      Teachers Resource Room –
                      Max 10`,
                      column5: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={10}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={
                              dataSent.InfrastructurefacilitiesTeachersResourceRoom
                            }
                            placeholder={
                              "Infrastructure facilities: Teachers Resource Room"
                            }
                            name={
                              "InfrastructurefacilitiesTeachersResourceRoom"
                            }
                          />
                        </>
                      ),
                        column6: (
                        <span className="font-bold text-black">
                          {" "}
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${
                                (dataSent?.InfrastructurefacilitiesTeachersResourceRoomNA ? 'N/A' : 'Applicable') }`,
                            }}
                          />
                        </span>
                      ),
                      column7: (
                        <label
                          className="block mb-2"
                          dangerouslySetInnerHTML={{
                            __html: `${dataSent?.commentInfrastructurefacilitiesTeachersResourceRoom?.replace(
                              /\n/g,
                              "<br />"
                            ) || 'No comment'}`,
                          }}
                        />
                      ),
                    }),
                      dataSent?.InfrastructurefacilitiesEducationTechnologylaboratoriesNA ?  null : (
                    {  
                      column4: `7.16 Infrastructure facilities:
                      Education Technology
                      laboratories – Max 10`,
                      column5: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={10}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={
                              dataSent.InfrastructurefacilitiesEducationTechnologylaboratories
                            }
                            placeholder={
                              "Infrastructure facilities: Education Technology laboratories?"
                            }
                            name={
                              "InfrastructurefacilitiesEducationTechnologylaboratories"
                            }
                          />
                        </>
                      ),
                        column6: (
                        <span className="font-bold text-black">
                          {" "}
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${
                                (dataSent?.InfrastructurefacilitiesEducationTechnologylaboratoriesNA ? 'N/A' : 'Applicable') }`,
                            }}
                          />
                        </span>
                      ),
                      column7: (
                        <label
                          className="block mb-2"
                          dangerouslySetInnerHTML={{
                            __html: `${dataSent?.commentInfrastructurefacilitiesEducationTechnologylaboratories?.replace(
                              /\n/g,
                              "<br />"
                            ) || 'No comment'}`,
                          }}
                        />
                      ),
                    }),
                      dataSent?.InfrastructurefacilitiesFoodCateringlaboratoriesNA ?  null : (
                    {  
                      column4: `7.17 Infrastructure facilities:
                      Food && Catering laboratories
                      – Max 10`,
                      column5: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={10}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={
                              dataSent.InfrastructurefacilitiesFoodCateringlaboratories
                            }
                            placeholder={
                              "Infrastructure facilities: Food && Catering laboratories?"
                            }
                            name={
                              "InfrastructurefacilitiesFoodCateringlaboratories"
                            }
                          />
                        </>
                      ),
                        column6: (
                        <span className="font-bold text-black">
                          {" "}
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${
                                (dataSent?.InfrastructurefacilitiesFoodCateringlaboratoriesNA ? 'N/A' : 'Applicable') }`,
                            }}
                          />
                        </span>
                      ),
                      column7: (
                        <label
                          className="block mb-2"
                          dangerouslySetInnerHTML={{
                            __html: `${dataSent?.commentInfrastructurefacilitiesFoodCateringlaboratories?.replace(
                              /\n/g,
                              "<br />"
                            ) || 'No comment'}`,
                          }}
                        />
                      ),
                    }),
                      dataSent?.InfrastructureFacilitiesGarmentCuttingNA ?  null : (
                    {  
                      column4: `7.18 Infrastructure facilities:
                      Garment Cutting – Max 10`,
                      column5: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={10}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={
                              dataSent.InfrastructureFacilitiesGarmentCutting
                            }
                            placeholder={
                              "Infrastructure facilities: Garment Cutting?"
                            }
                            name={"InfrastructureFacilitiesGarmentCutting"}
                          />
                        </>
                      ),
                        column6: (
                        <span className="font-bold text-black">
                          {" "}
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${
                                (dataSent?.InfrastructureFacilitiesGarmentCuttingNA ? 'N/A' : 'Applicable') }`,
                            }}
                          />
                        </span>
                      ),
                      column7: (
                        <label
                          className="block mb-2"
                          dangerouslySetInnerHTML={{
                            __html: `${dataSent?.commentInfrastructureFacilitiesGarmentCutting?.replace(
                              /\n/g,
                              "<br />"
                            ) || 'No comment'}`,
                          }}
                        />
                      ),
                    }),
                      dataSent?.InfrastructurefacilitiesCosmetologylaboratoryNA ?  null : (
                    {  
                      column4: `7.19 Infrastructure facilities:
                      Cosmetology laboratory –
                      Max 10`,
                      column5: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={10}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={
                              dataSent.InfrastructurefacilitiesCosmetologylaboratory
                            }
                            placeholder={
                              "Infrastructure facilities: Cosmetology laboratory?"
                            }
                            name={
                              "InfrastructurefacilitiesCosmetologylaboratory"
                            }
                          />
                        </>
                      ),
                        column6: (
                        <span className="font-bold text-black">
                          {" "}
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${
                                (dataSent?.InfrastructurefacilitiesCosmetologylaboratoryNA ? 'N/A' : 'Applicable') }`,
                            }}
                          />
                        </span>
                      ),
                      column7: (
                        <label
                          className="block mb-2"
                          dangerouslySetInnerHTML={{
                            __html: `${dataSent?.commentInfrastructurefacilitiesCosmetologylaboratory?.replace(
                              /\n/g,
                              "<br />"
                            ) || 'No comment'}`,
                          }}
                        />
                      ),
                    }),
                      dataSent?.InfrastructurefacilitiesGeologylaboratoryNA ?  null : (
                    {  
                      column4: `7.20 Infrastructure facilities:
                      Geology laboratory – Max
                      10`,
                      column5: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={10}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={
                              dataSent.InfrastructurefacilitiesGeologylaboratory
                            }
                            placeholder={
                              "Infrastructure facilities: Geology laboratory ?"
                            }
                            name={"InfrastructurefacilitiesGeologylaboratory"}
                          />
                        </>
                      ),
                        column6: (
                        <span className="font-bold text-black">
                          {" "}
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${
                                (dataSent?.InfrastructurefacilitiesGeologylaboratoryNA ? 'N/A' : 'Applicable') }`,
                            }}
                          />
                        </span>
                      ),
                      column7: (
                        <label
                          className="block mb-2"
                          dangerouslySetInnerHTML={{
                            __html: `${dataSent?.commentInfrastructurefacilitiesGeologylaboratory?.replace(
                              /\n/g,
                              "<br />"
                            ) || 'No comment'}`,
                          }}
                        />
                      ),
                    }),
                      dataSent?.InfrastructurefacilitiesMuseumNA ?  null : (
                    {  
                      column4: `7.21 Infrastructure facilities:
                      Museum – Max 10`,
                      column5: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={15}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={dataSent.InfrastructurefacilitiesMuseum}
                            placeholder={"Infrastructure facilities: Museum?"}
                            name={"InfrastructurefacilitiesMuseum"}
                          />
                        </>
                      ),
                        column6: (
                        <span className="font-bold text-black">
                          {" "}
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${
                                (dataSent?.InfrastructurefacilitiesMuseumNA ? 'N/A' : 'Applicable') }`,
                            }}
                          />
                        </span>
                      ),
                      column7: (
                        <label
                          className="block mb-2"
                          dangerouslySetInnerHTML={{
                            __html: `${dataSent?.commentInfrastructurefacilitiesMuseum?.replace(
                              /\n/g,
                              "<br />"
                            ) || 'No comment'}`,
                          }}
                        />
                      ),
                    }),
                      dataSent?.TeachingLearningResourceSmartBoardsNA ?  null : (
                    {  
                      column4: `8.1 Teaching && Learning
                      Resource: Smart Boards –
                      Max 5`,
                      column5: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={5}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={
                              dataSent.TeachingLearningResourceSmartBoards
                            }
                            placeholder={
                              "Teaching && Learning Resource: Smart Boards?"
                            }
                            name={"TeachingLearningResourceSmartBoards"}
                          />
                        </>
                      ),
                        column6: (
                        <span className="font-bold text-black">
                          {" "}
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${
                                (dataSent?.TeachingLearningResourceSmartBoardsNA ? 'N/A' : 'Applicable') }`,
                            }}
                          />
                        </span>
                      ),
                      column7: (
                        <label
                          className="block mb-2"
                          dangerouslySetInnerHTML={{
                            __html: `${dataSent?.commentTeachingLearningResourceSmartBoards?.replace(
                              /\n/g,
                              "<br />"
                            ) || 'No comment'}`,
                          }}
                        />
                      ),
                    }),
                      dataSent?.TeachingLearningInternetavailabilityNA ?  null : (
                    {  
                      column4: `8.2 Teaching && Learning
                      Internet availability – Max 20`,
                      column5: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={20}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={
                              dataSent.TeachingLearningInternetavailability
                            }
                            placeholder={
                              "Teaching && Learning Internet availability?"
                            }
                            name={"TeachingLearningInternetavailability"}
                          />
                        </>
                      ),
                        column6: (
                        <span className="font-bold text-black">
                          {" "}
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${
                                (dataSent?.TeachingLearningInternetavailabilityNA ? 'N/A' : 'Applicable') }`,
                            }}
                          />
                        </span>
                      ),
                      column7: (
                        <label
                          className="block mb-2"
                          dangerouslySetInnerHTML={{
                            __html: `${dataSent?.commentTeachingLearningInternetavailability?.replace(
                              /\n/g,
                              "<br />"
                            ) || 'No comment'}`,
                          }}
                        />
                      ),
                    }),
                      dataSent?.TeachingLearningBookLibraryresourcesrelevanttotheprogrammeNA ?  null : (
                    {  
                      column4: `8.3 Teaching && Learning 
                      Book && Library resources
                      relevant to the programme –
                      Max 15`,
                      column5: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={15}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={
                              dataSent.TeachingLearningBookLibraryresourcesrelevanttotheprogramme
                            }
                            placeholder={
                              "Teaching && Learning Book && Library resources relevant to the programme?"
                            }
                            name={
                              "TeachingLearningBookLibraryresourcesrelevanttotheprogramme"
                            }
                          />
                        </>
                      ),
                        column6: (
                        <span className="font-bold text-black">
                          {" "}
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${
                                (dataSent?.TeachingLearningBookLibraryresourcesrelevanttotheprogrammeNA ? 'N/A' : 'Applicable') }`,
                            }}
                          />
                        </span>
                      ),
                      column7: (
                        <label
                          className="block mb-2"
                          dangerouslySetInnerHTML={{
                            __html: `${dataSent?.commentTeachingLearningBookLibraryresourcesrelevanttotheprogramme?.replace(
                              /\n/g,
                              "<br />"
                            ) || 'No comment'}`,
                          }}
                        />
                      ),
                    }),
                      dataSent?.TeachingLearningUniversityLearningManagementSystemNA ?  null : (
                    {  
                      column4: `8.4 Teaching && Learning
                      University Learning
                      Management System – Max
                      15`,
                      column5: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={15}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={
                              dataSent.TeachingLearningUniversityLearningManagementSystem
                            }
                            placeholder={
                              "Teaching && Learning University Learning Management System?"
                            }
                            name={
                              "TeachingLearningUniversityLearningManagementSystem"
                            }
                          />
                        </>
                      ),
                        column6: (
                        <span className="font-bold text-black">
                          {" "}
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${
                                (dataSent?.TeachingLearningUniversityLearningManagementSystemNA ? 'N/A' : 'Applicable') }`,
                            }}
                          />
                        </span>
                      ),
                      column7: (
                        <label
                          className="block mb-2"
                          dangerouslySetInnerHTML={{
                            __html: `${dataSent?.commentTeachingLearningUniversityLearningManagementSystem?.replace(
                              /\n/g,
                              "<br />"
                            ) || 'No comment'}`,
                          }}
                        />
                      ),
                    }),
                      dataSent?.TeachingLearningFacilitiesavailablesupportblendedlearningNA ?  null : (
                    {  
                      column4: `8.5 Teaching && Learning
                      Facilities available support
                      blended learning – Max 30`,
                      column5: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={30}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={
                              dataSent.TeachingLearningFacilitiesavailablesupportblendedlearning
                            }
                            placeholder={
                              "Teaching && Learning Facilities available support blended learning?"
                            }
                            name={
                              "TeachingLearningFacilitiesavailablesupportblendedlearning"
                            }
                          />
                        </>
                      ),
                        column6: (
                        <span className="font-bold text-black">
                          {" "}
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${
                                (dataSent?.TeachingLearningFacilitiesavailablesupportblendedlearningNA ? 'N/A' : 'Applicable') }`,
                            }}
                          />
                        </span>
                      ),
                      column7: (
                        <label
                          className="block mb-2"
                          dangerouslySetInnerHTML={{
                            __html: `${dataSent?.commentTeachingLearningFacilitiesavailablesupportblendedlearning?.replace(
                              /\n/g,
                              "<br />"
                            ) || 'No comment'}`,
                          }}
                        />
                      ),
                    }),
                      dataSent?.StudentassessmentApproachesadoptedincontinuousandsummativeassessmentNA ?  null : (
                    {  
                      column4: `9.1 Student assessment:
                      Approaches adopted in
                      continuous (formative) and
                      summative assessment –
                      Max 15`,
                      column5: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={15}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={
                              dataSent.StudentassessmentApproachesadoptedincontinuousandsummativeassessment
                            }
                            placeholder={
                              "Student assessment:  Approaches adopted in continuous (formative) and summative assessment?"
                            }
                            name={
                              "StudentassessmentApproachesadoptedincontinuousandsummativeassessment"
                            }
                          />
                        </>
                      ),
                        column6: (
                        <span className="font-bold text-black">
                          {" "}
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${
                                (dataSent?.StudentassessmentApproachesadoptedincontinuousandsummativeassessmentNA ? 'N/A' : 'Applicable') }`,
                            }}
                          />
                        </span>
                      ),
                      column7: (
                        <label
                          className="block mb-2"
                          dangerouslySetInnerHTML={{
                            __html: `${dataSent?.commentStudentassessmentApproachesadoptedincontinuousandsummativeassessment?.replace(
                              /\n/g,
                              "<br />"
                            ) || 'No comment'}`,
                          }}
                        />
                      ),
                    }),
                      dataSent?.StudentassessmentApproachestoassessmentofdigitalNA ?  null : (
                    {  
                      column4: `9.2 Student assessment
                      Approaches to assessment of
                      digital/online learning – Max
                      15`,
                      column5: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={15}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={
                              dataSent.StudentassessmentApproachestoassessmentofdigital
                            }
                            placeholder={
                              "Student assessment Approaches to assessment of digital?"
                            }
                            name={
                              "StudentassessmentApproachestoassessmentofdigital"
                            }
                          />
                        </>
                      ),
                        column6: (
                        <span className="font-bold text-black">
                          {" "}
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${
                                (dataSent?.StudentassessmentApproachestoassessmentofdigitalNA ? 'N/A' : 'Applicable') }`,
                            }}
                          />
                        </span>
                      ),
                      column7: (
                        <label
                          className="block mb-2"
                          dangerouslySetInnerHTML={{
                            __html: `${dataSent?.commentStudentassessmentApproachestoassessmentofdigital?.replace(
                              /\n/g,
                              "<br />"
                            ) || 'No comment'}`,
                          }}
                        />
                      ),
                    }),
                      dataSent?.StudentassessmentInternalExternalmoderationofexaminationsresultNA ?  null : (
                    {  
                      column4: `9.3 Student assessment
                      Internal && External
                      moderation of examinations
                      && results – Max 10`,
                      column5: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={10}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={
                              dataSent.StudentassessmentInternalExternalmoderationofexaminationsresult
                            }
                            placeholder={
                              "Student assessment Internal && External  moderation of examinations   && results?"
                            }
                            name={
                              "StudentassessmentInternalExternalmoderationofexaminationsresult"
                            }
                          />
                        </>
                      ),
                        column6: (
                        <span className="font-bold text-black">
                          {" "}
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${
                                (dataSent?.StudentassessmentInternalExternalmoderationofexaminationsresultNA ? 'N/A' : 'Applicable') }`,
                            }}
                          />
                        </span>
                      ),
                      column7: (
                        <label
                          className="block mb-2"
                          dangerouslySetInnerHTML={{
                            __html: `${dataSent?.commentStudentassessmentInternalExternalmoderationofexaminationsresult?.replace(
                              /\n/g,
                              "<br />"
                            ) || 'No comment'}`,
                          }}
                        />
                      ),
                    }),
                      dataSent?.StudentassessmentSecurityofExaminationsNA ?  null : (
                    {  
                      column4: `9.4 Student assessment
                      Security of Examinations –
                      Max 5`,
                      column5: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={5}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={
                              dataSent.StudentassessmentSecurityofExaminations
                            }
                            placeholder={
                              "Student assessment Security of Examinations?"
                            }
                            name={"StudentassessmentSecurityofExaminations"}
                          />
                        </>
                      ),
                        column6: (
                        <span className="font-bold text-black">
                          {" "}
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${
                                (dataSent?.StudentassessmentSecurityofExaminationsNA ? 'N/A' : 'Applicable') }`,
                            }}
                          />
                        </span>
                      ),
                      column7: (
                        <label
                          className="block mb-2"
                          dangerouslySetInnerHTML={{
                            __html: `${dataSent?.commentStudentassessmentSecurityofExaminations?.replace(
                              /\n/g,
                              "<br />"
                            ) || 'No comment'}`,
                          }}
                        />
                      ),
                    }),
                      dataSent?.StudentassessmentInternshipIndustrialAttachmentNA ?  null : (
                    {  
                      column4: `9.5 Student assessment
                      Internship/Industrial
                      Attachment – Max 10`,
                      column5: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={10}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={
                              dataSent.StudentassessmentInternshipIndustrialAttachment
                            }
                            placeholder={
                              "Student assessment Internship/Industrial Attachment?"
                            }
                            name={
                              "StudentassessmentInternshipIndustrialAttachment"
                            }
                          />
                        </>
                      ),
                        column6: (
                        <span className="font-bold text-black">
                          {" "}
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${
                                (dataSent?.StudentassessmentInternshipIndustrialAttachmentNA ? 'N/A' : 'Applicable') }`,
                            }}
                          />
                        </span>
                      ),
                      column7: (
                        <label
                          className="block mb-2"
                          dangerouslySetInnerHTML={{
                            __html: `${dataSent?.commentStudentassessmentInternshipIndustrialAttachment?.replace(
                              /\n/g,
                              "<br />"
                            ) || 'No comment'}`,
                          }}
                        />
                      ),
                    }),
                      dataSent?.StudentsSupportSystemProvisionforstudentsNA ?  null : (
                    {  
                      column4: `10.1 Students Support
                      System: Provision for
                      students
                      tutor/mentor/advisor – Max
                      15`,
                      column5: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={15}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={
                              dataSent.StudentsSupportSystemProvisionforstudents
                            }
                            placeholder={
                              "Students Support System: Provision for students?"
                            }
                            name={"StudentsSupportSystemProvisionforstudents"}
                          />
                        </>
                      ),
                        column6: (
                        <span className="font-bold text-black">
                          {" "}
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${
                                (dataSent?.StudentsSupportSystemProvisionforstudentsNA ? 'N/A' : 'Applicable') }`,
                            }}
                          />
                        </span>
                      ),
                      column7: (
                        <label
                          className="block mb-2"
                          dangerouslySetInnerHTML={{
                            __html: `${dataSent?.commentStudentsSupportSystemProvisionforstudents?.replace(
                              /\n/g,
                              "<br />"
                            ) || 'No comment'}`,
                          }}
                        />
                      ),
                    }),
                      dataSent?.StudentsSupportProgrammeorientationNA ?  null : (
                    {  
                      column4: `10.2 Students Support
                      Programme orientation –
                      Max 10`,
                      column5: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={10}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={
                              dataSent.StudentsSupportProgrammeorientation
                            }
                            placeholder={
                              "Students Support Programme orientation?"
                            }
                            name={"StudentsSupportProgrammeorientation"}
                          />
                        </>
                      ),
                        column6: (
                        <span className="font-bold text-black">
                          {" "}
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${
                                (dataSent?.StudentsSupportProgrammeorientationNA ? 'N/A' : 'Applicable') }`,
                            }}
                          />
                        </span>
                      ),
                      column7: (
                        <label
                          className="block mb-2"
                          dangerouslySetInnerHTML={{
                            __html: `${dataSent?.commentStudentsSupportProgrammeorientation?.replace(
                              /\n/g,
                              "<br />"
                            ) || 'No comment'}`,
                          }}
                        />
                      ),
                    }),
                      dataSent?.StudentsSupportCo_curriculumactivitiesNA ?  null : (
                    {  
                      column4: `10.3 Students Support Co-
                      curriculum activities – Max 5`,
                      column5: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={5}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={
                              dataSent.StudentsSupportCo_curriculumactivities
                            }
                            placeholder={
                              "Students Support Co-curriculum activities?"
                            }
                            name={"StudentsSupportCo_curriculumactivities"}
                          />
                        </>
                      ),
                        column6: (
                        <span className="font-bold text-black">
                          {" "}
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${
                                (dataSent?.StudentsSupportCo_curriculumactivitiesNA ? 'N/A' : 'Applicable') }`,
                            }}
                          />
                        </span>
                      ),
                      column7: (
                        <label
                          className="block mb-2"
                          dangerouslySetInnerHTML={{
                            __html: `${dataSent?.commentStudentsSupportCo_curriculumactivities?.replace(
                              /\n/g,
                              "<br />"
                            ) || 'No comment'}`,
                          }}
                        />
                      ),
                    }),
                    dataSent?.StudentsSupportCounsellingservicesNA ?  null : (
                    {  
                      column4: `10.4 Students Support
                      Counselling services – Max
                      15`,
                      column5: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={15}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={
                              dataSent.StudentsSupportCounsellingservices
                            }
                            placeholder={
                              "Students Support Counselling services?"
                            }
                            name={"StudentsSupportCounsellingservices"}
                          />
                        </>
                      ),
                        column6: (
                        <span className="font-bold text-black">
                          {" "}
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${
                                (dataSent?.StudentsSupportCounsellingservicesNA ? 'N/A' : 'Applicable') }`,
                            }}
                          />
                        </span>
                      ),
                      column7: (
                        <label
                          className="block mb-2"
                          dangerouslySetInnerHTML={{
                            __html: `${dataSent?.commentStudentsSupportCounsellingservices?.replace(
                              /\n/g,
                              "<br />"
                            ) || 'No comment'}`,
                          }}
                        />
                      ),
                    }),

                      dataSent?.StudentsSupportMentorshipprogrammeNA ?  null : (
                    {  
                      column4: `10.5 Students Support
                      Mentorship programme –
                      Max 10`,
                      column5: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={10}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={
                              dataSent.StudentsSupportMentorshipprogramme
                            }
                            placeholder={
                              "Students Support Mentorship programme?"
                            }
                            name={"StudentsSupportMentorshipprogramme"}
                          />
                        </>
                      ),
                        column6: (
                        <span className="font-bold text-black">
                          {" "}
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${
                                (dataSent?.StudentsSupportMentorshipprogrammeNA ? 'N/A' : 'Applicable') }`,
                            }}
                          />
                        </span>
                      ),
                      column7: (
                        <label
                          className="block mb-2"
                          dangerouslySetInnerHTML={{
                            __html: `${dataSent?.commentStudentsSupportMentorshipprogramme?.replace(
                              /\n/g,
                              "<br />"
                            ) || 'No comment'}`,
                          }}
                        />
                      ),
                    }),
                      dataSent?.StudentsSupportProvisionofsocialpresencefordigitallearningNA ?  null : (
                    {  
                      column4: `10.6 Students Support
                      Provision of social presence
                      for digital learning – Max 5`,
                      column5: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={5}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={
                              dataSent.StudentsSupportProvisionofsocialpresencefordigitallearning
                            }
                            placeholder={
                              "Students Support Provision of social presence for digital learning?"
                            }
                            name={
                              "StudentsSupportProvisionofsocialpresencefordigitallearning"
                            }
                          />
                        </>
                      ),
                        column6: (
                        <span className="font-bold text-black">
                          {" "}
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${
                                (dataSent?.StudentsSupportProvisionofsocialpresencefordigitallearningNA ? 'N/A' : 'Applicable') }`,
                            }}
                          />
                        </span>
                      ),
                      column7: (
                        <label
                          className="block mb-2"
                          dangerouslySetInnerHTML={{
                            __html: `${dataSent?.commentStudentsSupportProvisionofsocialpresencefordigitallearning?.replace(
                              /\n/g,
                              "<br />"
                            ) || 'No comment'}`,
                          }}
                        />
                      ),
                    }),
                      dataSent?.StudentsSupportInformationonstudentssupportmadeavailableNA ?  null : (
                    {  
                      column4: `10.7 Students Support
                      Information on students
                      support made available –
                      Max 10`,
                      column5: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={10}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={
                              dataSent.StudentsSupportInformationonstudentssupportmadeavailable
                            }
                            placeholder={
                              "Students Support Information on students support made available?"
                            }
                            name={
                              "StudentsSupportInformationonstudentssupportmadeavailable"
                            }
                          />
                        </>
                      ),
                        column6: (
                        <span className="font-bold text-black">
                          {" "}
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${
                                (dataSent?.StudentsSupportInformationonstudentssupportmadeavailableNA ? 'N/A' : 'Applicable') }`,
                            }}
                          />
                        </span>
                      ),
                      column7: (
                        <label
                          className="block mb-2"
                          dangerouslySetInnerHTML={{
                            __html: `${dataSent?.commentStudentsSupportInformationonstudentssupportmadeavailable?.replace(
                              /\n/g,
                              "<br />"
                            ) || 'No comment'}`,
                          }}
                        />
                      ),
                    }),
                      dataSent?.StudentsSupportScholarship_financialsupportservicestoneedystudentsNA ?  null : (
                    {  
                      column4: `10.8 Students Support
                      Scholarship/financial support
                      services to needy students –
                      Max 15`,
                      column5: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={15}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={
                              dataSent.StudentsSupportScholarship_financialsupportservicestoneedystudents
                            }
                            placeholder={
                              "Students Support Scholarship/financial support services to needy students?"
                            }
                            name={
                              "StudentsSupportScholarship_financialsupportservicestoneedystudents"
                            }
                          />
                        </>
                      ),
                        column6: (
                        <span className="font-bold text-black">
                          {" "}
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${
                                (dataSent?.StudentsSupportScholarship_financialsupportservicestoneedystudentsNA ? 'N/A' : 'Applicable') }`,
                            }}
                          />
                        </span>
                      ),
                      column7: (
                        <label
                          className="block mb-2"
                          dangerouslySetInnerHTML={{
                            __html: `${dataSent?.commentStudentsSupportScholarship_financialsupportservicestoneedystudents?.replace(
                              /\n/g,
                              "<br />"
                            ) || 'No comment'}`,
                          }}
                        />
                      ),
                    }),
                      dataSent?.CommunityServicesTheprogrammehaspoliciesstrategiesforlecturersininvolveincommunityNA ?  null : (
                    {  
                      column4: `11.1 Community Services:
                      The programme has policies
                      && strategies for lecturers in
                      involve in community
                      services – Max 10`,
                      column5: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={10}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={
                              dataSent.CommunityServicesTheprogrammehaspoliciesstrategiesforlecturersininvolveincommunity
                            }
                            placeholder={
                              "Community Services:  The programme has policies  && strategies for lecturers in involve in community?"
                            }
                            name={
                              "CommunityServicesTheprogrammehaspoliciesstrategiesforlecturersininvolveincommunity"
                            }
                          />
                        </>
                      ),
                        column6: (
                        <span className="font-bold text-black">
                          {" "}
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${
                                (dataSent?.CommunityServicesTheprogrammehaspoliciesstrategiesforlecturersininvolveincommunityNA ? 'N/A' : 'Applicable') }`,
                            }}
                          />
                        </span>
                      ),
                      column7: (
                        <label
                          className="block mb-2"
                          dangerouslySetInnerHTML={{
                            __html: `${dataSent?.commentCommunityServicesTheprogrammehaspoliciesstrategiesforlecturersininvolveincommunity?.replace(
                              /\n/g,
                              "<br />"
                            ) || 'No comment'}`,
                          }}
                        />
                      ),
                    }),
                      dataSent?.CommunityServicesThestrategiesarereviewedAndimprovedeveryfiveNA ?  null : (
                    {  
                      column4: `11.2 Community Services:
                      The strategies are reviewed
                      and improved every five (5)
                      years – Max 5`,
                      column5: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={5}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={
                              dataSent.CommunityServicesThestrategiesarereviewedAndimprovedeveryfive
                            }
                            placeholder={
                              "Community Services: The strategies are reviewed and improved every five?"
                            }
                            name={
                              "CommunityServicesThestrategiesarereviewedAndimprovedeveryfive"
                            }
                          />
                        </>
                      ),
                        column6: (
                        <span className="font-bold text-black">
                          {" "}
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${
                                (dataSent?.CommunityServicesThestrategiesarereviewedAndimprovedeveryfiveNA ? 'N/A' : 'Applicable') }`,
                            }}
                          />
                        </span>
                      ),
                      column7: (
                        <label
                          className="block mb-2"
                          dangerouslySetInnerHTML={{
                            __html: `${dataSent?.commentCommunityServicesThestrategiesarereviewedAndimprovedeveryfive?.replace(
                              /\n/g,
                              "<br />"
                            ) || 'No comment'}`,
                          }}
                        />
                      ),
                    }),
                      dataSent?.CommunityServicesReportsonimpactandbenefitsoftheprogrammetotherealcommunityNA ?  null : (
                    {  
                      column4: `11.3 Community Services
                      Reports on impact and
                      benefits of the programme to
                      the real community(ies) –
                      Max 15`,
                      column5: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={15}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={
                              dataSent.CommunityServicesReportsonimpactandbenefitsoftheprogrammetotherealcommunity
                            }
                            placeholder={
                              "Community Services  Reports on impact and benefits of the programme to the real community(ies)?"
                            }
                            name={
                              "CommunityServicesReportsonimpactandbenefitsoftheprogrammetotherealcommunity"
                            }
                          />
                        </>
                      ),
                        column6: (
                        <span className="font-bold text-black">
                          {" "}
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${
                                (dataSent?.CommunityServicesReportsonimpactandbenefitsoftheprogrammetotherealcommunityNA ? 'N/A' : 'Applicable') }`,
                            }}
                          />
                        </span>
                      ),
                      column7: (
                        <label
                          className="block mb-2"
                          dangerouslySetInnerHTML={{
                            __html: `${dataSent?.commentCommunityServicesReportsonimpactandbenefitsoftheprogrammetotherealcommunity?.replace(
                              /\n/g,
                              "<br />"
                            ) || 'No comment'}`,
                          }}
                        />
                      ),
                    }),
                      dataSent?.ProgrammeReviewProcessesproceduresofprogrammereviewNA ?  null : (
                    {  
                      column4: `12.1 Programme Review:
                      Processes && procedures of
                      programme review – Max 15`,
                      column5: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={15}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={ 
                              dataSent.ProgrammeReviewProcessesproceduresofprogrammereview
                            }
                            placeholder={
                              "Programme Review: Processes && procedures of programme review?"
                            }
                            name={
                              "ProgrammeReviewProcessesproceduresofprogrammereview"
                            }
                          />
                        </>
                      ),
                        column6: (
                        <span className="font-bold text-black">
                          {" "}
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${
                                (dataSent?.ProgrammeReviewProcessesproceduresofprogrammereviewNA ? 'N/A' : 'Applicable') }`,
                            }}
                          />
                        </span>
                      ),
                      column7: (
                        <label
                          className="block mb-2"
                          dangerouslySetInnerHTML={{
                            __html: `${dataSent?.commentProgrammeReviewProcessesproceduresofprogrammereview?.replace(
                              /\n/g,
                              "<br />"
                            ) || 'No comment'}`,
                          }}
                        />
                      ),
                    }),
                      dataSent?.programmeReviewPeriodicreviewofprogrammeNA ?  null : (
                    {  
                      column4: `12.2 Programme Review
                      Periodic review of
                      programme – Max 10`,
                      column5: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={10}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={
                              dataSent.programmeReviewPeriodicreviewofprogramme
                            }
                            placeholder={
                              "Programme Review Periodic review of programme?"
                            }
                            name={"programmeReviewPeriodicreviewofprogramme"}
                          />
                        </>
                      ),
                        column6: (
                        <span className="font-bold text-black">
                          {" "}
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${
                                (dataSent?.programmeReviewPeriodicreviewofprogrammeNA ? 'N/A' : 'Applicable') }`,
                            }}
                          />
                        </span>
                      ),
                      column7: (
                        <label
                          className="block mb-2"
                          dangerouslySetInnerHTML={{
                            __html: `${dataSent?.commentprogrammeReviewPeriodicreviewofprogramme?.replace(
                              /\n/g,
                              "<br />"
                            ) || 'No comment'}`,
                          }}
                        />
                      ),
                    }),
                      dataSent?.ProgrammeReviewRegulartracerstudiesNA ?  null : (
                    {  
                      column4: `12.3 Programme Review
                      Regular tracer studies – Max
                      15`,
                      column5: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={15}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={
                              dataSent.ProgrammeReviewRegulartracerstudies
                            }
                            placeholder={
                              "Programme Review Regular tracer studies?"
                            }
                            name={"ProgrammeReviewRegulartracerstudies"}
                          />
                        </>
                      ),
                        column6: (
                        <span className="font-bold text-black">
                          {" "}
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${
                                (dataSent?.ProgrammeReviewRegulartracerstudiesNA ? 'N/A' : 'Applicable') }`,
                            }}
                          />
                        </span>
                      ),
                      column7: (
                        <label
                          className="block mb-2"
                          dangerouslySetInnerHTML={{
                            __html: `${dataSent?.commentProgrammeReviewRegulartracerstudies?.replace(
                              /\n/g,
                              "<br />"
                            ) || 'No comment'}`,
                          }}
                        />
                      ),
                    }),

                      dataSent?.ProgrammeReviewProceduresPlansforstaffdevelopmentbasedonidentifiedneedsandemployersatisfactionNA ?  null : (
                    {  
                      column4: `12.4 Programme Review
                      Procedures && Plans for staff
                      development based on
                      identified needs and
                      employer satisfaction – Max 15`,
                      column5: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={15}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={
                              dataSent.ProgrammeReviewProceduresPlansforstaffdevelopmentbasedonidentifiedneedsandemployersatisfaction
                            }
                            placeholder={
                              "Programme Review Procedures && Plans for staff development based on identified needs and employer satisfaction?"
                            }
                            name={
                              "ProgrammeReviewProceduresPlansforstaffdevelopmentbasedonidentifiedneedsandemployersatisfaction"
                            }
                          />
                        </>
                      ),
                        column6: (
                        <span className="font-bold text-black">
                          {" "}
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${
                                (dataSent?.ProgrammeReviewProceduresPlansforstaffdevelopmentbasedonidentifiedneedsandemployersatisfactionNA ? 'N/A' : 'Applicable') }`,
                            }}
                          />
                        </span>
                      ),
                      column7: (
                        <label
                          className="block mb-2"
                          dangerouslySetInnerHTML={{
                            __html: `${dataSent?.commentProgrammeReviewProceduresPlansforstaffdevelopmentbasedonidentifiedneedsandemployersatisfaction?.replace(
                              /\n/g,
                              "<br />"
                            ) || 'No comment'}`,
                          }}
                        />
                      ),
                    }),
                  ], // data content tag
                },
              ], // sub content
            },
          ],
        },
      ],
    },
  ],
});
