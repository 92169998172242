import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";
import "./BackButton.css";

const BackButton = ({ clickedMenu, setClickedMenu }) => {
  const history = useHistory();
  const location = useLocation();
  const [isHovered, setIsHovered] = useState(false);

  const goBack = () => {
    history.goBack();
  };

  // console.log('clickedMenu', clickedMenu)

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };

  const showArrow = location.pathname !== "/";

  return (
    <div className="back-button-container mt-1.5 ml-2 -mb-2">
      {showArrow && (
        <div className="grid grid-cols-2">
          {" "}
          <div
            className={`back-button ${isHovered ? "hover" : ""}`}
            onClick={goBack}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
          >
            <div className="back-button-circle"></div>
            <FiArrowLeft className="back-icon" size={24} />
          </div>
          <div className="ml-4 capitalize " style={{ whiteSpace: 'nowrap' }}>{clickedMenu}</div>

        </div>
      )}
    </div>
  );
};

export default BackButton;
