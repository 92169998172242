import React, { useState} from 'react'; 
import { Link } from 'react-router-dom';
import {AiOutlineClose, AiOutlineMenu} from 'react-icons/ai';
 

const Navbar = props => {
    const[nav, setNav] = useState(false);

    // dark mode
  
    const handleNav = () => {
        setNav(!nav);
    }
  
    
    return( 
        <div className='flex justify-between items-center h-24 max-w-[1240px] mx-auto px-4 dark:text-[var(--color-dark-font)] text-[var(--color-light-font)]'>
            {/* <h1 className='uppercase w-full text-3xl font-bold  dark:text-[var(--color-dark-font)] text-[var(--color-light-font)]'>Regional Accreditation </h1> 
            <ul className='hidden md:flex'>
                <Link className='nav-link p-4' to={'/'}> <button className=' w-[100px]  py-3 rounded-md font-medium dark:bg-[var(--color-dark-buttonBackground)]  bg-[var(--color-light-buttonBackground)] dark:text-[var(--color-dark-fontButton)] text-[var(--color-light-fontButton)]'>Home</button></Link>
                <Link className='nav-link p-4' to={'/login'}><button className=' w-[100px]  py-3 rounded-md font-medium dark:bg-[var(--color-dark-buttonBackground)]  bg-[var(--color-light-buttonBackground)] dark:text-[var(--color-dark-fontButton)] text-[var(--color-light-fontButton)]'>Login</button></Link> 
                <Link className='nav-link p-4' to={'/alert'}>  <button className='dark:bg-[var(--color-dark-buttonBackground)]  bg-[var(--color-light-buttonBackground)] dark:text-[var(--color-dark-fontButton)] text-[var(--color-light-fontButton)]  w-[100px]  py-3 rounded-md font-medium'> Apply now</button></Link>
                <Link className='nav-link p-4' to={'/create'}>  <button className='dark:bg-[var(--color-dark-buttonBackground)]  bg-[var(--color-light-buttonBackground)] dark:text-[var(--color-dark-fontButton)] text-[var(--color-light-fontButton)]  w-[100px]  py-3 rounded-md font-medium'> Add/Edit</button></Link>
                <Link className='nav-link p-4' to={'/list'}>  <button className='dark:bg-[var(--color-dark-buttonBackground)]  bg-[var(--color-light-buttonBackground)] dark:text-[var(--color-dark-fontButton)] text-[var(--color-light-fontButton)]  w-[100px]  py-3 rounded-md font-medium'> List</button></Link> 
            </ul> 
            
            <div onClick={handleNav} className='block md:hidden'>
                {nav ?  <AiOutlineClose size={23}/> :  <AiOutlineMenu size={23} />}
            </div>
            <div className={nav ? 'fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-900 dark:bg-[#000300] bg-[#317773] ease-in-out duration-600' : 'ease-in-out duration-500 fixed left-[-100%]'}>
            <h1 className='uppercase w-full text-3xl font-bold dark:text-[#0969DA] text-[#E2D1F9] m-4'>Regional Accreditation </h1>

                <ul className='uppercase p-4'>
                    <Link className='nav-link p-4 border-b border-gray-600 dark:bg-[var(--color-dark-bakcgroundSecond)] bg-[var(--color-light-bakcgroundSecond)] dark:text-[var(--color-dark-fontButton)] text-[var(--color-light-fontButton)]' to={'/'}>  Home </Link>
                    <Link className='nav-link p-4 border-b border-gray-600 dark:bg-[var(--color-dark-bakcgroundSecond)] bg-[var(--color-light-bakcgroundSecond)] dark:text-[var(--color-dark-fontButton)] text-[var(--color-light-fontButton)]' to={'/login'}> Login </Link> 
                    <Link className='nav-link p-4 border-b border-gray-600 dark:bg-[var(--color-dark-bakcgroundSecond)] bg-[var(--color-light-bakcgroundSecond)] dark:text-[var(--color-dark-fontButton)] text-[var(--color-light-fontButton)]' to={'/appply'}>  Apply now </Link>
                </ul>
            </div> */}
        </div> 
    );
}

export default Navbar;