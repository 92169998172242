import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import ApplicationService from "../../../services/ApplicationService";
import { connect } from "react-redux";
import { login } from "../../actions/auth";
import { ApplicationSchemasValidation } from "../../alerts/Validations";
import { useFormik, Field } from "formik";
import Loading from "../../eventsHandler/Loading";

const ApplicationAdd = (props) => {
  let editing = false;
  let initialTitle_program_application = "";
  let initialDesignation_application = "";
  let initialUniversity_id_application = "";
  let initialLevel_id_application = "";
  let initialOtherInfos = "";
  let initialevidence_for_accreditation_at_national_level = "";
  let initialconfirmation_letter_national_agency = "";
  let initialinvolvment_of_the_relevant_national_bodies = "";
  let initialcurriculum_development = "";
  let initialpostgraduate_supervision_guidelines = "";
  let initialquality_assurence_policy = "";
  let initialcredit_transfer_policy = "";
  let initialsummary_of_staff_capacity = "";
  let initialinventory_of_facilities = "";
  let initialannual_survey_report = "";
  let initialtracer_study_report = "";
  let initialexamination_policy = "";
  let initialadmission_policy = "";
  let initialprogram_review_report = "";
  let initialother_not_specified = "";
  let initialDocument2_application = "";
  let initialDocument3_application = "";
  let initialApplicationID = "";

  if (props.location.state && props.location.state.currentApplication) {
    editing = true;
    initialApplicationID =
      props.location.state.currentApplication.id_application;
    initialevidence_for_accreditation_at_national_level =
      props.location.state.currentApplication
        .evidence_for_accreditation_at_national_level;
    initialconfirmation_letter_national_agency =
      props.location.state.currentApplication
        .confirmation_letter_national_agency;
    initialinvolvment_of_the_relevant_national_bodies =
      props.location.state.currentApplication
        .involvment_of_the_relevant_national_bodies;
    initialcurriculum_development =
      props.location.state.currentApplication.curriculum_development;
    initialpostgraduate_supervision_guidelines =
      props.location.state.currentApplication
        .postgraduate_supervision_guidelines;
    initialquality_assurence_policy =
      props.location.state.currentApplication.quality_assurence_policy;
    initialcredit_transfer_policy =
      props.location.state.currentApplication.credit_transfer_policy;
    initialsummary_of_staff_capacity =
      props.location.state.currentApplication.summary_of_staff_capacity;
    initialinventory_of_facilities =
      props.location.state.currentApplication.inventory_of_facilities;
    initialannual_survey_report =
      props.location.state.currentApplication.annual_survey_report;
    initialtracer_study_report =
      props.location.state.currentApplication.tracer_study_report;
    initialexamination_policy =
      props.location.state.currentApplication.examination_policy;
    initialadmission_policy =
      props.location.state.currentApplication.admission_policy;
    initialprogram_review_report =
      props.location.state.currentApplication.program_review_report;
    initialother_not_specified =
      props.location.state.currentApplication.other_not_specified;
    initialTitle_program_application =
      props.location.state.currentApplication.title_program_application;
    initialDesignation_application =
      props.location.state.currentApplication.designation_application;
    initialUniversity_id_application =
      props.location.state.currentApplication.university_id_application;
    initialLevel_id_application =
      props.location.state.currentApplication.level_id_application;

    initialOtherInfos =
      props.location.state.currentApplication.otherInfor_application;
  }

  const [titleName, setTitleName] = useState(initialTitle_program_application);
  const [disableSaveBtn, setDisableSaveBtn] = useState(false);
  const [level, setLevel] = useState(initialLevel_id_application);
  const [designation, setDesignation] = useState(
    initialDesignation_application
  );
  const [otherInfo, setOtherInfo] = useState(initialOtherInfos);
  const [document1, setDocument1] = useState("");
  const [document2, setDocument2] = useState("");
  const [document3, setDocument3] = useState("");
  const [status, setStatus] = useState("");

  let levelData = JSON.parse(localStorage.getItem("levelData"));

  const history = useHistory();
  const [errorsGot, setErrorsGot] = useState("");
  const [successGot, setSuccessGot] = useState("");

  if (!(history.location.state == null)) {
    const data = history.location.state.data;
    console.log("Data send: ");
    console.log(data);
  }

  const onSubmit = (values, { resetForm }) => {
    setDisableSaveBtn(true);
    let userInfo = localStorage.getItem("userInfo");
    let universityID = JSON.parse(userInfo);
    const newApplication = new FormData();
    newApplication.append(
      "title_program_application",
      values.title_program_application
    );
    newApplication.append(
      "designation_application",
      values.designation_application
    );
    newApplication.append("university_id_application", universityID.university);
    newApplication.append("level_id_application", values.level_id_application);
    newApplication.append(
      "otherInfor_application",
      values.otherInfor_application
    );

    if (values.evidence_for_accreditation_at_national_level) {
      newApplication.append(
        "evidence_for_accreditation_at_national_level",
        values.evidence_for_accreditation_at_national_level
      );
    }
    if (values.confirmation_letter_national_agency) {
      newApplication.append(
        "confirmation_letter_national_agency",
        values.confirmation_letter_national_agency
      );
    }
    if (values.involvment_of_the_relevant_national_bodies) {
      newApplication.append(
        "involvment_of_the_relevant_national_bodies",
        values.involvment_of_the_relevant_national_bodies
      );
    }

    if (values.curriculum_development) {
      newApplication.append(
        "curriculum_development",
        values.curriculum_development
      );
    }

    if (values.postgraduate_supervision_guidelines) {
      newApplication.append(
        "postgraduate_supervision_guidelines",
        values.postgraduate_supervision_guidelines
      );
    }

    if (values.quality_assurence_policy.size > 10) {
      newApplication.append(
        "quality_assurence_policy",
        values.quality_assurence_policy
      );
    }

    if (values.credit_transfer_policy) {
      newApplication.append(
        "credit_transfer_policy",
        values.credit_transfer_policy
      );
    }
    if (values.summary_of_staff_capacity.size > 0) {
      newApplication.append(
        "summary_of_staff_capacity",
        values.summary_of_staff_capacity
      );
    }
    if (values.inventory_of_facilities.size > 0) {
      newApplication.append(
        "inventory_of_facilities",
        values.inventory_of_facilities
      );
    }

    if (values.annual_survey_report.size > 0) {
      newApplication.append(
        "annual_survey_report",
        values.annual_survey_report
      );
    }
    if (values.tracer_study_report.size > 0) {
      newApplication.append("tracer_study_report", values.tracer_study_report);
    }
    if (values.examination_policy.size > 0) {
      newApplication.append("examination_policy", values.examination_policy);
    }

    if (values.admission_policy.size > 0) {
      newApplication.append("admission_policy", values.admission_policy);
    }
    if (values.program_review_report.size > 0) {
      newApplication.append(
        "program_review_report",
        values.program_review_report
      );
    }
    if (values.other_not_specified.size > 0) {
      newApplication.append("other_not_specified", values.other_not_specified);
    }

    console.log("newApplication: ");
    console.log(values);

    // doing edit here
    console.log("Edit clicked " + initialApplicationID);
    console.log(newApplication);

    ApplicationService.editApplication(
      initialApplicationID,
      newApplication,
      localStorage.getItem("access")
    )
      .then((response) => {
        // setSubmitted(true);
        // props.history.push('/');
        setStatus("Saved correctly...");
        let data = {
          status: 1,
          title: "Okay",
          message: "Saved with Success ... Wait for feedback please!!!",
        };
        history.push("/application/list", { data: data });
      })
      .catch((error) => {
        let data = {
          status: 0,
          title: "Error",
          message: "An Error happened !",
        };
        history.push("/application/list", { data: data });
        setStatus("Error Saving data");
      });
      setDisableSaveBtn(false)
  };

  const {
    values,
    errors,
    touched,
    isSubmitting,
    setFieldValue,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      designation_application: "",
      title_program_application: initialTitle_program_application,
      university_id_application: initialUniversity_id_application,

      evidence_for_accreditation_at_national_level: "",
      confirmation_letter_national_agency: "",
      involvment_of_the_relevant_national_bodies: "",

      evidence_for_accreditation_at_national_level: "",
      confirmation_letter_national_agency: "",
      involvment_of_the_relevant_national_bodies: "",

      curriculum_development: "",
      postgraduate_supervision_guidelines: "",
      quality_assurence_policy: "",

      credit_transfer_policy: "",
      summary_of_staff_capacity: "",
      inventory_of_facilities: "",

      annual_survey_report: "",
      tracer_study_report: "",
      examination_policy: "",

      admission_policy: "",
      program_review_report: "",
      other_not_specified: "",

      otherInfor_application: initialOtherInfos,
      level_id_application: initialLevel_id_application,

      otherInfor_application: initialOtherInfos,
      level_id_application: initialLevel_id_application,
    },
    onSubmit,
  });

  let allUniveData = JSON.parse(localStorage.getItem("universityData"));
  let universityName = "";
  allUniveData.map((dataUniv) => {
    if (dataUniv.id === Number(initialUniversity_id_application)) {
      universityName = dataUniv.university_name;
    }
  });

  console.log(errors);
  // console.log(initialevidence_for_accreditation_at_national_level);
  // let text = initialevidence_for_accreditation_at_national_level;
  // const myArray = text.split("/");
  // console.log(myArray[4]+'/'+myArray[5]+'/'+myArray[6]);

  return (
    <div>
      <div className="w-full p-8">
      {disableSaveBtn && <Loading />}
        <form onSubmit={handleSubmit} autoComplete="off">
          <div className="flex mb-4 text-md pt-4">
            <div className="w-1/2 px-3 mb-6 md:mb-0">
              <label
                className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-2"
                htmlFor="grid-first-name"
              >
                Title of the Programme <span className="text-red-600">*</span>
              </label>
              <input
                className={`
                               ${
                                 errors.title_program_application &&
                                 touched.title_program_application
                                   ? "input-error"
                                   : ""
                               }
                              appearance-none block w-full  dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)]  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                id="title_program_application"
                type="text"
                name="title_program_application"
                value={values.title_program_application}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Title of the programme"
              />
              {errors.title_program_application &&
              touched.title_program_application ? (
                <p className="text-red-500 text-xs italic">
                  {errors.title_program_application}
                </p>
              ) : (
                ""
              )}
            </div>
            <div className="w-1/2 px-3 mb-6 md:mb-0 text-md ">
              <label
                className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-2"
                htmlFor="grid-first-name"
              >
                Level<span className="text-red-600">*</span>
              </label>
              <div className="relative">
                <select
                  className={`
                              ${
                                errors.level_id_application &&
                                touched.level_id_application
                                  ? "input-error"
                                  : ""
                              }
                              block appearance-none w-full border border-gray-200 dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                  id="level_id_application"
                  name="level_id_application"
                  value={values.level_id_application}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value={0}></option>
                  {levelData.map((levelDB) => {
                    return (
                      <option
                        selected={levelDB.id_level === level}
                        value={levelDB.id_level}
                      >
                        {levelDB.designation_level}
                      </option>
                    );
                  })}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)]">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
              {errors.title_program_application &&
              touched.title_program_application ? (
                <p className="text-red-500 text-xs italic">
                  {errors.title_program_application}
                </p>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="w-full px-3 mb-4 text-md ">
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-2"
              htmlFor="grid-last-name"
            >
              Other Informations <span className="text-gray-600">*</span>
            </label>
            <textarea
              className="appearance-none block w-full dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] border border-gray-200 rounded py-3 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-last-name"
              type="text"
              placeholder="Give the designation or others details"
              name="otherInfor_application"
              value={values.otherInfor_application}
              onChange={handleChange}
              onBlur={handleBlur}
              rows={4}
            ></textarea>
          </div>
          <div className="w-full px-3 mb-4 text-md hidden">
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-2"
              htmlFor="grid-password"
            >
              Designation
            </label>
            <input
              className="appearance-none block w-full dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-password"
              type="text"
              value={designation}
              onChange={(event) => {
                setDesignation(event.target.value);
              }}
              placeholder="Other informations"
            />
            <p className="text-gray-600 text-md italic">Not required</p>
          </div>
          <div className="w-full text-md px-3 mb-4 hidden">
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-2"
              htmlFor="grid-password"
            >
              University
            </label>
            <input
              className="appearance-none block w-full dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-password"
              value={universityName}
              type="text"
            />
          </div>

          <div className="flex flex-wrap pt-4">
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                className="block capitalize text-md tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
                htmlFor="grid-city"
              >
                Evidence for accreditation at national level
                <span className="text-red-600">*</span>
              </label>

              <a
                href={`${process.env.REACT_APP_API_URL}/documents/${initialevidence_for_accreditation_at_national_level}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>
              </a>
              <label
                for="evidence_for_accreditation_at_national_level"
                class="
                                          hover:bg-green-400 
                                          font-bold py-2 
                                          px-4 rounded 
                                          cursor-pointer
                                          inline-flex items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1024 1024"
                >
                  <path
                    fill="currentColor"
                    d="M544 864V672h128L512 480 352 672h128v192H320v-1.6c-5.376.32-10.496 1.6-16 1.6A240 240 0 0 1 64 624c0-123.136 93.12-223.488 212.608-237.248A239.808 239.808 0 0 1 512 192a239.872 239.872 0 0 1 235.456 194.752c119.488 13.76 212.48 114.112 212.48 237.248a240 240 0 0 1-240 240c-5.376 0-10.56-1.28-16-1.6v1.6H544z"
                  />
                </svg>
                <span>Edit file</span>
              </label>

              <input
                className={`hidden
                                ${
                                  errors.evidence_for_accreditation_at_national_level &&
                                  touched.evidence_for_accreditation_at_national_level
                                    ? "input-error"
                                    : ""
                                }
                                appearance-none block w-full dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] border border-gray-200 rounded py-3 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                id="evidence_for_accreditation_at_national_level"
                type="file"
                accept="application/pdf"
                onChange={(event) => {
                  setFieldValue(
                    "evidence_for_accreditation_at_national_level",
                    event.currentTarget.files[0]
                  );
                }}
                onBlur={handleBlur}
              />
              {errors.evidence_for_accreditation_at_national_level &&
              touched.evidence_for_accreditation_at_national_level ? (
                <p className="text-red-500 text-xs italic">
                  {errors.evidence_for_accreditation_at_national_level}
                </p>
              ) : (
                ""
              )}
            </div>
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                className="block text-md capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
                htmlFor="grid-city"
              >
                Confirmation letter from the national agency
                <span className="text-red-600">*</span>
              </label>

              <a
                href={`${process.env.REACT_APP_API_URL}/documents/${initialconfirmation_letter_national_agency}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>
              </a>
              <label
                for="confirmation_letter_national_agency"
                class="
                                          hover:bg-green-400 
                                          font-bold py-2 
                                          px-4 rounded 
                                          cursor-pointer
                                          inline-flex items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1024 1024"
                >
                  <path
                    fill="currentColor"
                    d="M544 864V672h128L512 480 352 672h128v192H320v-1.6c-5.376.32-10.496 1.6-16 1.6A240 240 0 0 1 64 624c0-123.136 93.12-223.488 212.608-237.248A239.808 239.808 0 0 1 512 192a239.872 239.872 0 0 1 235.456 194.752c119.488 13.76 212.48 114.112 212.48 237.248a240 240 0 0 1-240 240c-5.376 0-10.56-1.28-16-1.6v1.6H544z"
                  />
                </svg>
                <span>Edit file</span>
              </label>

              <input
                className={`hidden  
                                ${
                                  errors.confirmation_letter_national_agency &&
                                  touched.confirmation_letter_national_agency
                                    ? "input-error"
                                    : ""
                                }
                                appearance-none block w-full dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] border border-gray-200 rounded py-3 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                id="confirmation_letter_national_agency"
                type="file"
                accept="application/pdf"
                onChange={(event) => {
                  setFieldValue(
                    "confirmation_letter_national_agency",
                    event.currentTarget.files[0]
                  );
                }}
                onBlur={handleBlur}
              />
              {errors.confirmation_letter_national_agency &&
              touched.confirmation_letter_national_agency ? (
                <p className="text-red-500 text-xs italic">
                  {errors.confirmation_letter_national_agency}
                </p>
              ) : (
                ""
              )}
            </div>
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md">
              <label
                className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
                htmlFor="grid-city"
              >
                Involvment of the relevant national bodies
                <span className="text-red-600">*</span>
              </label>

              <a
                href={`${process.env.REACT_APP_API_URL}/documents/${initialinvolvment_of_the_relevant_national_bodies}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>
              </a>
              <label
                for="involvment_of_the_relevant_national_bodies"
                class="
                                          hover:bg-green-400 
                                          font-bold py-2 
                                          px-4 rounded 
                                          cursor-pointer
                                          inline-flex items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1024 1024"
                >
                  <path
                    fill="currentColor"
                    d="M544 864V672h128L512 480 352 672h128v192H320v-1.6c-5.376.32-10.496 1.6-16 1.6A240 240 0 0 1 64 624c0-123.136 93.12-223.488 212.608-237.248A239.808 239.808 0 0 1 512 192a239.872 239.872 0 0 1 235.456 194.752c119.488 13.76 212.48 114.112 212.48 237.248a240 240 0 0 1-240 240c-5.376 0-10.56-1.28-16-1.6v1.6H544z"
                  />
                </svg>
                <span>Edit file</span>
              </label>

              <input
                className={`hidden
                           ${
                             errors.involvment_of_the_relevant_national_bodies &&
                             touched.involvment_of_the_relevant_national_bodies
                               ? "input-error"
                               : ""
                           }
                            appearance-none block w-full dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] border border-gray-200 rounded py-3 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                id="involvment_of_the_relevant_national_bodies"
                type="file"
                accept="application/pdf"
                onChange={(event) => {
                  setFieldValue(
                    "involvment_of_the_relevant_national_bodies",
                    event.currentTarget.files[0]
                  );
                }}
                onBlur={handleBlur}
              />
              {errors.involvment_of_the_relevant_national_bodies &&
              touched.involvment_of_the_relevant_national_bodies ? (
                <p className="text-red-500 text-xs italic">
                  {errors.involvment_of_the_relevant_national_bodies}
                </p>
              ) : (
                ""
              )}
            </div>

            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md pt-3">
              <label
                className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
                htmlFor="grid-city"
              >
                Curriculum development<span className="text-red-600">*</span>
              </label>
              {initialcurriculum_development ? (
                <a
                  href={`${process.env.REACT_APP_API_URL}/documents/${initialcurriculum_development}`}
                  target="_blank"
                  className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
                >
                  <svg
                    className="fill-current w-4 h-4 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                  </svg>
                  <span>View</span>
                </a>
              ) : (
                ""
              )}
              <label
                for="curriculum_development"
                class="
                                          hover:bg-green-400 
                                          font-bold py-2 
                                          px-4 rounded 
                                          cursor-pointer
                                          inline-flex items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1024 1024"
                >
                  <path
                    fill="currentColor"
                    d="M544 864V672h128L512 480 352 672h128v192H320v-1.6c-5.376.32-10.496 1.6-16 1.6A240 240 0 0 1 64 624c0-123.136 93.12-223.488 212.608-237.248A239.808 239.808 0 0 1 512 192a239.872 239.872 0 0 1 235.456 194.752c119.488 13.76 212.48 114.112 212.48 237.248a240 240 0 0 1-240 240c-5.376 0-10.56-1.28-16-1.6v1.6H544z"
                  />
                </svg>
                <span>Edit file</span>
              </label>

              <input
                className={`hidden
                           ${
                             errors.curriculum_development &&
                             touched.curriculum_development
                               ? "input-error"
                               : ""
                           }
                                appearance-none block w-full dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] border border-gray-200 rounded py-3 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                id="curriculum_development"
                type="file"
                accept="application/pdf"
                onChange={(event) => {
                  setFieldValue(
                    "curriculum_development",
                    event.currentTarget.files[0]
                  );
                }}
                onBlur={handleBlur}
              />
              {errors.curriculum_development &&
              touched.curriculum_development ? (
                <p className="text-red-500 text-xs italic">
                  {errors.curriculum_development}
                </p>
              ) : (
                ""
              )}
            </div>
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md pt-3 ">
              <label
                className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
                htmlFor="grid-city"
              >
                Postgraduate supervision guidelines
                <span className="text-red-600">*</span>
              </label>
              {initialpostgraduate_supervision_guidelines ? (
                <a
                  href={`${process.env.REACT_APP_API_URL}/documents/${initialpostgraduate_supervision_guidelines}`}
                  target="_blank"
                  className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
                >
                  <svg
                    className="fill-current w-4 h-4 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                  </svg>
                  <span>View</span>
                </a>
              ) : (
                ""
              )}
              <label
                for="postgraduate_supervision_guidelines"
                class="
                                          hover:bg-green-400 
                                          font-bold py-2 
                                          px-4 rounded 
                                          cursor-pointer
                                          inline-flex items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1024 1024"
                >
                  <path
                    fill="currentColor"
                    d="M544 864V672h128L512 480 352 672h128v192H320v-1.6c-5.376.32-10.496 1.6-16 1.6A240 240 0 0 1 64 624c0-123.136 93.12-223.488 212.608-237.248A239.808 239.808 0 0 1 512 192a239.872 239.872 0 0 1 235.456 194.752c119.488 13.76 212.48 114.112 212.48 237.248a240 240 0 0 1-240 240c-5.376 0-10.56-1.28-16-1.6v1.6H544z"
                  />
                </svg>
                <span>Edit file</span>
              </label>

              <input
                className={`hidden
                           ${
                             errors.postgraduate_supervision_guidelines &&
                             touched.postgraduate_supervision_guidelines
                               ? "input-error"
                               : ""
                           }
                            appearance-none block w-full dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] border border-gray-200 rounded py-3 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                id="postgraduate_supervision_guidelines"
                type="file"
                accept="application/pdf"
                onChange={(event) => {
                  setFieldValue(
                    "postgraduate_supervision_guidelines",
                    event.currentTarget.files[0]
                  );
                }}
                onBlur={handleBlur}
              />
              {errors.postgraduate_supervision_guidelines &&
              touched.postgraduate_supervision_guidelines ? (
                <p className="text-red-500 text-xs italic">
                  {errors.postgraduate_supervision_guidelines}
                </p>
              ) : (
                ""
              )}
            </div>
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md pt-3">
              <label
                className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
                htmlFor="grid-city"
              >
                Quality Assurance policy<span className="text-red-600">*</span>
              </label>
              {initialquality_assurence_policy ? (
                <a
                  href={`${process.env.REACT_APP_API_URL}/documents/${initialquality_assurence_policy}`}
                  target="_blank"
                  className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
                >
                  <svg
                    className="fill-current w-4 h-4 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                  </svg>
                  <span>View</span>
                </a>
              ) : (
                ""
              )}
              <label
                for="quality_assurence_policy"
                class="
                                          hover:bg-green-400 
                                          font-bold py-2 
                                          px-4 rounded 
                                          cursor-pointer
                                          inline-flex items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1024 1024"
                >
                  <path
                    fill="currentColor"
                    d="M544 864V672h128L512 480 352 672h128v192H320v-1.6c-5.376.32-10.496 1.6-16 1.6A240 240 0 0 1 64 624c0-123.136 93.12-223.488 212.608-237.248A239.808 239.808 0 0 1 512 192a239.872 239.872 0 0 1 235.456 194.752c119.488 13.76 212.48 114.112 212.48 237.248a240 240 0 0 1-240 240c-5.376 0-10.56-1.28-16-1.6v1.6H544z"
                  />
                </svg>
                <span>Edit file</span>
              </label>

              <input
                className={`hidden
                           ${
                             errors.quality_assurence_policy &&
                             touched.quality_assurence_policy
                               ? "input-error"
                               : ""
                           }
                            appearance-none block w-full dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] border border-gray-200 rounded py-3 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                id="quality_assurence_policy"
                type="file"
                accept="application/pdf"
                onChange={(event) => {
                  setFieldValue(
                    "quality_assurence_policy",
                    event.currentTarget.files[0]
                  );
                }}
                onBlur={handleBlur}
              />
              {errors.quality_assurence_policy &&
              touched.quality_assurence_policy ? (
                <p className="text-red-500 text-xs italic">
                  {errors.quality_assurence_policy}
                </p>
              ) : (
                ""
              )}
            </div>

            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md pt-3 ">
              <label
                className="block capitalize text-md tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
                htmlFor="grid-city"
              >
                Credit transfer policy<span className="text-gray-600">*</span>
              </label>
              {initialcredit_transfer_policy ? (
                <a
                  href={`${process.env.REACT_APP_API_URL}/documents/${initialcredit_transfer_policy}`}
                  target="_blank"
                  className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
                >
                  <svg
                    className="fill-current w-4 h-4 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                  </svg>
                  <span>View</span>
                </a>
              ) : (
                ""
              )}
              <label
                for="credit_transfer_policy"
                class="
                                          hover:bg-green-400 
                                          font-bold py-2 
                                          px-4 rounded 
                                          cursor-pointer
                                          inline-flex items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1024 1024"
                >
                  <path
                    fill="currentColor"
                    d="M544 864V672h128L512 480 352 672h128v192H320v-1.6c-5.376.32-10.496 1.6-16 1.6A240 240 0 0 1 64 624c0-123.136 93.12-223.488 212.608-237.248A239.808 239.808 0 0 1 512 192a239.872 239.872 0 0 1 235.456 194.752c119.488 13.76 212.48 114.112 212.48 237.248a240 240 0 0 1-240 240c-5.376 0-10.56-1.28-16-1.6v1.6H544z"
                  />
                </svg>
                <span>Edit file</span>
              </label>

              <input
                className={`hidden
                               ${
                                 errors.credit_transfer_policy &&
                                 touched.credit_transfer_policy
                                   ? "input-error"
                                   : ""
                               }
                                appearance-none block w-full dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] border border-gray-200 rounded py-3 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                id="credit_transfer_policy"
                type="file"
                accept="application/pdf"
                onChange={(event) => {
                  setFieldValue(
                    "credit_transfer_policy",
                    event.currentTarget.files[0]
                  );
                }}
                onBlur={handleBlur}
              />
              {errors.credit_transfer_policy &&
              touched.credit_transfer_policy ? (
                <p className="text-red-500 text-xs italic">
                  {errors.credit_transfer_policy}
                </p>
              ) : (
                ""
              )}
            </div>
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md pt-3 ">
              <label
                className="block  capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
                htmlFor="grid-city"
              >
                Summary of staff capacity<span className="text-red-600">*</span>
              </label>
              {initialsummary_of_staff_capacity ? (
                <a
                  href={`${process.env.REACT_APP_API_URL}/documents/${initialsummary_of_staff_capacity}`}
                  target="_blank"
                  className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
                >
                  <svg
                    className="fill-current w-4 h-4 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                  </svg>
                  <span>View</span>
                </a>
              ) : (
                ""
              )}
              <label
                for="summary_of_staff_capacity"
                class="
                                          hover:bg-green-400 
                                          font-bold py-2 
                                          px-4 rounded 
                                          cursor-pointer
                                          inline-flex items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1024 1024"
                >
                  <path
                    fill="currentColor"
                    d="M544 864V672h128L512 480 352 672h128v192H320v-1.6c-5.376.32-10.496 1.6-16 1.6A240 240 0 0 1 64 624c0-123.136 93.12-223.488 212.608-237.248A239.808 239.808 0 0 1 512 192a239.872 239.872 0 0 1 235.456 194.752c119.488 13.76 212.48 114.112 212.48 237.248a240 240 0 0 1-240 240c-5.376 0-10.56-1.28-16-1.6v1.6H544z"
                  />
                </svg>
                <span>Edit file</span>
              </label>

              <input
                className={`hidden
                           ${
                             errors.summary_of_staff_capacity &&
                             touched.summary_of_staff_capacity
                               ? "input-error"
                               : ""
                           }
                            appearance-none block w-full dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] border border-gray-200 rounded py-3 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                id="summary_of_staff_capacity"
                type="file"
                accept="application/pdf"
                onChange={(event) => {
                  setFieldValue(
                    "summary_of_staff_capacity",
                    event.currentTarget.files[0]
                  );
                }}
                onBlur={handleBlur}
              />
              {errors.summary_of_staff_capacity &&
              touched.summary_of_staff_capacity ? (
                <p className="text-red-500 text-xs italic">
                  {errors.summary_of_staff_capacity}
                </p>
              ) : (
                ""
              )}
            </div>
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md pt-3 ">
              <label
                className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
                htmlFor="grid-city"
              >
                Inventory of facilities<span className="text-red-600">*</span>
              </label>
              {initialinventory_of_facilities ? (
                <a
                  href={`${process.env.REACT_APP_API_URL}/documents/${initialinventory_of_facilities}`}
                  target="_blank"
                  className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
                >
                  <svg
                    className="fill-current w-4 h-4 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                  </svg>
                  <span>View</span>
                </a>
              ) : (
                ""
              )}
              <label
                for="inventory_of_facilities"
                class="
                                          hover:bg-green-400 
                                          font-bold py-2 
                                          px-4 rounded 
                                          cursor-pointer
                                          inline-flex items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1024 1024"
                >
                  <path
                    fill="currentColor"
                    d="M544 864V672h128L512 480 352 672h128v192H320v-1.6c-5.376.32-10.496 1.6-16 1.6A240 240 0 0 1 64 624c0-123.136 93.12-223.488 212.608-237.248A239.808 239.808 0 0 1 512 192a239.872 239.872 0 0 1 235.456 194.752c119.488 13.76 212.48 114.112 212.48 237.248a240 240 0 0 1-240 240c-5.376 0-10.56-1.28-16-1.6v1.6H544z"
                  />
                </svg>
                <span>Edit file</span>
              </label>

              <input
                className={`hidden
                           ${
                             errors.inventory_of_facilities &&
                             touched.inventory_of_facilities
                               ? "input-error"
                               : ""
                           }
                            appearance-none block w-full dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] border border-gray-200 rounded py-3 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                id="inventory_of_facilities"
                type="file"
                accept="application/pdf"
                onChange={(event) => {
                  setFieldValue(
                    "inventory_of_facilities",
                    event.currentTarget.files[0]
                  );
                }}
                onBlur={handleBlur}
              />
              {errors.inventory_of_facilities &&
              touched.inventory_of_facilities ? (
                <p className="text-red-500 text-xs italic">
                  {errors.inventory_of_facilities}
                </p>
              ) : (
                ""
              )}
            </div>

            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md pt-3 ">
              <label
                className="block capitalize text-md tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
                htmlFor="grid-city"
              >
                Annual survey report<span className="text-red-600">*</span>
              </label>
              {initialannual_survey_report ? (
                <a
                  href={`${process.env.REACT_APP_API_URL}/documents/${initialannual_survey_report}`}
                  target="_blank"
                  className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
                >
                  <svg
                    className="fill-current w-4 h-4 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                  </svg>
                  <span>View</span>
                </a>
              ) : (
                ""
              )}
              <label
                for="annual_survey_report"
                class="
                                          hover:bg-green-400 
                                          font-bold py-2 
                                          px-4 rounded 
                                          cursor-pointer
                                          inline-flex items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1024 1024"
                >
                  <path
                    fill="currentColor"
                    d="M544 864V672h128L512 480 352 672h128v192H320v-1.6c-5.376.32-10.496 1.6-16 1.6A240 240 0 0 1 64 624c0-123.136 93.12-223.488 212.608-237.248A239.808 239.808 0 0 1 512 192a239.872 239.872 0 0 1 235.456 194.752c119.488 13.76 212.48 114.112 212.48 237.248a240 240 0 0 1-240 240c-5.376 0-10.56-1.28-16-1.6v1.6H544z"
                  />
                </svg>
                <span>Edit file</span>
              </label>

              <input
                className={`hidden
                           ${
                             errors.annual_survey_report &&
                             touched.annual_survey_report
                               ? "input-error"
                               : ""
                           }
                                appearance-none block w-full dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] border border-gray-200 rounded py-3 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                id="annual_survey_report"
                type="file"
                accept="application/pdf"
                onChange={(event) => {
                  setFieldValue(
                    "annual_survey_report",
                    event.currentTarget.files[0]
                  );
                }}
                onBlur={handleBlur}
              />
              {errors.annual_survey_report && touched.annual_survey_report ? (
                <p className="text-red-500 text-xs italic">
                  {errors.annual_survey_report}
                </p>
              ) : (
                ""
              )}
            </div>
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md pt-3 ">
              <label
                className="block text-md capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
                htmlFor="grid-city"
              >
                Tracer study report<span className="text-gray-600">*</span>
              </label>
              {initialtracer_study_report ? (
                <a
                  href={`${process.env.REACT_APP_API_URL}/documents/${initialtracer_study_report}`}
                  target="_blank"
                  className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
                >
                  <svg
                    className="fill-current w-4 h-4 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                  </svg>
                  <span>View</span>
                </a>
              ) : (
                ""
              )}
              <label
                for="tracer_study_report"
                class="
                                          hover:bg-green-400 
                                          font-bold py-2 
                                          px-4 rounded 
                                          cursor-pointer
                                          inline-flex items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1024 1024"
                >
                  <path
                    fill="currentColor"
                    d="M544 864V672h128L512 480 352 672h128v192H320v-1.6c-5.376.32-10.496 1.6-16 1.6A240 240 0 0 1 64 624c0-123.136 93.12-223.488 212.608-237.248A239.808 239.808 0 0 1 512 192a239.872 239.872 0 0 1 235.456 194.752c119.488 13.76 212.48 114.112 212.48 237.248a240 240 0 0 1-240 240c-5.376 0-10.56-1.28-16-1.6v1.6H544z"
                  />
                </svg>
                <span>Edit file</span>
              </label>

              <input
                className={`hidden
                           ${
                             errors.tracer_study_report &&
                             touched.tracer_study_report
                               ? "input-error"
                               : ""
                           }
                            appearance-none block w-full dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] border border-gray-200 rounded py-3 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                id="tracer_study_report"
                type="file"
                accept="application/pdf"
                onChange={(event) => {
                  setFieldValue(
                    "tracer_study_report",
                    event.currentTarget.files[0]
                  );
                }}
                onBlur={handleBlur}
              />
              {errors.tracer_study_report && touched.tracer_study_report ? (
                <p className="text-red-500 text-xs italic">
                  {errors.tracer_study_report}
                </p>
              ) : (
                ""
              )}
            </div>
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md pt-3 ">
              <label
                className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
                htmlFor="grid-city"
              >
                Examination policy<span className="text-red-600">*</span>
              </label>
              {initialexamination_policy ? (
                <a
                  href={`${process.env.REACT_APP_API_URL}/documents/${initialexamination_policy}`}
                  target="_blank"
                  className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
                >
                  <svg
                    className="fill-current w-4 h-4 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                  </svg>
                  <span>View</span>
                </a>
              ) : (
                ""
              )}
              <label
                for="examination_policy"
                class="
                                          hover:bg-green-400 
                                          font-bold py-2 
                                          px-4 rounded 
                                          cursor-pointer
                                          inline-flex items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1024 1024"
                >
                  <path
                    fill="currentColor"
                    d="M544 864V672h128L512 480 352 672h128v192H320v-1.6c-5.376.32-10.496 1.6-16 1.6A240 240 0 0 1 64 624c0-123.136 93.12-223.488 212.608-237.248A239.808 239.808 0 0 1 512 192a239.872 239.872 0 0 1 235.456 194.752c119.488 13.76 212.48 114.112 212.48 237.248a240 240 0 0 1-240 240c-5.376 0-10.56-1.28-16-1.6v1.6H544z"
                  />
                </svg>
                <span>Edit file</span>
              </label>

              <input
                className={`hidden
                           ${
                             errors.examination_policy &&
                             touched.examination_policy
                               ? "input-error"
                               : ""
                           }
                            appearance-none block w-full dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] border border-gray-200 rounded py-3 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                id="examination_policy"
                type="file"
                accept="application/pdf"
                onChange={(event) => {
                  setFieldValue(
                    "examination_policy",
                    event.currentTarget.files[0]
                  );
                }}
                onBlur={handleBlur}
              />
              {errors.examination_policy && touched.examination_policy ? (
                <p className="text-red-500 text-xs italic">
                  {errors.examination_policy}
                </p>
              ) : (
                ""
              )}
            </div>

            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md pt-3">
              <label
                className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
                htmlFor="grid-city"
              >
                Admission Policy<span className="text-red-600">*</span>
              </label>
              {initialadmission_policy ? (
                <a
                  href={`${process.env.REACT_APP_API_URL}/documents/${initialadmission_policy}`}
                  target="_blank"
                  className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
                >
                  <svg
                    className="fill-current w-4 h-4 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                  </svg>
                  <span>View</span>
                </a>
              ) : (
                ""
              )}
              <label
                for="admission_policy"
                class="
                                          hover:bg-green-400 
                                          font-bold py-2 
                                          px-4 rounded 
                                          cursor-pointer
                                          inline-flex items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1024 1024"
                >
                  <path
                    fill="currentColor"
                    d="M544 864V672h128L512 480 352 672h128v192H320v-1.6c-5.376.32-10.496 1.6-16 1.6A240 240 0 0 1 64 624c0-123.136 93.12-223.488 212.608-237.248A239.808 239.808 0 0 1 512 192a239.872 239.872 0 0 1 235.456 194.752c119.488 13.76 212.48 114.112 212.48 237.248a240 240 0 0 1-240 240c-5.376 0-10.56-1.28-16-1.6v1.6H544z"
                  />
                </svg>
                <span>Edit file</span>
              </label>

              <input
                className={`hidden
                                ${
                                  errors.admission_policy &&
                                  touched.admission_policy
                                    ? "input-error"
                                    : ""
                                }
                                appearance-none 
                                block w-full mt-0
                                dark:bg-[var(--color-dark-backgroundMain)] 
                                bg-[var(--color-light-backgroundMain)] 
                                dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] border border-gray-200 rounded py-3 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                id="admission_policy"
                type="file"
                accept="application/pdf"
                onChange={(event) => {
                  setFieldValue(
                    "admission_policy",
                    event.currentTarget.files[0]
                  );
                }}
                onBlur={handleBlur}
              />
              {errors.admission_policy && touched.admission_policy ? (
                <p className="text-red-500 text-xs italic">
                  {errors.admission_policy}
                </p>
              ) : (
                ""
              )}
            </div>
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md pt-3 ">
              <label
                className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
                htmlFor="grid-city"
              >
                Program review report<span className="text-red-600">*</span>
              </label>
              {initialprogram_review_report ? (
                <a
                  href={`${process.env.REACT_APP_API_URL}/documents/${initialprogram_review_report}`}
                  target="_blank"
                  className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
                >
                  <svg
                    className="fill-current w-4 h-4 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                  </svg>
                  <span>View</span>
                </a>
              ) : (
                ""
              )}
              <label
                for="program_review_report"
                class="
                                          hover:bg-green-400 
                                          font-bold py-2 
                                          px-4 rounded 
                                          cursor-pointer
                                          inline-flex items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1024 1024"
                >
                  <path
                    fill="currentColor"
                    d="M544 864V672h128L512 480 352 672h128v192H320v-1.6c-5.376.32-10.496 1.6-16 1.6A240 240 0 0 1 64 624c0-123.136 93.12-223.488 212.608-237.248A239.808 239.808 0 0 1 512 192a239.872 239.872 0 0 1 235.456 194.752c119.488 13.76 212.48 114.112 212.48 237.248a240 240 0 0 1-240 240c-5.376 0-10.56-1.28-16-1.6v1.6H544z"
                  />
                </svg>
                <span>Edit file</span>
              </label>

              <input
                className={`hidden
                           ${
                             errors.program_review_report &&
                             touched.program_review_report
                               ? "input-error"
                               : ""
                           }
                            appearance-none block w-full dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] border border-gray-200 rounded py-3 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                id="program_review_report"
                type="file"
                accept="application/pdf"
                onChange={(event) => {
                  setFieldValue(
                    "program_review_report",
                    event.currentTarget.files[0]
                  );
                }}
                onBlur={handleBlur}
              />
              {errors.program_review_report && touched.program_review_report ? (
                <p className="text-red-500 text-xs italic">
                  {errors.program_review_report}
                </p>
              ) : (
                ""
              )}
            </div>
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md pt-3 ">
              <label
                className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
                htmlFor="grid-city"
              >
                Other not specified<span className="text-gray-600">*</span>
              </label>
              {initialother_not_specified ? (
                <a
                  href={`${process.env.REACT_APP_API_URL}/documents/${initialother_not_specified}`}
                  target="_blank"
                  className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
                >
                  <svg
                    className="fill-current w-4 h-4 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                  </svg>
                  <span>View</span>
                </a>
              ) : (
                ""
              )}
              <label
                for="other_not_specified"
                class="
                                          hover:bg-green-400 
                                          font-bold py-2 
                                          px-4 rounded 
                                          cursor-pointer
                                          inline-flex items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1024 1024"
                >
                  <path
                    fill="currentColor"
                    d="M544 864V672h128L512 480 352 672h128v192H320v-1.6c-5.376.32-10.496 1.6-16 1.6A240 240 0 0 1 64 624c0-123.136 93.12-223.488 212.608-237.248A239.808 239.808 0 0 1 512 192a239.872 239.872 0 0 1 235.456 194.752c119.488 13.76 212.48 114.112 212.48 237.248a240 240 0 0 1-240 240c-5.376 0-10.56-1.28-16-1.6v1.6H544z"
                  />
                </svg>
                <span>Edit file</span>
              </label>

              <input
                className={`hidden
                           ${
                             errors.other_not_specified &&
                             touched.other_not_specified
                               ? "input-error"
                               : ""
                           }
                            appearance-none block w-full dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] border border-gray-200 rounded py-3 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                id="other_not_specified"
                type="file"
                accept="application/pdf"
                onChange={(event) => {
                  setFieldValue(
                    "other_not_specified",
                    event.currentTarget.files[0]
                  );
                }}
                onBlur={handleBlur}
              />
              {errors.other_not_specified && touched.other_not_specified ? (
                <p className="text-red-500 text-xs italic">
                  {errors.other_not_specified}
                </p>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="text-center p-5">
            <button
              type="submit"
              className="dark:bg-[var(--color-dark-buttonBackground)] 
                        bg-[var(--color-light-buttonBackground)] 
                        dark:text-[var(--color-dark-fontButton)] 
                        text-[var(--color-light-fontButton)]  
                        w-[100px]  py-3 rounded-md font-medium"
            >
              {" "}
              {editing ? "Edit" : "Savee"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { login })(ApplicationAdd);
