import React from 'react'
 


const FooterLoggedIn = () => {
  return ( 
<div className='justify-center  items-center font-loader'>  
            <p className='text-center'>
             Copyrigth IUCEA &copy; 2022-{new Date().getFullYear() + 1}
            </p>  
         
    </div> 
  )
}

export default FooterLoggedIn