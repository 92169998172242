import axios from "axios";
class AppealService {
    
    async getAll(access, url) {
        axios.defaults.headers.common["Authorization"] = "JWT " + access;
        // axios.defaults.headers.common["X-Frame-Options"] = "sameorigin";
        axios.defaults.headers.common["Content-type"] = "multipart/form-data";
        axios.defaults.headers.common["Accept-Encoding"] = "gzip, deflate, br";
        const result = await axios.get(`${url}`);
        return result;
      }
      createAppeal(data, access, userEmail, title, userApplicationUserName) {
        axios.defaults.headers.common["Authorization"] = "JWT " + access;
        axios.defaults.headers.common["Content-type"] = "multipart/form-data";
        axios.defaults.headers.common["Accept-Encoding"] = "gzip, deflate, br";
      // console.log("userEmail SEr",userEmail);
        return axios.post(
          `${process.env.REACT_APP_API_URL}/api/appeal/create/${userEmail}/${title}/${userApplicationUserName}`,
          data
        );
      }

      async getAllExpert(access, url) {
        axios.defaults.headers.common["Authorization"] = "JWT " + access;
        // axios.defaults.headers.common["X-Frame-Options"] = "sameorigin";
        axios.defaults.headers.common["Content-type"] = "multipart/form-data";
        const result = await axios.get(`${url}`);
        return result;
      }

      addExpertOnAppeal(data, access, email, initialTitle_program_application) {
        axios.defaults.headers.common["Authorization"] = "JWT " + access;
        axios.defaults.headers.common["Content-type"] = "multipart/form-data";
         
        console.log("data: ");
        console.log(data);
    
        return axios.post(
          `${process.env.REACT_APP_API_URL}/api/appealExpert/create/${email}/${initialTitle_program_application}`,
          data
        );
      }

      editAppelExpert(id,data, access, email) {
        axios.defaults.headers.common["Authorization"] = "JWT " + access;
        axios.defaults.headers.common["Content-type"] = "multipart/form-data";
         
        console.log("data: ");
        console.log(data);
    
        return axios.put(
          `${process.env.REACT_APP_API_URL}/api/appealExpert/edit/${id}/`,
          data
        );
      }

      appealLastDecion(id,data, access, Senderemail, applicantName,applicationEmail,titleProgramme) {
        axios.defaults.headers.common["Authorization"] = "JWT " + access;
        axios.defaults.headers.common["Content-type"] = "multipart/form-data";
           
        return axios.put(
          `${process.env.REACT_APP_API_URL}/api/appeal/edit/${id}/${Senderemail}/${applicantName}/${applicationEmail}/${titleProgramme}`,
          data
        );
      }

      submissionReviewAppeal(id,data, access, Senderemail, applicantName,applicationEmail,titleProgramme) {
        axios.defaults.headers.common["Authorization"] = "JWT " + access;
        axios.defaults.headers.common["Content-type"] = "multipart/form-data";
          //  'CHEADecision/edit/<str:pk>/<str:email>/<str:title>/<str:applicantName>/<str:idApplication>'
        return axios.put(
          `${process.env.REACT_APP_API_URL}/api/appeal/createReportAppeal/${id}/${Senderemail}/${applicantName}/${applicationEmail}/${titleProgramme}`,
          data
        );
      }

      addExpertAppeal(data, access, email, initialTitle_program_application) {
        axios.defaults.headers.common["Authorization"] = "JWT " + access;
        axios.defaults.headers.common["Content-type"] = "multipart/form-data";
        console.log(data);
        return axios.post(
          `${process.env.REACT_APP_API_URL}/api/applicationAffectationAppeal/create/${email}/${initialTitle_program_application}`,
          data
        );
      }
    
    
}

export default new AppealService();
