import React from "react";
import "./CircularButton.css"; // Import your CSS file

const CircularButton = ({ value, color }) => {
  const buttonStyle = {
    backgroundColor: color,
  };

  return (
    <div className="circular-button" style={buttonStyle}>
      {value}
    </div>
  );
};

export default CircularButton;
