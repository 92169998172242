import React from 'react'



const Cards = () => {
  return (
    <div className='w-full  dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] py-[10rem] px-4 text-center '>
        <div className='max-w-[1240px] mx-auto grid md:grid-cols-3 gap-8'>
            <div className='w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300 dark:bg-[var(--color-dark-bakcgroundCard)] bg-[var(--color-light-bakcgroundCard)]'>
                <img className='w-40 mx-auto ' src="/assets/design/eacLogo.png" alt="/" />
                <h2 className='text-2xl font-bold text-center py-8'>Get Services:</h2>
                <div>
                    <p className='text-center text-2xl font-bold'>Accreditation</p>
                    <p className='text-center font-medium py-2 border-b mx-8'>Job Opportunities</p>
                    <p className='text-center font-medium py-2 border-b mx-8'>Shoolarship</p> 
                </div>
                <button className='dark:text-[var(--color-dark-fontButton)] text-[var(--color-light-fontButton)] dark:bg-[var(--color-dark-buttonBackground)] bg-[var(--color-light-buttonBackground)] w-[150px] rounded-md font-medium mx-auto my-6 py-3 '>More ..</button>
            </div>
            

            <div className='w-full shadow-xl flex flex-col p-4 md:my-0 my-8 rounded-lg hover:scale-105 duration-300 dark:bg-[var(--color-dark-bakcgroundCard)] bg-[var(--color-light-bakcgroundCard)]'>
                <img className='w-40 mx-auto bg-transparent' src="/assets/design/iuceaLogo.png" alt="/" />
                <h2 className='text-2xl font-bold text-center py-8'>Get Services:</h2>
                <div>
                    <p className='text-center text-2xl font-bold'>Accreditation</p>
                    <p className='text-center font-medium py-2 border-b mx-8'>Job Opportunities</p>
                    <p className='text-center font-medium py-2 border-b mx-8'>News</p> 
                </div>
                <button className='dark:text-[var(--color-dark-fontButtonSpecial)] text-[var(--color-light-fontButtonSpecial)] dark:bg-[var(--color-dark-buttonBackgroundSpecial)] bg-[var(--color-light-buttonBackgroundSpecial)]  w-[150px] rounded-md font-medium mx-auto my-6 py-3 '>More ..</button>
            </div>


            <div className='w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300 dark:bg-[var(--color-dark-bakcgroundCard)] bg-[var(--color-light-bakcgroundCard)]'>
                <img className='w-40 mx-auto ' src="/assets/design/qaLogo.png" alt="/" />
                <h2 className='text-2xl font-bold text-center py-8'>Get Services:</h2>
                <div>
                    <p className='text-center text-2xl font-bold'>Accreditation</p>
                    <p className='text-center font-medium py-2 border-b mx-8'>News</p>
                    <p className='text-center font-medium py-2 border-b mx-8'>Others</p> 
                </div>
                <button className='dark:text-[var(--color-dark-fontButton)] text-[var(--color-light-fontButton)] dark:bg-[var(--color-dark-buttonBackground)] bg-[var(--color-light-buttonBackground)]  w-[150px] rounded-md font-medium mx-auto my-6 py-3 '>More ..</button>
            </div>
        </div>
    </div>
  )
}

export default Cards