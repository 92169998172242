import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import UserService from "../../../services/UserService";
import { connect } from "react-redux";
import Loading from "../../eventsHandler/Loading";
import UniversityService from "../../../services/UniversityService";
import ChairSignatorieService from "../../../services/ChairSignatorieService";

const ChairSignatoriesAdd = (props) => {
  let editing = false;
  let initialacademicTitle_signatory = "";
  let initialfullName_signatory = "";
  let initialsignature_signatory = "";
  let initialtitle_signatory = "";

  const history = useHistory();
  const [choicesDB, setchoicesDB] = useState(null);
  const [countryDB, setcountryDB] = useState(null);

  useEffect(() => {
    // get Small dataComponet
    UniversityService.getSmallData(access)
      .then((response) => {
        setchoicesDB(response.data);
        console.log(response.data.establishment_choices);
      })
      .catch((errors) => {
        console.log("Error : ", errors);
        if (errors.response.status === 401) {
          // Redirect to the login page
          history.push('/login');
        }    
      });

    UniversityService.getCountries(access)
      .then((response) => {
        setcountryDB(response.data);
        console.log(response.data.establishment_choices);
      })
      .catch((erro) => {
        console.log("Error : ", erro);
      });
  }, []);

  console.log("isAuthenticated: ", localStorage.getItem("connected"));
  console.log("userConnected: ", localStorage.getItem("userId"));

  if (props.location.state && props.location.state.currentUser) {
    editing = true;
    initialacademicTitle_signatory =
      props.location.state.currentUser.academicTitle_signatory;
    initialfullName_signatory =
      props.location.state.currentUser.fullName_signatory;
    initialsignature_signatory =
      props.location.state.currentUser.signature_signatory;
    initialtitle_signatory = props.location.state.currentUser.title_signatory;
  }

  let access = localStorage.getItem("access");

  const [buttonDisabled, setButtonDisabled] = useState(false);

  const [academicTitle_signatory, setacademicTitle_signatory] = useState(
    initialacademicTitle_signatory
  );
  const [fullName_signatory, setfullName_signatory] = useState(
    initialfullName_signatory
  );
  const [signature_signatory, setsignature_signatory] = useState(null);
  const [title_signatory, settitle_signatory] = useState(
    initialtitle_signatory
  );

  let univData = JSON.parse(localStorage.getItem("universityData"));
  // console.log("LevelData: ", univData);

  const saveUser = () => {
    setButtonDisabled(true);
    const newSignatory = new FormData();
    newSignatory.append("academicTitle_signatory", academicTitle_signatory);
    newSignatory.append("fullName_signatory", fullName_signatory);
    if (signature_signatory) {
      newSignatory.append("signature_signatory", signature_signatory);
    }
    newSignatory.append("title_signatory", title_signatory);

    console.log(newSignatory);
    if (!editing) {
      // addiing

      ChairSignatorieService.createSignatorie(
        newSignatory,
        localStorage.getItem("access")
      )
        .then((response) => {
          let data = {
            status: 1,
            title: "Okay",
            message: "Signatory added successFully ...",
          };
          history.push("/Chairsignatory/list", { data: data });
        })
        .catch((error) => {
          console.log("Carmel Error: ");
          let data = {
            status: 0,
            title: "Error",
            message: "An error happened ...",
          };
          history.push("/Chairsignatory/list", { data: data });
        });
    } else {
      // edit user
      console.log(newSignatory);
      ChairSignatorieService.updateSignatorie(
        props.location.state.currentUser.id,
        newSignatory,
        localStorage.getItem("access")
      )
        .then((response) => {
          let data = {
            status: 1,
            title: "Okay",
            message: "Signatory edit successFully ...",
          };
          history.push("/Chairsignatory/list", { data: data });
        })
        .catch((e) => {
          let data = {
            status: 0,
            title: "Error",
            message: "An Error happened !",
          };
          history.push("/Chairsignatory/list", { data: data });
        });
    }
    setButtonDisabled(false);
  };

  if (!choicesDB || !countryDB) {
    // Render a loading indicator or null if you don't want anything to show while waiting for data
    return <p> {<Loading />}</p>;
  }

  return (
    <div>
      <div className="w-full p-8">
        {buttonDisabled && <Loading />}
        <div className="w-full px-3 mb-4">
          <label
            className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-2"
            htmlFor="grid-password"
          >
            Name
            <span className="text-red-600">*</span>
          </label>
          <input
            required
            className="appearance-none block w-full dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            id="academicTitle_signatory"
            type="text"
            value={academicTitle_signatory}
            name="academicTitle_signatory"
            onChange={(event) => setacademicTitle_signatory(event.target.value)}
            placeholder="academic title signatory"
          />
        </div>
        <div className="w-full px-3 mb-4">
          <label
            className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-2"
            htmlFor="grid-password"
          >
            Full Name
            <span className="text-red-600">*</span>
          </label>
          <input
            required
            className="appearance-none block w-full dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            id="grid-password"
            type="text"
            name="fullName_signatory"
            value={fullName_signatory}
            onChange={(event) => setfullName_signatory(event.target.value)}
            placeholder="fullName signatory"
          />
        </div>
        <div className="w-full px-3 mb-4">
          <label
            className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-2"
            htmlFor="grid-password"
          >
            Signature{editing ? " select a new file if to update" : ""}
            <span className="text-red-600">*</span>
          </label>
          <input
            required
            className="appearance-none block w-full dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            id="signature_signatory"
            name="signature_signatory"
            type="file"
            onChange={(event) => setsignature_signatory(event.target.files[0])}
            placeholder="signature signatory"
          />
        </div>
        <div className="w-full px-3 mb-6 md:mb-0">
          <label
            className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-2"
            htmlFor="grid-first-name"
          >
            Title<span className="text-red-600">*</span>
          </label>
          <div className="relative">
            <select
              required
              className="block appearance-none w-full border border-gray-200 dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-state"
              name="title_signatory"
              value={title_signatory}
              onChange={(event) => settitle_signatory(event.target.value)}
            >
              <option value="" selected disabled>
                Please select{" "}
              </option>
              {choicesDB.titleSignatories.map((titleSignatories) => {
                return (
                  <option
                    selected={titleSignatories === initialtitle_signatory}
                    value={titleSignatories}
                  >
                    {titleSignatories}
                  </option>
                );
              })}
            </select>
          </div>{" "}
        </div>{" "}
      </div>

      <div className="text-center p-5">
        <button
          variant="info"
          className="dark:bg-[var(--color-dark-buttonBackground)]  bg-[var(--color-light-buttonBackground)] dark:text-[var(--color-dark-fontButton)] text-[var(--color-light-fontButton)]  w-[150px]  py-3 rounded-md font-medium"
          onClick={() => {
            saveUser();
          }}
        >
          {" "}
          {editing ? "Edit" : "Add"}
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  userConnected: state.auth.user,
});

export default connect(mapStateToProps)(ChairSignatoriesAdd);
