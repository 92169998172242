import React, { useState, useEffect } from "react";
import { FaEye, FaHandSparkles } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";
import ApplicationService from "../../../services/ApplicationService";
import moment from "moment";
import Error from "../../alerts/Error";
import Success from "../../alerts/Success";
import ConfirmLink from "react-confirm-dialog";
import { connect } from "react-redux";
import Loading from "../../eventsHandler/Loading";
import AppealService from "../../../services/AppealService";

const AppealList = ({ userConnected, isAuthenticated }) => {
  const [listData, setListData] = useState([]);
  const [nextUrl, setNextUrl] = useState();
  const [previoustUrl, setPrevioustUrl] = useState();
  const [disableSaveBtn, setDisableSaveBtn] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalNumberData, setTotalNumberData] = useState(0);
  const [totalNumberPerPage, setTotalNumberPerPage] = useState(0);

  const history = useHistory();

  const userInfo = localStorage.getItem("userInfo");
  const userConnectedhere = JSON.parse(userInfo);
  const statusStaff = userConnectedhere.is_staff;
  let changeStatus = 0;
  if (statusStaff) {
    changeStatus = 1;
  }
  const userId = userConnectedhere.id;
  const baseUrl = `${process.env.REACT_APP_API_URL}/api/appeal/list/${changeStatus}/${userId}`;

  useEffect(() => {
    retrieveApplications();
  }, [currentPage]);

  const retrieveApplications = () => {
    setDisableSaveBtn(true);
    const pageUrl = `${baseUrl}?page=${currentPage}`;
    var access = localStorage.getItem("access");
    AppealService.getAll(access, baseUrl)
      .then((response) => {
        setNextUrl(response.data.next);
        setPrevioustUrl(response.data.previous);
        setListData(response.data.results);
        setTotalPages(response.data.total_pages); // Update total pages
        setTotalNumberData(response.data.total_records);
        setTotalNumberPerPage(response.data.items_per_page);
        console.log(response.data.results);
      })
      .catch((e) => {
        console.log("error : ", e);
        if (e.response.status === 401) {
          // Redirect to the login page
          history.push('/login');
        }    
      })
      .finally(() => {
        setDisableSaveBtn(false);
      });
  };

  // delete an application
  ConfirmLink.defaultProps.confirmMessage =
    "Are you sure you want to delete this?";
  ConfirmLink.defaultProps.confirmText = "Yes please";
  ConfirmLink.defaultProps.cancelText = "No, thanks";
  const deleteConfirm = (applicId) => {
    deleteApplication(applicId);
  };

  const deleteApplication = (applicationId) => {
    var access = localStorage.getItem("access");
    ApplicationService.deleteApplication(applicationId, access)
      .then((response) => {
        let data = {
          status: 1,
          title: "Done",
          message: "Deleted Successfully",
        };
        history.push("/cheaReport", { data: data });
        retrieveApplications();
      })
      .catch((e) => {
        if (e.response.status === 401) {
          // Redirect to the login page
          history.push('/login');
        }    
        let data = {
          status: 0,
          title: "Error",
          message: "An Error happened !",
        };
        history.push("/cheaReport", { data: data });
      });
  };

  const [showModal, setShowModal] = React.useState(false);
  const [show, setShow] = useState(false);
  const [selectedData, setSelectedData] = useState({});

  const hanldeClick = (selectedRec) => {
    setSelectedData(selectedRec);
    setShowModal(true);
    setShow(true);
  };

  const hideModal = () => {
    setShow(false);
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(listData);

  useEffect(() => {
    setFilteredData(listData);
  }, [listData]);

  const handleSearch = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setSearchTerm(searchTerm);

    if (searchTerm.trim() === "") {
      // If search input is empty, display all data
      setFilteredData(listData);
    } else {
      // Filter data based on search term
      const filteredResults = listData.filter((item) =>
        item.executiveCommittee.CHEADecisionData[0].applicationData.title_program_application
          .toLowerCase()
          .includes(searchTerm)
      );
      setFilteredData(filteredResults);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  return (
    <div className="w-full p-1 mt-4">
      {disableSaveBtn && <Loading />}
      {history?.location?.state === null ? (
        ""
      ) : history?.location?.state?.data?.status === 1 ? (
        <Success delay="5000" valueFromParent={history?.location?.state?.data} />
      ) : history?.location?.state?.data?.status === 0 ?(
        <Error delay="7000" valueFromParent={history?.location?.state?.data} />
      ) : <span></span>}

      <div className="m-0 relative shadow-md sm:rounded-lg">
        <span
          className=" text-gray-500
                          mb-2 
                          hover:text-gray-800"
        >
          {" "}
        </span>

        <div className="items-center justify-between mr-2 pb-1 grid grid-cols-3">
          {/* <Link to={"/expertReview/create"}>
            <button className="flex space-x-2 items-center px-3 py-2 bg-blue-500 hover:bg-blue-800 rounded-md drop-shadow-md">
              <svg
                className="fill-white"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="20"
                height="20"
                viewBox="0 0 24 24"
              >
                <path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z" />
              </svg>
              <span className="text-white">Add</span>
            </button>
          </Link> */}
          <div className=""></div>
          <div className=""></div>
          <div className=""></div>
          <div className="mr-8">
            <label htmlFor="table-search" className="sr-only">
              Search
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  className="w-5 h-5 text-[var(--color-tbl200)] dark:text-[var(--color-gray-tbl400)]"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>
              <input
                type="text"
                id="table-search"
                value={searchTerm}
                onChange={handleSearch}
                className="block p-2 pl-10 text-md text-[var(--color-gray-tbl900)] border border-[var(--color-gray-tbl300)] rounded-lg w-80 bg-[var(--color-gray-tbl50)] focus:ring-blue-500 focus:border-blue-500 dark:bg-[var(--color-gray-tbl700)] dark:border-[var(--color-gray-tbl600)] dark:placeholder-[var(--color-gray-tbl400)] dark:text-[var(--color-gray-tbl0)] dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Search for items by program title"
              />
            </div>
          </div>
        </div>
        <div className="flex">
          <table
            className="w-full 
              text-md 
              text-left 
              text-[var(--color-tbl200)] 
              dark:text-[var(--color-gray-tbl400)]"
          >
            <thead className="text-md text-[var(--color-gray-tbl700)] capitalize bg-[var(--color-gray-tbl50)] dark:bg-[var(--color-gray-tbl700)] dark:text-[var(--color-gray-tbl400)]">
              <tr id="11s" key={1}>
                <th scope="col" className="p-4">
                  <div className="flex items-center">
                    <input
                      id="checkbox-all-search"
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-[var(--color-gray-tbl100)] border-[var(--color-gray-tbl300)] rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-[var(--color-gray-tbl800)] dark:focus:ring-offset-[var(--color-gray-tbl800)] focus:ring-2 dark:bg-[var(--color-gray-tbl700)] dark:border-[var(--color-gray-tbl600)]"
                    />
                    <label htmlFor="checkbox-all-search" className="sr-only">
                      checkbox
                    </label>
                  </div>
                </th>
                <th scope="col" className="px-6 py-3">
                  Programme title
                </th>
                <th scope="col" className="px-6 py-3">
                  Constant
                </th>

                <th scope="col" className="px-6 py-3">
                  Submitted By
                </th>

                <th scope="col" className="px-6 py-3">
                  Date
                </th>

                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((application) => {
                return (
                  <tr
                    className="bg-[var(--color-gray-tbl0)] 
                  border-b dark:bg-[var(--color-gray-tbl800)] 
                  dark:border-[var(--color-gray-tbl700)] 
                  hover:bg-[var(--color-gray-tbl200)] 
                      dark:hover:bg-[var(--color-gray-tbl600)]"
                    key={application.id_appeal}
                  >
                    <td className="w-4 p-4">
                      <div className="flex items-center">
                        <input
                          id="checkbox-table-search-1"
                          type="checkbox"
                          className="w-4 h-4 text-blue-600 bg-[var(--color-gray-tbl100)] border-[var(--color-gray-tbl300)] rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-[var(--color-gray-tbl800)] dark:focus:ring-offset-[var(--color-gray-tbl800)] focus:ring-2 dark:bg-[var(--color-gray-tbl700)] dark:border-[var(--color-gray-tbl600)]"
                        />
                        <label
                          htmlFor="checkbox-table-search-1"
                          className="sr-only"
                        >
                          checkbox
                        </label>
                      </div>
                    </td>

                    <td className="px-6 py-4">
                      {
                        application.executiveCommittee.CHEADecisionData[0].applicationData
                          .title_program_application
                      }
                    </td>

                    <td
                      className={`px-6 py-4 text-ellipsis ${
                        Number(application.status_Appeal) === 0
                          ? "text-red-600"
                          : "text-yellow-600"
                      }`}
                    >
                      {application.constant_appeal}
                    </td>
                    <td className="px-6 py-4 text-ellipsis">
                      {application.userInfo.username}
                    </td>
                    <td className="px-6 py-4">
                      {moment(application.date_created_appeal).format(
                        "Do MMMM YYYY HH:mm"
                      )}
                    </td>

                    <td className="px-1 py-1">
                      <div className="grid grid-cols-2">
                        {changeStatus > 0 &&
                        Number(application.numberRemaining) === 0 ? (
                          <>
                            {Number(application.status_Appeal) === 0 ? (
                              <Link
                                to={{
                                  pathname:
                                    "/appealAddExpert/" +
                                    application.id_appeal +
                                    "/appealAddExpert",
                                  state: {
                                    application: application,
                                    typeOperation: "AddExpert",
                                  },
                                }}
                              >
                                <span
                                  rel="noopener noreferrer"
                                  className="
                                  font-medium  
                                  hover:underline"
                                >
                                  add expert
                                </span>
                              </Link>
                            ) : (
                              <>
                                {Number(application.status_Appeal) > 2 ? (
                                  <>
                                  {Number(application.status_Appeal) === 3 ? <span className="text-red-600">Unceptable</span>: <span className="text-green-600">Accepted</span>}
                                  </>
                                ) : (
                                  <Link
                                    to={{
                                      pathname:
                                        "/appealFeedBackRepport/" +
                                        application.id_application +
                                        "/view",
                                      state: {
                                        currentApplication: application,
                                        typeOperation: "Last Feedback",
                                      },
                                    }}
                                  >
                                    <span
                                      rel="noopener noreferrer"
                                      className="   text-gray-600
                                           font-medium  
                                           hover:underline"
                                    >
                                      give feedback
                                    </span>
                                  </Link>
                                )}{" "}
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {Number(application.numberRemaining) <= 0 ? (
                              <Link
                                to={{
                                  pathname:
                                    "/appealAddExpert/" +
                                    application.id_application +
                                    "/view",
                                  state: {
                                    currentApplication: application,
                                    typeOperation: "last Decison",
                                  },
                                }}
                              >
                                <span
                                  rel="noopener noreferrer"
                                  className="   text-blue-600
                                              font-medium  
                                              hover:underline"
                                >
                                  Last Decision
                                </span>
                              </Link>
                            ) : (
                              <span
                                rel="noopener noreferrer"
                                className="
                                            font-medium  
                                            hover:underline"
                              >
                                <FaEye />
                                {Number(application.status_Appeal) === 0 ? (
                                  <span>Under Review</span>
                                ) : (
                                  ""
                                )}
                              </span>
                            )}
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}

              {filteredData.length === 0 && (
                <tr>
                  <td className="border border-gray-300 px-4 py-2" colSpan="6">
                    No matching results found...
                  </td>
                </tr>
              )}
            </tbody>
          </table>{" "}
        </div>

        <div className="w-full">
          {/* <!-- Start coding here --> */}
          <div className="relative overflow-hidden bg-[var(--color-gray-tbl0)] rounded-b-lg shadow-md dark:bg-[var(--color-gray-tbl800)]">
            <nav
              className="flex flex-col items-start justify-between p-4 space-y-3 md:flex-row md:items-center md:space-y-0"
              aria-label="Table navigation"
            >
              <span className="text-md font-normal text-[var(--color-gray-tbl500)] dark:text-[var(--color-gray-tbl400)]">
                Showing{" "}
                <span className="font-semibold text-[var(--color-gray-tbl900)] dark:text-[var(--color-gray-tbl0)]">
                  1-{totalNumberPerPage}
                </span>{" "}
                of{" "}
                <span className="font-semibold text-[var(--color-gray-tbl900)] dark:text-[var(--color-gray-tbl0)]">
                  {totalNumberData}
                </span>
              </span>
              <ul className="inline-flex items-stretch -space-x-px">
                <div className="flex items-center justify-between px-4 py-2">
                  <li>
                    <button
                      onClick={handlePreviousPage} // Call the new function here
                      disabled={currentPage <= 1}
                      className={`px-3 py-1 text-md font-semibold rounded-lg focus:outline-none ${
                        currentPage <= 1
                          ? "opacity-50 cursor-not-allowed"
                          : "bg-[var(--color-gray-tbl0)] hover:bg-blue-600 text-black hover:text-white"
                      }`}
                    >
                      <svg
                        className="w-5 h-5"
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={handleNextPage}
                      disabled={currentPage >= totalPages}
                      className={`px-3 py-1 text-md font-semibold rounded-lg focus:outline-none ${
                        currentPage >= totalPages
                          ? "opacity-50 cursor-not-allowed"
                          : "bg-[var(--color-gray-tbl0)] hover:bg-blue-600 text-black hover:text-white"
                      }`}
                    >
                      <svg
                        className="w-5 h-5"
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </button>
                  </li>
                </div>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  userConnected: state.auth.user,
});

export default connect(mapStateToProps)(AppealList);
