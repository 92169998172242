import axios from "axios";

class NotificationService {
    
  async getAll(access, url) {
    axios.defaults.headers.common["Authorization"] = "JWT " + access;
    // axios.defaults.headers.common["X-Frame-Options"] = "sameorigin";
    axios.defaults.headers.common["Content-type"] = "multipart/form-data";
    axios.defaults.headers.common["Accept-Encoding"] = "gzip, deflate, br";
 
    const result = await axios.get(`${url}`);
    return result;
  }
 
  updateStatusApplication(id, access) {
    axios.defaults.headers.common["Authorization"] = "JWT " + access;
    axios.defaults.headers.common["Content-type"] = "multipart/form-data";
    
    return axios.put(
      `${process.env.REACT_APP_API_URL}/api/application/${id}/delete/`
    );
  }
}

export default new NotificationService();
