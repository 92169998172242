import axios from "axios"

class ExecutiveCommitteeService {

    async getAll(access, url) {
        axios.defaults.headers.common["Authorization"] = "JWT " + access;
        // axios.defaults.headers.common["X-Frame-Options"] = "sameorigin";
        axios.defaults.headers.common["Content-type"] = "multipart/form-data";
        axios.defaults.headers.common["Accept-Encoding"] = "gzip, deflate, br";
        const result = await axios.get(`${url}`);
        return result;
      }
       
    
      createExecutiveCommittee(data, access, userEmail, title, applicantName, idApplication) {
        axios.defaults.headers.common["Authorization"] = "JWT " + access;
        axios.defaults.headers.common["Content-type"] = "multipart/form-data";
        axios.defaults.headers.common["Accept-Encoding"] = "gzip, deflate, br";
      // console.log("userEmail SEr",userEmail);
        return axios.post(
          `${process.env.REACT_APP_API_URL}/api/ExecutiveCommittee/create/${userEmail}/${title}/${applicantName}/${idApplication}`,
          data
        );
      }

        
      udateExecutiveCommitteeData(id,data, access, userEmail, title, applicantName, idApplication) {
        axios.defaults.headers.common["Authorization"] = "JWT " + access;
        axios.defaults.headers.common["Content-type"] = "multipart/form-data";
        axios.defaults.headers.common["Accept-Encoding"] = "gzip, deflate, br";
      // console.log("userEmail SEr",userEmail);
        return axios.put(
          `${process.env.REACT_APP_API_URL}/api/ExecutiveCommittee/edit/${id}/${userEmail}/${title}/${applicantName}/${idApplication}`,
          data
        );
      }

      submitExecutive(data, access, userEmail, title, applicantName, idApplication, university) {
        axios.defaults.headers.common["Authorization"] = "JWT " + access;
        axios.defaults.headers.common["Content-type"] = "multipart/form-data";
        axios.defaults.headers.common["Accept-Encoding"] = "gzip, deflate, br"; 
        return axios.post(
          `${process.env.REACT_APP_API_URL}/api/CHEADecision/createReport/${userEmail}/${title}/${applicantName}/${idApplication}/${university}`,
          data
        );
      }

      submitReportExecutive(id,data, access, userEmail, title, applicantName, university) {
        axios.defaults.headers.common["Authorization"] = "JWT " + access;
        axios.defaults.headers.common["Content-type"] = "multipart/form-data";
        axios.defaults.headers.common["Accept-Encoding"] = "gzip, deflate, br"; 
        return axios.put(
          `${process.env.REACT_APP_API_URL}/api/CHEADecision/createReportCHEAAffectation/${id}/${userEmail}/${title}/${applicantName}/${university}`,
          data
        );
      }

      submitReportExecutiveCommitteeCreate(data, access, userEmail, title, applicantName, idApplication, university) {
        const headers = {
          "Authorization": "JWT " + access,
          "Content-Type": "multipart/form-data",
          "Accept-Encoding": "gzip, deflate, br"
        };
      
        return axios.post(
          `${process.env.REACT_APP_API_URL}/api/CHEADecision/createReport/${userEmail}/${title}/${applicantName}/${idApplication}/${university}`,
          data,
          headers
        );
      }
 
    
      updateStatus(id, access){ 
        axios.defaults.headers.common["Authorization"] = "JWT " + access;
        axios.defaults.headers.common["Content-type"] = "multipart/form-data";
        axios.defaults.headers.common["Accept-Encoding"] = "gzip, deflate, br";
     
        return axios.patch(
          `${process.env.REACT_APP_API_URL}/api/accreditation/${id}/edit` 
        );
      }
     
      editExecutiveCommittee(id, data, access, userEmail = null) {
        axios.defaults.headers.common["Authorization"] = "JWT " + access;
        axios.defaults.headers.common["Content-type"] = "multipart/form-data";
        axios.defaults.headers.common["Accept-Encoding"] = "gzip, deflate, br";
    
        console.log("data: ");
        console.log(id);
        if (userEmail) {
          console.log("email: ");
        console.log(userEmail);
          return axios.put(
            `${process.env.REACT_APP_API_URL}/api/accreditation/${id}/${userEmail}/editEmail`,
            data
          );
        } else {
          return axios.put(
            `${process.env.REACT_APP_API_URL}/api/accreditation/${id}`,
            data
          );
        }
      }
    
      deleteExecutiveCommittee(id, access) {
        axios.defaults.headers.common["Authorization"] = "JWT " + access;
        axios.defaults.headers.common["Content-type"] = "multipart/form-data";
        axios.defaults.headers.common["Accept-Encoding"] = "gzip, deflate, br";
    
        return axios.put(
          `${process.env.REACT_APP_API_URL}/api/ExecutiveCommittee/${id}/delete/`
        );
      }


  addExpertExecutiveCommittee(data, access, email, initialTitle_program_application) {
    axios.defaults.headers.common["Authorization"] = "JWT " + access;
    axios.defaults.headers.common["Content-type"] = "multipart/form-data";
      
    return axios.post(
      `${process.env.REACT_APP_API_URL}/api/applicationAffectationExecutiveCommittee/create/${email}/${initialTitle_program_application}`,
      data
    );
  }
}

export default new ExecutiveCommitteeService()