import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Cell,
} from "recharts";

const getStatusColor = (status) => {
  if (status === "InProgress") {
    return "#FFC107"; // Yellow
  } else if (status === "Completed") {
    return "#4CAF50"; // Green
  } else if (status === "Pending") {
    return "#FF5722"; // Red
  }
};

const ColoredBar = ({ dataKey, status }) => {
  const fill = getStatusColor(status);
  return <Bar dataKey={dataKey} fill={fill} />;
};

const ProgressChartComponent = (props) => {
  const dataWithColors = props.data.map((item) => ({
    ...item,
    color: getStatusColor(item.status),
  }));
  
  return (
    <BarChart width={400} height={300} data={dataWithColors}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="status" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey="value">
        {dataWithColors.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={entry.color} />
        ))}
      </Bar>
    </BarChart>
  );
};

export default ProgressChartComponent;
