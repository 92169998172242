export const preliminaryAssessmentForDeskReviewVisualization = (dataSent) => ({
  point1: [
    {
      bigHeader: "Preliminary Review",
      content: [
        {
          subData: [
            {
              number: ``,
              subtitleStandard: ``,
              subcontent: [
                {
                  subtitleNorm: ``,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">S/N</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Item/Aspect
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">Reviewers Remarks</span>
                      ),
                    },
                    {
                      column4: <span className="">1)</span>,
                      column5:
                      (
                        <label
                        className="block mb-2"
                        dangerouslySetInnerHTML={{
                          __html: `${(`1.0 Accreditation status of
                          the programme \n (This includes evidence of
                          recent audit of
                          programme)`).replace(
                            /\n/g,
                            "<br />"
                          )}`  }}
                      />
                     ),

                      column6: (
                        <>
                          {dataSent.accreditationStatusOfTheProgramme ? (
                            <label
                              className="block mb-2"
                              dangerouslySetInnerHTML={{
                                __html: `${dataSent?.accreditationStatusOfTheProgramme?.replace(
                                  /\n/g,
                                  "<br />"
                                )}`,
                              }}
                            />
                          ) : (
                            <label className="block mb-2">
                              No comment available.
                            </label>
                          )}
                        </>
                      ),
                    },

                    {
                      column4: <span className="">2)</span>,
                      column5:
                      (
                        <label
                        className="block mb-2"
                        dangerouslySetInnerHTML={{
                          __html: `${(`2.1 Curriculum development
                          process \n (Stakeholder Involvement)`).replace(
                            /\n/g,
                            "<br />"
                          )}`  }}
                      />
                     ),

                      column6: (
                        <>
                          {dataSent.curriculumDevelopmentProcess ? (
                            <label
                              className="block mb-2"
                              dangerouslySetInnerHTML={{
                                __html: `${dataSent?.curriculumDevelopmentProcess?.replace(
                                  /\n/g,
                                  "<br />"
                                )}`,
                              }}
                            />
                          ) : (
                            <label className="block mb-2">
                              No comment available.
                            </label>
                          )}
                        </>
                      ),
                    },

                    {
                      column4: <span className="">3)</span>,
                      column5: 
                      (
                        <label
                        className="block mb-2"
                        dangerouslySetInnerHTML={{
                          __html: `${(`2.2 Curriculum development
                          process \n(Alignment of programme to National,\n
                          Regional, Continental or \nglobal qualifications
                          frameworks)`).replace(
                            /\n/g,
                            "<br />"
                          )}`  }}
                      />
                     ),
 
                      column6: (
                        <>
                          {dataSent.curriculumDevelopmentAlignmentOfProgrammeToNationalRegionalContinental ? (
                            <label
                              className="block mb-2"
                              dangerouslySetInnerHTML={{
                                __html: `${dataSent?.curriculumDevelopmentAlignmentOfProgrammeToNationalRegionalContinental?.replace(
                                  /\n/g,
                                  "<br />"
                                )}`,
                              }}
                            />
                          ) : (
                            <label className="block mb-2">
                              No comment available.
                            </label>
                          )}
                        </>
                      ),
                    },
                    {
                      column4: <span className="">4)</span>,
                      column5: 
                      (
                        <label
                        className="block mb-2"
                        dangerouslySetInnerHTML={{
                          __html: `${(`2.3 Curriculum development
                          process \n(Endorsement/input
                          from professional bodies) \n(Where applicable)`).replace(
                            /\n/g,
                            "<br />"
                          )}`  }}
                      />
                     ),

                      column6: (
                        <>
                          {dataSent.curriculumDevelopmentEndorsmentInputFromProfessionBodies ? (
                            <label
                              className="block mb-2"
                              dangerouslySetInnerHTML={{
                                __html: `${dataSent?.curriculumDevelopmentEndorsmentInputFromProfessionBodies?.replace(
                                  /\n/g,
                                  "<br />"
                                )}`,
                              }}
                            />
                          ) : (
                            <label className="block mb-2">
                              No comment available.
                            </label>
                          )}
                        </>
                      ),
                    },

                    {
                      column4: <span className="">5)</span>,
                      column5: `2.4 Evidence of Payment`,

                      column6: (
                        <>
                          {dataSent.evidenceOfPayment ? (
                            <label
                              className="block mb-2"
                              dangerouslySetInnerHTML={{
                                __html: `${dataSent?.evidenceOfPayment?.replace(
                                  /\n/g,
                                  "<br />"
                                )}`,
                              }}
                            />
                          ) : (
                            <label className="block mb-2">
                              No comment available.
                            </label>
                          )}
                        </>
                      ),
                    },
                    {
                      column4: <span className="">6)</span>,
                      column5: `Recommendation*(Recommended, Not Recommended)`,

                      column6: (
                        <>
                          {dataSent.recommendationProgramme ? (
                            <label
                              className="block mb-2"
                              dangerouslySetInnerHTML={{
                                __html: `${dataSent?.recommendationProgramme?.replace(
                                  /\n/g,
                                  "<br />"
                                )}`,
                              }}
                            />
                          ) : (
                            <label className="block mb-2">
                              No comment available.
                            </label>
                          )}
                        </>
                      ),
                    },
                  ], // data co
                },
              ], // sub content
            },
          ],
        },

        // End of part 2
      ],
    },
  ],
});
