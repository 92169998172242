import InputNumberForScoreReviewAssessors from "../smallComponents/InputNumberForScoreReviewAssessors";

export const preliminaryAssessmentAssessorsReviewLastDecion = (
  dataSent,
  numberSubmission
) => ({
  point1: [
    {
      bigHeader: "Experts Comments",
      content: [
        {
          subData: [
            {
              number: ``,
              subtitleStandard: ``,
              subcontent: [
                {
                  subtitleNorm: ``,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">S/N</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Designation
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Marks
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">
                          Comments if there is any
                        </span>
                      ),
                    },
                    {
                      column4: `1.`,
                      column5: `3.1 AProgramme content:
                      Sufficient/adequate content(coverage of content is
                      adequate) for the level of
                      award`,
                      column6: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={10}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={dataSent.programmeContentSufficient}
                            placeholder={
                              "Is the programme Content Sufficient ?"
                            }
                            name={"programmeContentSufficient"}
                          />
                        </>
                      ),
                      column7: (
                        <label
                          className="block mb-2"
                          dangerouslySetInnerHTML={{
                            __html: `${dataSent?.commentProgrammeContentSufficient?.replace(
                              /\n/g,
                              "<br />"
                            )}`,
                          }}
                        />
                      ),
                    },

                    {
                      column4: `2.`,
                      column5: `3.2 Programme content
                      Admission criteria`,
                      column6: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={10}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={
                              dataSent.programmeContentAdmissionCriteriaSufficient
                            }
                            placeholder={
                              "Is the programme Admission criteria Sufficient ?"
                            }
                            name={"programmeContentAdmissionCriteriaSufficient"}
                          />
                        </>
                      ),
                      column7: (
                        <label
                          className="block mb-2"
                          dangerouslySetInnerHTML={{
                            __html: `${dataSent?.commentProgrammeContentAdmissionCriteriaSufficient?.replace(
                              /\n/g,
                              "<br />"
                            )}`,
                          }}
                        />
                      ),
                    },

                    {
                      column4: `3.`,
                      column5: `3.3 Programme content
                      Structure of the Programme`,
                      column6: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={25}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={dataSent.programmeStructureSufficient}
                            placeholder={
                              "Is the programme Structure Sufficient ?"
                            }
                            name={"programmeStructureSufficient"}
                          />
                        </>
                      ),
                      column7: (
                        <>
                        {dataSent.commentProgrammeStructureSufficient ? (
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${dataSent?.commentProgrammeStructureSufficient?.replace(
                                /\n/g,
                                "<br />"
                              )}`,
                            }}
                          />
                        ) : (
                          <label className="block mb-2">
                            No comment available.
                          </label>
                        )}
                      </>  
                      ),
                    },

                    {
                      column4: `4.`,
                      column5: `3.4 Programme content
                      Learning Outcomes`,
                      column6: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={15}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={
                              dataSent.programmeContentLearningOutcomeSufficient
                            }
                            placeholder={
                              "Is the programme Content Learning Outcome  Sufficient ?"
                            }
                            name={"programmeContentLearningOutcomeSufficient"}
                          />
                        </>
                      ),
                      column7: (
                        <>
                          {dataSent.commentProgrammeContentLearningOutcomeSufficient ? (
                            <label
                              className="block mb-2"
                              dangerouslySetInnerHTML={{
                                __html: `${dataSent?.commentProgrammeContentLearningOutcomeSufficient?.replace(
                                  /\n/g,
                                  "<br />"
                                )}`,
                              }}
                            />
                          ) : (
                            <label className="block mb-2">
                              No comment available.
                            </label>
                          )}
                        </>
                      ),
                    },

                    {
                      column4: `5.`,
                      column5: `3.5 Programme content
                      Programme consistent to
                      EAQFHE`,
                      column6: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={5}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={
                              dataSent.programmeContentconsistentToEAQFHE
                            }
                            placeholder={
                              "Is the programme Content Programme consistent to EAQFHE ?"
                            }
                            name={"ProgrammeContentconsistentToEAQFHE"}
                          />
                        </>
                      ),
                      column7: (
                        <>
                         <>
                          {dataSent.commentProgrammeContentconsistentToEAQFHE ? (
                            <label
                              className="block mb-2"
                              dangerouslySetInnerHTML={{
                                __html: `${dataSent?.commentProgrammeContentconsistentToEAQFHE?.replace(
                                  /\n/g,
                                  "<br />"
                                )}`,
                              }}
                            />
                          ) : (
                            <label className="block mb-2">
                              No comment available.
                            </label>
                          )}
                        </>
                         
                          <a
                            href="https://www.iucea.org/guidelines/#"
                            target="_blanck"
                            className="text-sm text-blue-800"
                          >
                            click to see EAQFHE
                          </a>
                        </>
                      ),
                    },

                    {
                      column4: `6.`,
                      column5: `3.6 Programme content
                      Credit Hours`,
                      column6: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={10}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={
                              dataSent.programmeContentCreditHourSufficient
                            }
                            placeholder={
                              "Is the programme Content Credit Hoursufficient ?"
                            }
                            name={"programmeContentCreditHourSufficient"}
                          />
                        </>
                      ),
                      column7: (
                        <>
                        {dataSent.commentProgrammeContentCreditHourSufficient ? (
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${dataSent?.commentProgrammeContentCreditHourSufficient?.replace(
                                /\n/g,
                                "<br />"
                              )}`,
                            }}
                          />
                        ) : (
                          <label className="block mb-2">
                            No comment available.
                          </label>
                        )}
                      </>
                        
                      ),
                    },

                    {
                      column4: `7.`,
                      column5: `3.7 Programme content
                      Minimum duration`,
                      column6: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={10}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={
                              dataSent.programmeContentMinimumDurationSufficient
                            }
                            placeholder={
                              "Is the programme Content Minimum Duration  Sufficient ?"
                            }
                            name={"programmeContentMinimumDurationSufficient"}
                          />
                        </>
                      ),
                      column7: (
                        <>
                        {dataSent.commentProgrammeContentMinimumDurationSufficient ? (
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${dataSent?.commentProgrammeContentMinimumDurationSufficient?.replace(
                                /\n/g,
                                "<br />"
                              )}`,
                            }}
                          />
                        ) : (
                          <label className="block mb-2">
                            No comment available.
                          </label>
                        )}
                      </>
                        
                      ),
                    },

                    {
                      column4: `8.`,
                      column5: `3.8 Programme content
                      Provision of RPL`,
                      column6: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={10}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={
                              dataSent.programmeContentProvisionofRPLSufficient
                            }
                            placeholder={
                              "Is the programme Content Provision of RPL Sufficient ?"
                            }
                            name={"programmeContentProvisionofRPLSufficient"}
                          />
                        </>
                      ),
                      column7: (
                        <>
                        {dataSent.commentProgrammeContentProvisionofRPLSufficient ? (
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${dataSent?.commentProgrammeContentProvisionofRPLSufficient?.replace(
                                /\n/g,
                                "<br />"
                              )}`,
                            }}
                          />
                        ) : (
                          <label className="block mb-2">
                            No comment available.
                          </label>
                        )}
                      </>
                         
                      ),
                    },

                    {
                      column4: `9.`,
                      column5: `4.1 Mode of Teaching and
                      Learning:
                      Method of delivery is clearly
                      identified and appropriate`,
                      column6: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={15}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={
                              dataSent.MethodOfDeliveryIsClearlyIdentifiedAndAppropriate
                            }
                            placeholder={
                              "Is Method of delivery is clearly identified and appropriate ?"
                            }
                            name={
                              "MethodOfDeliveryIsClearlyIdentifiedAndAppropriate"
                            }
                          />
                        </>
                      ),
                      column7: (
                        <>
                        {dataSent.commentMethodOfDeliveryIsClearlyIdentifiedAndAppropriate ? (
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${dataSent?.commentMethodOfDeliveryIsClearlyIdentifiedAndAppropriate?.replace(
                                /\n/g,
                                "<br />"
                              )}`,
                            }}
                          />
                        ) : (
                          <label className="block mb-2">
                            No comment available.
                          </label>
                        )}
                      </>
                        
                      ),
                    },

                    {
                      column4: `10.`,
                      column5: `4.2 Mode of Teaching and
                      Learning: Flexibility in mode of
                      learning`,
                      column6: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={5}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={dataSent.flexibilityInModeOfLearning}
                            placeholder={"Flexibility in mode of learning?"}
                            name={"flexibilityInModeOfLearning"}
                          />
                        </>
                      ),
                      column7: (
                        <>
                        {dataSent.commentFlexibilityInModeOfLearning ? (
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${dataSent?.commentFlexibilityInModeOfLearning?.replace(
                                /\n/g,
                                "<br />"
                              )}`,
                            }}
                          />
                        ) : (
                          <label className="block mb-2">
                            No comment available.
                          </label>
                        )}
                      </>
                      
                      ),
                    },

                    {
                      column4: `11.`,
                      column5: `4.3 Mode of Teaching and
                      Learning: Student centered
                      approaches of learning`,
                      column6: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={15}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={
                              dataSent.StudentCenteredApproachesOfLearning
                            }
                            placeholder={
                              "Is the programme Content Student centered approaches of learning?"
                            }
                            name={"StudentCenteredApproachesOfLearning"}
                          />
                        </>
                      ),
                      column7: (
                        <>
                        {dataSent.commentStudentCenteredApproachesOfLearning ? (
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${dataSent?.commentStudentCenteredApproachesOfLearning?.replace(
                                /\n/g,
                                "<br />"
                              )}`,
                            }}
                          />
                        ) : (
                          <label className="block mb-2">
                            No comment available.
                          </label>
                        )}
                      </> 
                      ),
                    },

                    {
                      column4: `12.`,
                      column5: `4.4 Mode of Teaching and
                      Learning: Programme provided
                      for blended system`,
                      column6: (
                        <>
                          <InputNumberForScoreReviewAssessors
                            maximum={15}
                            minimum={0}
                            numberSubmission={numberSubmission}
                            valueGot={
                              dataSent.ProgrammeProvidedForBlendedSystem
                            }
                            placeholder={
                              "Is the Programme provided for blended system?"
                            }
                            name={"ProgrammeProvidedForBlendedSystem"}
                          />
                        </>
                      ),
                      column7: (
                        <>
                        {dataSent.commentProgrammeProvidedForBlendedSystem ? (
                          <label
                            className="block mb-2"
                            dangerouslySetInnerHTML={{
                              __html: `${dataSent?.commentProgrammeProvidedForBlendedSystem?.replace(
                                /\n/g,
                                "<br />"
                              )}`,
                            }}
                          />
                        ) : (
                          <label className="block mb-2">
                            No comment available.
                          </label>
                        )}
                      </> 
                        
                      ),
                    },
                  ], // data content tag
                },
              ], // sub content
            },
          ],
        },

        // End of part 2
      ],
    },
  ],
});
