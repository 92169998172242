import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import ApplicationService from "../../../services/ApplicationService";
import { connect } from "react-redux";
import { login } from "../../actions/auth";
import { useFormik, Field } from "formik";
import Success from "../../alerts/Success";
import Error from "../../alerts/Error";
import UserService from "../../../services/UserService";
import Loading from "../../eventsHandler/Loading";
import moment from "moment";
import TabContent from "../../smallComponents/TabContent";
import iucea from "../../../images/iucea.jpg";
import "./Application.css";
import { preliminaryAssessmentForDeskReviewVisualization } from "../ConstantsAssessementsForDeskReviewVisualisation";

const ApplicationViewAssignExpertPrintReport = (props) => {
  let editing = false;
  let initialTitle_program_application = "";
  let initialDesignation_application = "";
  let initialUniversity_id_application = "";
  let initialLevel_id_application = "";
  let initialOtherInfos = "";
  let initialevidence_for_accreditation_at_national_level = "";
  let initalevidenceNationalLevelDate = "";

  let initialconfirmation_letter_national_agency = "";
  let initialinvolvment_of_the_relevant_national_bodies = "";
  let initialcurriculum_development = "";
  let initialpostgraduate_supervision_guidelines = "";
  let initialquality_assurence_policy = "";
  let initialcredit_transfer_policy = "";
  let initialsummary_of_staff_capacity = "";
  let initialinventory_of_facilities = "";
  let initialannual_survey_report = "";
  let initialtracer_study_report = "";
  let initialexamination_policy = "";
  let initialadmission_policy = "";
  let initialprogram_review_report = "";
  let initialother_not_specified = "";
  let initialDocument2_application = "";
  let initialDocument3_application = "";
  let initialApplicationID = "";
  let typeOperation = "";
  let initialstatus_application = "";
  let initialstatus_thematicArea_application = "";
  let initialuser_created_application = "";

  let initialprogramme_file = "";
  let initialevidence_of_payment = "";
  let initialmarket_survey_report = "";
  let initialevidence_of_participation_of_stackHolder = "";
  let initialcurriculum_development_and_guidelines = "";
  let initialconfirmation_letter_of_key_stackHolder = "";
  let initialauditReport_report = "";
  let emailSubmission = "";
  let userApplicationUsername = "";
  let dataSent = "";
  let initialbatchNumber_Application = "";
  let initialNameFirstReview = "";
  let initialEmailFirstReview = "";
  let initialNameSecondReview = "";
  let initialEmailSecondReview = "";
  let initialdateSecondReviewIUCEA = "";
  let initialdateFirstReviewIUCEA = "";

  if (props.location.state && props.location.state.currentApplication) {
    editing = true;
    typeOperation = props.location.state.typeOperation;
    initialstatus_application =
      props.location.state.currentApplication.status_application;
    initialApplicationID =
      props.location.state.currentApplication.id_application;
    initialevidence_for_accreditation_at_national_level =
      props.location.state.currentApplication
        .evidence_for_accreditation_at_national_level;
    initalevidenceNationalLevelDate =
      props.location.state.currentApplication.evidenceNationalLevelDate;
    initialconfirmation_letter_national_agency =
      props.location.state.currentApplication
        .confirmation_letter_national_agency;
    initialinvolvment_of_the_relevant_national_bodies =
      props.location.state.currentApplication
        .involvment_of_the_relevant_national_bodies;
    initialcurriculum_development =
      props.location.state.currentApplication.curriculum_development;
    initialpostgraduate_supervision_guidelines =
      props.location.state.currentApplication
        .postgraduate_supervision_guidelines;
    initialquality_assurence_policy =
      props.location.state.currentApplication.quality_assurence_policy;
    initialcredit_transfer_policy =
      props.location.state.currentApplication.credit_transfer_policy;
    initialsummary_of_staff_capacity =
      props.location.state.currentApplication.summary_of_staff_capacity;
    initialinventory_of_facilities =
      props.location.state.currentApplication.inventory_of_facilities;
    initialannual_survey_report =
      props.location.state.currentApplication.annual_survey_report;
    initialtracer_study_report =
      props.location.state.currentApplication.tracer_study_report;
    initialexamination_policy =
      props.location.state.currentApplication.examination_policy;
    initialadmission_policy =
      props.location.state.currentApplication.admission_policy;
    initialprogram_review_report =
      props.location.state.currentApplication.program_review_report;
    initialother_not_specified =
      props.location.state.currentApplication.other_not_specified;
    initialTitle_program_application =
      props.location.state.currentApplication.title_program_application;
    initialDesignation_application =
      props.location.state.currentApplication.designation_application;
    initialUniversity_id_application =
      props.location.state.currentApplication.university_id_application;
    initialLevel_id_application =
      props.location.state.currentApplication.level_id_application;

    initialOtherInfos =
      props.location.state.currentApplication.otherInfor_application;
    initialuser_created_application =
      props.location.state.currentApplication.user_created_application;
    initialstatus_thematicArea_application =
      props.location.state.currentApplication.thematicArea_application;

    initialprogramme_file =
      props.location.state.currentApplication.programme_file;
    initialevidence_of_payment =
      props.location.state.currentApplication.evidence_of_payment;
    initialmarket_survey_report =
      props.location.state.currentApplication.market_survey_report;
    initialevidence_of_participation_of_stackHolder =
      props.location.state.currentApplication
        .evidence_of_participation_of_stackHolder;
    initialcurriculum_development_and_guidelines =
      props.location.state.currentApplication
        .curriculum_development_and_guidelines;
    initialconfirmation_letter_of_key_stackHolder =
      props.location.state.currentApplication
        .confirmation_letter_of_key_stackHolder;
    initialauditReport_report =
      props.location.state.currentApplication.auditReport_report;
    emailSubmission =
      props.location.state.currentApplication.userApplicationEmail;
    userApplicationUsername =
      props.location.state.currentApplication.userApplicationUsername;

    dataSent = props.location.state.currentApplication.commentsDeskReview;
    initialbatchNumber_Application =
      props.location.state.currentApplication.batchNumber_Application;

    initialNameFirstReview =
      props.location.state.currentApplication.nameFirstReviewer;
    initialEmailFirstReview =
      props.location.state.currentApplication.emailFirstReviewer;
    initialNameSecondReview =
      props.location.state.currentApplication.nameSecondReviewer;
    initialEmailSecondReview =
      props.location.state.currentApplication.emailSecondReviewer;

    initialdateSecondReviewIUCEA =
      props.location.state.currentApplication.dateSecondReviewIUCEA;
    initialdateFirstReviewIUCEA =
      props.location.state.currentApplication.dateFirstReviewIUCEA;
  }

  // console.log('date_created_application', props.location.state.currentApplication.date_created_application);
  // console.log('initialdateFirstReviewIUCEA', initialdateFirstReviewIUCEA);

  const [level, setLevel] = useState(initialLevel_id_application);

  const [disableSaveBtn, setDisableSaveBtn] = useState(false);
  const [removeObjectionReview, setRemoveObjectionReview] = useState("");
  const [hiddenTextAreas, setHiddenTextAreas] = useState("hidden");

  const [valueRejection, setValueRejection] = useState(0);
  const [valueValidation, setValueValidation] = useState(1);

  const [status, setStatus] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [rejectionReason, setRejectionReason] = useState("");
  const [acceptanceReason, setAcceptanceReason] = useState("");

  let levelData = JSON.parse(localStorage.getItem("levelData"));
  const certificateRef = useRef(null);
  const history = useHistory();
  const [successGot, setSuccessGot] = useState("");
  const [expertId, setExpertId] = useState([]);
  const [dataOfAddedExpert, setDataOfAddedExpert] = useState([]);
  const [expertApplication, setExpertApplication] = useState("");
  const [thematic, setThematic] = useState([]);
  const [expertList, setExpertList] = useState([]);

  if (!(history.location.state == null)) {
    const data = history.location.state.data;
    console.log("Data send: ");
    console.log(data);
  }

  function removeItemOnce(arr, value) {
    var index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  }

  function removeItemAll(arr, value) {
    var i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }

  const onAddingItem = (item) => {
    const isChecked = item.target.checked;
    let newValue = item.target.value;
    if (isChecked) {
      expertId.push(newValue);
      expertId.indexOf(newValue) === -1
        ? expertId.push(newValue)
        : console.log("This item already exists");
    } else {
      removeItemAll(expertId, newValue);
    }
    console.log("List: ", expertId);
  };

  useEffect(() => {
    retrieveApplicationsExepert(initialApplicationID);
  }, [initialApplicationID]);

  const retrieveApplicationsExepert = (idApplication) => {
    setDataOfAddedExpert([]);
    dataOfAddedExpert.length = 0;
    var access = localStorage.getItem("access");
    const linkToData = `${process.env.REACT_APP_API_URL}/api/applicationExpert/${idApplication}/application`;

    ApplicationService.getAllExpert(access, linkToData)
      .then((response) => {
        localStorage.setItem(
          "ExpertApplication",
          JSON.stringify(response.data)
        );
        setExpertApplication(response.data);
      })
      .catch((e) => {
        console.log("error : ", e);
        if (e.response.status === 401) {
          // Redirect to the login page
          history.push("/login");
        }
      });
  };

  if (expertApplication.length > 0 && expertApplication != null) {
    expertApplication.forEach((expert) => {
      dataOfAddedExpert.push(Number(expert.id_expert_applicationExpert));
    });
  }

  const [formData, setFormData] = useState({});

  const handleInputChangeTextComment = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  // let urlUser = `${process.env.REACT_APP_API_URL}/api/user/${initialuser_created_application}/view`;

  const onSubmit = (values, { resetForm }) => {
    setDisableSaveBtn(true);
    let userInfo = localStorage.getItem("userInfo");
    let userCreatedApplication = JSON.parse(userInfo);
    let userJson = JSON.parse(userInfo);
    const newApplication = new FormData();

    const allValues = { ...formData };
    console.log(formData);
    console.log("print report 1: ");
  };

  let numberSubmission = 1;

  let contentGot = [];
  let newDictionnaryExpertReview = {};

  let allUniveData = JSON.parse(localStorage.getItem("universityData"));
  let universityName = "no got";
  allUniveData.map((dataUniv) => {
    if (dataUniv.id === Number(initialUniversity_id_application)) {
      universityName = dataUniv.university_name;
    }
  });

  let levelGot = "";
  levelData.map((levelDB) => {
    if (levelDB.id_level === level) {
      levelGot = levelDB.designation_level;
    }
  });

  useEffect(() => {
    // getThematic
    let url = `${process.env.REACT_APP_API_URL}/api/user/list/expert`;
    UserService.getExpert(url)
      .then((response) => {
        console.log("response");
        console.log(response.data);
        setExpertList(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 401) {
          // Redirect to the login page
          history.push("/login");
        }
      });
  }, []);

  let listSelectedExpert = [];

  if (typeOperation !== "statusEdit") {
    if (expertList == null) {
      expertList.map((expert) => {
        listSelectedExpert.push(Number(expert.id_expert_applicationExpert));
      });
    }
  }

  function isInArray(value, array) {
    return array.indexOf(value) > -1;
  }

  const showInputTextArea = (event) => {
    if (event.target.checked) {
      setHiddenTextAreas("hidden");
    } else {
      setHiddenTextAreas("");
    }
  };

  const disableObjection = (event) => {
    if (event.target.checked) {
      setHiddenTextAreas("hidden");
      setRemoveObjectionReview(false);
      setValueRejection(0);
      setValueValidation(1);
      console.log("Okay");
    } else {
      setRemoveObjectionReview(true);
      console.log("Test");
      setHiddenTextAreas("");
      setValueRejection(1);
      setValueValidation(0);
    }
  };

  let numberOfExpert = expertList.length;
  let hiddenExpert = 0;

  useEffect(() => {
    // getThematic
    let url = `${process.env.REACT_APP_API_URL}/api/thematicArea/`;
    UserService.getThematic(url)
      .then((response) => {
        console.log("response");
        console.log(response.data);
        setThematic(response.data);
      })
      .catch((errors) => {
        console.log("errors");
        console.log(errors);
        if (errors.response.status === 401) {
          // Redirect to the login page
          history.push("/login");
        }
      });
  }, []);

  const tabs = [
    {
      title: preliminaryAssessmentForDeskReviewVisualization(
        JSON.parse(dataSent)
      ).point1[0].bigHeader,
      content: (
        <div>
          <TabContent
            content={
              preliminaryAssessmentForDeskReviewVisualization(
                JSON.parse(dataSent)
              ).point1[0]
            }
          />
        </div>
      ),
    },
  ];

  const handlePrint = () => {
    const contentToPrint = certificateRef.current;
    if (contentToPrint) {
      const printWindow = window.open("", "_blank");
      printWindow.document.write(`
        <html>
          <head>
            <link rel="stylesheet" type="text/css" >
          </head>
          <style>
          body {
            font-family: "Times New Roman", serif;
          }
          .logo-img {
            width: 140px; /* Adjust image size as needed */
            height: 140px;
            display: block; 
            margin: 0 auto;  
            text-align: center;  
        }
        .center-content { 
          display: block; 
          margin: 0 auto;  
          text-align: center;  
      }
        .text-gray-900 {
            --tw-text-opacity: 1;
            color: rgb(17 24 39 / var(--tw-text-opacity));
        } 
        .text-center {
            text-align: center;
        }
        .uppercase {
            text-transform: uppercase;
        }
        .bg-gray-200 {
            --tw-bg-opacity: 1;
            background-color: rgb(229 231 235 / var(--tw-bg-opacity));
        }
        .px-6 {
            padding-left: 1.5rem/* 24px */;
            padding-right: 1.5rem/* 24px */;
        }
        .font-semibold {
            font-weight: 600;
        }
        .py-2 {
            padding-top: 0.5rem/* 8px */;
            padding-bottom: 0.5rem/* 8px */;
        }
        .pl-3 {
            padding-left: 0.75rem/* 12px */;
        }
        .pr-3 {
          padding-right: 0.75rem/* 12px */;
      }
      .win-10 {
        width: 10px;
      }
      .win-200 {
          width: 200px;
      }
      .win-100 {
          width: 100px;
      }
      .text-center {
          text-align: center;
      }
      .win-600 {
          width: 600px;
      }
          </style>
          <body>
            ${contentToPrint.outerHTML}
          </body>
        </html>
      `);
      printWindow.document.close();
      printWindow.print();
    }
  };

  return (
    <div>
      <div className="w-full p-8">
        {history.location.state == null ||
        history.location.state == undefined ? (
          ""
        ) : (
          <>
            {!(history.location.state.data == null) ? (
              <>
                {history.location.state.data.status === 1 ? (
                  <Success
                    delay="5000"
                    valueFromParent={history.location.state.data}
                  />
                ) : (
                  <Error
                    delay="7000"
                    valueFromParent={history.location.state.data}
                  />
                )}
              </>
            ) : (
              <></>
            )}
          </>
        )}

        <table ref={certificateRef} className="text-gray-900">
          <thead>
            <tr>
              <td
                className="  py-2 px-6 bg-gray-0 text-gray-900 uppercase text-lg font-semibold text-center"
                colspan="2"
              >
                <img src={iucea} className="logo-img" />
                <span className="center-content">
                  INTER-UNIVERSITY COUNCIL FOR EAST AFRICA
                  <br />
                  P.O. BOX 7110 KAMPALA
                </span>
              </td>
            </tr>
            <tr>
              <td
                className=" py-2 px-6 bg-gray-200 text-gray-900 uppercase text-lg font-semibold text-center"
                colspan="2"
              >
               <span className="center-content">EAC PROGRAMME ACCREDITATION - PRELIMINARY REVIEW REPORT</span> 
              </td>
            </tr>
            <tr>
              <td className="py-2 px-6 text-gray-900">Name of Institution</td>
              <td className="py-2 px-6"> {universityName}</td>
            </tr>
            <tr>
              <td className="py-2 px-6 text-gray-900">Name of Programme</td>
              <td className="py-2 px-6">{initialTitle_program_application}</td>
            </tr>
            <tr>
              <td className="py-2 px-6 text-gray-900">Cluster Discipline</td>
              <td className="py-2 px-6">
                {thematic.map((area) =>
                  initialstatus_thematicArea_application ===
                  area.id_thematicsArea
                    ? area.designation_thematicsArea
                    : ""
                )}
              </td>
            </tr>
            <tr>
              <td className="py-2 px-6 up text-gray-900">
                Name of Reviewer (Preliminary Review)
              </td>
              <td className="py-2 px-6">
                <span className="uppercase">{initialNameFirstReview} </span>{" "}
                <br />{" "}
                {moment(initialdateFirstReviewIUCEA.replace(/"/g, "")).format(
                  "Do MMMM YYYY HH:mm"
                )}{" "}
                {}
              </td>
            </tr>
            <tr>
              <td className="py-2 px-6 text-gray-900">Checked by (IUCEA)</td>
              <td className="py-2 px-6">
                <span className="uppercase"> {initialNameSecondReview} </span>{" "}
                <br />{" "}
                {moment(initialdateSecondReviewIUCEA.replace(/"/g, "")).format(
                  "Do MMMM YYYY HH:mm"
                )}{" "}
                {}
              </td>
            </tr>
            <tr>
              <td
                className=" bg-gray-200 py-2 px-6 text-gray-900 text-center uppercase text-lg font-semibold"
                colspan="2"
              >
               <span className="center-content">FOR OFFICIAL USE ONLY</span> 
              </td>
            </tr>
            <tr>
              <td className="py-2 px-6 text-gray-900 capitalize">
                Category of Institution
              </td>
              <td className="py-2 px-6">
                University  
              </td>
            </tr>
            <tr>
              <td className="py-2 px-6">Level of the programme</td>
              <td className="py-2 px-6">{levelGot}</td>
            </tr>
            <tr>
              <td className="py-2 px-6">Status</td>
              <td className="py-2 px-6">Yes</td>
            </tr>
            <tr>
              <td className="py-2 px-6">Batch Number</td>
              <td className="py-2 px-6">{initialbatchNumber_Application}</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colspan="2">
                <br />
                <br />

                <div>
                  <TabContent
                    content={
                      preliminaryAssessmentForDeskReviewVisualization(
                        JSON.parse(dataSent)
                      ).point1[0]
                    }
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td className="pl-3 pr-3" colspan="2">
                <br />
                <br />
              <span className="font-semibold"> *Recommended  </span>(this programme is recommended for assessment by experts). <br />
             <span className="font-semibold"> Not Recommended </span> (this Programme is not recommended for assessment because <br />
               of clear reasons stated in the report. The institution may be asked to submit complete<br />
                  application documents).
              </td>
            </tr>
          </tbody>
 
        </table>
        <div className="text-center p-5"> 
            <>
              {" "}
              <button
                onClick={() => handlePrint()}
                className="dark:bg-[var(--color-dark-buttonBackground)] 
                        bg-[var(--color-light-buttonBackground)] 
                        dark:text-[var(--color-dark-fontButton)] 
                        text-[var(--color-light-fontButton)]  
                        w-[100px]  py-3 rounded-md font-medium"
              >
                {" "}
                {typeOperation == "AddExpert" ? <span>Print</span> : "Print"}
              </button>
            </> 
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { login })(
  ApplicationViewAssignExpertPrintReport
);
