import React, { useState } from "react";
import Navbar from "../general/Navbar";
import { Link, Redirect, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { reset_password_confirm } from "../actions/auth";
import Success from "../alerts/Success";
import Error from "../alerts/Error";
import Loading from "../eventsHandler/Loading";

const ResetPasswordConfirm = ({
  match,
  reset_password_confirm,
  isAuthenticated,
}) => {
  const [requestSent, setRequestSent] = useState(false);
  const [formData, setFormData] = useState({
    new_password: "",
    re_new_password: "",
  });

  const [status, setStatus] = useState(false);
  const history = useHistory();

  const { new_password, re_new_password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    const uid = match.params.uid;
    const token = match.params.token;
    e.preventDefault();
    if (new_password !== re_new_password) {
      alert("Passwords does not much");
      return;
    }
    if (!isPasswordSecure(new_password)) {
      alert(
        "Password must meet security requirements with capital letter, number and characters"
      );
      return;
    }
    if (!isPasswordSecure(re_new_password)) {
      alert(
        "Confirm Password must meet security requirements with capital letter, number and characters"
      );
      return;
    }
    setStatus(true);
    let response = reset_password_confirm(
      uid,
      token,
      new_password,
      re_new_password
    );
    response
      .then((resp) => {
        console.log(resp);
        let data = {
          status: 1,
          title: "Good",
          message:
            "Password reset successfully, you can login using new credentials",
        };
        setStatus(false);
        history.push("/", { data: data });
      })
      .catch((err) => {
        let data = {
          status: 0,
          title: "No",
          message: "Error, check your internet connection!!!",
        };
        setStatus(false);
        history.push("/", { data: data });
        console.log(err);
        setStatus(false);
      })
      .finally(() => {
        setStatus(false);
      });
  };

  if (requestSent) {
    return <Redirect to="/" />;
  }

  console.log(status);
  if (isAuthenticated) {
    // window.location.href = '/dashboard';
    return <Redirect to={"/dashboard"} />;
  }

  const isPasswordSecure = (password) => {
    // Define your security requirements here
    const minLength = 8;
    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasDigit = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*()_+{}\[\]:;<>,.?~\-]/.test(password);

    return (
      password.length >= minLength &&
      hasUppercase &&
      hasLowercase &&
      hasDigit &&
      hasSpecialChar
    );
  };

  return (
    <div>
      <div className="text-whit">
        {/* <Navbar /> */}
        {history?.location?.state === null ? (
        ""
      ) : history?.location?.state?.data?.status === 1 ? (
        <Success delay="5000" valueFromParent={history?.location?.state?.data} />
      ) : history?.location?.state?.data?.status === 0 ?(
        <Error delay="7000" valueFromParent={history?.location?.state?.data} />
      ) : <span></span>}

        {status && <Loading />}
        <div className="max-w-[800px] mt-[-96px] w-full h-screen mx-auto text-center flex flex-col justify-center ">
          <div className="w-full shadow-xl shadow-gray-600 rounded-xl dark:bg-[var(--color-dark-bakcgroundSecond)] bg-[var(--color-light-backgroundSecond)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)]">
            <p className="uppercase text-[#0969DA] p-2 md:txt-5xl sm:text-4xl text-2xl font-bold md:py-4">
              Reset Password Confirm
            </p>
            <form onSubmit={(e) => onSubmit(e)}>
              <div className="m-10">
                <div className="flex">
                  <input
                    className="p-3 flex w-full rounded-md text-black font-medium"
                    type="password"
                    placeholder="New Password"
                    name="new_password"
                    autoComplete="new-password"
                    value={new_password}
                    onChange={(e) => onChange(e)}
                    minLength="6"
                    required
                  />
                </div>
                <br />
                <div className="flex">
                  <input
                    className="p-3 flex w-full rounded-md text-black font-medium"
                    type="password"
                    placeholder="Confirm New Password"
                    name="re_new_password"
                    value={re_new_password}
                    autoComplete="new-password"
                    onChange={(e) => onChange(e)}
                    minLength="6"
                    required
                  />
                </div>
              </div>
              <button
                className="uppercase bg-[#0969DA] w-[90%] mt-0 mb-4 rounded-md  font-bold my-0 mx-auto py-3 text-black"
                type="submit"
              >
                {" "}
                Reset password
              </button>
            </form>
            <p className="mt-3 text-blue-700 pb-4  cursor-pointer">
              <Link to={"/"}>Home</Link>
            </p>

            <p><span className="text-xs font-semibold text-red-600">A new password must have capital and small letter, characters and numbers. </span></p>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { reset_password_confirm })(
  ResetPasswordConfirm
);
