import axios from "axios";

class ApplicationService {
  async getAll(access, url) {
    axios.defaults.headers.common["Authorization"] = "JWT " + access;
    // axios.defaults.headers.common["X-Frame-Options"] = "sameorigin";
    axios.defaults.headers.common["Content-type"] = "multipart/form-data";
    const result = await axios.get(`${url}`);
    return result;
  }

  async getAllExpert(access, url) {
    axios.defaults.headers.common["Authorization"] = "JWT " + access;
    // axios.defaults.headers.common["X-Frame-Options"] = "sameorigin";
    axios.defaults.headers.common["Content-type"] = "multipart/form-data";
    const result = await axios.get(`${url}`);
    return result;
  }

  async getApplicantEmail(access, url) {
    axios.defaults.headers.common["Authorization"] = "JWT " + access;
    // axios.defaults.headers.common["X-Frame-Options"] = "sameorigin";
    axios.defaults.headers.common["Content-type"] = "multipart/form-data";
    const result = await axios.get(`${url}`);
    return result;
  }

  async getOneApplication(access, url) {
    axios.defaults.headers.common["Authorization"] = "JWT " + access;
    // axios.defaults.headers.common["X-Frame-Options"] = "sameorigin";
    axios.defaults.headers.common["Content-type"] = "multipart/form-data";
    const result = await axios.get(`${url}`);
    return result;
  }


  createApplication(data, access,universityName) {
    axios.defaults.headers.common["Authorization"] = "JWT " + access;
    axios.defaults.headers.common["Content-type"] = "multipart/form-data";
    let userInfo = localStorage.getItem("userInfo");
    let user = JSON.parse(userInfo);

    return axios.post(
      `${process.env.REACT_APP_API_URL}/api/application/create/${user.email}/${user.name}/${universityName}`,
      data
    );
  }

  addExpertOnApplication(data, access, email,  name, universityName, initialTitle_program_application) {
    axios.defaults.headers.common["Authorization"] = "JWT " + access;
    axios.defaults.headers.common["Content-type"] = "multipart/form-data"; 
    
    return axios.post(
      `${process.env.REACT_APP_API_URL}/api/applicationExpert/create/${email}/${name}/${universityName}/${initialTitle_program_application}`,
      data
    );
  }

  editApplication(id, data, access, userEmail = null, applicantName= null) {
    axios.defaults.headers.common["Authorization"] = "JWT " + access;
    axios.defaults.headers.common["Content-type"] = "multipart/form-data";
   
    console.log("data: ");
    console.log(data);
    if (userEmail) {
      console.log("email: ");
    console.log(userEmail);
      return axios.put(
        `${process.env.REACT_APP_API_URL}/api/application/${id}/${userEmail}/${applicantName}/editEmail`,
        data
      );
    } else {
      return axios.put(
        `${process.env.REACT_APP_API_URL}/api/application/${id}`,
        data
      );
    }
  }

  deleteApplication(id, access) {
    axios.defaults.headers.common["Authorization"] = "JWT " + access;
    axios.defaults.headers.common["Content-type"] = "multipart/form-data";
    
    return axios.put(
      `${process.env.REACT_APP_API_URL}/api/application/${id}/delete/`
    );
  }

  getTheNewBatchNumber(access) {
    axios.defaults.headers.common["Authorization"] = "JWT " + access;
    axios.defaults.headers.common["Content-type"] = "multipart/form-data";
    
    return axios.get(
      `${process.env.REACT_APP_API_URL}/api/applicationBatchnumber/`
    );
  }
}

export default new ApplicationService();
