import React, { Fragment, useEffect, useState } from "react";
import { Link, Redirect, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "./actions/auth";
import { FaBell, FaEnvelope, FaUserCheck } from "react-icons/fa";
import { BiLogOutCircle } from "react-icons/bi";
import Notifications from "./alerts/Notifications";
import BackButton from "./BackButton";
import ReactSwitch from "react-switch";

const NavbarLoggedIn = ({
  logout,
  isAuthenticated,
  clickedMenu,
  setClickedMenu,
}) => {
  const [redirect, setRedirect] = useState(false);
  const location = useLocation();
  const [openMenu, setOpenMenu] = useState(false);

  // console.log('Clic1 ',clickedMenu)

  let userConnectInfo = localStorage.getItem("userInfo");
  let userConnected = JSON.parse(userConnectInfo);

  const logout_user = () => {
    logout();
    setRedirect(true);
  };

  const toggleMenu = () => {
    setOpenMenu((prevOpenMenu) => !prevOpenMenu);
    console.log(openMenu);
  };

  const toggleThemSwitch = () => {
    setTheme((current) => (current === "dark" ? "light" : "dark"));
  };

  const [theme, setTheme] = useState("dark");

  useEffect(() => {
    if (window.matchMedia("(prefers-color-scheme: dark").matches) {
      setTheme("dark");
    } else {
      setTheme("light");
    }
  }, []);

  useEffect(() => {
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [theme]);

  const guestLinks = () => (
    <Fragment>
      <li>
        <Link
          to={"/login"}
          className=" 
                   text-gray-900  
                   dark:text-white
                   md:border-0 
                   md:hover:text-blue-700
                   hover:bg-gray-400 
                  font-bold  
                  py-2 px-4 
                  rounded inline-flex 
                  items-center"
        >
          Login
        </Link>
      </li>
      <li>
        <Link
          to={"/register"}
          className="
                pl-3 
                pr-4 
                text-gray-900  
                dark:text-white
                md:border-0 
                md:hover:text-blue-700
                hover:bg-gray-400 
               font-bold  
               py-2 px-4 
               rounded inline-flex 
               items-center"
        >
          Register
        </Link>
      </li>
    </Fragment>
  );
  const authLinks = () => (
    <Fragment>
      <li>
        <div className="flex-1 mt-2.5 text-center">
          {" "}
          <ReactSwitch
            onChange={toggleThemSwitch}
            checked={theme === "dark"}
          />{" "}
        </div>
      </li>
      <li>
        <span
          className=" 
                 pl-3 
                 pr-4 
                 text-gray-900  
                 dark:text-white
                 md:border-0 
                 md:hover:text-blue-700
                 hover:bg-gray-400 
                font-bold  
                py-2 px-4 
                rounded inline-flex 
                items-center"
        >
          {" "}
          <FaUserCheck className="text-green-500" />{" "}
          {userConnected ? userConnected.name : ""}{" "}
        </span>
      </li>
      <li>
        <div className="">
          <a className="text-gray-900 " href="mailto:support@iucea.org">
            <FaEnvelope size={28} className="mt-1.5" />
          </a>
        </div>
      </li>
      <li>
        <Link
          to="/dashboard"
          className=" 
                 pl-3 
                 pr-4 
                 text-gray-900  
                 dark:text-white
                 md:border-0 
                 md:hover:text-blue-700
                 hover:bg-gray-400 
                font-bold  
                py-2 px-4 
                rounded inline-flex 
                items-center"
          aria-current="page"
        >
          Dashboard
        </Link>
      </li>
      <li>
        <Notifications />
      </li>
      <li>
        <span
          onClick={logout_user}
          className=" 
                 pl-3 
                 pr-4 
                 cursor-pointer
                 text-red-900 
                 dark:text-white
                 md:border-0 
                 md:hover:text-blue-700
                 hover:bg-gray-400 
                font-bold  
                py-2 px-4 
                rounded inline-flex 
                items-center"
        >
          <BiLogOutCircle /> Logout
        </span>
      </li>
    </Fragment>
  );

  return (
    <Fragment>
      <nav className="bg-white border-gray-200 dark:bg-gray-900 pl-10 pr-10 ">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          <a to="https://iucea.org/" className="flex items-center">
            <img
              src="/assets/images/iuceaLogo.png"
              className="h-8  bg-white dark:bg-white
              text-4xl rounded-sm 
                  cursor-pointer 
                  block float-left mr-2 duration-500"
              alt="Flowbite Logo"
            />
            <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">
              Regional Programme Accreditation
            </span>
          </a>
          <button
            data-collapse-toggle="navbar-default"
            type="button"
            className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            aria-controls="navbar-default"
            aria-expanded="false"
            onClick={toggleMenu}
          >
            <span className="sr-only">Menu</span>
            <svg
              className="w-6 h-6"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
          <div
            className={`${
              openMenu ? "block" : "hidden"
            } w-full md:block md:w-auto`}
            id="navbar-default"
            style={{ marginLeft: "auto" }}
          >
            <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
              {isAuthenticated ? authLinks() : guestLinks()}
            </ul>
          </div>
        </div>
      </nav>
      {redirect ? <Redirect to="/allcomponents" /> : <Fragment></Fragment>}
      {isAuthenticated && location.pathname !== "/" && (
        <BackButton clickedMenu={clickedMenu} setClickedMenu={setClickedMenu} />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { logout })(NavbarLoggedIn);
