import React, { useEffect, useState } from "react";
import Navbar from "../general/Navbar";
import { Link, Redirect, useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { login } from "../actions/auth";
import Success from "../alerts/Success";
import Error from "../alerts/Error";

const SignIn = ({ login, isAuthenticated }) => {
  const dispatch = useDispatch();
  const authError = useSelector((state) => state.auth.error); // Assuming you have set up your Redux store correctly
  // Access the dispatched data immediately after login action
  const userData = useSelector((state) => state.auth.user); // Assuming the user data is stored in 'auth.user'

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const history = useHistory();

  const { email, password } = formData;

  useEffect(() => {
    // Access the userData after the login process is successful
    if (userData && userData !== null) {
      alert(userData.detail);
    }
  }, [userData]); // The effect will run whenever the 'userData' changes

  const onChange = (e) =>
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

  const onSubmit = (e) => {
    e.preventDefault();
    login(email, password);
  };

  // check if user is authenticated
  //  redirect him to the home page
  if (isAuthenticated) {
    // window.location.href = '/dashboard';
    return <Redirect to={"/dashboard"} />;
  }

  return (
    <div>
      <div className="text-whit">
        {/* <Navbar /> */}
        {history?.location?.state === null ? (
        ""
      ) : history?.location?.state?.data?.status === 1 ? (
        <Success delay="5000" valueFromParent={history?.location?.state?.data} />
      ) : history?.location?.state?.data?.status === 0 ?(
        <Error delay="7000" valueFromParent={history?.location?.state?.data} />
      ) : <span></span>}
        <div className="max-w-[800px] mt-[-96px] w-full h-screen mx-auto text-center flex flex-col justify-center ">
          <div className="w-full shadow-xl shadow-gray-600 rounded-xl dark:bg-[var(--color-dark-bakcgroundSecond)] bg-[var(--color-light-backgroundSecond)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)]">
            <p className="uppercase text-[#0969DA] p-2 md:txt-5xl sm:text-4xl text-2xl font-bold md:py-4">
              Sign in
            </p>
            <form onSubmit={(e) => onSubmit(e)}>
              <div className="m-10">
                <div className="flex">
                  <input
                    className="p-3 flex w-full rounded-md text-black font-medium"
                    type="email"
                    placeholder="Enter Email"
                    required
                    name="email"
                    value={email}
                    onChange={(e) => onChange(e)}
                  />
                </div>
                <div className="flex mt-5">
                  <input
                    className="p-3 flex w-full font-medium rounded-md text-black"
                    type="password"
                    placeholder="Enter Password"
                    required
                    autoComplete="off"
                    name="password"
                    value={password}
                    onChange={(e) => onChange(e)}
                    // minLength={7}
                  />
                </div>
              </div>
              <button
                className="uppercase bg-[#0969DA] w-[90%] mt-0 mb-4 rounded-md  font-bold my-0 mx-auto py-3 text-black"
                type="submit"
              >
                {" "}
                Sign In
              </button>
            </form>
            {authError && <p>{authError.message}</p>}{" "}
            {/* Display the error message if it exists */}
            <p className="mt-3">
              Don't have an account? <Link className="mt-3 text-blue-700 pb-4" to={"/register"}>Clic to Register</Link>
            </p>
            <p className="mt-3">
              Forget password? <Link className="mt-3 text-blue-700 pb-4" to={"/resetPassword"}>Clic to Reset</Link>
            </p>
            <p className="mt-3 text-blue-700 pb-4">
              <Link to={"/"}>Home</Link>
            </p>
            {/* <p className='mt-3 pb-4'>
                                Forgot your password? <Link to={"/resetPassword"}>Reset Password</Link>
                           </p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { login })(SignIn);
