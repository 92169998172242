import React from 'react'

const Alert = () => {
  return (
    <div className='dark:bg-[var(--color-dark-backgrounSecond)] bg-[var(--color-light-backgrounSecond)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)]'>
        Alert!!!!
        <div className="p-6 m-6 bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md" role="alert">
            <div className="flex">
                <div className="py-1"><svg className="fill-current h-6 w-6 text-teal-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
                <div>
                <p className="font-bold">Message(Success Full)</p>
                <p className="text-sm">Make sure you know how these changes affect you.</p>
                </div>
            </div>
        </div>

        <div className="p-6 m-6 bg-blue-100 border-t border-b border-blue-500 text-blue-700 px-4 py-3 shadow-md" role="alert">
            <p className="font-bold">Message(Please cross check)</p>
            <p className="text-sm">Some additional text to explain said message.</p>
        </div>

        <div className="m-6 bg-orange-100 border-l-4 border-orange-200 text-orange-500 p-4  shadow-md" role="alert">
            <p className="font-bold">Message(Be Warned)</p>
            <p>Something not ideal might be happening.</p>
        </div>

        <div className="m-6 bg-orange-100 border-l-4 border-red-500 text-red-700 p-4  shadow-md" role="alert">
            <p className="font-bold">Message(Be in danger)</p>
            <p>Something not ideal is happening!!!</p>
        </div>

        <button type="button" className="m-6 bg-indigo-500 ..." disabled>
            <svg className="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24">
                
            </svg>
            Processing...
          </button>
        
    </div>
  )
}

export default Alert