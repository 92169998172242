import "./App.css";
import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Switch,
  Route,
  useHistory,
  Redirect,
} from "react-router-dom"; 

import AllComponents from "./components/general/AllComponents";
import Add from "./components/general/Add";
import List from "./components/general/List";
import SignIn from "./components/authentification/SignIn";
import Alert from "./components/general/Alert";

import Dashboard from "./components/Dashboard";
import Layout from "./components/hocs/Layout";
import AccountActivation from "./components/authentification/AccountActivation";
import ResetPassword from "./components/authentification/ResetPassword";
import ResetPasswordConfirm from "./components/authentification/ResetPasswordConfirm";

import { Provider } from "react-redux";
import store from "./store";
import ApplicationList from "./components/views/application/ApplicationList";
import ApplicationAdd from "./components/views/application/ApplicationAdd";
import ApplicationView from "./components/views/application/ApplicationView";

import UserAdd from "./components/views/users/UserAdd";
import UserList from "./components/views/users/UserList";
import UserView from "./components/views/users/UserView";
import UserRegister from "./components/views/users/UserRegister";
import { FaEnvelope } from "react-icons/fa";
import ApplicationEdit from "./components/views/application/ApplicationEdit";
import ExpertReviewAdd from "./components/views/ExpertReview/ExpertReviewAdd";
import ExpertReviewList from "./components/views/ExpertReview/ExpertReviewList";
import ExpertReviewView from "./components/views/ExpertReview/ExpertReviewView";
import SiteVisiteList from "./components/views/SiteVisite/SiteVisiteList";
import SiteVisiteAdd from "./components/views/SiteVisite/SiteVisiteAdd";
import SiteVisiteView from "./components/views/SiteVisite/SiteVisiteView";
import SiteVisiteEdit from "./components/views/SiteVisite/SiteVisiteEdit";
import CHEAAdd from "./components/views/CHEA/CHEAAdd";
import CHEAList from "./components/views/CHEA/CHEAList";
import AppealList from "./components/views/Appeal/AppealList";
import AppealAdd from "./components/views/Appeal/AppealAdd";
import AppealExpertAdd from "./components/views/AppealExpert/AppealExpertAdd";
import AccreditationList from "./components/views/accreditation/AccreditationList";
import UniversityList from "./components/views/university/UniversityList";
import UniversityAdd from "./components/views/university/UniversityAdd";
import LevelList from "./components/views/level/LevelList";
import LevelAdd from "./components/views/level/LevelAdd";
import ThematicAreaList from "./components/views/thematicArea/ThematicAreaList";
import ThematicAreaAdd from "./components/views/thematicArea/ThematicAreaAdd";
import NotFoundPage from "./components/NotFoundPage";
import CHEAAddReport from "./components/views/CHEA/CHEAAddReport";
import CHEAView from "./components/views/CHEA/CHEAView";
import AppealExpertView from "./components/views/AppealExpert/AppealExpertView";
import AppealView from "./components/views/Appeal/AppealView";
import CHEAViewReport from "./components/views/CHEA/CHEAViewReport";
import AppealExpertGiveLastFeedBack from "./components/views/AppealExpert/AppealExpertGiveLastFeedBack";
import AccreditationView from "./components/views/accreditation/AccreditationView";
import ChairSignatoriesAdd from "./components/views/chairsignatories/ChairSignatoriesAdd";
import ChairSignatoriesList from "./components/views/chairsignatories/ChairSignatoriesList";
import AccreditationOpenApiView from "./components/views/accreditation/AccreditationOpenApiView";
import ExpertReviewViewReport from "./components/views/ExpertReview/ExpertReviewViewReport";
import ApplicationSecondReview from "./components/views/application/ApplicationSecondReview";
import ApplicationViewAssignExpert from "./components/views/application/ApplicationViewAssignExpert";
import ApplicationViewAssignExpertPrintReport from "./components/views/application/ApplicationViewAssignExpertPrintReport";
import ExecutiveCommitteeList from "./components/views/ExecutiveCommittee/ExecutiveCommitteeList";
import ExecutiveCommitteeView from "./components/views/ExecutiveCommittee/ExecutiveCommitteeView";
import ExecutiveCommitteeViewReport from "./components/views/ExecutiveCommittee/ExecutiveCommitteeViewReport";
import ExpertReviewViewReportPrint from "./components/views/ExpertReview/ExpertReviewViewReportPrint";

function App() {
  const history = useHistory();
  // useEffect(() => {
  //   WebFont.load({
  //     google: {
  //       families: ["Regular", "Roboto"],
  //     },
  //   });
  // }, []);

  // const [theme, setTheme] = useState("dark");
 

  return (
    <div className="App font-sans ">
      
        <BrowserRouter>
          {/* Routes used here !!!!! */}

          <Provider store={store}>
            <Switch>
              <Route 
                exact
                path={"/accreditationOpenView/:id"}
                component={(props) => <AccreditationOpenApiView {...props} /> }
               />
              <Route
                exact
                path={"/login"}
                component={(props) => <SignIn {...props} />}
              />

              <Route
                exact
                path={"/"}
                component={(props) => <AllComponents {...props} />}
              />
              <Route
                exact
                path="/users/view/:uid/:token"
                render={(props) => <UserView {...props} />}
              ></Route>

              <Route
                exact
                path={"/register"}
                component={(props) => <UserRegister {...props} />}
              />
              <Route
                exact
                path={"/resetPassword"}
                component={(props) => <ResetPassword {...props} />}
              />

              <Route
                exact
                path={"/password/reset/confirm/:uid/:token"}
                component={(props) => <ResetPasswordConfirm {...props} />}
              />
              <Route
                exact
                path={"/activate/:uid/:token"}
                component={(props) => <AccountActivation {...props} />}
              />
              <Layout>
                <Route
                  exact
                  path={"/dashboard"}
                  component={(props) => <Dashboard {...props} />}
                />

                <Route
                  exact
                  path="/create"
                  render={(props) => <Add {...props} />}
                ></Route>
                <Route
                  exact
                  path="/list"
                  render={(props) => <List {...props} />}
                ></Route>
                <Route
                  exact
                  path="/alert"
                  render={(props) => <Alert {...props} />}
                ></Route>
                {/* application */}
                <Route
                  exact
                  path="/application/list"
                  render={(props) => <ApplicationList {...props} />}
                ></Route>
                <Route
                  exact
                  path="/application/create"
                  render={(props) => <ApplicationAdd {...props} />}
                ></Route>
                <Route
                  exact
                  path="/application/:id/edit"
                  render={(props) => <ApplicationEdit {...props} />}
                ></Route>
                <Route
                  exact
                  path="/application/:id/view"
                  render={(props) => <ApplicationViewAssignExpert {...props} />}
                ></Route> 
                 <Route
                  exact
                  path="/application/:id/viewFirstReview"
                  render={(props) => <ApplicationView {...props} />}
                ></Route> 
                  <Route
                  exact
                  path="/application/:id/viewSecondReview"
                  render={(props) => <ApplicationSecondReview
                  licationSecondReview {...props} />}
                ></Route>
                 <Route
                  exact
                  path="/application/:id/viewPrintReport"
                  render={(props) => <ApplicationViewAssignExpertPrintReport {...props} />}
                ></Route>
                {/* end application */}
                {/* firstReview */}
                <Route
                  exact
                  path="/expertReview/:id"
                  render={(props) => <ExpertReviewAdd {...props} />}
                ></Route>
                 <Route
                  exact
                  path="/expertReview/:id/view"
                  render={(props) => <ExpertReviewViewReport {...props} />}
                ></Route>
                  <Route
                  exact
                  path="/expertReview/:id/printReport"
                  render={(props) => <ExpertReviewViewReportPrint {...props} />}
                ></Route>
                <Route
                  exact
                  path="/expertReview/list/data"
                  render={(props) => <ExpertReviewList {...props} />}
                ></Route>
                <Route
                  exact
                  path="/applicationReviewRepport/:id"
                  render={(props) => <ExpertReviewView {...props} />}
                ></Route>
                {/* End firstReview */}
                {/* user */}
                <Route
                  exact
                  path="/users/list"
                  render={(props) => <UserList {...props} />}
                ></Route>
                <Route
                  exact
                  path="/users/create"
                  render={(props) => <UserAdd {...props} />}
                ></Route>
                <Route
                  exact
                  path="/users/:id/edit"
                  render={(props) => <UserAdd {...props} />}
                ></Route>
                <Route
                  exact
                  path="/users/view/"
                  render={(props) => <UserView {...props} />}
                ></Route>
                {/* end user */}

                {/* Site visite */}
                <Route
                  exact
                  path="/siteVisite/list/view"
                  render={(props) => <SiteVisiteList {...props} />}
                ></Route>
                <Route
                  exact
                  path="/siteVisite/:id"
                  render={(props) => <SiteVisiteAdd {...props} />}
                ></Route>
                <Route
                  exact
                  path="/siteVisite/:id/viewdata"
                  render={(props) => <SiteVisiteView {...props} />}
                ></Route>
                <Route
                  exact
                  path="/siteVisite/:id/edit"
                  render={(props) => <SiteVisiteEdit {...props} />}
                ></Route>
                <Route
                  exact
                  path="/cheaReport/:id"
                  render={(props) => <CHEAAddReport {...props} />}
                ></Route>
                <Route
                  exact
                  path="/cheaAdd/:id"
                  render={(props) => <CHEAAdd {...props} />}
                ></Route>
                <Route
                  exact
                  path="/cheaDecisionMade/:id"
                  render={(props) => <CHEAView {...props} />}
                ></Route>
                <Route
                  exact
                  path="/cheaAddCommiteeRepport/:id"
                  render={(props) => <CHEAViewReport {...props} />}
                ></Route>
                <Route
                  exact
                  path="/cheaReport"
                  render={(props) => <CHEAList {...props} />}
                ></Route>
                <Route
                  exact
                  path="/appeal/list"
                  render={(props) => <AppealList {...props} />}
                ></Route>
                <Route
                  exact
                  path="/appealAdd/:id/add"
                  render={(props) => <AppealAdd {...props} />}
                ></Route>
                <Route
                  exact
                  path="/appealFeedBackExpert/:id/view"
                  render={(props) => <AppealExpertAdd {...props} />}
                ></Route>
                <Route
                  exact
                  path="/appealFeedBackRepport/:id/view"
                  render={(props) => (
                    <AppealExpertGiveLastFeedBack {...props} />
                  )}
                ></Route>
                <Route
                  exact
                  path="/appealAddExpert/:id/appealAddExpert"
                  render={(props) => <AppealView {...props} />}
                ></Route>
                <Route
                  exact
                  path="/accreditation/list"
                  render={(props) => <AccreditationList {...props} />}
                ></Route>

                <Route
                  exact
                  path="/university/list"
                  render={(props) => <UniversityList {...props} />}
                ></Route>

                <Route
                  exact
                  path="/university/:id/add"
                  render={(props) => <UniversityAdd {...props} />}
                ></Route>

                <Route
                  exact
                  path="/university/add"
                  render={(props) => <UniversityAdd {...props} />}
                ></Route>

                <Route
                  exact
                  path="/level/list"
                  render={(props) => <LevelList {...props} />}
                ></Route>

                <Route
                  exact
                  path="/level/:id/add"
                  render={(props) => <LevelAdd {...props} />}
                ></Route>

                <Route
                  exact
                  path="/level/add"
                  render={(props) => <LevelAdd {...props} />}
                ></Route>
                <Route
                  exact
                  path="/Chairsignatory/add"
                  render={(props) => <ChairSignatoriesAdd {...props} />}
                ></Route>

<Route
                  exact
                  path="/Chairsignatory/:id/add"
                  render={(props) => <ChairSignatoriesAdd {...props} />}
                ></Route>
 <Route
                  exact
                  path="/Chairsignatory/list"
                  render={(props) => <ChairSignatoriesList {...props} />}
                ></Route>
                <Route
                  exact
                  path="/thematicArea/list"
                  render={(props) => <ThematicAreaList {...props} />}
                ></Route>

                <Route
                  exact
                  path="/thematicArea/:id/add"
                  render={(props) => <ThematicAreaAdd {...props} />}
                ></Route>

                <Route
                  exact
                  path="/thematicArea/add"
                  render={(props) => <ThematicAreaAdd {...props} />}
                ></Route>
                <Route
                  exact
                  path="/accreditation/:id/view"
                  render={(props) => <AccreditationView {...props} />}
                ></Route>

<Route
                  exact
                  path="/executiveCommittee"
                  render={(props) => <ExecutiveCommitteeList {...props} />}
                ></Route>
<Route
                  exact
                  path="/executiveCommittee/:id/view"
                  render={(props) => <ExecutiveCommitteeViewReport {...props} />}
                ></Route>
                {/* <Route exact path="/not-found" component={NotFoundPage} />
                <Redirect from="*" to="/not-found" /> */}
              </Layout>
            </Switch>
          </Provider>
        </BrowserRouter>
      </div> 
  );
}

export default App;
