import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import UserService from "../../../services/UserService";
import { connect } from "react-redux";
import Loading from "../../eventsHandler/Loading";
import UniversityService from "../../../services/UniversityService";

const UniversityAdd = (props) => {
  let editing = false;
  let initialuniversity_name = "";
  let initialiucea_membership = "";
  let initialtype_of_establishment = "";
  let initiallevel_of_progression = "";
  let initialwebsite = "";
  let initialcountry_id = 0;

  const history = useHistory();
  const [choicesDB, setchoicesDB] = useState(null);
  const [countryDB, setcountryDB] = useState(null);


  useEffect(() => {
    // get Small dataComponet 
    UniversityService.getSmallData(access)
      .then((response) => {
        setchoicesDB(response.data);
        console.log(response.data.establishment_choices);
      })
      .catch((erro) => {
        if (erro.response.status === 401) {
          // Redirect to the login page
          history.push('/login');
        }    
        console.log("Error : ", erro);
      });

    UniversityService.getCountries(access)
      .then((response) => {
        setcountryDB(response.data);
        console.log(response.data.establishment_choices);
      })
      .catch((erro) => {
        if (erro.response.status === 401) {
          // Redirect to the login page
          history.push('/login');
        }    
        console.log("Error : ", erro);
      });
  }, []);

  console.log("isAuthenticated: ", localStorage.getItem("connected"));
  console.log("userConnected: ", localStorage.getItem("userId"));

  if (props.location.state && props.location.state.currentUser) {
    editing = true;
    initialuniversity_name = props.location.state.currentUser.university_name;
    initialiucea_membership = props.location.state.currentUser.iucea_membership;
    initialtype_of_establishment =
      props.location.state.currentUser.type_of_establishment;
    initiallevel_of_progression =
      props.location.state.currentUser.level_of_progression;
    initialwebsite = props.location.state.currentUser.website;
    initialcountry_id = props.location.state.currentUser.country_id;
  }


  let access = localStorage.getItem("access");

  const [buttonDisabled, setButtonDisabled] = useState(false);

  const [university_name, setuniversity_name] = useState(
    initialuniversity_name
  );
  const [iucea_membership, setiucea_membership] = useState(
    initialiucea_membership
  );
  const [type_of_establishment, settype_of_establishment] = useState(
    initialtype_of_establishment
  );
  const [level_of_progression, setlevel_of_progression] = useState(
    initiallevel_of_progression
  );
  const [website, setwebsite] = useState(initialwebsite);
  const [country_id, setcountry_id] = useState(initialcountry_id);

  let univData = JSON.parse(localStorage.getItem("universityData"));
  // console.log("LevelData: ", univData);

  const saveUser = () => {
    setButtonDisabled(true);
    const newUniversity = new FormData();
    newUniversity.append("university_name", university_name);
    newUniversity.append("iucea_membership", iucea_membership);
    newUniversity.append("type_of_establishment", type_of_establishment);
    newUniversity.append("level_of_progression", level_of_progression);
    newUniversity.append("website", website);
    newUniversity.append("country_id", country_id);

    console.log(newUniversity);
    if (!editing) {
      // addiing

      UniversityService.createUniversity(
        newUniversity,
        localStorage.getItem("access")
      )
        .then((response) => {
          let data = {
            status: 1,
            title: "Okay",
            message: "University added successFully ...",
          };
          history.push("/university/list", { data: data });
        })
        .catch((error) => {
          if (error.response.status === 401) {
            // Redirect to the login page
            history.push('/login');
          }    
          console.log(" Error: ");
          let data = {
            status: 0,
            title: "Error",
            message: "An error happened ...",
          };
          history.push("/university/list", { data: data });
        });
    } else {
      // edit user
      console.log(newUniversity);
      UniversityService.updateUniversity(
        props.location.state.currentUser.id,
        newUniversity,
        localStorage.getItem("access")
      )
        .then((response) => {
          let data = {
            status: 1,
            title: "Okay",
            message: "University edit successFully ...",
          };
          history.push("/university/list", { data: data });
        })
        .catch((e) => {
          if (e.response.status === 401) {
            // Redirect to the login page
            history.push('/login');
          }   
          let data = {
            status: 0,
            title: "Error",
            message: "An Error happened !",
          };
          history.push("/university/list", { data: data });
        });
    }
    setButtonDisabled(false);
  };

  const [isValidUrl, setIsValidUrl] = useState(true);
  const [isValideName, setIsValideName] = useState(true);
  const [isValidMembership, setIsValidMembership] = useState(true);
  const [isValidEstablishment, setIsValidEstablishment] = useState(true);
  const [isValidProgression, setIsValidProgression] = useState(true);
  const [isCountry, setIsCountry] = useState(true);

  const handleMembershipChange = (e) => {
    const selectedOption = e.target.value;
    setiucea_membership(selectedOption);
    setIsValidMembership(true); // Reset validation on each change
  };

  const handleProgressionChange = (e) => {
    const selectedOption = e.target.value;
    setlevel_of_progression(selectedOption);
    setIsValidProgression(true); // Reset validation on each change
  };

  const handleEstablishmentChange = (e) => {
    const selectedOption = e.target.value;
    settype_of_establishment(selectedOption);
    setIsValidEstablishment(true); // Reset validation on each change
  };

  const handleCountryChange = (e) => {
    const selectedOption = e.target.value;
    setcountry_id(selectedOption);
    setIsCountry(true); // Reset validation on each change
  };


  const handleInputChangeWebsite = (e) => {
    const inputUrl = e.target.value;
    setwebsite(inputUrl);

    // Regular expression to check for a valid URL format
    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
    setIsValidUrl(urlRegex.test(inputUrl));
  };

  const handleInputChangeName = (e) => {
    const name = e.target.value;
    setuniversity_name(name);
    const nameRegex = /^[a-zA-Z-0-9 -]+$/;
    setIsValideName(nameRegex.test(name));
  };

  if (!choicesDB || !countryDB) {
    // Render a loading indicator or null if you don't want anything to show while waiting for data
    return <p> {<Loading />}</p>;
  }

  return (
    <div>
      <div className="w-full p-8">
        {buttonDisabled && <Loading />}

        <div className="w-full px-3 mb-4">
          <label
            className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-2"
            htmlFor="grid-password"
          >
            Designation of the university
          <span className="text-red-600">*</span>
          </label>
          <input
            className="appearance-none block w-full dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            id="grid-password"
            type="text"
            value={university_name}
            onChange={(event) => handleInputChangeName(event)}
            placeholder="university name"
          />
          {!isValideName && <p style={{ color: "red" }}>Enter a valid name.</p>}
        </div>

        <div className="w-full px-3 mb-4">
          <label
            className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-2"
            htmlFor="grid-password"
          >
            Website<span className="text-red-600">*</span>
          </label>
          <input
            className="appearance-none block w-full dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            id="grid-password"
            type="link"
            value={website}
            onChange={handleInputChangeWebsite}
          />
          {!isValidUrl && <p style={{ color: "red" }}>Enter a valid URL.</p>}
        </div>

        <div className="grid grid-cols-2">
          <div className="w-full px-3 mb-6 md:mb-0">
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-2"
              htmlFor="grid-first-name"
            >
              Type of Establishment<span className="text-red-600">*</span>
            </label>
            <div className="relative">
              <select
                className="uppercase block appearance-none w-full border border-gray-200 dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-state"
                name="type_of_establishment"
                value={type_of_establishment}
                onChange={(event) => handleEstablishmentChange(event)}
              >
                <option value="" selected disabled>
                  Please select{" "}
                </option>
                {choicesDB.establishment_choices.map((establishmentDB) => {
                  return (
                    <option  
                      selected={establishmentDB === type_of_establishment}
                      value={establishmentDB}
                    >
                      {establishmentDB}
                    </option>
                  );
                })}
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)]">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
            {!isValidEstablishment && (
              <p style={{ color: "red" }}>
                Please select a type of Establishement option.
              </p>
            )}

            <br />
          </div>

          <div className="w-full px-3 mb-6 md:mb-0">
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-2"
              htmlFor="grid-first-name"
            >
              Type of MemberShip <span className="text-red-600">*</span>
            </label>
            <div className="relative">
              <select
                className="uppercase block appearance-none w-full border border-gray-200 dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-state"
                name="iucea_membership"
                value={iucea_membership}
                onChange={(event) => handleMembershipChange(event)}
              >
                <option value="" selected disabled>
                  Please select{" "}
                </option>
                {choicesDB.membership.map((establishmentDB) => {
                  return (
                    <option
                      selected={establishmentDB === iucea_membership}
                      value={establishmentDB}
                    >
                      {establishmentDB}
                    </option>
                  );
                })}
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)]">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
            {!isValidMembership && (
              <p style={{ color: "red" }}>Please select a membership option.</p>
            )}
             <br />
          </div>
         
          <div className="w-full px-3 mb-6 md:mb-0">
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-2"
              htmlFor="grid-first-name"
            >
              Level of Progression <span className="text-red-600">*</span>
            </label>
            <div className="relative">
              <select
                className="uppercase block appearance-none w-full border border-gray-200 dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-state"
                name="level_of_progression"
                value={level_of_progression}
                onChange={(event) =>  handleProgressionChange (event)}
              >
                <option value="" selected disabled>
                  Please select{" "}
                </option>
                {choicesDB.progression.map((establishmentDB) => {
                  return (
                    <option
                      selected={establishmentDB === level_of_progression}
                      value={establishmentDB}
                    >
                      {establishmentDB}
                    </option>
                  );
                })}
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)]">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
            {!isValidProgression && (
              <p style={{ color: "red" }}>
                Please select a level of progression option.
              </p>
            )}
          </div>
        </div>

        <div className="w-full px-3 mb-6 md:mb-0">
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-2"
              htmlFor="grid-first-name"
            >
              Country <span className="text-red-600">*</span>
            </label>
            <div className="relative">
              <select
                className="block appearance-none w-full border border-gray-200 dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-state"
                name="country_id"
                value={country_id}
                onChange={(event) => handleCountryChange(event)}
              >
                <option value="" selected disabled>
                  Please select{" "}
                </option>
                {countryDB.map((country) => {
                  return (
                    <option
                      selected={country.id === country_id}
                      value={country.id}
                    >
                      {country.country_name}
                    </option>
                  );
                })}
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)]">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
            {!isCountry && (
              <p style={{ color: "red" }}>
                Please select a Country option.
              </p>
            )}

            <br />
          </div>
      </div>

      <div className="text-center p-5">
        <button
          variant="info"
          className="dark:bg-[var(--color-dark-buttonBackground)]  bg-[var(--color-light-buttonBackground)] dark:text-[var(--color-dark-fontButton)] text-[var(--color-light-fontButton)]  w-[150px]  py-3 rounded-md font-medium"
          onClick={() => {
            saveUser();
          }}
        >
          {" "}
          {editing ? "Edit" : "Add"} University
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  userConnected: state.auth.user,
});

export default connect(mapStateToProps)(UniversityAdd);
