import React from "react";
import "./RadialButton.css"; // Import your CSS file

const RadialButton = ({ value, color }) => {
  const buttonStyle = {
    background: `conic-gradient(${color} 0% 30%, transparent 30% 100%)`,
  };

  return (
    <button className="radial-button" style={buttonStyle}>
      {value}
    </button>
  );
};

export default RadialButton;
