import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import UserService from "../../../services/UserService";
import { connect } from "react-redux";
import NormalCheckBox from "../../smallComponents/NormalCheckBox";
import NormalRadio from "../../smallComponents/NormalRadio";
import Loading from "../../eventsHandler/Loading";

const UserAdd = (props) => {
  let editing = false;
  let initialName = "";
  let initialEmail = "";
  // let initialPassword = "";
  // let initialRepassword = "";
  let initialUniversity = 0;
  let initialIsStaff = false;
  let initialIsActive = false;
  let initialIsSuperUser = false;
  let initialThematicArea = "";
  let initialCHEADecision = false;

  console.log("isAuthenticated: ", localStorage.getItem("connected"));
  console.log("userConnected: ", localStorage.getItem("userId"));

  if (props.location.state && props.location.state.currentUser) {
    editing = true;
    initialName = props.location.state.currentUser.name;
    initialEmail = props.location.state.currentUser.email;
    // initialPassword = props.location.state.currentUser.password;
    // initialRepassword = props.location.state.currentUser.re_password;
    initialUniversity = props.location.state.currentUser.university;
    initialIsStaff = props.location.state.currentUser.is_staff;
    initialIsActive = props.location.state.currentUser.is_active;
    initialIsSuperUser = props.location.state.currentUser.is_superuser;
    initialThematicArea = props.location.state.currentUser.thematicArea;
    initialCHEADecision = props.location.state.currentUser.is_CHEA_DecionMember;
  }

  console.log(props.location.state.currentUser);

  const [thematicFromDB, setThematicFromDB] = useState(
    initialThematicArea.split(",")
  );

  const [email, setEmail] = useState(initialEmail);
  // const [password, setPassword] = useState(initialPassword);
  // const [re_password, setRe_password] = useState(initialRepassword);
  const [is_active, setIs_active] = useState(initialIsActive);
  const [is_superuser, setIs_superuser] = useState(initialIsSuperUser);
  const [is_staff, setIs_staff] = useState(initialIsStaff);
  const [university, setUniversity] = useState(initialUniversity);
  const [name, setName] = useState(initialName);
  // const [status, setStatus] = useState(null);
  const history = useHistory();
  const [thematic, setThematic] = useState([]);
  const [thematicSelected, setThematicSelected] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false); 
  const [cheaDecision, setCheaDecision] = useState(initialCHEADecision);

  let univData = JSON.parse(localStorage.getItem("universityData"));
  // console.log("LevelData: ", univData);

  useEffect(() => {
    // getThematic
    let url = `${process.env.REACT_APP_API_URL}/api/thematicArea/`;
    console.log(url);
    UserService.getThematic(url)
      .then((response) => {
        console.log("response");
        console.log(response.data);
        setThematic(response.data);
      })
      .catch((errors) => {
        console.log("errors");
        console.log(errors);
        if (errors.response.status === 401) {
          // Redirect to the login page
          history.push('/login');
        }    
      });
  }, []);

  const handleCheckboxChange = (e, id) => {
    const { checked } = e.target;
    setThematicSelected((prevSelected) => {
      if (checked) {
        return [...prevSelected, id];
      } else {
        const updatedArray = thematicFromDB.filter((item) => item !== id);
        setThematicFromDB(updatedArray);
        return prevSelected.filter((value) => value !== id);
      }
    });
  };

  const saveUser = () => {
    setButtonDisabled(true);
    const newUser = new FormData();
    newUser.append("name", name);
    newUser.append("email", email);
    newUser.append("university", university);
    newUser.append("is_staff", is_staff);
    newUser.append("is_active", is_active);
    newUser.append("is_superuser", is_superuser);
    newUser.append("is_CHEA_DecionMember",cheaDecision);

    const combinedArray = [...thematicFromDB, ...thematicSelected];
    const uniqueArray = Array.from(new Set(combinedArray));
    newUser.append("thematicArea", uniqueArray);

    console.log(newUser);
    if (!editing) {
      // addiing
      // newUser.append("password", password);
      // newUser.append("re_password", re_password);
      // UserService.createUser(newUser, localStorage.getItem("access"))
      //   .then((response) => {
      //     // setSubmitted(true);
      //     // props.history.push('/');
      //     console.log("Carmel Success: ");
      //     console.log(response);
      //   })
      //   .catch((error) => {
      //     console.log("Carmel Error: ");
      //     console.log(error);
      //   });
    } else {
      // edit user
      console.log(newUser);
      UserService.updateUser(
        props.location.state.currentUser.id,
        newUser,
        localStorage.getItem("access")
      )
        .then((response) => {
          let data = {
            status: 1,
            title: "Okay",
            message: "User edit successFully ...",
          };
          history.push("/users/list", { data: data });
        })
        .catch((e) => {
          if (e.response.status === 401) {
            // Redirect to the login page
            history.push('/login');
          }    
          let data = {
            status: 0,
            title: "Error",
            message: "An Error happened !",
          };
          history.push("/users/list", { data: data });
        });
    }
    setButtonDisabled(false);
  };

  // new code
  const groupThematicAreas = (thematicAreas, groupSize) => {
    const groupedThematicAreas = [];
    for (let i = 0; i < thematicAreas.length; i += groupSize) {
      groupedThematicAreas.push(thematicAreas.slice(i, i + groupSize));
    }
    return groupedThematicAreas;
  };

  const groupedThematicAreas = groupThematicAreas(thematic, 3);
  
  return (
    <div>
      <div className="w-full p-4">
        {buttonDisabled && <Loading />}
        <Link
          to={"/users/list"}
          className="flex 
                            space-x-2 
                            items-center 
                            w-[90px]
                            px-3 py-2 
                            bg-blue-500 
                            hover:bg-blue-800 
                            rounded-md 
                            drop-shadow-md"
        >
          <svg
            className="fill-white"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="20"
            height="20"
            viewBox="0 0 24 24"
          >
            <path d="M10,1.529c-4.679,0-8.471,3.792-8.471,8.471c0,4.68,3.792,8.471,8.471,8.471c4.68,0,8.471-3.791,8.471-8.471C18.471,5.321,14.68,1.529,10,1.529 M10,17.579c-4.18,0-7.579-3.399-7.579-7.579S5.82,2.421,10,2.421S17.579,5.82,17.579,10S14.18,17.579,10,17.579 M14.348,10c0,0.245-0.201,0.446-0.446,0.446h-5c-0.246,0-0.446-0.201-0.446-0.446s0.2-0.446,0.446-0.446h5C14.146,9.554,14.348,9.755,14.348,10 M14.348,12.675c0,0.245-0.201,0.446-0.446,0.446h-5c-0.246,0-0.446-0.201-0.446-0.446s0.2-0.445,0.446-0.445h5C14.146,12.229,14.348,12.43,14.348,12.675 M7.394,10c0,0.245-0.2,0.446-0.446,0.446H6.099c-0.245,0-0.446-0.201-0.446-0.446s0.201-0.446,0.446-0.446h0.849C7.194,9.554,7.394,9.755,7.394,10 M7.394,12.675c0,0.245-0.2,0.446-0.446,0.446H6.099c-0.245,0-0.446-0.201-0.446-0.446s0.201-0.445,0.446-0.445h0.849C7.194,12.229,7.394,12.43,7.394,12.675 M14.348,7.325c0,0.246-0.201,0.446-0.446,0.446h-5c-0.246,0-0.446-0.2-0.446-0.446c0-0.245,0.2-0.446,0.446-0.446h5C14.146,6.879,14.348,7.08,14.348,7.325 M7.394,7.325c0,0.246-0.2,0.446-0.446,0.446H6.099c-0.245,0-0.446-0.2-0.446-0.446c0-0.245,0.201-0.446,0.446-0.446h0.849C7.194,6.879,7.394,7.08,7.394,7.325"></path>
          </svg>
          <span className="text-white">List</span>
        </Link>

        <div className="w-full px-3 mb-2">
          <label
            className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-2"
            htmlFor="grid-password"
          >
            Name
          </label>
          <input
            className="appearance-none block w-full dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            id="grid-password"
            type="text"
            value={name}
            onChange={(event) => {
              setName(event.target.value);
            }}
            placeholder="Name"
          /> 
          <p className="text-red-500 font-medium text-sm italic">
            Please fill out this field.
          </p>
        </div>

        <div className="w-full px-3 mb-4">
          <label
            className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-2"
            htmlFor="grid-password"
          >
            E-mail
          </label>
          <input
            disabled
            className="appearance-none block w-full dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            id="grid-password"
            type="email"
            value={email}
            onChange={(event) => {
              setEmail(event.target.value);
            }}
            placeholder="E-mail"
          />
          <p className="text-red-500 font-medium text-sm italic">
            Please fill out this field.
          </p>
        </div>

        <div className="w-full px-3 mb-6 md:mb-0">
          <label
            className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-2"
            htmlFor="grid-first-name"
          >
            University
          </label>
          <div className="relative">
            <select
              className="block appearance-none w-full border border-gray-200 dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-state"
              name="university"
              value={university.id}
              onChange={(event) => {
                setUniversity(event.target.value);
              }}
            >
              {univData.map((universityDB) => {
                return (
                  <option
                    selected={universityDB.id === Number(university)}
                    value={universityDB.id}
                  >
                    {universityDB.university_name}
                  </option>
                );
              })}
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)]">
              <svg
                className="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
          <p className="text-red-500 font-medium text-sm italic">
            Please fill out this field.
          </p>
        </div>

        <div className="flex ">
          <div className="w-1/2 px-3 mb-6 md:mb-0">
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-2"
              htmlFor="grid-first-name"
            >
              Is He/She active?
            </label>
            <div
              onChange={(event) => {
                setIs_active(event.target.value);
              }}
            >
              <div className="mb-[0.125rem] mr-4 inline-block min-h-[1.5rem] pl-[1.5rem]">
                <NormalRadio
                  name="Isactive"
                  value="1"
                  defaultChecked={is_active === 1}
                  label="Yes"
                />

                <NormalRadio
                  id="Isactive2"
                  name="Isactive"
                  type="radio"
                  value="0"
                  defaultChecked={is_active === 0}
                  label="No"
                />
              </div>
            </div>
          </div>

          <div className="w-1/2 px-3 mb-6 md:mb-0">
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-2"
              htmlFor="grid-first-name"
            >
              Is He/She Expert?
            </label>
            <div
              onChange={(event) => {
                setIs_staff(event.target.value);
              }}
            >
              <NormalRadio
                id="IsStaff"
                name="IsStaff"
                type="radio"
                value="1"
                defaultChecked={is_staff === 1}
                label="Yes"
              />
              <NormalRadio
                id="IsStaff2"
                name="IsStaff"
                type="radio"
                value="0"
                defaultChecked={is_staff === 0}
                label="No"
              />
            </div>
          </div>

          <div className="w-1/2 px-3 mb-6 md:mb-0">
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-2"
              htmlFor="grid-first-name"
            >
              Is He/She Admin(member)?
            </label>
            <div
              onChange={(event) => {
                setIs_superuser(event.target.value);
              }}
            >
              <NormalRadio
                id="IsSuperUser"
                name="IsSuperUser"
                type="radio"
                value="1"
                defaultChecked={is_superuser === 1}
                label="Yes"
              />
              <NormalRadio
                id="IsSuperUser2"
                name="IsSuperUser"
                type="radio"
                value="0"
                defaultChecked={is_superuser === 0}
                label="No"
              />
            </div>
          </div>

          <div className="w-1/2 px-3 mb-6 md:mb-0">
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-2"
              htmlFor="grid-first-name"
            >
              Is He/She C.H.E.A?
            </label>
            <div
              onChange={(event) => {
                setCheaDecision(event.target.value);
              }}
            >
              <NormalRadio
                id="isCHEADecision"
                name="isCHEADecision"
                type="radio"
                value="1"
                defaultChecked={cheaDecision === 1}
                label="Yes"
              />
              <NormalRadio
                id="isCHEADecision2"
                name="isCHEADecision"
                type="radio"
                value="0"
                defaultChecked={cheaDecision === 0}
                label="No"
              />
            </div>
          </div>
        </div>

        <div className="w-full px-3 mb-2">
          <label
            className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-1"
            htmlFor="grid-password"
          >
            Select Discipline cluster:
          </label>
          <div style={{ display: "flex" }}>
            {/* Loop through the groups and create columns */}
            {groupedThematicAreas.map((group, index) => (
              <div key={index} style={{ flex: 1, margin: "10px" }}>
                {group.map((area) => (
                  <div key={area.id_thematicsArea}>
                    <NormalCheckBox
                      name="thematicAreaSpeciality"
                      onChange={(e) =>
                        handleCheckboxChange(e, area.id_thematicsArea)
                      }
                      checked={
                        thematicFromDB.includes(area.id_thematicsArea)
                          ? "yes"
                          : null
                      }
                      value={`${area.id_thematicsArea}`}
                      label={area.designation_thematicsArea}
                    />
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="text-center p-2">
        <button
          variant="info"
          className="dark:bg-[var(--color-dark-buttonBackground)]  bg-[var(--color-light-buttonBackground)] dark:text-[var(--color-dark-fontButton)] text-[var(--color-light-fontButton)]  w-[100px]  py-3 rounded-md font-medium"
          onClick={() => {
            saveUser();
          }}
        >
          {" "}
          {editing ? "Edit" : "Add"} User
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  userConnected: state.auth.user,
});

export default connect(mapStateToProps)(UserAdd);
