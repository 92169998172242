import InputCheckboxNA from "../smallComponents/InputCheckboxNA";
import InputNumberForScore from "../smallComponents/InputNumberForScore";

export const siteVisiteInformationDataInputPreview = (
  handleInputChangeNumber,
  handleInputChangeTextComment,
  handleInputChangeNumberNA
) => ({
  point1: [
    {
      bigHeader: "Site visit Comments",
      content: [
        {
          subData: [
            {
              number: ``,
              subtitleStandard: ``,
              subcontent: [
                {
                  subtitleNorm: ``,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">
                          Designation
                        </span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Marks obtained
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">N/A</span>
                      ),
                      column7: (
                        <span className="font-bold text-black">Comments</span>
                      ),
                    },
                    {
                      column4: `5.1 Academic Staffing: Staff
                      Student ratio - Max 5`,
                      column5: (
                        <>
                          <InputNumberForScore
                            maximum={5}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            placeholder={
                              "What is the Academic Staffing: Staff Student ratio ?"
                            }
                            name={"academicStaffingRatio"}
                          />
                        </>
                      ),
                      column6: (
                        <>
                          <InputCheckboxNA
                            value={5}
                            onChangeNumber={handleInputChangeNumberNA}
                            name={"academicStaffingRatioNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentacademicStaffingRatio"
                          cols={60} // Increase the number of columns
                          rows={3}
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },

                    {
                      column4: `5.2 Academic Staffing
                      Minimum number of
                      academic staff for a
                      programme – Max 10`,

                      column5: (
                        <>
                          <InputNumberForScore
                            maximum={10}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            placeholder={
                              "What Is the Academic Staffing Minimum number of academic staff for a programme ?"
                            }
                            name={
                              "academicStaffingMinimumNumberOfAcademicStaffForAProgramme"
                            }
                          />
                        </>
                      ),
                      column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            name={
                              "academicStaffingMinimumNumberOfAcademicStaffForAProgrammeNA"
                            }
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentacademicStaffingMinimumNumberOfAcademicStaffForAProgramme"
                          cols={60} // Increase the number of columns
                          rows={3}
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },

                    {
                      column4: `5.3 Academic Staffing
                      Qualifications of core staff
                      related to the programme –
                      Max 10`,
                      column5: (
                        <>
                          <InputNumberForScore
                            maximum={10}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            placeholder={
                              "Is the academic staffing qualitfications of core staff related to the programme?"
                            }
                            name={
                              "academicStaffingQualificationsOfCoreStaffRelatedToTheProgramme"
                            }
                          />
                        </>
                      ),
                      column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            name={
                              "academicStaffingQualificationsOfCoreStaffRelatedToTheProgrammeNA"
                            }
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentacademicStaffingQualificationsOfCoreStaffRelatedToTheProgramme"
                          cols={60} // Increase the number of columns
                          rows={3}
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },

                    {
                      column4: `5.4 Academic Staffing
                      Proportion of Full Time to
                      Part Time Staff – Max 5`,
                      column5: (
                        <>
                          <InputNumberForScore
                            maximum={5}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            placeholder={
                              "Is the Academic Staffing Proportion of Full Time to Part Time Staff ?"
                            }
                            name={
                              "AcademicStaffingProportionofFullTimetoPartTimeStaff"
                            }
                          />
                        </>
                      ),
                      column6: (
                        <>
                          <InputCheckboxNA
                            value={5}
                            onChangeNumber={handleInputChangeNumberNA}
                            name={
                              "AcademicStaffingProportionofFullTimetoPartTimeStaffNA"
                            }
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentAcademicStaffingProportionofFullTimetoPartTimeStaff"
                          cols={60} // Increase the number of columns
                          rows={3}
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },

                    {
                      column4: `5.5 Academic Staffing
                      Teaching experience – Max
                      5`,
                      column5: (
                        <>
                          <InputNumberForScore
                            maximum={5}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            placeholder={
                              "What Is the Academic Staffing Teaching experience ?"
                            }
                            name={"academicStaffingTeachingExperience"}
                          />
                        </>
                      ),
                      column6: (
                        <>
                          <InputCheckboxNA
                            value={5}
                            onChangeNumber={handleInputChangeNumberNA}
                            name={"academicStaffingTeachingExperienceNA"}
                          />
                        </>
                      ),
                      column7: (
                        <>
                          <textarea
                            name="commentAcademicStaffingTeachingExperience"
                            cols={60} // Increase the number of columns
                            rows={3}
                            type="text"
                            style={{
                              width: "100%",
                              padding: "5px",
                              border: "1px solid #ccc",
                              borderRadius: "4px",
                            }}
                          ></textarea>{" "}
                        </>
                      ),
                    },

                    {
                      column4: `5.6 Academic Staffing
                      Workload/Contact hours –
                      Max 5`,
                      column5: (
                        <>
                          <InputNumberForScore
                            maximum={5}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            placeholder={
                              "What Is the Academic Staffing Workload ?"
                            }
                            name={"academicStaffingWorkload"}
                          />
                        </>
                      ),
                      column6: (
                        <>
                          <InputCheckboxNA
                            value={5}
                            onChangeNumber={handleInputChangeNumberNA}
                            name={"academicStaffingWorkloadNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentacademicStaffingWorkload"
                          cols={60} // Increase the number of columns
                          rows={3}
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },

                    {
                      column4: `5.6 Academic Staffing
                      Training in pedagogy – Max
                      5`,
                      column5: (
                        <>
                          <InputNumberForScore
                            maximum={5}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            placeholder={
                              "What Is the Academic Staffing Training in pedagogy?"
                            }
                            name={"AcademicStaffingTrainingInPedagogy"}
                          />
                        </>
                      ),
                      column6: (
                        <>
                          <InputCheckboxNA
                            value={5}
                            onChangeNumber={handleInputChangeNumberNA}
                            name={"AcademicStaffingTrainingInPedagogyNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentAcademicStaffingTrainingInPedagogy"
                          cols={60} // Increase the number of columns
                          rows={3}
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },

                    {
                      column4: `5.8 Academic Staffing
                      Provision of regular CPD
                      (where applicable) – Max 5`,
                      column5: (
                        <>
                          <InputNumberForScore
                            maximum={10}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            placeholder={
                              "What Is the Academic Staffing Provision of regular CPD ?"
                            }
                            name={"AcademicStaffingProvisionofRegularCPD"}
                          />
                        </>
                      ),

                      column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            name={"AcademicStaffingProvisionofRegularCPDNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentAcademicStaffingProvisionofRegularCPD"
                          cols={60} // Increase the number of columns
                          rows={3}
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },

                    {
                      column4: `5.9 Academic Staffing
                      Deployment && allocation of
                      staff – Max 20`,
                      column5: (
                        <>
                          <InputNumberForScore
                            maximum={20}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            placeholder={
                              "What is the Academic Staffing Deployment allocation of staff  ?"
                            }
                            name={"AcademicStaffingDeploymentallocationOfStaff"}
                          />
                        </>
                      ),

                      column6: (
                        <>
                          <InputCheckboxNA
                            value={20}
                            onChangeNumber={handleInputChangeNumberNA}
                            name={
                              "AcademicStaffingDeploymentallocationOfStaffNA"
                            }
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentAcademicStaffingDeploymentallocationOfStaff"
                          cols={60} // Increase the number of columns
                          rows={3}
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },

                    {
                      column4: `5.10 Academic Staffing
                      Staff publication – Max 5`,
                      column5: (
                        <>
                          <InputNumberForScore
                            maximum={5}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            placeholder={"Academic Staffing Staff publication?"}
                            name={"AcademicStaffingStaffPublication"}
                          />
                        </>
                      ),

                      column6: (
                        <>
                          <InputCheckboxNA
                            value={5}
                            onChangeNumber={handleInputChangeNumberNA}
                            name={"AcademicStaffingStaffPublicationNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentAcademicStaffingStaffPublication"
                          cols={60} // Increase the number of columns
                          rows={3}
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },

                    {
                      column4: `5.11 Innovations by staff –
                      Max 5`,
                      column5: (
                        <>
                          <InputNumberForScore
                            maximum={5}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            placeholder={"What Is the Innovations by staff?"}
                            name={"InnovationsbyStaff"}
                          />
                        </>
                      ),
                      column6: (
                        <>
                          <InputCheckboxNA
                            value={5}
                            onChangeNumber={handleInputChangeNumberNA}
                            name={"InnovationsbyStaffNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentInnovationsbyStaff"
                          cols={60} // Increase the number of columns
                          rows={3}
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },

                    {
                      column4: `5.12 Academic Staffing
                      Patent && copy rights – Max 5`,
                      column5: (
                        <>
                          <InputNumberForScore
                            maximum={15}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            placeholder={
                              "Academic Staffing  Patent  copy rights?"
                            }
                            name={"AcademicStaffingPatentcopyRights"}
                          />
                        </>
                      ),

                      column6: (
                        <>
                          <InputCheckboxNA
                            value={15}
                            onChangeNumber={handleInputChangeNumberNA}
                            name={"AcademicStaffingPatentcopyRightsNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentAcademicStaffingPatentcopyRights"
                          cols={60} // Increase the number of columns
                          rows={3}
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },

                    {
                      column4: `6.1 Governance &&
                      Administration
                      Head of Department – Max
                      10`,
                      column5: (
                        <>
                          <InputNumberForScore
                            maximum={10}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            placeholder={
                              "Governance && Administration Head of Department?"
                            }
                            name={"GovernanceAdministrationHeadofDepartment"}
                          />
                        </>
                      ),

                      column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            name={"GovernanceAdministrationHeadofDepartmentNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentGovernanceAdministrationHeadofDepartment"
                          cols={60} // Increase the number of columns
                          rows={3}
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    {
                      column4: `6.2 Governance &&
                      Administration
                      Coordination of the programme academic
                      leader/coordinator – Max 10`,
                      column5: (
                        <>
                          <InputNumberForScore
                            maximum={10}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            placeholder={
                              "Governance && Administration Coordination of the programme academic leader/coordinator?"
                            }
                            name={
                              "GovernanceAdministrationCoordinationoftheprogrammeacademicleadercoordinator"
                            }
                          />
                        </>
                      ),

                      column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            name={
                              "GovernanceAdministrationCoordinationoftheprogrammeacademicleadercoordinatorNA"
                            }
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentGovernanceAdministrationCoordinationoftheprogrammeacademicleadercoordinator"
                          cols={60} // Increase the number of columns
                          rows={3}
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    {
                      column4: `6.3 Governance &&
                      Administration
                      Quality Assurance Policy –
                      Max 10`,
                      column5: (
                        <>
                          <InputNumberForScore
                            maximum={10}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            placeholder={
                              "Governance &&  Administration Quality Assurance Policy?"
                            }
                            name={
                              "GovernanceAdministrationQualityAssurancePolicy"
                            }
                          />
                        </>
                      ),

                      column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            name={
                              "GovernanceAdministrationQualityAssurancePolicyNA"
                            }
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentGovernanceAdministrationQualityAssurancePolicy"
                          cols={60} // Increase the number of columns
                          rows={3}
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    {
                      column4: `6.4 Governance &&
                      Administration
                      Institutional programme
                      quality Assurance Mechanism
                      – Max 10`,
                      column5: (
                        <>
                          <InputNumberForScore
                            maximum={10}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            placeholder={
                              "Governance && Administration Institutional programme quality Assurance Mechanism ?"
                            }
                            name={
                              "GovernanceAdministrationInstitutionalprogrammequalityAssuranceMechanism"
                            }
                          />
                        </>
                      ),

                      column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            name={
                              "GovernanceAdministrationInstitutionalprogrammequalityAssuranceMechanismNA"
                            }
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentGovernanceAdministrationInstitutionalprogrammequalityAssuranceMechanism"
                          cols={60} // Increase the number of columns
                          rows={3}
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    {
                      column4: `6.5 Governance &&
                      Administration Quality Assurance Policy –
                      Max 30`,
                      column5: (
                        <>
                          <InputNumberForScore
                            maximum={15}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            placeholder={
                              "Governance && Administration Quality Assurance Policy ?"
                            }
                            name={
                              "GovernanceAdministrationQualityAssurancePolicy"
                            }
                          />
                        </>
                      ),

                      column6: (
                        <>
                          <InputCheckboxNA
                            value={15}
                            onChangeNumber={handleInputChangeNumberNA}
                            name={
                              "GovernanceAdministrationQualityAssurancePolicyNA"
                            }
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentGovernanceAdministrationQualityAssurancePolicy"
                          cols={60} // Increase the number of columns
                          rows={3}
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    {
                      column4: `6.6 Governance &&
                      Administration
                      Institutional policy on
                      programme connectedness
                      with the world of practice
                      with the world of work –
                      Max 20`,
                      column5: (
                        <>
                          <InputNumberForScore
                            maximum={20}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            placeholder={
                              "Governance && Administration Institutional policy on programme connectedness  with the world of practice with the world of work"
                            }
                            name={
                              "GovernanceAdministrationInstitutionalpolicyonprogrammeconnectednesswiththeworldofpracticewiththeworldofwork"
                            }
                          />
                        </>
                      ),

                      column6: (
                        <>
                          <InputCheckboxNA
                            value={20}
                            onChangeNumber={handleInputChangeNumberNA}
                            name={
                              "GovernanceAdministrationInstitutionalpolicyonprogrammeconnectednesswiththeworldofpracticewiththeworldofworkNA"
                            }
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentGovernanceAdministrationInstitutionalpolicyonprogrammeconnectednesswiththeworldofpracticewiththeworldofwork"
                          cols={60} // Increase the number of columns
                          rows={3}
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    {
                      column4: `7.1 Infrastructure facilities:
                      Lecture room space – Max
                      15`,
                      column5: (
                        <>
                          <InputNumberForScore
                            maximum={15}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            placeholder={
                              "Infrastructure facilities: Lecture room space?"
                            }
                            name={"InfrastructurefacilitiesLectureroomspace"}
                          />
                        </>
                      ),

                      column6: (
                        <>
                          <InputCheckboxNA
                            value={15}
                            onChangeNumber={handleInputChangeNumberNA}
                            name={"InfrastructurefacilitiesLectureroomspaceNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentInfrastructurefacilitiesLectureroomspace"
                          cols={60} // Increase the number of columns
                          rows={3}
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    {
                      column4: `7.2 Infrastructure facilities:
                      Library space – Max 10`,
                      column5: (
                        <>
                          <InputNumberForScore
                            maximum={10}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            placeholder={
                              "Infrastructure facilities:  Library space?"
                            }
                            name={"InfrastructureFacilitiesLibraryspace"}
                          />
                        </>
                      ),

                      column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            name={"InfrastructureFacilitiesLibraryspaceNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentInfrastructureFacilitiesLibraryspace"
                          cols={60} // Increase the number of columns
                          rows={3}
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    {
                      column4: `57.3 Infrastructure Facilities:
                      ICT Laboratories space –
                      Max 10`,
                      column5: (
                        <>
                          <InputNumberForScore
                            maximum={10}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            placeholder={
                              "Infrastructure Facilities:  ICT Laboratories space?"
                            }
                            name={
                              "InfrastructureFacilitiesICTLaboratoiresspace"
                            }
                          />
                        </>
                      ),

                      column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            name={"InfrastructureFacilitiesICTLaboratoiresspaceNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentInfrastructureFacilitiesICTLaboratoiresspace"
                          cols={60} // Increase the number of columns
                          rows={3}
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    {
                      column4:  `7.4 Infrastructure facilities:
                      Seminar rooms – Max 10`,
                      column5: (
                        <>
                          <InputNumberForScore
                            maximum={10}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            placeholder={
                              "Infrastructure facilities:  Seminar rooms?"
                            }
                            name={"InfrastructurefacilitiesSeminarRooms"}
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            name={"InfrastructurefacilitiesSeminarRoomsNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentInfrastructurefacilitiesSeminarRooms"
                          cols={60} // Increase the number of columns
                          rows={3}
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    {
                      column4: `7.5 Infrastructure
                      facilities: Studio space – Max
                      10`,
                      column5: (
                        <>
                          <InputNumberForScore
                            maximum={10}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            placeholder={
                              "Infrastructure facilities: Studio space?"
                            }
                            name={"InfrastructurefacilitiesStudiospace"}
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            name={"InfrastructurefacilitiesStudiospaceNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentInfrastructurefacilitiesStudiospace"
                          cols={60} // Increase the number of columns
                          rows={3}
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    {
                      column4:  `7.6 Infrastructure facilities:
                      Office space – Max 10`,
                      column5: (
                        <>
                          <InputNumberForScore
                            maximum={15}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            placeholder={
                              "Infrastructure facilities: Office space?"
                            }
                            name={"InfrastructurefacilitiesOfficespace"}
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={15}
                            onChangeNumber={handleInputChangeNumberNA}
                            name={"InfrastructurefacilitiesOfficespaceNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentInfrastructurefacilitiesOfficespace"
                          cols={60} // Increase the number of columns
                          rows={3}
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    {
                      column4: `7.7 Infrastructure facilities:
                      Space for basic science
                      laboratories – Max 10`,
                      column5: (
                        <>
                          <InputNumberForScore
                            maximum={10}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            placeholder={
                              "Infrastructure facilities: Space for basic science laboratories?"
                            }
                            name={
                              "InfrastructurefacilitiesSpaceforbasicsciencelaboratories"
                            }
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            name={"InfrastructurefacilitiesSpaceforbasicsciencelaboratoriesNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentInfrastructurefacilitiesSpaceforbasicsciencelaboratories"
                          cols={60} // Increase the number of columns
                          rows={3}
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    {
                      column4: `7.8 Infrastructure facilities:
                      Human Medicine laboratories 
                      – Max 45`,
                      column5: (
                        <>
                          <InputNumberForScore
                            maximum={45}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            placeholder={
                              "Infrastructure facilities: Human Medicine laboratories?"
                            }
                            name={
                              "InfrastructurefacilitiesHumanMedicinelaboratories"
                            }
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={45}
                            onChangeNumber={handleInputChangeNumberNA}
                            name={"InfrastructurefacilitiesHumanMedicinelaboratoriesNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentInfrastructurefacilitiesHumanMedicinelaboratories"
                          cols={60} // Increase the number of columns
                          rows={3}
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    {
                      column4: `7.9 Infrastructure facilities:
                      Dental Medicine Laboratories
                      – Max 20`,
                      column5: (
                        <>
                          <InputNumberForScore
                            maximum={20}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            placeholder={
                              "Infrastructure facilities: Dental Medicine Laboratories?"
                            }
                            name={
                              "InfrastructurefacilitiesDentalMedicineLaboratories"
                            }
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={20}
                            onChangeNumber={handleInputChangeNumberNA}
                            name={"InfrastructurefacilitiesDentalMedicineLaboratoriesNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentInfrastructurefacilitiesDentalMedicineLaboratories"
                          cols={60} // Increase the number of columns
                          rows={3}
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    {
                      column4: `7.10 Infrastructure facilities:
                      Skills Laboratories for
                      Nursing/Midwifery – Max 10`,
                      column5: (
                        <>
                          <InputNumberForScore
                            maximum={10}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            placeholder={
                              "Infrastructure facilities: Skills Laboratories for Nursing/Midwifery?"
                            }
                            name={
                              "InfrastructurefacilitiesSkillsLaboratoriesforNursingMidwifery"
                            }
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            name={"InfrastructurefacilitiesSkillsLaboratoriesforNursingMidwiferyNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentInfrastructurefacilitiesSkillsLaboratoriesforNursingMidwifery"
                          cols={60} // Increase the number of columns
                          rows={3}
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    {
                      column4: `7.11 Infrastructure facilities:
                      Teaching hospital – Max 5`,
                      column5: (
                        <>
                          <InputNumberForScore
                            maximum={5}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            placeholder={
                              "Infrastructure facilities:  Teaching hospital?"
                            }
                            name={"InfrastructurefacilitiesTeachinghospital"}
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={5}
                            onChangeNumber={handleInputChangeNumberNA}
                            name={"InfrastructurefacilitiesTeachinghospitalNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentInfrastructurefacilitiesTeachinghospital"
                          cols={60} // Increase the number of columns
                          rows={3}
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    {
                      column4: `7.12 Infrastructure facilities:
                      Agricultural Sciences
                      laboratories – Max 20`,
                      column5: (
                        <>
                          <InputNumberForScore
                            maximum={20}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            placeholder={
                              "Infrastructure facilities:  Agricultural Sciences laboratories?"
                            }
                            name={
                              "InfrastructurefacilitiesAgriculturalScienceslaboratoires"
                            }
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={20}
                            onChangeNumber={handleInputChangeNumberNA}
                            name={"InfrastructurefacilitiesAgriculturalScienceslaboratoiresNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentInfrastructurefacilitiesAgriculturalScienceslaboratoires"
                          cols={60} // Increase the number of columns
                          rows={3}
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    {
                      column4:  `7.13 Infrastructure facilities:
                      Engineering laboratories –
                      Max 30`,
                      column5: (
                        <>
                          <InputNumberForScore
                            maximum={30}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            placeholder={
                              "Infrastructure facilities: Engineering laboratories?"
                            }
                            name={
                              "InfrastructurefacilitiesEngineeringlaboratories"
                            }
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={30}
                            onChangeNumber={handleInputChangeNumberNA}
                            name={"InfrastructurefacilitiesEngineeringlaboratoriesNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentInfrastructurefacilitiesEngineeringlaboratories"
                          cols={60} // Increase the number of columns
                          rows={3}
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    {
                      column4:  `7.14 Infrastructure facilities:
                      Workshops – Max 35`,
                      column5: (
                        <>
                          <InputNumberForScore
                            maximum={35}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            placeholder={
                              "Infrastructure facilities: Workshops?"
                            }
                            name={"InfrastructurefacilitiesWorkshops"}
                          />
                        </>
                      ), 
                      column6: (
                        <>
                          <InputCheckboxNA
                            value={35}
                            onChangeNumber={handleInputChangeNumberNA}
                            name={"InfrastructurefacilitiesWorkshopsNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentInfrastructurefacilitiesWorkshops"
                          cols={60} // Increase the number of columns
                          rows={3}
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    {
                      column4:   `7.15 Infrastructure facilities:
                      Teachers Resource Room –
                      Max 10`,
                      column5: (
                        <>
                          <InputNumberForScore
                            maximum={10}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            placeholder={
                              "Infrastructure facilities: Teachers Resource Room"
                            }
                            name={
                              "InfrastructurefacilitiesTeachersResourceRoom"
                            }
                          />
                        </>
                      ),

                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            name={"InfrastructurefacilitiesTeachersResourceRoomNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentInfrastructurefacilitiesTeachersResourceRoom"
                          cols={60} // Increase the number of columns
                          rows={3}
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    {
                      column4:  `7.16 Infrastructure facilities:
                      Education Technology
                      laboratories – Max 10`,
                      column5: (
                        <>
                          <InputNumberForScore
                            maximum={10}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            placeholder={
                              "Infrastructure facilities: Education Technology laboratories?"
                            }
                            name={
                              "InfrastructurefacilitiesEducationTechnologylaboratories"
                            }
                          />
                        </>
                      ),

                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            name={"InfrastructurefacilitiesEducationTechnologylaboratoriesNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentInfrastructurefacilitiesEducationTechnologylaboratories"
                          cols={60} // Increase the number of columns
                          rows={3}
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    {
                      column4:  `7.17 Infrastructure facilities:
                      Food && Catering laboratories
                      – Max 10`,
                      column5: (
                        <>
                          <InputNumberForScore
                            maximum={10}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            placeholder={
                              "Infrastructure facilities: Food && Catering laboratories?"
                            }
                            name={
                              "InfrastructurefacilitiesFoodCateringlaboratories"
                            }
                          />
                        </>
                      ),

                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            name={"InfrastructurefacilitiesFoodCateringlaboratoriesNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentInfrastructurefacilitiesFoodCateringlaboratories"
                          cols={60} // Increase the number of columns
                          rows={3}
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    {
                      column4:  `7.18 Infrastructure facilities:
                      Garment Cutting – Max 10`,
                      column5: (
                        <>
                          <InputNumberForScore
                            maximum={10}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            placeholder={
                              "Infrastructure facilities: Garment Cutting?"
                            }
                            name={"InfrastructureFacilitiesGarmentCutting"}
                          />
                        </>
                      ),

                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            name={"InfrastructureFacilitiesGarmentCuttingNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentInfrastructureFacilitiesGarmentCutting"
                          cols={60} // Increase the number of columns
                          rows={3}
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    {
                      column4: `7.19 Infrastructure facilities:
                      Cosmetology laboratory –
                      Max 10`,
                      column5: (
                        <>
                          <InputNumberForScore
                            maximum={10}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            placeholder={
                              "Infrastructure facilities: Cosmetology laboratory?"
                            }
                            name={
                              "InfrastructurefacilitiesCosmetologylaboratory"
                            }
                          />
                        </>
                      ),

                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            name={"InfrastructurefacilitiesCosmetologylaboratoryNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentInfrastructurefacilitiesCosmetologylaboratory"
                          cols={60} // Increase the number of columns
                          rows={3}
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    {
                      column4: `7.20 Infrastructure facilities:
                      Geology laboratory – Max
                      10`,
                      column5: (
                        <>
                          <InputNumberForScore
                            maximum={10}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            placeholder={
                              "Infrastructure facilities: Geology laboratory ?"
                            }
                            name={"InfrastructurefacilitiesGeologylaboratory"}
                          />
                        </>
                      ),

                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            name={"InfrastructurefacilitiesGeologylaboratoryNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentInfrastructurefacilitiesGeologylaboratory"
                          cols={60} // Increase the number of columns
                          rows={3}
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    {
                      column4: `7.21 Infrastructure facilities:
                      Museum – Max 10`,
                      column5: (
                        <>
                          <InputNumberForScore
                            maximum={15}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            placeholder={"Infrastructure facilities: Museum?"}
                            name={"InfrastructurefacilitiesMuseum"}
                          />
                        </>
                      ),

                       column6: (
                        <>
                          <InputCheckboxNA
                            value={15}
                            onChangeNumber={handleInputChangeNumberNA}
                            name={"InfrastructurefacilitiesMuseumNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentInfrastructurefacilitiesMuseum"
                          cols={60} // Increase the number of columns
                          rows={3}
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    {
                      column4:  `8.1 Teaching && Learning
                      Resource: Smart Boards –
                      Max 5`,
                      column5: (
                        <>
                          <InputNumberForScore
                            maximum={5}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            placeholder={
                              "Teaching && Learning Resource: Smart Boards?"
                            }
                            name={"TeachingLearningResourceSmartBoards"}
                          />
                        </>
                      ),

                       column6: (
                        <>
                          <InputCheckboxNA
                            value={5}
                            onChangeNumber={handleInputChangeNumberNA}
                            name={"TeachingLearningResourceSmartBoardsNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentTeachingLearningResourceSmartBoards"
                          cols={60} // Increase the number of columns
                          rows={3}
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    {
                      column4:`8.2 Teaching && Learning
                      Internet availability – Max 20`,
                      column5: (
                        <>
                          <InputNumberForScore
                            maximum={20}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            placeholder={
                              "Teaching && Learning Internet availability?"
                            }
                            name={"TeachingLearningInternetavailability"}
                          />
                        </>
                      ),

                       column6: (
                        <>
                          <InputCheckboxNA
                            value={20}
                            onChangeNumber={handleInputChangeNumberNA}
                            name={"TeachingLearningInternetavailabilityNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentTeachingLearningInternetavailability"
                          cols={60} // Increase the number of columns
                          rows={3}
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    {
                      column4:  `8.3 Teaching && Learning 
                      Book && Library resources
                      relevant to the programme –
                      Max 15`,
                      column5: (
                        <>
                          <InputNumberForScore
                            maximum={15}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            placeholder={
                              "Teaching && Learning Book && Library resources relevant to the programme?"
                            }
                            name={
                              "TeachingLearningBookLibraryresourcesrelevanttotheprogramme"
                            }
                          />
                        </>
                      ),

                       column6: (
                        <>
                          <InputCheckboxNA
                            value={15}
                            onChangeNumber={handleInputChangeNumberNA}
                            name={"TeachingLearningBookLibraryresourcesrelevanttotheprogrammeNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentTeachingLearningBookLibraryresourcesrelevanttotheprogramme"
                          cols={60} // Increase the number of columns
                          rows={3}
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    {
                      column4: `8.4 Teaching && Learning
                      University Learning
                      Management System – Max
                      15`,
                      column5: (
                        <>
                          <InputNumberForScore
                            maximum={15}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            placeholder={
                              "Teaching && Learning University Learning Management System?"
                            }
                            name={
                              "TeachingLearningUniversityLearningManagementSystem"
                            }
                          />
                        </>
                      ),

                       column6: (
                        <>
                          <InputCheckboxNA
                            value={15}
                            onChangeNumber={handleInputChangeNumberNA}
                            name={"TeachingLearningUniversityLearningManagementSystemNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentTeachingLearningUniversityLearningManagementSystem"
                          cols={60} // Increase the number of columns
                          rows={3}
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    {
                      column4:`8.5 Teaching && Learning
                      Facilities available support
                      blended learning – Max 30`,
                      column5: (
                        <>
                          <InputNumberForScore
                            maximum={30}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            placeholder={
                              "Teaching && Learning Facilities available support blended learning?"
                            }
                            name={
                              "TeachingLearningFacilitiesavailablesupportblendedlearning"
                            }
                          />
                        </>
                      ),

                       column6: (
                        <>
                          <InputCheckboxNA
                            value={30}
                            onChangeNumber={handleInputChangeNumberNA}
                            name={"TeachingLearningFacilitiesavailablesupportblendedlearningNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentTeachingLearningFacilitiesavailablesupportblendedlearning"
                          cols={60} // Increase the number of columns
                          rows={3}
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    {
                      column4: `9.1 Student assessment:
                      Approaches adopted in
                      continuous (formative) and
                      summative assessment –
                      Max 15`,
                      column5: (
                        <>
                          <InputNumberForScore
                            maximum={15}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            placeholder={
                              "Student assessment:  Approaches adopted in continuous (formative) and summative assessment?"
                            }
                            name={
                              "StudentassessmentApproachesadoptedincontinuousandsummativeassessment"
                            }
                          />
                        </>
                      ),

                       column6: (
                        <>
                          <InputCheckboxNA
                            value={15}
                            onChangeNumber={handleInputChangeNumberNA}
                            name={"StudentassessmentApproachesadoptedincontinuousandsummativeassessmentNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentStudentassessmentApproachesadoptedincontinuousandsummativeassessment"
                          cols={60} // Increase the number of columns
                          rows={3}
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    {
                      column4:  `9.2 Student assessment
                      Approaches to assessment of
                      digital/online learning – Max
                      15`,
                      column5: (
                        <>
                          <InputNumberForScore
                            maximum={15}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            placeholder={
                              "Student assessment Approaches to assessment of digital?"
                            }
                            name={
                              "StudentassessmentApproachestoassessmentofdigital"
                            }
                          />
                        </>
                      ),

                       column6: (
                        <>
                          <InputCheckboxNA
                            value={15}
                            onChangeNumber={handleInputChangeNumberNA}
                            name={"StudentassessmentApproachestoassessmentofdigitalNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentStudentassessmentApproachestoassessmentofdigital"
                          cols={60} // Increase the number of columns
                          rows={3}
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    {
                      column4:  `9.3 Student assessment
                      Internal && External
                      moderation of examinations
                      && results – Max 10`,
                      column5: (
                        <>
                          <InputNumberForScore
                            maximum={10}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            placeholder={
                              "Student assessment Internal && External  moderation of examinations   && results?"
                            }
                            name={
                              "StudentassessmentInternalExternalmoderationofexaminationsresult"
                            }
                          />
                        </>
                      ),

                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            name={"StudentassessmentInternalExternalmoderationofexaminationsresultNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentStudentassessmentInternalExternalmoderationofexaminationsresult"
                          cols={60} // Increase the number of columns
                          rows={3}
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    {
                      column4:`9.4 Student assessment
                      Security of Examinations –
                      Max 5`,
                      column5: (
                        <>
                          <InputNumberForScore
                            maximum={5}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            placeholder={
                              "Student assessment Security of Examinations?"
                            }
                            name={"StudentassessmentSecurityofExaminations"}
                          />
                        </>
                      ),

                       column6: (
                        <>
                          <InputCheckboxNA
                            value={5}
                            onChangeNumber={handleInputChangeNumberNA}
                            name={"StudentassessmentSecurityofExaminationsNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentStudentassessmentSecurityofExaminations"
                          cols={60} // Increase the number of columns
                          rows={3}
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    {
                      column4: `9.5 Student assessment
                      Internship/Industrial
                      Attachment – Max 10`,
                      column5: (
                        <>
                          <InputNumberForScore
                            maximum={10}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            placeholder={
                              "Student assessment Internship/Industrial Attachment?"
                            }
                            name={
                              "StudentassessmentInternshipIndustrialAttachment"
                            }
                          />
                        </>
                      ),

                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            name={"StudentassessmentInternshipIndustrialAttachmentNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentStudentassessmentInternshipIndustrialAttachment"
                          cols={60} // Increase the number of columns
                          rows={3}
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    {
                      column4:  `10.1 Students Support
                      System: Provision for
                      students
                      tutor/mentor/advisor – Max
                      15`,
                      column5: (
                        <>
                          <InputNumberForScore
                            maximum={15}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            placeholder={
                              "Students Support System: Provision for students?"
                            }
                            name={"StudentsSupportSystemProvisionforstudents"}
                          />
                        </>
                      ),

                       column6: (
                        <>
                          <InputCheckboxNA
                            value={15}
                            onChangeNumber={handleInputChangeNumberNA}
                            name={"StudentsSupportSystemProvisionforstudentsNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentStudentsSupportSystemProvisionforstudents"
                          cols={60} // Increase the number of columns
                          rows={3}
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    {
                      column4: `10.2 Students Support
                      Programme orientation –
                      Max 10`,
                      column5: (
                        <>
                          <InputNumberForScore
                            maximum={10}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            placeholder={
                              "Students Support Programme orientation?"
                            }
                            name={"StudentsSupportProgrammeorientation"}
                          />
                        </>
                      ),
                      column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            name={"StudentsSupportProgrammeorientationNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentStudentsSupportProgrammeorientation"
                          cols={60} // Increase the number of columns
                          rows={3}
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    {
                      column4: `10.3 Students Support Co-
                      curriculum activities – Max 5`,
                      column5: (
                        <>
                          <InputNumberForScore
                            maximum={5}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            placeholder={
                              "Students Support Co-curriculum activities?"
                            }
                            name={"StudentsSupportCo_curriculumactivities"}
                          />
                        </>
                      ),
                        column6: (
                        <>
                          <InputCheckboxNA
                            value={5}
                            onChangeNumber={handleInputChangeNumberNA}
                            name={"StudentsSupportCo_curriculumactivitiesNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentStudentsSupportCo_curriculumactivities"
                          cols={60} // Increase the number of columns
                          rows={3}
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    {
                      column4: `10.4 Students Support
                      Counselling services – Max
                      15`,
                      column5: (
                        <>
                          <InputNumberForScore
                            maximum={15}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            placeholder={
                              "Students Support Counselling services?"
                            }
                            name={"StudentsSupportCounsellingservices"}
                          />
                        </>
                      ),
                        column6: (
                        <>
                          <InputCheckboxNA
                            value={15}
                            onChangeNumber={handleInputChangeNumberNA}
                            name={"StudentsSupportCounsellingservicesNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentStudentsSupportCounsellingservices"
                          cols={60} // Increase the number of columns
                          rows={3}
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    {
                      column4: `10.5 Students Support
                      Mentorship programme –
                      Max 10`,
                      column5: (
                        <>
                          <InputNumberForScore
                            maximum={10}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            placeholder={
                              "Students Support Mentorship programme?"
                            }
                            name={"StudentsSupportMentorshipprogramme"}
                          />
                        </>
                      ),
                        column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            name={"StudentsSupportMentorshipprogrammeNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentStudentsSupportMentorshipprogramme"
                          cols={60} // Increase the number of columns
                          rows={3}
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    {
                      column4:`10.6 Students Support
                      Provision of social presence
                      for digital learning – Max 5`,
                      column5: (
                        <>
                          <InputNumberForScore
                            maximum={5}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            placeholder={
                              "Students Support Provision of social presence for digital learning?"
                            }
                            name={
                              "StudentsSupportProvisionofsocialpresencefordigitallearning"
                            }
                          />
                        </>
                      ),
                        column6: (
                        <>
                          <InputCheckboxNA
                            value={5}
                            onChangeNumber={handleInputChangeNumberNA}
                            name={"StudentsSupportProvisionofsocialpresencefordigitallearningNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentStudentsSupportProvisionofsocialpresencefordigitallearning"
                          cols={60} // Increase the number of columns
                          rows={3}
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    {
                      column4:  `10.7 Students Support
                      Information on students
                      support made available –
                      Max 10`,
                      column5: (
                        <>
                          <InputNumberForScore
                            maximum={10}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            placeholder={
                              "Students Support Information on students support made available?"
                            }
                            name={
                              "StudentsSupportInformationonstudentssupportmadeavailable"
                            }
                          />
                        </>
                      ),
                        column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            name={"StudentsSupportInformationonstudentssupportmadeavailableNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentStudentsSupportInformationonstudentssupportmadeavailable"
                          cols={60} // Increase the number of columns
                          rows={3}
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    {
                      column4: `10.8 Students Support
                      Scholarship/financial support
                      services to needy students –
                      Max 15`,
                      column5: (
                        <>
                          <InputNumberForScore
                            maximum={15}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            placeholder={
                              "Students Support Scholarship/financial support services to needy students?"
                            }
                            name={
                              "StudentsSupportScholarship_financialsupportservicestoneedystudents"
                            }
                          />
                        </>
                      ),
                        column6: (
                        <>
                          <InputCheckboxNA
                            value={15}
                            onChangeNumber={handleInputChangeNumberNA}
                            name={"StudentsSupportScholarship_financialsupportservicestoneedystudentsNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentStudentsSupportScholarship_financialsupportservicestoneedystudents"
                          cols={60} // Increase the number of columns
                          rows={3}
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    {
                      column4:  `11.1 Community Services:
                      The programme has policies
                      && strategies for lecturers in
                      involve in community
                      services – Max 10`,
                      column5: (
                        <>
                          <InputNumberForScore
                            maximum={10}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            placeholder={
                              "Community Services:  The programme has policies  && strategies for lecturers in involve in community?"
                            }
                            name={
                              "CommunityServicesTheprogrammehaspoliciesstrategiesforlecturersininvolveincommunity"
                            }
                          />
                        </>
                      ),
                        column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            name={"CommunityServicesTheprogrammehaspoliciesstrategiesforlecturersininvolveincommunityNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentCommunityServicesTheprogrammehaspoliciesstrategiesforlecturersininvolveincommunity"
                          cols={60} // Increase the number of columns
                          rows={3}
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    {
                      column4:`11.2 Community Services:
                      The strategies are reviewed
                      and improved every five (5)
                      years – Max 5`,
                      column5: (
                        <>
                          <InputNumberForScore
                            maximum={5}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            placeholder={
                              "Community Services: The strategies are reviewed and improved every five?"
                            }
                            name={
                              "CommunityServicesThestrategiesarereviewedAndimprovedeveryfive"
                            }
                          />
                        </>
                      ),
                        column6: (
                        <>
                          <InputCheckboxNA
                            value={5}
                            onChangeNumber={handleInputChangeNumberNA}
                            name={"CommunityServicesThestrategiesarereviewedAndimprovedeveryfiveNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentCommunityServicesThestrategiesarereviewedAndimprovedeveryfive"
                          cols={60} // Increase the number of columns
                          rows={3}
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    {
                      column4: `11.3 Community Services
                      Reports on impact and
                      benefits of the programme to
                      the real community(ies) –
                      Max 15`,
                      column5: (
                        <>
                          <InputNumberForScore
                            maximum={15}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            placeholder={
                              "Community Services  Reports on impact and benefits of the programme to the real community(ies)?"
                            }
                            name={
                              "CommunityServicesReportsonimpactandbenefitsoftheprogrammetotherealcommunity"
                            }
                          />
                        </>
                      ),
                        column6: (
                        <>
                          <InputCheckboxNA
                            value={15}
                            onChangeNumber={handleInputChangeNumberNA}
                            name={"CommunityServicesReportsonimpactandbenefitsoftheprogrammetotherealcommunityNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentCommunityServicesReportsonimpactandbenefitsoftheprogrammetotherealcommunity"
                          cols={60} // Increase the number of columns
                          rows={3}
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    {
                      column4: `12.1 Programme Review:
                      Processes && procedures of
                      programme review – Max 15`,
                      column5: (
                        <>
                          <InputNumberForScore
                            maximum={15}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            placeholder={
                              "Programme Review: Processes && procedures of programme review?"
                            }
                            name={
                              "ProgrammeReviewProcessesproceduresofprogrammereview"
                            }
                          />
                        </>
                      ),
                        column6: (
                        <>
                          <InputCheckboxNA
                            value={15}
                            onChangeNumber={handleInputChangeNumberNA}
                            name={"ProgrammeReviewProcessesproceduresofprogrammereviewNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentProgrammeReviewProcessesproceduresofprogrammereview"
                          cols={60} // Increase the number of columns
                          rows={3}
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    {
                      column4:`12.2 Programme Review
                      Periodic review of
                      programme – Max 10`,
                      column5: (
                        <>
                          <InputNumberForScore
                            maximum={10}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            placeholder={
                              "Programme Review Periodic review of programme?"
                            }
                            name={"programmeReviewPeriodicreviewofprogramme"}
                          />
                        </>
                      ),
                        column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            name={"programmeReviewPeriodicreviewofprogrammeNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentprogrammeReviewPeriodicreviewofprogramme"
                          cols={60} // Increase the number of columns
                          rows={3}
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    {
                      column4:  `12.3 Programme Review
                      Regular tracer studies – Max
                      15`,
                      column5: (
                        <>
                          <InputNumberForScore
                            maximum={15}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            placeholder={
                              "Programme Review Regular tracer studies?"
                            }
                            name={"ProgrammeReviewRegulartracerstudies"}
                          />
                        </>
                      ),
                        column6: (
                        <>
                          <InputCheckboxNA
                            value={15}
                            onChangeNumber={handleInputChangeNumberNA}
                            name={"ProgrammeReviewRegulartracerstudiesNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentProgrammeReviewRegulartracerstudies"
                          cols={60} // Increase the number of columns
                          rows={3}
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },

                    {
                      column4:  `12.4 Programme Review
                      Procedures && Plans for staff
                      development based on
                      identified needs and
                      employer satisfaction – Max 15`,
                      column5: (
                        <>
                          <InputNumberForScore
                            maximum={15}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            placeholder={
                              "Programme Review Procedures && Plans for staff development based on identified needs and employer satisfaction?"
                            }
                            name={
                              "ProgrammeReviewProceduresPlansforstaffdevelopmentbasedonidentifiedneedsandemployersatisfaction"
                            }
                          />
                        </>
                      ),
                        column6: (
                        <>
                          <InputCheckboxNA
                            value={15}
                            onChangeNumber={handleInputChangeNumberNA}
                            name={"ProgrammeReviewProceduresPlansforstaffdevelopmentbasedonidentifiedneedsandemployersatisfactionNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentProgrammeReviewProceduresPlansforstaffdevelopmentbasedonidentifiedneedsandemployersatisfaction"
                          cols={60} // Increase the number of columns
                          rows={3}
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                  ], // data content tag
                },
              ], // sub content
            },
          ],
        },

        // End of part 2
      ],
    },
  ],
});
