import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import ApplicationService from "../../../services/ApplicationService";
import { connect } from "react-redux";
import { login } from "../../actions/auth";
import { ExpertReviewSchemasValidation } from "../../alerts/Validations";
import { useFormik, Field } from "formik";
import Success from "../../alerts/Success";
import Error from "../../alerts/Error";
import ApplicationFirstFeedback from "../../../services/ApplicationFirstFeedback";
import ExpertReviewService from "../../../services/ExpertReviewService";
import Loading from "../../eventsHandler/Loading";
import UserService from "../../../services/UserService";

const ExpertReviewView = (props) => {
  let editing = false;
  let initialTitle_program_application = "";
  let initialDesignation_application = "";
  let initialUniversity_id_application = "";
  let initialLevel_id_application = "";
  let initialOtherInfos = "";
  let initialevidence_for_accreditation_at_national_level = "";
  let initialconfirmation_letter_national_agency = "";
  let initialinvolvment_of_the_relevant_national_bodies = "";
  let initialcurriculum_development = "";
  let initialpostgraduate_supervision_guidelines = "";
  let initialquality_assurence_policy = "";
  let initialcredit_transfer_policy = "";
  let initialsummary_of_staff_capacity = "";
  let initialinventory_of_facilities = "";
  let initialannual_survey_report = "";
  let initialtracer_study_report = "";
  let initialexamination_policy = "";
  let initialadmission_policy = "";
  let initialprogram_review_report = "";
  let initialother_not_specified = "";
  let initialDocument2_application = "";
  let initialDocument3_application = "";
  let initialApplicationID = "";
  let typeOperation = "";
  let initialstatus_application = "";
  let initialstatus_thematicArea_application = "";
  let initialuser_created_application = "";
  let id_provenance = "";
  let initialStatusApplication = "";

  let numberOfStaffFeedbacks = [];
  let userSubmitterInfor = [];
  let userApplicantName = '';

  if (props.location.state && props.location.state.application) {
    editing = true;
    typeOperation = props.location.state.typeOperation;
    initialstatus_application =
      props.location.state.application.status_application;
    initialApplicationID = props.location.state.application.id_application;
    initialevidence_for_accreditation_at_national_level =
      props.location.state.application
        .evidence_for_accreditation_at_national_level;
    initialconfirmation_letter_national_agency =
      props.location.state.application.confirmation_letter_national_agency;
    initialinvolvment_of_the_relevant_national_bodies =
      props.location.state.application
        .involvment_of_the_relevant_national_bodies;
    initialcurriculum_development =
      props.location.state.application.curriculum_development;
    initialpostgraduate_supervision_guidelines =
      props.location.state.application.postgraduate_supervision_guidelines;
    initialquality_assurence_policy =
      props.location.state.application.quality_assurence_policy;
    initialcredit_transfer_policy =
      props.location.state.application.credit_transfer_policy;
    initialsummary_of_staff_capacity =
      props.location.state.application.summary_of_staff_capacity;
    initialinventory_of_facilities =
      props.location.state.application.inventory_of_facilities;
    initialannual_survey_report =
      props.location.state.application.annual_survey_report;
    initialtracer_study_report =
      props.location.state.application.tracer_study_report;
    initialexamination_policy =
      props.location.state.application.examination_policy;
    initialadmission_policy = props.location.state.application.admission_policy;
    initialprogram_review_report =
      props.location.state.application.program_review_report;
    initialother_not_specified =
      props.location.state.application.other_not_specified;
    initialTitle_program_application =
      props.location.state.application.title_program_application;
    initialDesignation_application =
      props.location.state.application.designation_application;
    initialUniversity_id_application =
      props.location.state.application.university_id_application;
    initialLevel_id_application =
      props.location.state.application.level_id_application;

    initialOtherInfos = props.location.state.application.otherInfor_application;
    initialuser_created_application =
      props.location.state.application.user_created_application;
    initialstatus_thematicArea_application =
      props.location.state.application.thematicArea_application;
    numberOfStaffFeedbacks = props.location.state.application.expertReviewData;
    userSubmitterInfor = props.location.state.application.userApplicationEmail;
    userApplicantName = props.location.state.application.userApplicationUsername;
    id_provenance = props.location.state.application.id_provenance;
    initialStatusApplication =
      props.location.state.application.status_application;
  }

  console.log(props.location.state.application);

  const [titleName, setTitleName] = useState(initialTitle_program_application);
  const [level, setLevel] = useState(initialLevel_id_application);
  const [designation, setDesignation] = useState(
    initialDesignation_application
  );
  const [otherInfo, setOtherInfo] = useState(initialOtherInfos);

  const [valueRejection, setValueRejection] = useState(0);
  const [disableSaveBtn, setDisableSaveBtn] = useState(false);
  const [valueValidation, setValueValidation] = useState(1);
  const [status, setStatus] = useState("");
  const [theOverAllFeedBack, setTheOverAllFeedBack] = useState("");
  const [rejectionReason, setRejectionReason] = useState("");

  let levelData = JSON.parse(localStorage.getItem("levelData"));
  if (props.location.state.application.expertReviewData) {
  }
  const history = useHistory();
  const [successGot, setSuccessGot] = useState("");
  const [dataOfAddedExpert, setDataOfAddedExpert] = useState([]);
  const [expertApplication, setExpertApplication] = useState("");
  const [submitterEmail, setSubmitterEmail] = useState([]);
  const [statusCheckboxSelect, setStatusCheckboxSelect] = useState(false);
  const [expertList, setExpertList] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  if (!(history.location.state == null)) {
    const data = history.location.state.data;
    console.log("Data send: ");
    console.log(data);
  }

  let urlUser = `${process.env.REACT_APP_API_URL}/api/user/${initialuser_created_application}/view`;

  const onSubmit = (values, { resetForm }) => {
    setButtonDisabled(true);
    let userInfo = localStorage.getItem("userInfo");
    let userJson = JSON.parse(userInfo);
    const newApplication = new FormData();

    // retrieve the applicant email

    let userCreatedApplication = JSON.parse(userInfo);

    newApplication.append("type_firstFeedback", "End of first compulations");
    newApplication.append("constants_firstFeedback", values.theOverAllFeedBack);
    newApplication.append("status_firstFeedback", statusCheckboxSelect);
    newApplication.append("id_ApplicationfirstFeedback", id_provenance);
    console.log(newApplication);
    console.log(submitterEmail);

    ApplicationFirstFeedback.createFirstFeedback(
      newApplication,
      localStorage.getItem("access"),
      userSubmitterInfor,
      initialTitle_program_application,
      userApplicantName
    )
      .then((response) => {
        setStatus("Saved correctly...");
        setSuccessGot("OKAY");
        console.log("Access: ");
        let data = {
          status: 1,
          title: "Okay",
          message:
            "Over All decision made with Success continue the next stage (Site visit)",
        };
        history.push("/application/list", { data: data });
      })
      .catch((error) => {
        setSuccessGot("Not");
        setStatus("Error Saving data");
        console.log("Error : ", error);
        let data = {
          status: 0,
          title: "Error",
          message: "An Error happened !",
        };
        history.push(`/application/list`, {
          data: data,
        });
      });
  };

  let expertListId = JSON.parse(localStorage.getItem("ExpertApplication"));

  const {
    values,
    errors,
    touched,
    isSubmitting,
    setFieldValue,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      designation_application: "",
      title_program_application: initialTitle_program_application,
      university_id_application: initialUniversity_id_application,

      evidence_for_accreditation_at_national_level: "",
      confirmation_letter_national_agency: "",
      involvment_of_the_relevant_national_bodies: "",

      otherInfor_application: initialOtherInfos,
      level_id_application: initialLevel_id_application,

      otherInfor_application: initialOtherInfos,
      level_id_application: initialLevel_id_application,
      theOverAllFeedBack: "",
    },
    validationSchema: ExpertReviewSchemasValidation,
    onSubmit,
  });

  let allUniveData = JSON.parse(localStorage.getItem("universityData"));
  let universityName = "";
  allUniveData.map((dataUniv) => {
    if (dataUniv.id === Number(initialUniversity_id_application)) {
      universityName = dataUniv.university_name;
    }
  });

  let levelGot = "";
  levelData.map((levelDB) => {
    if (levelDB.id_level === level) {
      levelGot = levelDB.designation_level;
    }
  });

   
  useEffect(() => { 
    let url = `${process.env.REACT_APP_API_URL}/api/user/list/expert`;
    UserService.getExpert(url)
      .then((response) => {
        console.log("response");
        console.log(response.data);
        setExpertList(response.data);
      })
      .catch((errors) => { 
        if (errors.response.status === 401) {
          // Redirect to the login page
          history.push('/login');
        }    
      });
  }, []);
 
  let listSelectedExpert = [];

  if (typeOperation !== "statusEdit") {
    if (expertList == null) {
      expertList.map((expert) => {
        listSelectedExpert.push(Number(expert.id_expert_applicationExpert));
      });
    }
  }

  const changeStatus = (status) => {
    console.log("checked: ", status.target.value);
    setStatusCheckboxSelect(status.target.value);
  };

  useEffect(() => {
    let notificationListUrl = `${process.env.REACT_APP_API_URL}/api/applicationExpert/${initialApplicationID}/getStatusExpertSiteVisite`;
    ExpertReviewService.getAllStatusSiteVisite(
      localStorage.getItem("access"),
      notificationListUrl
    )
      .then((resNot) => {
        console.log("resNot ", resNot);

        if (Number(resNot.data.statusApplication) === 1) {
          setButtonDisabled(true);
        }
      })
      .catch((errNot) => {
        console.log("errNot : ", errNot);
        if (errNot.response.status === 401) {
          // Redirect to the login page
          history.push('/login');
        }    
      });
  }, []);

  return (
    <div>
      <div className="w-full p-8">
         {disableSaveBtn && <Loading />}  
        <span
          className=" text-blue-500
                            mb-2 mt-2
                            hover:text-blue-800"
        >
          {" "}
          <Link to={"/application/list"}>Application List</Link>
        </span>

        {history.location.state == null ||
        history.location.state == undefined ? (
          ""
        ) : (
          <>
            {!(history.location.state.data == null) ? (
              <>
                {history.location.state.data.status === 1 ? (
                  <Success
                    delay="5000"
                    valueFromParent={history.location.state.data}
                  />
                ) : (
                  <Error
                    delay="7000"
                    valueFromParent={history.location.state.data}
                  />
                )}
              </>
            ) : (
              <></>
            )}
          </>
        )}
        <form onSubmit={handleSubmit} autoComplete="off">
          <div className="grid grid-cols-3">
            <div className="mb-4 text-md pt-4 grid grid-cols-1">
              <div className="w-1/2 px-3 mb-6 md:mb-0">
                <label
                  className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-2"
                  htmlFor="grid-first-name"
                >
                  Title of the Programme :
                </label>
                <label className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2">
                  {values.title_program_application}
                </label>
              </div>
              <div className="w-1/2 px-3 mb-6 md:mb-0 text-md ">
                <label
                  className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-2"
                  htmlFor="grid-first-name"
                >
                  Level :
                </label>
                <div className="relative">
                  <label
                    className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2"
                    htmlFor="grid-first-name"
                  >
                    {levelGot}
                  </label>
                </div>
              </div>
            </div>
            <div className="mb-4 text-md pt-4 grid grid-cols-1">
              <div className="w-full px-3 mb-4 text-md ">
                <label
                  className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-2"
                  htmlFor="grid-last-name"
                >
                  Other Informations :
                </label>
                <label
                  className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2"
                  htmlFor="grid-last-name"
                >
                  {values.otherInfor_application}
                </label>
              </div>

              <div className="w-full text-md px-3 mb-4">
                <label
                  className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-2"
                  htmlFor="grid-password"
                >
                  University :
                </label>
                <label
                  className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2"
                  htmlFor="grid-password"
                >
                  {universityName}
                </label>
              </div>
            </div>

            <div className="flex mb-4 md:mb-0 text-md pt-4 border border-gray-600">
              {
                <div className="capitalize">
                  <span className="text-md font-extrabold">
                    FeedBack on the appalication:
                  </span>{" "}
                  <hr />
                  {numberOfStaffFeedbacks.map((dataGot) => {
                    return (
                      <p>
                        {dataGot.username} :{" "}
                        <span
                          className={`${
                            Number(dataGot.status_expertReview) > 0
                              ? "text-green-600"
                              : "text-red-600"
                          }`}
                        >
                          {dataGot.constants_expertReview}
                        </span>
                      </p>
                    );
                  })}
                </div>
              }
            </div>
          </div>

          <div className="md:mb-0 text-md">
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2"
              htmlFor="grid-last-name"
            >
              Feedback on the application{" "}
              <span className="text-red-600">*</span>
            </label>
            
            <textarea
              {... Number(initialStatusApplication) > 1 ? {disabled:true} : {}}
              className="appearance-none block w-full dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] border border-gray-200 rounded py-3 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="theOverAllFeedBack"
              type="text"
              placeholder="Feedback on the application"
              name="theOverAllFeedBack"
              value={values.theOverAllFeedBack}
              onChange={handleChange}
              onBlur={handleBlur}
              rows={4}
            ></textarea>
            <p className="text-red-500 text-md italic">
              {errors.theOverAllFeedBack}
            </p>
          </div>

          <>
            <div className="mt-2" onChange={(e) => changeStatus(e)}>
              <div className="mb-[0.125rem] mr-4 inline-block min-h-[1.5rem] pl-[1.5rem]">
                <input
                  className="relative 
                      float-left 
                      -ml-[1.5rem] 
                      mr-1 mt-0.5 
                      h-5 w-5  
                      rounded-full 
                      border-2 
                      border-solid 
                      border-blue-300 
                      before:pointer-events-none 
                      before:absolute 
                      before:h-4 
                      before:w-4 
                      before:scale-0 
                      before:rounded-full 
                      before:bg-transparent 
                      before:opacity-0 
                      before:shadow-[0px_0px_0px_13px_transparent] 
                      before:content-[''] 
                      after:absolute after:z-[1] 
                      after:block after:h-4 
                      after:w-4 after:rounded-full 
                      after:content-[''] 
                      checked:border-primary 
                      checked:before:opacity-[0.16] 
                      checked:after:absolute 
                      checked:after:left-1/2 
                      checked:after:top-1/2 
                      checked:after:h-[0.625rem] 
                      checked:after:w-[0.625rem] 
                      checked:after:rounded-full 
                      checked:after:border-primary 
                      checked:after:bg-primary 
                      checked:after:content-[''] 
                      checked:after:[transform:translate(-50%,-50%)] 
                      hover:cursor-pointer hover:before:opacity-[0.04] 
                      hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] 
                      focus:shadow-none 
                      focus:outline-none 
                      focus:ring-0 
                      focus:before:scale-100 
                      focus:before:opacity-[0.12] 
                      focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] 
                      focus:before:transition-[box-shadow_0.2s,transform_0.2s] 
                      checked:focus:border-primary 
                      checked:focus:before:scale-100 
                      checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] 
                      checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] 
                      dark:border-neutral-600 
                      dark:checked:border-primary 
                      dark:checked:after:border-primary 
                      dark:checked:after:bg-primary 
                      dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] 
                      dark:checked:focus:border-primary 
                      dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]"
                  type="radio"
                  name="statusCheckboxSelect"
                  id="inlineRadio1"
                  value={"0"}
                  checked={statusCheckboxSelect === "0"}
                />
                <label
                  className="mt-px inline-block pl-[0.15rem] hover:cursor-pointer"
                  htmlFor="inlineRadio1"
                >
                  <span className="text-red-600">Deferred</span>
                </label>
              </div>

              <div className="mb-[0.125rem] mr-4 inline-block min-h-[1.5rem] pl-[1.5rem]">
                <input
                  className="relative 
                  float-left 
                  -ml-[1.5rem] 
                  mr-1 mt-0.5 
                  h-5 w-5  
                  rounded-full 
                  border-2 
                  border-solid 
                  border-blue-300 
                  before:pointer-events-none 
                  before:absolute 
                  before:h-4 
                  before:w-4 
                  before:scale-0 
                  before:rounded-full 
                  before:bg-transparent 
                  before:opacity-0 
                  before:shadow-[0px_0px_0px_13px_transparent] 
                  before:content-[''] 
                  after:absolute after:z-[1] 
                  after:block after:h-4 
                  after:w-4 after:rounded-full 
                  after:content-[''] 
                  checked:border-primary 
                  checked:before:opacity-[0.16] 
                  checked:after:absolute 
                  checked:after:left-1/2 
                  checked:after:top-1/2 
                  checked:after:h-[0.625rem] 
                  checked:after:w-[0.625rem] 
                  checked:after:rounded-full 
                  checked:after:border-primary 
                  checked:after:bg-primary 
                  checked:after:content-[''] 
                  checked:after:[transform:translate(-50%,-50%)] 
                  hover:cursor-pointer hover:before:opacity-[0.04] 
                  hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] 
                  focus:shadow-none 
                  focus:outline-none 
                  focus:ring-0 
                  focus:before:scale-100 
                  focus:before:opacity-[0.12] 
                  focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] 
                  focus:before:transition-[box-shadow_0.2s,transform_0.2s] 
                  checked:focus:border-primary 
                  checked:focus:before:scale-100 
                  checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] 
                  checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] 
                  dark:border-blue-600 
                  dark:checked:border-primary 
                  dark:checked:after:border-primary 
                  dark:checked:after:bg-primary 
                  dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] 
                  dark:checked:focus:border-primary 
                  dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]"
                  type="radio"
                  name="statusCheckboxSelect"
                  id="inlineRadio2"
                  value={"1"}
                  checked={statusCheckboxSelect === "1"}
                />
                <label
                  className="mt-px inline-block pl-[0.15rem] hover:cursor-pointer"
                  htmlFor="inlineRadio2"
                >
                  <span className="text-green-600">Acceptable</span>
                </label>
              </div>
            </div>
          </>
          {/* {
              userSubmitterInfor.map((user) =>{
                setSubmitterEmail(user.email);
              }) 
            } */}
          <div className="flex flex-wrap pt-4">
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                className="block capitalize text-md tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
                htmlFor="grid-city"
              >
                Evidence for accreditation at national level
                <span className="text-red-600">*</span>
              </label>
              {initialevidence_for_accreditation_at_national_level ? (
                <a
                  href={`${process.env.REACT_APP_API_URL}/documents/${initialevidence_for_accreditation_at_national_level}`}
                  target="_blank"
                  className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
                >
                  <svg
                    className="fill-current w-4 h-4 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                  </svg>
                  <span>View</span>
                </a>
              ) : (
                ""
              )}
            </div>
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                className="block text-md capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
                htmlFor="grid-city"
              >
                Confirmation letter from the national agency
                <span className="text-red-600">*</span>
              </label>
              {initialconfirmation_letter_national_agency ? (
                <a
                  href={`${process.env.REACT_APP_API_URL}/documents/${initialconfirmation_letter_national_agency}`}
                  target="_blank"
                  className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
                >
                  <svg
                    className="fill-current w-4 h-4 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                  </svg>
                  <span>View</span>
                </a>
              ) : (
                ""
              )}
            </div>
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md">
              <label
                className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
                htmlFor="grid-city"
              >
                Involvment of the relevant national bodies
                <span className="text-red-600">*</span>
              </label>
              {initialinvolvment_of_the_relevant_national_bodies ? (
                <a
                  href={`${process.env.REACT_APP_API_URL}/documents/${initialinvolvment_of_the_relevant_national_bodies}`}
                  target="_blank"
                  className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
                >
                  <svg
                    className="fill-current w-4 h-4 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                  </svg>
                  <span>View</span>
                </a>
              ) : (
                ""
              )}
            </div>

            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md pt-3">
              <label
                className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
                htmlFor="grid-city"
              >
                Curriculum development<span className="text-red-600">*</span>
              </label>
              {initialcurriculum_development ? (
                <a
                  href={`${process.env.REACT_APP_API_URL}/documents/${initialcurriculum_development}`}
                  target="_blank"
                  className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
                >
                  <svg
                    className="fill-current w-4 h-4 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                  </svg>
                  <span>View</span>
                </a>
              ) : (
                ""
              )}
            </div>
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md pt-3 ">
              <label
                className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
                htmlFor="grid-city"
              >
                Postgraduate supervision guidelines
                <span className="text-red-600">*</span>
              </label>
              {initialpostgraduate_supervision_guidelines ? (
                <a
                  href={`${process.env.REACT_APP_API_URL}/documents/${initialpostgraduate_supervision_guidelines}`}
                  target="_blank"
                  className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
                >
                  <svg
                    className="fill-current w-4 h-4 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                  </svg>
                  <span>View</span>
                </a>
              ) : (
                ""
              )}
            </div>
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md pt-3">
              <label
                className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
                htmlFor="grid-city"
              >
                Quality Assurance policy<span className="text-red-600">*</span>
              </label>
              {initialquality_assurence_policy ? (
                <a
                  href={`${process.env.REACT_APP_API_URL}/documents/${initialquality_assurence_policy}`}
                  target="_blank"
                  className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
                >
                  <svg
                    className="fill-current w-4 h-4 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                  </svg>
                  <span>View</span>
                </a>
              ) : (
                ""
              )}
            </div>

            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md pt-3 ">
              <label
                className="block capitalize text-md tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
                htmlFor="grid-city"
              >
                Credit transfer policy<span className="text-gray-600">*</span>
              </label>
              {initialcredit_transfer_policy ? (
                <a
                  href={`${process.env.REACT_APP_API_URL}/documents/${initialcredit_transfer_policy}`}
                  target="_blank"
                  className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
                >
                  <svg
                    className="fill-current w-4 h-4 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                  </svg>
                  <span>View</span>
                </a>
              ) : (
                ""
              )}
            </div>
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md pt-3 ">
              <label
                className="block  capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
                htmlFor="grid-city"
              >
                Summary of staff capacity<span className="text-red-600">*</span>
              </label>
              {initialsummary_of_staff_capacity ? (
                <a
                  href={`${process.env.REACT_APP_API_URL}/documents/${initialsummary_of_staff_capacity}`}
                  target="_blank"
                  className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
                >
                  <svg
                    className="fill-current w-4 h-4 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                  </svg>
                  <span>View</span>
                </a>
              ) : (
                ""
              )}
            </div>
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md pt-3 ">
              <label
                className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
                htmlFor="grid-city"
              >
                Inventory of facilities<span className="text-red-600">*</span>
              </label>
              {initialinventory_of_facilities ? (
                <a
                  href={`${process.env.REACT_APP_API_URL}/documents/${initialinventory_of_facilities}`}
                  target="_blank"
                  className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
                >
                  <svg
                    className="fill-current w-4 h-4 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                  </svg>
                  <span>View</span>
                </a>
              ) : (
                ""
              )}
            </div>

            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md pt-3 ">
              <label
                className="block capitalize text-md tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
                htmlFor="grid-city"
              >
                Annual survey report<span className="text-red-600">*</span>
              </label>
              {initialannual_survey_report ? (
                <a
                  href={`${process.env.REACT_APP_API_URL}/documents/${initialannual_survey_report}`}
                  target="_blank"
                  className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
                >
                  <svg
                    className="fill-current w-4 h-4 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                  </svg>
                  <span>View</span>
                </a>
              ) : (
                ""
              )}
            </div>
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md pt-3 ">
              <label
                className="block text-md capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
                htmlFor="grid-city"
              >
                Tracer study report<span className="text-gray-600">*</span>
              </label>
              {initialtracer_study_report ? (
                <a
                  href={`${process.env.REACT_APP_API_URL}/documents/${initialtracer_study_report}`}
                  target="_blank"
                  className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
                >
                  <svg
                    className="fill-current w-4 h-4 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                  </svg>
                  <span>View</span>
                </a>
              ) : (
                ""
              )}
            </div>
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md pt-3 ">
              <label
                className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
                htmlFor="grid-city"
              >
                Examination policy<span className="text-red-600">*</span>
              </label>
              {initialexamination_policy ? (
                <a
                  href={`${process.env.REACT_APP_API_URL}/documents/${initialexamination_policy}`}
                  target="_blank"
                  className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
                >
                  <svg
                    className="fill-current w-4 h-4 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                  </svg>
                  <span>View</span>
                </a>
              ) : (
                ""
              )}
            </div>

            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md pt-3">
              <label
                className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
                htmlFor="grid-city"
              >
                Admission Policy<span className="text-red-600">*</span>
              </label>
              {initialadmission_policy ? (
                <a
                  href={`${process.env.REACT_APP_API_URL}/documents/${initialadmission_policy}`}
                  target="_blank"
                  className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
                >
                  <svg
                    className="fill-current w-4 h-4 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                  </svg>
                  <span>View</span>
                </a>
              ) : (
                ""
              )}
            </div>
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md pt-3 ">
              <label
                className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
                htmlFor="grid-city"
              >
                Program review report<span className="text-red-600">*</span>
              </label>
              {initialprogram_review_report ? (
                <a
                  href={`${process.env.REACT_APP_API_URL}/documents/${initialprogram_review_report}`}
                  target="_blank"
                  className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
                >
                  <svg
                    className="fill-current w-4 h-4 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                  </svg>
                  <span>View</span>
                </a>
              ) : (
                ""
              )}
            </div>
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md pt-3 ">
              <label
                className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
                htmlFor="grid-city"
              >
                Other not specified<span className="text-gray-600">*</span>
              </label>
              {initialother_not_specified ? (
                <a
                  href={`${process.env.REACT_APP_API_URL}/documents/${initialother_not_specified}`}
                  target="_blank"
                  className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
                >
                  <svg
                    className="fill-current w-4 h-4 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                  </svg>
                  <span>View</span>
                </a>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="text-center p-5">
            {Number(initialStatusApplication) <= 1 ? (
              <button
                {...(isSubmitting ? { disabled: true } : {})}
                type="submit"
                className="dark:bg-[var(--color-dark-buttonBackground)] 
                       bg-[var(--color-light-buttonBackground)] 
                       dark:text-[var(--color-dark-fontButton)] 
                       text-[var(--color-light-fontButton)]  
                       w-[250px]  py-3 rounded-md font-medium"
              >
                {" "}
                {typeOperation == "AddExpert" ? (
                  <span>Send Repport to the university</span>
                ) : (
                  "Send Repport to the university"
                )}
              </button>
            ) : (
              <span >Aleardy submitted</span>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { login })(ExpertReviewView);
