import React from 'react'

const Popup = ({ message, onConfirm, onCancel, colorFont }) => {
    console.log("colorFont ",colorFont);
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-20 z-40">
          <div className="bg-white p-6 rounded shadow">
            <p className={`mb-4 font-semibold uppercase text-${colorFont}-600`}>{message}</p>
            <div className="flex justify-end">
              <button className="bg-green-500 hover:bg-green-700 text-white rounded px-4 py-2 mr-2" onClick={onConfirm}>
                YES
              </button>
              <button className="bg-gray-300 hover:bg-gray-400 text-gray-700 rounded px-4 py-2" onClick={onCancel}>
                NO
              </button>
            </div>
          </div>
        </div>
    
    
      );
}

export default Popup