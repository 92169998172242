import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import ApplicationService from "../../../services/ApplicationService";
import { connect } from "react-redux";
import { login } from "../../actions/auth";
import { differenceInDays, parseISO, format } from "date-fns";
import { useFormik } from "formik";
import Success from "../../alerts/Success";
import Error from "../../alerts/Error";
import UserService from "../../../services/UserService";
import Loading from "../../eventsHandler/Loading";
import TabCHEA from "../../smallComponents/TabCHEA";
import { preliminaryAssessment } from "../ConstantsAssessementsReportCHEA";
import TabContentCHEA from "../../smallComponents/TabContentCHEA";
import Popup from "../../alerts/Popup";
import AccreditationService from "../../../services/AccreditationService";
import AppealService from "../../../services/AppealService";
import AppealExpertReview from "../../../services/AppealExpertReviewService";
import AppealExpertReviewService from "../../../services/AppealExpertReviewService";
import TabContent from "../../smallComponents/TabContent";
import { preliminaryAssessmentAssessorsReviewLastDecion } from "../ConstantsAssessementsForExpertReviewLastDecision";

const AppealExpertAdd = (props) => {
  let editing = false;
  let initialTitle_program_application = "";
  let initialDesignation_application = "";
  let initialUniversity_id_application = "";
  let initialLevel_id_application = "";
  let initialOtherInfos = "";
  let initialevidence_for_accreditation_at_national_level = "";
  let initialconfirmation_letter_national_agency = "";
  let initialinvolvment_of_the_relevant_national_bodies = "";
  let initialcurriculum_development = "";
  let initialpostgraduate_supervision_guidelines = "";
  let initialquality_assurence_policy = "";
  let initialcredit_transfer_policy = "";
  let initialsummary_of_staff_capacity = "";
  let initialinventory_of_facilities = "";
  let initialannual_survey_report = "";
  let initialtracer_study_report = "";
  let initialexamination_policy = "";
  let initialadmission_policy = "";
  let initialprogram_review_report = "";
  let initialother_not_specified = "";
  let initialApplicationID = "";
  let typeOperation = "";
  let initialstatus_application = "";
  let initialstatus_thematicArea_application = "";
  let initialAppealID = "";
  let appealExpertID = "";
  let initialNumberRemaining = -1;
  let initialId_CHEADecision_appeal_id = "";

  let idAffectationAppeal = props.location.state.notifID;
  let dateSubmission = props.location.state.dateSubmission;

  let currentDate = new Date();
  let targetDate = "";
  let daysDifference = 0;
  if (dateSubmission) {
    targetDate = parseISO(dateSubmission);
    daysDifference = differenceInDays(targetDate, currentDate);
  }

  console.log(props.location.state.currentApplication)
  let cheaDecisionComment =
    props.location.state.currentApplication.CHEADecision.constants_CHEADecision;

  if (props.location.state && props.location.state.currentApplication) {
    editing = true;
    typeOperation = props.location.state.typeOperation;
    initialstatus_application =
      props.location.state.currentApplication.resultsApplication
        .status_application;
    initialApplicationID =
      props.location.state.currentApplication.resultsApplication.id_application;
    initialevidence_for_accreditation_at_national_level =
      props.location.state.currentApplication.resultsApplication
        .evidence_for_accreditation_at_national_level;
    initialconfirmation_letter_national_agency =
      props.location.state.currentApplication.resultsApplication
        .confirmation_letter_national_agency;
    initialinvolvment_of_the_relevant_national_bodies =
      props.location.state.currentApplication.resultsApplication
        .involvment_of_the_relevant_national_bodies;
    initialcurriculum_development =
      props.location.state.currentApplication.resultsApplication
        .curriculum_development;
    initialpostgraduate_supervision_guidelines =
      props.location.state.currentApplication.resultsApplication
        .postgraduate_supervision_guidelines;
    initialquality_assurence_policy =
      props.location.state.currentApplication.resultsApplication
        .quality_assurence_policy;
    initialcredit_transfer_policy =
      props.location.state.currentApplication.resultsApplication
        .credit_transfer_policy;
    initialsummary_of_staff_capacity =
      props.location.state.currentApplication.resultsApplication
        .summary_of_staff_capacity;
    initialinventory_of_facilities =
      props.location.state.currentApplication.resultsApplication
        .inventory_of_facilities;
    initialannual_survey_report =
      props.location.state.currentApplication.resultsApplication
        .annual_survey_report;
    initialtracer_study_report =
      props.location.state.currentApplication.resultsApplication
        .tracer_study_report;
    initialexamination_policy =
      props.location.state.currentApplication.resultsApplication
        .examination_policy;
    initialadmission_policy =
      props.location.state.currentApplication.resultsApplication
        .admission_policy;
    initialprogram_review_report =
      props.location.state.currentApplication.resultsApplication
        .program_review_report;
    initialother_not_specified =
      props.location.state.currentApplication.resultsApplication
        .other_not_specified;
    initialTitle_program_application =
      props.location.state.currentApplication.resultsApplication
        .title_program_application;
    initialDesignation_application =
      props.location.state.currentApplication.resultsApplication
        .designation_application;
    initialUniversity_id_application =
      props.location.state.currentApplication.resultsApplication
        .university_id_application;
    initialLevel_id_application =
      props.location.state.currentApplication.resultsApplication
        .level_id_application;

    initialOtherInfos =
      props.location.state.currentApplication.resultsApplication
        .otherInfor_application;
    initialAppealID = props.location.state.currentApplication.id_appeal;
    appealExpertID = props.location.state.currentApplication.appealExpertID;
    initialstatus_thematicArea_application =
      props.location.state.currentApplication.resultsApplication
        .thematicArea_application;
    initialNumberRemaining =
      props.location.state.currentApplication.numberRemaining;
    initialId_CHEADecision_appeal_id =
      props.location.state.currentApplication.CHEADecision.id_CHEADecision;
  }

  // console.log(props.location.state.currentApplication.userInfo.useremail);
  let userApplicationEmail =
    props.location.state.currentApplication.userInfo.useremail;
  let userApplicationID =
    props.location.state.currentApplication.userInfo.userId;
  let userApplicationUserName =
    props.location.state.currentApplication.userInfo.username;
  let titleProgram =
    props.location.state.currentApplication.title_program_application;

  const [titleName, setTitleName] = useState(initialTitle_program_application);
  const [level, setLevel] = useState(initialLevel_id_application);
  const [designation, setDesignation] = useState(
    initialDesignation_application
  );
  const [disableSaveBtn, setDisableSaveBtn] = useState(false);
  const [otherInfo, setOtherInfo] = useState(initialOtherInfos);
  const [colorFont, setColorFont] = useState("");
  const [confirmationText, setConfirmationText] = useState("");

  let levelData = JSON.parse(localStorage.getItem("levelData"));

  const history = useHistory();
  const [userInfo, setUserInfo] = useState("");
  const [expertId, setExpertId] = useState([]);
  const [dataOfAddedExpert, setDataOfAddedExpert] = useState([]);
  const [expertApplication, setExpertApplication] = useState("");
  const [thematic, setThematic] = useState([]);
  const [statusCheckboxSelect, setStatusCheckboxSelect] = useState(false);

  const [showPopup, setShowPopup] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [clickedButton, setclickedButton] = useState("");

  const [removeValidateReview, setRemoveValidateReview] = useState("");
  const [removeObjectionReview, setRemoveObjectionReview] = useState("");
  const [hiddenTextAreas, setHiddenTextAreas] = useState("hidden");

  const [valueRejection, setValueRejection] = useState(0);
  const [valueValidation, setValueValidation] = useState(1);

  const [status, setStatus] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [rejectionReason, setRejectionReason] = useState("");
  const [expertList, setExpertList] = useState([]);

  if (!(history.location.state == null)) {
    const data = history.location.state.data;
    // console.log("Data send: ");
    // console.log(data);
  }

  useEffect(() => {
    retrieveApplicationsExepert(initialAppealID);
  }, [initialAppealID]);

  const retrieveApplicationsExepert = (appealID) => {
    setDataOfAddedExpert([]);
    dataOfAddedExpert.length = 0;
    var access = localStorage.getItem("access");
    const linkToData = `${process.env.REACT_APP_API_URL}/api/appealExpert/${appealID}/appeal`;

    AppealService.getAllExpert(access, linkToData)
      .then((response) => {
        localStorage.setItem(
          "ExpertApplication",
          JSON.stringify(response.data)
        );
        setExpertApplication(response.data);
        // console.log("response.data", response.data);
      })
      .catch((e) => {
        console.log("error : ", e);
        if (e.response.status === 401) {
          // Redirect to the login page
          history.push('/login');
        }    
      });
  };

  if (expertApplication.length > 0 && expertApplication != null) {
    expertApplication.forEach((expert) => {
      dataOfAddedExpert.push(Number(expert.id_expert_expertAppeal));
    });
  }

  // console.log("expertApplication", props.location.state.currentApplication.CHEADecision.siteVisiteData.reportSiteVisite);

  let siteVisitInfo =
    props.location.state.currentApplication.CHEADecision.siteVisiteData
      .reportSiteVisite;

      let numberSubmission = siteVisitInfo.length; 
      let newDictionnarySiteVisite = {};
      let siteVisiteIDReport = [];
      let totalGotSiteVisite = 0;
      let totalRemoveSiteVisite = 0;
    
      siteVisitInfo.map((data) => {
        siteVisiteIDReport.push(data.id_siteVisitAffectationExpert);
        let dataSiteVisite = JSON.parse(data.dataColectedSiteVisite);
        // console.log(dataSiteVisite);
        Object.keys(dataSiteVisite).forEach((key) => {
          if (newDictionnarySiteVisite.hasOwnProperty(key)) {
            if (key.endsWith("NA")) {
              // for the one to be removed
              let newValue = Number(dataSiteVisite[key]);
              if (!isNaN(newValue)) {
                newDictionnarySiteVisite[key] += newValue;
                totalRemoveSiteVisite = totalRemoveSiteVisite + newValue;
              } else {
                newDictionnarySiteVisite[key] = dataSiteVisite[key];
              }
            } else {
              // normal one
              let newValue = Number(dataSiteVisite[key]);
              if (!isNaN(newValue)) {
                newDictionnarySiteVisite[key] += newValue;
                totalGotSiteVisite = totalGotSiteVisite + newValue;
              } else {
                newDictionnarySiteVisite[key] = dataSiteVisite[key];
              }
            }
          } else {
            if (key.endsWith("NA")) {
              // total to be removed
              let newValue = Number(dataSiteVisite[key]);
              if (!isNaN(newValue)) {
                newDictionnarySiteVisite[key] = newValue;
                totalRemoveSiteVisite = totalRemoveSiteVisite + newValue; 
              }  
            } else {
              // normal one
              let newValue = Number(dataSiteVisite[key]);
              if (!isNaN(newValue)) {
                newDictionnarySiteVisite[key] = newValue;
                totalGotSiteVisite = totalGotSiteVisite + newValue;
              } else {
                newDictionnarySiteVisite[key] = dataSiteVisite[key];
              }
            }
          }
        });
      });
    
      let scoreSiteVisite = (
        (totalGotSiteVisite / (numberSubmission * (805-(totalRemoveSiteVisite)))) *
        100
      ).toFixed(2); 
  // console.log("scoreSiteVisite ", scoreSiteVisite);
  console.log(props.location.state.currentApplication)
  let expertReviewData =
    props.location.state.currentApplication.CHEADecision.siteVisiteData.expertReview;
  let numberSubmissionExpertReview = expertReviewData.length;

  let newDictionnaryExpertReview = {};
  let expertReviewIdAlreadyDone = [];
  let numberGotTotalExpertReview = 0;

  if (expertReviewData) {
    expertReviewData.map((data) => {
      // console.log(data.id_siteVisite);
      expertReviewIdAlreadyDone.push(data.id_expertReview);
      let dataExpertReviewScore = JSON.parse(data.scoreFromExpert);
      Object.keys(dataExpertReviewScore).forEach((key) => {
        if (newDictionnaryExpertReview.hasOwnProperty(key)) {
          let newValue = Number(dataExpertReviewScore[key]);
          if (!isNaN(newValue)) {
            newDictionnaryExpertReview[key] += newValue;
            numberGotTotalExpertReview = numberGotTotalExpertReview + newValue;
          } else {
            newDictionnaryExpertReview[key] +=
              "\n *" + dataExpertReviewScore[key];
          }
        } else {
          // if it is a new value
          let newValue = Number(dataExpertReviewScore[key]);
          if (!isNaN(newValue)) {
            newDictionnaryExpertReview[key] = newValue;
            numberGotTotalExpertReview = numberGotTotalExpertReview + newValue;
          } else {
            newDictionnaryExpertReview[key] = "*" + dataExpertReviewScore[key];
          }
        }
      });
    });
  }

  let scoreFirstReview =
    (numberGotTotalExpertReview / (numberSubmissionExpertReview * 145)) * 100;

  // alert('okay')
  const onSubmit = (values, { resetForm }) => {
    setDisableSaveBtn(true);
    let userInfo = localStorage.getItem("userInfo");
    let userCreatedApplication = JSON.parse(userInfo);

    const neReviewSubmission = new FormData();

    if (Number(statusCheckboxSelect) ===3) {
      neReviewSubmission.append("status_applicationAppeal", "3");
      neReviewSubmission.append(
        "constant_appealAffectationExpert",
        values.theOverAllFeedBack
      );
    } else if (Number(statusCheckboxSelect) === 4) {
      neReviewSubmission.append("status_applicationAppeal", "4");
      neReviewSubmission.append(
        "constant_appealAffectationExpert",
        values.theOverAllFeedBack
      );
    }

    AppealService.submissionReviewAppeal(
      idAffectationAppeal,
      neReviewSubmission,
      localStorage.getItem("access"),
      userCreatedApplication.email,
      userApplicationUserName,
      userApplicationEmail,
      initialTitle_program_application
    )
      .then((response) => {
        console.log("Access: ");
        let data = {
          status: 1,
          title: "Okay",
          message: "Saved with Success ... Wait for feedback please!!!",
        };
        history.push("/dashboard", { data: data });
      })
      .catch((error) => {
        console.log("Error : ", error);
        let data = {
          status: 0,
          title: "Error",
          message: "An Error happened !",
        };
        history.push(`/dashboard`, {
          data: data,
        });
      });
  };

  const handleConfirm = (e) => {
    // console.log("event ", e);
    e.preventDefault();
    handleSubmit();
    setShowPopup(false);
  };

  const handleCancel = () => {
    setShowPopup(false);
  };

  const stopEventForm = (event) => {
    event.preventDefault();
  };

  const changeStatus = (status) => {
    setStatusCheckboxSelect(status.target.value);
  };

  let expertListId = JSON.parse(localStorage.getItem("ExpertApplication"));

  const {
    values,
    errors,
    touched,
    isSubmitting,
    setFieldValue,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      designation_application: "",
      title_program_application: initialTitle_program_application,
      university_id_application: initialUniversity_id_application,

      evidence_for_accreditation_at_national_level: "",
      confirmation_letter_national_agency: "",
      involvment_of_the_relevant_national_bodies: "",
      action: "",
      inputValue: "",

      otherInfor_application: initialOtherInfos,
      level_id_application: initialLevel_id_application,

      otherInfor_application: initialOtherInfos,
      level_id_application: initialLevel_id_application,
      theOverAllFeedBack: "",
    },
    onSubmit,
  });

  console.log("errors ", errors);

  let allUniveData = JSON.parse(localStorage.getItem("universityData"));
  let universityName = "";
  allUniveData.map((dataUniv) => {
    if (dataUniv.id === Number(initialUniversity_id_application)) {
      universityName = dataUniv.university_name;
    }
  });

  let levelGot = "";
  levelData.map((levelDB) => {
    if (levelDB.id_level === level) {
      levelGot = levelDB.designation_level;
    }
  });

  let listSelectedExpert = [];

  if (typeOperation !== "statusEdit") {
    if (expertList == null) {
      expertList.map((expert) => {
        listSelectedExpert.push(Number(expert.id_expert_applicationExpert));
      });
    }
  }

  function isInArray(value, array) {
    return array.indexOf(value) > -1;
  }

  let numberOfExpert = expertList.length;
  let hiddenExpert = 0;

  useEffect(() => {
    let url = `${process.env.REACT_APP_API_URL}/api/user/list/expert`;
    UserService.getExpert(url)
      .then((response) => {
        console.log("response");
        console.log(response.data);
        setExpertList(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 401) {
          // Redirect to the login page
          history.push('/login');
        }    
      });
  }, []);

  // console.log('newDictionnarySiteVisite ',newDictionnarySiteVisite);

  function removeItemOnce(arr, value) {
    var index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  }

  function removeItemAll(arr, value) {
    var i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }

  const onAddingItem = (item) => {
    const isChecked = item.target.checked;
    let newValue = item.target.value;
    if (isChecked) {
      expertId.push(newValue);
      expertId.indexOf(newValue) === -1
        ? expertId.push(newValue)
        : console.log("This item already exists");
    } else {
      removeItemAll(expertId, newValue);
    }
    console.log("List: ", expertId);
  };

  allUniveData.map((dataUniv) => {
    if (dataUniv.id === Number(initialUniversity_id_application)) {
      universityName = dataUniv.university_name;
    }
  });

  if (typeOperation !== "statusEdit") {
    if (expertListId == null) {
      expertListId.map((expert) => {
        listSelectedExpert.push(Number(expert.id_expert_applicationExpert));
      });
    }
  }

  function isInArray(value, array) {
    return array.indexOf(value) > -1;
  }

  const showInputTextArea = (event) => {
    if (event.target.checked) {
      setHiddenTextAreas("hidden");
      setRemoveValidateReview(true);
    } else {
      setHiddenTextAreas("");
      setRemoveValidateReview(false);
    }
  };

  const disableObjection = (event) => {
    if (event.target.checked) {
      setHiddenTextAreas("hidden");
      setRemoveObjectionReview(false);
      setValueRejection(0);
      setValueValidation(1);
      console.log("Okay");
    } else {
      setRemoveObjectionReview(true);
      console.log("Test");
      setHiddenTextAreas("");
      setValueRejection(1);
      setValueValidation(0);
    }
  };

  useEffect(() => {
    // getThematic
    let url = `${process.env.REACT_APP_API_URL}/api/thematicArea/`;
    UserService.getThematic(url)
      .then((response) => {
        console.log("response");
        console.log(response.data);
        setThematic(response.data);
      })
      .catch((errors) => {
        console.log("errors");
        console.log(errors);
        if (errors.response.status === 401) {
          // Redirect to the login page
          history.push('/login');
        }    
      });
  }, []);

  const tabs = [
    {
      title: "Documents",
      content: (
        <div className="flex flex-wrap pt-4">
          <div className="w-full md:w-1/3 px-3 mb-3 md:mb-0">
            <label
              className="block capitalize text-md tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
              htmlFor="grid-city"
            >
              Evidence for accreditation at national level
              <span className="text-red-600">*</span>
            </label>
            {initialevidence_for_accreditation_at_national_level ? (
              <a
                rel="noreferrer"
                href={`${process.env.REACT_APP_API_URL}/documents/${initialevidence_for_accreditation_at_national_level}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>
              </a>
            ) : (
              ""
            )}
          </div>
          <div className="w-full md:w-1/3 px-3 mb-3 md:mb-0">
            <label
              className="block text-md capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
              htmlFor="grid-city"
            >
              Confirmation letter from the national agency
              <span className="text-red-600">*</span>
            </label>
            {initialconfirmation_letter_national_agency ? (
              <a
                rel="noreferrer"
                href={`${process.env.REACT_APP_API_URL}/documents/${initialconfirmation_letter_national_agency}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>
              </a>
            ) : (
              ""
            )}
          </div>
          <div className="w-full md:w-1/3 px-3 mb-3 md:mb-0 text-md">
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
              htmlFor="grid-city"
            >
              Involvment of the relevant national bodies
              <span className="text-red-600">*</span>
            </label>
            {initialinvolvment_of_the_relevant_national_bodies ? (
              <a
                rel="noreferrer"
                href={`${process.env.REACT_APP_API_URL}/documents/${initialinvolvment_of_the_relevant_national_bodies}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>
              </a>
            ) : (
              ""
            )}
          </div>

          <div className="w-full md:w-1/3 px-3 mb-3 md:mb-0 text-md pt-3">
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
              htmlFor="grid-city"
            >
              Curriculum development<span className="text-red-600">*</span>
            </label>
            {initialcurriculum_development ? (
              <a
                rel="noreferrer"
                href={`${process.env.REACT_APP_API_URL}/documents/${initialcurriculum_development}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>
              </a>
            ) : (
              ""
            )}
          </div>
          <div className="w-full md:w-1/3 px-3 mb-3 md:mb-0 text-md pt-3 ">
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
              htmlFor="grid-city"
            >
              Postgraduate supervision guidelines
              <span className="text-red-600">*</span>
            </label>
            {initialpostgraduate_supervision_guidelines ? (
              <a
                rel="noreferrer"
                href={`${process.env.REACT_APP_API_URL}/documents/${initialpostgraduate_supervision_guidelines}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>
              </a>
            ) : (
              ""
            )}
          </div>
          <div className="w-full md:w-1/3 px-3 mb-3 md:mb-0 text-md pt-3">
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
              htmlFor="grid-city"
            >
              Quality Assurance policy<span className="text-red-600">*</span>
            </label>
            {initialquality_assurence_policy ? (
              <a
                rel="noreferrer"
                href={`${process.env.REACT_APP_API_URL}/documents/${initialquality_assurence_policy}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>
              </a>
            ) : (
              ""
            )}
          </div>

          <div className="w-full md:w-1/3 px-3 mb-3 md:mb-0 text-md pt-3 ">
            <label
              className="block capitalize text-md tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
              htmlFor="grid-city"
            >
              Credit transfer policy<span className="text-gray-600">*</span>
            </label>
            {initialcredit_transfer_policy ? (
              <a
                rel="noreferrer"
                href={`${process.env.REACT_APP_API_URL}/documents/${initialcredit_transfer_policy}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>
              </a>
            ) : (
              ""
            )}
          </div>
          <div className="w-full md:w-1/3 px-3 mb-3 md:mb-0 text-md pt-3 ">
            <label
              className="block  capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
              htmlFor="grid-city"
            >
              Summary of staff capacity<span className="text-red-600">*</span>
            </label>
            {initialsummary_of_staff_capacity ? (
              <a
                rel="noreferrer"
                href={`${process.env.REACT_APP_API_URL}/documents/${initialsummary_of_staff_capacity}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>
              </a>
            ) : (
              ""
            )}
          </div>
          <div className="w-full md:w-1/3 px-3 mb-3 md:mb-0 text-md pt-3 ">
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
              htmlFor="grid-city"
            >
              Inventory of facilities<span className="text-red-600">*</span>
            </label>
            {initialinventory_of_facilities ? (
              <a
                rel="noreferrer"
                href={`${process.env.REACT_APP_API_URL}/documents/${initialinventory_of_facilities}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>
              </a>
            ) : (
              ""
            )}
          </div>

          <div className="w-full md:w-1/3 px-3 mb-3 md:mb-0 text-md pt-3 ">
            <label
              className="block capitalize text-md tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
              htmlFor="grid-city"
            >
              Annual survey report<span className="text-red-600">*</span>
            </label>
            {initialannual_survey_report ? (
              <a
                rel="noreferrer"
                href={`${process.env.REACT_APP_API_URL}/documents/${initialannual_survey_report}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>
              </a>
            ) : (
              ""
            )}
          </div>
          <div className="w-full md:w-1/3 px-3 mb-3 md:mb-0 text-md pt-3 ">
            <label
              className="block text-md capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
              htmlFor="grid-city"
            >
              Tracer study report<span className="text-gray-600">*</span>
            </label>
            {initialtracer_study_report ? (
              <a
                rel="noreferrer"
                href={`${process.env.REACT_APP_API_URL}/documents/${initialtracer_study_report}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>
              </a>
            ) : (
              ""
            )}
          </div>
          <div className="w-full md:w-1/3 px-3 mb-3 md:mb-0 text-md pt-3 ">
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
              htmlFor="grid-city"
            >
              Examination policy<span className="text-red-600">*</span>
            </label>
            {initialexamination_policy ? (
              <a
                rel="noreferrer"
                href={`${process.env.REACT_APP_API_URL}/documents/${initialexamination_policy}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>
              </a>
            ) : (
              ""
            )}
          </div>

          <div className="w-full md:w-1/3 px-3 mb-3 md:mb-0 text-md pt-3">
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
              htmlFor="grid-city"
            >
              Admission Policy<span className="text-red-600">*</span>
            </label>
            {initialadmission_policy ? (
              <a
                rel="noreferrer"
                href={`${process.env.REACT_APP_API_URL}/documents/${initialadmission_policy}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>
              </a>
            ) : (
              ""
            )}
          </div>
          <div className="w-full md:w-1/3 px-3 mb-3 md:mb-0 text-md pt-3 ">
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
              htmlFor="grid-city"
            >
              Program review report<span className="text-red-600">*</span>
            </label>
            {initialprogram_review_report ? (
              <a
                rel="noreferrer"
                href={`${process.env.REACT_APP_API_URL}/documents/${initialprogram_review_report}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>
              </a>
            ) : (
              ""
            )}
          </div>
          <div className="w-full md:w-1/3 px-3 mb-3 md:mb-0 text-md pt-3 ">
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
              htmlFor="grid-city"
            >
              Other not specified<span className="text-gray-600">*</span>
            </label>
            {initialother_not_specified ? (
              <a
                rel="noreferrer"
                href={`${process.env.REACT_APP_API_URL}/documents/${initialother_not_specified}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>
              </a>
            ) : (
              ""
            )}
          </div>
        </div>
      ),
    }, // end documents content
    {
      title: preliminaryAssessment(newDictionnarySiteVisite, numberSubmission)
        .point1[0].bigHeader,
      content: (
        <TabContentCHEA
          content={
            preliminaryAssessment(newDictionnarySiteVisite, numberSubmission)
              .point1[0]
          }
          numberSubmission={numberSubmission}
        />
      ),
    },
    {
      title: preliminaryAssessment(newDictionnarySiteVisite, numberSubmission)
        .point1[1].bigHeader,
      content: (
        <TabContentCHEA
          content={
            preliminaryAssessment(newDictionnarySiteVisite, numberSubmission)
              .point1[1]
          }
          numberSubmission={numberSubmission}
        />
      ),
    },
    {
      title: preliminaryAssessmentAssessorsReviewLastDecion(
        newDictionnaryExpertReview,
        numberSubmissionExpertReview
      ).point1[0].bigHeader,
      content: (
        <div>
          <TabContent
            content={
              preliminaryAssessmentAssessorsReviewLastDecion(
                newDictionnaryExpertReview,
                numberSubmissionExpertReview
              ).point1[0]
            }
          />
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="w-full p-8">
        {disableSaveBtn && <Loading />}

        {history.location.state === null ||
        history.location.state === undefined ? (
          ""
        ) : (
          <>
            {!(history.location.state.data == null) ? (
              <>
                {history.location.state.data.status === 1 ? (
                  <Success
                    delay="5000"
                    valueFromParent={history.location.state.data}
                  />
                ) : (
                  <Error
                    delay="7000"
                    valueFromParent={history.location.state.data}
                  />
                )}
              </>
            ) : (
              <></>
            )}
          </>
        )}
        <form onSubmit={(event) => stopEventForm(event)} autoComplete="off">
          <div className="grid grid-cols-2">
            <div className="mb-4 text-md grid grid-cols-1">
              <div className="md:mb-0">
                <label
                  className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-2"
                  htmlFor="grid-first-name"
                >
                  Title of the Programme :
                </label>
                <label className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2">
                  {values.title_program_application}
                </label>
              </div>
              <div className="md:mb-0 text-md ">
                <label
                  className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-2"
                  htmlFor="grid-first-name"
                >
                  Level :
                </label>
                <div className="relative">
                  <label
                    className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2"
                    htmlFor="grid-first-name"
                  >
                    {levelGot}
                  </label>
                </div>
              </div>
            </div>
            <div className="mb-4 text-md grid grid-cols-1">
              <div className="w-full px-3 mb-4 text-md ">
                <label
                  className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-2"
                  htmlFor="grid-last-name"
                >
                  Other Informations :
                </label>
                <label
                  className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2"
                  htmlFor="grid-last-name"
                >
                  {values.otherInfor_application}
                </label>
              </div>

              <div className="w-full text-md px-3 mb-4">
                <label
                  className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-2"
                  htmlFor="grid-password"
                >
                  University :
                </label>
                <label
                  className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2"
                  htmlFor="grid-password"
                >
                  {universityName}
                </label>
              </div>
            </div>
            <div className="mb-4 text-md hidden">
              {typeOperation === "AppealReview" ? (
                <div className="capitalize">
                  <div className="w-full px-3 mb-4 text-md ">
                    <label
                      className="block capitalize 
                                          tracking-wide 
                                          dark:text-[var(--color-dark-font)] 
                                          text-[var(--color-light-font)] 
                                          text-md text-green-700 font-light mb-2"
                      htmlFor="grid-last-name"
                    >
                      Validate you rAppeal review /Or Uncheck for Objection :
                    </label>

                    <div className="flex items-center mb-2">
                      <input
                        id="checkboxExpert"
                        type="checkbox"
                        value={`1`}
                        name="checkboxExpert"
                        defaultChecked={true}
                        onChange={(e) => disableObjection(e)}
                        className="w-4 h-4 
                                                        text-blue-600 
                                                        bg-gray-100 
                                                        border-gray-300 
                                                        rounded 
                                                        focus:ring-blue-500 
                                                        dark:focus:ring-blue-600 
                                                        dark:ring-offset-gray-800 
                                                        focus:ring-2 
                                                        dark:bg-gray-700 
                                                        dark:border-gray-600"
                      />
                      <label
                        for="default-checkbox"
                        className="ml-2 
                                                            text-md 
                                                            font-medium 
                                                            dark:text-[var(--color-dark-font)] 
                                                            text-[var(--color-light-font)]"
                      >
                        {" "}
                        Validate first review
                      </label>
                    </div>
                  </div>
                  <div
                    className={`rejectionReason w-full px-3 mb-4 text-md  ${hiddenTextAreas}`}
                  >
                    <label
                      className="block capitalize 
                                          tracking-wide 
                                          dark:text-[var(--color-dark-font)] 
                                          text-[var(--color-light-font)] 
                                          text-md text-red-700 font-light mb-2"
                      htmlFor="grid-last-name"
                    >
                      Deferred :
                    </label>

                    <div className="flex items-center mb-2">
                      <input
                        id="rejection"
                        type="checkbox"
                        value={`1`}
                        checked={removeObjectionReview}
                        name="checkboxRejection"
                        onChange={(e) => showInputTextArea(e)}
                        className="w-4 h-4 
                                                        text-blue-600 
                                                        bg-gray-100 
                                                        border-gray-300 
                                                        rounded 
                                                        focus:ring-blue-500 
                                                        dark:focus:ring-blue-600 
                                                        dark:ring-offset-gray-800 
                                                        focus:ring-2 
                                                        dark:bg-gray-700 
                                                        dark:border-gray-600"
                      />
                      <label
                        for="default-checkbox"
                        className="ml-2 
                                                            text-md 
                                                            font-medium 
                                                            dark:text-[var(--color-dark-font)] 
                                                            text-[var(--color-light-font)]"
                      >
                        {" "}
                        Deferred application
                      </label>
                    </div>

                    <div className="w-full px-3 mb-4 text-md ">
                      <textarea
                        className={`rejectionReason ${hiddenTextAreas}`}
                        name="rejectionReason"
                        id="rejectionReason"
                        onChange={(e) => setRejectionReason(e.target.value)}
                        placeholder="Please provide a reason"
                        cols="30"
                        rows="3"
                      ></textarea>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="w-full px-3 mb-0 text-md ">
                  {Number(initialNumberRemaining) == 0 ? (
                    <>
                      {" "}
                      <label
                        className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        Submission from Expert are :
                      </label>
                      {
                        expertList.map((expert) => {
                          let status = false;
                          let showOrHideExpert = "";
                          if (isInArray(Number(expert.id), dataOfAddedExpert)) {
                            status = true;
                          } else {
                            status = false;
                          }
                          let expertQualification = expert.thematicArea;

                          if (
                            isInArray(
                              initialstatus_thematicArea_application,
                              expertQualification.split(",")
                            )
                          ) {
                            showOrHideExpert = "";
                          } else {
                            showOrHideExpert = "hidden";
                            hiddenExpert = hiddenExpert + 1;
                          }

                          if (hiddenExpert === numberOfExpert) {
                            return (
                              <span className="text-red-500">
                                {" "}
                                No expert found for that appeal in: "{" "}
                                {thematic.map((area) =>
                                  initialstatus_thematicArea_application ===
                                  area.id_thematicsArea
                                    ? area.designation_thematicsArea
                                    : ""
                                )}
                                "
                              </span>
                            );
                          } else {
                            return (
                              <div
                                className={`flex items-center mb-2 ${showOrHideExpert}`}
                              >
                                <label
                                  for="default-checkbox"
                                  className="ml-2 
                                                                 text-md 
                                                                 font-medium 
                                                                 dark:text-[var(--color-dark-font)] 
                                                                 text-[var(--color-light-font)]"
                                >
                                  {expert.name}{" "}
                                  {status ? (
                                    <span className="text-green-600">
                                      {" "}
                                      {expertApplication.map((data) => (
                                        <>
                                          {Number(
                                            data.id_expert_expertAppeal
                                          ) === expert.id ? (
                                            <label
                                              className={`${
                                                data.status_expertAppeal > 2
                                                  ? "text-red-500"
                                                  : ""
                                              }`}
                                            >
                                              {data.constants_expertAppeal}
                                            </label>
                                          ) : (
                                            ""
                                          )}
                                        </>
                                      ))}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </label>
                              </div>
                            );
                          }
                        })
                        //   })
                      }
                    </>
                  ) : (
                    <>
                      <label
                        className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        Experts :
                      </label>

                      {
                        expertList.map((expert) => {
                          let status = false;
                          let showOrHideExpert = "";
                          if (isInArray(Number(expert.id), dataOfAddedExpert)) {
                            status = true;
                          } else {
                            status = false;
                          }
                          let expertQualification = expert.thematicArea;

                          if (
                            isInArray(
                              initialstatus_thematicArea_application,
                              expertQualification.split(",")
                            )
                          ) {
                            showOrHideExpert = "";
                          } else {
                            showOrHideExpert = "hidden";
                            hiddenExpert = hiddenExpert + 1;
                          }

                          if (hiddenExpert === numberOfExpert) {
                            return (
                              <span className="text-red-500">
                                {" "}
                                No expert found for that appeal in: "{" "}
                                {thematic.map((area) =>
                                  initialstatus_thematicArea_application ===
                                  area.id_thematicsArea
                                    ? area.designation_thematicsArea
                                    : ""
                                )}
                                "
                              </span>
                            );
                          } else {
                            return (
                              <div
                                className={`flex items-center mb-2 ${showOrHideExpert}`}
                              >
                                <input
                                  id="checkboxExpert"
                                  type="checkbox"
                                  value={`${expert.id}`}
                                  name="checkboxExpert"
                                  disabled={status}
                                  onChange={onAddingItem}
                                  defaultChecked={status}
                                  className="w-4 h-4 
                                                             text-blue-600 
                                                             bg-gray-100 
                                                             border-gray-300 
                                                             rounded 
                                                             focus:ring-blue-500 
                                                             dark:focus:ring-blue-600 
                                                             dark:ring-offset-gray-800 
                                                             focus:ring-2 
                                                             dark:bg-gray-700 
                                                             dark:border-gray-600"
                                />
                                <label
                                  for="default-checkbox"
                                  className="ml-2 
                                                                 text-md 
                                                                 font-medium 
                                                                 dark:text-[var(--color-dark-font)] 
                                                                 text-[var(--color-light-font)]"
                                >
                                  {expert.name}{" "}
                                  {status ? (
                                    <span className="text-green-600">
                                      {" "}
                                      -Added already
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </label>
                              </div>
                            );
                          }
                        })
                        //   })
                      }
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
          <span className="md:mb-0 font-semibold text-md text-red-800 lg:mb-2 capitalize">
            Comment provided: {cheaDecisionComment}
          </span>
          <div
            className={`mb-3 border border-gray-300 ${
              typeOperation === "ReviewExpert" ? "" : "hidden"
            } `}
          >
            <div className="md:mb-0 text-md lg:mb-2">
              <label
                className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2"
                htmlFor="grid-last-name"
              >
                Provide you point of view on this appeal
                <span className="text-red-600">*</span> *** Due:
                <span
                  className={`${
                    daysDifference <= 3 ? "text-red-600" : "text-blue-600"
                  }`}
                >
                  {dateSubmission}
                </span>
              </label>

              <textarea
                className="appearance-none block w-full dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] border border-gray-200 rounded py-3 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="theOverAllFeedBack"
                type="text"
                placeholder="Feedback on the application"
                name="theOverAllFeedBack"
                value={values.theOverAllFeedBack}
                onChange={handleChange}
                onBlur={handleBlur}
                rows={4}
              ></textarea>
              <p className="text-red-500 text-md italic">
                {errors.theOverAllFeedBack}
              </p>
            </div>
            <div className="mt-2" onChange={(e) => changeStatus(e)}>
              <div className="mb-[0.125rem] mr-4 inline-block min-h-[1.5rem] pl-[1.5rem]">
                <input
                  className="relative 
                      float-left 
                      -ml-[1.5rem] 
                      mr-1 mt-0.5 
                      h-5 w-5  
                      rounded-full 
                      border-2 
                      border-solid 
                      border-blue-300 
                      before:pointer-events-none 
                      before:absolute 
                      before:h-4 
                      before:w-4 
                      before:scale-0 
                      before:rounded-full 
                      before:bg-transparent 
                      before:opacity-0 
                      before:shadow-[0px_0px_0px_13px_transparent] 
                      before:content-[''] 
                      after:absolute after:z-[1] 
                      after:block after:h-4 
                      after:w-4 after:rounded-full 
                      after:content-[''] 
                      checked:border-primary 
                      checked:before:opacity-[0.16] 
                      checked:after:absolute 
                      checked:after:left-1/2 
                      checked:after:top-1/2 
                      checked:after:h-[0.625rem] 
                      checked:after:w-[0.625rem] 
                      checked:after:rounded-full 
                      checked:after:border-primary 
                      checked:after:bg-primary 
                      checked:after:content-[''] 
                      checked:after:[transform:translate(-50%,-50%)] 
                      hover:cursor-pointer hover:before:opacity-[0.04] 
                      hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] 
                      focus:shadow-none 
                      focus:outline-none 
                      focus:ring-0 
                      focus:before:scale-100 
                      focus:before:opacity-[0.12] 
                      focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] 
                      focus:before:transition-[box-shadow_0.2s,transform_0.2s] 
                      checked:focus:border-primary 
                      checked:focus:before:scale-100 
                      checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] 
                      checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] 
                      dark:border-neutral-600 
                      dark:checked:border-primary 
                      dark:checked:after:border-primary 
                      dark:checked:after:bg-primary 
                      dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] 
                      dark:checked:focus:border-primary 
                      dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]"
                  type="radio"
                  name="statusCheckboxSelect"
                  id="inlineRadio1"
                  value={'3'}
                  checked={statusCheckboxSelect === '3'}
                />
                <label
                  className="mt-px inline-block pl-[0.15rem] hover:cursor-pointer"
                  htmlFor="inlineRadio1"
                >
                  <span className="text-red-600">Deferred</span>
                </label>
              </div>

              <div className="mb-[0.125rem] mr-4 inline-block min-h-[1.5rem] pl-[1.5rem]">
                <input
                  className="relative 
                  float-left 
                  -ml-[1.5rem] 
                  mr-1 mt-0.5 
                  h-5 w-5  
                  rounded-full 
                  border-2 
                  border-solid 
                  border-blue-300 
                  before:pointer-events-none 
                  before:absolute 
                  before:h-4 
                  before:w-4 
                  before:scale-0 
                  before:rounded-full 
                  before:bg-transparent 
                  before:opacity-0 
                  before:shadow-[0px_0px_0px_13px_transparent] 
                  before:content-[''] 
                  after:absolute after:z-[1] 
                  after:block after:h-4 
                  after:w-4 after:rounded-full 
                  after:content-[''] 
                  checked:border-primary 
                  checked:before:opacity-[0.16] 
                  checked:after:absolute 
                  checked:after:left-1/2 
                  checked:after:top-1/2 
                  checked:after:h-[0.625rem] 
                  checked:after:w-[0.625rem] 
                  checked:after:rounded-full 
                  checked:after:border-primary 
                  checked:after:bg-primary 
                  checked:after:content-[''] 
                  checked:after:[transform:translate(-50%,-50%)] 
                  hover:cursor-pointer hover:before:opacity-[0.04] 
                  hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] 
                  focus:shadow-none 
                  focus:outline-none 
                  focus:ring-0 
                  focus:before:scale-100 
                  focus:before:opacity-[0.12] 
                  focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] 
                  focus:before:transition-[box-shadow_0.2s,transform_0.2s] 
                  checked:focus:border-primary 
                  checked:focus:before:scale-100 
                  checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] 
                  checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] 
                  dark:border-blue-600 
                  dark:checked:border-primary 
                  dark:checked:after:border-primary 
                  dark:checked:after:bg-primary 
                  dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] 
                  dark:checked:focus:border-primary 
                  dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]"
                  type="radio"
                  name="statusCheckboxSelect"
                  id="inlineRadio2"
                  value={"4"}
                  checked={statusCheckboxSelect === '4'}
                />
                <label
                  className="mt-px inline-block pl-[0.15rem] hover:cursor-pointer"
                  htmlFor="inlineRadio2"
                >
                  <span className="text-green-600">Acceptable</span>
                </label>
              </div>
            </div>
          </div>

          <TabCHEA tabs={tabs} />

          <div className="w-full sm:w-full md:w-full lg:w-full flex flex-col md:flex-row md:grid md:grid-cols-3 border border-gray-300 p-3">
            <span className="text-md font-semibold">
              Site  visite ({numberSubmission})
            </span>
            <span className="text-md font-semibold">
              Score: {totalGotSiteVisite} over {numberSubmission * 805}
            </span>
            <span
              className={`${
                scoreSiteVisite > 70 ? "text-green-600" : "text-red-600"
              } text-md font-semibold`}
            >
              Percent: {(scoreSiteVisite).toFixed(2)}
            </span>
          </div>
          <br />
          <div className="w-full sm:w-full md:w-full lg:w-full flex flex-col md:flex-row md:grid md:grid-cols-3 border border-gray-300 p-3">
            <span className="text-md font-semibold">
              Expert review ({numberSubmissionExpertReview})
            </span>
            <span className="text-md font-semibold">
              Score: {numberGotTotalExpertReview} over{" "}
              {numberSubmissionExpertReview * 145}
            </span>
            <span
              className={`${
                scoreFirstReview > 70 ? "text-green-600" : "text-red-600"
              } text-md font-semibold`}
            >
              Percent: {(scoreFirstReview).toFixed(2)}
            </span>
          </div>

          <div className="text-center p-2 justify-between">
            <button
              name="action"
              type="submit"
              value={"accreditation"}
              className="dark:bg-gray-800
              bg-gray-500 
                        dark:text-[var(--color-dark-fontButton)] 
                        text-[var(--color-light-fontButton)] 
                        mr-2 
                        w-[120px]  py-2 rounded-md font-medium"
              onClick={() => {
                setFormValues(values); // Store form values
                setShowPopup(true);
                setclickedButton("accreditation");
                setColorFont("green");
                setConfirmationText(
                  "Are you sure you want to Submit this report?"
                );
              }}
            >
              Submit review
            </button>
          </div>
        </form>
        {showPopup && (
          <Popup
            message={confirmationText}
            onConfirm={(e) => handleConfirm(e)}
            onCancel={handleCancel}
            colorFont={colorFont}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { login })(AppealExpertAdd);
