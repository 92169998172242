import * as yup from "yup";

// const regularExpression = /^(?=.*\d)(?=.*[a-z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
const regularExpression = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;
const SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/gif",
  "image/png",
  "application/pdf",
];

export const userRegSchemasValidation = yup.object().shape({
  name: yup.string().required("Required"),
  // email : yup.string().email("Please enter a valid email").required("Required"),
  password: yup
    .string()
    .min(8)
    .matches(regularExpression, {
      message:
        "Please create a stronger password with capital Letter and special characters",
    })
    .required("Required"),
  re_password: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must much!")
    .min(8)
    .required("Required"),
});

export const userResetEmail = yup.object().shape({
  name: yup.string().required("Required"),
  // email : yup.string().email("Please enter a valid email").required("Required"), 
});

export const CHEASchemasValidation = yup.object().shape({
  theOverAllFeedBack: yup.string().required("Please provide something"),
  // theOverAllFeedBack : yup.string().required("Required"),
});

export const ApplicationSchemasValidation = yup.object().shape({
  title_program_application: yup.string().required("Required"),
  level_id_application: yup.string().required("Required"),

  evidence_for_accreditation_at_national_level: yup
    .mixed()
    .required("You need to provide a  PDF file"),
  confirmation_letter_national_agency: yup
    .mixed()
    .required("You need to provide a  PDF file"),
   
  curriculum_development: yup
    .mixed()
    .required("You need to provide a  PDF file"),
   
  quality_assurence_policy: yup
    .mixed()
    .required("You need to provide a  PDF file"),
  summary_of_staff_capacity: yup
    .mixed()
    .required("You need to provide a  PDF file"),
  inventory_of_facilities: yup
    .mixed()
    .required("You need to provide a  PDF file"),
  annual_survey_report: yup.mixed().required("You need to provide a  PDF file"),
  admission_policy: yup.mixed().required("You need to provide a  PDF file"),
  examination_policy: yup
    .mixed()
    .required("You need to provide a  PDF file for the examination policy"),
  program_review_report: yup
    .mixed()
    .required("You need to provide a  PDF file"),
  auditReport_report: yup
    .mixed()
    .required("You need to provide a  PDF file for the Audit Report "),

  confirmation_letter_of_key_stackHolder: yup
    .mixed()
    .required("You need to provide a  PDF file for the confirmation Letter"),

  curriculum_development_and_guidelines: yup
    .mixed()
    .required(
      "You need to provide a  PDF file Curriculum development and guidelines"
    ),

  evidence_of_participation_of_stackHolder: yup
    .mixed()
    .required(
      "You need to provide a  PDF file for the evidence of participation of stackHolder"
    ),

  market_survey_report: yup
    .mixed()
    .required("You need to provide a  PDF file for market survey repport"),

  programme_file: yup
    .mixed()
    .required("You need to provide a  PDF file for the programme file"),
  evidence_of_payment: yup
    .mixed()
    .required("You need to provide a  PDF file for the evidence of payment"),
});

export const ExpertReviewSchemasValidation = yup.object().shape({
  theOverAllFeedBack: yup.string().required("Required"),
});
