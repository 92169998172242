import axios from "axios";


class UniversityService{

    async getAll(access, url){ 
        axios.defaults.headers.common["Authorization"] = "JWT "+ access;
        // axios.defaults.headers.common["X-Frame-Options"] = "sameorigin";
        axios.defaults.headers.common["Content-type"] = "multipart/form-data";
        axios.defaults.headers.common["Accept-Encoding"] = "gzip, deflate, br";
        const  result = await axios.get(`${url}`);
        return result;
    } 

    async getOpenApi(url){  
      // axios.defaults.headers.common["X-Frame-Options"] = "sameorigin";
      axios.defaults.headers.common["Content-type"] = "multipart/form-data";
      axios.defaults.headers.common["Accept-Encoding"] = "gzip, deflate, br";
      const  result = await axios.get(`${url}`);
      return result;
  } 

    createUniversity(data, access) {
        axios.defaults.headers.common["Authorization"] = "JWT " + access;
        axios.defaults.headers.common["Content-type"] = "multipart/form-data";
        axios.defaults.headers.common["Accept-Encoding"] = "gzip, deflate, br";
      // console.log("userEmail SEr",userEmail);
        return axios.post(
          `${process.env.REACT_APP_API_URL}/api/university/create`,
          data
        );
      }

    getSmallData(access) {
      axios.defaults.headers.common["Authorization"] = "JWT " + access;
      axios.defaults.headers.common["Content-type"] = "multipart/form-data";
      axios.defaults.headers.common["Accept-Encoding"] = "gzip, deflate, br";
 
      return axios.get(
        `${process.env.REACT_APP_API_URL}/api/getSmallComponent/list`, 
      );
    } 
 
    getCountries(access) {
      axios.defaults.headers.common["Authorization"] = "JWT " + access;
      axios.defaults.headers.common["Content-type"] = "multipart/form-data";
      axios.defaults.headers.common["Accept-Encoding"] = "gzip, deflate, br";
 
      return axios.get(
        `${process.env.REACT_APP_API_URL}/api/getListCountries/list`, 
      );
    } 

    updateUniversity(id,data, access){ 
        axios.defaults.headers.common["Authorization"] = "JWT "+ access;
        axios.defaults.headers.common["Content-type"] = "multipart/form-data";
        axios.defaults.headers.common["Accept-Encoding"] = "gzip, deflate, br";
         
        return axios.put(`${process.env.REACT_APP_API_URL}/api/university/edit/${id}`, data)
    } 

    deleteuniversity(id, access){
        axios.defaults.headers.common["Authorization"] = "JWT "+ access;
        axios.defaults.headers.common["Content-type"] = "multipart/form-data";
        axios.defaults.headers.common["Accept-Encoding"] = "gzip, deflate, br";
       
        return axios.put(`${process.env.REACT_APP_API_URL}/api/university/delete/${id}`);
    }
    
}
export default new UniversityService()