import React from 'react'
import { 
    FaLinkedin,
    FaFacebookSquare,
    FaInstagramSquare,
    FaTwitterSquare,
 } from 'react-icons/fa';


function Footer() {
  return (
    <div className='pl-10 pr-10 justify-center w-full   dark:bg-[var(--color-dark-bakcgroundSecond)] bg-[var(--color-light-backgroundSecond)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)]  mx-auto py-16 px-4 grid lg:grid-cols-3 gap-8 '> 
        <div className=''>
            <h1 className='w-full text-3xl font-bold dark:text-[var(--color-dark-font)] text-[var(--color-light-font)]'>Regional Accreditation </h1>
            <p className='py-2 font-loader'>
            IUCEA has rules governing membership and establish the application process, eligibility, categories, and admission and termination of IUCEA membership. The IUCEA membership comprises of universities, university colleges, and other institutions.
            </p> 
            <div className='flex justify-between md:w-[75%] my-6'>
                <a href="https://www.facebook.com/InterUniversity/"><FaFacebookSquare size={30} /></a>
                <FaInstagramSquare size={30} />
               <a href="https://twitter.com/iucea_info"> <FaTwitterSquare  size={30} /></a>
                <a href="https://www.linkedin.com/in/inter-univesity-council-for-east-africa-684077212/?originalSubdomain=ug"><FaLinkedin size={30} /></a>
            </div>
        </div>
        <div className='lg:col-span-2 flex justify-between mt-6'>
            <div>
                <h5 className='font-medium dark:text-[var(--color-dark-font)] text-[var(--color-light-font)]'>Quick links</h5>
                <ul>
                    <li className='py-2 text-sm'>Analytics</li>
                    <li className='py-2 text-sm'>QA</li>
                    <li className='py-2 text-sm'>Schoolarship</li>
                </ul>
            </div>
            <div>
                <h5 className='font-medium dark:text-[var(--color-dark-font)] text-[var(--color-light-font)]'>Support</h5>
                <ul>
                    <li className='py-2 text-sm'>Importants</li>
                    <li className='py-2 text-sm'>Document</li>
                    <li className='py-2 text-sm'>About</li>
                </ul>
            </div>
            <div>
                <h5 className='font-medium text-gay-400'>Others</h5>
                <ul>
                    <li className='py-2 text-sm'><a href="https://eac.int">EAC</a> </li>
                    <li className='py-2 text-sm'><a href="https://iucea.org">IUCEA</a> </li>
                    <li className='py-2 text-sm'><a href="https://dskills.iucea.org/">dSkills@EA</a> </li>
                </ul>
            </div>
        </div>
    </div>
  )
}

export default Footer