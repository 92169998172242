import React from 'react'
import Navbar from './Navbar'
import { FaEdit,FaTrash,FaEye } from 'react-icons/fa'

const List = () => {
  return (
    <div>
         <Navbar />
 
<div className="m-6 relative overflow-x-auto shadow-md sm:rounded-lg">
    <div className="flex items-center justify-between pb-4">
        <div>
            <button id="dropdownRadioButton" data-dropdown-toggle="dropdownRadio" className="inline-flex items-center text-[var(--color-tbl200)] bg-[var(--color-gray-tbl0)] border border-[var(--color-gray-tbl300)] focus:outline-none hover:bg-[var(--color-gray-tbl100)] focus:ring-4 focus:ring-[var(--color-gray-tbl200)] font-medium rounded-lg text-sm px-3 py-1.5 dark:bg-[var(--color-gray-tbl800)] dark:text-[var(--color-gray-tbl0)] dark:border-[var(--color-gray-tbl600)] dark:hover:bg-[var(--color-gray-tbl700)] dark:hover:border-[var(--color-gray-tbl600)] dark:focus:ring-[var(--color-gray-tbl700)]" type="button">
                <svg className="w-4 h-4 mr-2 text-[var(--color-gray-tbl400)]" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clipRule="evenodd"></path></svg>
                Last 30 days
                <svg className="w-3 h-3 ml-2" aria-hidden="true" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path></svg>
            </button>
            
            <div id="dropdownRadio" className="z-10 hidden w-48 bg-[var(--color-gray-tbl0)] divide-y divide-[var(--color-gray-tbl100)] rounded-lg shadow dark:bg-[var(--color-gray-tbl700)] dark:divide-[var(--color-gray-tbl600)]" data-popper-reference-hidden="" data-popper-escaped="" data-popper-placement="top" style={{position: 'absolute', inset: 'auto auto 0px 0px', margin: '0px', transform: 'translate3d(522.5px, 3847.5px, 0px)'}}>
                <ul className="p-3 space-y-1 text-sm text-[var(--color-gray-tbl700)] dark:text-[var(--color-gray-tbl200)]" aria-labelledby="dropdownRadioButton">
                    <li>
                        <div className="flex items-center p-2 rounded hover:bg-[var(--color-gray-tbl100)] dark:hover:bg-[var(--color-gray-tbl600)]">
                            <input id="filter-radio-example-1" type="radio" value="" name="filter-radio" className="w-4 h-4 text-blue-600 bg-[var(--color-gray-tbl100)] border-[var(--color-gray-tbl300)] focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-[var(--color-gray-tbl800)] dark:focus:ring-offset-[var(--color-gray-tbl800)] focus:ring-2 dark:bg-[var(--color-gray-tbl700)] dark:border-[var(--color-gray-tbl600)]" />
                            <label htmlFor="filter-radio-example-1" className="w-full ml-2 text-sm font-medium text-[var(--color-gray-tbl900)] rounded dark:text-[var(--color-gray-tbl300)]">Last day</label>
                        </div>
                    </li>
                    <li>
                        <div className="flex items-center p-2 rounded hover:bg-[var(--color-gray-tbl100)] dark:hover:bg-[var(--color-gray-tbl600)]">
                            <input checked="" onChange={e => {}} id="filter-radio-example-2" type="radio" value="" name="filter-radio" className="w-4 h-4 text-blue-600 bg-[var(--color-gray-tbl100)] border-[var(--color-gray-tbl300)] focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-[var(--color-gray-tbl800)] dark:focus:ring-offset-[var(--color-gray-tbl800)] focus:ring-2 dark:bg-[var(--color-gray-tbl700)] dark:border-[var(--color-gray-tbl600)]" />
                            <label htmlFor="filter-radio-example-2" className="w-full ml-2 text-sm font-medium text-[var(--color-gray-tbl900)] rounded dark:text-[var(--color-gray-tbl300)]">Last 7 days</label>
                        </div>
                    </li>
                    <li>
                        <div className="flex items-center p-2 rounded hover:bg-[var(--color-gray-tbl100)] dark:hover:bg-[var(--color-gray-tbl600)]">
                            <input id="filter-radio-example-3" type="radio" value="" name="filter-radio" className="w-4 h-4 text-blue-600 bg-[var(--color-gray-tbl100)] border-[var(--color-gray-tbl300)] focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-[var(--color-gray-tbl800)] dark:focus:ring-offset-[var(--color-gray-tbl800)] focus:ring-2 dark:bg-[var(--color-gray-tbl700)] dark:border-[var(--color-gray-tbl600)]" />
                            <label htmlFor="filter-radio-example-3" className="w-full ml-2 text-sm font-medium text-[var(--color-gray-tbl900)] rounded dark:text-[var(--color-gray-tbl300)]">Last 30 days</label>
                        </div>
                    </li>
                    <li>
                        <div className="flex items-center p-2 rounded hover:bg-[var(--color-gray-tbl100)] dark:hover:bg-[var(--color-gray-tbl600)]">
                            <input id="filter-radio-example-4" type="radio" value="" name="filter-radio" className="w-4 h-4 text-blue-600 bg-[var(--color-gray-tbl100)] border-[var(--color-gray-tbl300)] focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-[var(--color-gray-tbl800)] dark:focus:ring-offset-[var(--color-gray-tbl800)] focus:ring-2 dark:bg-[var(--color-gray-tbl700)] dark:border-[var(--color-gray-tbl600)]" />
                            <label htmlFor="filter-radio-example-4" className="w-full ml-2 text-sm font-medium text-[var(--color-gray-tbl900)] rounded dark:text-[var(--color-gray-tbl300)]">Last month</label>
                        </div>
                    </li>
                    <li>
                        <div className="flex items-center p-2 rounded hover:bg-[var(--color-gray-tbl100)] dark:hover:bg-[var(--color-gray-tbl600)]">
                            <input id="filter-radio-example-5" type="radio" value="" name="filter-radio" className="w-4 h-4 text-blue-600 bg-[var(--color-gray-tbl100)] border-[var(--color-gray-tbl300)] focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-[var(--color-gray-tbl800)] dark:focus:ring-offset-[var(--color-gray-tbl800)] focus:ring-2 dark:bg-[var(--color-gray-tbl700)] dark:border-[var(--color-gray-tbl600)]" />
                            <label htmlFor="filter-radio-example-5" className="w-full ml-2 text-sm font-medium text-[var(--color-gray-tbl900)] rounded dark:text-[var(--color-gray-tbl300)]">Last year</label>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <label htmlFor="table-search" className="sr-only">Search</label>
        <div className="relative">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg className="w-5 h-5 text-[var(--color-tbl200)] dark:text-[var(--color-gray-tbl400)]" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path></svg>
            </div>
            <input type="text" id="table-search" className="block p-2 pl-10 text-sm text-[var(--color-gray-tbl900)] border border-[var(--color-gray-tbl300)] rounded-lg w-80 bg-[var(--color-gray-tbl50)] focus:ring-blue-500 focus:border-blue-500 dark:bg-[var(--color-gray-tbl700)] dark:border-[var(--color-gray-tbl600)] dark:placeholder-[var(--color-gray-tbl400)] dark:text-[var(--color-gray-tbl0)] dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search for items" />
        </div>
    </div>
    <table className="w-full text-sm text-left text-[var(--color-tbl200)] dark:text-[var(--color-gray-tbl400)]">
        <thead className="text-xs text-[var(--color-gray-tbl700)] uppercase bg-[var(--color-gray-tbl50)] dark:bg-[var(--color-gray-tbl700)] dark:text-[var(--color-gray-tbl400)]">
            <tr>
                <th scope="col" className="p-4">
                    <div className="flex items-center">
                        <input id="checkbox-all-search" type="checkbox" className="w-4 h-4 text-blue-600 bg-[var(--color-gray-tbl100)] border-[var(--color-gray-tbl300)] rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-[var(--color-gray-tbl800)] dark:focus:ring-offset-[var(--color-gray-tbl800)] focus:ring-2 dark:bg-[var(--color-gray-tbl700)] dark:border-[var(--color-gray-tbl600)]" />
                        <label htmlFor="checkbox-all-search" className="sr-only">checkbox</label>
                    </div>
                </th>
                <th scope="col" className="px-6 py-3">
                    Product name
                </th>
                <th scope="col" className="px-6 py-3">
                    Color
                </th>
                <th scope="col" className="px-6 py-3">
                    Category
                </th>
                <th scope="col" className="px-6 py-3">
                    Price
                </th>
                <th scope="col" className="px-6 py-3">
                    Action
                </th>
            </tr>
        </thead>
        <tbody>
            <tr className="bg-[var(--color-gray-tbl0)] border-b dark:bg-[var(--color-gray-tbl800)] dark:border-[var(--color-gray-tbl700)] hover:bg-[var(--color-gray-tbl200)] dark:hover:bg-[var(--color-gray-tbl600)]">
                <td className="w-4 p-4">
                    <div className="flex items-center">
                        <input id="checkbox-table-search-1" type="checkbox" className="w-4 h-4 text-blue-600 bg-[var(--color-gray-tbl100)] border-[var(--color-gray-tbl300)] rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-[var(--color-gray-tbl800)] dark:focus:ring-offset-[var(--color-gray-tbl800)] focus:ring-2 dark:bg-[var(--color-gray-tbl700)] dark:border-[var(--color-gray-tbl600)]" />
                        <label htmlFor="checkbox-table-search-1" className="sr-only">checkbox</label>
                    </div>
                </td>
                <th scope="row" className="px-6 py-4 font-medium text-[var(--color-gray-tbl900)] whitespace-nowrap dark:text-[var(--color-gray-tbl0)]">
                    Apple MacBook Pro 17"
                </th>
                <td className="px-6 py-4">
                    Silver
                </td>
                <td className="px-6 py-4">
                    Laptop
                </td>
                <td className="px-6 py-4">
                    $2999
                </td>
              
                <td className="px-6 py-4 grid grid-cols-3">
                    <a href="/edit" rel="noopener noreferrer" className="font-medium text-blue-600 dark:text-blue-500 hover:underline"><FaEdit /></a>
                    <a href="/delete" rel="noopener noreferrer" className="font-medium text-red-600 dark:text-red-500 hover:underline"><FaTrash /></a>
                    <a href="/view" rel="noopener noreferrer" className="font-medium text-[var(--color-gray-tbl600)] dark:text-[var(--color-tbl200)] hover:underline"><FaEye /></a>
                </td>
            </tr>
            <tr className="bg-[var(--color-gray-tbl0)] border-b dark:bg-[var(--color-gray-tbl800)] dark:border-[var(--color-gray-tbl700)] hover:bg-[var(--color-gray-tbl200)] dark:hover:bg-[var(--color-gray-tbl600)]">
                <td className="w-4 p-4">
                    <div className="flex items-center">
                        <input id="checkbox-table-search-2" type="checkbox" className="w-4 h-4 text-blue-600 bg-[var(--color-gray-tbl100)] border-[var(--color-gray-tbl300)] rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-[var(--color-gray-tbl800)] dark:focus:ring-offset-[var(--color-gray-tbl800)] focus:ring-2 dark:bg-[var(--color-gray-tbl700)] dark:border-[var(--color-gray-tbl600)]" />
                        <label htmlFor="checkbox-table-search-2" className="sr-only">checkbox</label>
                    </div>
                </td>
                <th scope="row" className="px-6 py-4 font-medium text-[var(--color-gray-tbl900)] whitespace-nowrap dark:text-[var(--color-gray-tbl0)]">
                    Microsoft Surface Pro
                </th>
                <td className="px-6 py-4">
                    White
                </td>
                <td className="px-6 py-4">
                    Laptop PC
                </td>
                <td className="px-6 py-4">
                    $1999
                </td>
                
                <td className="px-6 py-4 grid grid-cols-3">
                    <a href="/edit" rel="noopener noreferrer" className="font-medium text-blue-600 dark:text-blue-500 hover:underline"><FaEdit /></a>
                    <a href="/delete" rel="noopener noreferrer" className="font-medium text-red-600 dark:text-red-500 hover:underline"><FaTrash /></a>
                    <a href="/view" rel="noopener noreferrer" className="font-medium text-[var(--color-gray-tbl600)] dark:text-[var(--color-tbl200)] hover:underline"><FaEye /></a>
                </td>
            </tr>
            <tr className="bg-[var(--color-gray-tbl0)] border-b dark:bg-[var(--color-gray-tbl800)] dark:border-[var(--color-gray-tbl700)] hover:bg-[var(--color-gray-tbl200)] dark:hover:bg-[var(--color-gray-tbl600)]">
                <td className="w-4 p-4">
                    <div className="flex items-center">
                        <input id="checkbox-table-search-3" type="checkbox" className="w-4 h-4 text-blue-600 bg-[var(--color-gray-tbl100)] border-[var(--color-gray-tbl300)] rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-[var(--color-gray-tbl800)] dark:focus:ring-offset-[var(--color-gray-tbl800)] focus:ring-2 dark:bg-[var(--color-gray-tbl700)] dark:border-[var(--color-gray-tbl600)]" />
                        <label htmlFor="checkbox-table-search-3" className="sr-only">checkbox</label>
                    </div>
                </td>
                <th scope="row" className="px-6 py-4 font-medium text-[var(--color-gray-tbl900)] whitespace-nowrap dark:text-[var(--color-gray-tbl0)]">
                    Magic Mouse 2
                </th>
                <td className="px-6 py-4">
                    Black
                </td>
                <td className="px-6 py-4">
                    Accessories
                </td>
                <td className="px-6 py-4">
                    $99
                </td>
              
                <td className="px-6 py-4 grid grid-cols-3">
                    <a href="/edit" rel="noopener noreferrer" className="font-medium text-blue-600 dark:text-blue-500 hover:underline"><FaEdit /></a>
                    <a href="/delete" rel="noopener noreferrer" className="font-medium text-red-600 dark:text-red-500 hover:underline"><FaTrash /></a>
                    <a href="/view" rel="noopener noreferrer" className="font-medium text-[var(--color-gray-tbl600)] dark:text-[var(--color-tbl200)] hover:underline"><FaEye /></a>
                </td>
            </tr>
            <tr className="bg-[var(--color-gray-tbl0)] border-b dark:bg-[var(--color-gray-tbl800)] dark:border-[var(--color-gray-tbl700)] hover:bg-[var(--color-gray-tbl200)] dark:hover:bg-[var(--color-gray-tbl600)]">
                <td className="w-4 p-4">
                    <div className="flex items-center">
                        <input id="checkbox-table-3" type="checkbox" className="w-4 h-4 text-blue-600 bg-[var(--color-gray-tbl100)] border-[var(--color-gray-tbl300)] rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-[var(--color-gray-tbl800)] dark:focus:ring-offset-[var(--color-gray-tbl800)] focus:ring-2 dark:bg-[var(--color-gray-tbl700)] dark:border-[var(--color-gray-tbl600)]" />
                        <label htmlFor="checkbox-table-3" className="sr-only">checkbox</label>
                    </div>
                </td>
                <th scope="row" className="px-6 py-4 font-medium text-[var(--color-gray-tbl900)] whitespace-nowrap dark:text-[var(--color-gray-tbl0)]">
                    Apple Watch
                </th>
                <td className="px-6 py-4">
                    Silver
                </td>
                <td className="px-6 py-4">
                    Accessories
                </td>
                <td className="px-6 py-4">
                    $179
                </td>
              
                <td className="px-6 py-4 grid grid-cols-3">
                    <a href="/edit" rel="noopener noreferrer" className="font-medium text-blue-600 dark:text-blue-500 hover:underline"><FaEdit /></a>
                    <a href="/delete" rel="noopener noreferrer" className="font-medium text-red-600 dark:text-red-500 hover:underline"><FaTrash /></a>
                    <a href="/view" rel="noopener noreferrer" className="font-medium text-[var(--color-gray-tbl600)] dark:text-[var(--color-tbl200)] hover:underline"><FaEye /></a>
                </td>
            </tr>
             
        </tbody>
    </table>

    {/* <section className="flex items-center h-screen bg-[var(--color-gray-tbl50)] dark:bg-[var(--color-gray-tbl900)]"> */}
        <div className="w-full">
            {/* <!-- Start coding here --> */}
            <div className="relative overflow-hidden bg-[var(--color-gray-tbl0)] rounded-b-lg shadow-md dark:bg-[var(--color-gray-tbl800)]">
            <nav className="flex flex-col items-start justify-between p-4 space-y-3 md:flex-row md:items-center md:space-y-0"
                aria-label="Table navigation">
                <span className="text-sm font-normal text-[var(--color-gray-tbl500)] dark:text-[var(--color-gray-tbl400)]">Showing <span
                    className="font-semibold text-[var(--color-gray-tbl900)] dark:text-[var(--color-gray-tbl0)]">1-10</span> of <span
                    className="font-semibold text-[var(--color-gray-tbl900)] dark:text-[var(--color-gray-tbl0)]">1000</span></span>
                <ul className="inline-flex items-stretch -space-x-px">
                <li>
                    <a href="/"
                    className="flex items-center justify-center h-full py-1.5 px-3 ml-0 text-[var(--color-gray-tbl500)] bg-[var(--color-gray-tbl0)] rounded-l-lg border border-[var(--color-gray-tbl300)] hover:bg-[var(--color-gray-tbl100)] hover:text-[var(--color-gray-tbl700)] dark:bg-[var(--color-gray-tbl800)] dark:border-[var(--color-gray-tbl700)] dark:text-[var(--color-gray-tbl400)] dark:hover:bg-[var(--color-gray-tbl700)] dark:hover:text-[var(--color-gray-tbl0)]">
                    <span className="sr-only">Previous</span>
                    <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd"
                            d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                            clipRule="evenodd"></path>
                    </svg>
                    </a>
                </li>
                <li>
                    <a href="/"
                    className="flex items-center justify-center px-3 py-2 text-sm leading-tight text-[var(--color-gray-tbl500)] bg-[var(--color-gray-tbl0)] border border-[var(--color-gray-tbl300)] hover:bg-[var(--color-gray-tbl100)] hover:text-[var(--color-gray-tbl700)] dark:bg-[var(--color-gray-tbl800)] dark:border-[var(--color-gray-tbl700)] dark:text-[var(--color-gray-tbl400)] dark:hover:bg-[var(--color-gray-tbl700)] dark:hover:text-[var(--color-gray-tbl0)]">1</a>
                </li>
                <li>
                    <a href="/"
                    className="flex items-center justify-center px-3 py-2 text-sm leading-tight text-[var(--color-gray-tbl500)] bg-[var(--color-gray-tbl0)] border border-[var(--color-gray-tbl300)] hover:bg-[var(--color-gray-tbl100)] hover:text-[var(--color-gray-tbl700)] dark:bg-[var(--color-gray-tbl800)] dark:border-[var(--color-gray-tbl700)] dark:text-[var(--color-gray-tbl400)] dark:hover:bg-[var(--color-gray-tbl700)] dark:hover:text-[var(--color-gray-tbl0)]">2</a>
                </li>
                <li>
                    <a href="/" aria-current="page"
                    className="z-10 flex items-center justify-center px-3 py-2 text-sm leading-tight border text-primary-600 bg-primary-50 border-primary-300 hover:bg-primary-100 hover:text-primary-700 dark:border-[var(--color-gray-tbl700)] dark:bg-[var(--color-gray-tbl700)] dark:text-[var(--color-gray-tbl0)]">3</a>
                </li>
                <li>
                    <a href="/"
                    className="flex items-center justify-center px-3 py-2 text-sm leading-tight text-[var(--color-gray-tbl500)] bg-[var(--color-gray-tbl0)] border border-[var(--color-gray-tbl300)] hover:bg-[var(--color-gray-tbl100)] hover:text-[var(--color-gray-tbl700)] dark:bg-[var(--color-gray-tbl800)] dark:border-[var(--color-gray-tbl700)] dark:text-[var(--color-gray-tbl400)] dark:hover:bg-[var(--color-gray-tbl700)] dark:hover:text-[var(--color-gray-tbl0)]">...</a>
                </li>
                <li>
                    <a href="/"
                    className="flex items-center justify-center px-3 py-2 text-sm leading-tight text-[var(--color-gray-tbl500)] bg-[var(--color-gray-tbl0)] border border-[var(--color-gray-tbl300)] hover:bg-[var(--color-gray-tbl100)] hover:text-[var(--color-gray-tbl700)] dark:bg-[var(--color-gray-tbl800)] dark:border-[var(--color-gray-tbl700)] dark:text-[var(--color-gray-tbl400)] dark:hover:bg-[var(--color-gray-tbl700)] dark:hover:text-[var(--color-gray-tbl0)]">100</a>
                </li>
                <li>
                    <a href="/"
                    className="flex items-center justify-center h-full py-1.5 px-3 leading-tight text-[var(--color-gray-tbl500)] bg-[var(--color-gray-tbl0)] rounded-r-lg border border-[var(--color-gray-tbl300)] hover:bg-[var(--color-gray-tbl100)] hover:text-[var(--color-gray-tbl700)] dark:bg-[var(--color-gray-tbl800)] dark:border-[var(--color-gray-tbl700)] dark:text-[var(--color-gray-tbl400)] dark:hover:bg-[var(--color-gray-tbl700)] dark:hover:text-[var(--color-gray-tbl0)]">
                    <span className="sr-only">Next</span>
                    <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd"
                            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                            clipRule="evenodd"></path>
                    </svg>
                    </a>
                </li>
                </ul>
            </nav>
            </div>
        </div>
    {/* </section> */}
    </div>


    </div>
  )
}

export default List