import React from 'react'

const Newsletter = () => {
  return (
        <div className='w-full py-16  dark:bg-[var(--color-dark-bakcgroundSecond)] bg-[var(--color-light-backgroundSecond)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)]  px-4'>
            <div className='max-w-[1240px] mx-auto grid lg:grid-cols-3'>
            <div className='lg:col-span-2 my-4'>
                <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2'>
                Want tips & tricks to optimize your flow?
                </h1>
                <p>Sign up to our newsletter and stay up to date.</p>
            </div>
            <div className='my-4'>
                <div className='flex flex-col sm:flex-row items-center justify-between w-full'>
                <input
                    className='dark:bg-[var(--color-dark-fontButton)] bgs-[var(--color-light-fontButton)] p-3 flex w-full rounded-md text-black'
                    type='email'
                    placeholder='Enter Email'
                />
                <button className='dark:bg-[var(--color-dark-buttonBackground)]  bg-[var(--color-light-buttonBackground)] dark:text-[var(--color-dark-fontButton)] text-[var(--color-light-fontButton)] rounded-md font-medium w-[200px] ml-4 my-6 px-6 py-3'>
                    Notify Me
                </button>
                </div>
                <p>
                We care bout the protection of your data. Read our{' '}
                <a href='/policy' className='text-[#0969DA]'>Privacy Policy.</a>
                </p>
            </div>
            </div>
        </div>  
  )
}

export default Newsletter