import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const Analytics = () => {
  return (
    <div className="w-full  dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] py-16 px-4">
      <div className="max-w-[1240px] mx-auto grid md:grid-cols-2">
        <img
          className="shadow-xl  w-[500px] mx-auto my-4"
          src="/assets/design/iucea.jpg"
          alt="/"
        />
        <div className="flex flex-col justify-center">
          <p className="dark:text-[var(--color-dark-font)] text-[var(--color-light-font)]  font-bold ">
              IUCEA 
          </p>
          <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2">
            Mission
          </h1>
          <p>
            The quality assurance approach to the quality of programmes
            incorporates three core assurance functions; defining quality,
            measuring quality, and improving quality in what is described as a
            quality triangle. The focus of the norms is on measuring quality.
            Measuring quality consists of quantifying the current level of
            performance or compliance with expected standards. This process
            requires identifying indicators of performance, collecting data, and
            analysing information. Measuring quality is inextricably linked with
            defining quality because the indicators for measuring quality are
            related to the specific definition or standard of quality under
            study. When standard define quality, measuring quality requires
            assessing the level of compliance with standards. Hence, measuring
            quality is easier with a clearly defined standards because
            indicators are directly derived from the expressions of the
            standards.
          </p>
          <Link to={"/login"}>
          <button className=" dark:bg-[var(--color-dark-buttonBackground)] bg-[var(--color-light-buttonBackground)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] w-[200px] rounded-md font-medium my-6 mx-auto md:mx-0 py-3">
            Get Started
          </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
