import axios from "axios";

class SiteVisiteService {
  async getAll(access, url) {
    axios.defaults.headers.common["Authorization"] = "JWT " + access;
    // axios.defaults.headers.common["X-Frame-Options"] = "sameorigin";
    axios.defaults.headers.common["Content-type"] = "multipart/form-data";
    const result = await axios.get(`${url}`);
    return result;
  }

  async getExpertReview(access, url) {
    axios.defaults.headers.common["Authorization"] = "JWT " + access;
    // axios.defaults.headers.common["X-Frame-Options"] = "sameorigin";
    axios.defaults.headers.common["Content-type"] = "multipart/form-data";
    const result = await axios.get(`${url}`);
    return result;
  }

  create(data, access, title = "A", email = "B",id_siteVisitAffectationExpert="C") {
    axios.defaults.headers.common["Authorization"] = "JWT " + access;
    axios.defaults.headers.common["Content-type"] = "multipart/form-data";

    console.log("data: ");
    console.log(data);

    return axios.post(
      `${process.env.REACT_APP_API_URL}/api/siteVisite/create/${title}/${email}/${id_siteVisitAffectationExpert}`,
      data
    );
  }

  editSiteVisite(id, data, access, titleProgramm, userEmail = null) {
    axios.defaults.headers.common["Authorization"] = "JWT " + access;
    axios.defaults.headers.common["Content-type"] = "multipart/form-data";

    console.log("data: ");
    console.log(data);
    console.log("Url: ");
    console.log(`${process.env.REACT_APP_API_URL}/api/siteVisite/${id}/${userEmail}/${titleProgramm}/edit`);

    return axios.put(
      `${process.env.REACT_APP_API_URL}/api/siteVisite/${id}/${userEmail}/${titleProgramm}/edit`,
      data
    );
  }

  deleteApplication(id, access) {
    axios.defaults.headers.common["Authorization"] = "JWT " + access;
    axios.defaults.headers.common["Content-type"] = "multipart/form-data";

    return axios.put(
      `${process.env.REACT_APP_API_URL}/api/application/${id}/delete/`
    );
  }


  addExpertOnSiteVisite(data, access, email ,nameUser,universityName, initialTitle_program_application) {
    axios.defaults.headers.common["Authorization"] = "JWT " + access;
    axios.defaults.headers.common["Content-type"] = "multipart/form-data";
      
    return axios.post(
      `${process.env.REACT_APP_API_URL}/api/applicationAffectationSiteVisit/create/${email}/${nameUser}/${universityName}/${initialTitle_program_application}`,
      data
    );
  }

  submitReportSiteVisite(id, data, access, emailSender, name, title, idApplication){ 
  axios.defaults.headers.common["Authorization"] = "JWT " + access;
  axios.defaults.headers.common["Content-type"] = "multipart/form-data";
    
  return axios.put(
    `${process.env.REACT_APP_API_URL}/api/applicationSiteVisiteAffectationExpertReport/${id}/${emailSender}/${name}/${title}/${idApplication}`,
    data
  );
}

}

export default new SiteVisiteService();
