import axios from "axios"

class AccreditationService {

    async getAll(access, url) {
        axios.defaults.headers.common["Authorization"] = "JWT " + access;
        // axios.defaults.headers.common["X-Frame-Options"] = "sameorigin";
        axios.defaults.headers.common["Content-type"] = "multipart/form-data";
        axios.defaults.headers.common["Accept-Encoding"] = "gzip, deflate, br";
        const result = await axios.get(`${url}`);
        return result;
      }
       
    
      createAccreditation(data, access, userEmail, title, applicantName) {
        axios.defaults.headers.common["Authorization"] = "JWT " + access;
        axios.defaults.headers.common["Content-type"] = "multipart/form-data";
        axios.defaults.headers.common["Accept-Encoding"] = "gzip, deflate, br";
      // console.log("userEmail SEr",userEmail);
        return axios.post(
          `${process.env.REACT_APP_API_URL}/api/accreditation/create/${userEmail}/${title}/${applicantName}`,
          data
        );
      }
    
      updateStatus(id, access){ 
        axios.defaults.headers.common["Authorization"] = "JWT " + access;
        axios.defaults.headers.common["Content-type"] = "multipart/form-data";
        axios.defaults.headers.common["Accept-Encoding"] = "gzip, deflate, br";
     
        return axios.patch(
          `${process.env.REACT_APP_API_URL}/api/accreditation/${id}/edit` 
        );
      }
     
      editFirstAccreditation(id, data, access, userEmail = null) {
        axios.defaults.headers.common["Authorization"] = "JWT " + access;
        axios.defaults.headers.common["Content-type"] = "multipart/form-data";
        axios.defaults.headers.common["Accept-Encoding"] = "gzip, deflate, br";
    
        console.log("data: ");
        console.log(id);
        if (userEmail) {
          console.log("email: ");
        console.log(userEmail);
          return axios.put(
            `${process.env.REACT_APP_API_URL}/api/accreditation/${id}/${userEmail}/editEmail`,
            data
          );
        } else {
          return axios.put(
            `${process.env.REACT_APP_API_URL}/api/accreditation/${id}`,
            data
          );
        }
      }
    
      deleteAccreditation(id, access) {
        axios.defaults.headers.common["Authorization"] = "JWT " + access;
        axios.defaults.headers.common["Content-type"] = "multipart/form-data";
        axios.defaults.headers.common["Accept-Encoding"] = "gzip, deflate, br";
    
        return axios.put(
          `${process.env.REACT_APP_API_URL}/api/accreditation/${id}/delete/`
        );
      }

      getTheNewBatchNumberAccreditation(access) {
        axios.defaults.headers.common["Authorization"] = "JWT " + access;
        axios.defaults.headers.common["Content-type"] = "multipart/form-data";
        
        return axios.get(
          `${process.env.REACT_APP_API_URL}/api/accreditationBatchnumber/`
        );
      }
}

export default new AccreditationService()