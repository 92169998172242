import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";

const LandingPage = () => {
  return (
    <div className="dark:bg-[var(--color-dark-bakcgroundSecond)] bg-[var(--color-light-backgroundSecond)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] ">
      <div className="max-w-[800px] mt-[-96px] w-full h-screen mx-auto text-center flex flex-col justify-center">
        <p className="dark:text-[var(--color-dark-font)] uppercase text-[var(--color-light-font)]   p-2 md:txt-5xl sm:text-4xl text-2xl font-bold md:py-4">
          Inter University Council of East Africa
        </p>
        <h1 className="md:txt-7xl sm:text-6xl text-4xl capitalize font-bold md:py-6">
           Regional Programme Accreditation System
        </h1>
        <div className="flex justify-center items-center ">
          <p className="md:text-5xl sm:text-4xl text-xl font-bold py-4"> </p>
          {/* <Typed 
                // className='md:text-5xl sm:text-4xl text-xl font-bold pl-2'
                className='md:text-5xl sm:text-4xl text-xl font-bold'
                strings={['Appliquer pour votre Accreditation en utilisant cette platform', 
                         'Apply for accreditation using this platform', 
                         'Omba accreditation kwakutumiya huyu mtandao']} 
                   typeSpeed={360} 
                   backSpeed={375} /> */}
        </div>
        <div>
          <p className="md:text-2xl text-xl font-bold dark:text-[var(--color-dark-fontSpecial)] text-[var(--color-light-fontSpecial)]">
            IUCEA developed a comprehensive Standards, Guidelines, Processes &
            Procedures for EAC Regional Programme Accreditation which provides
            for the standards and guidelines for each area of focus.
          </p>
          <Link to={"/login"}>
            <button className="dark:text-[var(--color-dark-fontButton)] text-[var(--color-light-fontButton)] dark:bg-[var(--color-dark-buttonBackground)] bg-[var(--color-light-buttonBackground)] w-[200px] rounded-md font-medium my-6 mx-auto py-3 ">
              Apply now
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
