import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import ApplicationService from "../../../services/ApplicationService";
import { connect } from "react-redux";
import { login } from "../../actions/auth";
import { CHEASchemasValidation } from "../../alerts/Validations";
import { useFormik } from "formik";
import Success from "../../alerts/Success";
import Error from "../../alerts/Error";
import UserService from "../../../services/UserService";
import Loading from "../../eventsHandler/Loading";
import TabCHEA from "../../smallComponents/TabCHEA";
import { preliminaryAssessment } from "../ConstantsAssessementsReportCHEA";
import TabContentCHEA from "../../smallComponents/TabContentCHEA";
import Popup from "../../alerts/Popup";
import CHEAService from "../../../services/CHEAService";
import AccreditationService from "../../../services/AccreditationService";

const CHEAAdd = (props) => {
  let editing = false;
  let initialTitle_program_application = "";
  let initialDesignation_application = "";
  let initialUniversity_id_application = "";
  let initialLevel_id_application = "";
  let initialOtherInfos = "";
  let initialevidence_for_accreditation_at_national_level = "";
  let initialconfirmation_letter_national_agency = "";
  let initialinvolvment_of_the_relevant_national_bodies = "";
  let initialcurriculum_development = "";
  let initialpostgraduate_supervision_guidelines = "";
  let initialquality_assurence_policy = "";
  let initialcredit_transfer_policy = "";
  let initialsummary_of_staff_capacity = "";
  let initialinventory_of_facilities = "";
  let initialannual_survey_report = "";
  let initialtracer_study_report = "";
  let initialexamination_policy = "";
  let initialadmission_policy = "";
  let initialprogram_review_report = "";
  let initialother_not_specified = "";
  let initialApplicationID = "";
  let typeOperation = "";
  let initialstatus_application = "";
  let initialstatus_thematicArea_application = "";
  let initialuser_created_application = "";

  if (props.location.state && props.location.state.application) {
    editing = true;
    typeOperation = props.location.state.application.typeOperation;
    initialstatus_application =
      props.location.state.application.status_application;
    initialApplicationID = props.location.state.application.id_application;
    initialevidence_for_accreditation_at_national_level =
      props.location.state.application
        .evidence_for_accreditation_at_national_level;
    initialconfirmation_letter_national_agency =
      props.location.state.application.confirmation_letter_national_agency;
    initialinvolvment_of_the_relevant_national_bodies =
      props.location.state.application
        .involvment_of_the_relevant_national_bodies;
    initialcurriculum_development =
      props.location.state.application.curriculum_development;
    initialpostgraduate_supervision_guidelines =
      props.location.state.application.postgraduate_supervision_guidelines;
    initialquality_assurence_policy =
      props.location.state.application.quality_assurence_policy;
    initialcredit_transfer_policy =
      props.location.state.application.credit_transfer_policy;
    initialsummary_of_staff_capacity =
      props.location.state.application.summary_of_staff_capacity;
    initialinventory_of_facilities =
      props.location.state.application.inventory_of_facilities;
    initialannual_survey_report =
      props.location.state.application.annual_survey_report;
    initialtracer_study_report =
      props.location.state.application.tracer_study_report;
    initialexamination_policy =
      props.location.state.application.examination_policy;
    initialadmission_policy = props.location.state.application.admission_policy;
    initialprogram_review_report =
      props.location.state.application.program_review_report;
    initialother_not_specified =
      props.location.state.application.other_not_specified;
    initialTitle_program_application =
      props.location.state.application.title_program_application;
    initialDesignation_application =
      props.location.state.application.designation_application;
    initialUniversity_id_application =
      props.location.state.application.university_id_application;
    initialLevel_id_application =
      props.location.state.application.level_id_application;

    initialOtherInfos = props.location.state.application.otherInfor_application;
    initialuser_created_application =
      props.location.state.application.user_created_application;
    initialstatus_thematicArea_application =
      props.location.state.application.thematicArea_application;
  }

  console.log(props.location.state.application);
  let userApplicationEmail =
    props.location.state.application.userApplicationEmail;
  let userApplicationID = props.location.state.application.applicantID;
  let userApplicationUserName =
    props.location.state.application.userApplicationUsername;
  let titleProgram = props.location.state.application.title_program_application;

  const [titleName, setTitleName] = useState(initialTitle_program_application);
  const [level, setLevel] = useState(initialLevel_id_application);
  const [designation, setDesignation] = useState(
    initialDesignation_application
  );
  const [disableSaveBtn, setDisableSaveBtn] = useState(false);
  const [otherInfo, setOtherInfo] = useState(initialOtherInfos);
  const [colorFont, setColorFont] = useState("");
  const [confirmationText, setConfirmationText] = useState("");

  let levelData = JSON.parse(localStorage.getItem("levelData"));

  const history = useHistory();
  const [userInfo, setUserInfo] = useState("");
  const [expertId, setExpertId] = useState([]);
  const [dataOfAddedExpert, setDataOfAddedExpert] = useState([]);
  const [expertApplication, setExpertApplication] = useState("");
  const [thematic, setThematic] = useState([]);
  const [statusCheckboxSelect, setStatusCheckboxSelect] = useState(false);

  const [showPopup, setShowPopup] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [clickedButton, setclickedButton] = useState("");
  const [expertList, setExpertList] = useState([]);

  if (!(history.location.state == null)) {
    const data = history.location.state.data;
    console.log("Data send: ");
    console.log(data);
  }

  useEffect(() => {
    retrieveApplicationsExepert(initialApplicationID);
  }, [initialApplicationID]);

  const retrieveApplicationsExepert = (idApplication) => {
    setDataOfAddedExpert([]);
    dataOfAddedExpert.length = 0;
    var access = localStorage.getItem("access");
    const linkToData = `${process.env.REACT_APP_API_URL}/api/applicationExpert/${idApplication}/application`;

    ApplicationService.getAllExpert(access, linkToData)
      .then((response) => {
        localStorage.setItem(
          "ExpertApplication",
          JSON.stringify(response.data)
        );
        setExpertApplication(response.data);
      })
      .catch((e) => {
        console.log("error : ", e);
        if (e.response.status === 401) {
          // Redirect to the login page
          history.push('/login');
        }    
      });
  };
  console.log("Expert here: ", expertApplication);
  console.log("expertApplication.length  ", expertApplication.length);

  if (expertApplication.length > 0 && expertApplication != null) {
    expertApplication.forEach((expert) => {
      dataOfAddedExpert.push(Number(expert.id_expert_applicationExpert));
    });
  }

  console.log("dataOfAddedExpert", dataOfAddedExpert);

  let siteVisitInfo =
    props.location.state.application.resultSiteVisiteDone.siteVisiteInfo;

  let numberSubmission = siteVisitInfo.length;

  let contentGot = [];
  let newDictionnarySiteVisite = {};
  let siteVisiteID = [];

  siteVisitInfo.map((data) => {
    // console.log(data.id_siteVisite);
    siteVisiteID.push(data.id_siteVisite);
    let dataSiteVisite = JSON.parse(data.dataRecolted_siteVisite);
    Object.keys(dataSiteVisite).forEach((key) => {
      if (newDictionnarySiteVisite.hasOwnProperty(key)) {
        let newValue = Number(dataSiteVisite[key]);
        if (!isNaN(newValue)) {
          newDictionnarySiteVisite[key] += newValue;
        }
      } else {
        // if it is a new value
        let newValue = Number(dataSiteVisite[key]);
        if (!isNaN(newValue)) {
          newDictionnarySiteVisite[key] = newValue;
        }
      }
    });
  });

  const onSubmit = (values, { resetForm, setSubmitting }) => {
    setDisableSaveBtn(true);
    let userInfo = localStorage.getItem("userInfo");
    let userJson = JSON.parse(userInfo);
    const newCheaDecision = new FormData();

    newCheaDecision.append("constants_CHEADecision", values.theOverAllFeedBack);
    newCheaDecision.append(
      "id_siteViste_CHEADecision",
      JSON.stringify(siteVisiteID)
    );
    newCheaDecision.append("id_applicant_CHEADecision", userApplicationID);

    if (clickedButton === "accreditation") {
      newCheaDecision.append("status_CHEADecision", "1");
      CHEAService.createCHEADecision(
        newCheaDecision,
        localStorage.getItem("access"),
        userApplicationEmail,
        titleProgram,
        userApplicationUserName,
        initialApplicationID
      )
        .then((response) => {
          const newAccreditation = new FormData();
          newAccreditation.append(
            "id_CHEADecision_accreditation",
            response.data.id_CHEADecision
          );
          newAccreditation.append("status_accreditation", "1");
          newAccreditation.append(
            "constant_accreditation",
            values.theOverAllFeedBack
          );
          newAccreditation.append(
            "id_applicant_accreditation",
            userApplicationID
          );
          newAccreditation.append(
            "id_application_accreditation",
            initialApplicationID
          );

          AccreditationService.createAccreditation(
            newAccreditation,
            localStorage.getItem("access"),
            userApplicationEmail,
            titleProgram,
            userApplicationUserName
          )
            .then(() => {
              console.log("Access: ");
              let data = {
                status: 1,
                title: "Okay",
                message: "Programme accreditated With success Thank you",
              };
              history.push("/cheaReport", { data: data });
            })
            .catch((err) => {
              // show erro
              console.log("Error Accreditation in CHEA Add ", err);
            })
            .finally(() => {
              setDisableSaveBtn(false);
            });
        })
        .catch((error) => {
          console.log("Error : ");
        });
    } else if (clickedButton === "rejection") {
      newCheaDecision.append("status_CHEADecision", "0");
      CHEAService.createCHEADecision(
        newCheaDecision,
        localStorage.getItem("access"),
        userApplicationEmail,
        titleProgram,
        userApplicationUserName,
        initialApplicationID
      )
        .then((response) => {
          console.log("Access: ");
          let data = {
            status: 1,
            title: "Okay",
            message: "Programme rejection done Thank you",
          };
          history.push("/cheaReport", { data: data });
        })
        .catch((error) => {
          console.log("Error : ");
        })
        .finally(() => {
          setDisableSaveBtn(false);
        });
    }
  };

  const handleConfirm = (e) => {
    console.log("event ", e);
    e.preventDefault();
    handleSubmit();
    setShowPopup(false);
  };

  const handleCancel = () => {
    setShowPopup(false);
  };

  const stopEventForm = (event) => {
    event.preventDefault();
  };

  const changeStatus = (status) => {
    setStatusCheckboxSelect(status.target.value);
  };

  let expertListId = JSON.parse(localStorage.getItem("ExpertApplication"));

  const {
    values,
    errors,
    touched,
    isSubmitting,
    setFieldValue,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      designation_application: "",
      title_program_application: initialTitle_program_application,
      university_id_application: initialUniversity_id_application,

      evidence_for_accreditation_at_national_level: "",
      confirmation_letter_national_agency: "",
      involvment_of_the_relevant_national_bodies: "",
      action: "",
      inputValue: "",

      otherInfor_application: initialOtherInfos,
      level_id_application: initialLevel_id_application,

      otherInfor_application: initialOtherInfos,
      level_id_application: initialLevel_id_application,
      theOverAllFeedBack: "",
    },
    validationSchema: CHEASchemasValidation,
    onSubmit,
  });

  console.log("errors ", errors);

  let allUniveData = JSON.parse(localStorage.getItem("universityData"));
  let universityName = "";
  allUniveData.map((dataUniv) => {
    if (dataUniv.id === Number(initialUniversity_id_application)) {
      universityName = dataUniv.university_name;
    }
  });

  let levelGot = "";
  levelData.map((levelDB) => {
    if (levelDB.id_level === level) {
      levelGot = levelDB.designation_level;
    }
  });

  useEffect(() => {
    let url = `${process.env.REACT_APP_API_URL}/api/user/list/expert`;
    UserService.getExpert(url)
      .then((response) => {
        console.log("response");
        console.log(response.data);
        setExpertList(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 401) {
          // Redirect to the login page
          history.push('/login');
        }    
      });
  }, []);

  let listSelectedExpert = [];

  if (typeOperation !== "statusEdit") {
    if (expertList == null) {
      expertList.map((expert) => {
        listSelectedExpert.push(Number(expert.id_expert_applicationExpert));
      });
    }
  }

  function isInArray(value, array) {
    return array.indexOf(value) > -1;
  }

  let numberOfExpert = expertList.length;
  let hiddenExpert = 0;

  useEffect(() => {
    // getThematic
    let url = `${process.env.REACT_APP_API_URL}/api/thematicArea/`;
    UserService.getThematic(url)
      .then((response) => {
        console.log("response");
        console.log(response.data);
        setThematic(response.data);
      })
      .catch((errors) => {
        console.log("errors");
        console.log(errors);
        if (errors.response.status === 401) {
          // Redirect to the login page
          history.push('/login');
        }    
      });
  }, []);

  const tabs = [
    {
      title: "Documents",
      content: (
        <div className="flex flex-wrap pt-4">
          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label
              className="block capitalize text-md tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
              htmlFor="grid-city"
            >
              Evidence for accreditation at national level
              <span className="text-red-600">*</span>
            </label>
            {initialevidence_for_accreditation_at_national_level ? (
              <a
                rel="noreferrer"
                href={`${process.env.REACT_APP_API_URL}/documents/${initialevidence_for_accreditation_at_national_level}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>
              </a>
            ) : (
              ""
            )}
          </div>
          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label
              className="block text-md capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
              htmlFor="grid-city"
            >
              Confirmation letter from the national agency
              <span className="text-red-600">*</span>
            </label>
            {initialconfirmation_letter_national_agency ? (
              <a
                rel="noreferrer"
                href={`${process.env.REACT_APP_API_URL}/documents/${initialconfirmation_letter_national_agency}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>
              </a>
            ) : (
              ""
            )}
          </div>
          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md">
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
              htmlFor="grid-city"
            >
              Involvment of the relevant national bodies
              <span className="text-red-600">*</span>
            </label>
            {initialinvolvment_of_the_relevant_national_bodies ? (
              <a
                rel="noreferrer"
                href={`${process.env.REACT_APP_API_URL}/documents/${initialinvolvment_of_the_relevant_national_bodies}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>
              </a>
            ) : (
              ""
            )}
          </div>

          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md pt-3">
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
              htmlFor="grid-city"
            >
              Curriculum development<span className="text-red-600">*</span>
            </label>
            {initialcurriculum_development ? (
              <a
                rel="noreferrer"
                href={`${process.env.REACT_APP_API_URL}/documents/${initialcurriculum_development}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>
              </a>
            ) : (
              ""
            )}
          </div>
          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md pt-3 ">
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
              htmlFor="grid-city"
            >
              Postgraduate supervision guidelines
              <span className="text-red-600">*</span>
            </label>
            {initialpostgraduate_supervision_guidelines ? (
              <a
                rel="noreferrer"
                href={`${process.env.REACT_APP_API_URL}/documents/${initialpostgraduate_supervision_guidelines}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>
              </a>
            ) : (
              ""
            )}
          </div>
          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md pt-3">
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
              htmlFor="grid-city"
            >
              Quality Assurance policy<span className="text-red-600">*</span>
            </label>
            {initialquality_assurence_policy ? (
              <a
                rel="noreferrer"
                href={`${process.env.REACT_APP_API_URL}/documents/${initialquality_assurence_policy}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>
              </a>
            ) : (
              ""
            )}
          </div>

          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md pt-3 ">
            <label
              className="block capitalize text-md tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
              htmlFor="grid-city"
            >
              Credit transfer policy<span className="text-gray-600">*</span>
            </label>
            {initialcredit_transfer_policy ? (
              <a
                rel="noreferrer"
                href={`${process.env.REACT_APP_API_URL}/documents/${initialcredit_transfer_policy}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>
              </a>
            ) : (
              ""
            )}
          </div>
          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md pt-3 ">
            <label
              className="block  capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
              htmlFor="grid-city"
            >
              Summary of staff capacity<span className="text-red-600">*</span>
            </label>
            {initialsummary_of_staff_capacity ? (
              <a
                rel="noreferrer"
                href={`${process.env.REACT_APP_API_URL}/documents/${initialsummary_of_staff_capacity}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>
              </a>
            ) : (
              ""
            )}
          </div>
          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md pt-3 ">
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
              htmlFor="grid-city"
            >
              Inventory of facilities<span className="text-red-600">*</span>
            </label>
            {initialinventory_of_facilities ? (
              <a
                rel="noreferrer"
                href={`${process.env.REACT_APP_API_URL}/documents/${initialinventory_of_facilities}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>
              </a>
            ) : (
              ""
            )}
          </div>

          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md pt-3 ">
            <label
              className="block capitalize text-md tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
              htmlFor="grid-city"
            >
              Annual survey report<span className="text-red-600">*</span>
            </label>
            {initialannual_survey_report ? (
              <a
                rel="noreferrer"
                href={`${process.env.REACT_APP_API_URL}/documents/${initialannual_survey_report}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>
              </a>
            ) : (
              ""
            )}
          </div>
          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md pt-3 ">
            <label
              className="block text-md capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
              htmlFor="grid-city"
            >
              Tracer study report<span className="text-gray-600">*</span>
            </label>
            {initialtracer_study_report ? (
              <a
                rel="noreferrer"
                href={`${process.env.REACT_APP_API_URL}/documents/${initialtracer_study_report}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>
              </a>
            ) : (
              ""
            )}
          </div>
          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md pt-3 ">
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
              htmlFor="grid-city"
            >
              Examination policy<span className="text-red-600">*</span>
            </label>
            {initialexamination_policy ? (
              <a
                rel="noreferrer"
                href={`${process.env.REACT_APP_API_URL}/documents/${initialexamination_policy}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>
              </a>
            ) : (
              ""
            )}
          </div>

          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md pt-3">
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
              htmlFor="grid-city"
            >
              Admission Policy<span className="text-red-600">*</span>
            </label>
            {initialadmission_policy ? (
              <a
                rel="noreferrer"
                href={`${process.env.REACT_APP_API_URL}/documents/${initialadmission_policy}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>
              </a>
            ) : (
              ""
            )}
          </div>
          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md pt-3 ">
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
              htmlFor="grid-city"
            >
              Program review report<span className="text-red-600">*</span>
            </label>
            {initialprogram_review_report ? (
              <a
                rel="noreferrer"
                href={`${process.env.REACT_APP_API_URL}/documents/${initialprogram_review_report}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>
              </a>
            ) : (
              ""
            )}
          </div>
          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md pt-3 ">
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
              htmlFor="grid-city"
            >
              Other not specified<span className="text-gray-600">*</span>
            </label>
            {initialother_not_specified ? (
              <a
                rel="noreferrer"
                href={`${process.env.REACT_APP_API_URL}/documents/${initialother_not_specified}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>
              </a>
            ) : (
              ""
            )}
          </div>
        </div>
      ),
    }, // end documents content
    {
      title: preliminaryAssessment(newDictionnarySiteVisite, numberSubmission)
        .point1[0].bigHeader,
      content: (
        <TabContentCHEA
          content={
            preliminaryAssessment(newDictionnarySiteVisite, numberSubmission)
              .point1[0]
          }
          numberSubmission={numberSubmission}
        />
      ),
    },
    {
      title: preliminaryAssessment(newDictionnarySiteVisite, numberSubmission)
        .point1[1].bigHeader,
      content: (
        <TabContentCHEA
          content={
            preliminaryAssessment(newDictionnarySiteVisite, numberSubmission)
              .point1[1]
          }
          numberSubmission={numberSubmission}
        />
      ),
    },
  ];

  return (
    <div>
      <div className="w-full p-8">
        {disableSaveBtn && <Loading />}

        {history.location.state === null ||
        history.location.state === undefined ? (
          ""
        ) : (
          <>
            {!(history.location.state.data == null) ? (
              <>
                {history.location.state.data.status === 1 ? (
                  <Success
                    delay="5000"
                    valueFromParent={history.location.state.data}
                  />
                ) : (
                  <Error
                    delay="7000"
                    valueFromParent={history.location.state.data}
                  />
                )}
              </>
            ) : (
              <></>
            )}
          </>
        )}
        <form onSubmit={(event) => stopEventForm(event)} autoComplete="off">
          <div className="grid grid-cols-3">
            <div className="mb-4 text-md grid grid-cols-1">
              <div className="w-1/2 px-3 mb-6 md:mb-0">
                <label
                  className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-2"
                  htmlFor="grid-first-name"
                >
                  Title of the Programme :
                </label>
                <label className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2">
                  {values.title_program_application}
                </label>
              </div>
              <div className="w-1/2 px-3 mb-6 md:mb-0 text-md ">
                <label
                  className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-2"
                  htmlFor="grid-first-name"
                >
                  Level :
                </label>
                <div className="relative">
                  <label
                    className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2"
                    htmlFor="grid-first-name"
                  >
                    {levelGot}
                  </label>
                </div>
              </div>
            </div>
            <div className="mb-4 text-md grid grid-cols-1">
              <div className="w-full px-3 mb-4 text-md ">
                <label
                  className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-2"
                  htmlFor="grid-last-name"
                >
                  Other Informations :
                </label>
                <label
                  className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2"
                  htmlFor="grid-last-name"
                >
                  {values.otherInfor_application}
                </label>
              </div>

              <div className="w-full text-md px-3 mb-4">
                <label
                  className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-2"
                  htmlFor="grid-password"
                >
                  University :
                </label>
                <label
                  className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2"
                  htmlFor="grid-password"
                >
                  {universityName}
                </label>
              </div>
            </div>
            <div className="w-full px-3 mb-4 text-md ">
              <label
                className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2"
                htmlFor="grid-last-name"
              >
                List Experts :
              </label>

              {
                expertList.map((expert) => {
                  let status = false;
                  let showOrHideExpert = "";
                  if (isInArray(Number(expert.id), dataOfAddedExpert)) {
                    status = true;
                  } else {
                    status = false;
                  }
                  let expertQualification = expert.thematicArea;

                  if (
                    isInArray(
                      initialstatus_thematicArea_application,
                      expertQualification.split(",")
                    )
                  ) {
                    showOrHideExpert = "";
                  } else {
                    showOrHideExpert = "hidden";
                    hiddenExpert = hiddenExpert + 1;
                  }

                  if (hiddenExpert === numberOfExpert) {
                    return (
                      <span className="text-red-500">
                        {" "}
                        No expert found for that application in: "{" "}
                        {thematic.map((area) =>
                          initialstatus_thematicArea_application ===
                          area.id_thematicsArea
                            ? area.designation_thematicsArea
                            : ""
                        )}
                        "
                      </span>
                    );
                  } else {
                    return (
                      <div
                        className={`flex items-center mb-2 ${showOrHideExpert}`}
                      >
                        <label
                          for="default-checkbox"
                          className="ml-2 
                                                                 text-md 
                                                                 font-medium 
                                                                 dark:text-[var(--color-dark-font)] 
                                                                 text-[var(--color-light-font)]"
                        >
                          {expert.name}{" "}
                          {status ? (
                            <span className="text-green-600"> - Submitted</span>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                    );
                  }
                })
                //   })
              }
            </div>
          </div>
          <div className="mb-3 border border-gray-300">
            <div className="md:mb-0 text-md lg:mb-2">
              <label
                className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2"
                htmlFor="grid-last-name"
              >
                Comment on the decision <span className="text-red-600">*</span>
              </label>

              <textarea
                className="appearance-none block w-full dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] border border-gray-200 rounded py-3 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="theOverAllFeedBack"
                type="text"
                placeholder="Feedback on the application"
                name="theOverAllFeedBack"
                value={values.theOverAllFeedBack}
                onChange={handleChange}
                onBlur={handleBlur}
                rows={4}
              ></textarea>
              <p className="text-red-500 text-md italic">
                {errors.theOverAllFeedBack}
              </p>
            </div>

            <div className="mt-2 justify-center text-center mb-2">
              <div className="mb-[0.125rem] mr-4 inline-block min-h-[1.5rem] pl-[1.5rem]">
                <label
                  className="mt-px inline-block pl-[0.15rem] hover:cursor-pointer"
                  htmlFor="inlineRadio2"
                >
                  <span
                    className=" bg-green-100 
                          text-green-800 
                          border 
                          border-green-400
                          text-md 
                          font-medium 
                          cursor-pointer
                          mr-2 px-2.5 
                          rounded-2xl
                          py-0.5  
                          dark:bg-green-900 
                          dark:text-green-300  uppercase"
                  >
                    Green for Button Accreditation
                  </span>
                </label>
              </div>
              <div className="mb-[0.125rem] mr-4 inline-block min-h-[1.5rem] pl-[1.5rem]">
                <label
                  className="mt-px inline-block pl-[0.15rem] hover:cursor-pointer"
                  htmlFor="inlineRadio1"
                >
                  <span
                    className=" bg-red-100 
                          text-red-800 
                          border 
                          border-red-400
                          text-md 
                          font-medium 
                          cursor-pointer
                          mr-2 px-2.5 
                          rounded-2xl
                          py-0.5  
                          dark:bg-red-900 
                          dark:text-red-300  uppercase"
                  >
                    Red for Button Rejection
                  </span>
                </label>
              </div>
            </div>
          </div>

          <TabCHEA tabs={tabs} />

          <div className="text-center p-5 justify-between">
            <button
              name="action"
              type="submit"
              value={"accreditation"}
              className="dark:bg-green-800
              bg-green-500 
                        dark:text-[var(--color-dark-fontButton)] 
                        text-[var(--color-light-fontButton)] 
                        mr-2 
                        w-[120px]  py-3 rounded-md font-medium"
              onClick={() => {
                setFormValues(values); // Store form values
                setShowPopup(true);
                setclickedButton("accreditation");
                setColorFont("green");
                setConfirmationText(
                  "Are you sure you want to Accredit this programme?"
                );
              }}
            >
              Accreditation
            </button>
            <button
              name="action"
              type="submit"
              value={"rejection"}
              className="dark:bg-red-800
                        bg-red-500 
                        dark:text-[var(--color-dark-fontButton)] 
                        text-[var(--color-light-fontButton)]  
                        w-[120px]  py-3 rounded-md font-medium"
              onClick={() => {
                setFormValues(values); // Store form values
                setShowPopup(true);
                setColorFont("red");
                setclickedButton("rejection");
                setConfirmationText(
                  "Are you sure you want to Reject this programme?"
                );
              }}
            >
              Rejection
            </button>
          </div>
        </form>
        {showPopup && (
          <Popup
            message={confirmationText}
            onConfirm={(e) => handleConfirm(e)}
            onCancel={handleCancel}
            colorFont={colorFont}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { login })(CHEAAdd);
