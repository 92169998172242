import React, { useState } from "react";

const InputNumberForScoreForEdit = (props) => {

  const [value, setValue] = useState(props.valueGot);
 

  const handleChange = (event) => {
    const newValue = event.target.value;
   
    if (!isNaN(newValue)) { 
      const numericValue = Number(newValue);
 
      if (numericValue <= props.maximum) { 
        setValue(newValue); 
        console.log('Min')
        props.onChangeNumber(event.target.name,newValue); // Pass the event object to the parent component's function
      } else { 
        setValue(props.maximum.toString()); 
        console.log('Max')
        props.onChangeNumber(event.target.name,props.maximum.toString());// Pass the event object to the parent component's function
      }
    } else {
      // If the new value is not a valid number, set the value to an empty string
      setValue(Number(props.valueGot)); 
      props.onChangeNumber(event.target.name,Number(props.valueGot)); // Pass the event object to the parent component's function
    }
  };
   
  const handleKeyDown = (event) => {
    // Allow only valid number inputs and the backspace key
    if (
      !(
        event.key === "Backspace" ||
        /^[0-9]+$/.test(event.key) // Check if the pressed key is a valid number
      )
    ) {
      event.preventDefault();
    }
  };
  return (
    <div className="">
      <div className="mb-[0.125rem] mr-4 inline-block min-h-[1.5rem] pl-[1.5rem]">
        <input
          type="number"
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          value={value}
          max={props.maximum}
          min={props.minimum}
          name={props.name}
          placeholder={`Marks :${props.maximum}`}
          id={props.name}
          style={{
            width: "150px", // Set the width as per your preference
            height: "30px", // Set the height as per your preference
            padding: "5px", // Set the padding as per your preference
            border: "1px solid #ccc", // Add a border to make the input visible
            borderRadius: "4px", // Add some border radius for a rounded look
          }}
        />
        <br />
        <label  style={{
            width: "150px", 
            padding: "5px",  
          }} className="text-gray-700 font-light text-xs">{props.placeholder}</label>
      </div>
    </div>
  );
};

export default InputNumberForScoreForEdit;
