import axios from "axios";

class ApplicationFirstFeedback {
  async getAll(access, url) {
    axios.defaults.headers.common["Authorization"] = "JWT " + access;
    // axios.defaults.headers.common["X-Frame-Options"] = "sameorigin";
    axios.defaults.headers.common["Content-type"] = "multipart/form-data";
    axios.defaults.headers.common["Accept-Encoding"] = "gzip, deflate, br";
    const result = await axios.get(`${url}`);
    return result;
  }

  async getAllExpert(access, url) {
    axios.defaults.headers.common["Authorization"] = "JWT " + access;
    // axios.defaults.headers.common["X-Frame-Options"] = "sameorigin";
    axios.defaults.headers.common["Content-type"] = "multipart/form-data";
    axios.defaults.headers.common["Accept-Encoding"] = "gzip, deflate, br";
    const result = await axios.get(`${url}`);
    return result;
  }

  async getApplicantEmail(access, url) {
    axios.defaults.headers.common["Authorization"] = "JWT " + access;
    // axios.defaults.headers.common["X-Frame-Options"] = "sameorigin";
    axios.defaults.headers.common["Content-type"] = "multipart/form-data";
    axios.defaults.headers.common["Accept-Encoding"] = "gzip, deflate, br"; 
    const result = await axios.get(`${url}`);
    return result;
  }

  async getOneFirstFeedback(access, url) {
    axios.defaults.headers.common["Authorization"] = "JWT " + access;
    // axios.defaults.headers.common["X-Frame-Options"] = "sameorigin";
    axios.defaults.headers.common["Content-type"] = "multipart/form-data";
    axios.defaults.headers.common["Accept-Encoding"] = "gzip, deflate, br"; 
    const result = await axios.get(`${url}`);
    return result;
  }


  createFirstFeedback(data, access, userEmail, title, username) {
    axios.defaults.headers.common["Authorization"] = "JWT " + access;
    axios.defaults.headers.common["Content-type"] = "multipart/form-data";
    axios.defaults.headers.common["Accept-Encoding"] = "gzip, deflate, br";
  // console.log("userEmail SEr",userEmail);
    return axios.post(
      `${process.env.REACT_APP_API_URL}/api/firstFeedback/create/${userEmail}/${title}/${username}`,
      data
    );
  }

  updateStatus(id, access){ 
    axios.defaults.headers.common["Authorization"] = "JWT " + access;
    axios.defaults.headers.common["Content-type"] = "multipart/form-data";
    axios.defaults.headers.common["Accept-Encoding"] = "gzip, deflate, br";
 
    return axios.patch(
      `${process.env.REACT_APP_API_URL}/api/firstFeedback/${id}/edit` 
    );
  }

  addExpertOnFirstFeedback(data, access, email,  name, universityName, initialTitle_program_application) {
      axios.defaults.headers.common["Authorization"] = "JWT " + access;
      axios.defaults.headers.common["Content-type"] = "multipart/form-data"; 
      
      return axios.post(
        `${process.env.REACT_APP_API_URL}/api/applicationExpert/create/${email}/${name}/${universityName}/${initialTitle_program_application}`,
        data
      );
    }

  editFirstFeedback(id, data, access, userEmail = null) {
    axios.defaults.headers.common["Authorization"] = "JWT " + access;
    axios.defaults.headers.common["Content-type"] = "multipart/form-data";
    axios.defaults.headers.common["Accept-Encoding"] = "gzip, deflate, br";

    console.log("data: ");
    console.log(id);
    if (userEmail) {
      console.log("email: ");
    console.log(userEmail);
      return axios.put(
        `${process.env.REACT_APP_API_URL}/api/firstFeedback/${id}/${userEmail}/editEmail`,
        data
      );
    } else {
      return axios.put(
        `${process.env.REACT_APP_API_URL}/api/firstFeedback/${id}`,
        data
      );
    }
  }

  deleteFirstFeedback(id, access) {
    axios.defaults.headers.common["Authorization"] = "JWT " + access;
    axios.defaults.headers.common["Content-type"] = "multipart/form-data";
    axios.defaults.headers.common["Accept-Encoding"] = "gzip, deflate, br";

    return axios.put(
      `${process.env.REACT_APP_API_URL}/api/firstFeedback/${id}/delete/`
    );
  }
}

export default new ApplicationFirstFeedback();
