import axios from "axios";

class ExpertReviewService {
  async getAll(access, url) {
    axios.defaults.headers.common["Authorization"] = "JWT " + access;
    // axios.defaults.headers.common["X-Frame-Options"] = "sameorigin";
    axios.defaults.headers.common["Content-type"] = "multipart/form-data";
    axios.defaults.headers.common["Accept-Encoding"] = "gzip, deflate, br";
    const result = await axios.get(`${url}`);
    return result;
  }

  async getAllExpert(access, url) {
    axios.defaults.headers.common["Authorization"] = "JWT " + access;
    // axios.defaults.headers.common["X-Frame-Options"] = "sameorigin";
    axios.defaults.headers.common["Content-type"] = "multipart/form-data";
    axios.defaults.headers.common["Accept-Encoding"] = "gzip, deflate, br";
    const result = await axios.get(`${url}`);
    return result;
  }

  async getApplicantEmail(access, url) {
    axios.defaults.headers.common["Authorization"] = "JWT " + access;
    // axios.defaults.headers.common["X-Frame-Options"] = "sameorigin";
    axios.defaults.headers.common["Content-type"] = "multipart/form-data";
    axios.defaults.headers.common["Accept-Encoding"] = "gzip, deflate, br";
    const result = await axios.get(`${url}`);
    return result;
  }

  async getOneExpertReview(access, url) {
    axios.defaults.headers.common["Authorization"] = "JWT " + access;
    // axios.defaults.headers.common["X-Frame-Options"] = "sameorigin";
    axios.defaults.headers.common["Content-type"] = "multipart/form-data";
    axios.defaults.headers.common["Accept-Encoding"] = "gzip, deflate, br";
    const result = await axios.get(`${url}`);
    return result;
  }

  async getAllStatusSiteVisite(access, url) {
    axios.defaults.headers.common["Authorization"] = "JWT " + access;
    // axios.defaults.headers.common["X-Frame-Options"] = "sameorigin";
    axios.defaults.headers.common["Content-type"] = "multipart/form-data";
    axios.defaults.headers.common["Accept-Encoding"] = "gzip, deflate, br";

    const result = await axios.get(`${url}`);
    return result;
  }

  createExpertReview(data, access, title, userID) {
    axios.defaults.headers.common["Authorization"] = "JWT " + access;
    axios.defaults.headers.common["Content-type"] = "multipart/form-data";
    axios.defaults.headers.common["Accept-Encoding"] = "gzip, deflate, br";

    return axios.post(
      `${process.env.REACT_APP_API_URL}/api/expertReview/create/${title}/${userID}/`,
      data
    );
  }
 

  addExpertOnExpertReview(
    data,
    access,
    email,
    initialTitle_program_application
  ) {
    axios.defaults.headers.common["Authorization"] = "JWT " + access;
    axios.defaults.headers.common["Content-type"] = "multipart/form-data";
    axios.defaults.headers.common["Accept-Encoding"] = "gzip, deflate, br";

    console.log("data: ");
    console.log(data);

    return axios.post(
      `${process.env.REACT_APP_API_URL}/api/applicationExpert/create/${email}/${initialTitle_program_application}`,
      data
    );
  }

  editExpertReview(id, data, access, userEmail = null) {
    axios.defaults.headers.common["Authorization"] = "JWT " + access;
    axios.defaults.headers.common["Content-type"] = "multipart/form-data";
    axios.defaults.headers.common["Accept-Encoding"] = "gzip, deflate, br";

    console.log("data: ");
    console.log(id);
    if (userEmail) {
      console.log("email: ");
      console.log(userEmail);
      return axios.put(
        `${process.env.REACT_APP_API_URL}/api/expertReview/${id}/${userEmail}/editEmail`,
        data
      );
    } else {
      return axios.put(
        `${process.env.REACT_APP_API_URL}/api/expertReview/${id}`,
        data
      );
    }
  }

  deleteExpertReview(id, access) {
    axios.defaults.headers.common["Authorization"] = "JWT " + access;
    axios.defaults.headers.common["Content-type"] = "multipart/form-data";
    axios.defaults.headers.common["Accept-Encoding"] = "gzip, deflate, br";

    return axios.put(
      `${process.env.REACT_APP_API_URL}/api/expertReview/${id}/delete/`
    );
  }
}

export default new ExpertReviewService();
