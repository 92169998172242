import React, { useState } from "react";

const InputNumberForScoreReviewAssessors = (props) => {
  let newNumber = props.valueGot || 0;
 
  return (
    <div className="">
      <div className="mb-[0.125rem] mr-4 inline-block min-h-[1.5rem] pl-[1.5rem]">
        

        <label
          className="mt-px inline-block pl-[0.15rem] hover:cursor-pointer"
          for="inlineRadio2"
        >
          {" "}
          Submission({props.numberSubmission}): 
        </label>
        <label
          className="mt-px inline-block pl-[0.15rem] hover:cursor-pointer"
          for="inlineRadio2"
        >
          {" "}
         Score: {Number(newNumber) < Number(props.numberSubmission*props.maximum)/2 ? <span className="text-red-600 font-bold text-lg">{newNumber}/{props.numberSubmission*props.maximum}</span> : <span className="text-green-600 font-bold text-lg">{newNumber}/{props.numberSubmission*props.maximum}</span>} 
        </label>
      </div>
    </div>
  );
};

export default InputNumberForScoreReviewAssessors;
