import React from "react";
import {
  RadialBarChart,
  RadialBar,
  Legend,
  Tooltip,
  Cell,
  Label,
} from "recharts";

const getStatusColor = (status) => {
  if (status === "InProgress") {
    return "#FFC107"; // Yellow
  } else if (status === "Completed") {
    return "#4CAF50"; // Green
  } else if (status === "Pending") {
    return "#FF5722"; // Red
  }
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`${label} : ${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};

const DonutChartComponent = (props) => {
  const dataWithColors = props.data.map(item => ({
    ...item,
    fill: getStatusColor(item.status),
  }));

  return (
    <RadialBarChart width={400} height={300} data={dataWithColors} innerRadius="40%" outerRadius="80%" barSize={10}>
    <RadialBar dataKey="value" cornerRadius={10} fill="#8884d8" background={{ fill: "#eee" }}>
      {dataWithColors.map((entry, index) => (
        <Cell key={`cell-${index}`} fill={entry.fill} />
      ))}
      <Label content={({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * (Math.PI / 180));
        const y = cy + radius * Math.sin(-midAngle * (Math.PI / 180));
        return (
          <text x={x} y={y} fill="#8884d8" textAnchor="middle" dominantBaseline="central">
            {dataWithColors[index].status}
          </text>
        );
      }} />
    </RadialBar>
    <Legend />
    <Tooltip content={<CustomTooltip />} />
  </RadialBarChart>
  );
};

export default DonutChartComponent;
