import axios from "axios";

class AppealExpertReviewService {
        
    editChEADecision(data, access, userEmail, title, applicantName, idApplication,id) {
        axios.defaults.headers.common["Authorization"] = "JWT " + access;
        axios.defaults.headers.common["Content-type"] = "multipart/form-data";
        axios.defaults.headers.common["Accept-Encoding"] = "gzip, deflate, br";
      // console.log("userEmail SEr",userEmail);
        return axios.put(
          `${process.env.REACT_APP_API_URL}/api/CHEADecision/edit/${id}/${userEmail}/${title}/${applicantName}/${idApplication}`,
          data
        );
      }

      updateStatus(id, access){ 
        axios.defaults.headers.common["Authorization"] = "JWT " + access;
        axios.defaults.headers.common["Content-type"] = "multipart/form-data";
        axios.defaults.headers.common["Accept-Encoding"] = "gzip, deflate, br";
     
        return axios.patch(
          `${process.env.REACT_APP_API_URL}/api/accreditation/${id}/edit` 
        );
      }
     
    
}

export default new AppealExpertReviewService();