import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import ApplicationService from "../../../services/ApplicationService";
import { connect } from "react-redux";
import { login } from "../../actions/auth";
import { CHEASchemasValidation } from "../../alerts/Validations";
import { useFormik } from "formik";
import Success from "../../alerts/Success";
import Error from "../../alerts/Error";
import UserService from "../../../services/UserService";
import Loading from "../../eventsHandler/Loading";
import CHEAService from "../../../services/CHEAService";
import AccreditationService from "../../../services/AccreditationService";
import "./PrintcertificateCss.css";
import moment from "moment";
import cachet1 from "../../../images/cachet1.png";
import iucea from "../../../images/iucea.jpg";
import eac from "../../../images/eac.png";
import QRCode from "qrcode.react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import printStyles from "./PrintStyles.css";
import QRCodeImage from "./QRCodeImage";

const AccreditationView = (props) => {
  let editing = false;
  let initialTitle_program_application = "";
  let initialDesignation_application = "";
  let initialUniversity_id_application = "";
  let initialLevel_id_application = "";
  let initialOtherInfos = "";
  let initialevidence_for_accreditation_at_national_level = "";
  let initialconfirmation_letter_national_agency = "";
  let initialinvolvment_of_the_relevant_national_bodies = "";
  let initialcurriculum_development = "";
  let initialpostgraduate_supervision_guidelines = "";
  let initialquality_assurence_policy = "";
  let initialcredit_transfer_policy = "";
  let initialsummary_of_staff_capacity = "";
  let initialinventory_of_facilities = "";
  let initialannual_survey_report = "";
  let initialtracer_study_report = "";
  let initialexamination_policy = "";
  let initialadmission_policy = "";
  let initialprogram_review_report = "";
  let initialother_not_specified = "";
  let initialApplicationID = "";
  let signatories = [];

  let initialstatus_application = "";
  let initialstatus_thematicArea_application = "";
  let initialuser_created_application = "";
  let applicationData = {};
  let batchnumber = "";

  let typeOperation = props.location.state.typeOperation;
  let date_created_accreditation =
    props.location.state.currentApplication.date_created_accreditation;
  let constant_accreditation =
    props.location.state.currentApplication.constant_accreditation;

  applicationData = props.location.state.currentApplication.resultsApplication;
  console.log(props.location.state.currentApplication);

  if (applicationData) {
    editing = true;
    typeOperation = props.location.state.typeOperation;
    initialstatus_application = applicationData.status_application;
    initialApplicationID = applicationData.id_application;
    initialevidence_for_accreditation_at_national_level =
      applicationData.evidence_for_accreditation_at_national_level;
    batchnumber = applicationData.batchNumber_Application;
    initialconfirmation_letter_national_agency =
      applicationData.confirmation_letter_national_agency;
    initialinvolvment_of_the_relevant_national_bodies =
      applicationData.involvment_of_the_relevant_national_bodies;
    initialcurriculum_development = applicationData.curriculum_development;
    initialpostgraduate_supervision_guidelines =
      applicationData.postgraduate_supervision_guidelines;
    initialquality_assurence_policy = applicationData.quality_assurence_policy;
    initialcredit_transfer_policy = applicationData.credit_transfer_policy;
    initialsummary_of_staff_capacity =
      applicationData.summary_of_staff_capacity;
    initialinventory_of_facilities = applicationData.inventory_of_facilities;
    initialannual_survey_report = applicationData.annual_survey_report;
    initialtracer_study_report = applicationData.tracer_study_report;
    initialexamination_policy = applicationData.examination_policy;
    initialadmission_policy = applicationData.admission_policy;
    initialprogram_review_report = applicationData.program_review_report;
    initialother_not_specified = applicationData.other_not_specified;
    initialTitle_program_application =
      applicationData.title_program_application;
    initialDesignation_application = applicationData.designation_application;
    initialUniversity_id_application =
      applicationData.university_id_application;
    initialLevel_id_application = applicationData.level_id_application;

    initialOtherInfos = applicationData.otherInfor_application;
    initialuser_created_application = applicationData.user_created_application;
    initialstatus_thematicArea_application =
      applicationData.thematicArea_application;
    signatories = props.location.state.currentApplication.signatories;
  }

  console.log(props.location.state.currentApplication);
  let idAffectation = props.location.state.notifID;
  // id_CHEADecisionAffectationCHEADecision
  let userApplicationEmail = applicationData.userApplicationEmail;
  let userApplicationID = applicationData.applicantID;
  let userApplicationUserName = applicationData.userApplicationUsername;
  let titleProgram = applicationData.title_program_application;

  const [titleName, setTitleName] = useState(initialTitle_program_application);
  const [level, setLevel] = useState(initialLevel_id_application);
  const [designation, setDesignation] = useState(
    initialDesignation_application
  );
  const [disableSaveBtn, setDisableSaveBtn] = useState(false);
  const [otherInfo, setOtherInfo] = useState(initialOtherInfos);
  const [colorFont, setColorFont] = useState("");
  const [confirmationText, setConfirmationText] = useState("");

  let levelData = JSON.parse(localStorage.getItem("levelData"));

  const history = useHistory();
  const [userInfo, setUserInfo] = useState("");
  const [expertId, setExpertId] = useState([]);
  const [dataOfAddedExpert, setDataOfAddedExpert] = useState([]);
  const [expertApplication, setExpertApplication] = useState("");
  const [thematic, setThematic] = useState([]);
  const [statusCheckboxSelect, setStatusCheckboxSelect] = useState(false);

  const [showPopup, setShowPopup] = useState(false);
  const [expertList, setExpertList] = useState([]);

  if (!(history.location.state == null)) {
    const data = history.location.state.data;
    // console.log("Data send: ");
    // console.log(data);
  }
  const certificateRef = useRef(null);

  useEffect(() => {
    retrieveApplicationsExepert(initialApplicationID);
  }, [initialApplicationID]);

  const retrieveApplicationsExepert = (idApplication) => {
    setDataOfAddedExpert([]);
    dataOfAddedExpert.length = 0;
    var access = localStorage.getItem("access");
    const linkToData = `${process.env.REACT_APP_API_URL}/api/applicationSiteVisiteAffectationExpert/${idApplication}/applicationAffectation`;

    ApplicationService.getAllExpert(access, linkToData)
      .then((response) => {
        localStorage.setItem(
          "ExpertApplication: ",
          JSON.stringify(response.data)
        );
        setExpertApplication(response.data);
      })
      .catch((e) => {
        console.log("error : ", e);
        if (e.response.status === 401) {
          // Redirect to the login page
          history.push("/login");
        }
      });
  };

  if (expertApplication.length > 0 && expertApplication != null) {
    expertApplication.forEach((expert) => {
      dataOfAddedExpert.push(
        Number(expert.id_expertSiteVisitAffectationExpert)
      );
    });
  }

  let siteVisitInfo = [];
  if (
    applicationData.resultSiteVisite.siteVisiteInfo.reportSiteVisite.length > 0
  ) {
    siteVisitInfo =
      applicationData.resultSiteVisite.siteVisiteInfo.reportSiteVisite;
  } else if (expertApplication.length > 0) {
    siteVisitInfo = expertApplication;
  }

  let numberSubmission = siteVisitInfo.length;  
  let newDictionnarySiteVisite = {};
  let siteVisiteIDReport = [];
  let totalGotSiteVisite = 0;
  let totalRemoveSiteVisite = 0;

  siteVisitInfo.map((data) => {
    siteVisiteIDReport.push(data.id_siteVisitAffectationExpert);
    let dataSiteVisite = JSON.parse(data.dataColectedSiteVisite);
    // console.log(dataSiteVisite);
    Object.keys(dataSiteVisite).forEach((key) => {
      if (newDictionnarySiteVisite.hasOwnProperty(key)) {
        if (key.endsWith("NA")) {
          // for the one to be removed
          let newValue = Number(dataSiteVisite[key]);
          if (!isNaN(newValue)) {
            newDictionnarySiteVisite[key] += newValue;
            totalRemoveSiteVisite = totalRemoveSiteVisite + newValue;
          } else {
            newDictionnarySiteVisite[key] = dataSiteVisite[key];
          }
        } else {
          // normal one
          let newValue = Number(dataSiteVisite[key]);
          if (!isNaN(newValue)) {
            newDictionnarySiteVisite[key] += newValue;
            totalGotSiteVisite = totalGotSiteVisite + newValue;
          } else {
            newDictionnarySiteVisite[key] = dataSiteVisite[key];
          }
        }
      } else {
        if (key.endsWith("NA")) {
          // total to be removed
          let newValue = Number(dataSiteVisite[key]);
          if (!isNaN(newValue)) {
            newDictionnarySiteVisite[key] = newValue;
            totalRemoveSiteVisite = totalRemoveSiteVisite + newValue; 
          }  
        } else {
          // normal one
          let newValue = Number(dataSiteVisite[key]);
          if (!isNaN(newValue)) {
            newDictionnarySiteVisite[key] = newValue;
            totalGotSiteVisite = totalGotSiteVisite + newValue;
          } else {
            newDictionnarySiteVisite[key] = dataSiteVisite[key];
          }
        }
      }
    });
  });

  let scoreSiteVisite = (
    (totalGotSiteVisite / (numberSubmission * (805-(totalRemoveSiteVisite)))) *
    100
  ).toFixed(2); 
  // console.log("scoreSiteVisite ", scoreSiteVisite);
  let expertReviewData = [];
  let numberSubmissionExpertReview = 0;
  if (typeOperation === "sendChEAD") {
    expertReviewData = applicationData.expertReviewData;
    numberSubmissionExpertReview = expertReviewData.length;
  } else {
    expertReviewData = applicationData.expertReviewData;
    numberSubmissionExpertReview = expertReviewData.length;
  }

  let newDictionnaryExpertReview = {};
  let expertReviewIdAlreadyDone = [];
  let numberGotTotalExpertReview = 0;

  if (expertReviewData) {
    expertReviewData.map((data) => {
      // console.log(data.id_siteVisite);
      expertReviewIdAlreadyDone.push(data.id_expertReview);
      let dataExpertReviewScore = JSON.parse(data.scoreFromExpert);
      Object.keys(dataExpertReviewScore).forEach((key) => {
        if (newDictionnaryExpertReview.hasOwnProperty(key)) {
          let newValue = Number(dataExpertReviewScore[key]);
          if (!isNaN(newValue)) {
            newDictionnaryExpertReview[key] += newValue;
            numberGotTotalExpertReview = numberGotTotalExpertReview + newValue;
          } else {
            newDictionnaryExpertReview[key] +=
              "\n *" + dataExpertReviewScore[key];
          }
        } else {
          // if it is a new value
          let newValue = Number(dataExpertReviewScore[key]);
          if (!isNaN(newValue)) {
            newDictionnaryExpertReview[key] = newValue;
            numberGotTotalExpertReview = numberGotTotalExpertReview + newValue;
          } else {
            newDictionnaryExpertReview[key] = "*" + dataExpertReviewScore[key];
          }
        }
      });
    });
  }

  let scoreFirstReview = (
    (numberGotTotalExpertReview / (numberSubmissionExpertReview * 145)) *
    100
  ).toFixed(2);

  let allUniveData = JSON.parse(localStorage.getItem("universityData"));
  let universityName = "";
  allUniveData.map((dataUniv) => {
    if (dataUniv.id === Number(initialUniversity_id_application)) {
      universityName = dataUniv.university_name;
    }
  });
  let siteVisiteID = 0;
  let applicantIDOnFromApplication = 0;
  if (typeOperation === "sendChEAD") {
    siteVisiteID =
      applicationData.resultSiteVisite.siteVisiteInfo.id_siteVisite;
    applicantIDOnFromApplication = initialuser_created_application;
  } else {
    siteVisiteID =
      applicationData.resultSiteVisite.siteVisiteInfo.id_siteVisite;
    applicantIDOnFromApplication = initialuser_created_application;
  }

  const onSubmit = (values, { resetForm, setSubmitting }) => {
    // check if there is a selection of acceptance or Deferred
    if (Number(statusCheckboxSelect) <= 1) {
      alert("Please provide Accept or Deferred this report");
      return;
    }
    setDisableSaveBtn(true);
    let userInfo = localStorage.getItem("userInfo");
    let userJson = JSON.parse(userInfo);
    const newCheaDecision = new FormData();

    if (typeOperation === "sendChEAD") {
      // when it is a new chea desion
      newCheaDecision.append(
        "constants_CHEADecision ",
        values.theOverAllFeedBack
      );
      newCheaDecision.append("id_siteViste_CHEADecision ", siteVisiteID);
      newCheaDecision.append("id_applicant_CHEADecision", userApplicationID);
      newCheaDecision.append(
        "status_applicationCHEADecison",
        statusCheckboxSelect
      );
      newCheaDecision.append(
        "id_application_CHEADecision",
        initialApplicationID
      );
      CHEAService.submitReportCHEACreate(
        newCheaDecision,
        localStorage.getItem("access"),
        userApplicationEmail,
        titleProgram,
        userApplicationUserName,
        initialApplicationID,
        universityName
      )
        .then((response) => {
          console.log("Success CHEA report sent: ");
          let data = {
            status: 1,
            title: "Okay",
            message: "You have submit your report thank you!",
          };
          history.push("/dashboard", { data: data });
        })
        .catch((error) => {
          console.log("Error : ");
          let data = {
            status: 0,
            title: "Error",
            message: "Error made ...",
          };
          history.push("/dashboard", { data: data });
        });
    } else {
      // when it is a report submitted
      newCheaDecision.append(
        "constant_CHEADecisionAffectationExpert ",
        values.theOverAllFeedBack
      );
      newCheaDecision.append(
        "status_applicationCHEADecison",
        statusCheckboxSelect
      );
      CHEAService.submitReportCHEA(
        idAffectation,
        newCheaDecision,
        localStorage.getItem("access"),
        userApplicationEmail,
        titleProgram,
        userApplicationUserName,
        universityName
      )
        .then((response) => {
          console.log("Success CHEA report sent: ");
          let data = {
            status: 1,
            title: "Okay",
            message: "You have submit your report thank you!",
          };
          history.push("/dashboard", { data: data });
        })
        .catch((error) => {
          console.log("Error : ");
          let data = {
            status: 0,
            title: "Error",
            message: "Error made ...",
          };
          history.push("/dashboard", { data: data });
        });
    }
  };

  const handleConfirm = (e) => {
    // console.log("event ", e);
    e.preventDefault();
    handleSubmit();
    setShowPopup(false);
  };

  const handleCancel = () => {
    setShowPopup(false);
  };

  const stopEventForm = (event) => {
    event.preventDefault();
  };

  const changeStatus = (status) => {
    setStatusCheckboxSelect(status.target.value);
  };

  const {
    values,
    errors,
    touched,
    isSubmitting,
    setFieldValue,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      designation_application: "",
      title_program_application: initialTitle_program_application,
      university_id_application: initialUniversity_id_application,

      evidence_for_accreditation_at_national_level: "",
      confirmation_letter_national_agency: "",
      involvment_of_the_relevant_national_bodies: "",
      action: "",
      inputValue: "",

      otherInfor_application: initialOtherInfos,
      level_id_application: initialLevel_id_application,

      otherInfor_application: initialOtherInfos,
      level_id_application: initialLevel_id_application,
      theOverAllFeedBack: "",
    },
    validationSchema: CHEASchemasValidation,
    onSubmit,
  });

  // console.log("errors ", errors);

  let levelGot = "";
  levelData.map((levelDB) => {
    if (levelDB.id_level === level) {
      levelGot = levelDB.designation_level;
    }
  });

  useEffect(() => {
    let url = `${process.env.REACT_APP_API_URL}/api/user/list/expert`;
    UserService.getExpert(url)
      .then((response) => {
        // console.log("response");
        // console.log(response.data);
        setExpertList(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 401) {
          // Redirect to the login page
          history.push("/login");
        }
      });
  }, []);

  let listSelectedExpert = [];

  if (typeOperation !== "statusEdit") {
    if (expertList == null) {
      expertList.map((expert) => {
        listSelectedExpert.push(Number(expert.id_expert_applicationExpert));
      });
    }
  }

  useEffect(() => {
    // getThematic
    let url = `${process.env.REACT_APP_API_URL}/api/thematicArea/`;
    UserService.getThematic(url)
      .then((response) => {
        // console.log("Thematic ");
        // console.log(response.data);
        setThematic(response.data);
      })
      .catch((errors) => {
        console.log("errors");
        console.log(errors);
        if (errors.response.status === 401) {
          // Redirect to the login page
          history.push("/login");
        }
      });
  }, []);

  console.log("printStyles", printStyles);

  const handlePrint = () => {
    const contentToPrint = certificateRef.current;
    if (contentToPrint) {
      const printWindow = window.open("", "_blank");
      printWindow.document.write(`
        <html>
          <head>
            <link rel="stylesheet" type="text/css"  href="${printStyles}">
          </head>
          <style>
          body {
            font-family: "Times New Roman", serif;
          }
          .qr-code {
            text-align: center;
            font-family: "Times New Roman", serif;
          }
          .certificate-details-container {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            margin-bottom: 10px;
            text-align: center; /* Center text within flex container */
            height: 100vh;
            font-family: "Times New Roman", serif;
          }
        
          .certificate-details-table {
            width: 80%;
            margin: 0 auto;
            text-align: left; 
            font-family: "Times New Roman", serif;
          }
        
          .certificate-details-table th,
          .certificate-details-table td {
            padding: 0px 0px;
            font-family: "Times New Roman", serif;
          }
        
          .certificate-details {
            display: flex;
            align-items: center;
            font-family: "Times New Roman", serif;
          }
        
          .certificate-details {
            font-size: 16px;
            margin-right: 10px;
            font-family: "Times New Roman", serif;
          }
        
          .print-certificate {
            width: 297mm;
            height: 210mm;
            padding: 20mm;
            margin: 0 auto;
            border: 1px solid #ccc;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
            font-family: "Times New Roman", serif;
          }
        
          .print-button {
            display: block;
            margin: 20px auto;
            padding: 10px 20px;
            background-color: #0969da;
            color: #fff;
            border: none;
            cursor: pointer;
          }
        
          .certificate-content {
            width: 100%; /* Adjust width as needed */
            height: 87%; /* Adjust height as needed */
            padding-bottom: 0mm; 
            margin: 0 auto;
            outline: 1px double #ccc; /* Add a double border effect using outline */
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
            text-align: center;
            position: relative;
            font-family: "Times New Roman", serif; 
          }
        
          .outer-border {
            border: 2px solid #000; /* Adjust border style as needed */
            padding: 4px; /* Adjust padding as needed */
          }
        
          .table-header {
            width: 100%;
            table-layout: fixed;
            text-align: center;
          }
                  
          .mini-title-header {
            text-align: left;
            padding-left: 65%;
            padding-top: 0px;
            position: absolute;
            top: 41px;
            left: 20px;
            font-family: "Times New Roman", serif;
            font-size: 10px;
            font-weight: bold;
          }
          .certificate-title {
            font-size: 50px;
            font-weight: bold;  
            font-family: "Times New Roman", serif;
          }

          .second-title {
            margin-top: 2%;
            font-size: 30px;
            font-weight: bold;
            font-family: "Times New Roman", serif;
            /* text-align: "center"; */
          }
        
          .title-programme {
            font-size: 30px;
            font-weight: bold;
            font-family: "Times New Roman", serif;
          }
        
          .specialization {
            font-size: 20px;
            font-weight: bold;
            font-family: "Times New Roman", serif;
          }
        
          .universityName {
            font-size: 20px;
            font-weight: bold;
            font-family: "Times New Roman", serif;
          }
        
          .small-title {
            font-size: 20px;
            font-family: "Times New Roman", serif;
          }
        
          .footer-signature {
            font-size: 17px;
            font-weight: bold;
            font-family: "Times New Roman", serif;
            margin-top: 10px;
            margin-bottom: 0px;
          }
          .certificate-text {
            font-size: 18px;
            margin-bottom: 15px;
            font-family: "Times New Roman", serif;
          }
        
          .certificate-details {
            font-size: 16px;
            margin-bottom: 10px;
            font-family: "Times New Roman", serif;
          }
        
          .signature-table {
            width: 100%;
            table-layout: fixed; /* Prevent cells from adjusting width based on content */
          }
        
          .signature-cell {
            padding: 0 10px; /* Adjust padding as needed */
            text-align: left; /* Push content to the left */
          }
        
          .signature-box {
            display: flex;
            flex-direction: column;
            align-items: center; /* Center align content */
            margin-bottom: 14px;
          }
        
          .signature-image-container {
            flex: 0 0 auto; /* Ensure the image container doesn't grow */
          }
        
          .signature-img {
            width: 110px; /* Adjust image size as needed */
            height: 110px;
          }
        
          .signature-content {
            flex: 1; /* Let the content take remaining space */
            text-align: left; /* Push content to the left */
        
            font-size: 17px;
            font-weight: bold;
            font-family: "Times New Roman", serif;
          }
        
          .small-content {
            font-size: 16px;
            text-align: center;
          }
        
          /* logo */
          .logo-table {
            width: 100%;
            table-layout: fixed; /* Prevent cells from adjusting width based on content */
          }
        
          .logo-cell {
            padding: 0 0px; /* Adjust padding as needed */
            text-align: left; /* Push content to the left */
          }
        
          .logo-box {
            display: flex;
            flex-direction: column;
            align-items: center; /* Center align content */
            margin-bottom: 0px;
          }
        
          .logo-image-container {
            flex: 0 0 auto; /* Ensure the image container doesn't grow */
          }
        
          .logo-img1 {
            width: 105px; /* Adjust image size as needed */
            height: 105px;
          }
        
          .logo-content {
            flex: 1; /* Let the content take remaining space */
            text-align: left; /* Push content to the left */
          }
          </style>
          <body>
            ${contentToPrint.outerHTML}
          </body>
        </html>
      `);
      printWindow.document.close();
      printWindow.print();
    }
  };

  // get url
  const location = useLocation();
  const idToConvert = props.location.state.currentApplication.id_accreditation; // Replace with the actual ID you want to convert

  const qrCodeLink = `${window.location.origin}/accreditationOpenView/${idToConvert}`;

  const originalDate = moment(date_created_accreditation);
  const newDate = originalDate.add(5, "years");
  return (
    <div>
      <div className="w-full pt-0">
        {disableSaveBtn && <Loading />}
        {/* <Link 
        to={"/accreditationOpenView/"+idToConvert} 
        >Test</Link> */}
        {history.location.state === null ||
        history.location.state === undefined ? (
          ""
        ) : (
          <>
            {!(history.location.state.data == null) ? (
              <>
                {history.location.state.data.status === 1 ? (
                  <Success
                    delay="5000"
                    valueFromParent={history.location.state.data}
                  />
                ) : (
                  <Error
                    delay="7000"
                    valueFromParent={history.location.state.data}
                  />
                )}
              </>
            ) : (
              <></>
            )}
          </>
        )}
        <div>
          <button className="print-button" onClick={() => handlePrint()}>
            Print Certificate
          </button>
          <div ref={certificateRef} className="outer-border">
            <div className="certificate-content">
              {/* <table className="logo-table">
                <tbody>
                 
                </tbody>
              </table> */}
              <table className="table-header">
                <thead>
                  <tr>
                    <td key={32}  style={{ textAlign: "left" }}>
                      <div className="">
                        <div className="">
                          <img src={eac} className="logo-img1" />
                        </div>
                      </div>
                    </td>{" "}
                    <td key={321} style={{ textAlign: "center" }}>
                      <div className="qr-code">
                        <QRCodeImage
                          id="qrCodeEl"
                          value={qrCodeLink}
                          size={100}
                          style={{ border: "1px solid red" }}
                        />
                      </div>
                    </td>
                    <td key={1221} style={{ textAlign: "right" }}>
                      <div className="">
                        <div className="">
                          <img src={iucea} className="logo-img1" />
                        </div>
                      </div>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colspan="3">
                      {" "}
                      <span className="certificate-title">
                        Inter University Council for East Africa
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3">
                      <p>
                        <u className="second-title">Certification</u>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3">
                      <p className="certificate-text">
                        Accreditation is awarded to the{" "}
                        {/* <span className="specialization">
                          With the specialization in 
                          &#8220;
                          {thematic.map((area) =>
                            initialstatus_thematicArea_application === area.id_thematicsArea
                              ? area.designation_thematicsArea
                              : ""
                          )}
                          &#8221;
                        </span> */}
                        {/* ’s degree in{" "} */}
                        <span className="title-programme">
                          {" "}
                          {titleProgram}
                        </span>{" "}
                        of <br />
                        <span className="universityName">
                          {universityName}
                        </span>{" "}
                        in compliance with
                        <br /> standards and Guidelines for, and requirements
                        for programme accreditation and in <br />
                        compliance with the requirements defined by the East
                        African Qualifications <br />
                        Framework for Higher Education (EAQFHE).
                        {/* The aconeditation is implemented according to the resolution <br />
                of the RPA's Commission of{" "}
                {moment(date_created_accreditation).format("Do MMMM YYYY")}
                . The acceditation is conditional and linated <br /> in time
                until {newDate.format("Do MMMM YYYY")} */}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className="certificate-details-table">
                <tbody>
                  <tr>
                    <td className="certificate-text" colSpan="2">
                      Accreditation status: FULL ACCREDITATION
                    </td>
                    <td className="certificate-text" colSpan="2">
                      Registration number: {batchnumber}
                    </td>
                  </tr>
                  <tr>
                    <td className="certificate-text" colSpan="2">
                      Validity status:{" "}
                      {moment(date_created_accreditation).format(
                        "Do MMMM YYYY"
                      )}{" "}
                      to {newDate.format("Do MMMM YYYY")}
                    </td>
                    <td className="certificate-text" colSpan="2">
                      Date of issue:{" "}
                      {moment(date_created_accreditation).format(
                        "Do MMMM YYYY"
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className="certificate-details-table">
                <tbody>
                  <tr>
                    <td>
                      <span className="footer-signature">
                        Uganda, Kampala <br />
                        {moment(date_created_accreditation).format(
                          "Do MMMM YYYY HH:mm"
                        )}
                      </span>
                    </td>
                    {signatories.map((dataSign, index) => (
                      <td key={index} className="signature-cell">
                        <div className="signature-box">
                          <div className="signature-image-container">
                            <img
                              src={`${process.env.REACT_APP_API_URL}/documents/${dataSign.signature_signatory}`}
                              alt={dataSign.academicTitle_signatory}
                              className="signature-img"
                            />
                          </div>
                          <div className="">
                            <p className="signature-content">
                              {dataSign.academicTitle_signatory}{" "}
                              {dataSign.fullName_signatory}
                            </p>
                            <p className="signature-content small-content">
                              {dataSign.title_signatory}
                            </p>
                          </div>
                        </div>
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { login })(AccreditationView);
