import axios from "axios";

class ChairSignatorieService {
  async getAll(access, url) {
    axios.defaults.headers.common["Authorization"] = "JWT " + access;
    // axios.defaults.headers.common["X-Frame-Options"] = "sameorigin";
    axios.defaults.headers.common["Content-type"] = "multipart/form-data";
    axios.defaults.headers.common["Accept-Encoding"] = "gzip, deflate, br";
    const result = await axios.get(`${url}`);
    return result;
  }

  createSignatorie(data, access) {
    axios.defaults.headers.common["Authorization"] = "JWT " + access;
    axios.defaults.headers.common["Content-type"] = "multipart/form-data";
    axios.defaults.headers.common["Accept-Encoding"] = "gzip, deflate, br";
    // console.log("userEmail SEr",userEmail);
    return axios.post(
      `${process.env.REACT_APP_API_URL}/api/chairSignatories/create`,
      data
    );
  }

  getSmallData(access) {
    axios.defaults.headers.common["Authorization"] = "JWT " + access;
    axios.defaults.headers.common["Content-type"] = "multipart/form-data";
    axios.defaults.headers.common["Accept-Encoding"] = "gzip, deflate, br";

    return axios.get(
      `${process.env.REACT_APP_API_URL}/api/getSmallComponent/list`
    );
  }

  updateSignatorie(id, data, access) {
    axios.defaults.headers.common["Authorization"] = "JWT " + access;
    axios.defaults.headers.common["Content-type"] = "multipart/form-data";
    axios.defaults.headers.common["Accept-Encoding"] = "gzip, deflate, br";

    return axios.put(
      `${process.env.REACT_APP_API_URL}/api/chairSignatories/edit/${id}`,
      data
    );
  }

  deleteSignatorie(id, access) {
    axios.defaults.headers.common["Authorization"] = "JWT " + access;
    axios.defaults.headers.common["Content-type"] = "multipart/form-data";
    axios.defaults.headers.common["Accept-Encoding"] = "gzip, deflate, br";

    return axios.put(
      `${process.env.REACT_APP_API_URL}/api/chairSignatories/delete/${id}`
    );
  }

  getDeferentsProfiles(access) {
    axios.defaults.headers.common["Authorization"] = "JWT " + access;
    axios.defaults.headers.common["Content-type"] = "multipart/form-data";
    axios.defaults.headers.common["Accept-Encoding"] = "gzip, deflate, br";

    return axios.get(
      `${process.env.REACT_APP_API_URL}/api/chairSignatories/getLastDiffrentProfileID`
    );
  }
}
export default new ChairSignatorieService();
