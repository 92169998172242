import React, { useState, useCallback, useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { login } from "../../actions/auth";
import { useFormik } from "formik";
import Success from "../../alerts/Success";
import Error from "../../alerts/Error";
import UserService from "../../../services/UserService";
import Tab from "../../smallComponents/Tab";
import TabContent from "../../smallComponents/TabContent";
import { preliminaryAssessment } from "../ConstantsAssessementsForEdit";
import SiteVisiteService from "../../../services/SiteVisiteService";
import Loading from "../../eventsHandler/Loading";
import { siteVisiteInformationDataInputPreview } from "../ConstantsAssessementsForExpertSiteVisiteInformationsPreview";
import { siteVisiteInformationDataEditInputPreview } from "../ConstantsAssessementsForExpertSiteVisiteInformationsPreviewEdit";
import TabContentSiteVisit from "../../smallComponents/TabContentSiteVisit";

const SiteVisiteEdit = (props) => {
  let editing = false;
  let initialTitle_program_application = "";
  let initialDesignation_application = "";
  let initialUniversity_id_application = "";
  let initialLevel_id_application = "";
  let initialOtherInfos = "";
  let initialevidence_for_accreditation_at_national_level = "";
  let initialconfirmation_letter_national_agency = "";
  let initialinvolvment_of_the_relevant_national_bodies = "";
  let initialcurriculum_development = "";
  let initialpostgraduate_supervision_guidelines = "";
  let initialquality_assurence_policy = "";
  let initialcredit_transfer_policy = "";
  let initialsummary_of_staff_capacity = "";
  let initialinventory_of_facilities = "";
  let initialannual_survey_report = "";
  let initialtracer_study_report = "";
  let initialexamination_policy = "";
  let initialadmission_policy = "";
  let initialprogram_review_report = "";
  let initialother_not_specified = "";
  let initialotherConstantsSiteVisite = "";
  let initialIDSiteViste = "";
  let typeOperation = "";
  let initialstatus_application = "";
  let initialstatus_thematicArea_application = "";
  let initialuser_created_application = "";
  let id_provenance = "";
  let application = [];
  let userSubmitterInfor = [];
  let initialDataSendObject = {};
  let idApplication = '';

  let dataCollected = props.location.data.dataCollected;
  let id_AfectationsiteVisitAffectationExpert =
    props.location.data.id_siteVisitAffectationExpert;

  if (props.location.data && props.location.data.currentApplication) {
    editing = true;
    typeOperation = props.location.data.currentApplication.typeOperation;
    initialstatus_application =
      props.location.data.currentApplication.status_application;
    initialIDSiteViste = props.location.data.currentApplication.id_siteVisite;
    initialevidence_for_accreditation_at_national_level =
      props.location.data.currentApplication
        .evidence_for_accreditation_at_national_level;
    initialconfirmation_letter_national_agency =
      props.location.data.currentApplication
        .confirmation_letter_national_agency;
    initialinvolvment_of_the_relevant_national_bodies =
      props.location.data.currentApplication
        .involvment_of_the_relevant_national_bodies;
    initialcurriculum_development =
      props.location.data.currentApplication.curriculum_development;
    initialpostgraduate_supervision_guidelines =
      props.location.data.currentApplication
        .postgraduate_supervision_guidelines;
    initialquality_assurence_policy =
      props.location.data.currentApplication.quality_assurence_policy;
    initialcredit_transfer_policy =
      props.location.data.currentApplication.credit_transfer_policy;
    initialsummary_of_staff_capacity =
      props.location.data.currentApplication.summary_of_staff_capacity;
    initialinventory_of_facilities =
      props.location.data.currentApplication.inventory_of_facilities;
    initialannual_survey_report =
      props.location.data.currentApplication.annual_survey_report;
    initialtracer_study_report =
      props.location.data.currentApplication.tracer_study_report;
    initialexamination_policy =
      props.location.data.currentApplication.examination_policy;
    initialadmission_policy =
      props.location.data.currentApplication.admission_policy;
    initialprogram_review_report =
      props.location.data.currentApplication.program_review_report;
    initialother_not_specified =
      props.location.data.currentApplication.other_not_specified;
    initialTitle_program_application =
      props.location.data.currentApplication.title_program_application;
    initialDesignation_application =
      props.location.data.currentApplication.designation_application;
    initialUniversity_id_application =
      props.location.data.currentApplication.university_id_application;
    initialLevel_id_application =
      props.location.data.currentApplication.level_id_application;
    initialotherConstantsSiteVisite = props.location.data.constantProvided;

    initialOtherInfos =
      props.location.data.currentApplication.otherInfor_application;
    initialuser_created_application =
      props.location.data.currentApplication.user_created_application;
    initialstatus_thematicArea_application =
      props.location.data.currentApplication.thematicArea_application;
    userSubmitterInfor =
      props.location.data.currentApplication.userApplicationEmail;
    id_provenance = props.location.data.currentApplication.id_provenance;
    application = props.location.data.currentApplication;
    initialOtherInfos =
      props.location.data.currentApplication.constants_siteVisite;
    initialDataSendObject =
      props.location.data.currentApplication.dataRecolted_siteVisite;

      idApplication = props.location.data.currentApplication.id_application;
  }

  // console.log(props.location.data);
  // console.log(dataCollected);
  const history = useHistory();
  const dataObject = JSON.parse(dataCollected);
  const [selectedValues, setSelectedValues] = useState({});
  const [initialConstants, setInitialConstants] = useState("");
  const [buttonLabel, setButtonLabel] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  let userInfo = localStorage.getItem("userInfo");
  let userJson = JSON.parse(userInfo); // Rest of the code...

  const handleRadioChange = useCallback((name, value) => {
    console.log('newValue',value);
    setSelectedValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  const handleInputChangeTextComment = useCallback((event) => {
    setSelectedValues((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  }, []);

  const handleInputChangeNA = useCallback((event) => {
    setSelectedValues((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  }, []);
  

  const memoizedSelectedValues = useMemo(
    () => selectedValues,
    [selectedValues]
  );

  const handleFormSubmit = (values, { setSubmitting }) => {
    setButtonDisabled(true);

    // create a constant for all input radio
    const allValues = { ...selectedValues };

    const mergedDataObject = Object.assign({}, dataObject, allValues);

    // // prepare the Json which we will send in database
    const editSiteVisiteData = new FormData();

    editSiteVisiteData.append(
      "constant_siteVisitAffectationExpert",
      values.otherConstantsSiteVisite
    );
    editSiteVisiteData.append(
      "dataColectedSiteVisite",
      JSON.stringify(mergedDataObject)
    );

    if (buttonLabel === "SubmitFinal") {
      editSiteVisiteData.append("status_applicationSiteVisite", "2");
    } else if (buttonLabel === "SubmitPartial") {
      editSiteVisiteData.append("status_applicationSiteVisite", "1");
    }

    // // perform the saving of the information
    SiteVisiteService.submitReportSiteVisite(
      id_AfectationsiteVisitAffectationExpert,
      editSiteVisiteData,
      localStorage.getItem("access"),
      userJson.email,
      userJson.name,
      initialTitle_program_application,
      idApplication,
    )
      .then((response) => {
        // console.log("Access: ");
        let data = {
          status: 1,
          title: "Okay",
          message: "Thank you for your feedback",
        };
        history.push("/dashboard", { data: data });
      })
      .catch((error) => {
        // console.log("Error : ", error);
        let data = {
          status: 0,
          title: "Error",
          message: "An Error happened !",
        };
        history.push(`/dashboard`, {
          data: data,
        });
      });
    setSubmitting(false);
  };

  const {
    values,
    errors,
    touched,
    isSubmitting,
    setFieldValue,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      otherConstantsSiteVisite: initialotherConstantsSiteVisite,
    },
    onSubmit: handleFormSubmit,
  });

  const handleButtonClick = (label, event) => {
    event.preventDefault();
    setButtonLabel(label);
    handleSubmit();
  };

  const tabs = [
    {
      title: siteVisiteInformationDataEditInputPreview(dataObject, handleRadioChange, handleInputChangeTextComment,handleInputChangeNA).point1[0]
        .bigHeader,
      content: (
        <div>
          <TabContentSiteVisit
            content={
              siteVisiteInformationDataEditInputPreview(dataObject, handleRadioChange,handleInputChangeTextComment,handleInputChangeNA).point1[0]
            }
          />
        </div>
      ),
    },
    
  ];

  return (
    <div>
      <div className="w-full p-8">
        {buttonDisabled && <Loading />}
        {history.location.state == null ||
        history.location.state == undefined ? (
          ""
        ) : (
          <>
            {!(history.location.state.data == null) ? (
              <>
                {history.location.state.data.status === 1 ? (
                  <Success
                    delay="5000"
                    valueFromParent={history.location.state.data}
                  />
                ) : (
                  <Error
                    delay="7000"
                    valueFromParent={history.location.state.data}
                  />
                )}
              </>
            ) : (
              <></>
            )}
          </>
        )}

        <div className="">
          <div className="mb-2 text-md pt-4 grid grid-cols-1">
            <div className="w-full mb-6 md:mb-0">
              <label
                className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-2"
                htmlFor="grid-first-name"
              >
                Title of the Programme :
              </label>
              <label className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2">
                {initialTitle_program_application}
              </label>
            </div>
          </div>
          {/* <div className="mb-4 text-md pt-4 hidden">
        <div className="w-full px-3 mb-4 text-md ">
          <label
            className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-2"
            htmlFor="grid-last-name"
          >
            Other Informations :
          </label>
          <label
            className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2"
            htmlFor="grid-last-name"
          >
            {initialOtherInfos}
          </label>
        </div>
      </div>  */}
        </div>
        <div className="mb-3">
          <div className="w-full mb-6 md:mb-0 text-md">
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-2"
              htmlFor="grid-last-name"
            >
              Other Constants <span className="text-gray-600">*</span>
            </label>
            <textarea
              className="appearance-none block w-full dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] border border-gray-200 rounded py-3 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="otherConstantsSiteVisite"
              type="text"
              placeholder="Give the designation or others details"
              name="otherConstantsSiteVisite"
              value={values.otherConstantsSiteVisite}
              onChange={handleChange}
              rows={4}
            ></textarea>
          </div>
        </div>

        <Tab
          tabs={tabs}
          submitButton={
            <div className="text-center p-5">
              <button
                {...(isSubmitting ? { disabled: true } : "")}
                onClick={(event) => handleButtonClick("SubmitFinal", event)}
                type="submit"
                className="dark:bg-[var(--color-dark-buttonBackground)] 
                    bg-[var(--color-light-buttonBackground)] 
                    dark:text-[var(--color-dark-fontButton)] 
                    text-[var(--color-light-fontButton)]  
                    w-[200px]  py-3 rounded-md font-medium"
              >
                <span> {isSubmitting ? "Submitting..." : "Final Submission"} </span>
              </button>
            </div>
          }
          partialSubmission={
            <div className="text-center p-5">
              <button
                {...(isSubmitting ? { disabled: true } : "")}
                onClick={(event) => handleButtonClick("SubmitPartial", event)}
                type="submit"
                className="dark:bg-[var(--color-dark-buttonBackgroundSpecial)] 
                    bg-[var(--color-light-buttonBackgroundSpecial)] 
                    dark:text-[var(--color-dark-fontButton)] 
                    text-[var(--color-light-fontButton)]  
                    w-[200px]  py-3 rounded-md font-medium"
              >
                <span>
                  {" "}
                  {isSubmitting ? "Submitting..." : "Partial submission"}{" "}
                </span>
              </button>
            </div>
          }
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { login })(SiteVisiteEdit);
