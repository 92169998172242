import React, { useState, useEffect } from "react";
import { FaEdit, FaTrash, FaEye, FaPlus } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";
import UserService from "../../../services/UserService";
import { connect } from "react-redux";
import Success from "../../alerts/Success";
import Error from "../../alerts/Error";
import Loading from "../../eventsHandler/Loading";
import Popup from "../../alerts/Popup";
import UniversityService from "../../../services/UniversityService";

const UniversityList = ({ userConnectedhere, isAuthenticated }) => {
  var access = localStorage.getItem("access");
  const userInfo = localStorage.getItem("userInfo");
  const userConnected = JSON.parse(userInfo);
  const baseUrl = `${process.env.REACT_APP_API_URL}/api/university/list/`;
  const [listData, setListData] = useState([]);
  const [nextUrl, setNextUrl] = useState();
  const [previoustUrl, setPrevioustUrl] = useState();
  const history = useHistory();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalNumberData, setTotalNumberData] = useState(0);
  const [totalNumberPerPage, setTotalNumberPerPage] = useState(0);

  const [emailTobeUsed, setEmailToBeUser] = useState("");
  const [userIdTobeUsed, setUserIdToBeUser] = useState("");

  const [colorFont, setColorFont] = useState("");
  const [confirmationText, setConfirmationText] = useState("");

  const [showPopup, setShowPopup] = useState(false);
  const [from, setfrom] = useState("");
  const [clickedButton, setclickedButton] = useState("");

  if (!(history.location.state == null)) {
    const data = history.location.state.data;
  }

  //  we can get id of authenticated user in the object userConnected

  useEffect(() => {
    retrieveUniversity();
  }, [currentPage]);

  const retrieveUniversity = () => {
    setButtonDisabled(true);
    var access = localStorage.getItem("access");
    const pageUrl = `${baseUrl}?page=${currentPage}`;
    UniversityService.getAll(access, pageUrl)
      .then((response) => {
        setNextUrl(response.data.next);
        setPrevioustUrl(response.data.previous);
        setListData(response.data.results);
        setTotalPages(response.data.total_pages); // Update total pages
        setTotalNumberData(response.data.total_records);
        setTotalNumberPerPage(response.data.items_per_page);
        console.log(response.data.results);
      })
      .catch((e) => {
        console.log("error : ", e);
        if (e.response.status === 401) {
          // Redirect to the login page
          history.push('/login');
        }    
      })
      .finally(() => {
        setButtonDisabled(false);
      });
  };

  const handleConfirm = (e) => {
    console.log("event ", e);
    e.preventDefault(); 
      deleteUniversity(userIdTobeUsed); 
    setShowPopup(false);
  };

  const handleCancel = () => {
    setShowPopup(false);
  };

  const stopEventForm = (event) => {
    event.preventDefault();
  };

  // edit astutus
  const editStatut = (userId, email) => {
    setButtonDisabled(true); 
    if (userConnected.id === userId) {
      alert("you can't desactivate yourself!!");
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
      var access = localStorage.getItem("access");
      UserService.desableEnableUser(userId, access, email)
        .then((response) => {
          retrieveUniversity();
        })
        .catch((e) => {
          console.log(e);
          if (e.response.status === 401) {
            // Redirect to the login page
            history.push('/login');
          }    
        })
        .finally(() => {
          setButtonDisabled(false);
        });
    }
  };

  //  delete a user

  const deleteUniversity = (userId) => {
    if (userConnected.id === userId) {
      console.log("you can't edit your self!!");
    } else {
      var access = localStorage.getItem("access");
      UniversityService.deleteuniversity(userId, access)
        .then((response) => {
          retrieveUniversity();
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  let univData = JSON.parse(localStorage.getItem("universityData"));

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(listData);

  useEffect(() => {
    setFilteredData(listData);
  }, [listData]);

  const handleSearch = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setSearchTerm(searchTerm);
    if (
      searchTerm.trim() === "" ||
      searchTerm.trim() === null ||
      searchTerm.trim() === undefined
    ) {
      setFilteredData(listData);
    } else {
      // Filter data based on search term
      const filteredResults = listData.filter((item) =>
        item.university_name.toLowerCase().includes(searchTerm)
      );
      setFilteredData(filteredResults);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };
 

  return (
    <div className="w-full p-1">
      {buttonDisabled && <Loading />}
      {history?.location?.state === null ? (
        ""
      ) : history?.location?.state?.data?.status === 1 ? (
        <Success delay="5000" valueFromParent={history?.location?.state?.data} />
      ) : history?.location?.state?.data?.status === 0 ?(
        <Error delay="7000" valueFromParent={history?.location?.state?.data} />
      ) : <span></span>}
      <div className="m-0 relative shadow-md sm:rounded-lg">
      <span
          className=" text-gray-500
                          mb-2 
                          hover:text-gray-800"
        >
          {" "}
        </span>

        <div className="items-center justify-between mr-2 pb-2 grid grid-cols-3">
          <div className=""></div>
          <div className=""></div>
          <div className=""></div>
          <div className="mr-8">
            <label htmlFor="table-search" className="sr-only">
              Search
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  className="w-5 h-5 text-[var(--color-tbl200)] dark:text-[var(--color-gray-tbl400)]"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>
              <input
                type="text"
                id="table-search"
                value={searchTerm}
                onChange={handleSearch}
                className="block p-2 pl-10 text-md text-[var(--color-gray-tbl900)] border border-[var(--color-gray-tbl300)] rounded-lg w-80 bg-[var(--color-gray-tbl50)] focus:ring-blue-500 focus:border-blue-500 dark:bg-[var(--color-gray-tbl700)] dark:border-[var(--color-gray-tbl600)] dark:placeholder-[var(--color-gray-tbl400)] dark:text-[var(--color-gray-tbl0)] dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Search using username or full name"
              />
            </div>
          </div>
        </div>
        <div className="flex">
          <table
            className="w-full 
                text-md 
                text-left 
                text-[var(--color-tbl200)] 
                dark:text-[var(--color-gray-tbl400)]"
          >
            <thead className="text-md text-[var(--color-gray-tbl700)] capitalize bg-[var(--color-gray-tbl50)] dark:bg-[var(--color-gray-tbl700)] dark:text-[var(--color-gray-tbl400)]">
              <tr id="11s">
                <th scope="col" className="p-4">
                  <div className="flex items-center">
                    <input
                      id="checkbox-all-search"
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 
                                     bg-[var(--color-gray-tbl100)] 
                                     border-[var(--color-gray-tbl300)] 
                                     rounded focus:ring-blue-500
                                     dark:focus:ring-blue-600 dark:ring-offset-[var(--color-gray-tbl800)] dark:focus:ring-offset-[var(--color-gray-tbl800)] focus:ring-2 dark:bg-[var(--color-gray-tbl700)] dark:border-[var(--color-gray-tbl600)]"
                    />
                    <label htmlFor="checkbox-all-search" className="sr-only">
                      
                    </label>
                  </div>
                </th>
                <th scope="col" className="px-6 py-3">
                  Designation
                </th>
                <th scope="col" className="px-6 py-3">
                  Member
                </th>
                <th scope="col" className="px-6 py-3">
                  Type
                </th>

                <th scope="col" className="px-6 py-3 items-center ">
                  Level
                </th>
                <th scope="col" className="px-6 py-3">
                  WebSite
                </th>

                <th scope="col" className="px-6 py-3">
                  Country
                </th>

                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((user) => {
                // var classValue = "";
                // var nameComplete = "Complete";
                // var buttonCompleteClass = "outline-success";
                if (user.completed) {
                  // classValue = "text-decoration-line-through";
                  // nameComplete = "Revert back";
                  // buttonCompleteClass = "outline-warning";
                }
                return (
                  <tr
                    className="bg-[var(--color-gray-tbl0)] 
                    border-b dark:bg-[var(--color-gray-tbl800)] 
                    dark:border-[var(--color-gray-tbl700)] 
                    hover:bg-[var(--color-gray-tbl200)] 
                        dark:hover:bg-[var(--color-gray-tbl600)]"
                    key={`user${user.id}`}
                  >
                    <td className="w-4 p-4">
                      <div className="flex items-center">
                        <input
                          id="checkbox-table-search-1"
                          type="checkbox"
                          className="w-4 h-4 text-blue-600 bg-[var(--color-gray-tbl100)] border-[var(--color-gray-tbl300)] rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-[var(--color-gray-tbl800)] dark:focus:ring-offset-[var(--color-gray-tbl800)] focus:ring-2 dark:bg-[var(--color-gray-tbl700)] dark:border-[var(--color-gray-tbl600)]"
                        />
                        <label
                          htmlFor="checkbox-table-search-1"
                          className="sr-only"
                        >
                          checkbox
                        </label>
                      </div>
                    </td>
                    <th
                      scope="row"
                      className="px-1 py-1 font-medium text-[var(--color-gray-tbl900)] whitespace-nowrap dark:text-[var(--color-gray-tbl0)]"
                    >
                      {user.university_name}
                    </th>
                    <td className="px-6 py-4 uppercase">{user.iucea_membership}</td>
                    <td className="px-6 py-4 uppercase">
                      {user.type_of_establishment}
                    </td>
                    <td className="uppercase">
                      {user.level_of_progression}
                    </td>
                    <td className="px-6 py-4 text-ellipsis">
                      {user.website}
                    </td> 
                    <td className="px-6 py-4 text-ellipsis">
                      {user.country_name}
                    </td>
                    <td className="px-1 py-1">
                      <div className="grid grid-cols-2">
                       
                        <Link
                          to={{
                            pathname: "/university/" + user.id + "/add",
                            state: {
                              currentUser: user,
                            },
                          }}
                        >
                          <a
                            href="!#"
                            rel="noopener noreferrer"
                            className="
                                                font-medium 
                                                text-blue-600 
                                                dark:text-blue-500 
                                                hover:underline"
                          >
                            <FaEdit />
                          </a>
                        </Link>

                        <button
                          onClick={() => {
                            setShowPopup(true);
                            setclickedButton("deleteUniv");
                            setColorFont("red");
                            setUserIdToBeUser(user.id);
                            setfrom("delete");
                            setConfirmationText(
                              "Are you sure you want delete this university?"
                            );
                          }}
                          className="
                                                font-medium 
                                                text-red-600 
                                                dark:text-red-500 
                                                hover:underline"
                        >
                          <FaTrash />
                        </button>
                      </div>
                      {showPopup && (
                          <Popup
                            message={confirmationText}
                            onConfirm={(e) => handleConfirm(e)}
                            onCancel={handleCancel}
                            colorFont={colorFont}
                          />
                        )}
                    </td>
                  </tr>
                );
              })}
              {filteredData.length === 0 && (
                <tr>
                  <td className="border border-gray-300 px-4 py-2" colSpan="7">
                    No matching results found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="w-full">
          {/* <!-- Start coding here --> */}
          <div className="relative overflow-hidden bg-[var(--color-gray-tbl0)] rounded-b-lg shadow-md dark:bg-[var(--color-gray-tbl800)]">
            <nav
              className="flex flex-col items-start justify-between p-4 space-y-3 md:flex-row md:items-center md:space-y-0"
              aria-label="Table navigation"
            >
              <span className="text-md font-normal text-[var(--color-gray-tbl500)] dark:text-[var(--color-gray-tbl400)]">
                Showing{" "}
                <span className="font-semibold text-[var(--color-gray-tbl900)] dark:text-[var(--color-gray-tbl0)]">
                  1-{totalNumberPerPage}
                </span>{" "}
                of{" "}
                <span className="font-semibold text-[var(--color-gray-tbl900)] dark:text-[var(--color-gray-tbl0)]">
                  {totalNumberData}
                </span>
              </span>
              <ul className="inline-flex items-stretch -space-x-px">
                <div className="flex items-center justify-between px-4 py-2">
                  <li>
                    <button
                      onClick={handlePreviousPage} // Call the new function here
                      disabled={currentPage <= 1}
                      className={`px-3 py-1 text-md font-semibold rounded-lg focus:outline-none ${
                        currentPage <= 1
                          ? "opacity-50 cursor-not-allowed"
                          : "bg-[var(--color-gray-tbl0)] hover:bg-blue-600 text-black hover:text-white"
                      }`}
                    >
                      <svg
                        className="w-5 h-5"
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={handleNextPage}
                      disabled={currentPage >= totalPages}
                      className={`px-3 py-1 text-md font-semibold rounded-lg focus:outline-none ${
                        currentPage >= totalPages
                          ? "opacity-50 cursor-not-allowed"
                          : "bg-[var(--color-gray-tbl0)] hover:bg-blue-600 text-black hover:text-white"
                      }`}
                    >
                      <svg
                        className="w-5 h-5"
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </button>
                  </li>
                </div>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  userConnectedhere: state.auth.user,
});

export default connect(mapStateToProps)(UniversityList);
