import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import ApplicationService from "../../../services/ApplicationService";
import { connect } from "react-redux";
import { login } from "../../actions/auth";
import { ExpertReviewSchemasValidation } from "../../alerts/Validations";
import { useFormik, Field } from "formik";
import Success from "../../alerts/Success";
import Error from "../../alerts/Error";
import ApplicationFirstFeedback from "../../../services/ApplicationFirstFeedback";
import ExpertReviewService from "../../../services/ExpertReviewService";
import Loading from "../../eventsHandler/Loading";
import UserService from "../../../services/UserService";
import { FaFilePdf } from "react-icons/fa";
import TabContent from "../../smallComponents/TabContent";
import { preliminaryAssessmentAssessorsReviewLastDecion } from "../ConstantsAssessementsForExpertReviewLastDecision";
import SiteVisiteService from "../../../services/SiteVisiteService";
import iucea from "../../../images/iucea.jpg";
import moment from "moment";

const ExpertReviewViewReportPrint = (props) => {
  let editing = false;
  let initialTitle_program_application = "";
  let initialDesignation_application = "";
  let initialUniversity_id_application = "";
  let initialLevel_id_application = "";
  let initialOtherInfos = "";
  let initialevidence_for_accreditation_at_national_level = "";
  let initialconfirmation_letter_national_agency = "";
  let initialinvolvment_of_the_relevant_national_bodies = "";
  let initialcurriculum_development = "";
  let initialpostgraduate_supervision_guidelines = "";
  let initialquality_assurence_policy = "";
  let initialcredit_transfer_policy = "";
  let initialsummary_of_staff_capacity = "";
  let initialinventory_of_facilities = "";
  let initialannual_survey_report = "";
  let initialtracer_study_report = "";
  let initialexamination_policy = "";
  let initialadmission_policy = "";
  let initialprogram_review_report = "";
  let initialother_not_specified = "";
  let initialDocument2_application = "";
  let initialUserCreated = "";
  let initialApplicationID = "";
  let typeOperation = "";
  let initialstatus_application = "";
  let initialstatus_thematicArea_application = "";
  let initialuser_created_application = "";
  let id_provenance = "";
  let initialbatchNumber_Application = "";

  let numberOfStaffFeedbacks = [];
  let userSubmitterInfor = [];
  let userApplicantName = "";
  let application = [];
  let initialDataSendObject = "";
  let date_created_expertReview = "";
  let signatureImage = "";

  // console.log(props.location.state);
  if (props.location.state && props.location.state.currentApplication) {
    editing = true;
    typeOperation = props.location.typeOperation;
    initialstatus_application =
      props.location.state.currentApplication.resultApplication
        .status_application;
    initialApplicationID =
      props.location.state.currentApplication.resultApplication.id_application;
    initialevidence_for_accreditation_at_national_level =
      props.location.state.currentApplication.resultApplication
        .evidence_for_accreditation_at_national_level;
    initialconfirmation_letter_national_agency =
      props.location.state.currentApplication.resultApplication
        .confirmation_letter_national_agency;
    initialinvolvment_of_the_relevant_national_bodies =
      props.location.state.currentApplication.resultApplication
        .involvment_of_the_relevant_national_bodies;
    initialcurriculum_development =
      props.location.state.currentApplication.resultApplication
        .curriculum_development;
    initialpostgraduate_supervision_guidelines =
      props.location.state.currentApplication.resultApplication
        .postgraduate_supervision_guidelines;
    initialquality_assurence_policy =
      props.location.state.currentApplication.resultApplication
        .quality_assurence_policy;
    initialcredit_transfer_policy =
      props.location.state.currentApplication.resultApplication
        .credit_transfer_policy;
    initialsummary_of_staff_capacity =
      props.location.state.currentApplication.resultApplication
        .summary_of_staff_capacity;
    initialinventory_of_facilities =
      props.location.state.currentApplication.resultApplication
        .inventory_of_facilities;
    initialannual_survey_report =
      props.location.state.currentApplication.resultApplication
        .annual_survey_report;
    initialtracer_study_report =
      props.location.state.currentApplication.resultApplication
        .tracer_study_report;
    initialexamination_policy =
      props.location.state.currentApplication.resultApplication
        .examination_policy;
    initialadmission_policy =
      props.location.state.currentApplication.resultApplication
        .admission_policy;
    initialprogram_review_report =
      props.location.state.currentApplication.resultApplication
        .program_review_report;
    initialother_not_specified =
      props.location.state.currentApplication.resultApplication
        .other_not_specified;
    initialTitle_program_application =
      props.location.state.currentApplication.resultApplication
        .title_program_application;
    initialDesignation_application =
      props.location.state.currentApplication.resultApplication
        .designation_application;
    initialUniversity_id_application =
      props.location.state.currentApplication.resultApplication
        .university_id_application;
    initialLevel_id_application =
      props.location.state.currentApplication.resultApplication
        .level_id_application;

    initialOtherInfos =
      props.location.state.currentApplication.resultApplication
        .otherInfor_application;
    initialuser_created_application =
      props.location.state.currentApplication.resultApplication
        .user_created_application;
    numberOfStaffFeedbacks =
      props.location.state.currentApplication.resultApplication
        .expertReviewData;
    userSubmitterInfor =
      props.location.state.currentApplication.resultApplication
        .userApplicationEmail;
    userApplicantName =
      props.location.state.currentApplication.resultApplication
        .userApplicationUsername;
    id_provenance =
      props.location.state.currentApplication.resultApplication.id_application;

    initialstatus_thematicArea_application =
      props.location.state.currentApplication.resultApplication
        .thematicArea_application;

    application = props.location.state.currentApplication.resultApplication;
    initialDataSendObject =
      props.location.state.currentApplication.scoreFromExpert;
    initialbatchNumber_Application = application.batchNumber_Application;
    initialUserCreated = props.location.state.currentApplication.username;
    date_created_expertReview =
      props.location.state.currentApplication.date_created_expertReview;
    signatureImage =
      props.location.state.currentApplication.signature_imageExpertReview;
  }

  console.log(props.location.state.currentApplication);
  initialDataSendObject = JSON.parse(initialDataSendObject);

  let numberSubmission = 1;

  let contentGot = [];
  let newDictionnaryExpertReview = {};
  let expertReviewIdAlreadyDone = [];
  let numberGotTotal = 0;
  console.log(initialDataSendObject);

  if (initialDataSendObject) {
    Object.keys(initialDataSendObject).forEach((key) => {
      if (newDictionnaryExpertReview.hasOwnProperty(key)) {
        let newValue = Number(initialDataSendObject[key]);
        if (!isNaN(newValue)) {
          newDictionnaryExpertReview[key] += newValue;
          numberGotTotal = numberGotTotal + newValue;
        } else {
          newDictionnaryExpertReview[key] +=
            "\n *" + initialDataSendObject[key];
        }
      } else {
        // if it is a new value
        let newValue = Number(initialDataSendObject[key]);
        if (!isNaN(newValue)) {
          newDictionnaryExpertReview[key] = newValue;
          numberGotTotal = numberGotTotal + newValue;
        } else {
          newDictionnaryExpertReview[key] = "*" + initialDataSendObject[key];
        }
      }
    });
  }

  console.log(newDictionnaryExpertReview);

  let score = (numberGotTotal / (numberSubmission * 145)) * 100;
  // work on data submitted for the summary
  //

  const [level, setLevel] = useState(initialLevel_id_application);
  const certificateRef = useRef(null);
  const [disableSaveBtn, setDisableSaveBtn] = useState(false);
  const [status, setStatus] = useState("");

  let levelData = JSON.parse(localStorage.getItem("levelData"));
  if (props.location.state.expertReviewData) {
  }
  const history = useHistory();
  const [statusCheckboxSelect, setStatusCheckboxSelect] = useState(false);
  const [expertList, setExpertList] = useState([]);
  const [expertListNewAffectation, setExpertListNewAffectation] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  if (!(history.location.state == null)) {
    const data = history.location.state.data;
  }

  const [expertId, setExpertId] = useState([]);
  const [expertIdNew, setExpertIdNew] = useState([]);
  const [dataOfAddedExpert, setDataOfAddedExpert] = useState([]);
  const [expertApplication, setExpertApplication] = useState("");
  const [thematic, setThematic] = useState([]);

  let urlUser = `${process.env.REACT_APP_API_URL}/api/user/${initialuser_created_application}/view`;

  let allUniveData = JSON.parse(localStorage.getItem("universityData"));
  let universityName = "";
  allUniveData.map((dataUniv) => {
    if (dataUniv.id === Number(initialUniversity_id_application)) {
      universityName = dataUniv.university_name;
    }
  });

  const onSubmit = (values, { resetForm }) => {
    setDisableSaveBtn(true);
    let userInfo = localStorage.getItem("userInfo");
    let userJson = JSON.parse(userInfo);

    const newApplication = new FormData();

    newApplication.append("type_firstFeedback", "End of first compulations");
    newApplication.append("constants_firstFeedback", values.theOverAllFeedBack);
    newApplication.append("status_firstFeedback", statusCheckboxSelect);
    newApplication.append("id_ApplicationfirstFeedback", id_provenance);
    ApplicationFirstFeedback.createFirstFeedback(
      newApplication,
      localStorage.getItem("access"),
      userSubmitterInfor,
      initialTitle_program_application,
      userApplicantName
    )
      .then((response) => {
        console.log("response: ", response.data);
        //  if validation
        if (statusCheckboxSelect) {
          const newSiteVisit = new FormData();
          newSiteVisit.append("constants_siteVisite ", "");
          newSiteVisit.append("status_siteVisite", 0);
          newSiteVisit.append("id_applicationSiteVisite", initialApplicationID);
          newSiteVisit.append(
            "id_ExpertReview_siteVisite",
            JSON.stringify(expertReviewIdAlreadyDone)
          );

          SiteVisiteService.create(newSiteVisit, localStorage.getItem("access"))
            .then((response) => {
              let idSiteVisite = response.data.id_siteVisite;

              // affect Site visit to new expert'
              console.log("expertIdNew: ", expertIdNew);
              expertIdNew.forEach((idExpert) => {
                const newSiteVisiteAffectation = new FormData();
                newSiteVisiteAffectation.append(
                  "id_applicationSiteVisitAffectationExpert",
                  initialApplicationID
                );
                newSiteVisiteAffectation.append(
                  "id_expertSiteVisitAffectationExpert",
                  idExpert
                );
                newSiteVisiteAffectation.append(
                  "id_siteVisitesiteVisitAffectationExpert",
                  idSiteVisite
                );

                let emailUser = "";
                let nameUser = "";
                expertList.map((userInfogot) => {
                  if (Number(userInfogot.id) === Number(idExpert)) {
                    emailUser = userInfogot.email;
                    nameUser = userInfogot.name;
                  }
                });

                SiteVisiteService.addExpertOnSiteVisite(
                  newSiteVisiteAffectation,
                  localStorage.getItem("access"),
                  emailUser,
                  nameUser,
                  universityName,
                  initialTitle_program_application
                )
                  .then((response) => {
                    console.log("response ", response);
                  })
                  .catch((error) => {
                    console.log("error ", error);
                  });
              });
              setStatus("Saved correctly...");
              let data = {
                status: 1,
                title: "Okay",
                message:
                  "Over All decision made with Success continue the next stage (Site visit)",
              };
              history.push("/application/list", { data: data });
            })
            .catch((error) => {
              setStatus("Error Saving data");
              console.log("Error : ", error);
              let data = {
                status: 0,
                title: "Error",
                message: "An Error happened !",
              };
              history.push(`/application/list`, {
                data: data,
              });
            })
            .finally(() => {
              setDisableSaveBtn(false);
            });
        }
      })
      .catch((err) => {
        console.log("errors creation siteVisite", err);
      });

    setDisableSaveBtn(false);
  };

  useEffect(() => {
    let url = `${process.env.REACT_APP_API_URL}/api/user/list/expert`;
    UserService.getExpert(url)
      .then((response) => {
        setExpertList(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 401) {
          // Redirect to the login page
          history.push("/login");
        }
      });
  }, []);

  const {
    values,
    errors,
    touched,
    isSubmitting,
    setFieldValue,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      designation_application: "",
      title_program_application: initialTitle_program_application,
      university_id_application: initialUniversity_id_application,

      evidence_for_accreditation_at_national_level: "",
      confirmation_letter_national_agency: "",
      involvment_of_the_relevant_national_bodies: "",

      otherInfor_application: initialOtherInfos,
      level_id_application: initialLevel_id_application,

      otherInfor_application: initialOtherInfos,
      level_id_application: initialLevel_id_application,
      theOverAllFeedBack: "",
    },
    validationSchema: ExpertReviewSchemasValidation,
    onSubmit,
  });

  let levelGot = "";
  levelData.map((levelDB) => {
    if (levelDB.id_level === level) {
      levelGot = levelDB.designation_level;
    }
  });

  let listSelectedExpert = [];

  if (typeOperation !== "statusEdit") {
    if (expertList == null) {
      expertList.map((expert) => {
        listSelectedExpert.push(Number(expert.id_expert_applicationExpert));
      });
    }
  }

  const changeStatus = (status) => {
    setStatusCheckboxSelect(status.target.value);
  };

  useEffect(() => {
    let notificationListUrl = `${process.env.REACT_APP_API_URL}/api/applicationExpert/${initialApplicationID}/getStatusExpertSiteVisite`;
    ExpertReviewService.getAllStatusSiteVisite(
      localStorage.getItem("access"),
      notificationListUrl
    )
      .then((resNot) => {
        if (Number(resNot.data.statusApplication) === 1) {
          setButtonDisabled(true);
        }
      })
      .catch((errNot) => {
        console.log("errNot : ", errNot);
        if (errNot.response.status === 401) {
          // Redirect to the login page
          history.push("/login");
        }
      });
  }, []);

  useEffect(() => {
    retrieveApplicationsExepert(initialApplicationID);
  }, [initialApplicationID]);

  const retrieveApplicationsExepert = (idApplication) => {
    setDataOfAddedExpert([]);
    dataOfAddedExpert.length = 0;
    var access = localStorage.getItem("access");
    const linkToData = `${process.env.REACT_APP_API_URL}/api/applicationSiteVisiteAffectationExpert/${idApplication}/applicationAffectation`;

    ApplicationService.getAllExpert(access, linkToData)
      .then((response) => {
        localStorage.setItem(
          "ExpertApplication",
          JSON.stringify(response.data)
        );
        setExpertApplication(response.data);
      })
      .catch((e) => {
        console.log("error : ", e);
        if (e.response.status === 401) {
          // Redirect to the login page
          history.push("/login");
        }
      });
  };

  if (expertApplication.length > 0 && expertApplication != null) {
    expertApplication.forEach((expert) => {
      dataOfAddedExpert.push(
        Number(expert.id_expertSiteVisitAffectationExpert)
      );
    });
  }

  useEffect(() => {
    // getThematic
    let url = `${process.env.REACT_APP_API_URL}/api/user/list/expert`;
    UserService.getExpert(url)
      .then((response) => {
        setExpertList(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 401) {
          // Redirect to the login page
          history.push("/login");
        }
      });
  }, []);

  function isInArray(value, array) {
    return array.indexOf(value) > -1;
  }

  function removeItemAll(arr, value) {
    var i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }

  const onAddingItem = (item) => {
    const isChecked = item.target.checked;
    let newValue = item.target.value;
    if (isChecked) {
      expertIdNew.push(newValue);
      expertIdNew.indexOf(newValue) === -1
        ? expertIdNew.push(newValue)
        : console.log("This item already exists");
    } else {
      removeItemAll(expertIdNew, newValue);
    }
  };

  let numberOfExpert = expertList.length;
  let hiddenExpert = 0;

  useEffect(() => {
    // getThematic
    let url = `${process.env.REACT_APP_API_URL}/api/thematicArea/`;
    UserService.getThematic(url)
      .then((response) => {
        setThematic(response.data);
      })
      .catch((errors) => {
        console.log("errors");
        console.log(errors);
        if (errors.response.status === 401) {
          // Redirect to the login page
          history.push("/login");
        }
      });
  }, []);

  const handlePrint = () => {
    const contentToPrint = certificateRef.current;
    if (contentToPrint) {
      const printWindow = window.open("", "_blank");
      printWindow.document.write(`
        <html>
          <head>
            <link rel="stylesheet" type="text/css" >
          </head>
          <style>
          body {
            font-family: "Times New Roman", serif;
          }
          .logo-img {
            width: 140px; /* Adjust image size as needed */
            height: 140px;
            display: block; 
            margin: 0 auto;  
            text-align: center;  
        }
        .center-content { 
          display: block; 
          margin: 0 auto;  
          text-align: center; 
         
      }
       .text-gray-900 {
            --tw-text-opacity: 1;
            color: rgb(17 24 39 / var(--tw-text-opacity));
        } 
        .text-center {
            text-align: center;
        }
        .uppercase {
            text-transform: uppercase;
        }
        .bg-gray-200 {
            --tw-bg-opacity: 1;
            background-color: rgb(229 231 235 / var(--tw-bg-opacity));
        }
        .px-6 {
            padding-left: 1.5rem/* 24px */;
            padding-right: 1.5rem/* 24px */;
        }
        .font-semibold {
            font-weight: 600;
        }
        .py-2 {
            padding-top: 0.5rem/* 8px */;
            padding-bottom: 0.5rem/* 8px */;
        }
        .win-10 {
            width: 10px;
        }
        .win-200 {
            width: 200px;
        }
        .win-100 {
            width: 100px;
        }
        .text-center {
            text-align: center;
        }
        .win-600 {
            width: 600px;
        }
          </style>
          <body>
            ${contentToPrint.outerHTML}
          </body>
        </html>
      `);
      printWindow.document.close();
      printWindow.print();
    }
  };

  return (
    <div>
      <div className="w-full p-4">
        {disableSaveBtn && <Loading />}

        <table ref={certificateRef} className="content-table text-gray-900">
          <thead>
            <tr>
              <td
                className="  py-2 px-6 bg-gray-0 text-gray-900 uppercase text-lg font-semibold text-center"
                colspan="2"
              >
                <img src={iucea} className="logo-img" />
                <span className="center-content">
                  INTER-UNIVERSITY COUNCIL FOR EAST AFRICA
                  <br />
                  P.O. BOX 7110 KAMPALA
                </span>
              </td>
            </tr>
            <tr>
              <td
                className=" py-2 px-6 bg-gray-200 text-gray-900 uppercase text-lg font-semibold text-center"
                colspan="2"
              >
                <span className="center-content">
                  EAC PROGRAMME ACCREDITATION - PRELIMINARY REVIEW REPORT
                </span>
              </td>
            </tr>
            <tr>
              <td className="py-2 px-6 text-gray-900">Name of Institution</td>
              <td className="py-2 px-6"> {universityName}</td>
            </tr>
            <tr>
              <td className="py-2 px-6 text-gray-900">Name of Programme</td>
              <td className="py-2 px-6">{initialTitle_program_application}</td>
            </tr>
            <tr>
              <td className="py-2 px-6 text-gray-900">Cluster Discipline</td>
              <td className="py-2 px-6">
                {thematic.map((area) =>
                  initialstatus_thematicArea_application ===
                  area.id_thematicsArea
                    ? area.designation_thematicsArea
                    : ""
                )}
              </td>
            </tr>
            <tr>
              <td className="py-2 px-6 up text-gray-900">Name of Reviewer</td>
              <td className="py-2 px-6">
                <span className="uppercase">{initialUserCreated} </span> <br />{" "}
                {/* {signatureImage ? (
                  <img
                    style={{
                      border: "1px solid black",
                      width: "100px",
                      height: "50px",
                      position: "relative",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    src={`${process.env.REACT_APP_API_URL}/documents/${signatureImage}`}
                  />
                ) : (
                  ""
                )} */}
                {moment(date_created_expertReview.replace(/"/g, "")).format(
                  "Do MMMM YYYY HH:mm"
                )}{" "}
                {}
              </td>
            </tr>

            <tr>
              <td
                className=" bg-gray-200 py-2 px-6 text-gray-900 text-center uppercase text-lg font-semibold"
                colspan="2"
              >
                <span className="center-content">FOR OFFICIAL USE ONLY</span>
              </td>
            </tr>
            <tr>
              <td className="py-2 px-6 text-gray-900 capitalize">
                Category of Institution
              </td>
              <td className="py-2 px-6">University</td>
            </tr>
            <tr>
              <td className="py-2 px-6">Level of the programme</td>
              <td className="py-2 px-6">{levelGot}</td>
            </tr>
            <tr>
              <td className="py-2 px-6">Status</td>
              <td className="py-2 px-6">Yes</td>
            </tr>
            <tr>
              <td className="py-2 px-6">Batch Number</td>
              <td className="py-2 px-6">{initialbatchNumber_Application}</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colspan="2">
                <br />
                <br />

                <div>
                  <TabContent
                    content={
                      preliminaryAssessmentAssessorsReviewLastDecion(
                        newDictionnaryExpertReview,
                        numberSubmission
                      ).point1[0]
                    }
                  />
                </div>
              </td>
            </tr>
          </tbody>
          {/* <tfoot>
            <tr>
              <td>
                <span className="font-semibold"> *Recommended </span>(this
                programme is recommended for assessment by experts). <br />
                <span className="font-semibold"> Not Recommended </span> (this
                Programme is not recommended for assessment because <br />
                of clear reasons stated in the report. The institution may be
                asked to submit complete
                <br />
                application documents).
              </td>
            </tr>
          </tfoot> */}
        </table>
        <div className="text-center p-5">
          <>
            {" "}
            <button
              onClick={() => handlePrint()}
              className="dark:bg-[var(--color-dark-buttonBackground)] 
                        bg-[var(--color-light-buttonBackground)] 
                        dark:text-[var(--color-dark-fontButton)] 
                        text-[var(--color-light-fontButton)]  
                        w-[100px]  py-3 rounded-md font-medium"
            >
              {" "}
              {typeOperation == "AddExpert" ? <span>Print</span> : "Print"}
            </button>
          </>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { login })(ExpertReviewViewReportPrint);
