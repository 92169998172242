 
export const preliminaryAssessmentDeskReview = (handleInputChangeTextComment) => ({
  point1: [
    {
      bigHeader: "Preliminary Review Comments",
      content: [
        {
          subData: [
            {
              number: ``,
              subtitleStandard: ``,
              subcontent: [
                {
                  subtitleNorm: ``,
                  dataContent: [
                    {
                     column4: (
                        <span className="font-bold text-black">S/N</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">Item/Aspect</span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Reviewers Remarks
                        </span>
                      ), 
                    },
                    {
                      column4: (
                        <span className="">1)</span>
                      ),
                      column5: `1.0 Accreditation status of
                      the programme (This
                      includes evidence of
                      recent audit of
                      programme)`,
                        
                      column6: (
                        <textarea
                          name="accreditationStatusOfTheProgramme"
                          cols={80}  // Increase the number of columns
                          rows={10}  // Increase the number of rows
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",  // Adjust width if needed
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },

                    {

                      column4: (
                        <span className="">2)</span>
                      ),
                      column5: `2.1 Curriculum development
                      process (Stakeholder
                      Involvement)`,
                        
                      column6: (
                        <textarea
                          name="curriculumDevelopmentProcess"
                          cols={80}  // Increase the number of columns
                          rows={10}  // Increase the number of rows
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",  // Adjust width if needed
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },

                    {

                      column4: (
                        <span className="">3)</span>
                      ),
                      column5: `2.2 Curriculum development
                      process (Alignment of
                      programme to National,
                      Regional, Continental or
                      global qualifications
                      frameworks)`,
                        
                      column6: (
                        <textarea
                          name="curriculumDevelopmentAlignmentOfProgrammeToNationalRegionalContinental"
                          cols={80}  // Increase the number of columns
                          rows={10}  // Increase the number of rows
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",  // Adjust width if needed
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    {
                      column4: (
                        <span className="">4)</span>
                      ),
                      column5: `2.3 Curriculum development
                      process
                      (Endorsement/input
                      from professional
                      bodies) (Where
                      applicable)`,
                        
                      column6: (
                        <textarea
                          name="curriculumDevelopmentEndorsmentInputFromProfessionBodies"
                          cols={80}  // Increase the number of columns
                          rows={10}  // Increase the number of rows
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",  // Adjust width if needed
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },

                    {
                      column4: (
                        <span className="">5)</span>
                      ),
                      column5: `2.4 Evidence of Payment`,
                        
                      column6: (
                        <textarea
                          name="evidenceOfPayment"
                          cols={80}  // Increase the number of columns
                          rows={10}  // Increase the number of rows
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",  // Adjust width if needed
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    {
                      column4: (
                        <span className="">6)</span>
                      ),
                      column5: `Recommendation*(Recommended, Not Recommended)`,
                        
                      column6: (
                        <textarea
                          name="recommendationProgramme"
                          cols={80}  // Increase the number of columns
                          rows={3}  // Increase the number of rows
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",  // Adjust width if needed
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                  ], // data content tag
                },
              ], // sub content
            },
          ],
        },

        // End of part 2
      ],
    },
  ],
});
