import axios from "axios";

class DashboardService {
    async getAllApplication(access) {
        axios.defaults.headers.common["Authorization"] = "JWT " + access;
        // axios.defaults.headers.common["X-Frame-Options"] = "sameorigin";
        axios.defaults.headers.common["Content-type"] = "multipart/form-data";
        axios.defaults.headers.common["Accept-Encoding"] = "gzip, deflate, br";
        const result = await axios.get(`${process.env.REACT_APP_API_URL}/api/getDashboardDataApplication/`);
        return result;
      }

      async getAccreditation(access) {
        axios.defaults.headers.common["Authorization"] = "JWT " + access;
        // axios.defaults.headers.common["X-Frame-Options"] = "sameorigin";
        axios.defaults.headers.common["Content-type"] = "multipart/form-data";
        axios.defaults.headers.common["Accept-Encoding"] = "gzip, deflate, br";
        const result = await axios.get(`${process.env.REACT_APP_API_URL}/api/getDashboardDataAccreditation/`);
        return result;
      }

      async getCHEADecision(access) {
        axios.defaults.headers.common["Authorization"] = "JWT " + access;
        // axios.defaults.headers.common["X-Frame-Options"] = "sameorigin";
        axios.defaults.headers.common["Content-type"] = "multipart/form-data";
        axios.defaults.headers.common["Accept-Encoding"] = "gzip, deflate, br";
        const result = await axios.get(`${process.env.REACT_APP_API_URL}/api/getDashboardDataCHEADecision/`);
        return result;
      }

      async getSiteVisit(access) {
        axios.defaults.headers.common["Authorization"] = "JWT " + access;
        // axios.defaults.headers.common["X-Frame-Options"] = "sameorigin";
        axios.defaults.headers.common["Content-type"] = "multipart/form-data";
        axios.defaults.headers.common["Accept-Encoding"] = "gzip, deflate, br";
        const result = await axios.get(`${process.env.REACT_APP_API_URL}/api/getDashboardDataSiteVisit/`);
        return result;
      }
       
      async getAppeal(access) {
        axios.defaults.headers.common["Authorization"] = "JWT " + access;
        // axios.defaults.headers.common["X-Frame-Options"] = "sameorigin";
        axios.defaults.headers.common["Content-type"] = "multipart/form-data";
        axios.defaults.headers.common["Accept-Encoding"] = "gzip, deflate, br";
        const result = await axios.get(`${process.env.REACT_APP_API_URL}/api/getDashboardDataAppeal/`);
        return result;
      }
}
export default  new DashboardService()