import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import ApplicationService from "../../../services/ApplicationService";
import { connect } from "react-redux";
import { login } from "../../actions/auth";
import { CHEASchemasValidation } from "../../alerts/Validations";
import { useFormik } from "formik";
import Success from "../../alerts/Success";
import Error from "../../alerts/Error";
import UserService from "../../../services/UserService";
import Loading from "../../eventsHandler/Loading";
import TabCHEA from "../../smallComponents/TabCHEA";
import { preliminaryAssessment } from "../ConstantsAssessementsReportCHEA";
import TabContentCHEA from "../../smallComponents/TabContentCHEA";
import Popup from "../../alerts/Popup";
import CHEAService from "../../../services/CHEAService";
import AccreditationService from "../../../services/AccreditationService";
import { preliminaryAssessmentAssessorsReviewLastDecion } from "../ConstantsAssessementsForExpertReviewLastDecision";
import TabContent from "../../smallComponents/TabContent";
import { preliminaryAssessmentForDeskReviewVisualization } from "../ConstantsAssessementsForDeskReviewVisualisation";
import { siteVisiteInformationDataInputPreviewVizualization } from "../ConstantsAssessementsForExpertSiteVisiteInformationsPreview";

const CHEAAddReport = (props) => {
  let editing = false;
  let initialTitle_program_application = "";
  let initialDesignation_application = "";
  let initialUniversity_id_application = "";
  let initialLevel_id_application = "";
  let initialOtherInfos = "";
  let initialevidence_for_accreditation_at_national_level = "";
  let initialconfirmation_letter_national_agency = "";
  let initialinvolvment_of_the_relevant_national_bodies = "";
  let initialcurriculum_development = "";
  let initialpostgraduate_supervision_guidelines = "";
  let initialquality_assurence_policy = "";
  let initialcredit_transfer_policy = "";
  let initialsummary_of_staff_capacity = "";
  let initialinventory_of_facilities = "";
  let initialannual_survey_report = "";
  let initialtracer_study_report = "";
  let initialexamination_policy = "";
  let initialadmission_policy = "";
  let initialprogram_review_report = "";
  let initialother_not_specified = "";
  let initialApplicationID = "";
  let dataSent = "";

  let initialstatus_application = "";
  let initialstatus_thematicArea_application = "";
  let initialuser_created_application = "";
  let applicationData = {};

  let typeOperation = props.location.state.typeOperation;

  if (typeOperation === "sendChEAD") {
    applicationData = props.location.state.application;
  } else {
    applicationData = props.location.state.application.applicationData;
  }

  console.log(applicationData);

  if (applicationData) {
    editing = true;
    typeOperation = props.location.state.typeOperation;
    initialstatus_application = applicationData.status_application;
    initialApplicationID = applicationData.id_application;
    initialevidence_for_accreditation_at_national_level =
      applicationData.evidence_for_accreditation_at_national_level;
    initialconfirmation_letter_national_agency =
      applicationData.confirmation_letter_national_agency;
    initialinvolvment_of_the_relevant_national_bodies =
      applicationData.involvment_of_the_relevant_national_bodies;
    initialcurriculum_development = applicationData.curriculum_development;
    initialpostgraduate_supervision_guidelines =
      applicationData.postgraduate_supervision_guidelines;
    initialquality_assurence_policy = applicationData.quality_assurence_policy;
    initialcredit_transfer_policy = applicationData.credit_transfer_policy;
    initialsummary_of_staff_capacity =
      applicationData.summary_of_staff_capacity;
    initialinventory_of_facilities = applicationData.inventory_of_facilities;
    initialannual_survey_report = applicationData.annual_survey_report;
    initialtracer_study_report = applicationData.tracer_study_report;
    initialexamination_policy = applicationData.examination_policy;
    initialadmission_policy = applicationData.admission_policy;
    initialprogram_review_report = applicationData.program_review_report;
    initialother_not_specified = applicationData.other_not_specified;
    initialTitle_program_application =
      applicationData.title_program_application;
    initialDesignation_application = applicationData.designation_application;
    initialUniversity_id_application =
      applicationData.university_id_application;
    initialLevel_id_application = applicationData.level_id_application;

    initialOtherInfos = applicationData.otherInfor_application;
    initialuser_created_application = applicationData.user_created_application;
    initialstatus_thematicArea_application =
      applicationData.thematicArea_application;
    dataSent = applicationData.commentsDeskReview;
  }

  console.log(dataSent);
  let idAffectation = props.location.state.notifID;
  // id_CHEADecisionAffectationCHEADecision
  let userApplicationEmail = applicationData.userApplicationEmail;
  let userApplicationID = applicationData.applicantID;
  let userApplicationUserName = applicationData.userApplicationUsername;
  let titleProgram = applicationData.title_program_application;

  const [titleName, setTitleName] = useState(initialTitle_program_application);
  const [level, setLevel] = useState(initialLevel_id_application);
  const [designation, setDesignation] = useState(
    initialDesignation_application
  );
  const [disableSaveBtn, setDisableSaveBtn] = useState(false);
  const [otherInfo, setOtherInfo] = useState(initialOtherInfos);
  const [colorFont, setColorFont] = useState("");
  const [confirmationText, setConfirmationText] = useState("");

  let levelData = JSON.parse(localStorage.getItem("levelData"));

  const history = useHistory();
  const [userInfo, setUserInfo] = useState("");
  const [expertId, setExpertId] = useState([]);
  const [dataOfAddedExpert, setDataOfAddedExpert] = useState([]);
  const [expertApplication, setExpertApplication] = useState("");
  const [thematic, setThematic] = useState([]);
  const [statusCheckboxSelect, setStatusCheckboxSelect] = useState(false);

  const [showPopup, setShowPopup] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [clickedButton, setclickedButton] = useState("");
  const [expertList, setExpertList] = useState([]);

  if (!(history.location.state == null)) {
    const data = history.location.state.data;
    // console.log("Data send: ");
    // console.log(data);
  }

  useEffect(() => {
    retrieveApplicationsExepert(initialApplicationID);
  }, [initialApplicationID]);

  const retrieveApplicationsExepert = (idApplication) => {
    setDataOfAddedExpert([]);
    dataOfAddedExpert.length = 0;
    var access = localStorage.getItem("access");
    const linkToData = `${process.env.REACT_APP_API_URL}/api/applicationSiteVisiteAffectationExpert/${idApplication}/applicationAffectation`;

    ApplicationService.getAllExpert(access, linkToData)
      .then((response) => {
        localStorage.setItem(
          "ExpertApplication",
          JSON.stringify(response.data)
        );
        setExpertApplication(response.data);
      })
      .catch((e) => {
        console.log("error : ", e);
        if (e.response.status === 401) {
          // Redirect to the login page
          history.push("/login");
        }
      });
  };

  if (expertApplication.length > 0 && expertApplication != null) {
    expertApplication.forEach((expert) => {
      dataOfAddedExpert.push(
        Number(expert.id_expertSiteVisitAffectationExpert)
      );
    });
  }

  let siteVisitInfo =
    applicationData.resultSiteVisite.siteVisiteInfo.reportSiteVisite;

    let numberSubmission = siteVisitInfo.length;
    console.log('siteVisitInfo',siteVisitInfo);
    let newDictionnarySiteVisite = {};
    let siteVisiteIDReport = [];
    let totalGotSiteVisite = 0;
    let totalRemoveSiteVisite = 0;
  
    siteVisitInfo.map((data) => {
      siteVisiteIDReport.push(data.id_siteVisitAffectationExpert);
      let dataSiteVisite = JSON.parse(data.dataColectedSiteVisite);
      // console.log(dataSiteVisite);
      Object.keys(dataSiteVisite).forEach((key) => {
        if (newDictionnarySiteVisite.hasOwnProperty(key)) {
          if (key.endsWith("NA")) {
            // for the one to be removed
            let newValue = Number(dataSiteVisite[key]);
            if (!isNaN(newValue)) {
              newDictionnarySiteVisite[key] += newValue;
              totalRemoveSiteVisite = totalRemoveSiteVisite + newValue;
            } else {
              newDictionnarySiteVisite[key] = dataSiteVisite[key];
            }
          } else {
            // normal one
            let newValue = Number(dataSiteVisite[key]);
            if (!isNaN(newValue)) {
              newDictionnarySiteVisite[key] += newValue;
              totalGotSiteVisite = totalGotSiteVisite + newValue;
            } else {
              newDictionnarySiteVisite[key] = dataSiteVisite[key];
            }
          }
        } else {
          if (key.endsWith("NA")) {
            // total to be removed
            let newValue = Number(dataSiteVisite[key]);
            if (!isNaN(newValue)) {
              newDictionnarySiteVisite[key] = newValue;
              totalRemoveSiteVisite = totalRemoveSiteVisite + newValue; 
            }  
          } else {
            // normal one
            let newValue = Number(dataSiteVisite[key]);
            if (!isNaN(newValue)) {
              newDictionnarySiteVisite[key] = newValue;
              totalGotSiteVisite = totalGotSiteVisite + newValue;
            } else {
              newDictionnarySiteVisite[key] = dataSiteVisite[key];
            }
          }
        }
      });
    });
  
    let scoreSiteVisite = (
      (totalGotSiteVisite / (numberSubmission * (805-(totalRemoveSiteVisite)))) *
      100
    ).toFixed(2); 
  // console.log("scoreSiteVisite ", scoreSiteVisite);
  let expertReviewData = [];
  let numberSubmissionExpertReview = 0;
  if (typeOperation === "sendChEAD") {
    expertReviewData = applicationData.expertReviewData;
    numberSubmissionExpertReview = expertReviewData.length;
  } else {
    expertReviewData = applicationData.expertReviewData;
    numberSubmissionExpertReview = expertReviewData.length;
  }

  console.log("--", applicationData);
  let newDictionnaryExpertReview = {};
  let expertReviewIdAlreadyDone = [];
  let numberGotTotalExpertReview = 0;

  if (expertReviewData) {
    expertReviewData.map((data) => {
      // console.log(data.id_siteVisite);
      expertReviewIdAlreadyDone.push(data.id_expertReview);
      let dataExpertReviewScore = JSON.parse(data.scoreFromExpert);
      Object.keys(dataExpertReviewScore).forEach((key) => {
        if (newDictionnaryExpertReview.hasOwnProperty(key)) {
          let newValue = Number(dataExpertReviewScore[key]);
          if (!isNaN(newValue)) {
            newDictionnaryExpertReview[key] += newValue;
            numberGotTotalExpertReview = numberGotTotalExpertReview + newValue;
          } else {
            newDictionnaryExpertReview[key] +=
              "\n *" + dataExpertReviewScore[key];
          }
        } else {
          // if it is a new value
          let newValue = Number(dataExpertReviewScore[key]);
          if (!isNaN(newValue)) {
            newDictionnaryExpertReview[key] = newValue;
            numberGotTotalExpertReview = numberGotTotalExpertReview + newValue;
          } else {
            newDictionnaryExpertReview[key] = "*" + dataExpertReviewScore[key];
          }
        }
      });
    });
  }

  let scoreFirstReview =
    (numberGotTotalExpertReview / (numberSubmissionExpertReview * 145)) * 100;

  let allUniveData = JSON.parse(localStorage.getItem("universityData"));
  let universityName = "";
  allUniveData.map((dataUniv) => {
    if (dataUniv.id === Number(initialUniversity_id_application)) {
      universityName = dataUniv.university_name;
    }
  });
  let siteVisiteID = 0;
  let applicantIDOnFromApplication = 0;
  if (typeOperation === "sendChEAD") {
    siteVisiteID =
      applicationData.resultSiteVisite.siteVisiteInfo.id_siteVisite;
    applicantIDOnFromApplication = initialuser_created_application;
  } else {
    siteVisiteID =
      applicationData.resultSiteVisite.siteVisiteInfo.id_siteVisite;
    applicantIDOnFromApplication = initialuser_created_application;
  }

  const onSubmit = (values, { resetForm, setSubmitting }) => {
    // check if there is a selection of acceptance or Deferred
    if (Number(statusCheckboxSelect) <= 1) {
      alert("Please provide Accept or Deferred this report");
      return;
    }
    setDisableSaveBtn(true);
    let userInfo = localStorage.getItem("userInfo");
    let userJson = JSON.parse(userInfo);
    const newCheaDecision = new FormData();

    if (typeOperation === "sendChEAD") {
      // when it is a new chea desion
      newCheaDecision.append(
        "constants_CHEADecision ",
        values.theOverAllFeedBack
      );
      newCheaDecision.append("id_siteViste_CHEADecision ", siteVisiteID);
      newCheaDecision.append("id_applicant_CHEADecision", userApplicationID);
      newCheaDecision.append(
        "status_applicationCHEADecison",
        statusCheckboxSelect
      );
      newCheaDecision.append(
        "id_application_CHEADecision",
        initialApplicationID
      );
      CHEAService.submitReportCHEACreate(
        newCheaDecision,
        localStorage.getItem("access"),
        userApplicationEmail,
        titleProgram,
        userApplicationUserName,
        initialApplicationID,
        universityName
      )
        .then((response) => {
          console.log("Success CHEA report sent: ");
          let data = {
            status: 1,
            title: "Okay",
            message: "You have submit your report thank you!",
          };
          history.push("/dashboard", { data: data });
        })
        .catch((error) => {
          console.log("Error : ");
          let data = {
            status: 0,
            title: "Error",
            message: "Error made ...",
          };
          history.push("/dashboard", { data: data });
        });
    } else {
      // when it is a report submitted
      newCheaDecision.append(
        "constant_CHEADecisionAffectationExpert ",
        values.theOverAllFeedBack
      );
      newCheaDecision.append(
        "status_applicationCHEADecison",
        statusCheckboxSelect
      );
      CHEAService.submitReportCHEA(
        idAffectation,
        newCheaDecision,
        localStorage.getItem("access"),
        userApplicationEmail,
        titleProgram,
        userApplicationUserName,
        universityName
      )
        .then((response) => {
          console.log("Success CHEA report sent: ");
          let data = {
            status: 1,
            title: "Okay",
            message: "You have submit your report thank you!",
          };
          history.push("/dashboard", { data: data });
        })
        .catch((error) => {
          console.log("Error : ");
          let data = {
            status: 0,
            title: "Error",
            message: "Error made ...",
          };
          history.push("/dashboard", { data: data });
        });
    }
  };

  const handleConfirm = (e) => {
    // console.log("event ", e);
    e.preventDefault();
    handleSubmit();
    setShowPopup(false);
  };

  const handleCancel = () => {
    setShowPopup(false);
  };

  const stopEventForm = (event) => {
    event.preventDefault();
  };

  const changeStatus = (status) => {
    setStatusCheckboxSelect(status.target.value);
  };

  const {
    values,
    errors,
    touched,
    isSubmitting,
    setFieldValue,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      designation_application: "",
      title_program_application: initialTitle_program_application,
      university_id_application: initialUniversity_id_application,

      evidence_for_accreditation_at_national_level: "",
      confirmation_letter_national_agency: "",
      involvment_of_the_relevant_national_bodies: "",
      action: "",
      inputValue: "",

      otherInfor_application: initialOtherInfos,
      level_id_application: initialLevel_id_application,

      otherInfor_application: initialOtherInfos,
      level_id_application: initialLevel_id_application,
      theOverAllFeedBack: "",
    },
    validationSchema: CHEASchemasValidation,
    onSubmit,
  });

  // console.log("errors ", errors);

  let levelGot = "";
  levelData.map((levelDB) => {
    if (levelDB.id_level === level) {
      levelGot = levelDB.designation_level;
    }
  });

  useEffect(() => {
    let url = `${process.env.REACT_APP_API_URL}/api/user/list/expert`;
    UserService.getExpert(url)
      .then((response) => {
        // console.log("response");
        // console.log(response.data);
        setExpertList(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 401) {
          // Redirect to the login page
          history.push("/login");
        }
      });
  }, []);

  let listSelectedExpert = [];

  if (typeOperation !== "statusEdit") {
    if (expertList == null) {
      expertList.map((expert) => {
        listSelectedExpert.push(Number(expert.id_expert_applicationExpert));
      });
    }
  }

  function isInArray(value, array) {
    return array.indexOf(value) > -1;
  }

  let numberOfExpert = expertList.length;
  let hiddenExpert = 0;

  useEffect(() => {
    // getThematic
    let url = `${process.env.REACT_APP_API_URL}/api/thematicArea/`;
    UserService.getThematic(url)
      .then((response) => {
        // console.log("Thematic ");
        // console.log(response.data);
        setThematic(response.data);
      })
      .catch((errors) => {
        console.log("errors");
        console.log(errors);
        if (errors.response.status === 401) {
          // Redirect to the login page
          history.push("/login");
        }
      });
  }, []);

  const tabs = [
    {
      title: "Documents",
      content: (
        <div className="flex flex-wrap pt-4">
          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label
              className="block capitalize text-md tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
              htmlFor="grid-city"
            >
              Evidence for accreditation at national level
              <span className="text-red-600">*</span>
            </label>
            {initialevidence_for_accreditation_at_national_level ? (
              <a
                rel="noreferrer"
                href={`${process.env.REACT_APP_API_URL}/documents/${initialevidence_for_accreditation_at_national_level}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>
              </a>
            ) : (
              ""
            )}
          </div>
          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label
              className="block text-md capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
              htmlFor="grid-city"
            >
              Confirmation letter from the national agency
              <span className="text-red-600">*</span>
            </label>
            {initialconfirmation_letter_national_agency ? (
              <a
                rel="noreferrer"
                href={`${process.env.REACT_APP_API_URL}/documents/${initialconfirmation_letter_national_agency}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>
              </a>
            ) : (
              ""
            )}
          </div>
          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md">
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
              htmlFor="grid-city"
            >
              Involvment of the relevant national bodies
              <span className="text-red-600">*</span>
            </label>
            {initialinvolvment_of_the_relevant_national_bodies ? (
              <a
                rel="noreferrer"
                href={`${process.env.REACT_APP_API_URL}/documents/${initialinvolvment_of_the_relevant_national_bodies}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>
              </a>
            ) : (
              ""
            )}
          </div>

          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md pt-3">
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
              htmlFor="grid-city"
            >
              Curriculum development<span className="text-red-600">*</span>
            </label>
            {initialcurriculum_development ? (
              <a
                rel="noreferrer"
                href={`${process.env.REACT_APP_API_URL}/documents/${initialcurriculum_development}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>
              </a>
            ) : (
              ""
            )}
          </div>
          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md pt-3 ">
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
              htmlFor="grid-city"
            >
              Postgraduate supervision guidelines
              <span className="text-red-600">*</span>
            </label>
            {initialpostgraduate_supervision_guidelines ? (
              <a
                rel="noreferrer"
                href={`${process.env.REACT_APP_API_URL}/documents/${initialpostgraduate_supervision_guidelines}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>
              </a>
            ) : (
              ""
            )}
          </div>
          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md pt-3">
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
              htmlFor="grid-city"
            >
              Quality Assurance policy<span className="text-red-600">*</span>
            </label>
            {initialquality_assurence_policy ? (
              <a
                rel="noreferrer"
                href={`${process.env.REACT_APP_API_URL}/documents/${initialquality_assurence_policy}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>
              </a>
            ) : (
              ""
            )}
          </div>

          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md pt-3 ">
            <label
              className="block capitalize text-md tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
              htmlFor="grid-city"
            >
              Credit transfer policy<span className="text-gray-600">*</span>
            </label>
            {initialcredit_transfer_policy ? (
              <a
                rel="noreferrer"
                href={`${process.env.REACT_APP_API_URL}/documents/${initialcredit_transfer_policy}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>
              </a>
            ) : (
              ""
            )}
          </div>
          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md pt-3 ">
            <label
              className="block  capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
              htmlFor="grid-city"
            >
              Summary of staff capacity<span className="text-red-600">*</span>
            </label>
            {initialsummary_of_staff_capacity ? (
              <a
                rel="noreferrer"
                href={`${process.env.REACT_APP_API_URL}/documents/${initialsummary_of_staff_capacity}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>
              </a>
            ) : (
              ""
            )}
          </div>
          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md pt-3 ">
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
              htmlFor="grid-city"
            >
              Inventory of facilities<span className="text-red-600">*</span>
            </label>
            {initialinventory_of_facilities ? (
              <a
                rel="noreferrer"
                href={`${process.env.REACT_APP_API_URL}/documents/${initialinventory_of_facilities}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>
              </a>
            ) : (
              ""
            )}
          </div>

          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md pt-3 ">
            <label
              className="block capitalize text-md tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
              htmlFor="grid-city"
            >
              Annual survey report<span className="text-red-600">*</span>
            </label>
            {initialannual_survey_report ? (
              <a
                rel="noreferrer"
                href={`${process.env.REACT_APP_API_URL}/documents/${initialannual_survey_report}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>
              </a>
            ) : (
              ""
            )}
          </div>
          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md pt-3 ">
            <label
              className="block text-md capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
              htmlFor="grid-city"
            >
              Tracer study report<span className="text-gray-600">*</span>
            </label>
            {initialtracer_study_report ? (
              <a
                rel="noreferrer"
                href={`${process.env.REACT_APP_API_URL}/documents/${initialtracer_study_report}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>
              </a>
            ) : (
              ""
            )}
          </div>
          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md pt-3 ">
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
              htmlFor="grid-city"
            >
              Examination policy<span className="text-red-600">*</span>
            </label>
            {initialexamination_policy ? (
              <a
                rel="noreferrer"
                href={`${process.env.REACT_APP_API_URL}/documents/${initialexamination_policy}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>
              </a>
            ) : (
              ""
            )}
          </div>

          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md pt-3">
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
              htmlFor="grid-city"
            >
              Admission Policy<span className="text-red-600">*</span>
            </label>
            {initialadmission_policy ? (
              <a
                rel="noreferrer"
                href={`${process.env.REACT_APP_API_URL}/documents/${initialadmission_policy}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>
              </a>
            ) : (
              ""
            )}
          </div>
          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md pt-3 ">
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
              htmlFor="grid-city"
            >
              Program review report<span className="text-red-600">*</span>
            </label>
            {initialprogram_review_report ? (
              <a
                rel="noreferrer"
                href={`${process.env.REACT_APP_API_URL}/documents/${initialprogram_review_report}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>
              </a>
            ) : (
              ""
            )}
          </div>
          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md pt-3 ">
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
              htmlFor="grid-city"
            >
              Other not specified<span className="text-gray-600">*</span>
            </label>
            {initialother_not_specified ? (
              <a
                rel="noreferrer"
                href={`${process.env.REACT_APP_API_URL}/documents/${initialother_not_specified}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>
              </a>
            ) : (
              ""
            )}
          </div>
        </div>
      ),
    }, // end documents content

    {
      title: preliminaryAssessmentAssessorsReviewLastDecion(
        newDictionnaryExpertReview,
        numberSubmissionExpertReview
      ).point1[0].bigHeader,
      content: (
        <div>
          <TabContent
            content={
              preliminaryAssessmentAssessorsReviewLastDecion(
                newDictionnaryExpertReview,
                numberSubmissionExpertReview
              ).point1[0]
            }
          />
        </div>
      ),
    },
    {
      title: preliminaryAssessmentForDeskReviewVisualization(
        JSON.parse(dataSent)
      ).point1[0].bigHeader,
      content: (
        <div>
          <TabContent
            content={
              preliminaryAssessmentForDeskReviewVisualization(
                JSON.parse(dataSent)
              ).point1[0]
            }
          />
        </div>
      ),
    },
    {
      title: siteVisiteInformationDataInputPreviewVizualization(
        newDictionnarySiteVisite,
        numberSubmission
      ).point1[0].bigHeader,
      content: (
        <TabContentCHEA
          content={
            siteVisiteInformationDataInputPreviewVizualization(
              newDictionnarySiteVisite,
              numberSubmission
            ).point1[0]
          }
          numberSubmission={numberSubmission}
        />
      ),
    },
  ];

  return (
    <div>
      <div className="w-full p-8">
        {disableSaveBtn && <Loading />}

        {history.location.state === null ||
        history.location.state === undefined ? (
          ""
        ) : (
          <>
            {!(history.location.state.data == null) ? (
              <>
                {history.location.state.data.status === 1 ? (
                  <Success
                    delay="5000"
                    valueFromParent={history.location.state.data}
                  />
                ) : (
                  <Error
                    delay="7000"
                    valueFromParent={history.location.state.data}
                  />
                )}
              </>
            ) : (
              <></>
            )}
          </>
        )}
        <form onSubmit={(event) => stopEventForm(event)} autoComplete="off">
          <div className="grid grid-cols-3">
            <div className="mb-4 text-md grid grid-cols-1">
              <div className="w-1/2 px-3 mb-6 md:mb-0">
                <label
                  className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-2"
                  htmlFor="grid-first-name"
                >
                  Title of the Programme :
                </label>
                <label className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2">
                  {values.title_program_application}
                </label>
              </div>
              <div className="w-1/2 px-3 mb-6 md:mb-0 text-md ">
                <label
                  className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-2"
                  htmlFor="grid-first-name"
                >
                  Level :
                </label>
                <div className="relative">
                  <label
                    className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2"
                    htmlFor="grid-first-name"
                  >
                    {levelGot}
                  </label>
                </div>
              </div>
            </div>
            <div className="mb-4 text-md grid grid-cols-1">
              <div className="w-full px-3 mb-4 text-md ">
                <label
                  className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-2"
                  htmlFor="grid-last-name"
                >
                  Other Informations :
                </label>
                <label
                  className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2"
                  htmlFor="grid-last-name"
                >
                  {values.otherInfor_application}
                </label>
              </div>

              <div className="w-full text-md px-3 mb-4">
                <label
                  className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-2"
                  htmlFor="grid-password"
                >
                  University :
                </label>
                <label
                  className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2"
                  htmlFor="grid-password"
                >
                  {universityName}
                </label>
              </div>
            </div>
            <div className="w-full px-3 mb-4 text-md ">
              <label
                className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2"
                htmlFor="grid-last-name"
              >
                List Experts :
              </label>

              {
                expertList.map((expert) => {
                  let status = false;
                  let showOrHideExpert = "";
                  if (isInArray(Number(expert.id), dataOfAddedExpert)) {
                    status = true;
                  } else {
                    status = false;
                  }
                  let expertQualification = expert.thematicArea;

                  if (
                    isInArray(
                      initialstatus_thematicArea_application,
                      expertQualification.split(",")
                    )
                  ) {
                    showOrHideExpert = "*";
                  } else {
                    showOrHideExpert = "hidden";
                    hiddenExpert = hiddenExpert + 1;
                  }

                  if (hiddenExpert === numberOfExpert) {
                    return (
                      <span className="text-red-500">
                        {" "}
                        No expert found for that application in: "{" "}
                        {thematic.map((area) =>
                          initialstatus_thematicArea_application ===
                          area.id_thematicsArea
                            ? area.designation_thematicsArea
                            : ""
                        )}
                        "
                      </span>
                    );
                  } else {
                    return (
                      <div
                        className={`flex items-center mb-2 ${showOrHideExpert}`}
                      >
                        <label
                          for="default-checkbox"
                          className="ml-2 
                                                                 text-md 
                                                                 font-medium 
                                                                 dark:text-[var(--color-dark-font)] 
                                                                 text-[var(--color-light-font)]"
                        >
                          {expert.name}{" "}
                          {status ? (
                            <span className="text-green-600">
                              {" "}
                              - Done Site Visit {showOrHideExpert}
                            </span>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                    );
                  }
                })
                //   })
              }
            </div>
          </div>
          <div className="mb-3 border border-gray-300">
            <div className="md:mb-0 text-md lg:mb-2">
              <label
                className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2"
                htmlFor="grid-last-name"
              >
                Comment on the decision <span className="text-red-600">*</span>
              </label>

              <textarea
                className="appearance-none block w-full dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] border border-gray-200 rounded py-3 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="theOverAllFeedBack"
                type="text"
                placeholder="Feedback on the application"
                name="theOverAllFeedBack"
                value={values.theOverAllFeedBack}
                onChange={handleChange}
                onBlur={handleBlur}
                rows={4}
              ></textarea>
              <p className="text-red-500 text-md italic">
                {errors.theOverAllFeedBack}
              </p>
            </div>
            <>
              <div className="mt-2" onChange={(e) => changeStatus(e)}>
                <div className="mb-[0.125rem] mr-4 inline-block min-h-[1.5rem] pl-[1.5rem]">
                  <input
                    className="relative 
                      float-left 
                      -ml-[1.5rem] 
                      mr-1 mt-0.5 
                      h-5 w-5  
                      rounded-full 
                      border-2 
                      border-solid 
                      border-blue-300 
                      before:pointer-events-none 
                      before:absolute 
                      before:h-4 
                      before:w-4 
                      before:scale-0 
                      before:rounded-full 
                      before:bg-transparent 
                      before:opacity-0 
                      before:shadow-[0px_0px_0px_13px_transparent] 
                      before:content-[''] 
                      after:absolute after:z-[1] 
                      after:block after:h-4 
                      after:w-4 after:rounded-full 
                      after:content-[''] 
                      checked:border-primary 
                      checked:before:opacity-[0.16] 
                      checked:after:absolute 
                      checked:after:left-1/2 
                      checked:after:top-1/2 
                      checked:after:h-[0.625rem] 
                      checked:after:w-[0.625rem] 
                      checked:after:rounded-full 
                      checked:after:border-primary 
                      checked:after:bg-primary 
                      checked:after:content-[''] 
                      checked:after:[transform:translate(-50%,-50%)] 
                      hover:cursor-pointer hover:before:opacity-[0.04] 
                      hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] 
                      focus:shadow-none 
                      focus:outline-none 
                      focus:ring-0 
                      focus:before:scale-100 
                      focus:before:opacity-[0.12] 
                      focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] 
                      focus:before:transition-[box-shadow_0.2s,transform_0.2s] 
                      checked:focus:border-primary 
                      checked:focus:before:scale-100 
                      checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] 
                      checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] 
                      dark:border-neutral-600 
                      dark:checked:border-primary 
                      dark:checked:after:border-primary 
                      dark:checked:after:bg-primary 
                      dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] 
                      dark:checked:focus:border-primary 
                      dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]"
                    type="radio"
                    name="statusCheckboxSelect"
                    id="inlineRadio1"
                    value={"3"}
                    checked={statusCheckboxSelect === "3"}
                  />
                  <label
                    className="mt-px inline-block pl-[0.15rem] hover:cursor-pointer"
                    htmlFor="inlineRadio1"
                  >
                    <span className="text-red-600">Deferred</span>
                  </label>
                </div>

                <div className="mb-[0.125rem] mr-4 inline-block min-h-[1.5rem] pl-[1.5rem]">
                  <input
                    className="relative 
                  float-left 
                  -ml-[1.5rem] 
                  mr-1 mt-0.5 
                  h-5 w-5  
                  rounded-full 
                  border-2 
                  border-solid 
                  border-blue-300 
                  before:pointer-events-none 
                  before:absolute 
                  before:h-4 
                  before:w-4 
                  before:scale-0 
                  before:rounded-full 
                  before:bg-transparent 
                  before:opacity-0 
                  before:shadow-[0px_0px_0px_13px_transparent] 
                  before:content-[''] 
                  after:absolute after:z-[1] 
                  after:block after:h-4 
                  after:w-4 after:rounded-full 
                  after:content-[''] 
                  checked:border-primary 
                  checked:before:opacity-[0.16] 
                  checked:after:absolute 
                  checked:after:left-1/2 
                  checked:after:top-1/2 
                  checked:after:h-[0.625rem] 
                  checked:after:w-[0.625rem] 
                  checked:after:rounded-full 
                  checked:after:border-primary 
                  checked:after:bg-primary 
                  checked:after:content-[''] 
                  checked:after:[transform:translate(-50%,-50%)] 
                  hover:cursor-pointer hover:before:opacity-[0.04] 
                  hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] 
                  focus:shadow-none 
                  focus:outline-none 
                  focus:ring-0 
                  focus:before:scale-100 
                  focus:before:opacity-[0.12] 
                  focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] 
                  focus:before:transition-[box-shadow_0.2s,transform_0.2s] 
                  checked:focus:border-primary 
                  checked:focus:before:scale-100 
                  checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] 
                  checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] 
                  dark:border-blue-600 
                  dark:checked:border-primary 
                  dark:checked:after:border-primary 
                  dark:checked:after:bg-primary 
                  dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] 
                  dark:checked:focus:border-primary 
                  dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]"
                    type="radio"
                    name="statusCheckboxSelect"
                    id="inlineRadio2"
                    value={"4"}
                    checked={statusCheckboxSelect === "4"}
                  />
                  <label
                    className="mt-px inline-block pl-[0.15rem] hover:cursor-pointer"
                    htmlFor="inlineRadio2"
                  >
                    <span className="text-green-600">Acceptable</span>
                  </label>
                </div>
              </div>
            </>
          </div>

          <TabCHEA tabs={tabs} />
          <div className="w-full sm:w-full md:w-full lg:w-full flex flex-col md:flex-row md:grid md:grid-cols-3 border border-gray-300 p-3">
            <span className="text-md font-semibold">
              Site visite ({numberSubmission})
            </span>
            <span className="text-md font-semibold">
              Score: {totalGotSiteVisite} over {numberSubmission * 805}
            </span>
            <span
              className={`${
                scoreSiteVisite > 70 ? "text-green-600" : "text-red-600"
              } text-md font-semibold`}
            >
              Percent: {scoreSiteVisite}
            </span>
          </div>
          <br />
          <div className="w-full sm:w-full md:w-full lg:w-full flex flex-col md:flex-row md:grid md:grid-cols-3 border border-gray-300 p-3">
            <span className="text-md font-semibold">
              Expert review ({numberSubmissionExpertReview})
            </span>
            <span className="text-md font-semibold">
              Score: {numberGotTotalExpertReview} over{" "}
              {numberSubmissionExpertReview * 145}
            </span>
            <span
              className={`${
                scoreFirstReview > 70 ? "text-green-600" : "text-red-600"
              } text-md font-semibold`}
            >
              Percent: {scoreFirstReview.toFixed(2)}
            </span>
          </div>

          <div className="text-center p-5 justify-between">
            <button
              name="action"
              type="submit"
              value={"accreditation"}
              className="dark:bg-gray-800
              bg-gray-500 
                        dark:text-[var(--color-dark-fontButton)] 
                        text-[var(--color-light-fontButton)] 
                        mr-2 
                        w-[120px]  py-3 rounded-md font-medium"
              onClick={() => {
                setFormValues(values); // Store form values
                setShowPopup(true);
                setclickedButton("accreditation");
                setColorFont("green");
                setConfirmationText(
                  "Are you sure you want to Submit report To the CHEA Commitee?"
                );
              }}
            >
              Submit Report
            </button>
            <button
              name="action"
              type="submit"
              value={"rejection"}
              className="dark:bg-red-800 hidden
                        bg-red-500 
                        dark:text-[var(--color-dark-fontButton)] 
                        text-[var(--color-light-fontButton)]  
                        w-[120px]  py-3 rounded-md font-medium"
              onClick={() => {
                setFormValues(values); // Store form values
                setShowPopup(true);
                setColorFont("red");
                setclickedButton("rejection");
                setConfirmationText(
                  "Are you sure you want to Reject this programme?"
                );
              }}
            >
              Rejection
            </button>
          </div>
        </form>
        {showPopup && (
          <Popup
            message={confirmationText}
            onConfirm={(e) => handleConfirm(e)}
            onCancel={handleCancel}
            colorFont={colorFont}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { login })(CHEAAddReport);
