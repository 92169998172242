import React, { useState } from "react";

const InputNumber1 = (props) => {
  let newNumber = 0;
  if (props.ckecked && props.ckecked !== null) {
    newNumber = props.ckecked;
  }
  return (
    <div className="">
      <div className="mb-[0.125rem] mr-4 inline-block min-h-[1.5rem] pl-[1.5rem]">
        <input
          type="hidden"
          name={`${props.name}`}
          value={`${newNumber}`}
          id={`${props.name}`}
        />

        <label
          className="mt-px inline-block pl-[0.15rem] hover:cursor-pointer"
          for="inlineRadio2"
        >
          {" "}
          Score({props.numberSubmission}): 
        </label>
        <label
          className="mt-px inline-block pl-[0.15rem] hover:cursor-pointer"
          for="inlineRadio2"
        >
          {" "}
          {Number(newNumber) < Number(props.numberSubmission*5)/2 ? <span className="text-red-600 font-bold text-lg">{newNumber}/{props.numberSubmission*5}</span> : <span className="text-green-600 font-bold text-lg">{newNumber}/{props.numberSubmission*5}</span>} 
        </label>
      </div>
    </div>
  );
};

export default InputNumber1;
