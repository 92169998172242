import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import ApplicationService from "../../../services/ApplicationService";
import { connect } from "react-redux";
import { login } from "../../actions/auth";
import { ApplicationSchemasValidation } from "../../alerts/Validations";
import { useFormik, Field } from "formik";
import Success from "../../alerts/Success";
import Error from "../../alerts/Error";
import UserService from "../../../services/UserService";
import ExpertReviewService from "../../../services/ExpertReviewService";
import Loading from "../../eventsHandler/Loading";
import TabContent from "../../smallComponents/TabContent";
import { FaFilePdf } from "react-icons/fa";
import Tab from "../../smallComponents/Tab";
import { preliminaryAssessment } from "../ConstantsAssessements";
import { preliminaryAssessmentAssessorsReview } from "../ConstantsAssessementsForExpertReview";
import Popup from "../../alerts/Popup";
import SignatureCapture from "../../alerts/SignatureCapture";

const ExpertReviewAdd = (props) => {
  let initialTitle_program_application = "";
  let initialUniversity_id_application = "";
  let initialLevel_id_application = "";
  let initialOtherInfos = "";
  let initialevidence_for_accreditation_at_national_level = "";
  let initialconfirmation_letter_national_agency = "";
  let initialinvolvment_of_the_relevant_national_bodies = "";
  let initialcurriculum_development = "";
  let initialpostgraduate_supervision_guidelines = "";
  let initialquality_assurence_policy = "";
  let initialcredit_transfer_policy = "";
  let initialsummary_of_staff_capacity = "";
  let initialinventory_of_facilities = "";
  let initialannual_survey_report = "";
  let initialtracer_study_report = "";
  let initialexamination_policy = "";
  let initialadmission_policy = "";
  let initialprogram_review_report = "";
  let initialother_not_specified = "";
  let initialDocument2_application = "";
  let initialDocument3_application = "";
  let initialApplicationID = "";
  let typeOperation = "";
  let initialstatus_application = "";
  let initialstatus_thematicArea_application = "";
  let initialuser_created_application = "";
  let idNotificationSent = "";

  let initialprogramme_file = "";
  let initialevidence_of_payment = "";
  let initialmarket_survey_report = "";
  let initialevidence_of_participation_of_stackHolder = "";
  let initialcurriculum_development_and_guidelines = "";
  let initialconfirmation_letter_of_key_stackHolder = "";
  let initialauditReport_report = "";
  let application = [];

  if (props.location.data && props.location.data.currentApplication) {
    typeOperation = props.location.data.typeOperation;

    initialstatus_application =
      props.location.data.currentApplication.status_application;
    initialApplicationID =
      props.location.data.currentApplication.id_application;
    initialevidence_for_accreditation_at_national_level =
      props.location.data.currentApplication
        .evidence_for_accreditation_at_national_level;
    initialconfirmation_letter_national_agency =
      props.location.data.currentApplication
        .confirmation_letter_national_agency;
    initialinvolvment_of_the_relevant_national_bodies =
      props.location.data.currentApplication
        .involvment_of_the_relevant_national_bodies;
    initialcurriculum_development =
      props.location.data.currentApplication.curriculum_development;
    initialpostgraduate_supervision_guidelines =
      props.location.data.currentApplication
        .postgraduate_supervision_guidelines;
    initialquality_assurence_policy =
      props.location.data.currentApplication.quality_assurence_policy;
    initialcredit_transfer_policy =
      props.location.data.currentApplication.credit_transfer_policy;
    initialsummary_of_staff_capacity =
      props.location.data.currentApplication.summary_of_staff_capacity;
    initialinventory_of_facilities =
      props.location.data.currentApplication.inventory_of_facilities;
    initialannual_survey_report =
      props.location.data.currentApplication.annual_survey_report;
    initialtracer_study_report =
      props.location.data.currentApplication.tracer_study_report;
    initialexamination_policy =
      props.location.data.currentApplication.examination_policy;
    initialadmission_policy =
      props.location.data.currentApplication.admission_policy;
    initialprogram_review_report =
      props.location.data.currentApplication.program_review_report;
    initialother_not_specified =
      props.location.data.currentApplication.other_not_specified;
    initialTitle_program_application =
      props.location.data.currentApplication.title_program_application;
    initialUniversity_id_application =
      props.location.data.currentApplication.university_id_application;
    initialLevel_id_application =
      props.location.data.currentApplication.level_id_application;

    initialOtherInfos =
      props.location.data.currentApplication.otherInfor_application;
    initialuser_created_application =
      props.location.data.currentApplication.user_created_application;
    initialstatus_thematicArea_application =
      props.location.data.currentApplication.thematicArea_application;

    initialprogramme_file =
      props.location.data.currentApplication.programme_file;
    initialevidence_of_payment =
      props.location.data.currentApplication.evidence_of_payment;
    initialmarket_survey_report =
      props.location.data.currentApplication.market_survey_report;
    initialevidence_of_participation_of_stackHolder =
      props.location.data.currentApplication
        .evidence_of_participation_of_stackHolder;
    initialcurriculum_development_and_guidelines =
      props.location.data.currentApplication
        .curriculum_development_and_guidelines;
    initialconfirmation_letter_of_key_stackHolder =
      props.location.data.currentApplication
        .confirmation_letter_of_key_stackHolder;
    initialauditReport_report =
      props.location.data.currentApplication.auditReport_report;

    application = props.location.data.currentApplication;

    idNotificationSent = props.location.data.notifID;
  } else if (props.location.data) {
    idNotificationSent = props.location.data.notifID;
  }

  // console.log(props.location.data.currentApplication)

  const [level, setLevel] = useState(initialLevel_id_application);

  const [removeObjectionReview, setRemoveObjectionReview] = useState("");
  const [hiddenTextAreas, setHiddenTextAreas] = useState("hidden");
  const [hiddenShowAcceptanceComment, setHiddenShowAcceptanceComment] =
    useState("");

  const [valueRejection, setValueRejection] = useState(1);

  const [rejectionReason, setRejectionReason] = useState("");
  const [acceptanceReason, setAcceptanceReason] = useState("");

  let levelData = JSON.parse(localStorage.getItem("levelData"));

  const [buttonLabel, setButtonLabel] = useState("");
  const history = useHistory();
  const [userInfo, setUserInfo] = useState("");
  const [thematic, setThematic] = useState([]);
  const [disableSaveBtn, setDisableSaveBtn] = useState(false);
  const [expertList, setExpertList] = useState([]);

  const [formData, setFormData] = useState({});
  const [capturedData, setCapturedData] = useState(null);
 

  const handleInputChangeNumber = (name, value) => {
    // const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleInputChangeTextComment = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  // console.log("setCapturedData", capturedData); 
  const onSubmit = () => { 
    
    let userInfo = localStorage.getItem("userInfo");
    const newApplication = new FormData();
    if (capturedData instanceof File) { 
      newApplication.append("signature_imageExpertReview", capturedData);
    }
    //  if (capturedData === '' || capturedData === null) { 
    //   alert('Please provide a signature and submit your review');
    //   return;
    // } 

    setDisableSaveBtn(true);

    // retrieve the applicant email
    let userCreatedApplication = JSON.parse(userInfo);

    if (valueRejection > 0) {
      newApplication.append("constants_expertReview", acceptanceReason);
    } else {
      newApplication.append("constants_expertReview", rejectionReason);
    }

    newApplication.append(
      "id_user_group_expertReview",
      initialstatus_thematicArea_application
    );

    newApplication.append(
      "title_program_application",
      initialTitle_program_application
    );
    newApplication.append("id_provenance_expertReview", initialApplicationID);
    newApplication.append("type_expertReview", "Expert review");
    newApplication.append("status_expertReview", valueRejection);
    newApplication.append(
      "id_validationApplication_expertReview_id",
      userCreatedApplication.id
    );
    const allValues = { ...formData };
    console.log(formData);
    newApplication.append("scoreFromExpert", JSON.stringify(allValues));
    console.log(newApplication);

    ExpertReviewService.createExpertReview(
      newApplication,
      localStorage.getItem("access"),
      initialTitle_program_application,
      userCreatedApplication.id
    )
      .then((response) => {
        const newApplicationExpert = new FormData();
        newApplicationExpert.append("status_applicationExpert", 1);
        // update status for the notification also

        let data = {
          status: 1,
          title: "Okay",
          message: "Saved with Success ... Thank you for your feedback.",
        };
        history.push("/dashboard", { data: data });
      })
      .catch((error) => {
        // console.log(error);
        let data = {
          status: 0,
          title: "Error",
          message: "An Error happened",
        };
        history.push(`/expertReview/${initialApplicationID}`, {
          data: data,
        });
        setDisableSaveBtn(false);
      })
      .finally(() => {
        setDisableSaveBtn(false);
      });
  };

  let expertListId = JSON.parse(localStorage.getItem("ExpertApplication"));

  useEffect(() => {
    let url = `${process.env.REACT_APP_API_URL}/api/user/list/expert`;
    UserService.getExpert(url)
      .then((response) => {
        console.log("response");
        console.log(response.data);
        setExpertList(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 401) {
          // Redirect to the login page
          history.push("/login");
        }
      });
  }, []);

  const [showPopup, setShowPopup] = useState(false);
  const [from, setfrom] = useState("");
  const [clickedButton, setclickedButton] = useState("");

  const [colorFont, setColorFont] = useState("");
  const [confirmationText, setConfirmationText] = useState("");

  let allUniveData = JSON.parse(localStorage.getItem("universityData"));
  let universityName = "";
  allUniveData.map((dataUniv) => {
    if (dataUniv.id === Number(initialUniversity_id_application)) {
      universityName = dataUniv.university_name;
    }
  });

  let levelGot = "";
  levelData.map((levelDB) => {
    if (levelDB.id_level === level) {
      levelGot = levelDB.designation_level;
    }
  });

  let listSelectedExpert = [];

  if (typeOperation !== "statusEdit") {
    if (expertList == null) {
      expertList.map((expert) => {
        listSelectedExpert.push(Number(expert.id_expert_applicationExpert));
      });
    }
  }

  const disableObjection = (event) => {
    if (event.target.checked) {
      setHiddenTextAreas("hidden");
      setRemoveObjectionReview(false);
      setHiddenShowAcceptanceComment("");
      setValueRejection(1);
    } else {
      setRemoveObjectionReview(true);
      setHiddenTextAreas("");
      setHiddenShowAcceptanceComment("hidden");
      setValueRejection(0);
    }
  };

  const buttonClicked = (event) => {
    event.preventDefault();
  };

  const handleConfirm = (e) => {
    e.preventDefault(); 
    onSubmit(); 
    setShowPopup(false);
  };

  const handleCancel = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    // getThematic
    let url = `${process.env.REACT_APP_API_URL}/api/thematicArea/`;
    UserService.getThematic(url)
      .then((response) => {
        setThematic(response.data);
      })
      .catch((errors) => {
        console.log("errors");
        console.log(errors);
        if (errors.response.status === 401) {
          // Redirect to the login page
          history.push("/login");
        }
      });
  }, []);

  const tabs = [
    {
      title: "Document Submitted",
      content: (
        <div>
          <table
            className=" 
                     w-full 
                     tgray-700
                     text-left   
                     capitalize             
                     text-gray-700 
                     dark:text-gray-400"
          >
            <thead>
              <tr className="even:bg-gray-50 odd:bg-gray-100 even:text-black odd:text-gray-700">
                {application.auditReport_report ? (
                  <th scope="col">Audit report of the programme</th>
                ) : (
                  ""
                )}
                {application.confirmation_letter_of_key_stackHolder ? (
                  <th scope="col">confirmation letter from stake Holder</th>
                ) : (
                  ""
                )}
                {application.curriculum_development_and_guidelines ? (
                  <th scope="col">curriculum development and guidelines</th>
                ) : (
                  ""
                )}
              </tr>
              <tr className="even:bg-gray-50 odd:bg-gray-100 even:text-black odd:text-black-100">
                {application.auditReport_report ? (
                  <td>
                    <a
                      href={`${process.env.REACT_APP_API_URL}/documents/${application.auditReport_report}`}
                      target="_blank"
                    >
                      {" "}
                      <FaFilePdf color="green" size={25} />
                    </a>
                  </td>
                ) : (
                  ""
                )}
                {application.confirmation_letter_of_key_stackHolder ? (
                  <td>
                    <a
                      href={`${process.env.REACT_APP_API_URL}/documents/${application.confirmation_letter_of_key_stackHolder}`}
                      target="_blank"
                    >
                      {" "}
                      <FaFilePdf color="green" size={25} />
                    </a>
                  </td>
                ) : (
                  ""
                )}
                {application.curriculum_development_and_guidelines ? (
                  <td>
                    <a
                      href={`${process.env.REACT_APP_API_URL}/documents/${application.curriculum_development_and_guidelines}`}
                      target="_blank"
                    >
                      {" "}
                      <FaFilePdf color="green" size={25} />
                    </a>
                  </td>
                ) : (
                  ""
                )}
              </tr>

              <tr className="even:bg-gray-50 odd:bg-gray-100 even:text-black odd:text-gray-700">
                {application.evidence_of_participation_of_stackHolder ? (
                  <th scope="col">evidence of participation of stack Holder</th>
                ) : (
                  ""
                )}
                {application.market_survey_report ? (
                  <th scope="col">market survey report</th>
                ) : (
                  ""
                )}
                {application.programme_file ? (
                  <th scope="col" className="text-green-600">
                    programme file
                  </th>
                ) : (
                  ""
                )}
              </tr>
              <tr className="even:bg-gray-50 odd:bg-gray-100 even:text-black odd:text-black-100">
                {application.evidence_of_participation_of_stackHolder ? (
                  <td>
                    <a
                      href={`${process.env.REACT_APP_API_URL}/documents/${application.evidence_of_participation_of_stackHolder}`}
                      target="_blank"
                    >
                      {" "}
                      <FaFilePdf color="green" size={25} />
                    </a>
                  </td>
                ) : (
                  ""
                )}
                {application.market_survey_report ? (
                  <td>
                    <a
                      href={`${process.env.REACT_APP_API_URL}/documents/${application.market_survey_report}`}
                      target="_blank"
                    >
                      {" "}
                      <FaFilePdf color="green" size={25} />
                    </a>
                  </td>
                ) : (
                  ""
                )}
                {application.programme_file ? (
                  <td>
                    <a
                      href={`${process.env.REACT_APP_API_URL}/documents/${application.programme_file}`}
                      target="_blank"
                    >
                      {" "}
                      <FaFilePdf color="green" size={25} />
                    </a>
                  </td>
                ) : (
                  ""
                )}
              </tr>

              <tr className="even:bg-gray-50 odd:bg-gray-100  odd:text-gray-700">
                {application.other_not_specified ? (
                  <th className="" scope="col">
                    others not specified
                  </th>
                ) : (
                  ""
                )}
                {application.evidence_for_accreditation_at_national_level ? (
                  <th>evidence for accreditation at national level</th>
                ) : (
                  ""
                )}
                {application.confirmation_letter_national_agency ? (
                  <th>confirmation letter national agency</th>
                ) : (
                  ""
                )}
              </tr>
              <tr className="even:bg-gray-50 odd:bg-gray-100 even:text-black odd:text-gray-700">
                {application.other_not_specified ? (
                  <td className="">
                    <a
                      href={`${process.env.REACT_APP_API_URL}/documents/${application.other_not_specified}`}
                      target="_blank"
                    >
                      {" "}
                      <FaFilePdf color="green" size={25} />
                    </a>
                  </td>
                ) : (
                  ""
                )}
                {application.evidence_for_accreditation_at_national_level ? (
                  <td>
                    <a
                      href={`${process.env.REACT_APP_API_URL}/documents/${application.evidence_for_accreditation_at_national_level}`}
                      target="_blank"
                    >
                      {" "}
                      <FaFilePdf color="green" size={25} />{" "}
                    </a>
                  </td>
                ) : (
                  ""
                )}
                {application.confirmation_letter_national_agency ? (
                  <td>
                    {" "}
                    <a
                      href={`${process.env.REACT_APP_API_URL}/documents/${application.confirmation_letter_national_agency}`}
                      target="_blank"
                    >
                      {" "}
                      <FaFilePdf color="green" size={25} />
                    </a>
                  </td>
                ) : (
                  ""
                )}
              </tr>
              <tr className="even:bg-gray-50 odd:bg-gray-100 even:text-black odd:text-gray-700">
                {application.involvment_of_the_relevant_national_bodies ? (
                  <th scope="col">
                    involvment of the relevant national bodies
                  </th>
                ) : (
                  ""
                )}
                {application.curriculum_development ? (
                  <th scope="col">curriculum development</th>
                ) : (
                  ""
                )}
                {application.postgraduate_supervision_guidelines ? (
                  <th scope="col">postgraduate supervision guidelines</th>
                ) : (
                  ""
                )}
              </tr>
              <tr className="even:bg-gray-50 odd:bg-gray-100 even:text-black odd:text-gray-700">
                {application.involvment_of_the_relevant_national_bodies ? (
                  <td>
                    <a
                      href={`${process.env.REACT_APP_API_URL}/documents/${application.document2_involvment_of_the_relevant_national_bodiesdetails}`}
                      target="_blank"
                    >
                      {" "}
                      <FaFilePdf color="green" size={25} />
                    </a>
                  </td>
                ) : (
                  ""
                )}
                {application.curriculum_development ? (
                  <td>
                    <a
                      href={`${process.env.REACT_APP_API_URL}/documents/${application.curriculum_development}`}
                      target="_blank"
                    >
                      {" "}
                      <FaFilePdf color="green" size={25} />
                    </a>
                  </td>
                ) : (
                  ""
                )}
                {application.postgraduate_supervision_guidelines ? (
                  <td>
                    <a
                      href={`${process.env.REACT_APP_API_URL}/documents/${application.postgraduate_supervision_guidelines}`}
                      target="_blank"
                    >
                      {" "}
                      <FaFilePdf color="green" size={25} />
                    </a>
                  </td>
                ) : (
                  ""
                )}
              </tr>
              <tr className="even:bg-gray-50 odd:bg-gray-100 even:text-black odd:text-gray-700">
                {application.quality_assurence_policy ? (
                  <th scope="col">Quality Assurance policy</th>
                ) : (
                  ""
                )}
                {application.credit_transfer_policy ? (
                  <th scope="col">credit transfer policy</th>
                ) : (
                  ""
                )}
                {application.summary_of_staff_capacity ? (
                  <th scope="col">summary of staff capacity</th>
                ) : (
                  ""
                )}
              </tr>
              <tr className="even:bg-gray-50 odd:bg-gray-100 even:text-black odd:text-gray-700">
                {application.quality_assurence_policy ? (
                  <td>
                    <a
                      href={`${process.env.REACT_APP_API_URL}/documents/${application.quality_assurence_policy}`}
                      target="_blank"
                    >
                      {" "}
                      <FaFilePdf color="green" size={25} />
                    </a>
                  </td>
                ) : (
                  ""
                )}
                {application.credit_transfer_policy ? (
                  <td>
                    <a
                      href={`${process.env.REACT_APP_API_URL}/documents/${application.credit_transfer_policy}`}
                      target="_blank"
                    >
                      {" "}
                      <FaFilePdf color="green" size={25} />
                    </a>
                  </td>
                ) : (
                  ""
                )}
                {application.summary_of_staff_capacity ? (
                  <td>
                    <a
                      href={`${process.env.REACT_APP_API_URL}/documents/${application.summary_of_staff_capacity}`}
                      target="_blank"
                    >
                      {" "}
                      <FaFilePdf color="green" size={25} />
                    </a>
                  </td>
                ) : (
                  ""
                )}
              </tr>
              <tr className="even:bg-gray-50 odd:bg-gray-100 even:text-black odd:text-gray-700">
                {application.inventory_of_facilities ? (
                  <th scope="col">inventory of facilities</th>
                ) : (
                  ""
                )}
                {application.annual_survey_report ? (
                  <th scope="col">annual survey report</th>
                ) : (
                  ""
                )}
                {application.tracer_study_report ? (
                  <th scope="col">tracer study report</th>
                ) : (
                  ""
                )}
              </tr>
              <tr className="even:bg-gray-50 odd:bg-gray-100 even:text-black odd:text-gray-700">
                {application.inventory_of_facilities ? (
                  <td>
                    <a
                      href={`${process.env.REACT_APP_API_URL}/documents/${application.inventory_of_facilities}`}
                      target="_blank"
                    >
                      {" "}
                      <FaFilePdf color="green" size={25} />
                    </a>
                  </td>
                ) : (
                  ""
                )}
                {application.annual_survey_report ? (
                  <td>
                    <a
                      href={`${process.env.REACT_APP_API_URL}/documents/${application.annual_survey_report}`}
                      target="_blank"
                    >
                      {" "}
                      <FaFilePdf color="green" size={25} />
                    </a>
                  </td>
                ) : (
                  ""
                )}
                {application.tracer_study_report ? (
                  <td>
                    <a
                      href={`${process.env.REACT_APP_API_URL}/documents/${application.tracer_study_report}`}
                      target="_blank"
                    ></a>
                  </td>
                ) : (
                  ""
                )}
              </tr>
              <tr className="even:bg-gray-50 odd:bg-gray-100 even:text-black odd:text-gray-700">
                {application.examination_policy ? (
                  <th scope="col">examination policy</th>
                ) : (
                  ""
                )}
                {application.program_review_report ? (
                  <th scope="col">program review report</th>
                ) : (
                  ""
                )}
                {application.admission_policy ? (
                  <th scope="col">admission policy</th>
                ) : (
                  ""
                )}
              </tr>
              <tr className="even:bg-gray-50 odd:bg-gray-100 even:text-black odd:text-black-100">
                {application.examination_policy ? (
                  <td>
                    <a
                      href={`${process.env.REACT_APP_API_URL}/documents/${application.examination_policy}`}
                      target="_blank"
                    >
                      {" "}
                      <FaFilePdf color="green" size={25} />
                    </a>
                  </td>
                ) : (
                  ""
                )}
                {application.program_review_report ? (
                  <td>
                    <a
                      href={`${process.env.REACT_APP_API_URL}/documents/${application.program_review_report}`}
                      target="_blank"
                    >
                      {" "}
                      <FaFilePdf color="green" size={25} />
                    </a>
                  </td>
                ) : (
                  ""
                )}
                {application.admission_policy ? (
                  <td>
                    <a
                      href={`${process.env.REACT_APP_API_URL}/documents/${application.admission_policy}`}
                      target="_blank"
                    >
                      {" "}
                      <FaFilePdf color="green" size={25} />
                    </a>
                  </td>
                ) : (
                  ""
                )}
              </tr>
              <tr className="even:bg-gray-50 odd:bg-gray-100 even:text-black odd:text-gray-700">
                {application.evidence_of_payment ? (
                  <th
                    scope="col"
                    className="even:bg-gray-50 odd:bg-gray-100 even:text-blue-600 odd:text-gray-700"
                  >
                    Evidence Of payment
                  </th>
                ) : (
                  ""
                )}
                {application.program_review_report ? (
                  <th scope="col"> </th>
                ) : (
                  ""
                )}
                {application.admission_policy ? <th scope="col"> </th> : ""}
              </tr>
              <tr className="even:bg-gray-50 odd:bg-gray-100 even:text-black odd:text-black-100">
                {application.evidence_of_payment ? (
                  <td className="even:bg-gray-50 odd:bg-gray-100 even:text-blue-600 odd:text-blue-700">
                    <a
                      href={`${process.env.REACT_APP_API_URL}/documents/${application.evidence_of_payment}`}
                      target="_blank"
                    >
                      {" "}
                      <FaFilePdf color="green" size={25} />
                    </a>
                  </td>
                ) : (
                  ""
                )}
                {application.program_review_report ? <td></td> : ""}
                {application.admission_policy ? <td></td> : ""}
              </tr>
            </thead>
          </table>
        </div>
      ),
    },
    {
      title: preliminaryAssessmentAssessorsReview(
        handleInputChangeNumber,
        handleInputChangeTextComment
      ).point1[0].bigHeader,
      content: (
        <div>
          <TabContent
            content={
              preliminaryAssessmentAssessorsReview(
                handleInputChangeNumber,
                handleInputChangeTextComment
              ).point1[0]
            }
          />
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="w-full p-4">
        {disableSaveBtn && <Loading />}
        {history.location.state == null ||
        history.location.state === undefined ? (
          ""
        ) : (
          <>
            {!(history.location.state.data == null) ? (
              <>
                {history.location.state.data.status === 1 ? (
                  <Success
                    delay="5000"
                    valueFromParent={history.location.state.data}
                  />
                ) : (
                  <Error
                    delay="7000"
                    valueFromParent={history.location.state.data}
                  />
                )}
              </>
            ) : (
              <></>
            )}
          </>
        )}
        <form autoComplete="off">
          <div className="grid grid-cols-3">
            <div className="mb-4 text-md pt-4 grid grid-cols-1">
              <div className=" md:mb-0">
                <label
                  className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2"
                  htmlFor="grid-first-name"
                >
                  Title of the Programme :
                </label>
                <label className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2">
                  {initialTitle_program_application}
                </label>
              </div>
              <div className=" md:mb-0 text-md ">
                <label
                  className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2"
                  htmlFor="grid-first-name"
                >
                  Level :
                </label>
                <div className="relative">
                  <label
                    className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2"
                    htmlFor="grid-first-name"
                  >
                    {levelGot}
                  </label>
                </div>
              </div>
            </div>
            <div className="mb-4 text-md pt-4 grid grid-cols-1">
              <div className="w-full px-3 mb-4 text-md ">
                <label
                  className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2"
                  htmlFor="grid-last-name"
                >
                  Other Informations :
                </label>
                <label
                  className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2"
                  htmlFor="grid-last-name"
                >
                  {initialOtherInfos}
                </label>
              </div>

              <div className="w-full text-md px-3 mb-4">
                <label
                  className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2"
                  htmlFor="grid-password"
                >
                  University :
                </label>
                <label
                  className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2"
                  htmlFor="grid-password"
                >
                  {universityName}
                </label>
              </div>
            </div>

            <div className="capitalize">
              <div className="w-full px-3 mb-4 text-md ">
                <label
                  className={`block capitalize 
                                          tracking-wide 
                                          dark:text-[var(--color-dark-font)] 
                                          text-[var(--color-light-font)] 
                                          text-md 
                                          ${
                                            hiddenTextAreas
                                              ? "text-red-700"
                                              : "text-green-700"
                                          } 
                                          font-bold mb-2`}
                  htmlFor="grid-last-name"
                >
                  {hiddenTextAreas ? (
                    <span>Uncheck for Defford</span>
                  ) : (
                    <span>Check for Acceptance</span>
                  )}
                </label>

                <div className="flex items-center mb-2">
                  <input
                    id="checkboxExpert"
                    type="checkbox"
                    value={`1`}
                    name="checkboxExpert"
                    defaultChecked={true}
                    onChange={(e) => disableObjection(e)}
                    className="w-4 h-4 
                                                        text-blue-600 
                                                        bg-gray-100 
                                                        border-gray-300 
                                                        rounded 
                                                        focus:ring-blue-500 
                                                        dark:focus:ring-blue-600 
                                                        dark:ring-offset-gray-800 
                                                        focus:ring-2 
                                                        dark:bg-gray-700 
                                                        dark:border-gray-600"
                  />
                  <label
                    for="default-checkbox"
                    className="ml-2 
                                                            text-md 
                                                            font-medium 
                                                            dark:text-[var(--color-dark-font)] 
                                                            text-[var(--color-light-font)]"
                  >
                    {" "}
                    {hiddenTextAreas ? (
                      <span className="text-green-700">Accecptance</span>
                    ) : (
                      <span className="text-red-700">Rejection</span>
                    )}
                  </label>
                </div>
              </div>

              <div
                className={`rejectionReason w-full px-3 mb-4 text-md  ${hiddenShowAcceptanceComment}`}
              >
                <label
                  className="block capitalize 
                                          tracking-wide 
                                          dark:text-[var(--color-dark-font)] 
                                          text-[var(--color-light-font)] 
                                          text-md text-green-700 font-bold mb-2"
                  htmlFor="grid-last-name"
                >
                  Provide the overall comment :
                </label>

                <div className="w-full mb-4 text-md ">
                  <textarea
                    className={`acceptanceReason ${hiddenShowAcceptanceComment}`}
                    name="AcceptanceReason"
                    id="AcceptanceReason"
                    onChange={(e) => setAcceptanceReason(e.target.value)}
                    placeholder="Please provide a comment "
                    cols="30"
                    rows="3"
                  ></textarea>
                </div>
              </div>

              <div
                className={`rejectionReason w-full px-3 mb-4 text-md  ${hiddenTextAreas}`}
              >
                <label
                  className="block capitalize 
                                          tracking-wide 
                                          dark:text-[var(--color-dark-font)] 
                                          text-[var(--color-light-font)] 
                                          text-md text-red-700 font-bold mb-2"
                  htmlFor="grid-last-name"
                >
                  Defford Reason :
                </label>

                <div className="w-full mb-4 text-md ">
                  <textarea
                    className={`rejectionReason ${hiddenTextAreas}`}
                    name="rejectionReason"
                    id="rejectionReason"
                    onChange={(e) => setRejectionReason(e.target.value)}
                    placeholder="Please provide a reason"
                    cols="30"
                    rows="3"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-wrap pt-4">
            <Tab tabs={tabs} submitButton={``} partialSubmission={``} />
          </div>
          {/* <SignatureCapture onDataCapture={setCapturedData} /> */}
          <div className="text-center p-5">
            <button
              onClick={(event) => {
                setShowPopup(true);
                setclickedButton("disableStatus");
                setfrom("disable");
                setColorFont("blue");
                buttonClicked(event);
                setConfirmationText(
                  "Are you sure you want to Submit this review?"
                );
              }}
              type="submit"
              className="dark:bg-[var(--color-dark-buttonBackground)] 
                        bg-[var(--color-light-buttonBackground)] 
                        dark:text-[var(--color-dark-fontButton)] 
                        text-[var(--color-light-fontButton)]  
                        w-[200px]  py-3 rounded-md font-medium"
            >
              <span> {"Submit report"} </span>
            </button>

            {showPopup && (
              <Popup
                message={confirmationText}
                onConfirm={(e) => handleConfirm(e)}
                onCancel={handleCancel}
                colorFont={colorFont}
              />
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { login })(ExpertReviewAdd);
