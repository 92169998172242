import InputCheckboxNA from "../smallComponents/InputCheckboxNA";
import InputNumberForScoreForEdit from "../smallComponents/InputNumberForScoreForEdit";

export const siteVisiteInformationDataEditInputPreview = (
  dataSent,
  handleInputChangeNumber,
  handleInputChangeTextComment,
  handleInputChangeNumberNA
) => ({
  point1: [
    {
      bigHeader: "Site visit Comments",
      content: [
        {
          subData: [
            {
              number: ``,
              subtitleStandard: ``,
              subcontent: [
                {
                  subtitleNorm: ``,
                  dataContent: [
                    { 
                      column4: (
                        <span className="font-bold text-black">
                          Designation
                        </span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Marks obtained
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          N/A
                        </span>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            valueGot={dataSent.StudentsSupportMentorshipprogrammeNA}
                            name={"StudentsSupportMentorshipprogrammeNA"}
                          />
                        </>
                      ),
                      column7: (
                        <span className="font-bold text-black">
                          Comments
                        </span>
                      ),
                    },
                    { 
                      column4: `5.1 Academic Staffing: Staff
                      Student ratio - Max 5`,
                      column5: (
                        <>
                          <InputNumberForScoreForEdit
                            maximum={5}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            valueGot={dataSent.academicStaffingRatio}
                            placeholder={
                              "What is the Academic Staffing: Staff Student ratio ?"
                            }
                            name={"academicStaffingRatio"}
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            valueGot={dataSent.academicStaffingRatioNA}
                            name={"academicStaffingRatioNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentacademicStaffingRatio"
                          cols={80} // Increase the number of columns
                          rows={4}
                          type="text"
                          onChange={handleInputChangeTextComment} 
                          defaultValue={dataSent.commentacademicStaffingRatio}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },

                    { 
                      column4: `5.2 Academic Staffing
                      Minimum number of
                      academic staff for a
                      programme – Max 10`,
                      column5: (
                        <>
                          <InputNumberForScoreForEdit
                            maximum={10}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            valueGot={dataSent.academicStaffingMinimumNumberOfAcademicStaffForAProgramme}
                            placeholder={
                              "What Is the Academic Staffing Minimum number of academic staff for a programme ?"
                            }
                            name={"academicStaffingMinimumNumberOfAcademicStaffForAProgramme"}
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            valueGot={dataSent.academicStaffingMinimumNumberOfAcademicStaffForAProgrammeNA}
                            name={"academicStaffingMinimumNumberOfAcademicStaffForAProgrammeNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentacademicStaffingMinimumNumberOfAcademicStaffForAProgramme"
                          cols={80} // Increase the number of columns
                          rows={4}
                          type="text"
                          onChange={handleInputChangeTextComment}
                          defaultValue={dataSent.commentacademicStaffingMinimumNumberOfAcademicStaffForAProgramme}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },

                    { 
                      column4: `5.3 Academic Staffing
                      Qualifications of core staff
                      related to the programme –
                      Max 10`,
                      column5: (
                        <>
                          <InputNumberForScoreForEdit
                            maximum={10}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            valueGot={dataSent.academicStaffingQualificationsOfCoreStaffRelatedToTheProgramme}
                            placeholder={
                              "Is the academic staffing qualitfications of core staff related to the programme?"
                            }
                            name={"academicStaffingQualificationsOfCoreStaffRelatedToTheProgramme"}
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            valueGot={dataSent.academicStaffingQualificationsOfCoreStaffRelatedToTheProgrammeNA}
                            name={"academicStaffingQualificationsOfCoreStaffRelatedToTheProgrammeNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentacademicStaffingQualificationsOfCoreStaffRelatedToTheProgramme"
                          cols={80} // Increase the number of columns
                          rows={4}
                          type="text"
                          onChange={handleInputChangeTextComment}
                          defaultValue={dataSent.commentacademicStaffingQualificationsOfCoreStaffRelatedToTheProgramme} 
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },

                    { 
                      column4: `5.4 Academic Staffing
                      Proportion of Full Time to
                      Part Time Staff – Max 5`,
                      column5: (
                        <>
                          <InputNumberForScoreForEdit
                            maximum={5}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            valueGot={dataSent.AcademicStaffingProportionofFullTimetoPartTimeStaff}
                            placeholder={
                              "Is the Academic Staffing Proportion of Full Time to Part Time Staff ?"
                            }
                            name={"AcademicStaffingProportionofFullTimetoPartTimeStaff"}
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            valueGot={dataSent.AcademicStaffingProportionofFullTimetoPartTimeStaffNA}
                            name={"AcademicStaffingProportionofFullTimetoPartTimeStaffNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentAcademicStaffingProportionofFullTimetoPartTimeStaff"
                          cols={80} // Increase the number of columns
                          rows={4}
                          type="text"
                          defaultValue={dataSent.commentAcademicStaffingProportionofFullTimetoPartTimeStaff}
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },

                    { 
                      column4: `5.5 Academic Staffing
                      Teaching experience – Max
                      5`,
                      column5: (
                        <>
                          <InputNumberForScoreForEdit
                            maximum={5}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            valueGot={dataSent.academicStaffingTeachingExperience}
                            placeholder={
                              "What Is the Academic Staffing Teaching experience ?"
                            }
                            name={"academicStaffingTeachingExperience"}
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            valueGot={dataSent.academicStaffingTeachingExperienceNA}
                            name={"academicStaffingTeachingExperienceNA"}
                          />
                        </>
                      ),
                      column7: (
                        <>
                          <textarea
                            name="commentAcademicStaffingTeachingExperience"
                            cols={80} // Increase the number of columns
                            rows={4}
                            type="text"
                            defaultValue={dataSent.commentAcademicStaffingTeachingExperience}
                            style={{
                              width: "100%",
                              padding: "5px",
                              border: "1px solid #ccc",
                              borderRadius: "4px",
                            }}
                          ></textarea>{" "}
                          
                        </>
                      ),
                    },

                    { 
                      column4: `5.6 Academic Staffing
                      Workload/Contact hours –
                      Max 5`,
                      column5: (
                        <>
                          <InputNumberForScoreForEdit
                            maximum={5}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            valueGot={dataSent.academicStaffingWorkload}
                            placeholder={
                              "What Is the Academic Staffing Workload ?"
                            }
                            name={"academicStaffingWorkload"}
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            valueGot={dataSent.academicStaffingWorkloadNA}
                            name={"academicStaffingWorkloadNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentacademicStaffingWorkload"
                          cols={80} // Increase the number of columns
                          rows={4}
                          type="text"
                          defaultValue={dataSent.commentacademicStaffingWorkload}
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },

                    { 
                      column4: `5.6 Academic Staffing
                      Training in pedagogy – Max
                      5`,
                      column5: (
                        <>
                          <InputNumberForScoreForEdit
                            maximum={5}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            valueGot={dataSent.AcademicStaffingTrainingInPedagogy}
                            placeholder={
                              "What Is the Academic Staffing Training in pedagogy?"
                            }
                            name={"AcademicStaffingTrainingInPedagogy"}
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            valueGot={dataSent.AcademicStaffingTrainingInPedagogyNA}
                            name={"AcademicStaffingTrainingInPedagogyNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentAcademicStaffingTrainingInPedagogy"
                          cols={80} // Increase the number of columns
                          rows={4}
                          type="text"
                          defaultValue={dataSent.commentAcademicStaffingTrainingInPedagogy}
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },

                    { 
                      column4: `5.8 Academic Staffing
                      Provision of regular CPD
                      (where applicable) – Max 5`,
                      column5: (
                        <>
                          <InputNumberForScoreForEdit
                            maximum={10}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            valueGot={dataSent.AcademicStaffingProvisionofRegularCPD}
                            placeholder={
                              "What Is the Academic Staffing Provision of regular CPD ?"
                            }
                            name={"AcademicStaffingProvisionofRegularCPD"}
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            valueGot={dataSent.AcademicStaffingProvisionofRegularCPDNA}
                            name={"AcademicStaffingProvisionofRegularCPDNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentAcademicStaffingProvisionofRegularCPD"
                          cols={80} // Increase the number of columns
                          rows={4}
                          type="text"
                          defaultValue={dataSent.commentAcademicStaffingProvisionofRegularCPD}
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },

                    { 
                      column4: `5.9 Academic Staffing
                      Deployment && allocation of
                      staff – Max 20`,
                      column5: (
                        <>
                          <InputNumberForScoreForEdit
                            maximum={20}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            valueGot={dataSent.AcademicStaffingDeploymentallocationOfStaff}
                            placeholder={
                              "What is the Academic Staffing Deployment allocation of staff  ?"
                            }
                            name={
                              "AcademicStaffingDeploymentallocationOfStaff"
                            }
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            valueGot={dataSent.AcademicStaffingDeploymentallocationOfStaffNA}
                            name={"AcademicStaffingDeploymentallocationOfStaffNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentAcademicStaffingDeploymentallocationOfStaff"
                          cols={80} // Increase the number of columns
                          rows={4}
                          type="text"
                          defaultValue={dataSent.commentAcademicStaffingDeploymentallocationOfStaff}
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },

                    { 
                      column4: `5.10 Academic Staffing
                      Staff publication – Max 5`,
                      column5: (
                        <>
                          <InputNumberForScoreForEdit
                            maximum={5}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            valueGot={dataSent.AcademicStaffingStaffPublication}
                            placeholder={"Academic Staffing Staff publication?"}
                            name={"AcademicStaffingStaffPublication"}
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            valueGot={dataSent.AcademicStaffingStaffPublicationNA}
                            name={"AcademicStaffingStaffPublicationNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentAcademicStaffingStaffPublication"
                          cols={80} // Increase the number of columns
                          rows={4}
                          type="text"
                          defaultValue={dataSent.commentAcademicStaffingStaffPublication}
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },

                    { 
                      column4: `5.11 Innovations by staff –
                      Max 5`,
                      column5: (
                        <>
                          <InputNumberForScoreForEdit
                            maximum={5}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            valueGot={dataSent.InnovationsbyStaff}
                            placeholder={
                              "What Is the Innovations by staff?"
                            }
                            name={"InnovationsbyStaff"}
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            valueGot={dataSent.InnovationsbyStaffNA}
                            name={"InnovationsbyStaffNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentInnovationsbyStaff"
                          cols={80} // Increase the number of columns
                          rows={4}
                          type="text"
                          defaultValue={dataSent.commentInnovationsbyStaff}
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },

                    { 
                      column4: `5.12 Academic Staffing
                      Patent && copy rights – Max 5`,
                      column5: (
                        <>
                          <InputNumberForScoreForEdit
                            maximum={15}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            valueGot={dataSent.AcademicStaffingPatentcopyRights}
                            placeholder={
                              "Academic Staffing  Patent  copy rights?"
                            }
                            name={"AcademicStaffingPatentcopyRights"}
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            valueGot={dataSent.AcademicStaffingPatentcopyRightsNA}
                            name={"AcademicStaffingPatentcopyRightsNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentAcademicStaffingPatentcopyRights"
                          cols={80} // Increase the number of columns
                          rows={4}
                          type="text"
                          defaultValue={dataSent.commentAcademicStaffingPatentcopyRights}
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },

                    { 
                      column4: `6.1 Governance &&
                      Administration
                      Head of Department – Max
                      10`,
                      column5: (
                        <>
                          <InputNumberForScoreForEdit
                            maximum={10}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            valueGot={dataSent.GovernanceAdministrationHeadofDepartment}
                            placeholder={"Governance && Administration Head of Department?"
                            }
                            name={"GovernanceAdministrationHeadofDepartment"}
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            valueGot={dataSent.GovernanceAdministrationHeadofDepartmentNA}
                            name={"GovernanceAdministrationHeadofDepartmentNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentGovernanceAdministrationHeadofDepartment"
                          cols={80} // Increase the number of columns
                          rows={4}
                          type="text"
                          defaultValue={dataSent.commentGovernanceAdministrationHeadofDepartment}
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    { 
                      column4: `6.2 Governance &&
                      Administration
                      Coordination of the programme academic
                      leader/coordinator – Max 10`,
                      column5: (
                        <>
                          <InputNumberForScoreForEdit
                            maximum={10}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            valueGot={dataSent.GovernanceAdministrationCoordinationoftheprogrammeacademicleadercoordinator}
                            placeholder={
                              "Governance && Administration Coordination of the programme academic leader/coordinator?"
                            }
                            name={"GovernanceAdministrationCoordinationoftheprogrammeacademicleadercoordinator"}
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            valueGot={dataSent.GovernanceAdministrationCoordinationoftheprogrammeacademicleadercoordinatorNA}
                            name={"GovernanceAdministrationCoordinationoftheprogrammeacademicleadercoordinatorNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentGovernanceAdministrationCoordinationoftheprogrammeacademicleadercoordinator"
                          cols={80} // Increase the number of columns
                          rows={4}
                          type="text"
                          defaultValue={dataSent.commentGovernanceAdministrationCoordinationoftheprogrammeacademicleadercoordinator}
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    { 
                      column4: `6.3 Governance &&
                      Administration
                      Quality Assurance Policy –
                      Max 10`,
                      column5: (
                        <>
                          <InputNumberForScoreForEdit
                            maximum={10}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            valueGot={dataSent.GovernanceAdministrationQualityAssurancePolicy}
                            placeholder={
                              "Governance &&  Administration Quality Assurance Policy?"
                            }
                            name={"GovernanceAdministrationQualityAssurancePolicy"}
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            valueGot={dataSent.GovernanceAdministrationQualityAssurancePolicyNA}
                            name={"GovernanceAdministrationQualityAssurancePolicyNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentGovernanceAdministrationQualityAssurancePolicy"
                          cols={80} // Increase the number of columns
                          rows={4}
                          type="text"
                          defaultValue={dataSent.commentGovernanceAdministrationQualityAssurancePolicy}
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    { 
                      column4: `6.4 Governance &&
                      Administration
                      Institutional programme
                      quality Assurance Mechanism
                      – Max 10`,
                      column5: (
                        <>
                          <InputNumberForScoreForEdit
                            maximum={10}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            valueGot={dataSent.GovernanceAdministrationInstitutionalprogrammequalityAssuranceMechanism}
                            placeholder={
                              "Governance && Administration Institutional programme quality Assurance Mechanism ?"
                            }
                            name={"GovernanceAdministrationInstitutionalprogrammequalityAssuranceMechanism"}
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            valueGot={dataSent.GovernanceAdministrationInstitutionalprogrammequalityAssuranceMechanismNA}
                            name={"GovernanceAdministrationInstitutionalprogrammequalityAssuranceMechanismNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentGovernanceAdministrationInstitutionalprogrammequalityAssuranceMechanism"
                          cols={80} // Increase the number of columns
                          rows={4}
                          type="text"
                          defaultValue={dataSent.commentGovernanceAdministrationInstitutionalprogrammequalityAssuranceMechanism}
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    { 
                      column4: `6.5 Governance &&
                      Administration Quality Assurance Policy –
                      Max 30`,
                      column5: (
                        <>
                          <InputNumberForScoreForEdit
                            maximum={15}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            valueGot={dataSent.GovernanceAdministrationQualityAssurancePolicy}
                            placeholder={
                              "Governance && Administration Quality Assurance Policy ?"
                            }
                            name={"GovernanceAdministrationQualityAssurancePolicy"}
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            valueGot={dataSent.GovernanceAdministrationQualityAssurancePolicyNA}
                            name={"GovernanceAdministrationQualityAssurancePolicyNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentGovernanceAdministrationQualityAssurancePolicy"
                          cols={80} // Increase the number of columns
                          rows={4}
                          type="text"
                          defaultValue={dataSent.commentGovernanceAdministrationQualityAssurancePolicy}
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    { 
                      column4: `6.6 Governance &&
                      Administration
                      Institutional policy on
                      programme connectedness
                      with the world of practice
                      with the world of work –
                      Max 20`,
                      column5: (
                        <>
                          <InputNumberForScoreForEdit
                            maximum={20}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            valueGot={dataSent.GovernanceAdministrationInstitutionalpolicyonprogrammeconnectednesswiththeworldofpracticewiththeworldofwork}
                            placeholder={
                              "Governance && Administration Institutional policy on programme connectedness  with the world of practice with the world of work"
                            }
                            name={"GovernanceAdministrationInstitutionalpolicyonprogrammeconnectednesswiththeworldofpracticewiththeworldofwork"}
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            valueGot={dataSent.GovernanceAdministrationInstitutionalpolicyonprogrammeconnectednesswiththeworldofpracticewiththeworldofworkNA}
                            name={"GovernanceAdministrationInstitutionalpolicyonprogrammeconnectednesswiththeworldofpracticewiththeworldofworkNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentGovernanceAdministrationInstitutionalpolicyonprogrammeconnectednesswiththeworldofpracticewiththeworldofwork"
                          cols={80} // Increase the number of columns
                          rows={4}
                          type="text"
                          defaultValue={dataSent.commentGovernanceAdministrationInstitutionalpolicyonprogrammeconnectednesswiththeworldofpracticewiththeworldofwork}
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    { 
                      column4: `7.1 Infrastructure facilities:
                      Lecture room space – Max
                      15`,
                      column5: (
                        <>
                          <InputNumberForScoreForEdit
                            maximum={15}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            valueGot={dataSent.InfrastructurefacilitiesLectureroomspace}
                            placeholder={
                              "Infrastructure facilities: Lecture room space?"
                            }
                            name={"InfrastructurefacilitiesLectureroomspace"}
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            valueGot={dataSent.InfrastructurefacilitiesLectureroomspaceNA}
                            name={"InfrastructurefacilitiesLectureroomspaceNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentInfrastructurefacilitiesLectureroomspace"
                          cols={80} // Increase the number of columns
                          rows={4}
                          type="text"
                          defaultValue={dataSent.commentInfrastructurefacilitiesLectureroomspace}
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    { 
                      column4: `7.2 Infrastructure facilities:
                      Library space – Max 10`,
                      column5: (
                        <>
                          <InputNumberForScoreForEdit
                            maximum={10}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            valueGot={dataSent.InfrastructureFacilitiesLibraryspace}
                            placeholder={
                              "Infrastructure facilities:  Library space?"
                            }
                            name={"InfrastructureFacilitiesLibraryspace"}
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            valueGot={dataSent.InfrastructureFacilitiesLibraryspaceNA}
                            name={"InfrastructureFacilitiesLibraryspaceNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentInfrastructureFacilitiesLibraryspace"
                          cols={80} // Increase the number of columns
                          rows={4}
                          type="text"
                          defaultValue={dataSent.commentInfrastructureFacilitiesLibraryspace}
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    { 
                      column4: `57.3 Infrastructure Facilities:
                      ICT Laboratoires space –
                      Max 10`,
                      column5: (
                        <>
                          <InputNumberForScoreForEdit
                            maximum={10}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            valueGot={dataSent.InfrastructureFacilitiesICTLaboratoiresspace}
                            placeholder={
                              "Infrastructure Facilities:  ICT Laboratoires space?"
                            }
                            name={"InfrastructureFacilitiesICTLaboratoiresspace"}
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            valueGot={dataSent.InfrastructureFacilitiesICTLaboratoiresspaceNA}
                            name={"InfrastructureFacilitiesICTLaboratoiresspaceNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentInfrastructureFacilitiesICTLaboratoiresspace"
                          cols={80} // Increase the number of columns
                          rows={4}
                          type="text"
                          defaultValue={dataSent.commentInfrastructureFacilitiesICTLaboratoiresspace}
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    { 
                      column4: `7.4 Infrastructure facilities:
                      Seminar rooms – Max 10`,
                      column5: (
                        <>
                          <InputNumberForScoreForEdit
                            maximum={10}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            valueGot={dataSent.InfrastructurefacilitiesSeminarRooms}
                            placeholder={
                              "Infrastructure facilities:  Seminar rooms?"
                            }
                            name={"InfrastructurefacilitiesSeminarRooms"}
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            valueGot={dataSent.InfrastructurefacilitiesSeminarRoomsNA}
                            name={"InfrastructurefacilitiesSeminarRoomsNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentInfrastructurefacilitiesSeminarRooms"
                          cols={80} // Increase the number of columns
                          rows={4}
                          type="text"
                          defaultValue={dataSent.commentInfrastructurefacilitiesSeminarRooms}
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    { 
                      column4: `7.5 ** Infrastructure
                      facilities: Studio space – Max
                      10`,
                      column5: (
                        <>
                          <InputNumberForScoreForEdit
                            maximum={10}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            valueGot={dataSent.InfrastructurefacilitiesStudiospace}
                            placeholder={
                              "Infrastructure facilities: Studio space?"
                            }
                            name={"InfrastructurefacilitiesStudiospace"}
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            valueGot={dataSent.InfrastructurefacilitiesStudiospaceNA}
                            name={"InfrastructurefacilitiesStudiospaceNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentInfrastructurefacilitiesStudiospace"
                          cols={80} // Increase the number of columns
                          rows={4}
                          type="text"
                          defaultValue={dataSent.commentInfrastructurefacilitiesStudiospace}
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    { 
                      column4: `7.6 Infrastructure facilities:
                      Office space – Max 10`,
                      column5: (
                        <>
                          <InputNumberForScoreForEdit
                            maximum={15}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            valueGot={dataSent.InfrastructurefacilitiesOfficespace}
                            placeholder={
                              "Infrastructure facilities: Office space?"
                            }
                            name={"InfrastructurefacilitiesOfficespace"}
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            valueGot={dataSent.InfrastructurefacilitiesOfficespaceNA}
                            name={"InfrastructurefacilitiesOfficespaceNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentInfrastructurefacilitiesOfficespace"
                          cols={80} // Increase the number of columns
                          rows={4}
                          type="text"
                          onChange={handleInputChangeTextComment}
                          defaultValue={dataSent.commentInfrastructurefacilitiesOfficespace}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    { 
                      column4: `7.7 Infrastructure facilities:
                      Space for basic science
                      laboratories – Max 10`,
                      column5: (
                        <>
                          <InputNumberForScoreForEdit
                            maximum={10}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            valueGot={dataSent.InfrastructurefacilitiesSpaceforbasicsciencelaboratories}
                            placeholder={
                              "Infrastructure facilities: Space for basic science laboratories?"
                            }
                            name={"InfrastructurefacilitiesSpaceforbasicsciencelaboratories"}
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            valueGot={dataSent.InfrastructurefacilitiesSpaceforbasicsciencelaboratoriesNA}
                            name={"InfrastructurefacilitiesSpaceforbasicsciencelaboratoriesNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentInfrastructurefacilitiesSpaceforbasicsciencelaboratories"
                          cols={80} // Increase the number of columns
                          rows={4}
                          type="text"
                          defaultValue={dataSent.commentInfrastructurefacilitiesSpaceforbasicsciencelaboratories}
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    { 
                      column4: `7.8 Infrastructure facilities:
                      Human Medicine laboratories 
                      – Max 45`,
                      column5: (
                        <>
                          <InputNumberForScoreForEdit
                            maximum={45}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            valueGot={dataSent.InfrastructurefacilitiesHumanMedicinelaboratories}
                            placeholder={
                              "Infrastructure facilities: Human Medicine laboratories?"
                            }
                            name={"InfrastructurefacilitiesHumanMedicinelaboratories"}
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            valueGot={dataSent.InfrastructurefacilitiesHumanMedicinelaboratoriesNA}
                            name={"InfrastructurefacilitiesHumanMedicinelaboratoriesNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentInfrastructurefacilitiesHumanMedicinelaboratories"
                          cols={80} // Increase the number of columns
                          rows={4}
                          type="text"
                          defaultValue={dataSent.commentInfrastructurefacilitiesHumanMedicinelaboratories}
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    { 
                      column4: `7.9 Infrastructure facilities:
                      Dental Medicine Laboratories
                      – Max 20`,
                      column5: (
                        <>
                          <InputNumberForScoreForEdit
                            maximum={20}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            valueGot={dataSent.InfrastructurefacilitiesDentalMedicineLaboratories}
                            placeholder={
                              "Infrastructure facilities: Dental Medicine Laboratories?"
                            }
                            name={"InfrastructurefacilitiesDentalMedicineLaboratories"}
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            valueGot={dataSent.InfrastructurefacilitiesDentalMedicineLaboratoriesNA}
                            name={"InfrastructurefacilitiesDentalMedicineLaboratoriesNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentInfrastructurefacilitiesDentalMedicineLaboratories"
                          cols={80} // Increase the number of columns
                          rows={4}
                          type="text"
                          defaultValue={dataSent.commentInfrastructurefacilitiesDentalMedicineLaboratories}
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    { 
                      column4: `7.10 Infrastructure facilities:
                      Skills Laboratories for
                      Nursing/Midwifery – Max 10`,
                      column5: (
                        <>
                          <InputNumberForScoreForEdit
                            maximum={10}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            valueGot={dataSent.InfrastructurefacilitiesSkillsLaboratoriesforNursingMidwifery}
                            placeholder={
                              "Infrastructure facilities: Skills Laboratories for Nursing/Midwifery?"
                            }
                            name={"InfrastructurefacilitiesSkillsLaboratoriesforNursingMidwifery"}
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            valueGot={dataSent.InfrastructurefacilitiesSkillsLaboratoriesforNursingMidwiferyNA}
                            name={"InfrastructurefacilitiesSkillsLaboratoriesforNursingMidwiferyNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentInfrastructurefacilitiesSkillsLaboratoriesforNursingMidwifery"
                          cols={80} // Increase the number of columns
                          rows={4}
                          type="text"
                          defaultValue={dataSent.commentInfrastructurefacilitiesSkillsLaboratoriesforNursingMidwifery}
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    { 
                      column4: `7.11 Infrastructure facilities:
                      Teaching hospital – Max 5`,
                      column5: (
                        <>
                          <InputNumberForScoreForEdit
                            maximum={5}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            valueGot={dataSent.InfrastructurefacilitiesTeachinghospital}
                            placeholder={
                              "Infrastructure facilities:  Teaching hospital?"
                            }
                            name={"InfrastructurefacilitiesTeachinghospital"}
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            valueGot={dataSent.InfrastructurefacilitiesTeachinghospitalNA}
                            name={"InfrastructurefacilitiesTeachinghospitalNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentInfrastructurefacilitiesTeachinghospital"
                          cols={80} // Increase the number of columns
                          rows={4}
                          type="text"
                          defaultValue={dataSent.commentInfrastructurefacilitiesTeachinghospital}
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    { 
                      column4: `7.12 Infrastructure facilities:
                      Agricultural Sciences
                      laboratoires – Max 20`,
                      column5: (
                        <>
                          <InputNumberForScoreForEdit
                            maximum={20}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            valueGot={dataSent.InfrastructurefacilitiesAgriculturalScienceslaboratoires}
                            placeholder={
                              "Infrastructure facilities:  Agricultural Sciences laboratoires?"
                            }
                            name={"InfrastructurefacilitiesAgriculturalScienceslaboratoires"}
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            valueGot={dataSent.InfrastructurefacilitiesAgriculturalScienceslaboratoiresNA}
                            name={"InfrastructurefacilitiesAgriculturalScienceslaboratoiresNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentInfrastructurefacilitiesAgriculturalScienceslaboratoires"
                          cols={80} // Increase the number of columns
                          rows={4}
                          type="text"
                          defaultValue={dataSent.commentInfrastructurefacilitiesAgriculturalScienceslaboratoires}
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    { 
                      column4: `7.13 Infrastructure facilities:
                      Engineering laboratories –
                      Max 30`,
                      column5: (
                        <>
                          <InputNumberForScoreForEdit
                            maximum={30}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            valueGot={dataSent.InfrastructurefacilitiesEngineeringlaboratories}
                            placeholder={
                              "Infrastructure facilities: Engineering laboratories?"
                            }
                            name={"InfrastructurefacilitiesEngineeringlaboratories"}
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            valueGot={dataSent.InfrastructurefacilitiesEngineeringlaboratoriesNA}
                            name={"InfrastructurefacilitiesEngineeringlaboratoriesNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentInfrastructurefacilitiesEngineeringlaboratories"
                          cols={80} // Increase the number of columns
                          rows={4}
                          type="text"
                          defaultValue={dataSent.commentInfrastructurefacilitiesEngineeringlaboratories}
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    { 
                      column4: `7.14 Infrastructure facilities:
                      Workshops – Max 35`,
                      column5: (
                        <>
                          <InputNumberForScoreForEdit
                            maximum={35}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            valueGot={dataSent.InfrastructurefacilitiesWorkshops}
                            placeholder={
                              "Infrastructure facilities: Workshops?"
                            }
                            name={"InfrastructurefacilitiesWorkshops"}
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            valueGot={dataSent.InfrastructurefacilitiesWorkshopsNA}
                            name={"InfrastructurefacilitiesWorkshopsNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentInfrastructurefacilitiesWorkshops"
                          cols={80} // Increase the number of columns
                          rows={4}
                          type="text"
                          defaultValue={dataSent.commentInfrastructurefacilitiesWorkshops}
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    { 
                      column4: `7.15 Infrastructure facilities:
                      Teachers Resource Room –
                      Max 10`,
                      column5: (
                        <>
                          <InputNumberForScoreForEdit
                            maximum={10}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            valueGot={dataSent.InfrastructurefacilitiesTeachersResourceRoom}
                            placeholder={
                              "Infrastructure facilities: Teachers Resource Room"
                            }
                            name={"InfrastructurefacilitiesTeachersResourceRoom"}
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            valueGot={dataSent.InfrastructurefacilitiesTeachersResourceRoomNA}
                            name={"InfrastructurefacilitiesTeachersResourceRoomNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentInfrastructurefacilitiesTeachersResourceRoom"
                          cols={80} // Increase the number of columns
                          rows={4}
                          type="text"
                          defaultValue={dataSent.commentInfrastructurefacilitiesTeachersResourceRoom}
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    { 
                      column4: `7.16 Infrastructure facilities:
                      Education Technology
                      laboratories – Max 10`,
                      column5: (
                        <>
                          <InputNumberForScoreForEdit
                            maximum={10}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            valueGot={dataSent.InfrastructurefacilitiesEducationTechnologylaboratories}
                            placeholder={
                              "Infrastructure facilities: Education Technology laboratories?"
                            }
                            name={"InfrastructurefacilitiesEducationTechnologylaboratories"}
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            valueGot={dataSent.InfrastructurefacilitiesEducationTechnologylaboratoriesNA}
                            name={"InfrastructurefacilitiesEducationTechnologylaboratoriesNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentInfrastructurefacilitiesEducationTechnologylaboratories"
                          cols={80} // Increase the number of columns
                          rows={4}
                          type="text"
                          defaultValue={dataSent.commentInfrastructurefacilitiesEducationTechnologylaboratories}
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    { 
                      column4: `7.17 Infrastructure facilities:
                      Food && Catering laboratories
                      – Max 10`,
                      column5: (
                        <>
                          <InputNumberForScoreForEdit
                            maximum={10}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            valueGot={dataSent.InfrastructurefacilitiesFoodCateringlaboratories}
                            placeholder={
                              "Infrastructure facilities: Food && Catering laboratories?"
                            }
                            name={"InfrastructurefacilitiesFoodCateringlaboratories"}
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            valueGot={dataSent.InfrastructurefacilitiesFoodCateringlaboratoriesNA}
                            name={"InfrastructurefacilitiesFoodCateringlaboratoriesNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentInfrastructurefacilitiesFoodCateringlaboratories"
                          cols={80} // Increase the number of columns
                          rows={4}
                          type="text"
                          defaultValue={dataSent.commentInfrastructurefacilitiesFoodCateringlaboratories}
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    { 
                      column4: `7.18 Infrastructure facilities:
                      Garment Cutting – Max 10`,
                      column5: (
                        <>
                          <InputNumberForScoreForEdit
                            maximum={10}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            valueGot={dataSent.InfrastructureFacilitiesGarmentCutting}
                            placeholder={
                              "Infrastructure facilities: Garment Cutting?"
                            }
                            name={"InfrastructureFacilitiesGarmentCutting"}
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            valueGot={dataSent.InfrastructureFacilitiesGarmentCuttingNA}
                            name={"InfrastructureFacilitiesGarmentCuttingNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentInfrastructureFacilitiesGarmentCutting"
                          cols={80} // Increase the number of columns
                          rows={4}
                          type="text"
                          defaultValue={dataSent.commentInfrastructureFacilitiesGarmentCutting}
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    { 
                      column4: `7.19 Infrastructure facilities:
                      Cosmetology laboratory –
                      Max 10`,
                      column5: (
                        <>
                          <InputNumberForScoreForEdit
                            maximum={10}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            valueGot={dataSent.InfrastructurefacilitiesCosmetologylaboratory}
                            placeholder={
                              "Infrastructure facilities: Cosmetology laboratory?"
                            }
                            name={"InfrastructurefacilitiesCosmetologylaboratory"}
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            valueGot={dataSent.InfrastructurefacilitiesCosmetologylaboratoryNA}
                            name={"InfrastructurefacilitiesCosmetologylaboratoryNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentInfrastructurefacilitiesCosmetologylaboratory"
                          cols={80} // Increase the number of columns
                          rows={4}
                          type="text"
                          defaultValue={dataSent.commentInfrastructurefacilitiesCosmetologylaboratory}
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    { 
                      column4: `7.20 Infrastructure facilities:
                      Geology laboratory – Max
                      10`,
                      column5: (
                        <>
                          <InputNumberForScoreForEdit
                            maximum={10}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            valueGot={dataSent.InfrastructurefacilitiesGeologylaboratory}
                            placeholder={
                              "Infrastructure facilities: Geology laboratory ?"
                            }
                            name={"InfrastructurefacilitiesGeologylaboratory"}
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            valueGot={dataSent.InfrastructurefacilitiesGeologylaboratoryNA}
                            name={"InfrastructurefacilitiesGeologylaboratoryNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentInfrastructurefacilitiesGeologylaboratory"
                          cols={80} // Increase the number of columns
                          rows={4}
                          type="text"
                          defaultValue={dataSent.commentInfrastructurefacilitiesGeologylaboratory}
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    { 
                      column4: `7.21 Infrastructure facilities:
                      Museum – Max 10`,
                      column5: (
                        <>
                          <InputNumberForScoreForEdit
                            maximum={15}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            valueGot={dataSent.InfrastructurefacilitiesMuseum}
                            placeholder={
                              "Infrastructure facilities: Museum?"
                            }
                            name={"InfrastructurefacilitiesMuseum"}
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            valueGot={dataSent.InfrastructurefacilitiesMuseumNA}
                            name={"InfrastructurefacilitiesMuseumNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentInfrastructurefacilitiesMuseum"
                          cols={80} // Increase the number of columns
                          rows={4}
                          type="text"
                          defaultValue={dataSent.commentInfrastructurefacilitiesMuseum}
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    { 
                      column4: `8.1 Teaching && Learning
                      Resource: Smart Boards –
                      Max 5`,
                      column5: (
                        <>
                          <InputNumberForScoreForEdit
                            maximum={5}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            valueGot={dataSent.TeachingLearningResourceSmartBoards}
                            placeholder={
                              "Teaching && Learning Resource: Smart Boards?"
                            }
                            name={"TeachingLearningResourceSmartBoards"}
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            valueGot={dataSent.TeachingLearningResourceSmartBoardsNA}
                            name={"TeachingLearningResourceSmartBoardsNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentTeachingLearningResourceSmartBoards"
                          cols={80} // Increase the number of columns
                          rows={4}
                          type="text"
                          defaultValue={dataSent.commentTeachingLearningResourceSmartBoards}
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    { 
                      column4: `8.2 Teaching && Learning
                      Internet availability – Max 20`,
                      column5: (
                        <>
                          <InputNumberForScoreForEdit
                            maximum={20}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            valueGot={dataSent.TeachingLearningInternetavailability}
                            placeholder={
                              "Teaching && Learning Internet availability?"
                            }
                            name={"TeachingLearningInternetavailability"}
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            valueGot={dataSent.TeachingLearningInternetavailabilityNA}
                            name={"TeachingLearningInternetavailabilityNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentTeachingLearningInternetavailability"
                          cols={80} // Increase the number of columns
                          rows={4}
                          type="text"
                          defaultValue={dataSent.commentTeachingLearningInternetavailability}
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    { 
                      column4: `8.3 Teaching && Learning 
                      Book && Library resources
                      relevant to the programme –
                      Max 15`,
                      column5: (
                        <>
                          <InputNumberForScoreForEdit
                            maximum={15}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            valueGot={dataSent.TeachingLearningBookLibraryresourcesrelevanttotheprogramme}
                            placeholder={
                              "Teaching && Learning Book && Library resources relevant to the programme?"
                            }
                            name={"TeachingLearningBookLibraryresourcesrelevanttotheprogramme"}
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            valueGot={dataSent.TeachingLearningBookLibraryresourcesrelevanttotheprogrammeNA}
                            name={"StudentsTeachingLearningBookLibraryresourcesrelevanttotheprogrammeNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentTeachingLearningBookLibraryresourcesrelevanttotheprogramme"
                          cols={80} // Increase the number of columns
                          rows={4}
                          type="text"
                          defaultValue={dataSent.commentTeachingLearningBookLibraryresourcesrelevanttotheprogramme}
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    { 
                      column4: `8.4 Teaching && Learning
                      University Learning
                      Management System – Max
                      15`,
                      column5: (
                        <>
                          <InputNumberForScoreForEdit
                            maximum={15}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            valueGot={dataSent.TeachingLearningUniversityLearningManagementSystem}
                            placeholder={
                              "Teaching && Learning University Learning Management System?"
                            }
                            name={"TeachingLearningUniversityLearningManagementSystem"}
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            valueGot={dataSent.TeachingLearningUniversityLearningManagementSystemNA}
                            name={"TeachingLearningUniversityLearningManagementSystemNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentTeachingLearningUniversityLearningManagementSystem"
                          cols={80} // Increase the number of columns
                          rows={4}
                          type="text"
                          defaultValue={dataSent.commentTeachingLearningUniversityLearningManagementSystem}
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    { 
                      column4: `8.5 Teaching && Learning
                      Facilities available support
                      blended learning – Max 30`,
                      column5: (
                        <>
                          <InputNumberForScoreForEdit
                            maximum={30}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            valueGot={dataSent.TeachingLearningFacilitiesavailablesupportblendedlearning}
                            placeholder={
                              "Teaching && Learning Facilities available support blended learning?"
                            }
                            name={"TeachingLearningFacilitiesavailablesupportblendedlearning"}
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            valueGot={dataSent.TeachingLearningFacilitiesavailablesupportblendedlearningNA}
                            name={"TeachingLearningFacilitiesavailablesupportblendedlearningNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentTeachingLearningFacilitiesavailablesupportblendedlearning"
                          cols={80} // Increase the number of columns
                          rows={4}
                          type="text"
                          defaultValue={dataSent.commentTeachingLearningFacilitiesavailablesupportblendedlearning}
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    { 
                      column4: `9.1 Student assessment:
                      Approaches adopted in
                      continuous (formative) and
                      summative assessment –
                      Max 15`,
                      column5: (
                        <>
                          <InputNumberForScoreForEdit
                            maximum={15}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            valueGot={dataSent.StudentassessmentApproachesadoptedincontinuousandsummativeassessment}
                            placeholder={
                              "Student assessment:  Approaches adopted in continuous (formative) and summative assessment?"
                            }
                            name={"StudentassessmentApproachesadoptedincontinuousandsummativeassessment"}
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            valueGot={dataSent.StudentassessmentApproachesadoptedincontinuousandsummativeassessmentNA}
                            name={"StudentassessmentApproachesadoptedincontinuousandsummativeassessmentNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentStudentassessmentApproachesadoptedincontinuousandsummativeassessment"
                          cols={80} // Increase the number of columns
                          rows={4}
                          type="text"
                          defaultValue={dataSent.commentStudentassessmentApproachesadoptedincontinuousandsummativeassessment}
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    { 
                      column4: `9.2 Student assessment
                      Approaches to assessment of
                      digital/online learning – Max
                      15`,
                      column5: (
                        <>
                          <InputNumberForScoreForEdit
                            maximum={15}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            valueGot={dataSent.StudentassessmentApproachestoassessmentofdigital}
                            placeholder={
                              "Student assessment Approaches to assessment of digital?"
                            }
                            name={"StudentassessmentApproachestoassessmentofdigital"}
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            valueGot={dataSent.StudentassessmentApproachestoassessmentofdigitalNA}
                            name={"StudentassessmentApproachestoassessmentofdigitalNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentStudentassessmentApproachestoassessmentofdigital"
                          cols={80} // Increase the number of columns
                          rows={4}
                          type="text"
                          onChange={handleInputChangeTextComment}
                          defaultValue={dataSent.commentStudentassessmentApproachestoassessmentofdigital}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    { 
                      column4: `9.3 Student assessment
                      Internal && External
                      moderation of examinations
                      && results – Max 10`,
                      column5: (
                        <>
                          <InputNumberForScoreForEdit
                            maximum={10}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            valueGot={dataSent.StudentassessmentInternalExternalmoderationofexaminationsresult}
                            placeholder={
                              "Student assessment Internal && External  moderation of examinations   && results?"
                            }
                            name={"StudentassessmentInternalExternalmoderationofexaminationsresult"}
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            valueGot={dataSent.StudentassessmentInternalExternalmoderationofexaminationsresultNA}
                            name={"StudentassessmentInternalExternalmoderationofexaminationsresultNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentStudentassessmentInternalExternalmoderationofexaminationsresult"
                          cols={80} // Increase the number of columns
                          rows={4}
                          type="text"
                          defaultValue={dataSent.commentStudentassessmentInternalExternalmoderationofexaminationsresult}
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    { 
                      column4: `9.4 Student assessment
                      Security of Examinations –
                      Max 5`,
                      column5: (
                        <>
                          <InputNumberForScoreForEdit
                            maximum={5}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            valueGot={dataSent.StudentassessmentSecurityofExaminations}
                            placeholder={
                              "Student assessment Security of Examinations?"
                            }
                            name={"StudentassessmentSecurityofExaminations"}
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            valueGot={dataSent.StudentassessmentSecurityofExaminationsNA}
                            name={"StudentassessmentSecurityofExaminationsNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentStudentassessmentSecurityofExaminations"
                          cols={80} // Increase the number of columns
                          rows={4}
                          type="text"
                          defaultValue={dataSent.commentStudentassessmentSecurityofExaminations}
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    { 
                      column4: `9.5 Student assessment
                      Internship/Industrial
                      Attachment – Max 10`,
                      column5: (
                        <>
                          <InputNumberForScoreForEdit
                            maximum={10}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            valueGot={dataSent.StudentassessmentInternshipIndustrialAttachment}
                            placeholder={
                              "Student assessment Internship/Industrial Attachment?"
                            }
                            name={"StudentassessmentInternshipIndustrialAttachment"}
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            valueGot={dataSent.StudentassessmentInternshipIndustrialAttachmentNA}
                            name={"StudentassessmentInternshipIndustrialAttachmentNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentStudentassessmentInternshipIndustrialAttachment"
                          cols={80} // Increase the number of columns
                          rows={4}
                          type="text"
                          defaultValue={dataSent.commentStudentassessmentInternshipIndustrialAttachment}
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    { 
                      column4: `10.1 Students Support
                      System: Provision for
                      students
                      tutor/mentor/advisor – Max
                      15`,
                      column5: (
                        <>
                          <InputNumberForScoreForEdit
                            maximum={15}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            valueGot={dataSent.StudentsSupportSystemProvisionforstudents}
                            placeholder={
                              "Students Support System: Provision for students?"
                            }
                            name={"StudentsSupportSystemProvisionforstudents"}
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            valueGot={dataSent.StudentsSupportSystemProvisionforstudentsNA}
                            name={"StudentsSupportSystemProvisionforstudentsNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentStudentsSupportSystemProvisionforstudents"
                          cols={80} // Increase the number of columns
                          rows={4}
                          type="text"
                          defaultValue={dataSent.commentStudentsSupportSystemProvisionforstudents}
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    { 
                      column4: `10.2 Students Support
                      Programme orientation –
                      Max 10`,
                      column5: (
                        <>
                          <InputNumberForScoreForEdit
                            maximum={10}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            valueGot={dataSent.StudentsSupportProgrammeorientation}
                            placeholder={
                              "Students Support Programme orientation?"
                            }
                            name={"StudentsSupportProgrammeorientation"}
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            valueGot={dataSent.StudentsSupportProgrammeorientationNA}
                            name={"StudentsSupportProgrammeorientationNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentStudentsSupportProgrammeorientation"
                          cols={80} // Increase the number of columns
                          rows={4}
                          type="text"
                          defaultValue={dataSent.commentStudentsSupportProgrammeorientation}
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    {  
                      column4: `10.3 Students Support Co-
                      curriculum activities – Max 5`,
                      column5: (
                        <>
                          <InputNumberForScoreForEdit
                            maximum={5}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            valueGot={dataSent.StudentsSupportCo_curriculumactivities}
                            placeholder={
                              "Students Support Co-curriculum activities?"
                            }
                            name={"StudentsSupportCo_curriculumactivities"}
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            valueGot={dataSent.StudentsSupportCo_curriculumactivitiesNA}
                            name={"StudentsSupportCo_curriculumactivitiesNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentStudentsSupportCo_curriculumactivities"
                          cols={80} // Increase the number of columns
                          rows={4}
                          type="text"
                          defaultValue={dataSent.commentStudentsSupportCo_curriculumactivities}
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    { 
                      column4: `10.4 Students Support
                      Counselling services – Max
                      15`,
                      column5: (
                        <>
                          <InputNumberForScoreForEdit
                            maximum={15}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            valueGot={dataSent.StudentsSupportCounsellingservices}
                            placeholder={
                              "Students Support Counselling services?"
                            }
                            name={"StudentsSupportCounsellingservices"}
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            valueGot={dataSent.StudentsSupportCounsellingservicesNA}
                            name={"StudentsSupportCounsellingservicesNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentStudentsSupportCounsellingservices"
                          cols={80} // Increase the number of columns
                          rows={4}
                          type="text"
                          defaultValue={dataSent.commentStudentsSupportCounsellingservices}
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    { 
                      column4: `10.5 Students Support
                      Mentorship programme –
                      Max 10`,
                      column5: (
                        <>
                          <InputNumberForScoreForEdit
                            maximum={10}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            valueGot={dataSent.StudentsSupportMentorshipprogramme}
                            placeholder={
                              "Students Support Mentorship programme?"
                            }
                            name={"StudentsSupportMentorshipprogramme"}
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            valueGot={dataSent.StudentsSupportMentorshipprogrammeNA}
                            name={"StudentsSupportMentorshipprogrammeNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentStudentsSupportMentorshipprogramme"
                          cols={80} // Increase the number of columns
                          rows={4}
                          type="text"
                          defaultValue={dataSent.commentStudentsSupportMentorshipprogramme}
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    { 
                      column4: `10.6 Students Support
                      Provision of social presence
                      for digital learning – Max 5`,
                      column5: (
                        <>
                          <InputNumberForScoreForEdit
                            maximum={5}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            valueGot={dataSent.StudentsSupportProvisionofsocialpresencefordigitallearning}
                            placeholder={
                              "Students Support Provision of social presence for digital learning?"
                            }
                            name={"StudentsSupportProvisionofsocialpresencefordigitallearning"}
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            valueGot={dataSent.StudentsSupportProvisionofsocialpresencefordigitallearningNA}
                            name={"StudentsSupportProvisionofsocialpresencefordigitallearningNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentStudentsSupportProvisionofsocialpresencefordigitallearning"
                          cols={80} // Increase the number of columns
                          rows={4}
                          type="text"
                          defaultValue={dataSent.commentStudentsSupportProvisionofsocialpresencefordigitallearning}
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    { 
                      column4: `10.7 Students Support
                      Information on students
                      support made available –
                      Max 10`,
                      column5: (
                        <>
                          <InputNumberForScoreForEdit
                            maximum={10}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            valueGot={dataSent.StudentsSupportInformationonstudentssupportmadeavailable}
                            placeholder={
                              "Students Support Information on students support made available?"
                            }
                            name={"StudentsSupportInformationonstudentssupportmadeavailable"}
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            valueGot={dataSent.StudentsSupportInformationonstudentssupportmadeavailableNA}
                            name={"StudentsSupportInformationonstudentssupportmadeavailableNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentStudentsSupportInformationonstudentssupportmadeavailable"
                          cols={80} // Increase the number of columns
                          rows={4}
                          type="text"
                          defaultValue={dataSent.commentStudentsSupportInformationonstudentssupportmadeavailable}
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    { 
                      column4: `10.8 Students Support
                      Scholarship/financial support
                      services to needy students –
                      Max 15`,
                      column5: (
                        <>
                          <InputNumberForScoreForEdit
                            maximum={15}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            valueGot={dataSent.StudentsSupportScholarship_financialsupportservicestoneedystudents}
                            placeholder={
                              "Students Support Scholarship/financial support services to needy students?"
                            }
                            name={"StudentsSupportScholarship_financialsupportservicestoneedystudents"}
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            valueGot={dataSent.StudentsSupportScholarship_financialsupportservicestoneedystudentsNA}
                            name={"StudentsSupportScholarship_financialsupportservicestoneedystudentsNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentStudentsSupportScholarship_financialsupportservicestoneedystudents"
                          cols={80} // Increase the number of columns
                          rows={4}
                          type="text"
                          defaultValue={dataSent.commentStudentsSupportScholarship_financialsupportservicestoneedystudents}
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    { 
                      column4: `11.1 Community Services:
                      The programme has policies
                      && strategies for lecturers in
                      involve in community
                      services – Max 10`,
                      column5: (
                        <>
                          <InputNumberForScoreForEdit
                            maximum={10}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            valueGot={dataSent.CommunityServicesTheprogrammehaspoliciesstrategiesforlecturersininvolveincommunity}
                            placeholder={
                              "Community Services:  The programme has policies  && strategies for lecturers in involve in community?"
                            }
                            name={"CommunityServicesTheprogrammehaspoliciesstrategiesforlecturersininvolveincommunity"}
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            valueGot={dataSent.CommunityServicesTheprogrammehaspoliciesstrategiesforlecturersininvolveincommunityNA}
                            name={"CommunityServicesTheprogrammehaspoliciesstrategiesforlecturersininvolveincommunityNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentCommunityServicesTheprogrammehaspoliciesstrategiesforlecturersininvolveincommunity"
                          cols={80} // Increase the number of columns
                          rows={4}
                          type="text"
                          defaultValue={dataSent.commentCommunityServicesTheprogrammehaspoliciesstrategiesforlecturersininvolveincommunity}
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    { 
                      column4: `11.2 Community Services:
                      The strategies are reviewed
                      and improved every five (5)
                      years – Max 5`,
                      column5: (
                        <>
                          <InputNumberForScoreForEdit
                            maximum={5}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            valueGot={dataSent.CommunityServicesThestrategiesarereviewedAndimprovedeveryfive}
                            placeholder={
                              "Community Services: The strategies are reviewed and improved every five?"
                            }
                            name={"CommunityServicesThestrategiesarereviewedAndimprovedeveryfive"}
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            valueGot={dataSent.CommunityServicesThestrategiesarereviewedAndimprovedeveryfiveNA}
                            name={"CommunityServicesThestrategiesarereviewedAndimprovedeveryfiveNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentCommunityServicesThestrategiesarereviewedAndimprovedeveryfive"
                          cols={80} // Increase the number of columns
                          rows={4}
                          type="text"
                          defaultValue={dataSent.commentCommunityServicesThestrategiesarereviewedAndimprovedeveryfive}
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    { 
                      column4: `11.3 Community Services
                      Reports on impact and
                      benefits of the programme to
                      the real community(ies) –
                      Max 15`,
                      column5: (
                        <>
                          <InputNumberForScoreForEdit
                            maximum={15}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            valueGot={dataSent.CommunityServicesReportsonimpactandbenefitsoftheprogrammetotherealcommunity}
                            placeholder={
                              "Community Services  Reports on impact and benefits of the programme to the real community(ies)?"
                            }
                            name={"CommunityServicesReportsonimpactandbenefitsoftheprogrammetotherealcommunity"}
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            valueGot={dataSent.CommunityServicesReportsonimpactandbenefitsoftheprogrammetotherealcommunityNA}
                            name={"CommunityServicesReportsonimpactandbenefitsoftheprogrammetotherealcommunityNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentCommunityServicesReportsonimpactandbenefitsoftheprogrammetotherealcommunity"
                          cols={80} // Increase the number of columns
                          rows={4}
                          type="text"
                          defaultValue={dataSent.commentCommunityServicesReportsonimpactandbenefitsoftheprogrammetotherealcommunity}
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    { 
                      column4: `12.1 Programme Review:
                      Processes && procedures of
                      programme review – Max 15`,
                      column5: (
                        <>
                          <InputNumberForScoreForEdit
                            maximum={15}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            valueGot={dataSent.ProgrammeReviewProcessesproceduresofprogrammereview}
                            placeholder={
                              "Programme Review: Processes && procedures of programme review?"
                            }
                            name={"ProgrammeReviewProcessesproceduresofprogrammereview"}
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            valueGot={dataSent.ProgrammeReviewProcessesproceduresofprogrammereviewNA}
                            name={"ProgrammeReviewProcessesproceduresofprogrammereviewNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentProgrammeReviewProcessesproceduresofprogrammereview"
                          cols={80} // Increase the number of columns
                          rows={4}
                          type="text"
                          defaultValue={dataSent.commentProgrammeReviewProcessesproceduresofprogrammereview}
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    { 
                      column4: `12.2 Programme Review
                      Periodic review of
                      programme – Max 10`,
                      column5: (
                        <>
                          <InputNumberForScoreForEdit
                            maximum={10}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            valueGot={dataSent.programmeReviewPeriodicreviewofprogramme}
                            placeholder={
                              "Programme Review Periodic review of programme?"
                            }
                            name={"programmeReviewPeriodicreviewofprogramme"}
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            valueGot={dataSent.programmeReviewPeriodicreviewofprogrammeNA}
                            name={"programmeReviewPeriodicreviewofprogrammeNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentprogrammeReviewPeriodicreviewofprogramme"
                          cols={80} // Increase the number of columns
                          rows={4}
                          type="text"
                          defaultValue={dataSent.commentprogrammeReviewPeriodicreviewofprogramme}
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                    { 
                      column4: `12.3 Programme Review
                      Regular tracer studies – Max
                      15`,
                      column5: (
                        <>
                          <InputNumberForScoreForEdit
                            maximum={15}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            valueGot={dataSent.ProgrammeReviewRegulartracerstudies}
                            placeholder={
                              "Programme Review Regular tracer studies?"
                            }
                            name={"ProgrammeReviewRegulartracerstudies"}
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            valueGot={dataSent.ProgrammeReviewRegulartracerstudiesNA}
                            name={"ProgrammeReviewRegulartracerstudiesNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentProgrammeReviewRegulartracerstudies"
                          cols={80} // Increase the number of columns
                          rows={4}
                          type="text"
                          defaultValue={dataSent.commentProgrammeReviewRegulartracerstudies}
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },

                    { 
                      column4: `12.4 Programme Review
                      Procedures && Plans for staff
                      development based on
                      identified needs and
                      employer satisfaction – Max 15`,
                      column5: (
                        <>
                          <InputNumberForScoreForEdit
                            maximum={15}
                            minimum={0}
                            onChangeNumber={handleInputChangeNumber}
                            valueGot={dataSent.ProgrammeReviewProceduresPlansforstaffdevelopmentbasedonidentifiedneedsandemployersatisfaction}
                            placeholder={
                              "Programme Review Procedures && Plans for staff development based on identified needs and employer satisfaction?"
                            }
                            name={"ProgrammeReviewProceduresPlansforstaffdevelopmentbasedonidentifiedneedsandemployersatisfaction"}
                          />
                        </>
                      ),
                       column6: (
                        <>
                          <InputCheckboxNA
                            value={10}
                            onChangeNumber={handleInputChangeNumberNA}
                            valueGot={dataSent.ProgrammeReviewProceduresPlansforstaffdevelopmentbasedonidentifiedneedsandemployersatisfactionNA}
                            name={"ProgrammeReviewProceduresPlansforstaffdevelopmentbasedonidentifiedneedsandemployersatisfactionNA"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentProgrammeReviewProceduresPlansforstaffdevelopmentbasedonidentifiedneedsandemployersatisfaction"
                          cols={80} // Increase the number of columns
                          rows={4}
                          type="text"
                          defaultValue={dataSent.commentProgrammeReviewProceduresPlansforstaffdevelopmentbasedonidentifiedneedsandemployersatisfaction}
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%",
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                  ], // data content tag
                },
              ], // sub content
            },
          ],
        },

        // End of part 2
      ],
    },
  ],
});
