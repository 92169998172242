import React from "react";

const TabContentCHEA = ({ content, onChange }) => {
  console.log("content ", content);
  return (
    <div className="w-full">
      <>
        <table
          key={content.bigHeader}
          className="min-w-full bg-white border border-gray-300"
        >
          <thead>
            <tr>
              <th
                colSpan={4}
                className="py-4 uppercase font-extrabold text-black px-6 bg-gray-200"
              >
                {content.bigHeader}
              </th>
            </tr>
          </thead>

          {content.content.map((data) => {
            return (
              <>
                {data.subData.map((subInfo, index) => {
                  return (
                    <>
                      <tr>
                        <th
                          key={index}
                          colSpan={4}
                          className="py-4 px-6 bg-gray-100"
                        >
                          <span className="text-black ">{subInfo.number}.</span>
                          {subInfo.subtitleStandard}
                        </th>
                      </tr>
                      {subInfo.subcontent.map((dataSubContetn, index) => {
                        return (
                          <>
                            <tr>
                              <th
                                key={`New${index}`}
                                colSpan={4}
                                className="py-4 px-6 bg-gray-50"
                              >
                                {dataSubContetn.subtitleNorm}
                              </th>
                            </tr>
                            <tbody>
                              {dataSubContetn.dataContent.map(
                                (item, index) =>
                                  // Check if item is not null
                                  item && (
                                    <tr
                                      key={`Content${index}`}
                                      className={`${
                                        index % 2 === 0 ? "bg-gray-50" : ""
                                      }`}
                                    >
                                      <td
                                        className={`${
                                          index === 0
                                            ? "win-10 text-center "
                                            : "text-center  py-1 px-2 win-10 "
                                        }`}
                                      >
                                        {item.column4}
                                      </td>
                                      <td
                                        className={`${
                                          index === 0
                                            ? "win-200 w-5 text-center"
                                            : "w-5 win-200 "
                                        }`}
                                      >
                                        {item.column5}
                                      </td>
                                      <td
                                        className={`${
                                          index === 0
                                            ? "w-3 win-100 text-center"
                                            : " win-100 "
                                        }`}
                                      >
                                        {item.column6}
                                      </td>
                                      <td className="py-2 px-6">
                                        {item.column7}
                                      </td>
                                    </tr>
                                  )
                              )}
                              
                            </tbody>
                          </>
                        );
                      })}
                    </>
                  );
                })}
              </>
            );
          })}
        </table>
      </>

      {/* })} */}
    </div>
  );
};

export default TabContentCHEA;
