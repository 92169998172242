import React, { useState } from "react";

const TabCHEA = ({ tabs, submitButton }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [numberTabs, setNumberTabs] = useState(tabs.length);

  const handleTabClick = (index, event) => {
    event.preventDefault();
    console.log("index ", index);
    setActiveTab(index);
  };

  return (
    <div className="w-full">
      <div className="flex mb-4">
        {tabs.map((tab, index) => (
          <span
            key={index}
            className={`cursor-pointer ${
              activeTab === index ? "bg-blue-500 text-white" : "bg-gray-200"
            } px-4 py-2 mr-2 rounded`}
            onClick={(event) => handleTabClick(index, event)}
          >
            {tab.title}
          </span>
        ))}
      </div>
      <div className="bg-white p-4">
        {tabs[activeTab].content}
        <div className="grid grid-cols-2"> 
          {/* {activeTab === ((numberTabs-1)-activeTab) || activeTab === (numberTabs-1) || (numberTabs===2) ? partialSubmission : ""}
          {activeTab === (numberTabs-1) ? submitButton : ""} */}
        </div>
      </div>
      <div className="flex mb-4">
        {tabs.map((tab, index) => (
          <span
            key={index + 1}
            className={`cursor-pointer ${
              activeTab === index ? "bg-blue-500 text-white" : "bg-gray-200"
            } px-4 py-2 mr-2 rounded`}
            onClick={(event) => handleTabClick(index, event)}
          >
            {tab.title}
          </span>
        ))}
      </div>
    </div>
  );
};

export default TabCHEA;
