import React, { Fragment, useState, useEffect } from "react";

import Navbar from "./Navbar";
import Analytics from "./Analytics";
import Newsletter from "./Newsletter";
import Cards from "./Cards";
import Footer from "./Footer";
import LandingPage from "./LandingPage";
import NavbarLoggedIn from "../NavbarLoggedIn";
import { Redirect } from "react-router";
import { logout } from "../actions/auth";
import { connect } from "react-redux";
import { checkAuthenticated, load_user } from "../actions/auth";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Success from "../alerts/Success";
import Error from "../alerts/Error";

const AllComponents = ({ checkAuthenticated, isAuthenticated }) => {
  const [redirection, setRedirection] = useState(false);
  const history = useHistory();
  useEffect(() => {
    checkAuthenticated();
    load_user();
    setRedirection(true);
  }, []);
  return (
    <div className="w-full pt-8">
     {history?.location?.state === null ? (
        ""
      ) : history?.location?.state?.data?.status === 1 ? (
        <Success delay="5000" valueFromParent={history?.location?.state?.data} />
      ) : history?.location?.state?.data?.status === 0 ?(
        <Error delay="7000" valueFromParent={history?.location?.state?.data} />
      ) : <span></span>}
      <NavbarLoggedIn />
      <LandingPage />
      <Analytics />
      <Newsletter />
      <Cards />
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  userConnected: state.auth.user ?? "",
});

export default connect(mapStateToProps, {
  checkAuthenticated,
  load_user,
  logout,
})(AllComponents);
