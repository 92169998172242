import React, { useState } from "react";
import Navbar from "../general/Navbar";
import { Link, Redirect, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { reset_password } from "../actions/auth";
import Success from "../alerts/Success";
import Error from "../alerts/Error";
import Loading from "../eventsHandler/Loading";

const ResetPassword = ({ reset_password, isAuthenticated }) => {
  const [formData, setFormData] = useState({
    email: "", 
  });
  const [status, setStatus] = useState(false)
  const history = useHistory();

  const { email } = formData;

  const onChange = (e) =>
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

  const onSubmit = (e) => {
    setStatus(true);
    e.preventDefault();
    let response = reset_password(email);
    response.then((resp) => {
      console.log(resp)
      let data = {
        status: 1,
        title: "Good",
        message: "Check in your email box there is a link sent for reset your password",
      };
      setStatus(false);
      history.push("/", { data: data });
    }).catch(err =>{
      console.log(err)
      setStatus(false);
    }).finally(() => {
      setStatus(false);
    });
    // return <Redirect to={"/login"} />;
  };
 
console.log(status)
  if (isAuthenticated) {
    // window.location.href = '/dashboard';
    return <Redirect to={"/dashboard"} />;
  }

  return (
    <div>
      <div className="text-whit">
        {/* <Navbar /> */}
        {history?.location?.state === null ? (
        ""
      ) : history?.location?.state?.data?.status === 1 ? (
        <Success delay="5000" valueFromParent={history?.location?.state?.data} />
      ) : history?.location?.state?.data?.status === 0 ?(
        <Error delay="7000" valueFromParent={history?.location?.state?.data} />
      ) : <span></span>}
       
        {status && <Loading />}
        <div className="max-w-[800px] mt-[-96px] w-full h-screen mx-auto text-center flex flex-col justify-center ">
          <div className="w-full shadow-xl shadow-gray-600 rounded-xl dark:bg-[var(--color-dark-bakcgroundSecond)] bg-[var(--color-light-backgroundSecond)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)]">
            <p className="uppercase text-[#0969DA] p-2 md:txt-5xl sm:text-4xl text-2xl font-bold md:py-4">
              Reset Password
            </p>
            <form onSubmit={(e) => onSubmit(e)}>
              <div className="m-10">
                <div className="flex">
                  <input
                    className="p-3 flex w-full rounded-md text-black font-medium"
                    type="email"
                    placeholder="Enter your Email"
                    required
                    name="email"
                    value={email}
                    onChange={(e) => onChange(e)}
                  />
                </div>
              </div>
              <button
                className="uppercase bg-[#0969DA] w-[90%] mt-0 mb-4 rounded-md  font-bold my-0 mx-auto py-3 text-black"
                type="submit"
              >
                {" "}
                Send reset link
              </button>
            </form>
            <p className='mt-3 text-blue-700 pb-4'>
                                <Link to={"/"}>Home</Link>
                           </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { reset_password })(ResetPassword);
