import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import ApplicationService from "../../../services/ApplicationService";
import { connect } from "react-redux";
import { login } from "../../actions/auth";
import { ApplicationSchemasValidation } from "../../alerts/Validations";
import { useFormik, Field } from "formik";
import UserService from "../../../services/UserService";
import Loading from "../../eventsHandler/Loading";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const ApplicationAdd = (props) => {
  let editing = false;
  let initialTitle_program_application = "";
  let initialDesignation_application = "";
  let initialUniversity_id_application = "";
  let initialLevel_id_application = "";
  let initialOtherInfos = "";

  const [titleName, setTitleName] = useState(initialTitle_program_application);
  const [level, setLevel] = useState(initialLevel_id_application);
  const [designation, setDesignation] = useState(
    initialDesignation_application
  );
  const [otherInfo, setOtherInfo] = useState(initialOtherInfos);
  const [evidenceBorder, setEvidenceBorder] = useState("");
  const [classEvidence, setClassEvidence] = useState("hidden");
  const [thematic, setThematic] = useState([]);
  const [confirmationLetter, setConfirmationLetter] = useState("hidden");
  const [involvementNationalBody, setInvolvementNationalBody] =
    useState("hidden");

  const [curriculumDevelopmentYesOrNo, setCurriculumDevelopmentYesOrNo] =
    useState("hidden");
  const [postGraduateSupervisionYesOrNo, setPostGraduateSupervisionYesOrNo] =
    useState("hidden");
  const [qualityAssurencePolicyYesOrNo, setQualityAssurencePolicyYesOrNo] =
    useState("hidden");
  const [summaryStaffCapacityYesOrNo, setSummaryStaffCapacityYesOrNo] =
    useState("hidden");

  const [inventoryOfFacilitiesYesOrNo, setiInventoryOfFacilitiesYesOrNo] =
    useState("hidden");
  const [annualSurveyReportYesOrNo, setAnnualSurveyReportYesOrNo] =
    useState("hidden");
  const [examinationPolicyYesOrNo, setExaminationPolicyYesOrNo] =
    useState("hidden");
  const [admissionPolicyYesOrNo, setAdmissionPolicyYesOrNo] =
    useState("hidden");
  const [programReviewReportYesOrNo, setProgramReviewReportYesOrNo] =
    useState("hidden");

  const [evidenceNationalLevelCheckbox, setEvidenceNationalLevelCheckbox] =
    useState("0");
  const [confirmationLetterCheckbox, setConfirmationLetterCheckbox] =
    useState("0");
  const [involvementNationalCheckbox, setInvolvementNationalCheckbox] =
    useState("0");
  const [curriculumDevelopmentCheckbox, setCurriculumDevelopmentCheckbox] =
    useState("0");
  const [postGraduateSupervisionCheckbox, setPostGraduateSupervisionCheckbox] =
    useState("0");
  const [qualityAssurencePolicyCheckBox, setQualityAssurencePolicyCheckBox] =
    useState("0");
  const [summaryStaffCapacityCheckbox, setSummaryStaffCapacityCheckbox] =
    useState("0");
  const [inventoryOfFacilitiesCheckbox, setInventoryOfFacilitiesCheckbox] =
    useState("0");
  const [annualSurveyReportCheckbox, setAnnualSurveyReportCheckbox] =
    useState("0");
  const [examinationPolicyCheckbox, setExaminationPolicyCheckbox] =
    useState("0");
  const [admissionPolicyCheckbox, setAdmissionPolicyCheckbox] = useState("0");
  const [programReviewReportCheckbox, setProgramReviewReportCheckbox] =
    useState("0");

  const [newBatchNumber, setNewBatchNumber] = useState("");

  const [selectedDate, setSelectedDate] = useState(null);

  let levelData = JSON.parse(localStorage.getItem("levelData"));

  const history = useHistory();
  const [errorsGot, setErrorsGot] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [disableSaveBtn, setdisableSaveBtn] = useState("");

  if (!(history.location.state == null)) {
    const data = history.location.state.data;
    console.log("Data send: ");
    console.log(data);
  }

  useEffect(() => {
    ApplicationService.getTheNewBatchNumber(localStorage.getItem("access"))
      .then((resp) => {
        console.log("resp = ", resp.data.batchNumber);
        setNewBatchNumber(resp.data.batchNumber);
      })
      .catch((errors) => {
        if (errors.response.status === 401) {
          // Redirect to the login page
          history.push("/login");
        }
      })
      .finally(() => {
        // nothing
      });
  }, []);

  let allUniveData = JSON.parse(localStorage.getItem("universityData"));
  let universityName  = "";

  let userInfo = localStorage.getItem("userInfo");
  let universityID = JSON.parse(userInfo);

  allUniveData.map((dataUniv) => {
    if (dataUniv.id === Number(universityID.university)) {
      universityName = dataUniv.university_name;
    }
  });
 
  const onSubmit = (values, { resetForm }) => {
    setButtonDisabled(true);
    setdisableSaveBtn(
      "bg-gray-300 rounded cursor-not-allowed focus:outline-none disabled disabled:opacity-75"
    );
    console.log("check : ",universityName);
    // console.log(values);
    const newApplication = new FormData();
    newApplication.append(
      "title_program_application",
      values.title_program_application
    );
    newApplication.append(
      "designation_application",
      values.designation_application
    );
    newApplication.append("university_id_application", universityID.university);
    newApplication.append("level_id_application", values.level_id_application);
    newApplication.append(
      "otherInfor_application",
      values.otherInfor_application
    );
    newApplication.append(
      "thematicArea_application",
      values.thematicArea_application
    );

    newApplication.append(
      "evidenceNationalLevelDate",
      lastConvertion(selectedDate)
    );

    newApplication.append(
      "evidence_for_accreditation_at_national_level",
      values.evidence_for_accreditation_at_national_level
    );
    newApplication.append(
      "confirmation_letter_national_agency",
      values.confirmation_letter_national_agency
    );
    newApplication.append(
      "involvment_of_the_relevant_national_bodies",
      values.involvment_of_the_relevant_national_bodies
    );

    newApplication.append(
      "curriculum_development",
      values.curriculum_development
    );
    newApplication.append(
      "postgraduate_supervision_guidelines",
      values.postgraduate_supervision_guidelines
    );
    newApplication.append(
      "quality_assurence_policy",
      values.quality_assurence_policy
    );

    newApplication.append(
      "credit_transfer_policy",
      values.credit_transfer_policy
    );
    newApplication.append(
      "summary_of_staff_capacity",
      values.summary_of_staff_capacity
    );
    newApplication.append(
      "inventory_of_facilities",
      values.inventory_of_facilities
    );

    newApplication.append("annual_survey_report", values.annual_survey_report);
    newApplication.append("tracer_study_report", values.tracer_study_report);
    newApplication.append("examination_policy", values.examination_policy);

    newApplication.append("market_survey_report", values.market_survey_report);
    newApplication.append("auditReport_report", values.auditReport_report);
    newApplication.append(
      "confirmation_letter_of_key_stackHolder",
      values.confirmation_letter_of_key_stackHolder
    );
    newApplication.append(
      "curriculum_development_and_guidelines",
      values.curriculum_development_and_guidelines
    );
    newApplication.append(
      "evidence_of_participation_of_stackHolder",
      values.evidence_of_participation_of_stackHolder
    );
    newApplication.append("programme_file", values.programme_file);
    newApplication.append("evidence_of_payment", values.evidence_of_payment);

    newApplication.append("admission_policy", values.admission_policy);
    newApplication.append(
      "program_review_report",
      values.program_review_report
    );
    newApplication.append("other_not_specified", values.other_not_specified);
    newApplication.append("batchNumber_Application", newBatchNumber);

    console.log("newApplication: ");
    console.log(newApplication);

    if (!editing) {
      ApplicationService.createApplication(
        newApplication,
        localStorage.getItem("access"),
        universityName
      )
        .then((response) => {
          setdisableSaveBtn(false);
          let data = {
            status: 1,
            title: "Okay",
            message: "Saved with Success ... Wait for feedback please!!!",
            statesdata: {
              currentApplication: response.data,
              typeOperation: "addNew",
            },
          };
          history.push("/dashboard/", { data: data });
        })
        .catch((error) => {
          setButtonDisabled(false);
          if (error.response.status === 401) {
            // Redirect to the login page
            history.push("/login");
          }
          let data = {
            status: 0,
            title: "Error",
            message: "An Error happened !",
          };
          history.push("/dashboard/", { data: data });
        });
    }
  };

  const {
    values,
    errors,
    touched,
    isSubmitting,
    setFieldValue,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      designation_application: "",
      title_program_application: initialTitle_program_application,
      university_id_application: initialUniversity_id_application,

      evidenceNationalLevelDate: "",
      dateReceivedEvidentDate: "",
      evidenceNationalLevelYesOrNo: "",
      involvmentNationalBodiesYesOrNo: "",
      curriculumDevelopmentYesOrNo: "",
      postGraduateSupervisionYesOrNo: "",
      qualityAssurencePolicyYesOrNo: "",
      summaryStaffCapacityYesOrNo: "",
      inventoryOfFacilitiesYesOrNo: "",
      annualSurveyReportYesOrNo: "",
      examinationPolicyYesOrNo: "",
      admissionPolicyYesOrNo: "",
      programReviewReportYesOrNo: "",
      confirmationLetterYesOrNo: "",

      evidence_for_accreditation_at_national_level: "",
      confirmation_letter_national_agency: "",
      involvment_of_the_relevant_national_bodies: "",

      evidence_for_accreditation_at_national_level: "",
      confirmation_letter_national_agency: "",
      involvment_of_the_relevant_national_bodies: "",

      curriculum_development: "",
      postgraduate_supervision_guidelines: "",
      quality_assurence_policy: "",

      credit_transfer_policy: "",
      summary_of_staff_capacity: "",
      inventory_of_facilities: "",

      annual_survey_report: "",
      tracer_study_report: "",
      examination_policy: "",

      admission_policy: "",
      program_review_report: "",
      other_not_specified: "",
      thematicArea_application: "",
      programme_file: "",
      evidence_of_payment: "",
      auditReport_report: "",
      confirmation_letter_of_key_stackHolder: "",
      curriculum_development_and_guidelines: "",
      evidence_of_participation_of_stackHolder: "",
      market_survey_report: "",

      otherInfor_application: initialOtherInfos,
      level_id_application: initialLevel_id_application,
    },
    validationSchema: ApplicationSchemasValidation,
    onSubmit,
  });

  const evidenceLetterNationalLevelEvent = (e) => {
    if (e.target.checked) {
      setClassEvidence("");
      setEvidenceBorder("border border-gray-400");
      setEvidenceNationalLevelCheckbox(1);
    } else {
      setClassEvidence("hidden");
      setEvidenceBorder("");
    }
  };

  const confirmationLetterEvent = (e) => {
    if (e.target.checked) {
      setConfirmationLetter("");
      setConfirmationLetterCheckbox(1);
    } else {
      setConfirmationLetter("hidden");
    }
  };

  const involvmentNationalBodies = (e) => {
    if (e.target.checked) {
      setInvolvementNationalBody("");
      setInvolvementNationalCheckbox(1);
    } else {
      setInvolvementNationalBody("hidden");
      setInvolvementNationalCheckbox(0);
    }
  };

  const curriculum_development = (e) => {
    if (e.target.checked) {
      setCurriculumDevelopmentYesOrNo("");
      setCurriculumDevelopmentCheckbox(1);
    } else {
      setCurriculumDevelopmentYesOrNo("hidden");
    }
  };

  const postgraduate_supervision_guidelines = (e) => {
    if (e.target.checked) {
      setPostGraduateSupervisionYesOrNo("");
      setPostGraduateSupervisionCheckbox(1);
    } else {
      setPostGraduateSupervisionYesOrNo("hidden");
    }
  };

  const quality_assurence_policy = (e) => {
    if (e.target.checked) {
      setQualityAssurencePolicyYesOrNo("");
      setQualityAssurencePolicyCheckBox(1);
    } else {
      setQualityAssurencePolicyYesOrNo("hidden");
    }
  };

  const summary_of_staff_capacity = (e) => {
    if (e.target.checked) {
      setSummaryStaffCapacityYesOrNo("");
      setSummaryStaffCapacityCheckbox(1);
    } else {
      setSummaryStaffCapacityYesOrNo("hidden");
    }
  };

  const inventory_of_facilities = (e) => {
    if (e.target.checked) {
      setiInventoryOfFacilitiesYesOrNo("");
      setInventoryOfFacilitiesCheckbox(1);
    } else {
      setiInventoryOfFacilitiesYesOrNo("hidden");
    }
  };

  const annual_survey_report = (e) => {
    if (e.target.checked) {
      setAnnualSurveyReportYesOrNo("");
      setAnnualSurveyReportCheckbox(1);
    } else {
      setAnnualSurveyReportYesOrNo("hidden");
    }
  };

  const examination_policy = (e) => {
    if (e.target.checked) {
      setExaminationPolicyYesOrNo("");
      setExaminationPolicyCheckbox(1);
    } else {
      setExaminationPolicyYesOrNo("hidden");
    }
  };

  const admission_policy = (e) => {
    if (e.target.checked) {
      setAdmissionPolicyYesOrNo("");
      setAdmissionPolicyCheckbox(1);
    } else {
      setAdmissionPolicyYesOrNo("hidden");
    }
  };

  const program_review_report = (e) => {
    if (e.target.checked) {
      setProgramReviewReportYesOrNo("");
      setProgramReviewReportCheckbox(1);
    } else {
      setProgramReviewReportYesOrNo("hidden");
    }
  };

  useEffect(() => {
    // getThematic
    let url = `${process.env.REACT_APP_API_URL}/api/thematicArea/`;
    UserService.getThematic(url)
      .then((response) => {
        console.log("response");
        console.log(response.data);
        setThematic(response.data);
      })
      .catch((errors) => {
        console.log("errors");
        console.log(errors);
        if (errors.response.status === 401) {
          // Redirect to the login page
          history.push("/login");
        }
      });
  }, []);

  // console.log(errors);
  const today = new Date();

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const lastConvertion = (date) => {
    if (date instanceof Date) {
      const formattedDate =
        date.getFullYear() +
        "-" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + date.getDate()).slice(-2) +
        " " +
        ("0" + date.getHours()).slice(-2) +
        ":" +
        ("0" + date.getMinutes()).slice(-2) +
        ":" +
        ("0" + date.getSeconds()).slice(-2);

      return formattedDate;
    }
    return "";
  };

  return (
    <div>
      <div className="w-full p-8">
        {disableSaveBtn && <Loading />}

        <form onSubmit={handleSubmit} autoComplete="off">
          <div className="grid grid-cols-2 mb-4 text-md pt-4">
            <div className="mr-2  md:mb-0">
              <label
                className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2"
                htmlFor="grid-first-name"
              >
                Title of the Programme <span className="text-red-600">*</span>
              </label>
              <input
                className={`
                               ${
                                 errors.title_program_application &&
                                 touched.title_program_application
                                   ? "input-error"
                                   : ""
                               }
                              appearance-none block w-full  dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)]  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                id="title_program_application"
                type="text"
                name="title_program_application"
                value={values.title_program_application}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Title of the programme"
              />
              {errors.title_program_application &&
              touched.title_program_application ? (
                <p className="text-red-500 text-xs italic">
                  {errors.title_program_application}
                </p>
              ) : (
                ""
              )}
            </div>
            <div className="  md:mb-0 text-md ">
              <label
                className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2"
                htmlFor="grid-first-name"
              >
                Level<span className="text-red-600">*</span>
              </label>
              <div className="relative">
                <select
                  className={`
                              ${
                                errors.level_id_application &&
                                touched.level_id_application
                                  ? "input-error"
                                  : ""
                              }
                              block appearance-none w-full border border-gray-200 dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                  id="level_id_application"
                  name="level_id_application"
                  value={values.level_id_application}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value={0}>select please</option>
                  {levelData.map((levelDB) => {
                    return (
                      <option
                        selected={levelDB.id_level === level}
                        value={levelDB.id_level}
                      >
                        {levelDB.designation_level}
                      </option>
                    );
                  })}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)]">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
              {errors.level_id_application && touched.level_id_application ? (
                <p className="text-red-500 text-xs italic">
                  {errors.level_id_application}
                </p>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="grid grid-cols-2 mb-4 text-md pt-4">
            <div className="mr-2  md:mb-0">
              <label
                className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2"
                htmlFor="grid-first-name"
              >
                Discipline Cluster<span className="text-red-600">*</span>
              </label>
              <div className="relative">
                <select
                  className={`
                              ${
                                errors.thematicArea_application &&
                                touched.thematicArea_application
                                  ? "input-error"
                                  : ""
                              }
                              block appearance-none w-full border border-gray-200 dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                  id="thematicArea_application"
                  name="thematicArea_application"
                  value={values.thematicArea_application}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value={""}>select please</option>
                  {thematic.map((thematic) => {
                    return (
                      <option value={thematic.id_thematicsArea}>
                        {thematic.designation_thematicsArea}
                      </option>
                    );
                  })}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)]">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
              {errors.thematicArea_application &&
              touched.thematicArea_application ? (
                <p className="text-red-500 text-xs italic">
                  {errors.thematicArea_application}
                </p>
              ) : (
                ""
              )}
            </div>

            <div className=" md:mb-0 text-md ">
              <label
                className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2"
                htmlFor="grid-last-name"
              >
                Other Informations <span className="text-gray-600">*</span>
              </label>
              <textarea
                className="appearance-none block w-full dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] border border-gray-200 rounded py-3 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-last-name"
                type="text"
                placeholder="Give others details about the course if any"
                name="otherInfor_application"
                value={values.otherInfor_application}
                onChange={handleChange}
                onBlur={handleBlur}
                rows={4}
              ></textarea>
            </div>
          </div>

          <div className={`w-full text-md mb-4 hidden`}>
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2"
              htmlFor="grid-password"
            >
              University
            </label>
            <input
              className="appearance-none block w-full dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-password"
              value={universityName}
              type="text"
            />
          </div>

          <div className={`w-full text-md mb-4`}>
            <label
              className="block capitalize text-md tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-0"
              htmlFor="grid-city"
            >
              Evidence for accreditation at national level
              <span className="text-red-600">*</span>
            </label>

            <div className={`grid grid-cols-3 ${evidenceBorder}`}>
              <div className="validation mt-3">
                <input
                  className="
                      relative 
                      float-left  
                      mr-[6px] 
                      mt-[0.15rem] 
                      h-[1.125rem] 
                      w-[1.125rem] 
                      rounded-[0.25rem] 
                      border-[0.125rem] 
                      border-solid 
                      border-neutral-300 
                      outline-none 
                      before:pointer-events-none 
                      before:absolute 
                      before:h-[0.875rem] 
                      before:w-[0.875rem] 
                      before:scale-0 
                      before:rounded-full 
                      before:bg-transparent 
                      before:opacity-0 
                      before:shadow-[0px_0px_0px_13px_transparent] 
                      before:content-[''] 
                      checked:border-primary 
                      checked:bg-primary 
                      checked:before:opacity-[0.16] 
                      checked:after:absolute 
                      checked:after:-mt-px 
                      checked:after:ml-[0.25rem] 
                      checked:after:block 
                      checked:after:h-[0.8125rem] 
                      checked:after:w-[0.375rem] 
                      checked:after:rotate-45 
                      checked:after:border-[0.125rem] 
                      checked:after:border-l-0 
                      checked:after:border-t-0 
                      checked:after:border-solid 
                      checked:after:border-white 
                      checked:after:bg-transparent 
                      checked:after:content-[''] 
                      hover:cursor-pointer 
                      hover:before:opacity-[0.04] 
                      hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] 
                      focus:shadow-none focus:transition-[border-color_0.2s] 
                      focus:before:scale-100 focus:before:opacity-[0.12] 
                      focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] 
                      focus:before:transition-[box-shadow_0.2s,transform_0.2s] 
                      focus:after:absolute focus:after:z-[1] focus:after:block 
                      focus:after:h-[0.875rem] focus:after:w-[0.875rem] 
                      focus:after:rounded-[0.125rem] 
                      focus:after:content-[''] 
                      checked:focus:before:scale-100 
                      checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] 
                      checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] 
                      checked:focus:after:-mt-px 
                      checked:focus:after:ml-[0.25rem] 
                      checked:focus:after:h-[0.8125rem] 
                      checked:focus:after:w-[0.375rem] checked:focus:after:rotate-45 
                      checked:focus:after:rounded-none checked:focus:after:border-[0.125rem] 
                      checked:focus:after:border-l-0 checked:focus:after:border-t-0 
                      checked:focus:after:border-solid 
                      checked:focus:after:border-white 
                      checked:focus:after:bg-transparent 
                      dark:border-neutral-600 
                      dark:checked:border-primary 
                      dark:checked:bg-primary 
                      dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] 
                      dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]"
                  onChange={(e) => evidenceLetterNationalLevelEvent(e)}
                  onBlur={handleBlur}
                  type="checkbox"
                  name="evidenceNationalLevelYesOrNo"
                  value={values.evidenceNationalLevelYesOrNo}
                  id="evidenceNationalLevelYesOrNo"
                />{" "}
                Check if you have the document
              </div>
              <div className="mt-3">
                <DatePicker
                  selected={selectedDate}
                  className={`dateAndInputFile ${classEvidence}`}
                  onChange={handleDateChange}
                  dateFormat="dd MMMM yyyy HH:mm"
                  placeholderText="Provide a date"
                  minDate={new Date(2000, 0, 1)}
                  calendarIcon={null}
                  clearIcon={null}
                  maxDate={today}
                  required
                />
              </div>
              <input
                className={`
                                        dateAndInputFile ${classEvidence}
                                        ${
                                          errors.evidence_for_accreditation_at_national_level &&
                                          touched.evidence_for_accreditation_at_national_level
                                            ? "input-error"
                                            : ""
                                        }
                                        appearance-none block w-full dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] border border-gray-200 rounded py-3 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                id="evidence_for_accreditation_at_national_level"
                type="file"
                accept="application/pdf"
                onChange={(event) => {
                  setFieldValue(
                    "evidence_for_accreditation_at_national_level",
                    event.currentTarget.files[0]
                  );
                }}
                onBlur={handleBlur}
              />
              {errors.evidence_for_accreditation_at_national_level &&
              touched.evidence_for_accreditation_at_national_level ? (
                <p className="text-red-500 text-xs italic">
                  {errors.evidence_for_accreditation_at_national_level}
                </p>
              ) : (
                ""
              )}
            </div>
          </div>

          {/* New documents */}

          <div className={`w-full text-md mb-4 ${classEvidence}`}>
            <label
              className="block text-md capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-0"
              htmlFor="grid-city"
            >
              Audit Report of the programme
              <span className="text-red-600">*</span>
            </label>

            <div>
              <input
                className={`
                                    ${
                                      errors.auditReport_report &&
                                      touched.auditReport_report
                                        ? "input-error"
                                        : ""
                                    }
                                    appearance-none block w-full dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] border border-gray-200 rounded py-3 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                id="auditReport_report"
                type="file"
                accept="application/pdf"
                onChange={(event) => {
                  setFieldValue(
                    "auditReport_report",
                    event.currentTarget.files[0]
                  );
                }}
                onBlur={handleBlur}
              />
              {errors.auditReport_report && touched.auditReport_report ? (
                <p className="text-red-500 text-xs italic">
                  {errors.auditReport_report}
                </p>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className={`w-full text-md mb-4 ${classEvidence}`}>
            <label
              className="block text-md capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-0"
              htmlFor="grid-city"
            >
              confirmation letter from stakeholders
              <span className="text-red-600">*</span>
            </label>

            <div>
              <input
                className={`
                                    ${
                                      errors.confirmation_letter_of_key_stackHolder &&
                                      touched.confirmation_letter_of_key_stackHolder
                                        ? "input-error"
                                        : ""
                                    }
                                    appearance-none block w-full dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] border border-gray-200 rounded py-3 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                id="confirmation_letter_of_key_stackHolder"
                type="file"
                accept="application/pdf"
                onChange={(event) => {
                  setFieldValue(
                    "confirmation_letter_of_key_stackHolder",
                    event.currentTarget.files[0]
                  );
                }}
                onBlur={handleBlur}
              />
              {errors.confirmation_letter_of_key_stackHolder &&
              touched.confirmation_letter_of_key_stackHolder ? (
                <p className="text-red-500 text-xs italic">
                  {errors.confirmation_letter_of_key_stackHolder}
                </p>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className={`w-full text-md mb-4 ${classEvidence}`}>
            <label
              className="block text-md capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-0"
              htmlFor="grid-city"
            >
              curriculum development and guidelines
              <span className="text-red-600">*</span>
            </label>

            <div>
              <input
                className={`
                                    ${
                                      errors.curriculum_development_and_guidelines &&
                                      touched.curriculum_development_and_guidelines
                                        ? "input-error"
                                        : ""
                                    }
                                    appearance-none block w-full dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] border border-gray-200 rounded py-3 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                id="curriculum_development_and_guidelines"
                type="file"
                accept="application/pdf"
                onChange={(event) => {
                  setFieldValue(
                    "curriculum_development_and_guidelines",
                    event.currentTarget.files[0]
                  );
                }}
                onBlur={handleBlur}
              />
              {errors.curriculum_development_and_guidelines &&
              touched.curriculum_development_and_guidelines ? (
                <p className="text-red-500 text-xs italic">
                  {errors.curriculum_development_and_guidelines}
                </p>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className={`w-full text-md mb-4 ${classEvidence}`}>
            <label
              className="block text-md capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-0"
              htmlFor="grid-city"
            >
              evidence of participation of stakeholders
              <span className="text-red-600">*</span>
            </label>

            <div>
              <input
                className={`
                                    ${
                                      errors.evidence_of_participation_of_stackHolder &&
                                      touched.evidence_of_participation_of_stackHolder
                                        ? "input-error"
                                        : ""
                                    }
                                    appearance-none block w-full dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] border border-gray-200 rounded py-3 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                id="evidence_of_participation_of_stackHolder"
                type="file"
                accept="application/pdf"
                onChange={(event) => {
                  setFieldValue(
                    "evidence_of_participation_of_stackHolder",
                    event.currentTarget.files[0]
                  );
                }}
                onBlur={handleBlur}
              />
              {errors.evidence_of_participation_of_stackHolder &&
              touched.evidence_of_participation_of_stackHolder ? (
                <p className="text-red-500 text-xs italic">
                  {errors.evidence_of_participation_of_stackHolder}
                </p>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className={`w-full text-md mb-4 ${classEvidence}`}>
            <label
              className="block text-md capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-0"
              htmlFor="grid-city"
            >
              market survey report
              <span className="text-red-600">*</span>
            </label>

            <div>
              <input
                className={`
                                    ${
                                      errors.market_survey_report &&
                                      touched.market_survey_report
                                        ? "input-error"
                                        : ""
                                    }
                                    appearance-none block w-full dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] border border-gray-200 rounded py-3 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                id="market_survey_report"
                type="file"
                accept="application/pdf"
                onChange={(event) => {
                  setFieldValue(
                    "market_survey_report",
                    event.currentTarget.files[0]
                  );
                }}
                onBlur={handleBlur}
              />
              {errors.market_survey_report && touched.market_survey_report ? (
                <p className="text-red-500 text-xs italic">
                  {errors.market_survey_report}
                </p>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className={`w-full text-md mb-4 ${classEvidence}`}>
            <label
              className="block text-md capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-0"
              htmlFor="grid-city"
            >
              Curriculum/programme document
              <span className="text-red-600">*</span>
            </label>

            <div>
              <input
                className={`
                                    ${
                                      errors.programme_file &&
                                      touched.programme_file
                                        ? "input-error"
                                        : ""
                                    }
                                    appearance-none block w-full dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] border border-gray-200 rounded py-3 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                id="programme_file"
                type="file"
                accept="application/pdf"
                onChange={(event) => {
                  setFieldValue("programme_file", event.currentTarget.files[0]);
                }}
                onBlur={handleBlur}
              />
              {errors.programme_file && touched.programme_file ? (
                <p className="text-red-500 text-xs italic">
                  {errors.programme_file}
                </p>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className={`w-full text-md mb-4 ${classEvidence}`}>
            <label
              className="block text-md capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-0"
              htmlFor="grid-city"
            >
              evidence of payment
              <span className="text-red-600">*</span>
            </label>

            <div>
              <input
                className={`
                                    ${
                                      errors.evidence_of_payment &&
                                      touched.evidence_of_payment
                                        ? "input-error"
                                        : ""
                                    }
                                    appearance-none block w-full dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] border border-gray-200 rounded py-3 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                id="evidence_of_payment"
                type="file"
                accept="application/pdf"
                onChange={(event) => {
                  setFieldValue(
                    "evidence_of_payment",
                    event.currentTarget.files[0]
                  );
                }}
                onBlur={handleBlur}
              />
              {errors.evidence_of_payment && touched.evidence_of_payment ? (
                <p className="text-red-500 text-xs italic">
                  {errors.evidence_of_payment}
                </p>
              ) : (
                ""
              )}
            </div>
          </div>
          {/* New documents */}

          <div className={`w-full text-md mb-4 ${classEvidence}`}>
            <label
              className="block text-md capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-0"
              htmlFor="grid-city"
            >
              Confirmation letter from the national agency
              <span className="text-red-600">*</span>
            </label>
            <div>
              <input
                className="
              relative 
              float-left  
              mr-[6px] 
              mt-[0.15rem] 
              h-[1.125rem] 
              w-[1.125rem] 
              rounded-[0.25rem] 
              border-[0.125rem] 
              border-solid 
              border-neutral-300 
              outline-none 
              before:pointer-events-none 
              before:absolute 
              before:h-[0.875rem] 
              before:w-[0.875rem] 
              before:scale-0 
              before:rounded-full 
              before:bg-transparent 
              before:opacity-0 
              before:shadow-[0px_0px_0px_13px_transparent] 
              before:content-[''] 
              checked:border-primary 
              checked:bg-primary 
              checked:before:opacity-[0.16] 
              checked:after:absolute 
              checked:after:-mt-px 
              checked:after:ml-[0.25rem] 
              checked:after:block 
              checked:after:h-[0.8125rem] 
              checked:after:w-[0.375rem] 
              checked:after:rotate-45 
              checked:after:border-[0.125rem] 
              checked:after:border-l-0 
              checked:after:border-t-0 
              checked:after:border-solid 
              checked:after:border-white 
              checked:after:bg-transparent 
              checked:after:content-[''] 
              hover:cursor-pointer 
              hover:before:opacity-[0.04] 
              hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] 
              focus:shadow-none focus:transition-[border-color_0.2s] 
              focus:before:scale-100 focus:before:opacity-[0.12] 
              focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] 
              focus:before:transition-[box-shadow_0.2s,transform_0.2s] 
              focus:after:absolute focus:after:z-[1] focus:after:block 
              focus:after:h-[0.875rem] focus:after:w-[0.875rem] 
              focus:after:rounded-[0.125rem] 
              focus:after:content-[''] 
              checked:focus:before:scale-100 
              checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] 
              checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] 
              checked:focus:after:-mt-px 
              checked:focus:after:ml-[0.25rem] 
              checked:focus:after:h-[0.8125rem] 
              checked:focus:after:w-[0.375rem] checked:focus:after:rotate-45 
              checked:focus:after:rounded-none checked:focus:after:border-[0.125rem] 
              checked:focus:after:border-l-0 checked:focus:after:border-t-0 
              checked:focus:after:border-solid 
              checked:focus:after:border-white 
              checked:focus:after:bg-transparent 
              dark:border-neutral-600 
              dark:checked:border-primary 
              dark:checked:bg-primary 
              dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] 
              dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]"
                type="checkbox"
                onChange={(e) => confirmationLetterEvent(e)}
                onBlur={handleBlur}
                value={values.confirmationLetterYesOrNo}
                name="confirmationLetterYesOrNo"
                id="confirmationLetterYesOrNo"
              />{" "}
              Check if you have the document
            </div>
            <div className={`${confirmationLetter}`}>
              <input
                className={`
                                    ${
                                      errors.confirmation_letter_national_agency &&
                                      touched.confirmation_letter_national_agency
                                        ? "input-error"
                                        : ""
                                    }
                                    appearance-none block w-full dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] border border-gray-200 rounded py-3 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                id="confirmation_letter_national_agency"
                type="file"
                accept="application/pdf"
                onChange={(event) => {
                  setFieldValue(
                    "confirmation_letter_national_agency",
                    event.currentTarget.files[0]
                  );
                }}
                onBlur={handleBlur}
              />
              {errors.confirmation_letter_national_agency &&
              touched.confirmation_letter_national_agency ? (
                <p className="text-red-500 text-xs italic">
                  {errors.confirmation_letter_national_agency}
                </p>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className={`w-full text-md mb-4 ${classEvidence}`}>
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-0"
              htmlFor="grid-city"
            >
              Curriculum development<span className="text-red-600">*</span>
            </label>
            <div>
              <input
                className="
                      relative 
                      float-left  
                      mr-[6px] 
                      mt-[0.15rem] 
                      h-[1.125rem] 
                      w-[1.125rem] 
                      rounded-[0.25rem] 
                      border-[0.125rem] 
                      border-solid 
                      border-neutral-300 
                      outline-none 
                      before:pointer-events-none 
                      before:absolute 
                      before:h-[0.875rem] 
                      before:w-[0.875rem] 
                      before:scale-0 
                      before:rounded-full 
                      before:bg-transparent 
                      before:opacity-0 
                      before:shadow-[0px_0px_0px_13px_transparent] 
                      before:content-[''] 
                      checked:border-primary 
                      checked:bg-primary 
                      checked:before:opacity-[0.16] 
                      checked:after:absolute 
                      checked:after:-mt-px 
                      checked:after:ml-[0.25rem] 
                      checked:after:block 
                      checked:after:h-[0.8125rem] 
                      checked:after:w-[0.375rem] 
                      checked:after:rotate-45 
                      checked:after:border-[0.125rem] 
                      checked:after:border-l-0 
                      checked:after:border-t-0 
                      checked:after:border-solid 
                      checked:after:border-white 
                      checked:after:bg-transparent 
                      checked:after:content-[''] 
                      hover:cursor-pointer 
                      hover:before:opacity-[0.04] 
                      hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] 
                      focus:shadow-none focus:transition-[border-color_0.2s] 
                      focus:before:scale-100 focus:before:opacity-[0.12] 
                      focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] 
                      focus:before:transition-[box-shadow_0.2s,transform_0.2s] 
                      focus:after:absolute focus:after:z-[1] focus:after:block 
                      focus:after:h-[0.875rem] focus:after:w-[0.875rem] 
                      focus:after:rounded-[0.125rem] 
                      focus:after:content-[''] 
                      checked:focus:before:scale-100 
                      checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] 
                      checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] 
                      checked:focus:after:-mt-px 
                      checked:focus:after:ml-[0.25rem] 
                      checked:focus:after:h-[0.8125rem] 
                      checked:focus:after:w-[0.375rem] checked:focus:after:rotate-45 
                      checked:focus:after:rounded-none checked:focus:after:border-[0.125rem] 
                      checked:focus:after:border-l-0 checked:focus:after:border-t-0 
                      checked:focus:after:border-solid 
                      checked:focus:after:border-white 
                      checked:focus:after:bg-transparent 
                      dark:border-neutral-600 
                      dark:checked:border-primary 
                      dark:checked:bg-primary 
                      dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] 
                      dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]"
                type="checkbox"
                values={values.curriculumDevelopmentYesOrNo}
                onChange={(e) => curriculum_development(e)}
                name="curriculum_developmentYesOrNo"
                id="curriculum_developmentYesOrNo"
              />{" "}
              Check if you have the document
            </div>
            <input
              className={`
                                    ${curriculumDevelopmentYesOrNo}
                               ${
                                 errors.curriculum_development &&
                                 touched.curriculum_development
                                   ? "input-error"
                                   : ""
                               }
                                    appearance-none block w-full dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] border border-gray-200 rounded py-3 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
              id="curriculum_development"
              type="file"
              accept="application/pdf"
              onChange={(event) => {
                setFieldValue(
                  "curriculum_development",
                  event.currentTarget.files[0]
                );
              }}
              onBlur={handleBlur}
            />
            {errors.curriculum_development && touched.curriculum_development ? (
              <p className="text-red-500 text-xs italic">
                {errors.curriculum_development}
              </p>
            ) : (
              ""
            )}
          </div>

          <div className={`w-full text-md mb-4 ${classEvidence}`}>
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-0"
              htmlFor="grid-city"
            >
              Quality Assurance policy<span className="text-red-600">*</span>
            </label>
            <div>
              <input
                className="
                      relative 
                      float-left  
                      mr-[6px] 
                      mt-[0.15rem] 
                      h-[1.125rem] 
                      w-[1.125rem] 
                      rounded-[0.25rem] 
                      border-[0.125rem] 
                      border-solid 
                      border-neutral-300 
                      outline-none 
                      before:pointer-events-none 
                      before:absolute 
                      before:h-[0.875rem] 
                      before:w-[0.875rem] 
                      before:scale-0 
                      before:rounded-full 
                      before:bg-transparent 
                      before:opacity-0 
                      before:shadow-[0px_0px_0px_13px_transparent] 
                      before:content-[''] 
                      checked:border-primary 
                      checked:bg-primary 
                      checked:before:opacity-[0.16] 
                      checked:after:absolute 
                      checked:after:-mt-px 
                      checked:after:ml-[0.25rem] 
                      checked:after:block 
                      checked:after:h-[0.8125rem] 
                      checked:after:w-[0.375rem] 
                      checked:after:rotate-45 
                      checked:after:border-[0.125rem] 
                      checked:after:border-l-0 
                      checked:after:border-t-0 
                      checked:after:border-solid 
                      checked:after:border-white 
                      checked:after:bg-transparent 
                      checked:after:content-[''] 
                      hover:cursor-pointer 
                      hover:before:opacity-[0.04] 
                      hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] 
                      focus:shadow-none focus:transition-[border-color_0.2s] 
                      focus:before:scale-100 focus:before:opacity-[0.12] 
                      focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] 
                      focus:before:transition-[box-shadow_0.2s,transform_0.2s] 
                      focus:after:absolute focus:after:z-[1] focus:after:block 
                      focus:after:h-[0.875rem] focus:after:w-[0.875rem] 
                      focus:after:rounded-[0.125rem] 
                      focus:after:content-[''] 
                      checked:focus:before:scale-100 
                      checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] 
                      checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] 
                      checked:focus:after:-mt-px 
                      checked:focus:after:ml-[0.25rem] 
                      checked:focus:after:h-[0.8125rem] 
                      checked:focus:after:w-[0.375rem] checked:focus:after:rotate-45 
                      checked:focus:after:rounded-none checked:focus:after:border-[0.125rem] 
                      checked:focus:after:border-l-0 checked:focus:after:border-t-0 
                      checked:focus:after:border-solid 
                      checked:focus:after:border-white 
                      checked:focus:after:bg-transparent 
                      dark:border-neutral-600 
                      dark:checked:border-primary 
                      dark:checked:bg-primary 
                      dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] 
                      dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]"
                type="checkbox"
                values={values.qualityAssurencePolicyYesOrNo}
                onChange={(e) => quality_assurence_policy(e)}
                name="quality_assurence_policyYesOrNo"
                id="quality_assurence_policyYesOrNo"
              />{" "}
              Check if you have the document
            </div>
            <input
              className={`
                                    ${qualityAssurencePolicyYesOrNo}
                               ${
                                 errors.quality_assurence_policy &&
                                 touched.quality_assurence_policy
                                   ? "input-error"
                                   : ""
                               }
                                appearance-none block w-full dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] border border-gray-200 rounded py-3 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
              id="quality_assurence_policy"
              type="file"
              accept="application/pdf"
              onChange={(event) => {
                setFieldValue(
                  "quality_assurence_policy",
                  event.currentTarget.files[0]
                );
              }}
              onBlur={handleBlur}
            />
            {errors.quality_assurence_policy &&
            touched.quality_assurence_policy ? (
              <p className="text-red-500 text-xs italic">
                {errors.quality_assurence_policy}
              </p>
            ) : (
              ""
            )}
          </div>

          <div className={`w-full text-md mb-4 ${classEvidence}`}>
            <label
              className="block  capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-0"
              htmlFor="grid-city"
            >
              Summary of staff capacity<span className="text-red-600">*</span>
            </label>
            <div>
              <input
                className="
                      relative 
                      float-left  
                      mr-[6px] 
                      mt-[0.15rem] 
                      h-[1.125rem] 
                      w-[1.125rem] 
                      rounded-[0.25rem] 
                      border-[0.125rem] 
                      border-solid 
                      border-neutral-300 
                      outline-none 
                      before:pointer-events-none 
                      before:absolute 
                      before:h-[0.875rem] 
                      before:w-[0.875rem] 
                      before:scale-0 
                      before:rounded-full 
                      before:bg-transparent 
                      before:opacity-0 
                      before:shadow-[0px_0px_0px_13px_transparent] 
                      before:content-[''] 
                      checked:border-primary 
                      checked:bg-primary 
                      checked:before:opacity-[0.16] 
                      checked:after:absolute 
                      checked:after:-mt-px 
                      checked:after:ml-[0.25rem] 
                      checked:after:block 
                      checked:after:h-[0.8125rem] 
                      checked:after:w-[0.375rem] 
                      checked:after:rotate-45 
                      checked:after:border-[0.125rem] 
                      checked:after:border-l-0 
                      checked:after:border-t-0 
                      checked:after:border-solid 
                      checked:after:border-white 
                      checked:after:bg-transparent 
                      checked:after:content-[''] 
                      hover:cursor-pointer 
                      hover:before:opacity-[0.04] 
                      hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] 
                      focus:shadow-none focus:transition-[border-color_0.2s] 
                      focus:before:scale-100 focus:before:opacity-[0.12] 
                      focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] 
                      focus:before:transition-[box-shadow_0.2s,transform_0.2s] 
                      focus:after:absolute focus:after:z-[1] focus:after:block 
                      focus:after:h-[0.875rem] focus:after:w-[0.875rem] 
                      focus:after:rounded-[0.125rem] 
                      focus:after:content-[''] 
                      checked:focus:before:scale-100 
                      checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] 
                      checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] 
                      checked:focus:after:-mt-px 
                      checked:focus:after:ml-[0.25rem] 
                      checked:focus:after:h-[0.8125rem] 
                      checked:focus:after:w-[0.375rem] checked:focus:after:rotate-45 
                      checked:focus:after:rounded-none checked:focus:after:border-[0.125rem] 
                      checked:focus:after:border-l-0 checked:focus:after:border-t-0 
                      checked:focus:after:border-solid 
                      checked:focus:after:border-white 
                      checked:focus:after:bg-transparent 
                      dark:border-neutral-600 
                      dark:checked:border-primary 
                      dark:checked:bg-primary 
                      dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] 
                      dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]"
                type="checkbox"
                values={values.summaryStaffCapacityYesOrNo}
                onChange={(e) => summary_of_staff_capacity(e)}
                name="summary_of_staff_capacityYesOrNo"
                id="summary_of_staff_capacityYesOrNo"
              />{" "}
              Check if you have the document
            </div>
            <input
              className={`
                                    ${summaryStaffCapacityYesOrNo}
                               ${
                                 errors.summary_of_staff_capacity &&
                                 touched.summary_of_staff_capacity
                                   ? "input-error"
                                   : ""
                               }
                                appearance-none block w-full dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] border border-gray-200 rounded py-3 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
              id="summary_of_staff_capacity"
              type="file"
              accept="application/pdf"
              onChange={(event) => {
                setFieldValue(
                  "summary_of_staff_capacity",
                  event.currentTarget.files[0]
                );
              }}
              onBlur={handleBlur}
            />
            {errors.summary_of_staff_capacity &&
            touched.summary_of_staff_capacity ? (
              <p className="text-red-500 text-xs italic">
                {errors.summary_of_staff_capacity}
              </p>
            ) : (
              ""
            )}
          </div>
          <div className={`w-full text-md mb-4 ${classEvidence}`}>
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-0"
              htmlFor="grid-city"
            >
              Inventory of facilities<span className="text-red-600">*</span>
            </label>
            <div>
              <input
                className="
                      relative 
                      float-left  
                      mr-[6px] 
                      mt-[0.15rem] 
                      h-[1.125rem] 
                      w-[1.125rem] 
                      rounded-[0.25rem] 
                      border-[0.125rem] 
                      border-solid 
                      border-neutral-300 
                      outline-none 
                      before:pointer-events-none 
                      before:absolute 
                      before:h-[0.875rem] 
                      before:w-[0.875rem] 
                      before:scale-0 
                      before:rounded-full 
                      before:bg-transparent 
                      before:opacity-0 
                      before:shadow-[0px_0px_0px_13px_transparent] 
                      before:content-[''] 
                      checked:border-primary 
                      checked:bg-primary 
                      checked:before:opacity-[0.16] 
                      checked:after:absolute 
                      checked:after:-mt-px 
                      checked:after:ml-[0.25rem] 
                      checked:after:block 
                      checked:after:h-[0.8125rem] 
                      checked:after:w-[0.375rem] 
                      checked:after:rotate-45 
                      checked:after:border-[0.125rem] 
                      checked:after:border-l-0 
                      checked:after:border-t-0 
                      checked:after:border-solid 
                      checked:after:border-white 
                      checked:after:bg-transparent 
                      checked:after:content-[''] 
                      hover:cursor-pointer 
                      hover:before:opacity-[0.04] 
                      hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] 
                      focus:shadow-none focus:transition-[border-color_0.2s] 
                      focus:before:scale-100 focus:before:opacity-[0.12] 
                      focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] 
                      focus:before:transition-[box-shadow_0.2s,transform_0.2s] 
                      focus:after:absolute focus:after:z-[1] focus:after:block 
                      focus:after:h-[0.875rem] focus:after:w-[0.875rem] 
                      focus:after:rounded-[0.125rem] 
                      focus:after:content-[''] 
                      checked:focus:before:scale-100 
                      checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] 
                      checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] 
                      checked:focus:after:-mt-px 
                      checked:focus:after:ml-[0.25rem] 
                      checked:focus:after:h-[0.8125rem] 
                      checked:focus:after:w-[0.375rem] checked:focus:after:rotate-45 
                      checked:focus:after:rounded-none checked:focus:after:border-[0.125rem] 
                      checked:focus:after:border-l-0 checked:focus:after:border-t-0 
                      checked:focus:after:border-solid 
                      checked:focus:after:border-white 
                      checked:focus:after:bg-transparent 
                      dark:border-neutral-600 
                      dark:checked:border-primary 
                      dark:checked:bg-primary 
                      dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] 
                      dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]"
                type="checkbox"
                values={values.inventoryOfFacilitiesYesOrNo}
                onChange={(e) => inventory_of_facilities(e)}
                name="inventory_of_facilitiesYesOrNo"
                id="inventory_of_facilitiesYesOrNo"
              />{" "}
              Check if you have the document
            </div>
            <input
              className={`
                                    ${inventoryOfFacilitiesYesOrNo}
                               ${
                                 errors.inventory_of_facilities &&
                                 touched.inventory_of_facilities
                                   ? "input-error"
                                   : ""
                               }
                                appearance-none block w-full dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] border border-gray-200 rounded py-3 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
              id="inventory_of_facilities"
              type="file"
              accept="application/pdf"
              onChange={(event) => {
                setFieldValue(
                  "inventory_of_facilities",
                  event.currentTarget.files[0]
                );
              }}
              onBlur={handleBlur}
            />
            {errors.inventory_of_facilities &&
            touched.inventory_of_facilities ? (
              <p className="text-red-500 text-xs italic">
                {errors.inventory_of_facilities}
              </p>
            ) : (
              ""
            )}
          </div>

          <div className={`w-full text-md mb-4 ${classEvidence}`}>
            <label
              className="block capitalize text-md tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-0"
              htmlFor="grid-city"
            >
              Annual survey report<span className="text-red-600">*</span>
            </label>
            <div>
              <input
                className="
                      relative 
                      float-left  
                      mr-[6px] 
                      mt-[0.15rem] 
                      h-[1.125rem] 
                      w-[1.125rem] 
                      rounded-[0.25rem] 
                      border-[0.125rem] 
                      border-solid 
                      border-neutral-300 
                      outline-none 
                      before:pointer-events-none 
                      before:absolute 
                      before:h-[0.875rem] 
                      before:w-[0.875rem] 
                      before:scale-0 
                      before:rounded-full 
                      before:bg-transparent 
                      before:opacity-0 
                      before:shadow-[0px_0px_0px_13px_transparent] 
                      before:content-[''] 
                      checked:border-primary 
                      checked:bg-primary 
                      checked:before:opacity-[0.16] 
                      checked:after:absolute 
                      checked:after:-mt-px 
                      checked:after:ml-[0.25rem] 
                      checked:after:block 
                      checked:after:h-[0.8125rem] 
                      checked:after:w-[0.375rem] 
                      checked:after:rotate-45 
                      checked:after:border-[0.125rem] 
                      checked:after:border-l-0 
                      checked:after:border-t-0 
                      checked:after:border-solid 
                      checked:after:border-white 
                      checked:after:bg-transparent 
                      checked:after:content-[''] 
                      hover:cursor-pointer 
                      hover:before:opacity-[0.04] 
                      hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] 
                      focus:shadow-none focus:transition-[border-color_0.2s] 
                      focus:before:scale-100 focus:before:opacity-[0.12] 
                      focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] 
                      focus:before:transition-[box-shadow_0.2s,transform_0.2s] 
                      focus:after:absolute focus:after:z-[1] focus:after:block 
                      focus:after:h-[0.875rem] focus:after:w-[0.875rem] 
                      focus:after:rounded-[0.125rem] 
                      focus:after:content-[''] 
                      checked:focus:before:scale-100 
                      checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] 
                      checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] 
                      checked:focus:after:-mt-px 
                      checked:focus:after:ml-[0.25rem] 
                      checked:focus:after:h-[0.8125rem] 
                      checked:focus:after:w-[0.375rem] checked:focus:after:rotate-45 
                      checked:focus:after:rounded-none checked:focus:after:border-[0.125rem] 
                      checked:focus:after:border-l-0 checked:focus:after:border-t-0 
                      checked:focus:after:border-solid 
                      checked:focus:after:border-white 
                      checked:focus:after:bg-transparent 
                      dark:border-neutral-600 
                      dark:checked:border-primary 
                      dark:checked:bg-primary 
                      dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] 
                      dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]"
                type="checkbox"
                values={values.annualSurveyReportYesOrNo}
                onChange={(e) => annual_survey_report(e)}
                name="annual_survey_reportYesOrNo"
                id="annual_survey_reportYesOrNo"
              />{" "}
              Check if you have the document
            </div>
            <input
              className={`
                                    ${annualSurveyReportYesOrNo}
                               ${
                                 errors.annual_survey_report &&
                                 touched.annual_survey_report
                                   ? "input-error"
                                   : ""
                               }
                                    appearance-none block w-full dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] border border-gray-200 rounded py-3 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
              id="annual_survey_report"
              type="file"
              accept="application/pdf"
              onChange={(event) => {
                setFieldValue(
                  "annual_survey_report",
                  event.currentTarget.files[0]
                );
              }}
              onBlur={handleBlur}
            />
            {errors.annual_survey_report && touched.annual_survey_report ? (
              <p className="text-red-500 text-xs italic">
                {errors.annual_survey_report}
              </p>
            ) : (
              ""
            )}
          </div>

          <div className={`w-full text-md mb-4 ${classEvidence}`}>
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-0"
              htmlFor="grid-city"
            >
              Examination policy<span className="text-red-600">*</span>
            </label>
            <div>
              <input
                className="
                      relative 
                      float-left  
                      mr-[6px] 
                      mt-[0.15rem] 
                      h-[1.125rem] 
                      w-[1.125rem] 
                      rounded-[0.25rem] 
                      border-[0.125rem] 
                      border-solid 
                      border-neutral-300 
                      outline-none 
                      before:pointer-events-none 
                      before:absolute 
                      before:h-[0.875rem] 
                      before:w-[0.875rem] 
                      before:scale-0 
                      before:rounded-full 
                      before:bg-transparent 
                      before:opacity-0 
                      before:shadow-[0px_0px_0px_13px_transparent] 
                      before:content-[''] 
                      checked:border-primary 
                      checked:bg-primary 
                      checked:before:opacity-[0.16] 
                      checked:after:absolute 
                      checked:after:-mt-px 
                      checked:after:ml-[0.25rem] 
                      checked:after:block 
                      checked:after:h-[0.8125rem] 
                      checked:after:w-[0.375rem] 
                      checked:after:rotate-45 
                      checked:after:border-[0.125rem] 
                      checked:after:border-l-0 
                      checked:after:border-t-0 
                      checked:after:border-solid 
                      checked:after:border-white 
                      checked:after:bg-transparent 
                      checked:after:content-[''] 
                      hover:cursor-pointer 
                      hover:before:opacity-[0.04] 
                      hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] 
                      focus:shadow-none focus:transition-[border-color_0.2s] 
                      focus:before:scale-100 focus:before:opacity-[0.12] 
                      focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] 
                      focus:before:transition-[box-shadow_0.2s,transform_0.2s] 
                      focus:after:absolute focus:after:z-[1] focus:after:block 
                      focus:after:h-[0.875rem] focus:after:w-[0.875rem] 
                      focus:after:rounded-[0.125rem] 
                      focus:after:content-[''] 
                      checked:focus:before:scale-100 
                      checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] 
                      checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] 
                      checked:focus:after:-mt-px 
                      checked:focus:after:ml-[0.25rem] 
                      checked:focus:after:h-[0.8125rem] 
                      checked:focus:after:w-[0.375rem] checked:focus:after:rotate-45 
                      checked:focus:after:rounded-none checked:focus:after:border-[0.125rem] 
                      checked:focus:after:border-l-0 checked:focus:after:border-t-0 
                      checked:focus:after:border-solid 
                      checked:focus:after:border-white 
                      checked:focus:after:bg-transparent 
                      dark:border-neutral-600 
                      dark:checked:border-primary 
                      dark:checked:bg-primary 
                      dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] 
                      dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]"
                type="checkbox"
                values={values.examinationPolicyYesOrNo}
                onChange={(e) => examination_policy(e)}
                name="examination_policyYesOrNo"
                id="examination_policyYesOrNo"
              />{" "}
              Check if you have the document
            </div>
            <input
              className={`
                                    ${examinationPolicyYesOrNo}
                               ${
                                 errors.examination_policy &&
                                 touched.examination_policy
                                   ? "input-error"
                                   : ""
                               }
                                appearance-none block w-full dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] border border-gray-200 rounded py-3 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
              id="examination_policy"
              type="file"
              accept="application/pdf"
              onChange={(event) => {
                setFieldValue(
                  "examination_policy",
                  event.currentTarget.files[0]
                );
              }}
              onBlur={handleBlur}
            />
            {errors.examination_policy && touched.examination_policy ? (
              <p className="text-red-500 text-xs italic">
                {errors.examination_policy}
              </p>
            ) : (
              ""
            )}
          </div>

          <div className={`w-full text-md mb-4 ${classEvidence}`}>
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-0"
              htmlFor="grid-city"
            >
              Admission Policy<span className="text-red-600">*</span>
            </label>
            <div>
              <input
                className="
                      relative 
                      float-left  
                      mr-[6px] 
                      mt-[0.15rem] 
                      h-[1.125rem] 
                      w-[1.125rem] 
                      rounded-[0.25rem] 
                      border-[0.125rem] 
                      border-solid 
                      border-neutral-300 
                      outline-none 
                      before:pointer-events-none 
                      before:absolute 
                      before:h-[0.875rem] 
                      before:w-[0.875rem] 
                      before:scale-0 
                      before:rounded-full 
                      before:bg-transparent 
                      before:opacity-0 
                      before:shadow-[0px_0px_0px_13px_transparent] 
                      before:content-[''] 
                      checked:border-primary 
                      checked:bg-primary 
                      checked:before:opacity-[0.16] 
                      checked:after:absolute 
                      checked:after:-mt-px 
                      checked:after:ml-[0.25rem] 
                      checked:after:block 
                      checked:after:h-[0.8125rem] 
                      checked:after:w-[0.375rem] 
                      checked:after:rotate-45 
                      checked:after:border-[0.125rem] 
                      checked:after:border-l-0 
                      checked:after:border-t-0 
                      checked:after:border-solid 
                      checked:after:border-white 
                      checked:after:bg-transparent 
                      checked:after:content-[''] 
                      hover:cursor-pointer 
                      hover:before:opacity-[0.04] 
                      hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] 
                      focus:shadow-none focus:transition-[border-color_0.2s] 
                      focus:before:scale-100 focus:before:opacity-[0.12] 
                      focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] 
                      focus:before:transition-[box-shadow_0.2s,transform_0.2s] 
                      focus:after:absolute focus:after:z-[1] focus:after:block 
                      focus:after:h-[0.875rem] focus:after:w-[0.875rem] 
                      focus:after:rounded-[0.125rem] 
                      focus:after:content-[''] 
                      checked:focus:before:scale-100 
                      checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] 
                      checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] 
                      checked:focus:after:-mt-px 
                      checked:focus:after:ml-[0.25rem] 
                      checked:focus:after:h-[0.8125rem] 
                      checked:focus:after:w-[0.375rem] checked:focus:after:rotate-45 
                      checked:focus:after:rounded-none checked:focus:after:border-[0.125rem] 
                      checked:focus:after:border-l-0 checked:focus:after:border-t-0 
                      checked:focus:after:border-solid 
                      checked:focus:after:border-white 
                      checked:focus:after:bg-transparent 
                      dark:border-neutral-600 
                      dark:checked:border-primary 
                      dark:checked:bg-primary 
                      dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] 
                      dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]"
                type="checkbox"
                values={values.admissionPolicyYesOrNo}
                onChange={(e) => admission_policy(e)}
                name="admission_policyYesOrNo"
                id="admission_policyYesOrNo"
              />{" "}
              Check if you have the document
            </div>
            <input
              className={`
                                    ${admissionPolicyYesOrNo}
                                    ${
                                      errors.admission_policy &&
                                      touched.admission_policy
                                        ? "input-error"
                                        : ""
                                    }
                                    appearance-none 
                                    block w-full mt-0
                                    dark:bg-[var(--color-dark-backgroundMain)] 
                                    bg-[var(--color-light-backgroundMain)] 
                                    dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] border border-gray-200 rounded py-3 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
              id="admission_policy"
              type="file"
              accept="application/pdf"
              onChange={(event) => {
                setFieldValue("admission_policy", event.currentTarget.files[0]);
              }}
              onBlur={handleBlur}
            />
            {errors.admission_policy && touched.admission_policy ? (
              <p className="text-red-500 text-xs italic">
                {errors.admission_policy}
              </p>
            ) : (
              ""
            )}
          </div>
          <div className={`w-full text-md mb-4 ${classEvidence}`}>
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-0"
              htmlFor="grid-city"
            >
              Program review report<span className="text-red-600">*</span>
            </label>
            <div>
              <input
                className="
                      relative 
                      float-left  
                      mr-[6px] 
                      mt-[0.15rem] 
                      h-[1.125rem] 
                      w-[1.125rem] 
                      rounded-[0.25rem] 
                      border-[0.125rem] 
                      border-solid 
                      border-neutral-300 
                      outline-none 
                      before:pointer-events-none 
                      before:absolute 
                      before:h-[0.875rem] 
                      before:w-[0.875rem] 
                      before:scale-0 
                      before:rounded-full 
                      before:bg-transparent 
                      before:opacity-0 
                      before:shadow-[0px_0px_0px_13px_transparent] 
                      before:content-[''] 
                      checked:border-primary 
                      checked:bg-primary 
                      checked:before:opacity-[0.16] 
                      checked:after:absolute 
                      checked:after:-mt-px 
                      checked:after:ml-[0.25rem] 
                      checked:after:block 
                      checked:after:h-[0.8125rem] 
                      checked:after:w-[0.375rem] 
                      checked:after:rotate-45 
                      checked:after:border-[0.125rem] 
                      checked:after:border-l-0 
                      checked:after:border-t-0 
                      checked:after:border-solid 
                      checked:after:border-white 
                      checked:after:bg-transparent 
                      checked:after:content-[''] 
                      hover:cursor-pointer 
                      hover:before:opacity-[0.04] 
                      hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] 
                      focus:shadow-none focus:transition-[border-color_0.2s] 
                      focus:before:scale-100 focus:before:opacity-[0.12] 
                      focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] 
                      focus:before:transition-[box-shadow_0.2s,transform_0.2s] 
                      focus:after:absolute focus:after:z-[1] focus:after:block 
                      focus:after:h-[0.875rem] focus:after:w-[0.875rem] 
                      focus:after:rounded-[0.125rem] 
                      focus:after:content-[''] 
                      checked:focus:before:scale-100 
                      checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] 
                      checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] 
                      checked:focus:after:-mt-px 
                      checked:focus:after:ml-[0.25rem] 
                      checked:focus:after:h-[0.8125rem] 
                      checked:focus:after:w-[0.375rem] checked:focus:after:rotate-45 
                      checked:focus:after:rounded-none checked:focus:after:border-[0.125rem] 
                      checked:focus:after:border-l-0 checked:focus:after:border-t-0 
                      checked:focus:after:border-solid 
                      checked:focus:after:border-white 
                      checked:focus:after:bg-transparent 
                      dark:border-neutral-600 
                      dark:checked:border-primary 
                      dark:checked:bg-primary 
                      dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] 
                      dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]"
                type="checkbox"
                values={values.programReviewReportYesOrNo}
                onChange={(e) => program_review_report(e)}
                name="program_review_reportYesOrNo"
                id="program_review_reportYesOrNo"
              />{" "}
              Check if you have the document
            </div>
            <input
              className={`
                                    ${programReviewReportYesOrNo}
                                    ${
                                      errors.program_review_report &&
                                      touched.program_review_report
                                        ? "input-error"
                                        : ""
                                    }
                                        appearance-none block w-full dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] border border-gray-200 rounded py-3 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
              id="program_review_report"
              type="file"
              accept="application/pdf"
              onChange={(event) => {
                setFieldValue(
                  "program_review_report",
                  event.currentTarget.files[0]
                );
              }}
              onBlur={handleBlur}
            />
            {errors.program_review_report && touched.program_review_report ? (
              <p className="text-red-500 text-xs italic">
                {errors.program_review_report}
              </p>
            ) : (
              ""
            )}
          </div>

          <div className={`w-full text-md mb-4 ${classEvidence}`}>
            <label
              className="block capitalize text-md tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-0"
              htmlFor="grid-city"
            >
              Credit transfer policy<span className="text-gray-600">*</span>
            </label>
            <input
              className={`
                                   ${
                                     errors.credit_transfer_policy &&
                                     touched.credit_transfer_policy
                                       ? "input-error"
                                       : ""
                                   }
                                    appearance-none block w-full dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] border border-gray-200 rounded py-3 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
              id="credit_transfer_policy"
              type="file"
              accept="application/pdf"
              onChange={(event) => {
                setFieldValue(
                  "credit_transfer_policy",
                  event.currentTarget.files[0]
                );
              }}
              onBlur={handleBlur}
            />
            {errors.credit_transfer_policy && touched.credit_transfer_policy ? (
              <p className="text-red-500 text-xs italic">
                {errors.credit_transfer_policy}
              </p>
            ) : (
              ""
            )}
          </div>
          <div className={`w-full text-md mb-4 ${classEvidence}`}>
            <label
              className="block text-md capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-0"
              htmlFor="grid-city"
            >
              Tracer study report<span className="text-gray-600">*</span>
            </label>
            <input
              className={`
                               ${
                                 errors.tracer_study_report &&
                                 touched.tracer_study_report
                                   ? "input-error"
                                   : ""
                               }
                                appearance-none block w-full dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] border border-gray-200 rounded py-3 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
              id="tracer_study_report"
              type="file"
              accept="application/pdf"
              onChange={(event) => {
                setFieldValue(
                  "tracer_study_report",
                  event.currentTarget.files[0]
                );
              }}
              onBlur={handleBlur}
            />
            {errors.tracer_study_report && touched.tracer_study_report ? (
              <p className="text-red-500 text-xs italic">
                {errors.tracer_study_report}
              </p>
            ) : (
              ""
            )}
          </div>

          <div className={`w-full text-md mb-4 ${classEvidence}`}>
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-0"
              htmlFor="grid-city"
            >
              Postgraduate supervision guidelines
              <span className="text-gray-600">*</span>
            </label>

            <input
              className={` 
                               ${
                                 errors.postgraduate_supervision_guidelines &&
                                 touched.postgraduate_supervision_guidelines
                                   ? "input-error"
                                   : ""
                               }
                                appearance-none block w-full dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] border border-gray-200 rounded py-3 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
              id="postgraduate_supervision_guidelines"
              type="file"
              accept="application/pdf"
              onChange={(event) => {
                setFieldValue(
                  "postgraduate_supervision_guidelines",
                  event.currentTarget.files[0]
                );
              }}
              onBlur={handleBlur}
            />
            {errors.postgraduate_supervision_guidelines &&
            touched.postgraduate_supervision_guidelines ? (
              <p className="text-red-500 text-xs italic">
                {errors.postgraduate_supervision_guidelines}
              </p>
            ) : (
              ""
            )}
          </div>
          <div className={`w-full text-md mb-4 ${classEvidence}`}>
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-0"
              htmlFor="grid-city"
            >
              Involvment of the relevant national bodies
              <span className="text-gray-600">*</span>
            </label>

            <input
              className={` 
                               ${
                                 errors.involvment_of_the_relevant_national_bodies &&
                                 touched.involvment_of_the_relevant_national_bodies
                                   ? "input-error"
                                   : ""
                               }
                                appearance-none block w-full dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] border border-gray-200 rounded py-3 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
              id="involvment_of_the_relevant_national_bodies"
              type="file"
              accept="application/pdf"
              onChange={(event) => {
                setFieldValue(
                  "involvment_of_the_relevant_national_bodies",
                  event.currentTarget.files[0]
                );
              }}
              onBlur={handleBlur}
            />
            {errors.involvment_of_the_relevant_national_bodies &&
            touched.involvment_of_the_relevant_national_bodies ? (
              <p className="text-red-500 text-xs italic">
                {errors.involvment_of_the_relevant_national_bodies}
              </p>
            ) : (
              ""
            )}
          </div>
          <div className={`w-full text-md mb-4 ${classEvidence}`}>
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-0"
              htmlFor="grid-city"
            >
              Other not specified<span className="text-gray-600">*</span>
            </label>
            <input
              className={`
                               ${
                                 errors.other_not_specified &&
                                 touched.other_not_specified
                                   ? "input-error"
                                   : ""
                               }
                                appearance-none block w-full dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] border border-gray-200 rounded py-3 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
              id="other_not_specified"
              type="file"
              accept="application/pdf"
              onChange={(event) => {
                setFieldValue(
                  "other_not_specified",
                  event.currentTarget.files[0]
                );
              }}
              onBlur={handleBlur}
            />
            {errors.other_not_specified && touched.other_not_specified ? (
              <p className="text-red-500 text-xs italic">
                {errors.other_not_specified}
              </p>
            ) : (
              ""
            )}
          </div>

          {/*  
 {  classEvidence +' 1-'+  confirmationLetter +' 2-'+ involvementNationalBody +' 3-'+ 
     postGraduateSupervisionYesOrNo +' 4-'+  qualityAssurencePolicyYesOrNo +' 5-'+  summaryStaffCapacityYesOrNo +' 6-'+ 
     inventoryOfFacilitiesYesOrNo +' 7-'+  annualSurveyReportYesOrNo +' 8-'+  examinationPolicyYesOrNo +' 9-'+ 
     admissionPolicyYesOrNo  +' 10-'+ programReviewReportYesOrNo } */}

          {classEvidence !== "hidden" &&
          confirmationLetter !== "hidden" &&
          qualityAssurencePolicyYesOrNo !== "hidden" &&
          summaryStaffCapacityYesOrNo !== "hidden" &&
          inventoryOfFacilitiesYesOrNo !== "hidden" &&
          annualSurveyReportYesOrNo !== "hidden" &&
          examinationPolicyYesOrNo !== "hidden" &&
          admissionPolicyYesOrNo !== "hidden" &&
          programReviewReportYesOrNo !== "hidden" ? (
            <div className={`text-center p-5`}>
              <button
                disabled={buttonDisabled}
                type="submit"
                className={`${
                  disableSaveBtn
                    ? "opacity-50 cursor-not-allowed"
                    : "dark:bg-[var(--color-dark-buttonBackground)] bg-[var(--color-light-buttonBackground)] dark:text-[var(--color-dark-fontButton)]  text-[var(--color-light-fontButton)] w-[100px]  py-3 rounded-md font-medium "
                } 
                        transition-all duration-300 ease-in-out transform hover:-translate-y-0.5'
                         `}
              >
                {" "}
                {editing ? "Edit" : "Submit"}
              </button>
            </div>
          ) : (
            <span className="text-red-500">
              You have to fill all required files
            </span>
          )}
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { login })(ApplicationAdd);
