import React from "react";

const DocumentRequirement = ({ title, required }) => {
    const textStyle = required
      ? "bg-red-100 text-red-800 border border-red-400"
      : "bg-gray-100 text-gray-800 border border-gray-400";
  
    return (
      <p className={`flex items-center ${textStyle} text-sm font-medium capitalize rounded-full px-2.5 py-0.5 mr-2`}>
        {title}
        <span className={`ml-2 font-semibold ${required ? "text-red-300" : "text-gray-300"}`}>
          {required ? "(a must)" : "(not a must)"}
        </span>
      </p>
    );
  };
  
  export default DocumentRequirement