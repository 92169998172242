import React, { useState, useEffect } from "react";
import { FaEdit, FaTrash, FaEye } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";
import ApplicationService from "../../../services/ApplicationService";
import moment from "moment";
import Error from "../../alerts/Error";
import Success from "../../alerts/Success";
import { FaVoicemail, FaFilePdf } from "react-icons/fa";
import ConfirmLink from "react-confirm-dialog";
import Loading from "../../eventsHandler/Loading";

const ApplicationList = () => {
  var access = localStorage.getItem("access");
  const baseUrl = `${process.env.REACT_APP_API_URL}/api/listApplicationsAndExpertReview_api/data`;
  const [listData, setListData] = useState([]);
  const [nextUrl, setNextUrl] = useState();
  const [disableSaveBtn, setDisableSaveBtn] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalNumberData, setTotalNumberData] = useState(0);
  const [totalNumberPerPage, setTotalNumberPerPage] = useState(0);

  let userInfo = localStorage.getItem("userInfo");
  let userJson = JSON.parse(userInfo);

  const [previoustUrl, setPrevioustUrl] = useState();
  const history = useHistory();
  let levelData = JSON.parse(localStorage.getItem("levelData"));

  if (!(history.location.state == null)) {
    const data = history.location.state.data;
  }

  useEffect(() => {
    retrieveApplications();
  }, [currentPage]);

  const retrieveApplications = () => {
    setDisableSaveBtn(true);
    const pageUrl = `${baseUrl}?page=${currentPage}`;
    var access = localStorage.getItem("access");
    ApplicationService.getAll(access, pageUrl)
      .then((response) => {
        setNextUrl(response.data.next);
        setPrevioustUrl(response.data.previous);
        setListData(response.data.results);
        console.log(response.data.results);
        setTotalPages(response.data.total_pages);
        setTotalNumberData(response.data.total_records);
        setTotalNumberPerPage(response.data.items_per_page);
      })
      .catch((e) => {
        console.log("error : ", e);
        if (e.response.status === 401) {
          // Redirect to the login page
          history.push("/login");
        }
      })
      .finally(() => {
        setDisableSaveBtn(false);
      });
  };

  // delete an application
  ConfirmLink.defaultProps.confirmMessage =
    "Are you sure you want to delete this?";
  ConfirmLink.defaultProps.confirmText = "Yes please";
  ConfirmLink.defaultProps.cancelText = "No, thanks";
  const deleteConfirm = (applicId) => {
    deleteApplication(applicId);
  };

  const deleteApplication = (applicationId) => {
    var access = localStorage.getItem("access");
    ApplicationService.deleteApplication(applicationId, access)
      .then((response) => {
        let data = {
          status: 1,
          title: "Done",
          message: "Deleted Successfully",
        };
        history.push("/application/list", { data: data });
        retrieveApplications();
      })
      .catch((e) => {
        if (e.response.status === 401) {
          // Redirect to the login page
          history.push("/login");
        }
        let data = {
          status: 0,
          title: "Error",
          message: "An Error happened !",
        };
        history.push("/application/list", { data: data });
      });
  };

  // get data from abel module
  let univData = JSON.parse(localStorage.getItem("universityData"));

  const [showModal, setShowModal] = React.useState(false);
  const [show, setShow] = useState(false);
  const [selectedData, setSelectedData] = useState({});

  const hanldeClick = (selectedRec) => {
    setSelectedData(selectedRec);
    setShowModal(true);
    setShow(true);
  };

  const hideModal = () => {
    setShow(false);
  };

  useEffect(() => {
    setFilteredData(listData);
  }, [listData]);

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(listData);

  const handleSearch = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setSearchTerm(searchTerm);

    if (searchTerm.trim() === "") {
      // If search input is empty, display all data
      setFilteredData(listData);
    } else {
      // Filter data based on search term
      const filteredResults = listData.filter(
        (item) =>
          item.data.title_program_application
            .toLowerCase()
            .includes(searchTerm) ||
          item.data.batchNumber_Application.toLowerCase().includes(searchTerm)
      );
      setFilteredData(filteredResults);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  return (
    <div className="w-full p-1 mt-4">
      {disableSaveBtn && <Loading />}
      {history?.location?.state === null ? (
        ""
      ) : history?.location?.state?.data?.status === 1 ? (
        <Success
          delay="5000"
          valueFromParent={history?.location?.state?.data}
        />
      ) : history?.location?.state?.data?.status === 0 ? (
        <Error delay="7000" valueFromParent={history?.location?.state?.data} />
      ) : (
        <span></span>
      )}

      <div className="m-0 relative shadow-md sm:rounded-lg">
        {/* <span className=' text-gray-500
                            mb-2 
                            hover:text-gray-800'>  Application List {`>`}</span>
              */}
        <div className="flex items-center justify-between pb-1">
          <Link to={"/application/create"}>
            <button className="flex space-x-2 items-center px-3 py-2 bg-blue-500 hover:bg-blue-800 rounded-md drop-shadow-md">
              <svg
                className="fill-white"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="20"
                height="20"
                viewBox="0 0 24 24"
              >
                <path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z" />
              </svg>
              <span className="text-white">Add</span>
            </button>
          </Link>

          <label htmlFor="table-search" className="sr-only">
            Search
          </label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                className="w-5 h-5 text-[var(--color-tbl200)] dark:text-[var(--color-gray-tbl400)]"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            <input
              type="text"
              id="table-search"
              value={searchTerm}
              onChange={handleSearch}
              className="block p-2 pl-10 text-md text-[var(--color-gray-tbl900)] border border-[var(--color-gray-tbl300)] rounded-lg w-80 bg-[var(--color-gray-tbl50)] focus:ring-blue-500 focus:border-blue-500 dark:bg-[var(--color-gray-tbl700)] dark:border-[var(--color-gray-tbl600)] dark:placeholder-[var(--color-gray-tbl400)] dark:text-[var(--color-gray-tbl0)] dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Type title of the programme"
            />
          </div>
        </div>
        <div className="flex">
          <div className="overflow-x-auto">
            <table
              className="w-full 
                text-md 
                text-left 
                text-[var(--color-tbl200)] 
                dark:text-[var(--color-gray-tbl400)]"
            >
              <thead className="text-md text-[var(--color-gray-tbl700)] capitalize bg-[var(--color-gray-tbl50)] dark:bg-[var(--color-gray-tbl700)] dark:text-[var(--color-gray-tbl400)]">
                <tr id="11s" key={1}>
                  <th scope="col" className="p-4">
                    <div className="flex items-center">
                      <input
                        id="checkbox-all-search"
                        type="checkbox"
                        className="w-4 h-4 text-blue-600 bg-[var(--color-gray-tbl100)] border-[var(--color-gray-tbl300)] rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-[var(--color-gray-tbl800)] dark:focus:ring-offset-[var(--color-gray-tbl800)] focus:ring-2 dark:bg-[var(--color-gray-tbl700)] dark:border-[var(--color-gray-tbl600)]"
                      />
                      <label htmlFor="checkbox-all-search" className="sr-only">
                        checkbox
                      </label>
                    </div>
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Batch Number
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Programme title
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Name university
                  </th>

                  <th scope="col" className="px-6 py-3">
                    Level
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Documents
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Date
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Status review
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((application) => {
                  var classValue = "";
                  var nameComplete = "Complete";
                  var buttonCompleteClass = "";
                  if (Number(application.data.status_application) === 1) {
                    classValue = "green-500";
                    nameComplete = "Revert back";
                    buttonCompleteClass = "";
                  } else if (
                    Number(application.data.status_application) === 2
                  ) {
                    classValue = "red-500";
                    nameComplete = "Revert back";
                    buttonCompleteClass = "disabled";
                  } else {
                    classValue = "yellow-500";
                  }
                  return (
                    <tr
                      className={` bg-[var(--color-gray-tbl0)] 
                    border-b dark:bg-[var(--color-gray-tbl800)] 
                    dark:border-[var(--color-gray-tbl700)] 
                    hover:bg-[var(--color-gray-tbl200)] 
                        dark:hover:bg-[var(--color-gray-tbl600)]`}
                      key={application.data.id_application}
                    >
                      <td className="w-4 p-4">
                        <div className="flex items-center">
                          <input
                            id="checkbox-table-search-1"
                            type="checkbox"
                            className="w-4 h-4 text-blue-600 bg-[var(--color-gray-tbl100)] border-[var(--color-gray-tbl300)] rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-[var(--color-gray-tbl800)] dark:focus:ring-offset-[var(--color-gray-tbl800)] focus:ring-2 dark:bg-[var(--color-gray-tbl700)] dark:border-[var(--color-gray-tbl600)]"
                          />
                        </div>
                      </td>
                      <th
                        scope="row"
                        className="px-1 py-1 font-semibold text-[var(--color-gray-tbl900)] whitespace-nowrap dark:text-[var(--color-gray-tbl0)]"
                      >
                        {application.data.batchNumber_Application}
                      </th>
                      <td className="px-6 py-4">
                        {application.data.expertReviewData.length > 0 &&
                        application.data.expertReviewData.length ===
                          application.data.resultReviewerExpert.numberExpert ? (
                          <>
                            {" "}
                            {application.data.title_program_application} <br />
                            {Number(
                              application.data.status_applicationChEADecision
                            ) <= 0 &&
                            Number(application.data.status_application) ===
                              11 ? (
                              <Link
                                to={{
                                  pathname: `/applicationReviewRepport/${application.data.id_application}`,
                                  state: application.data,
                                  typeOperation: "NextStape",
                                }}
                              >
                                {" "}
                                <span
                                  className="text-sm
                          bg-green-100 
                          text-green-800 
                          border 
                          border-green-400
                          text-md 
                          font-medium 
                          cursor-pointer
                          mr-2 px-2.5 
                          rounded-2xl
                          py-0.5  
                          dark:bg-green-900 
                          dark:text-green-300 "
                                >
                                  NextLevel(
                                  {application.data.expertReviewData.length})
                                </span>
                              </Link>
                            ) : (
                              <>
                                {Number(application.data.status_application) ===
                                3 ? (
                                  <span
                                    className="text-sm
                        bg-gray-100 
                        text-gray-800 
                        border 
                        border-gray-400
                        text-md 
                        font-medium 
                        cursor-pointer
                        mr-2 px-2.5 
                        rounded-2xl
                        py-0.5  
                        dark:bg-gray-900 
                        dark:text-gray-300 "
                                  >
                                    RejectedAssessors
                                  </span>
                                ) : (
                                  <>
                                    {Number(
                                      application.data.resultSiteVisiteDone
                                        .numberSiteVisitNotDone
                                    ) > 0 &&
                                    Number(
                                      application.data.resultSiteVisiteDone
                                        .numberSiteVisiteDone
                                    ) === 0 ? (
                                      <span
                                        className="text-sm
                        bg-blue-100 
                        text-blue-800 
                        border 
                        border-blue-400
                        text-md 
                        font-medium 
                        cursor-pointer
                        mr-2 px-2.5 
                        rounded-2xl
                        py-0.5  
                        dark:bg-blue-900 
                        dark:text-blue-300 "
                                      >
                                        UnderSiteVisite
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {application.data.title_program_application} <br />
                            {Number(
                              application.data.status_applicationChEADecision
                            ) > 0 ? (
                              ""
                            ) : (
                              <span
                                className={`text-sm ${
                                  application.data.expertReviewData.length > 0
                                    ? `bg-yellow-100 
                              text-yellow-800 
                              cursor-pointer
                              border 
                              border-yellow-400
                              text-md 
                              font-medium 
                              mr-2 px-2.5 
                              py-0.5 
                              rounded-2xl
                              dark:bg-yellow-900 
                              dark:text-yellow-300`
                                    : `bg-red-100 
                              text-red-800 
                              cursor-pointer
                              border 
                              border-red-400
                              text-md 
                              font-medium 
                              mr-2 px-2.5 
                              py-0.5 
                              rounded-2xl
                              dark:bg-red-900 
                              dark:text-red-300`
                                }`}
                              >
                                {application.data.expertReviewData.length <= 0
                                  ? `NoExpertReview `
                                  : `${application.data.expertReviewData.length} in
                              ${application.data.resultReviewerExpert.numberExpert}`}
                              </span>
                            )}
                          </>
                        )}
                        <br />
                        {Number(
                          application.data.resultSiteVisiteDone
                            .numberSiteVisiteDone
                        ) > 0 ? (
                          <>
                            {Number(
                              application.data.status_applicationChEADecision
                            ) > 0 ? (
                              <span
                                rel="noopener noreferrer"
                                className=" text-sm
                              bg-gray-100 
                              text-gray-800 
                              border 
                            border-gray-400
                              text-md 
                              font-medium 
                              mr-2 px-2.5 
                              py-0.5 
                              rounded 
                              dark:bg-gray-900 
                              dark:text-gray-300 
                              hover:cursor-pointer"
                              >
                                SentToCHEA
                              </span>
                            ) : (
                              <>
                                {userJson.is_superuser ? (
                                  <Link
                                    to={{
                                      pathname: `/cheaReport/${application.data.id_application}`,
                                      state: {
                                        application: application.data,
                                        typeOperation: "sendChEAD",
                                      },
                                    }}
                                  >
                                    {Number(
                                      application.data.resultSiteVisiteDone
                                        .numberSiteVisitNotDone
                                    ) <= 0 &&
                                      Number(
                                        application.data
                                          .status_applicationChEADecision
                                      ) <= 0 && (
                                        <>
                                          {" "}
                                          <span
                                            rel="noopener noreferrer"
                                            className=" text-sm
                              bg-green-100 
                              text-green-800 
                              border 
                            border-green-400
                              text-md 
                              font-medium 
                              mr-2 px-2.5 
                              py-0.5 
                              rounded 
                              dark:bg-green-900 
                              dark:text-green-300 
                              hover:cursor-pointer"
                                          >
                                            To CHEA
                                          </span>
                                        </>
                                      )}
                                  </Link>
                                ) : (
                                  <span
                                    rel="noopener noreferrer"
                                    className=" text-sm
                              bg-green-100 
                              text-green-800 
                              border 
                            border-green-400
                              text-md 
                              font-medium 
                              mr-2 px-2.5 
                              py-0.5 
                              rounded 
                              dark:bg-green-900 
                              dark:text-green-300 
                              "
                                  >
                                    To CHEA
                                  </span>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {Number(application.data.numberSiteVisite) > 0 && (
                              <span
                                rel="noopener noreferrer"
                                className="text-sm
                             bg-blue-100 
                             text-blue-800 
                             border 
                           border-blue-400
                             text-md 
                             font-medium 
                             mr-2 px-2.5 
                             py-0.5 
                             rounded 
                             dark:bg-blue-900 
                             dark:text-blue-300 
                             hover:cursor-pointer"
                              >
                                UnderProcess
                              </span>
                            )}
                          </>
                        )}
                      </td>
                      <td className="px-6 py-4">
                        {univData.map((universityDB) => {
                          if (
                            universityDB.id ===
                            Number(application.data.university_id_application)
                          ) {
                            return <span>{universityDB.university_name}</span>;
                          }
                        })}
                      </td>
                      <td className="px-6 py-4">
                        {levelData.map((level) => {
                          if (
                            level.id_level ===
                            application.data.level_id_application
                          ) {
                            return <span>{level.designation_level}</span>;
                          }
                        })}
                      </td>
                      {/* <th
                        scope="row"
                        className="px-1 py-1 font-medium text-[var(--color-gray-tbl900)] whitespace-nowrap dark:text-[var(--color-gray-tbl0)]"
                      >
                        {application.data.otherInfor_application.length > 20
                          ? `${application.data.otherInfor_application.substring(
                              0,
                              20
                            )}...`
                          : application.data.otherInfor_application}batchNumber_Application
                      </th> */}
                      <td className="px-6 py-4 text-ellipsis">
                        <div className="flex">
                          <a
                            href="#"
                            className="text-black"
                            onClick={() => hanldeClick(application)}
                          >
                            <FaFilePdf color="green" size={25} />
                          </a>
                        </div>
                      </td>
                      <td className="px-6 py-4">
                        {moment(
                          application.data.date_created_application
                        ).format("Do MMMM YYYY HH:mm")}
                      </td>

                      <td className="px-6 py-4">
                        {Number(application.data.status_application) === 0 ||
                        Number(application.data.status_application) === 1 ? (
                          <>
                            {Number(application.data.status_application) ===
                            0 ? (
                              <>
                                {userJson.is_superuser ? (
                                  <Link
                                    to={{
                                      pathname:
                                        "/application/" +
                                        application.data.id_application +
                                        "/viewFirstReview",
                                      state: {
                                        currentApplication: application.data,
                                        typeOperation: "statusEdit",
                                      },
                                    }}
                                  >
                                    <span
                                      rel="noopener noreferrer"
                                      className="
                                  bg-yellow-100 
                                  text-yellow-800 
                                  border 
                                  border-yellow-400
                                  text-md 
                                  font-medium 
                                  mr-2 px-2.5 
                                  py-0.5 
                                  rounded 
                                  dark:bg-yellow-900 
                                  dark:text-yellow-300 
                                  hover:cursor-pointer"
                                    >
                                      Pending
                                    </span>
                                  </Link>
                                ) : (
                                  ""
                                )}
                              </>
                            ) : (
                              <>
                                {/* Check it is the same user who did desk review  */}
                                {Number(
                                  application.data.user_validated_id_application
                                ) === Number(userJson.id) ? (
                                  <span
                                    rel="noopener noreferrer"
                                    className="
                                   bg-blue-100 
                                   text-blue-800 
                                   border 
                                   border-blue-400
                                   text-md 
                                   font-medium 
                                   mr-2 px-2.5 
                                   py-0.5 
                                   rounded 
                                   dark:bg-blue-900 
                                   dark:text-blue-300"
                                  >
                                    SecondReview
                                  </span>
                                ) : (
                                  <>
                                    {userJson.is_superuser ? (
                                      <Link
                                        to={{
                                          pathname:
                                            "/application/" +
                                            application.data.id_application +
                                            "/viewSecondReview",
                                          state: {
                                            currentApplication:
                                              application.data,
                                            typeOperation: "statusEdit",
                                          },
                                        }}
                                      >
                                        <span
                                          rel="noopener noreferrer"
                                          className="
                                  bg-blue-100 
                                  text-blue-800 
                                  border 
                                  border-blue-400
                                  text-md 
                                  font-medium 
                                  mr-2 px-2.5 
                                  py-0.5 
                                  rounded 
                                  dark:bg-blue-900 
                                  dark:text-blue-300 
                                  hover:cursor-pointer"
                                        >
                                          PendingIUCEA
                                        </span>
                                      </Link>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {Number(application.data.status_application) ===
                            11 ? (
                              <span
                                rel="noopener noreferrer"
                                className="
                                        bg-green-100 
                                        text-green-800 
                                        border 
                                      border-green-400
                                        text-md 
                                        font-medium 
                                        mr-2 px-2.5 
                                        py-0.5 
                                        rounded 
                                        dark:bg-green-900 
                                        dark:text-green-300 
                                        hover:cursor-pointer"
                              >
                                Pre.ReviewDone
                              </span>
                            ) : (
                              <>
                                {Number(application.data.status_application) ===
                                3 ? (
                                  <span
                                    rel="noopener noreferrer"
                                    className="
                                        bg-red-100 
                                        text-red-800 
                                        border 
                                      border-red-400
                                        text-md 
                                        font-medium 
                                        mr-2 px-2.5 
                                        py-0.5 
                                        rounded 
                                        dark:bg-red-900 
                                        dark:text-red-300 
                                        hover:cursor-pointer"
                                  >
                                    Rejected
                                  </span>
                                ) : (
                                  <span
                                    rel="noopener noreferrer"
                                    className="
                                        bg-green-100 
                                        text-green-800 
                                        border 
                                      border-green-400
                                        text-md 
                                        font-medium 
                                        mr-2 px-2.5 
                                        py-0.5 
                                        rounded 
                                        dark:bg-green-900 
                                        dark:text-green-300 
                                        hover:cursor-pointer"
                                  >
                                    Validated
                                  </span>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </td>

                      <td className="px-1 py-1">
                        <div className="grid grid-cols-4">
                          {(Number(application.data.status_application) <= 1 &&
                            Number(
                              application.data.resultSiteVisiteDone
                                .numberSiteVisitNotDone
                            ) <= 0) ||
                          buttonCompleteClass === "disabled" ? (
                            <>
                              <span
                                rel="noopener noreferrer"
                                className="
                                    font-medium 
                                    text-gray-400 
                                    hover:underline"
                              >
                                <FaEye />
                              </span>

                              <span
                                rel="noopener noreferrer"
                                className="
                                    font-medium 
                                    text-gray-400 
                                    hover:underline"
                              >
                                <FaEdit />
                              </span>
                            </>
                          ) : (
                            <>
                              <Link
                                to={{
                                  pathname:
                                    "/application/" +
                                    application.data.id_application +
                                    "/view",
                                  state: {
                                    currentApplication: application.data,
                                    typeOperation: "AddExpert",
                                  },
                                }}
                              >
                                <span
                                  rel="noopener noreferrer"
                                  className="
                                                font-medium  
                                                hover:underline"
                                >
                                  <FaEye />
                                </span>
                              </Link>
                              <Link
                                to={{
                                  pathname:
                                    "/application/" +
                                    application.data.id_application +
                                    "/viewPrintReport",
                                  state: {
                                    currentApplication: application.data,
                                    typeOperation: "AddExpert",
                                  },
                                }}
                              >
                                <span
                                  rel="noopener noreferrer"
                                  className="
                                                font-medium  
                                                hover:underline"
                                >
                                  <FaFilePdf />
                                </span>
                              </Link>
                              {Number(application.data.status_application) >=
                              3 ? (
                                <span
                                  rel="noopener noreferrer"
                                  className="
                                    font-medium 
                                    text-gray-400 
                                    hover:underline"
                                >
                                  <FaEdit />
                                </span>
                              ) : (
                                <Link
                                  to={{
                                    pathname:
                                      "/application/" +
                                      application.data.id_application +
                                      "/edit",
                                    state: {
                                      currentApplication: application.data,
                                      typeOperation: "",
                                    },
                                  }}
                                >
                                  <span
                                    rel="noopener noreferrer"
                                    className="
                                                font-medium 
                                                text-blue-600 
                                                dark:text-blue-500 
                                                hover:underline"
                                  >
                                    <FaEdit />
                                  </span>
                                </Link>
                              )}
                            </>
                          )}

                          <ConfirmLink
                            action={deleteConfirm}
                            actionArgs={application.data.id_application}
                            style={{}}
                          >
                            <a
                              href="#"
                              rel="noopener noreferrer"
                              className="
                                                font-medium 
                                                text-red-600 
                                                dark:text-red-500 
                                                hover:underline"
                            >
                              <FaTrash />
                            </a>
                          </ConfirmLink>
                        </div>
                      </td>
                    </tr>
                  );
                })}
                {filteredData.length === 0 && (
                  <tr>
                    <td
                      className="border border-gray-300 px-4 py-2"
                      colSpan="9"
                    >
                      No matching results found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {show && <Modal details={selectedData} handleClose={hideModal} />}
        </div>

        <div className="w-full">
          {/* <!-- Start coding here --> */}
          <div className="relative overflow-hidden bg-[var(--color-gray-tbl0)] rounded-b-lg shadow-md dark:bg-[var(--color-gray-tbl800)]">
            <nav
              className="flex flex-col items-start justify-between p-4 space-y-3 md:flex-row md:items-center md:space-y-0"
              aria-label="Table navigation"
            >
              <span className="text-md font-normal text-[var(--color-gray-tbl500)] dark:text-[var(--color-gray-tbl400)]">
                Showing{" "}
                <span className="font-semibold text-[var(--color-gray-tbl900)] dark:text-[var(--color-gray-tbl0)]">
                  1-{totalNumberPerPage}
                </span>{" "}
                of{" "}
                <span className="font-semibold text-[var(--color-gray-tbl900)] dark:text-[var(--color-gray-tbl0)]">
                  {totalNumberData}
                </span>
              </span>
              <ul className="inline-flex items-stretch -space-x-px">
                <div className="flex items-center justify-between px-4 py-2">
                  <li>
                    <button
                      onClick={handlePreviousPage} // Call the new function here
                      disabled={currentPage <= 1}
                      className={`px-3 py-1 text-md font-semibold rounded-lg focus:outline-none ${
                        currentPage <= 1
                          ? "opacity-50 cursor-not-allowed"
                          : "bg-[var(--color-gray-tbl0)] hover:bg-blue-600 text-black hover:text-white"
                      }`}
                    >
                      <svg
                        className="w-5 h-5"
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={handleNextPage}
                      disabled={currentPage >= totalPages}
                      className={`px-3 py-1 text-md font-semibold rounded-lg focus:outline-none ${
                        currentPage >= totalPages
                          ? "opacity-50 cursor-not-allowed"
                          : "bg-[var(--color-gray-tbl0)] hover:bg-blue-600 text-black hover:text-white"
                      }`}
                    >
                      <svg
                        className="w-5 h-5"
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </button>
                  </li>
                </div>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

const Modal = ({ handleClose, details }) => {
  return (
    <>
      <div className="justify-center capitalize items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-3xl font-semibold">
                Documents submitted for:{" "}
                {details.data.title_program_application}
              </h3>
              <button
                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={handleClose}
              >
                <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>
            {/*body*/}
            <div className="relative p-6 flex-auto">
              {details.data.evidence_for_accreditation_at_national_level &&
              details.data.evidence_for_accreditation_at_national_level &&
              details.data.confirmation_letter_national_agency &&
              details.data.curriculum_development &&
              details.data.postgraduate_supervision_guidelines ? (
                <table
                  className=" 
                   w-full 
                   tgray-700
                   text-left                
                   text-gray-700 
                   dark:text-gray-400"
                >
                  <thead>
                    <tr className="even:bg-gray-50 odd:bg-gray-100  odd:text-gray-700">
                      {details.data.other_not_specified ? (
                        <th className="" scope="col">
                          others not specified
                        </th>
                      ) : (
                        ""
                      )}
                      {details.data
                        .evidence_for_accreditation_at_national_level ? (
                        <th scope="col">
                          evidence for accreditation at national level
                        </th>
                      ) : (
                        ""
                      )}
                      {details.data.confirmation_letter_national_agency ? (
                        <th scope="col">confirmation letter national agency</th>
                      ) : (
                        ""
                      )}
                    </tr>
                    <tr className="even:bg-gray-50 odd:bg-gray-100 even:text-black odd:text-gray-700">
                      {details.data.other_not_specified ? (
                        <td className="">
                          <a
                            href={`${process.env.REACT_APP_API_URL}/documents/${details.data.other_not_specified}`}
                            target="_blank"
                          >
                            {" "}
                            <FaFilePdf color="green" size={25} />
                          </a>
                        </td>
                      ) : (
                        ""
                      )}
                      {details.data
                        .evidence_for_accreditation_at_national_level ? (
                        <td>
                          <a
                            href={`${process.env.REACT_APP_API_URL}/documents/${details.data.evidence_for_accreditation_at_national_level}`}
                            target="_blank"
                          >
                            {" "}
                            <FaFilePdf color="green" size={25} />{" "}
                          </a>
                        </td>
                      ) : (
                        ""
                      )}
                      {details.data.confirmation_letter_national_agency ? (
                        <td>
                          {" "}
                          <a
                            href={`${process.env.REACT_APP_API_URL}/documents/${details.data.confirmation_letter_national_agency}`}
                            target="_blank"
                          >
                            {" "}
                            <FaFilePdf color="green" size={25} />
                          </a>
                        </td>
                      ) : (
                        ""
                      )}
                    </tr>
                    <tr className="even:bg-gray-50 odd:bg-gray-100 even:text-black odd:text-gray-700">
                      {details.data
                        .involvment_of_the_relevant_national_bodies ? (
                        <th scope="col">
                          involvment of the relevant national bodies
                        </th>
                      ) : (
                        ""
                      )}
                      {details.data.curriculum_development ? (
                        <th scope="col">curriculum development</th>
                      ) : (
                        ""
                      )}
                      {details.data.postgraduate_supervision_guidelines ? (
                        <th scope="col">postgraduate supervision guidelines</th>
                      ) : (
                        ""
                      )}
                    </tr>
                    <tr className="even:bg-gray-50 odd:bg-gray-100 even:text-black odd:text-gray-700">
                      {details.data
                        .involvment_of_the_relevant_national_bodies ? (
                        <td>
                          <a
                            href={`${process.env.REACT_APP_API_URL}/documents/${details.data.document2_involvment_of_the_relevant_national_bodiesdetails}`}
                            target="_blank"
                          >
                            {" "}
                            <FaFilePdf color="green" size={25} />
                          </a>
                        </td>
                      ) : (
                        ""
                      )}
                      {details.data.curriculum_development ? (
                        <td>
                          <a
                            href={`${process.env.REACT_APP_API_URL}/documents/${details.data.curriculum_development}`}
                            target="_blank"
                          >
                            {" "}
                            <FaFilePdf color="green" size={25} />
                          </a>
                        </td>
                      ) : (
                        ""
                      )}
                      {details.data.postgraduate_supervision_guidelines ? (
                        <td>
                          <a
                            href={`${process.env.REACT_APP_API_URL}/documents/${details.data.postgraduate_supervision_guidelines}`}
                            target="_blank"
                          >
                            {" "}
                            <FaFilePdf color="green" size={25} />
                          </a>
                        </td>
                      ) : (
                        ""
                      )}
                    </tr>
                    <tr className="even:bg-gray-50 odd:bg-gray-100 even:text-black odd:text-gray-700">
                      {details.data.quality_assurence_policy ? (
                        <th scope="col">Quality Assurance policy</th>
                      ) : (
                        ""
                      )}
                      {details.data.credit_transfer_policy ? (
                        <th scope="col">credit transfer policy</th>
                      ) : (
                        ""
                      )}
                      {details.data.summary_of_staff_capacity ? (
                        <th scope="col">summary of staff capacity</th>
                      ) : (
                        ""
                      )}
                    </tr>
                    <tr className="even:bg-gray-50 odd:bg-gray-100 even:text-black odd:text-gray-700">
                      {details.data.quality_assurence_policy ? (
                        <td>
                          <a
                            href={`${process.env.REACT_APP_API_URL}/documents/${details.data.quality_assurence_policy}`}
                            target="_blank"
                          >
                            {" "}
                            <FaFilePdf color="green" size={25} />
                          </a>
                        </td>
                      ) : (
                        ""
                      )}
                      {details.data.credit_transfer_policy ? (
                        <td>
                          <a
                            href={`${process.env.REACT_APP_API_URL}/documents/${details.data.credit_transfer_policy}`}
                            target="_blank"
                          >
                            {" "}
                            <FaFilePdf color="green" size={25} />
                          </a>
                        </td>
                      ) : (
                        ""
                      )}
                      {details.data.summary_of_staff_capacity ? (
                        <td>
                          <a
                            href={`${process.env.REACT_APP_API_URL}/documents/${details.data.summary_of_staff_capacity}`}
                            target="_blank"
                          >
                            {" "}
                            <FaFilePdf color="green" size={25} />
                          </a>
                        </td>
                      ) : (
                        ""
                      )}
                    </tr>
                    <tr className="even:bg-gray-50 odd:bg-gray-100 even:text-black odd:text-gray-700">
                      {details.data.inventory_of_facilities ? (
                        <th scope="col">inventory of facilities</th>
                      ) : (
                        ""
                      )}
                      {details.data.annual_survey_report ? (
                        <th scope="col">annual survey report</th>
                      ) : (
                        ""
                      )}
                      {details.data.tracer_study_report ? (
                        <th scope="col">tracer study report</th>
                      ) : (
                        ""
                      )}
                    </tr>
                    <tr className="even:bg-gray-50 odd:bg-gray-100 even:text-black odd:text-gray-700">
                      {details.data.inventory_of_facilities ? (
                        <td>
                          <a
                            href={`${process.env.REACT_APP_API_URL}/documents/${details.data.inventory_of_facilities}`}
                            target="_blank"
                          >
                            {" "}
                            <FaFilePdf color="green" size={25} />
                          </a>
                        </td>
                      ) : (
                        ""
                      )}
                      {details.data.annual_survey_report ? (
                        <td>
                          <a
                            href={`${process.env.REACT_APP_API_URL}/documents/${details.data.annual_survey_report}`}
                            target="_blank"
                          >
                            {" "}
                            <FaFilePdf color="green" size={25} />
                          </a>
                        </td>
                      ) : (
                        ""
                      )}
                      {details.data.tracer_study_report ? (
                        <td>
                          <a
                            href={`${process.env.REACT_APP_API_URL}/documents/${details.data.tracer_study_report}`}
                            target="_blank"
                          ></a>
                        </td>
                      ) : (
                        ""
                      )}
                    </tr>
                    <tr className="even:bg-gray-50 odd:bg-gray-100 even:text-black odd:text-gray-700">
                      {details.data.examination_policy ? (
                        <th scope="col">examination policy</th>
                      ) : (
                        ""
                      )}
                      {details.data.program_review_report ? (
                        <th scope="col">program review report</th>
                      ) : (
                        ""
                      )}
                      {details.data.admission_policy ? (
                        <th scope="col">admission policy</th>
                      ) : (
                        ""
                      )}
                    </tr>
                    <tr className="even:bg-gray-50 odd:bg-gray-100 even:text-black odd:text-black-100">
                      {details.data.examination_policy ? (
                        <td>
                          <a
                            href={`${process.env.REACT_APP_API_URL}/documents/${details.data.examination_policy}`}
                            target="_blank"
                          >
                            {" "}
                            <FaFilePdf color="green" size={25} />
                          </a>
                        </td>
                      ) : (
                        ""
                      )}
                      {details.data.program_review_report ? (
                        <td>
                          <a
                            href={`${process.env.REACT_APP_API_URL}/documents/${details.data.program_review_report}`}
                            target="_blank"
                          >
                            {" "}
                            <FaFilePdf color="green" size={25} />
                          </a>
                        </td>
                      ) : (
                        ""
                      )}
                      {details.data.admission_policy ? (
                        <td>
                          <a
                            href={`${process.env.REACT_APP_API_URL}/documents/${details.data.admission_policy}`}
                            target="_blank"
                          >
                            {" "}
                            <FaFilePdf color="green" size={25} />
                          </a>
                        </td>
                      ) : (
                        ""
                      )}
                    </tr>

                    <tr className="even:bg-gray-50 odd:bg-gray-100 even:text-black odd:text-gray-700">
                      {details.data.programme_file ? (
                        <th scope="col">programme file</th>
                      ) : (
                        ""
                      )}
                      {details.data.auditReport_report ? (
                        <th scope="col">Audit Report of the programme</th>
                      ) : (
                        ""
                      )}
                      {details.data.confirmation_letter_of_key_stackHolder ? (
                        <th scope="col">
                          confirmation letter from stake Holder
                        </th>
                      ) : (
                        ""
                      )}
                    </tr>
                    <tr className="even:bg-gray-50 odd:bg-gray-100 even:text-black odd:text-black-100">
                      {details.data.programme_file ? (
                        <td>
                          <a
                            href={`${process.env.REACT_APP_API_URL}/documents/${details.data.programme_file}`}
                            target="_blank"
                          >
                            {" "}
                            <FaFilePdf color="green" size={25} />
                          </a>
                        </td>
                      ) : (
                        ""
                      )}
                      {details.data.auditReport_report ? (
                        <td>
                          <a
                            href={`${process.env.REACT_APP_API_URL}/documents/${details.data.auditReport_report}`}
                            target="_blank"
                          >
                            {" "}
                            <FaFilePdf color="green" size={25} />
                          </a>
                        </td>
                      ) : (
                        ""
                      )}
                      {details.data.confirmation_letter_of_key_stackHolder ? (
                        <td>
                          <a
                            href={`${process.env.REACT_APP_API_URL}/documents/${details.data.confirmation_letter_of_key_stackHolder}`}
                            target="_blank"
                          >
                            {" "}
                            <FaFilePdf color="green" size={25} />
                          </a>
                        </td>
                      ) : (
                        ""
                      )}
                    </tr>
                    <tr className="even:bg-gray-50 odd:bg-gray-100 even:text-black odd:text-gray-700">
                      {details.data.curriculum_development_and_guidelines ? (
                        <th scope="col">
                          curriculum development and guidelines
                        </th>
                      ) : (
                        ""
                      )}
                      {details.data.evidence_of_participation_of_stackHolder ? (
                        <th scope="col">
                          evidence of participation of stackHolder{" "}
                        </th>
                      ) : (
                        ""
                      )}
                      {details.data.market_survey_report ? (
                        <th scope="col">market survey report</th>
                      ) : (
                        ""
                      )}
                    </tr>
                    <tr className="even:bg-gray-50 odd:bg-gray-100 even:text-black odd:text-black-100">
                      {details.data.curriculum_development_and_guidelines ? (
                        <td>
                          <a
                            href={`${process.env.REACT_APP_API_URL}/documents/${details.data.curriculum_development_and_guidelines}`}
                            target="_blank"
                          >
                            {" "}
                            <FaFilePdf color="green" size={25} />
                          </a>
                        </td>
                      ) : (
                        ""
                      )}
                      {details.data.evidence_of_participation_of_stackHolder ? (
                        <td>
                          <a
                            href={`${process.env.REACT_APP_API_URL}/documents/${details.data.evidence_of_participation_of_stackHolder}`}
                            target="_blank"
                          >
                            {" "}
                            <FaFilePdf color="green" size={25} />
                          </a>
                        </td>
                      ) : (
                        ""
                      )}
                      {details.data.market_survey_report ? (
                        <td>
                          <a
                            href={`${process.env.REACT_APP_API_URL}/documents/${details.data.market_survey_report}`}
                            target="_blank"
                          >
                            {" "}
                            <FaFilePdf color="green" size={25} />
                          </a>
                        </td>
                      ) : (
                        ""
                      )}
                    </tr>
                  </thead>
                </table>
              ) : (
                " No documents available"
              )}
            </div>
            {/*footer*/}
            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="text-red-500 bg-red-100 font-bold uppercase px-6 py-2 text-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={handleClose}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default ApplicationList;
