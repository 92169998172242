import {
  AiFillAlert,
  AiFillDashboard,
  AiFillHome,
  AiFillSetting,
  AiOutlineAccountBook,
  AiOutlineAlignCenter,
  AiOutlineBarChart,
  AiOutlineDotChart,
} from "react-icons/ai";
import {
  BiAdjust,
  BiHomeAlt2,
  BiLayer,
  BiLogOutCircle,
  BiUserCircle,
} from "react-icons/bi";
import { BsReverseLayoutTextSidebarReverse } from "react-icons/bs";
import {
  FaEdit,
  FaFileSignature,
  FaListAlt,
  FaMarker,
  FaPlus,
  FaUserCog,
} from "react-icons/fa";
import { IoIosEye } from "react-icons/io";

const MenusAdmin = [
  {
    title: "Dashboard",
    nametitle: "Dashboard",
    icon: <AiFillDashboard />,
    link: "/dashboard",
  },

  {
    title: "Application",
    nametitle: "Application",
    icon: <FaFileSignature />,
    submenu: true,
    indexMenu: 1,
    submenuItems: [
      {
        title: "List",
        nametitle: "List Application",
        icon: <FaListAlt />,
        link: "/application/list",
      },
      {
        title: "Add",
        nametitle: "Create Application",
        icon: <FaPlus />,
        link: "/application/create",
      },
    ],
  },
  {
    title: "Review",
    nametitle: "Review",
    submenu: true,
    indexMenu: 2,
    icon: <FaMarker />,
    submenuItems: [
      {
        title: "List",
        nametitle: "List Review",
        icon: <BsReverseLayoutTextSidebarReverse />,
        link: "/expertReview/list/data",
      },

      // {
      //   title: "Others",
      //   icon: <IoIosEye />,
      // },
    ],
  },
  // {
  //   title: "Analytics",
  //   icon: <AiOutlineBarChart />,
  //   link: '/cheaReport'
  // },

  {
    title: "Site Visit",
    nametitle: "List Site Visit",
    icon: <AiFillHome />,
    link: "/siteVisite/list/view",
  },
  {
    title: "C.H.E.A",
    nametitle: "List C.H.E.A",
    icon: <AiOutlineAlignCenter />,
    link: "/cheaReport",
  },
  {
    title: "Executive Com",
    nametitle: "Executive Committee",
    icon: <AiOutlineAccountBook />,
    link: "/executiveCommittee",
  },
  {
    title: "Accreditation",
    nametitle: "List Accreditation",
    icon: <AiFillAlert />,
    link: "/accreditation/list",
  },
  {
    title: "Appeal",
    nametitle: "List Appeal",
    icon: <AiOutlineDotChart />,
    link: "/appeal/list",
  },
  {
    title: <span className="text-red-400 font-semibold">Settings</span>,
    nametitle: "Settings",
    icon: <AiFillSetting />,
    submenu: true,
    indexMenu: 0,
    submenuItems: [
      {
        title: <span className="font-semibold">Users</span>,
        nametitle: "Settings Users",
        icon: <BiUserCircle />,
        submenu: true,
        indexMenu: 0,
        submenuItems: [
          {
            title: "List",
            nametitle: "List Users",
            icon: <FaListAlt />,
            link: "/users/list",
          },
          {
            title: "Profile",
            nametitle: "Profile User",
            spacing: true,
            icon: <FaUserCog />,
            link: `/users/view/`,
          },
        ],
      },

      {
        title: <span className="font-semibold">University</span>,
        nametitle: "University",
        icon: <BiHomeAlt2 />,
        submenu: true,
        indexMenu: 0,
        submenuItems: [
          {
            title: "List",
            nametitle: "List University",
            icon: <FaListAlt />,
            link: "/university/list",
          },
          {
            title: "Add",
            nametitle: "Add University",
            icon: <FaPlus />,
            link: "/university/add",
          },
        ],
      },

      {
        title: <span className="font-semibold">Level </span>,
        nametitle: "Level",
        icon: <BiLayer />,
        submenu: true,
        indexMenu: 0,
        submenuItems: [
          {
            title: "List",
            nametitle: "List Level",
            icon: <FaListAlt />,
            link: "/level/list",
          },
          {
            title: "Add",
            nametitle: "Add Level",
            icon: <FaPlus />,
            link: "/level/add",
          },
        ],
      },

      {
        title: <span className="font-semibold">Discipline Cluster</span>,
        nametitle: "Discipline Cluster",
        icon: <BiAdjust />,
        submenu: true,
        indexMenu: 0,
        submenuItems: [
          {
            title: "List",
            nametitle: "List Discipline Cluster",
            icon: <FaListAlt />,
            link: "/thematicArea/list",
          },
          {
            title: "Add",
            nametitle: "Add Discipline Cluster",
            icon: <FaPlus />,
            link: "/thematicArea/add",
          },
        ],
      },
      {
        title: <span className="font-semibold">Chair Signatories</span>,
        nametitle: "Chair Signatories",
        submenu: true,
        indexMenu: 2,
        icon: <FaEdit />,
        submenuItems: [
          {
            title: "List",
            nametitle: "List Chair Signatories",
            icon: <BsReverseLayoutTextSidebarReverse />,
            link: "/Chairsignatory/list",
          },

          {
            title: "Add",
            nametitle: "Add Chair Signatories",
            icon: <FaPlus />,
            link: "/Chairsignatory/add",
          },
        ],
      },
    ],
  },
];

const MenusApplicants = [
  {
    title: "Dashboard",
    nametitle: "Dashboard",
    icon: <AiFillDashboard />,
    link: "/dashboard",
  },
  {
    title: "Application",
    nametitle: "Application",
    icon: <FaFileSignature />,
    submenu: true,
    indexMenu: 0,
    submenuItems: [
      {
        title: "Add",
        nametitle: "Add Application",
        icon: <FaPlus />,
        link: "/application/create",
      },
    ],
  },
  {
    title: "C.H.E.A",
    nametitle: "List C.H.E.A",
    icon: <AiOutlineAlignCenter />,
    link: "/cheaReport",
  },
  {
    title: "Executive Com",
    nametitle: "Executive Committee",
    icon: <AiOutlineAccountBook />,
    link: "/executiveCommittee",
  },
  {
    title: "Accreditation",
    nametitle: "List Accreditation",
    icon: <AiFillAlert />,
    link: "/accreditation/list",
  },
  {
    title: "Appeal",
    nametitle: "List Appeal",
    icon: <AiOutlineDotChart />,
    link: "/appeal/list",
  },
  {
    title: "Profile",
    nametitle: "Profile User",
    spacing: true,
    icon: <FaUserCog />,
    link: `/users/view/`,
  },
];

const MenusAssessors = [
  {
    title: "Dashboard",
    nametitle: "Dashboard",
    icon: <AiFillDashboard />,
    link: "/dashboard",
  },

  {
    title: "Application",
    nametitle: "Application",
    icon: <FaFileSignature />,
    submenu: true,
    indexMenu: 1,
    submenuItems: [
      {
        title: "List",
        nametitle: "List Application",
        icon: <FaListAlt />,
        link: "/application/list",
      },
      // {
      //   title: "Add",
      //   nametitle: "Create Application",
      //   icon: <FaPlus />,
      //   link: "/application/create",
      // },
    ],
  },
  {
    title: "Review",
    nametitle: "Review",
    submenu: true,
    indexMenu: 2,
    icon: <FaMarker />,
    submenuItems: [
      {
        title: "List",
        nametitle: "List Review",
        icon: <BsReverseLayoutTextSidebarReverse />,
        link: "/expertReview/list/data",
      },
    ],
  },
  {
    title: "Site Visit",
    nametitle: "List Site Visit",
    icon: <AiFillHome />,
    link: "/siteVisite/list/view",
  },
  {
    title: "C.H.E.A",
    nametitle: "List C.H.E.A",
    icon: <AiOutlineAlignCenter />,
    link: "/cheaReport",
  },
  {
    title: "Accreditation",
    nametitle: "List Accreditation",
    icon: <AiFillAlert />,
    link: "/accreditation/list",
  },
  // {
  //   title: "Appeal",
  //   nametitle: "List Appeal",
  //   icon: <AiOutlineDotChart />,
  //   link: "/appeal/list",
  // },

  {
    title: "Profile",
    nametitle: "Profile User",
    spacing: true,
    icon: <FaUserCog />,
    link: `/users/view/`,
  },
];

const MenusIUCEADesk = [
  {
    title: "Dashboard",
    nametitle: "Dashboard",
    icon: <AiFillDashboard />,
    link: "/dashboard",
  },
  {
    title: "Application",
    nametitle: "Application",
    icon: <FaFileSignature />,
    submenu: true,
    indexMenu: 1,
    submenuItems: [
      {
        title: "List",
        nametitle: "List Application",
        icon: <FaListAlt />,
        link: "/application/list",
      },
      // {
      //   title: "Add",
      //   nametitle: "Create Application",
      //   icon: <FaPlus />,
      //   link: "/application/create",
      // },
    ],
  },
  {
    title: "Review",
    nametitle: "Review",
    submenu: true,
    indexMenu: 2,
    icon: <FaMarker />,
    submenuItems: [
      {
        title: "List",
        nametitle: "List Review",
        icon: <BsReverseLayoutTextSidebarReverse />,
        link: "/expertReview/list/data",
      },

      // {
      //   title: "Others",
      //   icon: <IoIosEye />,
      // },
    ],
  },

  {
    title: "Accreditation",
    nametitle: "List Accreditation",
    icon: <AiFillAlert />,
    link: "/accreditation/list",
  },
  {
    title: "C.H.E.A",
    nametitle: "List C.H.E.A",
    icon: <AiOutlineAlignCenter />,
    link: "/cheaReport",
  },
  {
    title: "Appeal",
    nametitle: "List Appeal",
    icon: <AiOutlineDotChart />,
    link: "/appeal/list",
  },
  {
    title: "Site Visit",
    nametitle: "List Site Visit",
    icon: <AiFillHome />,
    link: "/siteVisite/list/view",
  },
  {
    title: <span className="text-red-400 font-semibold">Settings</span>,
    nametitle: "Settings",
    icon: <AiFillSetting />,
    submenu: true,
    indexMenu: 0,
    submenuItems: [
      {
        title: <span className="font-semibold">Users</span>,
        nametitle: "List Users",
        icon: <BiUserCircle />,
        submenu: true,
        indexMenu: 0,
        submenuItems: [
          {
            title: "List",
            nametitle: "List Users",
            icon: <FaListAlt />,
            link: "/users/list",
          },
          {
            title: "Profile",
            nametitle: "Profile Users",
            spacing: true,
            icon: <FaUserCog />,
            link: `/users/view/`,
          },
        ],
      },

      {
        title: <span className="font-semibold">University</span>,
        nametitle: "University",
        icon: <BiHomeAlt2 />,
        submenu: true,
        indexMenu: 0,
        submenuItems: [
          {
            title: "List",
            nametitle: "List University",
            icon: <FaListAlt />,
            link: "/university/list",
          },
          {
            title: "Add",
            nametitle: "Add University",
            icon: <FaPlus />,
            link: "/university/add",
          },
        ],
      },

      {
        title: <span className="font-semibold">Level </span>,
        nametitle: "Level",
        icon: <BiLayer />,
        submenu: true,
        indexMenu: 0,
        submenuItems: [
          {
            title: "List",
            nametitle: "List Level",
            icon: <FaListAlt />,
            link: "/level/list",
          },
          {
            title: "Add",
            nametitle: "Add Level",
            icon: <FaPlus />,
            link: "/level/add",
          },
        ],
      },

      {
        title: <span className="font-semibold">Discipline Cluster</span>,
        nametitle: "Discipline Cluster",
        icon: <BiAdjust />,
        submenu: true,
        indexMenu: 0,
        submenuItems: [
          {
            title: "List",
            nametitle: "List Discipline Cluster",
            icon: <FaListAlt />,
            link: "/thematicArea/list",
          },
          {
            title: "Add",
            nametitle: "Add Discipline Cluster",
            icon: <FaPlus />,
            link: "/thematicArea/add",
          },
        ],
      },
      {
        title: <span className="font-semibold">Chair Signatories</span>,
        nametitle: "Chair Signatories",
        submenu: true,
        indexMenu: 2,
        icon: <FaEdit />,
        submenuItems: [
          {
            title: "List",
            nametitle: "List Chair Signatories",
            icon: <BsReverseLayoutTextSidebarReverse />,
            link: "/Chairsignatory/list",
          },

          {
            title: "Add",
            nametitle: "Add Chair Signatories",
            icon: <FaPlus />,
            link: "/Chairsignatory/add",
          },
        ],
      },
    ],
  },
  // {
  //   title: "Settings",
  //   icon: <AiFillSetting />,
  // },
];

export { MenusAdmin, MenusApplicants, MenusAssessors, MenusIUCEADesk };
