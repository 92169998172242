import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { login } from "../../actions/auth";
import { useFormik, Field } from "formik";
import Success from "../../alerts/Success";
import Error from "../../alerts/Error";
import ExpertReviewService from "../../../services/ExpertReviewService";
import { FaFilePdf } from "react-icons/fa";
import Tab from "../../smallComponents/Tab";
import TabContent from "../../smallComponents/TabContent";
import { preliminaryAssessment } from "../ConstantsAssessements";
import SiteVisiteService from "../../../services/SiteVisiteService";
import Loading from "../../eventsHandler/Loading";
import { siteVisiteInformationDataInputPreview } from "../ConstantsAssessementsForExpertSiteVisiteInformations";
import TabContentSiteVisit from "../../smallComponents/TabContentSiteVisit";

const SiteVisiteAdd = (props) => {
  let editing = false;
  let initialTitle_program_application = "";
  let initialDesignation_application = "";
  let initialUniversity_id_application = "";
  let initialLevel_id_application = "";
  let initialOtherInfos = "";
  let initialevidence_for_accreditation_at_national_level = "";
  let initialconfirmation_letter_national_agency = "";
  let initialinvolvment_of_the_relevant_national_bodies = "";
  let initialcurriculum_development = "";
  let initialpostgraduate_supervision_guidelines = "";
  let initialquality_assurence_policy = "";
  let initialcredit_transfer_policy = "";
  let initialsummary_of_staff_capacity = "";
  let initialinventory_of_facilities = "";
  let initialannual_survey_report = "";
  let initialtracer_study_report = "";
  let initialexamination_policy = "";
  let initialadmission_policy = "";
  let initialprogram_review_report = "";
  let initialother_not_specified = "";
  let initialDocument2_application = "";
  let initialDocument3_application = "";
  let initialApplicationID = "";
  let typeOperation = "";
  let initialstatus_application = "";
  let initialstatus_thematicArea_application = "";
  let initialuser_created_application = "";
  let id_provenance = "";
  let application = [];

  let userSubmitterInfor = [];
  let idApplication = '';

  if (
    props.location.data.currentApplication &&
    props.location.data.currentApplication
  ) {
    editing = true;
    typeOperation = props.location.data.currentApplication.typeOperation;
    initialstatus_application =
      props.location.data.currentApplication.status_application;
    initialApplicationID =
      props.location.data.currentApplication.id_application;
    initialevidence_for_accreditation_at_national_level =
      props.location.data.currentApplication
        .evidence_for_accreditation_at_national_level;
    initialconfirmation_letter_national_agency =
      props.location.data.currentApplication
        .confirmation_letter_national_agency;
    initialinvolvment_of_the_relevant_national_bodies =
      props.location.data.currentApplication
        .involvment_of_the_relevant_national_bodies;
    initialcurriculum_development =
      props.location.data.currentApplication.curriculum_development;
    initialpostgraduate_supervision_guidelines =
      props.location.data.currentApplication
        .postgraduate_supervision_guidelines;
    initialquality_assurence_policy =
      props.location.data.currentApplication.quality_assurence_policy;
    initialcredit_transfer_policy =
      props.location.data.currentApplication.credit_transfer_policy;
    initialsummary_of_staff_capacity =
      props.location.data.currentApplication.summary_of_staff_capacity;
    initialinventory_of_facilities =
      props.location.data.currentApplication.inventory_of_facilities;
    initialannual_survey_report =
      props.location.data.currentApplication.annual_survey_report;
    initialtracer_study_report =
      props.location.data.currentApplication.tracer_study_report;
    initialexamination_policy =
      props.location.data.currentApplication.examination_policy;
    initialadmission_policy =
      props.location.data.currentApplication.admission_policy;
    initialprogram_review_report =
      props.location.data.currentApplication.program_review_report;
    initialother_not_specified =
      props.location.data.currentApplication.other_not_specified;
    initialTitle_program_application =
      props.location.data.currentApplication.title_program_application;
    initialDesignation_application =
      props.location.data.currentApplication.designation_application;
    initialUniversity_id_application =
      props.location.data.currentApplication.university_id_application;
    initialLevel_id_application =
      props.location.data.currentApplication.level_id_application;
  
    initialOtherInfos =
      props.location.data.currentApplication.otherInfor_application;
    initialuser_created_application =
      props.location.data.currentApplication.user_created_application;
    initialstatus_thematicArea_application =
      props.location.data.currentApplication.thematicArea_application;
    userSubmitterInfor =
      props.location.data.currentApplication.userApplicationEmail;
    id_provenance = props.location.data.currentApplication.id_provenance;
    application = props.location.data.currentApplication;

    idApplication =application.id_application;
  }

  console.log("Values ", idApplication);
  let id_siteVisitAffectationExpert = props.location.data.id_siteVisitAffectationExpert;

  const [buttonLabel, setButtonLabel] = useState("");
  const [titleName, setTitleName] = useState(initialTitle_program_application);
  const [level, setLevel] = useState(initialLevel_id_application);
 

  let levelData = JSON.parse(localStorage.getItem("levelData"));
  if (props.location.data.currentApplication.expertReviewData) {
  }
  const history = useHistory();
 
  const [statusCheckboxSelect, setStatusCheckboxSelect] = useState(false);
  const [idFirstFeedback, setIdFirstFeedback] = useState("");

  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [disableSaveBtn, setDisableSaveBtn] = useState(false);

  if (!(history.location.state == null)) {
    const data = history.location.state.data;
    console.log("Data send: ");
    console.log(data);
  }

  const [selectedValues, setSelectedValues] = useState({});

  // Callback function to handle radio button change
  function handleRadioChange(name, value) {
    setSelectedValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    // name.preventDefault();
  }

  function handleInputChangeNumberNA(name, value) {
    setSelectedValues((prevState) => ({
      ...prevState,
      [name]: value,
    })); 
    console.log('name',name)
    console.log('value',value)
  }

  function handleInputChangeTextComment(name) {
    setSelectedValues((prevState) => ({
      ...prevState,
      [name.target.name]: name.target.value,
    }));
  }


  const changeStatus = (status) => {
    console.log("checked: ", status.target.value);
    setStatusCheckboxSelect(status.target.value);
  };

  let levelGot = "";
  levelData.map((levelDB) => {
    if (levelDB.id_level === level) {
      levelGot = levelDB.designation_level;
    }
  });

  useEffect(() => {
    // get the ExpertReviewIdBefore before insertion
    SiteVisiteService.getExpertReview(
      localStorage.getItem("access"),
      urlFirstFeedback
    )
      .then((response) => {
        response.data.map((data) => {
          setIdFirstFeedback(data.id_expertReview);
        });
        console.log("idFirstFeedback : ", idFirstFeedback);
      })
      .catch((err) => {
        console.log("err ", err);
        if (err.response.status === 401) {
          // Redirect to the login page
          history.push('/login');
        }    
      });
  }, []);

  let userInfo = localStorage.getItem("userInfo");
  let userJson = JSON.parse(userInfo);

  let urlFirstFeedback = `${process.env.REACT_APP_API_URL}/api/expertReview/${initialApplicationID}/${userJson.id}`;

  // let urlUser = `${process.env.REACT_APP_API_URL}/api/user/${initialuser_created_application}/view`;

  // let expertListId = JSON.parse(localStorage.getItem("ExpertApplication"));

  const handleFormSubmit = (values, { setSubmitting }) => {
    setButtonDisabled(true);
    setDisableSaveBtn(true);
    // create a constant for all input radio
    const allValues = { ...selectedValues }; 

    // prepare the Json which we will send in database
    const newsiteVisite = new FormData();
 
    newsiteVisite.append(
      "constant_siteVisitAffectationExpert",
      values.otherConstantsSiteVisite
    );
    newsiteVisite.append("dataColectedSiteVisite", JSON.stringify(allValues));

    if(buttonLabel === 'SubmitFinal'){
      newsiteVisite.append("status_applicationSiteVisite", "2");
    }else if(buttonLabel === 'SubmitPartial'){
      newsiteVisite.append("status_applicationSiteVisite", "1");
    }

    console.log("Clicked, ", buttonLabel);
    console.log("disableSaveBtn: ", disableSaveBtn);
    console.log("newsiteVisite: ", newsiteVisite);

    // // perform the saving of the information
    SiteVisiteService.submitReportSiteVisite(
      id_siteVisitAffectationExpert,
      newsiteVisite,
      localStorage.getItem("access"),
      userJson.email,
      userJson.name,
      initialTitle_program_application,
      idApplication,
    )
      .then((response) => { 
        console.log("Access: ");
        let data = {
          status: 1,
          title: "Okay",
          message: "Thank you for your feedback",
        };
        history.push("/dashboard", { data: data });
      })
      .catch((error) => { 
        console.log("Error : ", error);
        let data = {
          status: 0,
          title: "Error",
          message: "An Error happened !",
        };
        history.push(`/dashboard`, {
          data: data,
        });
      });
    setSubmitting(false);
    setDisableSaveBtn(false);
  };

  const {
    values,
    errors,
    touched,
    isSubmitting,
    setFieldValue,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      designation_application: "",
      title_program_application: initialTitle_program_application,
      university_id_application: initialUniversity_id_application,

      evidence_for_accreditation_at_national_level: "",
      confirmation_letter_national_agency: "",
      involvment_of_the_relevant_national_bodies: "",
      otherConstantsSiteVisite: "",

      otherInfor_application: initialOtherInfos,
      level_id_application: initialLevel_id_application,

      otherInfor_application: initialOtherInfos,
      level_id_application: initialLevel_id_application,
      theOverAllFeedBack: "",
    },
    // validationSchema: ExpertReviewSchemasValidation,
    onSubmit: handleFormSubmit,
  });

  const handleButtonClick = (label, event) => {
    event.preventDefault(); 
    // Store the label of the button in the state variable 
    setButtonLabel(label);

    // Trigger form submission
    handleSubmit();
  };

  let allUniveData = JSON.parse(localStorage.getItem("universityData"));
  let universityName = "";
  allUniveData.map((dataUniv) => {
    if (dataUniv.id === Number(initialUniversity_id_application)) {
      universityName = dataUniv.university_name;
    }
  });

  // console.log(isSubmitting)

  const tabs = [
    {
      title: "Document Submitted",
      content: (
        <div>
          <table
            className=" 
                     w-full 
                     tgray-700
                     text-left                
                     text-gray-700 
                     dark:text-gray-400"
          >
            <thead>
              <tr className="even:bg-gray-50 odd:bg-gray-100  odd:text-gray-700">
                {application.other_not_specified ? (
                  <th className="" scope="col">
                    others not specified
                  </th>
                ) : (
                  ""
                )}
                {application.evidence_for_accreditation_at_national_level ? (
                  <th>evidence for accreditation at national level</th>
                ) : (
                  ""
                )}
                {application.confirmation_letter_national_agency ? (
                  <th>confirmation letter national agency</th>
                ) : (
                  ""
                )}
              </tr>
              <tr className="even:bg-gray-50 odd:bg-gray-100 even:text-black odd:text-gray-700">
                {application.other_not_specified ? (
                  <td className="">
                    <a href={application.other_not_specified} target="_blank">
                      {" "}
                      <FaFilePdf color="green" size={25} />
                    </a>
                  </td>
                ) : (
                  ""
                )}
                {application.evidence_for_accreditation_at_national_level ? (
                  <td>
                    <a
                      href={
                        application.evidence_for_accreditation_at_national_level
                      }
                      target="_blank"
                    >
                      {" "}
                      <FaFilePdf color="green" size={25} />{" "}
                    </a>
                  </td>
                ) : (
                  ""
                )}
                {application.confirmation_letter_national_agency ? (
                  <td>
                    {" "}
                    <a
                      href={application.confirmation_letter_national_agency}
                      target="_blank"
                    >
                      {" "}
                      <FaFilePdf color="green" size={25} />
                    </a>
                  </td>
                ) : (
                  ""
                )}
              </tr>
              <tr className="even:bg-gray-50 odd:bg-gray-100 even:text-black odd:text-gray-700">
                {application.involvment_of_the_relevant_national_bodies ? (
                  <th scope="col">
                    involvment of the relevant national bodies
                  </th>
                ) : (
                  ""
                )}
                {application.curriculum_development ? (
                  <th scope="col">curriculum development</th>
                ) : (
                  ""
                )}
                {application.postgraduate_supervision_guidelines ? (
                  <th scope="col">postgraduate supervision guidelines</th>
                ) : (
                  ""
                )}
              </tr>
              <tr className="even:bg-gray-50 odd:bg-gray-100 even:text-black odd:text-gray-700">
                {application.involvment_of_the_relevant_national_bodies ? (
                  <td>
                    <a
                      href={
                        application.document2_involvment_of_the_relevant_national_bodiesdetails
                      }
                      target="_blank"
                    >
                      {" "}
                      <FaFilePdf color="green" size={25} />
                    </a>
                  </td>
                ) : (
                  ""
                )}
                {application.curriculum_development ? (
                  <td>
                    <a
                      href={application.curriculum_development}
                      target="_blank"
                    >
                      {" "}
                      <FaFilePdf color="green" size={25} />
                    </a>
                  </td>
                ) : (
                  ""
                )}
                {application.postgraduate_supervision_guidelines ? (
                  <td>
                    <a
                      href={application.postgraduate_supervision_guidelines}
                      target="_blank"
                    >
                      {" "}
                      <FaFilePdf color="green" size={25} />
                    </a>
                  </td>
                ) : (
                  ""
                )}
              </tr>
              <tr className="even:bg-gray-50 odd:bg-gray-100 even:text-black odd:text-gray-700">
                {application.quality_assurence_policy ? (
                  <th scope="col">Quality Assurance policy</th>
                ) : (
                  ""
                )}
                {application.credit_transfer_policy ? (
                  <th scope="col">credit transfer policy</th>
                ) : (
                  ""
                )}
                {application.summary_of_staff_capacity ? (
                  <th scope="col">summary of staff capacity</th>
                ) : (
                  ""
                )}
              </tr>
              <tr className="even:bg-gray-50 odd:bg-gray-100 even:text-black odd:text-gray-700">
                {application.quality_assurence_policy ? (
                  <td>
                    <a
                      href={application.quality_assurence_policy}
                      target="_blank"
                    >
                      {" "}
                      <FaFilePdf color="green" size={25} />
                    </a>
                  </td>
                ) : (
                  ""
                )}
                {application.credit_transfer_policy ? (
                  <td>
                    <a
                      href={application.credit_transfer_policy}
                      target="_blank"
                    >
                      {" "}
                      <FaFilePdf color="green" size={25} />
                    </a>
                  </td>
                ) : (
                  ""
                )}
                {application.summary_of_staff_capacity ? (
                  <td>
                    <a
                      href={application.summary_of_staff_capacity}
                      target="_blank"
                    >
                      {" "}
                      <FaFilePdf color="green" size={25} />
                    </a>
                  </td>
                ) : (
                  ""
                )}
              </tr>
              <tr className="even:bg-gray-50 odd:bg-gray-100 even:text-black odd:text-gray-700">
                {application.inventory_of_facilities ? (
                  <th scope="col">inventory of facilities</th>
                ) : (
                  ""
                )}
                {application.annual_survey_report ? (
                  <th scope="col">annual survey report</th>
                ) : (
                  ""
                )}
                {application.tracer_study_report ? (
                  <th scope="col">tracer study report</th>
                ) : (
                  ""
                )}
              </tr>
              <tr className="even:bg-gray-50 odd:bg-gray-100 even:text-black odd:text-gray-700">
                {application.inventory_of_facilities ? (
                  <td>
                    <a
                      href={application.inventory_of_facilities}
                      target="_blank"
                    >
                      {" "}
                      <FaFilePdf color="green" size={25} />
                    </a>
                  </td>
                ) : (
                  ""
                )}
                {application.annual_survey_report ? (
                  <td>
                    <a href={application.annual_survey_report} target="_blank">
                      {" "}
                      <FaFilePdf color="green" size={25} />
                    </a>
                  </td>
                ) : (
                  ""
                )}
                {application.tracer_study_report ? (
                  <td>
                    <a
                      href={application.tracer_study_report}
                      target="_blank"
                    ></a>
                  </td>
                ) : (
                  ""
                )}
              </tr>
              <tr className="even:bg-gray-50 odd:bg-gray-100 even:text-black odd:text-gray-700">
                {application.examination_policy ? (
                  <th scope="col">examination policy</th>
                ) : (
                  ""
                )}
                {application.program_review_report ? (
                  <th scope="col">program review report</th>
                ) : (
                  ""
                )}
                {application.admission_policy ? (
                  <th scope="col">admission policy</th>
                ) : (
                  ""
                )}
              </tr>
              <tr className="even:bg-gray-50 odd:bg-gray-100 even:text-black odd:text-black-100">
                {application.examination_policy ? (
                  <td>
                    <a href={application.examination_policy} target="_blank">
                      {" "}
                      <FaFilePdf color="green" size={25} />
                    </a>
                  </td>
                ) : (
                  ""
                )}
                {application.program_review_report ? (
                  <td>
                    <a href={application.program_review_report} target="_blank">
                      {" "}
                      <FaFilePdf color="green" size={25} />
                    </a>
                  </td>
                ) : (
                  ""
                )}
                {application.admission_policy ? (
                  <td>
                    <a href={application.admission_policy} target="_blank">
                      {" "}
                      <FaFilePdf color="green" size={25} />
                    </a>
                  </td>
                ) : (
                  ""
                )}
              </tr>
            </thead>
          </table>
        </div>
      ),
    },
    {
      title: siteVisiteInformationDataInputPreview(handleRadioChange,handleInputChangeTextComment,handleInputChangeNumberNA).point1[0].bigHeader,
      content: (
        <div>
          <TabContentSiteVisit
            content={siteVisiteInformationDataInputPreview(handleRadioChange,handleInputChangeTextComment,handleInputChangeNumberNA).point1[0]}
          />
        </div>
      ),
    }, 
  ];

  return (
    <div>
      <div className="w-full p-8">

      {(buttonDisabled || disableSaveBtn) && <Loading />}  
         

        {history.location.state == null ||
        history.location.state == undefined ? (
          ""
        ) : (
          <>
            {!(history.location.state.data == null) ? (
              <>
                {history.location.state.data.status === 1 ? (
                  <Success
                    delay="5000"
                    valueFromParent={history.location.state.data}
                  />
                ) : (
                  <Error
                    delay="7000"
                    valueFromParent={history.location.state.data}
                  />
                )}
              </>
            ) : (
              <></>
            )}
          </>
        )}
        <form onSubmit={handleSubmit} autoComplete="off">
          <div className="grid grid-cols-2">
            <div className="mb-4 text-md pt-1">
              <div className="w-1/2 px-3 mb-6 md:mb-0">
                <label
                  className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-2"
                  htmlFor="grid-first-name"
                >
                  Title of the Programme :
                </label>
                <label className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2">
                  {values.title_program_application}
                </label>
              </div>
              <div className="w-1/2 px-3 mb-6 md:mb-0 text-md ">
                <label
                  className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-2"
                  htmlFor="grid-first-name"
                >
                  Level :
                </label>
                <div className="relative">
                  <label
                    className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2"
                    htmlFor="grid-first-name"
                  >
                    {levelGot}
                  </label>
                </div>
              </div>
            </div>
            <div className="mb-4 text-md pt-1">
              <div className="w-full px-3 mb-4 text-md ">
                <label
                  className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-2"
                  htmlFor="grid-last-name"
                >
                  Other Informations :
                </label>
                <label
                  className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2"
                  htmlFor="grid-last-name"
                >
                  {values.otherInfor_application}
                </label>
              </div>

              <div className="w-full text-md px-3 mb-4">
                <label
                  className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-2"
                  htmlFor="grid-password"
                >
                  University :
                </label>
                <label
                  className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2"
                  htmlFor="grid-password"
                >
                  {universityName}
                </label>
              </div>
            </div>
          </div>
          <div className="w-full px-1 mb-6 md:mb-0 text-md">
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2"
              htmlFor="grid-last-name"
            >
              Other Recommandations <span className="text-gray-600">*</span>
            </label>
            <textarea
              className="appearance-none block w-full dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] border border-gray-200 rounded py-3 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="otherConstantsSiteVisite"
              type="text"
              placeholder="Give the designation or others details"
              name="otherConstantsSiteVisite"
              value={values.otherConstantsSiteVisite}
              onChange={handleChange}
              onBlur={handleBlur}
              rows={4}
            ></textarea>
 
          </div>

          <div className="mt-2">
            <Tab
              tabs={tabs}
              submitButton={
                <div className="text-center p-5">
                  <button
                    {...(isSubmitting ? { disabled: true } : "")}
                    onClick={(event) => handleButtonClick("SubmitFinal", event)}
                    type="submit"
                    className="dark:bg-[var(--color-dark-buttonBackground)] 
                        bg-[var(--color-light-buttonBackground)] 
                        dark:text-[var(--color-dark-fontButton)] 
                        text-[var(--color-light-fontButton)]  
                        w-[200px]  py-3 rounded-md font-medium"
                  >
                    <span> {isSubmitting ? 'Submitting...' : 'Final Submission'} </span>
                  </button>
                </div>
              }
              
              partialSubmission={
                <div className="text-center p-5">
                  {/* <button
                    {...(isSubmitting ? { disabled: true } : "")}
                    onClick={(event) => handleButtonClick("SubmitPartial" ,event)}
                    type="submit"
                    className="dark:bg-[var(--color-dark-buttonBackgroundSpecial)] 
                        bg-[var(--color-light-buttonBackgroundSpecial)] 
                        dark:text-[var(--color-dark-fontButton)] 
                        text-[var(--color-light-fontButton)]  
                        w-[200px]  py-3 rounded-md font-medium"
                  >
                    <span> {isSubmitting ? 'Submitting...' : 'Partial submission'} </span>
                  </button> */}
                </div>
              }
            />
            
          </div>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { login })(SiteVisiteAdd);
