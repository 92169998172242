import InputNumberForScore from "../smallComponents/InputNumberForScore";

export const preliminaryAssessmentAssessorsReview = (handleInputChangeNumber, handleInputChangeTextComment) => ({
  point1: [
    {
      bigHeader: "Experts Comments",
      content: [
        {
          subData: [
            {
              number: ``,
              subtitleStandard: ``,
              subcontent: [
                {
                  subtitleNorm: ``,
                  dataContent: [
                    {
                      column4: (
                        <span className="font-bold text-black">Number</span>
                      ),
                      column5: (
                        <span className="font-bold text-black">
                          Designation
                        </span>
                      ),
                      column6: (
                        <span className="font-bold text-black">
                          Marks obtained
                        </span>
                      ),
                      column7: (
                        <span className="font-bold text-black">
                          Comments if there is any
                        </span>
                      ),
                    },
                    {
                      column4: `1.`,
                      column5: `3.1 AProgramme content:
                      Sufficient/adequate content
                      (coverage of content is
                      adequate) for the level of
                      award`,
                      column6: (
                        <>
                          <InputNumberForScore
                            maximum={10}
                            minimum={0}
                            onChangeNumber = {handleInputChangeNumber}
                            placeholder={
                              "Is the programme Content Sufficient ?"
                            }
                            name={"programmeContentSufficient"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentProgrammeContentSufficient"
                          cols={80}  // Increase the number of columns
                          rows={5}  
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%", 
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },

                    {
                      column4: `2.`,
                      column5: `3.2 Programme content
                      Admission criteria`,
                      column6: (
                        <>
                          <InputNumberForScore
                            maximum={10}
                            minimum={0}
                            onChangeNumber = {handleInputChangeNumber}
                            placeholder={
                              "Is the programme Admission criteria Sufficient ?"
                            }
                            name={"programmeContentAdmissionCriteriaSufficient"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentProgrammeContentAdmissionCriteriaSufficient"
                          cols={80}  // Increase the number of columns
                          rows={5} 
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%", 
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },

                    {
                      column4: `3.`,
                      column5: `3.3 Programme content
                      Structure of the Programme`,
                      column6: (
                        <>
                          <InputNumberForScore
                            maximum={25}
                            minimum={0}
                            onChangeNumber = {handleInputChangeNumber}
                            placeholder={
                              "Is the programme Structure Sufficient ?"
                            }
                            name={"programmeStructureSufficient"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentProgrammeStructureSufficient"
                          cols={80}  // Increase the number of columns
                          rows={5} 
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%", 
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },

                    {
                      column4: `4.`,
                      column5: `3.4 Programme content
                      Learning Outcomes`,
                      column6: (
                        <>
                          <InputNumberForScore
                            maximum={15}
                            minimum={0}
                            onChangeNumber = {handleInputChangeNumber}
                            placeholder={
                              "Is the programme Content Learning Outcome  Sufficient ?"
                            }
                            name={"programmeContentLearningOutcomeSufficient"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentProgrammeContentLearningOutcomeSufficient"
                          cols={80}  // Increase the number of columns
                          rows={5} 
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%", 
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },

                    {
                      column4: `5.`,
                      column5: `3.5 Programme content
                      Programme consistent to
                      EAQFHE`,
                      column6: (
                        <>
                          <InputNumberForScore
                            maximum={5}
                            minimum={0}
                            onChangeNumber = {handleInputChangeNumber}
                            placeholder={
                              "Is the programme Content Programme consistent to EAQFHE ?"
                            }
                            name={"programmeContentconsistentToEAQFHE"}
                          />
                        </>
                      ),
                      column7: (
                        <>
                          <textarea
                            name="commentProgrammeContentconsistentToEAQFHE"
                            cols={80}  // Increase the number of columns
                            rows={5} 
                            type="text"
                            style={{
                              width: "100%",
                              padding: "5px",
                              border: "1px solid #ccc",
                              borderRadius: "4px",
                            }}
                          ></textarea>{" "}
                          <a href="https://www.iucea.org/guidelines/#" target="_blanck" className="text-sm text-blue-800">click to see EAQFHE</a>
                        </>
                      ),
                    },

                    {
                      column4: `6.`,
                      column5: `3.6 Programme content
                      Credit Hours`,
                      column6: (
                        <>
                          <InputNumberForScore
                            maximum={10}
                            minimum={0}
                            onChangeNumber = {handleInputChangeNumber}
                            placeholder={
                              "Is the programme Content Credit Hoursufficient ?"
                            }
                            name={"programmeContentCreditHourSufficient"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentProgrammeContentCreditHourSufficient"
                          cols={80}  // Increase the number of columns
                          rows={5} 
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%", 
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },

                    {
                      column4: `7.`,
                      column5: `3.7 Programme content
                      Minimum duration`,
                      column6: (
                        <>
                          <InputNumberForScore
                            maximum={10}
                            minimum={0}
                            onChangeNumber = {handleInputChangeNumber}
                            placeholder={
                              "Is the programme Content Minimum Duration  Sufficient ?"
                            }
                            name={"programmeContentMinimumDurationSufficient"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentProgrammeContentMinimumDurationSufficient"
                          cols={80}  // Increase the number of columns
                          rows={5} 
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%", 
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },

                    {
                      column4: `8.`,
                      column5: `3.8 Programme content
                      Provision of RPL`,
                      column6: (
                        <>
                          <InputNumberForScore
                            maximum={10}
                            minimum={0}
                            onChangeNumber = {handleInputChangeNumber}
                            placeholder={
                              "Is the programme Content Provision of RPL Sufficient ?"
                            }
                            name={"programmeContentProvisionofRPLSufficient"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentProgrammeContentProvisionofRPLSufficient"
                          cols={80}  // Increase the number of columns
                          rows={5} 
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%", 
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },

                    {
                      column4: `9.`,
                      column5: `4.1 Mode of Teaching and
                      Learning:
                      Method of delivery is clearly
                      identified and appropriate`,
                      column6: (
                        <>
                          <InputNumberForScore
                            maximum={15}
                            minimum={0}
                            onChangeNumber = {handleInputChangeNumber}
                            placeholder={
                              "Is Method of delivery is clearly identified and appropriate ?"
                            }
                            name={"MethodOfDeliveryIsClearlyIdentifiedAndAppropriate"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentMethodOfDeliveryIsClearlyIdentifiedAndAppropriate"
                          cols={80}  // Increase the number of columns
                          rows={5} 
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%", 
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },

                    {
                      column4: `10.`,
                      column5: `4.2 Mode of Teaching and
                      Learning: Flexibility in mode of
                      learning`,
                      column6: (
                        <>
                          <InputNumberForScore
                            maximum={5}
                            minimum={0}
                            onChangeNumber = {handleInputChangeNumber}
                            placeholder={
                              "Flexibility in mode of learning?"
                            }
                            name={"flexibilityInModeOfLearning"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentFlexibilityInModeOfLearning"
                          cols={80}  // Increase the number of columns
                          rows={5} 
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%", 
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },

                    {
                      column4: `11.`,
                      column5: `4.3 Mode of Teaching and
                      Learning: Student centered
                      approaches of learning`,
                      column6: (
                        <>
                          <InputNumberForScore
                            maximum={15}
                            minimum={0}
                            onChangeNumber = {handleInputChangeNumber}
                            placeholder={
                              "Is the programme Content Student centered approaches of learning?"
                            }
                            name={"StudentCenteredApproachesOfLearning"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentStudentCenteredApproachesOfLearning"
                          cols={80}  // Increase the number of columns
                          rows={5} 
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%", 
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },

                    {
                      column4: `12.`,
                      column5: `4.4 Mode of Teaching and
                      Learning: Programme provided
                      for blended system`,
                      column6: (
                        <>
                          <InputNumberForScore
                            maximum={15}
                            minimum={0}
                            onChangeNumber = {handleInputChangeNumber}
                            placeholder={
                              "Is the Programme provided for blended system?"
                            }
                            name={"ProgrammeProvidedForBlendedSystem"}
                          />
                        </>
                      ),
                      column7: (
                        <textarea
                          name="commentProgrammeProvidedForBlendedSystem"
                          cols={80}  // Increase the number of columns
                          rows={5} 
                          type="text"
                          onChange={handleInputChangeTextComment}
                          style={{
                            width: "100%", 
                            padding: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        ></textarea>
                      ),
                    },
                  ], // data content tag
                },
              ], // sub content
            },
          ],
        },

        // End of part 2
      ],
    },
  ],
});
