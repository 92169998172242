import axios from "axios";

class UserService {

    async getAll(access, url){ 
        axios.defaults.headers.common["Authorization"] = "JWT "+ access;
        // axios.defaults.headers.common["X-Frame-Options"] = "sameorigin";
        axios.defaults.headers.common["Content-type"] = "multipart/form-data";
        axios.defaults.headers.common["Accept-Encoding"] = "gzip, deflate, br";
        const  result = await axios.get(`${url}`);
        return result;
    } 

    async getThematic(url){   
        const  result = await axios.get(`${url}`);
        return result;
    } 


    async getExpert(url){   
        const  result = await axios.get(`${url}`);
        return result;
    }  

    async getUniversity(){
        const apiAbelUnivUrl = `${process.env.REACT_APP_API_URL}/api/university/list/openApi`
        const  result = await axios.get(`${apiAbelUnivUrl}`);
        return result;
    }

    desableEnableUser(userid, access, email){ 
        axios.defaults.headers.common["Authorization"] = "JWT "+ access;
        axios.defaults.headers.common["Content-type"] = "multipart/form-data";
        axios.defaults.headers.common["Accept-Encoding"] = "gzip, deflate, br";
         console.log("OKAYY");
         console.log("userid ", userid);
         console.log("email ", email);
        return axios.put(`${process.env.REACT_APP_API_URL}/api/user/${userid}/delete/${email}`)
    }

    createUser(data){  
        axios.defaults.headers.common["Content-type"] = "application/json";
        axios.defaults.headers.common["Accept-Encoding"] = "gzip, deflate, br";  
        axios.defaults.headers.common["Accept"] = "*/*";   
        axios.defaults.headers.common["Authorization"] =  "";
        console.log("operating")
        console.log(data);
        return axios.post(`${process.env.REACT_APP_API_URL}/auth/users/`, data)
    }


    updateUser(id,data, access){ 
        axios.defaults.headers.common["Authorization"] = "JWT "+ access;
        axios.defaults.headers.common["Content-type"] = "multipart/form-data";
        axios.defaults.headers.common["Accept-Encoding"] = "gzip, deflate, br";
         
        return axios.put(`${process.env.REACT_APP_API_URL}/api/user/${id}/view`, data)
    }

    resetPassword(id,data, access){ 
        axios.defaults.headers.common["Authorization"] = "JWT "+ access;
        axios.defaults.headers.common["Content-type"] = "multipart/form-data";
        axios.defaults.headers.common["Accept-Encoding"] = "gzip, deflate, br";
         
        return axios.post(`${process.env.REACT_APP_API_URL}/users/reset_password/`, data)
    }

    deleteUserFunction(id, access){
        axios.defaults.headers.common["Authorization"] = "JWT "+ access;
        axios.defaults.headers.common["Content-type"] = "multipart/form-data";
        axios.defaults.headers.common["Accept-Encoding"] = "gzip, deflate, br";
        console.log("Values " +id);
        // return "OK";
        return axios.put(`${process.env.REACT_APP_API_URL}/api/user/${id}`);
    }
}

export default new UserService();