import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Success from "./alerts/Success";
import Error from "./alerts/Error";
import LineChartComponent from "./charts/LineChartComponent";
import ProgressChartComponent from "./charts/ProgressChartComponent";
import DonutChartComponent from "./charts/DonutChartComponent";
import CircularButton from "./charts/CircularButton";
import RadialButton from "./charts/RadialButton";
import TimeLineChart from "./charts/TimeLineChart";
import MonthLineChart from "./charts/MonthLineChart";
import DashboardService from "../services/DashboardService";
import { string } from "yup";
import DocumentRequirement from "./alerts/DocumentRequirement";

const Dashboard = () => {
  const history = useHistory();
  if (!(history.location.state == null)) {
    const data = history.location.state.data;
    console.log("Data send: ");
    console.log(data);
  }

  const [pendindApplication, setPendingApplication] = useState(0);
  const [doneApplication, setDoneApplication] = useState(0);
  const [inProgressApplication, setinProgressApplication] = useState(0);
  const [dataPerMonthGotDB, setdataPerMonthGotDB] = useState([]);
  const [dataPerMonthDateGotDB, setdataPerMonthDateGotDB] = useState([]);
  const [numberSiteVisitDone, setnumberSiteVisitDone] = useState(0);
  const [numberSiteVisitPending, setnumberSiteVisitPending] = useState(0);
  const [numberExpertReviewDone, setnumberExpertReviewDone] = useState(0);
  const [numberApplication, setnumberApplication] = useState(0);

  useEffect(() => {
    // Application data
    DashboardService.getAllApplication(localStorage.getItem("access"))
      .then((response) => {
        let result = response.data;
        setnumberApplication(result.length);
        let done = 0;
        let pending = 0;
        let inProgress = 0;
        let dataByMonthApplication = [];
        const dataPerMonth = [];
        const dataPerMonthDay = [];
        let newValueNumberSiteVisiteDone = 0;
        let newValueNumberSiteVisiteNotDone = 0;
        let newExpertReviewNumberSiteVisiteDone = 0;

        // Iterate through the result array
        result.forEach((data) => {
          newValueNumberSiteVisiteDone =
            newValueNumberSiteVisiteDone +
              data.resultSiteVisiteDone.numberSiteVisiteDone || 0;
          newValueNumberSiteVisiteNotDone =
            newValueNumberSiteVisiteNotDone +
              data.resultSiteVisiteDone.numberSiteVisitNotDone || 0;
          newExpertReviewNumberSiteVisiteDone =
            newExpertReviewNumberSiteVisiteDone +
              data.resultReviewerExpert.numberExpert || 0;
          let dateObject = new Date(data.date_created_application);
          let year = dateObject.getFullYear();
          let month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
          let date = (dateObject.getDate() + 1).toString().padStart(2, "0");
          let dateMonth = `${year}-${month}`;
          let dateMonthYear = `${year}-${month}-${date}`;

          if (
            Number(data.status_application) === 4 ||
            Number(data.status_application) === 3
          ) {
            done = done + 1;
          } else if (Number(data.status_application) === 0) {
            pending = pending + 1;
          } else if (
            Number(data.status_application) === 1 ||
            Number(data.status_application) === 2 ||
            Number(data.status_application) === 11
          ) {
            inProgress = inProgress + 1;
          }

          const existingItem = dataPerMonth.find(
            (item) => item.name === (dateMonth)
          );

          if (!existingItem) {
            dataPerMonth.push({ name: dateMonth, value: -1 });
          } else {
            existingItem.value += 1;
          }

          const existingItemPerDate = dataPerMonthDay.find(
            (item) => item.name === dateMonthYear
          );

          if (!existingItem) {
            dataPerMonthDay.push({ name: dateMonthYear, value: 1 });
          } else {
            existingItem.value += 1;
          }
        });

        // Update state or use counters as needed
        setnumberExpertReviewDone(newExpertReviewNumberSiteVisiteDone);
        setnumberSiteVisitDone(newValueNumberSiteVisiteDone);
        setnumberSiteVisitPending(newValueNumberSiteVisiteNotDone);
        setDoneApplication(done);
        setPendingApplication(pending);
        setinProgressApplication(inProgress);
        setdataPerMonthGotDB(dataPerMonth);
        setdataPerMonthDateGotDB(dataPerMonthDay);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 401) {
          // Redirect to the login page
          history.push('/login');
        }        
      })
      .finally(() => {
        console.log("finaly");
      });

    // Site visit data
    DashboardService.getSiteVisit(localStorage.getItem("access"))
      .then((response) => {
        let result = response.data;

        console.log("site visit result", result);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        console.log("finaly");
      });

    // Accreditation data
    DashboardService.getAccreditation(localStorage.getItem("access"))
      .then((response) => {
        let result = response.data;

        console.log("Accreditation result", result);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        console.log("finaly");
      });

    // Appeal Data
    DashboardService.getAppeal(localStorage.getItem("access"))
      .then((response) => {
        let result = response.data;

        console.log("Appeal data ", result);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        console.log("finaly");
      });

    // Chea Decision data
    DashboardService.getCHEADecision(localStorage.getItem("access"))
      .then((response) => {
        let result = response.data;

        console.log("CHEA Decision data ", result);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        console.log("finaly");
      });
  }, []);

  // first graph
  const dataPerMonth = [
    { name: "Jan", value: 12 },
    { name: "Feb", value: 19 },
    { name: "Mar", value: 3 },
    { name: "Apr", value: 5 },
    { name: "May", value: 2 },
  ];
  const designationSubmission = "Submission per month";
  // first graph

  // second Graphic

  const dataProgress = [
    { status: "InProgress", value: inProgressApplication },
    { status: "Completed", value: doneApplication },
    { status: "Pending", value: pendindApplication },
  ];

  const dataTimeBaseData = [
    { time: "2023-08-01", dataset1: 10, dataset2: 20, dataset3: 5 },
    { time: "2023-08-02", dataset1: 15, dataset2: 18, dataset3: 8 },
    { time: "2023-08-04", dataset1: 35, dataset2: 28, dataset3: 1 },
    // Add more data points as needed
  ];

  const dataMonthLyBased = [
    { month: "January", dataset1: 10, dataset2: 20, dataset3: 5 },
    { month: "February", dataset1: 15, dataset2: 18, dataset3: 8 },
    { month: "March", dataset1: 5, dataset2: 8, dataset3: 4 },
    { month: "April", dataset1: 51, dataset2: 4, dataset3: 33 },
    // Add more data points as needed
  ];
  let userInfo = JSON.parse(localStorage.getItem("userInfo"));
  let statusUser = false;
  let is_admin = false;
  if (userInfo) {
    statusUser = userInfo.is_staff;
    is_admin = userInfo.is_superuser;
  }

  let showAdmin = false;
  let showAssessors = false;
  let showApplicant = false;
  let showChEADecision = false;

  let Menus = [];
  if (statusUser && is_admin) {
    showAdmin = true;
    showAssessors = true;
    showApplicant = true;
  } else if (statusUser) {
    showAdmin = false;
    showAssessors = true;
    showApplicant = false;
  } else {
    showAdmin = false;
    showAssessors = false;
    showApplicant = true;
  }

  return (
    <div className="container">
      <section className="bg-white dark:bg-gray-900">
      {history?.location?.state === null ? (
        ""
      ) : history?.location?.state?.data?.status === 1 ? (
        <Success delay="5000" valueFromParent={history?.location?.state?.data} />
      ) : history?.location?.state?.data?.status === 0 ?(
        <Error delay="7000" valueFromParent={history?.location?.state?.data} />
      ) : <span></span>}

        <div className="py-3 px-1 mx-auto max-w-screen-xl lg:py-4">
          <div className="grid grid-cols-1 lg:grid-cols-2 sm:grid-cols-1 md:grid-cols-2 gap-4">
            <div
              className={`border p-4 ${
                showAdmin && showAssessors ? "" : "hidden"
              }`}
            >
              <span className="font-semibold text-lg">
                Application Made per month
              </span>
              <LineChartComponent
                data={dataPerMonthGotDB}
                designationSubmission={designationSubmission}
              />
            </div>
            <div className={`border p-4 ${showAdmin ? "" : "hidden"}`}>
              <span className="font-semibold text-lg">Status application</span>
              <ProgressChartComponent data={dataProgress} />
            </div>
            <div className={`border p-4 ${showAssessors ? "" : "hidden"}`}>
              <span className="font-semibold text-lg">Status application</span>
              <DonutChartComponent data={dataProgress} />
            </div>
            <div className={`border p-4 ${showAssessors ? "" : "hidden"}`}>
              <span className="font-semibold text-lg">Over view</span>
              <div className="flex justify-center mt-6">
                {" "}
                {/* Adjust the margin-top value */}
                <div className="grid grid-cols-2 gap-2">
                  <div className="flex flex-col items-center">
                    <div className="flex justify-center">
                      <CircularButton
                        value={numberApplication}
                        color="#8884d8"
                      />
                    </div>
                    <p className="text-center  font-semibold text-sm text-[#8884d8]">
                      Applications made
                    </p>
                  </div>

                  <div className="flex flex-col items-center">
                    <div className="flex justify-center">
                      <CircularButton
                        value={numberExpertReviewDone}
                        color="#3b5fba"
                      />
                    </div>
                    <p className="text-center text-[#3b5fba] font-semibold text-sm">
                      Expert reviews
                    </p>
                  </div>

                  <div className="flex flex-col items-center">
                    <div className="flex justify-center">
                      <CircularButton
                        value={numberSiteVisitPending}
                        color="#f61212"
                      />
                    </div>
                    <p className="text-center text-[#f61212] font-semibold text-sm">
                      Site Visits Pending
                    </p>
                  </div>
                  <div className="flex flex-col items-center">
                    <div className="flex justify-center">
                      <CircularButton
                        value={numberSiteVisitDone}
                        color="#54ba3b"
                      />
                    </div>
                    <p className="text-center text-[#54ba3b] font-semibold text-sm">
                      Site Visits Done
                    </p>
                  </div>

                  <div className=" justify-center hidden">
                    <RadialButton value={300} color="#8884d8" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`border p-4 ${"hidden"}`}>
            {/* <div className={`border p-4 ${showAdmin ? '' : 'hidden'}`}> */}
            <span className="font-semibold text-lg">
              Application, review ,site visit
            </span>
            <TimeLineChart data={dataTimeBaseData} />
          </div>
          <div className={`border p-4 ${"hidden"}`}>
            {/* <div className={`border p-4 ${showAdmin ? '' : 'hidden'}`}> */}
            <span className="font-semibold text-lg">
              Application, CHEA Decision, Accreditation
            </span>
            <MonthLineChart data={dataMonthLyBased} />
          </div>

          <div className={` ${showApplicant ? '' : 'hidden'} className="flex justify-center mt-4`}>
            <Link
              to="/application/create"
              className="inline-block py-3 px-6 text-base font-medium text-white rounded-lg bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-900"
            >
              Apply now
            </Link>
          </div>
          <div className={` ${showApplicant ? '' : 'hidden'} bg-white p-6 rounded-lg shadow-md`}>
            <h3 className="text-lg font-semibold text-red-600">
              Documents Needed
            </h3>
            <div className="mt-4 space-y-2">
              <DocumentRequirement
                title="Evidence for accreditation at national level"
                required
              />
              <DocumentRequirement
                title="Audit Report of the programme"
                required
              />
              <DocumentRequirement
                title="Confirmation letter from Stakeholders"
                required
              />

              <DocumentRequirement
                title="curriculum development and guidelines"
                required
              />

              <DocumentRequirement title="market survey report" required />

              <DocumentRequirement
                title="evidence of participation of stake Holders"
                required
              />

              <DocumentRequirement
                title="Curriculum/programme document"
                required
              />

              <DocumentRequirement title="evidence of payment" required />

              <DocumentRequirement
                title="Confirmation letter from the national agency"
                required
              />

              <DocumentRequirement title="Curriculum development" required />

              <DocumentRequirement title="Quality Assurance policy" required />
              <DocumentRequirement title="Summary of staff capacity" required />
              <DocumentRequirement title="Inventory of facilities" required />
              <DocumentRequirement title="Annual survey report" required />
              <DocumentRequirement title="Examination policy" required />
              <DocumentRequirement title="Admission Policy" required />
              <DocumentRequirement title="Program review report" required />
              <DocumentRequirement
                title="Credit transfer policy"
                required={false}
              />
              <DocumentRequirement
                title="Tracer study report"
                required={false}
              />
              <DocumentRequirement
                title="Postgraduate supervision guidelines"
                required={false}
              />
              <DocumentRequirement
                title="Involvement of the relevant national bodies"
                required={false}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Dashboard;
