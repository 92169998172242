import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import ApplicationService from "../../../services/ApplicationService";
import { connect } from "react-redux";
import { login } from "../../actions/auth";
import { ExpertReviewSchemasValidation } from "../../alerts/Validations";
import { useFormik, Field } from "formik";
import Success from "../../alerts/Success";
import Error from "../../alerts/Error";
import ApplicationFirstFeedback from "../../../services/ApplicationFirstFeedback";
import ExpertReviewService from "../../../services/ExpertReviewService";
import Loading from "../../eventsHandler/Loading";
import UserService from "../../../services/UserService";
import { preliminaryAssessmentAssessorsReview } from "../ConstantsAssessementsForExpertReview";
import { FaFilePdf } from "react-icons/fa";
import TabContent from "../../smallComponents/TabContent";
import Tab from "../../smallComponents/Tab";
import { preliminaryAssessmentAssessorsReviewLastDecion } from "../ConstantsAssessementsForExpertReviewLastDecision";
import SiteVisiteService from "../../../services/SiteVisiteService";
import { preliminaryAssessmentForDeskReviewVisualization } from "../ConstantsAssessementsForDeskReviewVisualisation";

const ExpertReviewView = (props) => {
  let editing = false;
  let initialTitle_program_application = "";
  let initialDesignation_application = "";
  let initialUniversity_id_application = "";
  let initialLevel_id_application = "";
  let initialOtherInfos = "";
  let initialevidence_for_accreditation_at_national_level = "";
  let initialconfirmation_letter_national_agency = "";
  let initialinvolvment_of_the_relevant_national_bodies = "";
  let initialcurriculum_development = "";
  let initialpostgraduate_supervision_guidelines = "";
  let initialquality_assurence_policy = "";
  let initialcredit_transfer_policy = "";
  let initialsummary_of_staff_capacity = "";
  let initialinventory_of_facilities = "";
  let initialannual_survey_report = "";
  let initialtracer_study_report = "";
  let initialexamination_policy = "";
  let initialadmission_policy = "";
  let initialprogram_review_report = "";
  let initialother_not_specified = "";
  let initialDocument2_application = "";
  let initialDocument3_application = "";
  let initialApplicationID = "";
  let typeOperation = "";
  let initialstatus_application = "";
  let initialstatus_thematicArea_application = "";
  let initialuser_created_application = "";
  let id_provenance = "";
  let initialStatusApplication = "";

  let numberOfStaffFeedbacks = [];
  let userSubmitterInfor = [];
  let userApplicantName = "";
  let application = [];
  let initialDataSendObject = "";
  let dataSent = '';
  let siteVisiteDone = 0;
  let siteVisiteNotDone = 0;

  console.log(props.location.state);
  if (props.location.state && props.location.state) {
    editing = true;
    typeOperation = props.location.typeOperation;
    initialstatus_application = props.location.state.status_application;
    initialApplicationID = props.location.state.id_application;
    initialevidence_for_accreditation_at_national_level =
      props.location.state.evidence_for_accreditation_at_national_level;
    initialconfirmation_letter_national_agency =
      props.location.state.confirmation_letter_national_agency;
    initialinvolvment_of_the_relevant_national_bodies =
      props.location.state.involvment_of_the_relevant_national_bodies;
    initialcurriculum_development = props.location.state.curriculum_development;
    initialpostgraduate_supervision_guidelines =
      props.location.state.postgraduate_supervision_guidelines;
    initialquality_assurence_policy =
      props.location.state.quality_assurence_policy;
    initialcredit_transfer_policy = props.location.state.credit_transfer_policy;
    initialsummary_of_staff_capacity =
      props.location.state.summary_of_staff_capacity;
    initialinventory_of_facilities =
      props.location.state.inventory_of_facilities;
    initialannual_survey_report = props.location.state.annual_survey_report;
    initialtracer_study_report = props.location.state.tracer_study_report;
    initialexamination_policy = props.location.state.examination_policy;
    initialadmission_policy = props.location.state.admission_policy;
    initialprogram_review_report = props.location.state.program_review_report;
    initialother_not_specified = props.location.state.other_not_specified;
    initialTitle_program_application =
      props.location.state.title_program_application;
    initialDesignation_application =
      props.location.state.designation_application;
    initialUniversity_id_application =
      props.location.state.university_id_application;
    initialLevel_id_application = props.location.state.level_id_application;

    initialOtherInfos = props.location.state.otherInfor_application;
    initialuser_created_application =
      props.location.state.user_created_application;
    numberOfStaffFeedbacks = props.location.state.expertReviewData;
    userSubmitterInfor = props.location.state.userApplicationEmail;
    userApplicantName = props.location.state.userApplicationUsername;
    id_provenance = props.location.state.id_application;
    initialStatusApplication = props.location.state.status_application;
    initialstatus_thematicArea_application =
      props.location.state.thematicArea_application;

    application = props.location.state;
    initialDataSendObject = props.location.state.expertReviewData;
    dataSent = props.location.state.commentsDeskReview;

   siteVisiteDone = props.location.state.resultSiteVisite.numberSiteVisiteDone;
   siteVisiteNotDone = props.location.state.resultSiteVisite.numberSiteVisitNotDone;
  }

  // const dataObject = JSON.parse(initialDataSendObject); 

  let numberSubmission = initialDataSendObject.length;

  let contentGot = [];
  let newDictionnaryExpertReview = {};
  let expertReviewIdAlreadyDone = [];
  let numberGotTotal = 0;

  if (initialDataSendObject) {
    initialDataSendObject.map((data) => {
      // console.log(data.id_siteVisite);
      expertReviewIdAlreadyDone.push(data.id_expertReview);
      let dataExpertReviewScore = JSON.parse(data.scoreFromExpert);
      Object.keys(dataExpertReviewScore).forEach((key) => {
        if (newDictionnaryExpertReview.hasOwnProperty(key)) {
          let newValue = Number(dataExpertReviewScore[key]);
          if (!isNaN(newValue)) {
            newDictionnaryExpertReview[key] += newValue;
            numberGotTotal = numberGotTotal + newValue;
          } else {
            newDictionnaryExpertReview[key] +=
              "\n *" + dataExpertReviewScore[key];
          }
        } else {
          // if it is a new value
          let newValue = Number(dataExpertReviewScore[key]);
          if (!isNaN(newValue)) {
            newDictionnaryExpertReview[key] = newValue;
            numberGotTotal = numberGotTotal + newValue;
          } else {
            newDictionnaryExpertReview[key] = "*" + dataExpertReviewScore[key];
          }
        }
      });
    });
  }

  let score = (numberGotTotal / (numberSubmission * 145)) * 100;
  // work on data submitted for the summary
  //

  const [level, setLevel] = useState(initialLevel_id_application);

  const [disableSaveBtn, setDisableSaveBtn] = useState(false);
  const [status, setStatus] = useState("");

  let levelData = JSON.parse(localStorage.getItem("levelData"));
  if (props.location.state.expertReviewData) {
  }
  const history = useHistory();
  const [statusCheckboxSelect, setStatusCheckboxSelect] = useState(false);
  const [expertList, setExpertList] = useState([]);
  const [expertListNewAffectation, setExpertListNewAffectation] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  if (!(history.location.state == null)) {
    const data = history.location.state.data;
  }

  const [expertId, setExpertId] = useState([]);
  const [expertIdNew, setExpertIdNew] = useState([]);
  const [dataOfAddedExpert, setDataOfAddedExpert] = useState([]);
  const [expertApplication, setExpertApplication] = useState("");
  const [thematic, setThematic] = useState([]);

  let urlUser = `${process.env.REACT_APP_API_URL}/api/user/${initialuser_created_application}/view`;

  let allUniveData = JSON.parse(localStorage.getItem("universityData"));
  let universityName = "";
  allUniveData.map((dataUniv) => {
    if (dataUniv.id === Number(initialUniversity_id_application)) {
      universityName = dataUniv.university_name;
    }
  });

  const onSubmit = (values, { resetForm }) => {
    setDisableSaveBtn(true);
    let userInfo = localStorage.getItem("userInfo");
    let userJson = JSON.parse(userInfo);

    const newApplication = new FormData();

    newApplication.append("type_firstFeedback", "End of first compulations");
    newApplication.append("constants_firstFeedback", values.theOverAllFeedBack);
    newApplication.append("status_firstFeedback", statusCheckboxSelect);
    newApplication.append("id_ApplicationfirstFeedback", id_provenance);
    ApplicationFirstFeedback.createFirstFeedback(
      newApplication,
      localStorage.getItem("access"),
      userSubmitterInfor,
      initialTitle_program_application,
      userApplicantName
    )
      .then((response) => {
        console.log("response: ", response.data);
        //  if validation
        if (statusCheckboxSelect) {
          const newSiteVisit = new FormData();
          newSiteVisit.append("constants_siteVisite ", "");
          newSiteVisit.append("status_siteVisite", 0);
          newSiteVisit.append("id_applicationSiteVisite", initialApplicationID)
          newSiteVisit.append(
            "id_ExpertReview_siteVisite",
            JSON.stringify(expertReviewIdAlreadyDone)
          );

          SiteVisiteService.create(newSiteVisit, localStorage.getItem("access"))
            .then((response) => {
              let idSiteVisite = response.data.id_siteVisite;

              // affect Site visite to new expert'
              console.log("expertIdNew: ", expertIdNew);
              expertIdNew.forEach((idExpert) => {
                const newSiteVisiteAffectation = new FormData();
                newSiteVisiteAffectation.append(
                  "id_applicationSiteVisitAffectationExpert",
                  initialApplicationID
                );
                newSiteVisiteAffectation.append(
                  "id_expertSiteVisitAffectationExpert",
                  idExpert
                );
                newSiteVisiteAffectation.append(
                  "id_siteVisitesiteVisitAffectationExpert",
                  idSiteVisite
                );

                let emailUser = "";
                let nameUser = "";
                expertList.map((userInfogot) => {
                  if (Number(userInfogot.id) === Number(idExpert)) {
                    emailUser = userInfogot.email;
                    nameUser = userInfogot.name;
                  }
                });

                SiteVisiteService.addExpertOnSiteVisite(
                  newSiteVisiteAffectation,
                  localStorage.getItem("access"),
                  emailUser,
                  nameUser,
                  universityName,
                  initialTitle_program_application
                )
                  .then((response) => {
                    console.log("response ", response);
                  })
                  .catch((error) => {
                    console.log("error ", error);
                  });
              });
              setStatus("Saved correctly...");
              let data = {
                status: 1,
                title: "Okay",
                message:
                  "Over All decision made with Success continue the next stage (Site visit)",
              };
              history.push("/application/list", { data: data });
              setDisableSaveBtn(false);
            })
            .catch((error) => {
              setStatus("Error Saving data");
              console.log("Error : ", error);
              let data = {
                status: 0,
                title: "Error",
                message: "An Error happened !",
              };
              history.push(`/application/list`, {
                data: data,
              });
              setDisableSaveBtn(false);
            })
            .finally(() => {
              setDisableSaveBtn(false);
            });
        }
      })
      .catch((err) => {
        console.log("errors creation siteVisite", err);
        setDisableSaveBtn(false);
      });

  };

  useEffect(() => {
    let url = `${process.env.REACT_APP_API_URL}/api/user/list/expert`;
    UserService.getExpert(url)
      .then((response) => {
        setExpertList(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 401) {
          // Redirect to the login page
          history.push('/login');
        }    
      });
  }, []);

  const {
    values,
    errors,
    touched,
    isSubmitting,
    setFieldValue,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      designation_application: "",
      title_program_application: initialTitle_program_application,
      university_id_application: initialUniversity_id_application,

      evidence_for_accreditation_at_national_level: "",
      confirmation_letter_national_agency: "",
      involvment_of_the_relevant_national_bodies: "",

      otherInfor_application: initialOtherInfos,
      level_id_application: initialLevel_id_application,

      otherInfor_application: initialOtherInfos,
      level_id_application: initialLevel_id_application,
      theOverAllFeedBack: "",
    },
    validationSchema: ExpertReviewSchemasValidation,
    onSubmit,
  });

  let levelGot = "";
  levelData.map((levelDB) => {
    if (levelDB.id_level === level) {
      levelGot = levelDB.designation_level;
    }
  });

  let listSelectedExpert = [];

  if (typeOperation !== "statusEdit") {
    if (expertList == null) {
      expertList.map((expert) => {
        listSelectedExpert.push(Number(expert.id_expert_applicationExpert));
      });
    }
  }

  const changeStatus = (status) => {
    setStatusCheckboxSelect(status.target.value);
  };

  useEffect(() => {
    let notificationListUrl = `${process.env.REACT_APP_API_URL}/api/applicationExpert/${initialApplicationID}/getStatusExpertSiteVisite`;
    ExpertReviewService.getAllStatusSiteVisite(
      localStorage.getItem("access"),
      notificationListUrl
    )
      .then((resNot) => {
        if (Number(resNot.data.statusApplication) === 1) {
          setButtonDisabled(true);
        }
      })
      .catch((errNot) => {
        console.log("errNot : ", errNot);
        if (errNot.response.status === 401) {
          // Redirect to the login page
          history.push('/login');
        }    
      });
  }, []);

  useEffect(() => {
    retrieveApplicationsExepert(initialApplicationID);
  }, [initialApplicationID]);

  const retrieveApplicationsExepert = (idApplication) => {
    setDataOfAddedExpert([]);
    dataOfAddedExpert.length = 0;
    var access = localStorage.getItem("access");
    const linkToData = `${process.env.REACT_APP_API_URL}/api/applicationSiteVisiteAffectationExpert/${idApplication}/applicationAffectation`;

    ApplicationService.getAllExpert(access, linkToData)
      .then((response) => {
        localStorage.setItem(
          "ExpertApplication",
          JSON.stringify(response.data)
        );
        setExpertApplication(response.data);
      })
      .catch((e) => {
        console.log("error : ", e);
        if (e.response.status === 401) {
          // Redirect to the login page
          history.push('/login');
        }    
      });
  };

  if (expertApplication.length > 0 && expertApplication != null) {
    expertApplication.forEach((expert) => {
      dataOfAddedExpert.push(
        Number(expert.id_expertSiteVisitAffectationExpert)
      );
    });
  }

  useEffect(() => {
    // getThematic
    let url = `${process.env.REACT_APP_API_URL}/api/user/list/expert`;
    UserService.getExpert(url)
      .then((response) => {
        setExpertList(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 401) {
          // Redirect to the login page
          history.push('/login');
        }    
      });
  }, []);

  function isInArray(value, array) {
    return array.indexOf(value) > -1;
  }

  function removeItemAll(arr, value) {
    var i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }

  const onAddingItem = (item) => {
    const isChecked = item.target.checked;
    let newValue = item.target.value;
    if (isChecked) {
      expertIdNew.push(newValue);
      expertIdNew.indexOf(newValue) === -1
        ? expertIdNew.push(newValue)
        : console.log("This item already exists");
    } else {
      removeItemAll(expertIdNew, newValue);
    }
  };

  let numberOfExpert = expertList.length;
  let hiddenExpert = 0;

  useEffect(() => {
    // getThematic
    let url = `${process.env.REACT_APP_API_URL}/api/thematicArea/`;
    UserService.getThematic(url)
      .then((response) => {
        setThematic(response.data);
      })
      .catch((errors) => {
        console.log("errors");
        console.log(errors);
        if (errors.response.status === 401) {
          // Redirect to the login page
          history.push('/login');
        }    
      });
  }, []);

  const tabs = [
    {
      title: "Document Submitted",
      content: (
        <div>
          <table
            className=" 
                     w-full 
                     tgray-700
                     text-left   
                     capitalize             
                     text-gray-700 
                     dark:text-gray-400"
          >
            <thead>
              <tr className="even:bg-gray-50 odd:bg-gray-100 even:text-black odd:text-gray-700">
                {application.auditReport_report ? (
                  <th scope="col">Audit report of the programme</th>
                ) : (
                  ""
                )}
                {application.confirmation_letter_of_key_stackHolder ? (
                  <th scope="col">confirmation letter from  stake Holder</th>
                ) : (
                  ""
                )}
                {application.curriculum_development_and_guidelines ? (
                  <th scope="col">curriculum development and guidelines</th>
                ) : (
                  ""
                )}
              </tr>
              <tr className="even:bg-gray-50 odd:bg-gray-100 even:text-black odd:text-black-100">
                {application.auditReport_report ? (
                  <td>
                    <a href={`${process.env.REACT_APP_API_URL}/documents/${application.auditReport_report} `}target="_blank">
                      {" "}
                      <FaFilePdf color="green" size={25} />
                    </a>
                  </td>
                ) : (
                  ""
                )}
                {application.confirmation_letter_of_key_stackHolder ? (
                  <td>
                    <a
                      href={`${process.env.REACT_APP_API_URL}/documents/${application.confirmation_letter_of_key_stackHolder}`} target="_blank"
                    >
                      {" "}
                      <FaFilePdf color="green" size={25} />
                    </a>
                  </td>
                ) : (
                  ""
                )}
                {application.curriculum_development_and_guidelines ? (
                  <td>
                    <a
                      href={`${process.env.REACT_APP_API_URL}/documents/${application.curriculum_development_and_guidelines}`} target="_blank"
                    >
                      {" "}
                      <FaFilePdf color="green" size={25} />
                    </a>
                  </td>
                ) : (
                  ""
                )}
              </tr>

              <tr className="even:bg-gray-50 odd:bg-gray-100 even:text-black odd:text-gray-700">
                {application.evidence_of_participation_of_stackHolder ? (
                  <th scope="col">evidence of participation of stack Holder</th>
                ) : (
                  ""
                )}
                {application.market_survey_report ? (
                  <th scope="col">market survey report</th>
                ) : (
                  ""
                )}
                {application.programme_file ? (
                  <th scope="col" className="text-green-600">
                    programme file
                  </th>
                ) : (
                  ""
                )}
              </tr>
              <tr className="even:bg-gray-50 odd:bg-gray-100 even:text-black odd:text-black-100">
                {application.evidence_of_participation_of_stackHolder ? (
                  <td>
                    <a
                      href={`${process.env.REACT_APP_API_URL}/documents/${application.evidence_of_participation_of_stackHolder
                      }`}
                      target="_blank"
                    >
                      {" "}
                      <FaFilePdf color="green" size={25} />
                    </a>
                  </td>
                ) : (
                  ""
                )}
                {application.market_survey_report ? (
                  <td>
                    <a href={`${process.env.REACT_APP_API_URL}/documents/${application.market_survey_report}`} target="_blank">
                      {" "}
                      <FaFilePdf color="green" size={25} />
                    </a>
                  </td>
                ) : (
                  ""
                )}
                {application.programme_file ? (
                  <td>
                    <a href={`${process.env.REACT_APP_API_URL}/documents/${application.programme_file}`} target="_blank">
                      {" "}
                      <FaFilePdf color="green" size={25} />
                    </a>
                  </td>
                ) : (
                  ""
                )}
              </tr>

              <tr className="even:bg-gray-50 odd:bg-gray-100  odd:text-gray-700">
                {application.other_not_specified ? (
                  <th className="" scope="col">
                    others not specified
                  </th>
                ) : (
                  ""
                )}
                {application.evidence_for_accreditation_at_national_level ? (
                  <th>evidence for accreditation at national level</th>
                ) : (
                  ""
                )}
                {application.confirmation_letter_national_agency ? (
                  <th>confirmation letter national agency</th>
                ) : (
                  ""
                )}
              </tr>
              <tr className="even:bg-gray-50 odd:bg-gray-100 even:text-black odd:text-gray-700">
                {application.other_not_specified ? (
                  <td className="">
                    <a href={`${process.env.REACT_APP_API_URL}/documents/${application.other_not_specified}`} target="_blank">
                      {" "}
                      <FaFilePdf color="green" size={25} />
                    </a>
                  </td>
                ) : (
                  ""
                )}
                {application.evidence_for_accreditation_at_national_level ? (
                  <td>
                    <a
                      href={
                        application.evidence_for_accreditation_at_national_level
                      }
                      target="_blank"
                    >
                      {" "}
                      <FaFilePdf color="green" size={25} />{" "}
                    </a>
                  </td>
                ) : (
                  ""
                )}
                {application.confirmation_letter_national_agency ? (
                  <td>
                    {" "}
                    <a
                      href={`${process.env.REACT_APP_API_URL}/documents/${application.confirmation_letter_national_agency}`}
                      target="_blank"
                    >
                      {" "}
                      <FaFilePdf color="green" size={25} />
                    </a>
                  </td>
                ) : (
                  ""
                )}
              </tr>
              <tr className="even:bg-gray-50 odd:bg-gray-100 even:text-black odd:text-gray-700">
                {application.involvment_of_the_relevant_national_bodies ? (
                  <th scope="col">
                    involvment of the relevant national bodies
                  </th>
                ) : (
                  ""
                )}
                {application.curriculum_development ? (
                  <th scope="col">curriculum development</th>
                ) : (
                  ""
                )}
                {application.postgraduate_supervision_guidelines ? (
                  <th scope="col">postgraduate supervision guidelines</th>
                ) : (
                  ""
                )}
              </tr>
              <tr className="even:bg-gray-50 odd:bg-gray-100 even:text-black odd:text-gray-700">
                {application.involvment_of_the_relevant_national_bodies ? (
                  <td>
                    <a
                      href={`${process.env.REACT_APP_API_URL}/documents/
                        ${application.document2_involvment_of_the_relevant_national_bodiesdetails
                      }`}
                      target="_blank"
                    >
                      {" "}
                      <FaFilePdf color="green" size={25} />
                    </a>
                  </td>
                ) : (
                  ""
                )}
                {application.curriculum_development ? (
                  <td>
                    <a
                      href={`${process.env.REACT_APP_API_URL}/documents/${application.curriculum_development}`}
                      target="_blank"
                    >
                      {" "}
                      <FaFilePdf color="green" size={25} />
                    </a>
                  </td>
                ) : (
                  ""
                )}
                {application.postgraduate_supervision_guidelines ? (
                  <td>
                    <a
                      href={`${process.env.REACT_APP_API_URL}/documents/${application.postgraduate_supervision_guidelines}`}
                      target="_blank"
                    >
                      {" "}
                      <FaFilePdf color="green" size={25} />
                    </a>
                  </td>
                ) : (
                  ""
                )}
              </tr>
              <tr className="even:bg-gray-50 odd:bg-gray-100 even:text-black odd:text-gray-700">
                {application.quality_assurence_policy ? (
                  <th scope="col">Quality Assurance policy</th>
                ) : (
                  ""
                )}
                {application.credit_transfer_policy ? (
                  <th scope="col">credit transfer policy</th>
                ) : (
                  ""
                )}
                {application.summary_of_staff_capacity ? (
                  <th scope="col">summary of staff capacity</th>
                ) : (
                  ""
                )}
              </tr>
              <tr className="even:bg-gray-50 odd:bg-gray-100 even:text-black odd:text-gray-700">
                {application.quality_assurence_policy ? (
                  <td>
                    <a
                      href={`${process.env.REACT_APP_API_URL}/documents/${application.quality_assurence_policy}`}
                      target="_blank"
                    >
                      {" "}
                      <FaFilePdf color="green" size={25} />
                    </a>
                  </td>
                ) : (
                  ""
                )}
                {application.credit_transfer_policy ? (
                  <td>
                    <a
                      href={`${process.env.REACT_APP_API_URL}/documents/${application.credit_transfer_policy}`}
                      target="_blank"
                    >
                      {" "}
                      <FaFilePdf color="green" size={25} />
                    </a>
                  </td>
                ) : (
                  ""
                )}
                {application.summary_of_staff_capacity ? (
                  <td>
                    <a
                      href={`${process.env.REACT_APP_API_URL}/documents/${application.summary_of_staff_capacity}`}
                      target="_blank"
                    >
                      {" "}
                      <FaFilePdf color="green" size={25} />
                    </a>
                  </td>
                ) : (
                  ""
                )}
              </tr>
              <tr className="even:bg-gray-50 odd:bg-gray-100 even:text-black odd:text-gray-700">
                {application.inventory_of_facilities ? (
                  <th scope="col">inventory of facilities</th>
                ) : (
                  ""
                )}
                {application.annual_survey_report ? (
                  <th scope="col">annual survey report</th>
                ) : (
                  ""
                )}
                {application.tracer_study_report ? (
                  <th scope="col">tracer study report</th>
                ) : (
                  ""
                )}
              </tr>
              <tr className="even:bg-gray-50 odd:bg-gray-100 even:text-black odd:text-gray-700">
                {application.inventory_of_facilities ? (
                  <td>
                    <a
                      href={`${process.env.REACT_APP_API_URL}/documents/${application.inventory_of_facilities}`}
                      target="_blank"
                    >
                      {" "}
                      <FaFilePdf color="green" size={25} />
                    </a>
                  </td>
                ) : (
                  ""
                )}
                {application.annual_survey_report ? (
                  <td>
                    <a href={`${process.env.REACT_APP_API_URL}/documents/${application.annual_survey_report}`} target="_blank">
                      {" "}
                      <FaFilePdf color="green" size={25} />
                    </a>
                  </td>
                ) : (
                  ""
                )}
                {application.tracer_study_report ? (
                  <td>
                    <a
                      href={`${process.env.REACT_APP_API_URL}/documents/${application.tracer_study_report}`}
                      target="_blank"
                    ></a>
                  </td>
                ) : (
                  ""
                )}
              </tr>
              <tr className="even:bg-gray-50 odd:bg-gray-100 even:text-black odd:text-gray-700">
                {application.examination_policy ? (
                  <th scope="col">examination policy</th>
                ) : (
                  ""
                )}
                {application.program_review_report ? (
                  <th scope="col">program review report</th>
                ) : (
                  ""
                )}
                {application.admission_policy ? (
                  <th scope="col">admission policy</th>
                ) : (
                  ""
                )}
              </tr>
              <tr className="even:bg-gray-50 odd:bg-gray-100 even:text-black odd:text-black-100">
                {application.examination_policy ? (
                  <td>
                    <a href={`${process.env.REACT_APP_API_URL}/documents/${application.examination_policy}`} target="_blank">
                      {" "}
                      <FaFilePdf color="green" size={25} />
                    </a>
                  </td>
                ) : (
                  ""
                )}
                {application.program_review_report ? (
                  <td>
                    <a href={`${process.env.REACT_APP_API_URL}/documents/${application.program_review_report}`} target="_blank">
                      {" "}
                      <FaFilePdf color="green" size={25} />
                    </a>
                  </td>
                ) : (
                  ""
                )}
                {application.admission_policy ? (
                  <td>
                    <a href={`${process.env.REACT_APP_API_URL}/documents/${application.admission_policy}`} target="_blank">
                      {" "}
                      <FaFilePdf color="green" size={25} />
                    </a>
                  </td>
                ) : (
                  ""
                )}
              </tr>
            </thead>
          </table>
        </div>
      ),
    },
    {
      title: preliminaryAssessmentAssessorsReviewLastDecion(
        newDictionnaryExpertReview,
        numberSubmission
      ).point1[0].bigHeader,
      content: (
        <div>
          <TabContent
            content={
              preliminaryAssessmentAssessorsReviewLastDecion(
                newDictionnaryExpertReview,
                numberSubmission
              ).point1[0]
            }
          />
        </div>
      ),
    },
    {
      title: preliminaryAssessmentForDeskReviewVisualization(
        JSON.parse(dataSent)
      ).point1[0].bigHeader,
      content: (
        <div>
          <TabContent
            content={
              preliminaryAssessmentForDeskReviewVisualization(
                JSON.parse(dataSent)
              ).point1[0]
            }
          />
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="w-full p-4">
        {disableSaveBtn && <Loading />}

        {typeOperation === "NextStape" ? (
          <div className="text-center font-bold">
            <h2>Activate Site Visit </h2>
          </div>
        ) : (
          ""
        )}
        {history.location.state == null ||
        history.location.state === undefined ? (
          ""
        ) : (
          <>
            {!(history.location.state.data == null) ? (
              <>
                {history.location.state.data.status === 1 ? (
                  <Success
                    delay="5000"
                    valueFromParent={history.location.state.data}
                  />
                ) : (
                  <Error
                    delay="7000"
                    valueFromParent={history.location.state.data}
                  />
                )}
              </>
            ) : (
              <></>
            )}
          </>
        )}
        <form onSubmit={handleSubmit} autoComplete="off">
          <div className="grid grid-cols-3">
            <div className="mb-4 text-md pt-4 grid grid-cols-1">
              <div className=" md:mb-0">
                <label
                  className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-2"
                  htmlFor="grid-first-name"
                >
                  Title of the Programme :
                </label>
                <label className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2">
                  {values.title_program_application}
                </label>
              </div>
              <div className=" md:mb-0 text-md ">
                <label
                  className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-2"
                  htmlFor="grid-first-name"
                >
                  Level :
                </label>
                <div className="relative">
                  <label
                    className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2"
                    htmlFor="grid-first-name"
                  >
                    {levelGot}
                  </label>
                </div>
              </div>
            </div>
            <div className="mb-4 text-md pt-4 grid grid-cols-1">
              <div className="w-full px-3 mb-4 text-md ">
                <label
                  className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-2"
                  htmlFor="grid-last-name"
                >
                  Other Informations :
                </label>
                <label
                  className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2"
                  htmlFor="grid-last-name"
                >
                  {values.otherInfor_application}
                </label>
              </div>

              <div className="w-full text-md px-3 mb-4">
                <label
                  className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-2"
                  htmlFor="grid-password"
                >
                  University :
                </label>
                <label
                  className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2"
                  htmlFor="grid-password"
                >
                  {universityName}
                </label>
              </div>
            </div>

            <div className="flex mb-4 md:mb-0 text-md pt-4">
              {
                <div className="capitalize">
                  <span className="text-md font-extrabold">
                    FeedBack on the appalication:
                  </span>{" "}
                  <hr />
                  {numberOfStaffFeedbacks.map((dataGot) => {
                    return (
                      <p>
                        {dataGot.username} :{" "}
                        <span
                          className={`${
                            Number(dataGot.status_expertReview) > 0
                              ? "text-green-600"
                              : "text-red-600"
                          }`}
                        >
                          {"submitted"}
                        </span>
                      </p>
                    );
                  })}
                </div>
              }
            </div>
          </div>

          {/*  new code for affectations */}
          <div className="w-full px-3 mb-4 text-md ">
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2"
              htmlFor="grid-last-name"
            >
              Add Experts for the site visit from the same discipline like the
              application:
            </label>

            <div
              className={`${
                expertList.length > 6
                  ? "grid grid-cols-4 gap-2"
                  : "grid grid-cols-6 gap-2"
              }`}
            >
              {expertList.map((expert) => {
                let status = false;
                let showOrHideExpert = "";
                if (isInArray(Number(expert.id), dataOfAddedExpert)) {
                  status = true;
                } else {
                  status = false;
                }
                let expertQualification = expert.thematicArea || "";

                if (
                  isInArray(
                    initialstatus_thematicArea_application,
                    expertQualification.split(",")
                  )
                ) {
                  showOrHideExpert = "";
                } else {
                  showOrHideExpert = "hidden";
                  hiddenExpert = hiddenExpert + 1;
                }

                if (hiddenExpert === numberOfExpert) {
                  return (
                    <span className="text-red-500">
                      {expertQualification} No expert found for that application
                      in: "{" "}
                      {thematic.map((area) =>
                        initialstatus_thematicArea_application ===
                        area.id_thematicsArea
                          ? area.designation_thematicsArea
                          : ""
                      )}
                      "
                    </span>
                  );
                } else {
                  return (
                    <div
                      key={expert.id}
                      className={`flex items-center mb-2 ${showOrHideExpert}`}
                    >
                      <input
                        id={`checkboxExpert_${expert.id}`}
                        type="checkbox"
                        value={`${expert.id}`}
                        name="checkboxExpert"
                        disabled={status}
                        onChange={(event) => onAddingItem(event)}
                        defaultChecked={status}
                        className="w-4 h-4 
                           text-blue-600 
                           bg-gray-100 
                           border-gray-300 
                           rounded 
                           focus:ring-blue-500 
                           dark:focus:ring-blue-600 
                           dark:ring-offset-gray-800 
                           focus:ring-2 
                           dark:bg-gray-700 
                           dark:border-gray-600"
                      />
                      <label
                        htmlFor={`checkboxExpert_${expert.id}`}
                        className="ml-2 
                           text-md 
                           font-medium 
                           dark:text-[var(--color-dark-font)] 
                           text-[var(--color-light-font)]"
                      >
                        {expert.name}{" "}
                        {status ? (
                          <span className="text-green-600">
                            {" "}
                            -Added already
                          </span>
                        ) : (
                          ""
                        )}
                      </label>
                    </div>
                  );
                }
              })}
            </div>
          </div>

          <div className="md:mb-0 text-md">
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2"
              htmlFor="grid-last-name"
            >
              Feedback on the application{" "}
              <span className="text-red-600">*</span>
            </label>

            <textarea
              
              className="appearance-none block w-full dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] border border-gray-200 rounded py-3 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="theOverAllFeedBack"
              type="text"
              placeholder="Feedback on the application"
              name="theOverAllFeedBack"
              value={values.theOverAllFeedBack}
              onChange={handleChange}
              onBlur={handleBlur}
              rows={4}
            ></textarea>
            <p className="text-red-500 text-md italic">
              {errors.theOverAllFeedBack}
            </p>
          </div>

          <>
            <div className="mt-2" onChange={(e) => changeStatus(e)}>
              <div className="mb-[0.125rem] mr-4 inline-block min-h-[1.5rem] pl-[1.5rem]">
                <input
                  className="relative 
                      float-left 
                      -ml-[1.5rem] 
                      mr-1 mt-0.5 
                      h-5 w-5  
                      rounded-full 
                      border-2 
                      border-solid 
                      border-blue-300 
                      before:pointer-events-none 
                      before:absolute 
                      before:h-4 
                      before:w-4 
                      before:scale-0 
                      before:rounded-full 
                      before:bg-transparent 
                      before:opacity-0 
                      before:shadow-[0px_0px_0px_13px_transparent] 
                      before:content-[''] 
                      after:absolute after:z-[1] 
                      after:block after:h-4 
                      after:w-4 after:rounded-full 
                      after:content-[''] 
                      checked:border-primary 
                      checked:before:opacity-[0.16] 
                      checked:after:absolute 
                      checked:after:left-1/2 
                      checked:after:top-1/2 
                      checked:after:h-[0.625rem] 
                      checked:after:w-[0.625rem] 
                      checked:after:rounded-full 
                      checked:after:border-primary 
                      checked:after:bg-primary 
                      checked:after:content-[''] 
                      checked:after:[transform:translate(-50%,-50%)] 
                      hover:cursor-pointer hover:before:opacity-[0.04] 
                      hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] 
                      focus:shadow-none 
                      focus:outline-none 
                      focus:ring-0 
                      focus:before:scale-100 
                      focus:before:opacity-[0.12] 
                      focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] 
                      focus:before:transition-[box-shadow_0.2s,transform_0.2s] 
                      checked:focus:border-primary 
                      checked:focus:before:scale-100 
                      checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] 
                      checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] 
                      dark:border-neutral-600 
                      dark:checked:border-primary 
                      dark:checked:after:border-primary 
                      dark:checked:after:bg-primary 
                      dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] 
                      dark:checked:focus:border-primary 
                      dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]"
                  type="radio"
                  name="statusCheckboxSelect"
                  id="inlineRadio1"
                  value={"0"}
                  checked={statusCheckboxSelect === "0"}
                />
                <label
                  className="mt-px inline-block pl-[0.15rem] hover:cursor-pointer"
                  htmlFor="inlineRadio1"
                >
                  <span className="text-red-600">Deferred</span>
                </label>
              </div>

              <div className="mb-[0.125rem] mr-4 inline-block min-h-[1.5rem] pl-[1.5rem]">
                <input
                  className="relative 
                  float-left 
                  -ml-[1.5rem] 
                  mr-1 mt-0.5 
                  h-5 w-5  
                  rounded-full 
                  border-2 
                  border-solid 
                  border-blue-300 
                  before:pointer-events-none 
                  before:absolute 
                  before:h-4 
                  before:w-4 
                  before:scale-0 
                  before:rounded-full 
                  before:bg-transparent 
                  before:opacity-0 
                  before:shadow-[0px_0px_0px_13px_transparent] 
                  before:content-[''] 
                  after:absolute after:z-[1] 
                  after:block after:h-4 
                  after:w-4 after:rounded-full 
                  after:content-[''] 
                  checked:border-primary 
                  checked:before:opacity-[0.16] 
                  checked:after:absolute 
                  checked:after:left-1/2 
                  checked:after:top-1/2 
                  checked:after:h-[0.625rem] 
                  checked:after:w-[0.625rem] 
                  checked:after:rounded-full 
                  checked:after:border-primary 
                  checked:after:bg-primary 
                  checked:after:content-[''] 
                  checked:after:[transform:translate(-50%,-50%)] 
                  hover:cursor-pointer hover:before:opacity-[0.04] 
                  hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] 
                  focus:shadow-none 
                  focus:outline-none 
                  focus:ring-0 
                  focus:before:scale-100 
                  focus:before:opacity-[0.12] 
                  focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] 
                  focus:before:transition-[box-shadow_0.2s,transform_0.2s] 
                  checked:focus:border-primary 
                  checked:focus:before:scale-100 
                  checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] 
                  checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] 
                  dark:border-blue-600 
                  dark:checked:border-primary 
                  dark:checked:after:border-primary 
                  dark:checked:after:bg-primary 
                  dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] 
                  dark:checked:focus:border-primary 
                  dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]"
                  type="radio"
                  name="statusCheckboxSelect"
                  id="inlineRadio2"
                  value={"1"}
                  checked={statusCheckboxSelect === "1"}
                />
                <label
                  className="mt-px inline-block pl-[0.15rem] hover:cursor-pointer"
                  htmlFor="inlineRadio2"
                >
                  <span className="text-green-600">Acceptable</span>
                </label>
              </div>
            </div>
          </>

          <div className="flex flex-wrap pt-4">
            <Tab tabs={tabs} submitButton={``} partialSubmission={``} />
            <div className="w-full sm:w-full md:w-full lg:w-full flex flex-col md:flex-row md:grid md:grid-cols-3 border border-gray-300 p-3">
              <span className="text-md font-semibold">
                Submission made: {numberSubmission}
              </span>
              <span className="text-md font-semibold">
                Score: {numberGotTotal} over {(numberSubmission * 145)}
              </span>
              <span
                className={`${
                  score > 70 ? "text-green-600" : "text-red-600"
                } text-md font-semibold`}
              >
                Percent: {(score).toFixed(2)}
              </span>
            </div>
          </div>

          <div className="text-center p-5">
            {Number(siteVisiteDone) === 0  && Number(siteVisiteDone) ===0 ? (
              <button
                {...(isSubmitting ? { disabled: true } : {})}
                type="submit"
                className="dark:bg-[var(--color-dark-buttonBackground)] 
                       bg-[var(--color-light-buttonBackground)] 
                       dark:text-[var(--color-dark-fontButton)] 
                       text-[var(--color-light-fontButton)]  
                       w-[250px]  py-3 rounded-md font-medium"
              >
                {" "}
                {typeOperation === "AddExpert" ? (
                  <span>Send Repport to the university</span>
                ) : (
                  "Send Repport to the university"
                )}
              </button>
            ) : (
              <span>Aleardy submitted</span>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { login })(ExpertReviewView);
