import React, { useState, useEffect, useRef } from "react"; 
import ApplicationService from "../../../services/ApplicationService";
import { connect } from "react-redux";
import { login } from "../../actions/auth"; 
import Success from "../../alerts/Success";
import Error from "../../alerts/Error";
import UserService from "../../../services/UserService";
import Loading from "../../eventsHandler/Loading"; 
import "./PrintcertificateCss.css";
import moment from "moment";
import cachet1 from "../../../images/cachet1.png";
import iucea from "../../../images/iucea.jpg";
import eac from "../../../images/eac.png";
import QRCode from "qrcode.react";
import { useHistory, useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min"; 
import printStyles from "./PrintStyles.css";
import QRCodeImage from "./QRCodeImage";
import UniversityService from "../../../services/UniversityService";
import AccreditationService from "../../../services/AccreditationService";

const AccreditationOpenView = (props) => {
  let editing = false;
  const history = useHistory();
  let initialTitle_program_application = "";
 
  const [titleProgram, setTitleProgram] = useState('');
  const [batchnumber, setbatchnumber]= useState('');
  const [date_created_accreditation,setdate_created_accreditation] = useState('');
  const [allUniveData, setAllUniveData] = useState([]);
  const[signatories, setSignatories] =useState([]);
  const [thematic, setThematic] = useState([]);
  const [universityId, setuniversityId] = useState([]);
  const [initialThematic, setInitialThematic] = useState('');
  const [disableSaveBtn, setdisableSaveBtn] = useState(false);

 
  const certificateRef = useRef(null);
  let id_accreditationGot = useParams();
  let id_accreditation = id_accreditationGot.id;
  console.log('id_accreditation ',id_accreditation)

  useEffect(() => {
    retrieveDataAccreditation(id_accreditation);
  }, [id_accreditation]);

  const retrieveDataAccreditation = (id_accreditation) => { 
    setdisableSaveBtn(true);
    var access = localStorage.getItem("access");
    const linkToData = `${process.env.REACT_APP_API_URL}/api/accreditationViewOpenApi/${id_accreditation}`;

    AccreditationService.getAll(access, linkToData)
      .then((response) => {
        //  console.log("response : ",response.data)
         setTitleProgram(response.data.resultsApplication.title_program_application);
         setuniversityId(response.data.resultsApplication.university_id_application);
         setbatchnumber(response.data.resultsApplication.batchNumber_Application);
        //  console.log(response.data.resultsApplication.university_id_application);
        setInitialThematic(response.data.resultsApplication.thematicArea_application);
        setdate_created_accreditation(response.data.date_created_accreditation);
        setSignatories(response.data.signatories);
         setdisableSaveBtn(false);
      })
      .catch((e) => {
        console.log("error : ", e);
        if (e.response.status === 401) {
          // Redirect to the login page
          history.push('/login');
        }    
      }).finally(() => {
        setdisableSaveBtn(false);
      });
  };
 
  
  useEffect(() => {
    let url = `${process.env.REACT_APP_API_URL}/api/university/list/openApi`;
    UniversityService.getOpenApi(url)
      .then((response) => { 
        setAllUniveData(response.data);
        console.log('response un ', response);
      })
      .catch((errors) => {
        console.log('response errors ', errors);
        if (errors.response.status === 401) {
          // Redirect to the login page
          history.push('/login');
        }    
      });
  }, []);

  
  let universityName = "";
  allUniveData.map((dataUniv) => {
    if (dataUniv.id === Number(universityId)) {
      universityName = dataUniv.university_name;
    }
  });
 
  useEffect(() => {
    // getThematic
    let url = `${process.env.REACT_APP_API_URL}/api/thematicArea/`;
    UserService.getThematic(url)
      .then((response) => {
        // console.log("Thematic ");
        // console.log(response.data);
        setThematic(response.data);
      })
      .catch((errors) => {
        console.log("errors");
        console.log(errors);
        if (errors.response.status === 401) {
          // Redirect to the login page
          history.push('/login');
        }    
      });
  }, []);
 
  
  const handlePrint = () => {
    const contentToPrint = certificateRef.current;
    if (contentToPrint) {
      const printWindow = window.open("", "_blank");
      printWindow.document.write(`
      <html>
      <head>
        <link rel="stylesheet" type="text/css"  href="${printStyles}">
      </head>
      <style>
      body {
        font-family: "Times New Roman", serif;
      }
      .qr-code {
        text-align: center;
        font-family: "Times New Roman", serif;
      }
      .certificate-details-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 10px;
        text-align: center; /* Center text within flex container */
        height: 100vh;
        font-family: "Times New Roman", serif;
      }
    
      .certificate-details-table {
        width: 80%;
        margin: 0 auto;
        text-align: left; 
        font-family: "Times New Roman", serif;
      }
    
      .certificate-details-table th,
      .certificate-details-table td {
        padding: 0px 0px;
        font-family: "Times New Roman", serif;
      }
    
      .certificate-details {
        display: flex;
        align-items: center;
        font-family: "Times New Roman", serif;
      }
    
      .certificate-details {
        font-size: 16px;
        margin-right: 10px;
        font-family: "Times New Roman", serif;
      }
    
      .print-certificate {
        width: 297mm;
        height: 210mm;
        padding: 20mm;
        margin: 0 auto;
        border: 1px solid #ccc;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        font-family: "Times New Roman", serif;
      }
    
      .print-button {
        display: block;
        margin: 20px auto;
        padding: 10px 20px;
        background-color: #0969da;
        color: #fff;
        border: none;
        cursor: pointer;
      }
    
      .certificate-content {
        width: 100%; /* Adjust width as needed */
        height: 87%; /* Adjust height as needed */
        padding-bottom: 0mm; 
        margin: 0 auto;
        outline: 1px double #ccc; /* Add a double border effect using outline */
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        text-align: center;
        position: relative;
        font-family: "Times New Roman", serif; 
      }
    
      .outer-border {
        border: 2px solid #000; /* Adjust border style as needed */
        padding: 4px; /* Adjust padding as needed */
      }
    
      .table-header {
        width: 100%;
        table-layout: fixed;
        text-align: center;
      }
              
      .mini-title-header {
        text-align: left;
        padding-left: 65%;
        padding-top: 0px;
        position: absolute;
        top: 41px;
        left: 20px;
        font-family: "Times New Roman", serif;
        font-size: 10px;
        font-weight: bold;
      }
      .certificate-title {
        font-size: 50px;
        font-weight: bold;  
        font-family: "Times New Roman", serif;
      }

      .second-title {
        margin-top: 2%;
        font-size: 30px;
        font-weight: bold;
        font-family: "Times New Roman", serif;
        /* text-align: "center"; */
      }
    
      .title-programme {
        font-size: 30px;
        font-weight: bold;
        font-family: "Times New Roman", serif;
      }
    
      .specialization {
        font-size: 20px;
        font-weight: bold;
        font-family: "Times New Roman", serif;
      }
    
      .universityName {
        font-size: 20px;
        font-weight: bold;
        font-family: "Times New Roman", serif;
      }
    
      .small-title {
        font-size: 20px;
        font-family: "Times New Roman", serif;
      }
    
      .footer-signature {
        font-size: 17px;
        font-weight: bold;
        font-family: "Times New Roman", serif;
        margin-top: 10px;
        margin-bottom: 0px;
      }
      .certificate-text {
        font-size: 18px;
        margin-bottom: 15px;
        font-family: "Times New Roman", serif;
      }
    
      .certificate-details {
        font-size: 16px;
        margin-bottom: 10px;
        font-family: "Times New Roman", serif;
      }
    
      .signature-table {
        width: 100%;
        table-layout: fixed; /* Prevent cells from adjusting width based on content */
      }
    
      .signature-cell {
        padding: 0 10px; /* Adjust padding as needed */
        text-align: left; /* Push content to the left */
      }
    
      .signature-box {
        display: flex;
        flex-direction: column;
        align-items: center; /* Center align content */
        margin-bottom: 14px;
      }
    
      .signature-image-container {
        flex: 0 0 auto; /* Ensure the image container doesn't grow */
      }
    
      .signature-img {
        width: 110px; /* Adjust image size as needed */
        height: 110px;
      }
    
      .signature-content {
        flex: 1; /* Let the content take remaining space */
        text-align: left; /* Push content to the left */
    
        font-size: 17px;
        font-weight: bold;
        font-family: "Times New Roman", serif;
      }
    
      .small-content {
        font-size: 16px;
        text-align: center;
      }
    
      /* logo */
      .logo-table {
        width: 100%;
        table-layout: fixed; /* Prevent cells from adjusting width based on content */
      }
    
      .logo-cell {
        padding: 0 0px; /* Adjust padding as needed */
        text-align: left; /* Push content to the left */
      }
    
      .logo-box {
        display: flex;
        flex-direction: column;
        align-items: center; /* Center align content */
        margin-bottom: 0px;
      }
    
      .logo-image-container {
        flex: 0 0 auto; /* Ensure the image container doesn't grow */
      }
    
      .logo-img1 {
        width: 105px; /* Adjust image size as needed */
        height: 105px;
      }
    
      .logo-content {
        flex: 1; /* Let the content take remaining space */
        text-align: left; /* Push content to the left */
      }
      </style>
      <body>
        ${contentToPrint.outerHTML}
      </body>
    </html>
      `);
      printWindow.document.close();
      printWindow.print();
    }
  };
  
 
  // get url
  const location = useLocation();
  const idToConvert = id_accreditation; // Replace with the actual ID you want to convert

  const qrCodeLink = `${window.location.origin}/accreditationOpenView/${idToConvert}`;

  const originalDate = moment(date_created_accreditation);
  const newDate = originalDate.add(5, "years");
  return (
    <div>
      <div className="w-full pt-4">
        {disableSaveBtn && <Loading />}
 
        <div>
          <button
            className="print-button"
            onClick={() => handlePrint()}
          >
            Print Certificate
          </button>
          <div ref={certificateRef}  className="outer-border">
            <div className="certificate-content">
            <table className="table-header">
                <thead>
                  <tr>
                    <td key={32}  style={{ textAlign: "left" }}>
                      <div className="">
                        <div className="">
                          <img src={eac} className="logo-img1" />
                        </div>
                      </div>
                    </td>{" "}
                    <td key={321} style={{ textAlign: "center" }}>
                      <div className="qr-code">
                        <QRCodeImage
                          id="qrCodeEl"
                          value={qrCodeLink}
                          size={100}
                          style={{ border: "1px solid red" }}
                        />
                      </div>
                    </td>
                    <td key={1221} style={{ textAlign: "right" }}>
                      <div className="">
                        <div className="">
                          <img src={iucea} className="logo-img1" />
                        </div>
                      </div>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colspan="3">
                      {" "}
                      <span className="certificate-title">
                        Inter University Council for East Africa
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3">
                      <p>
                        <u className="second-title">Certification</u>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3">
                      <p className="certificate-text">
                        Accreditation is awarded to the{" "}
                        {/* <span className="specialization">
                          With the specialization in 
                          &#8220;
                          {thematic.map((area) =>
                            initialstatus_thematicArea_application === area.id_thematicsArea
                              ? area.designation_thematicsArea
                              : ""
                          )}
                          &#8221;
                        </span> */}
                        {/* ’s degree in{" "} */}
                        <span className="title-programme">
                          {" "}
                          {titleProgram}
                        </span>{" "}
                        of <br />
                        <span className="universityName">
                          {universityName}
                        </span>{" "}
                        in compliance with
                        <br /> standards and Guidelines for, and requirements
                        for programme accreditation and in <br />
                        compliance with the requirements defined by the East
                        African Qualifications <br />
                        Framework for Higher Education (EAQFHE).
                        {/* The aconeditation is implemented according to the resolution <br />
                of the RPA's Commission of{" "}
                {moment(date_created_accreditation).format("Do MMMM YYYY")}
                . The acceditation is conditional and linated <br /> in time
                until {newDate.format("Do MMMM YYYY")} */}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className="certificate-details-table">
                <tbody>
                  <tr>
                    <td className="certificate-text" colSpan="2">
                      Accreditation status: FULL ACCREDITATION
                    </td>
                    <td className="certificate-text" colSpan="2">
                      Registration number: {batchnumber}
                    </td>
                  </tr>
                  <tr>
                    <td className="certificate-text" colSpan="2">
                      Validity status:{" "}
                      {moment(date_created_accreditation).format(
                        "Do MMMM YYYY"
                      )}{" "}
                      to {newDate.format("Do MMMM YYYY")}
                    </td>
                    <td className="certificate-text" colSpan="2">
                      Date of issue:{" "}
                      {moment(date_created_accreditation).format(
                        "Do MMMM YYYY"
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className="certificate-details-table">
                <tbody>
                  <tr>
                    <td>
                      <span className="footer-signature">
                        Uganda, Kampala <br />
                        {moment(date_created_accreditation).format(
                          "Do MMMM YYYY HH:mm"
                        )}
                      </span>
                    </td>
                    {signatories.map((dataSign, index) => (
                      <td key={index} className="signature-cell">
                        <div className="signature-box">
                          <div className="signature-image-container">
                            <img
                              src={`${process.env.REACT_APP_API_URL}/documents/${dataSign.signature_signatory}`}
                              alt={dataSign.academicTitle_signatory}
                              className="signature-img"
                            />
                          </div>
                          <div className="">
                            <p className="signature-content">
                              {dataSign.academicTitle_signatory}{" "}
                              {dataSign.fullName_signatory}
                            </p>
                            <p className="signature-content small-content">
                              {dataSign.title_signatory}
                            </p>
                          </div>
                        </div>
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { login })(AccreditationOpenView);
