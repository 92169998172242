import React from "react";
import QRCode from "react-qr-code";

const QRCodeImage = ({ value }) => {
  return (
    <div className="text-black">
      <QRCode value={value}   size={100}/>
    </div>
  );
};

export default QRCodeImage;
