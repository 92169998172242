import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import ApplicationService from "../../../services/ApplicationService";
import { connect } from "react-redux";
import { login } from "../../actions/auth";
import { useFormik, Field } from "formik";
import Success from "../../alerts/Success";
import Error from "../../alerts/Error";
import UserService from "../../../services/UserService";
import Loading from "../../eventsHandler/Loading";
import moment from "moment";
import TabContent from "../../smallComponents/TabContent";
import { preliminaryAssessmentDeskReview } from "../ConstantsAssessementsForDeskReview";
import Tab from "../../smallComponents/Tab";
import { preliminaryAssessmentForDeskReviewVisualization } from "../ConstantsAssessementsForDeskReviewVisualisation";

const ApplicationSecondReview = (props) => {
  let editing = false;
  let initialTitle_program_application = "";
  let initialDesignation_application = "";
  let initialUniversity_id_application = "";
  let initialLevel_id_application = "";
  let initialOtherInfos = "";
  let initialevidence_for_accreditation_at_national_level = "";
  let initalevidenceNationalLevelDate = "";

  let initialconfirmation_letter_national_agency = "";
  let initialinvolvment_of_the_relevant_national_bodies = "";
  let initialcurriculum_development = "";
  let initialpostgraduate_supervision_guidelines = "";
  let initialquality_assurence_policy = "";
  let initialcredit_transfer_policy = "";
  let initialsummary_of_staff_capacity = "";
  let initialinventory_of_facilities = "";
  let initialannual_survey_report = "";
  let initialtracer_study_report = "";
  let initialexamination_policy = "";
  let initialadmission_policy = "";
  let initialprogram_review_report = "";
  let initialother_not_specified = "";
  let initialDocument2_application = "";
  let initialDocument3_application = "";
  let initialApplicationID = "";
  let typeOperation = "";
  let initialstatus_application = "";
  let initialstatus_thematicArea_application = "";
  let initialuser_created_application = "";

  let initialprogramme_file = "";
  let initialevidence_of_payment = "";
  let initialmarket_survey_report = "";
  let initialevidence_of_participation_of_stackHolder = "";
  let initialcurriculum_development_and_guidelines = "";
  let initialconfirmation_letter_of_key_stackHolder = "";
  let initialauditReport_report = "";
  let dataSent = "";
  let emailSubmission = "";
  let userApplicationUsername = "";

  if (props.location.state && props.location.state.currentApplication) {
    editing = true;
    typeOperation = props.location.state.typeOperation;
    initialstatus_application =
      props.location.state.currentApplication.status_application;
    initialApplicationID =
      props.location.state.currentApplication.id_application;
    initialevidence_for_accreditation_at_national_level =
      props.location.state.currentApplication
        .evidence_for_accreditation_at_national_level;
    initalevidenceNationalLevelDate =
      props.location.state.currentApplication.evidenceNationalLevelDate;
    initialconfirmation_letter_national_agency =
      props.location.state.currentApplication
        .confirmation_letter_national_agency;
    initialinvolvment_of_the_relevant_national_bodies =
      props.location.state.currentApplication
        .involvment_of_the_relevant_national_bodies;
    initialcurriculum_development =
      props.location.state.currentApplication.curriculum_development;
    initialpostgraduate_supervision_guidelines =
      props.location.state.currentApplication
        .postgraduate_supervision_guidelines;
    initialquality_assurence_policy =
      props.location.state.currentApplication.quality_assurence_policy;
    initialcredit_transfer_policy =
      props.location.state.currentApplication.credit_transfer_policy;
    initialsummary_of_staff_capacity =
      props.location.state.currentApplication.summary_of_staff_capacity;
    initialinventory_of_facilities =
      props.location.state.currentApplication.inventory_of_facilities;
    initialannual_survey_report =
      props.location.state.currentApplication.annual_survey_report;
    initialtracer_study_report =
      props.location.state.currentApplication.tracer_study_report;
    initialexamination_policy =
      props.location.state.currentApplication.examination_policy;
    initialadmission_policy =
      props.location.state.currentApplication.admission_policy;
    initialprogram_review_report =
      props.location.state.currentApplication.program_review_report;
    initialother_not_specified =
      props.location.state.currentApplication.other_not_specified;
    initialTitle_program_application =
      props.location.state.currentApplication.title_program_application;
    initialDesignation_application =
      props.location.state.currentApplication.designation_application;
    initialUniversity_id_application =
      props.location.state.currentApplication.university_id_application;
    initialLevel_id_application =
      props.location.state.currentApplication.level_id_application;

    initialOtherInfos =
      props.location.state.currentApplication.otherInfor_application;
    initialuser_created_application =
      props.location.state.currentApplication.user_created_application;
    initialstatus_thematicArea_application =
      props.location.state.currentApplication.thematicArea_application;

    initialprogramme_file =
      props.location.state.currentApplication.programme_file;
    initialevidence_of_payment =
      props.location.state.currentApplication.evidence_of_payment;
    initialmarket_survey_report =
      props.location.state.currentApplication.market_survey_report;
    initialevidence_of_participation_of_stackHolder =
      props.location.state.currentApplication
        .evidence_of_participation_of_stackHolder;
    initialcurriculum_development_and_guidelines =
      props.location.state.currentApplication
        .curriculum_development_and_guidelines;
    initialconfirmation_letter_of_key_stackHolder =
      props.location.state.currentApplication
        .confirmation_letter_of_key_stackHolder;
    initialauditReport_report =
      props.location.state.currentApplication.auditReport_report;
    dataSent = props.location.state.currentApplication.commentsDeskReview;
    emailSubmission = props.location.state.currentApplication.userApplicationEmail;
    userApplicationUsername = props.location.state.currentApplication.userApplicationUsername;
  }
 

  const [level, setLevel] = useState(initialLevel_id_application);

  const [disableSaveBtn, setDisableSaveBtn] = useState(false);
  const [removeObjectionReview, setRemoveObjectionReview] = useState("");
  const [hiddenTextAreas, setHiddenTextAreas] = useState("hidden");

  const [valueRejection, setValueRejection] = useState(0);
  const [valueValidation, setValueValidation] = useState(1);

  const [status, setStatus] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [rejectionReason, setRejectionReason] = useState("");
  const [acceptanceReason, setAcceptanceReason] = useState("");

  let levelData = JSON.parse(localStorage.getItem("levelData"));

  const history = useHistory();
  const [successGot, setSuccessGot] = useState("");
  const [expertId, setExpertId] = useState([]);
  const [dataOfAddedExpert, setDataOfAddedExpert] = useState([]);
  const [expertApplication, setExpertApplication] = useState("");
  const [thematic, setThematic] = useState([]);
  const [expertList, setExpertList] = useState([]);

  if (!(history.location.state == null)) {
    const data = history.location.state.data;
    console.log("Data send: ");
    console.log(data);
  }

  function removeItemOnce(arr, value) {
    var index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  }

  function removeItemAll(arr, value) {
    var i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }

  const onAddingItem = (item) => {
    const isChecked = item.target.checked;
    let newValue = item.target.value;
    if (isChecked) {
      expertId.push(newValue);
      expertId.indexOf(newValue) === -1
        ? expertId.push(newValue)
        : console.log("This item already exists");
    } else {
      removeItemAll(expertId, newValue);
    }
    console.log("List: ", expertId);
  };

  useEffect(() => {
    retrieveApplicationsExepert(initialApplicationID);
  }, [initialApplicationID]);

  const retrieveApplicationsExepert = (idApplication) => {
    setDataOfAddedExpert([]);
    dataOfAddedExpert.length = 0;
    var access = localStorage.getItem("access");
    const linkToData = `${process.env.REACT_APP_API_URL}/api/applicationExpert/${idApplication}/application`;

    ApplicationService.getAllExpert(access, linkToData)
      .then((response) => {
        localStorage.setItem(
          "ExpertApplication",
          JSON.stringify(response.data)
        );
        setExpertApplication(response.data);
      })
      .catch((e) => {
        console.log("error : ", e);
        if (e.response.status === 401) {
          // Redirect to the login page
          history.push("/login");
        }
      });
  };

  if (expertApplication.length > 0 && expertApplication != null) {
    expertApplication.forEach((expert) => {
      dataOfAddedExpert.push(Number(expert.id_expert_applicationExpert));
    });
  }

  const [formData, setFormData] = useState({});

  // let urlUser = `${process.env.REACT_APP_API_URL}/api/user/${initialuser_created_application}/view`;

  const onSubmit = (values, { resetForm }) => {
    setDisableSaveBtn(true);
    let userInfo = localStorage.getItem("userInfo");
    let userCreatedApplication = JSON.parse(userInfo);
    let userJson = JSON.parse(userInfo);
    const newApplication = new FormData();

    if (typeOperation == "statusEdit") {
      // retrieve the applicant email 
      let currentTime = new Date();
      if (valueRejection > 0) {
        newApplication.append("userSecondReviewIUCEA", userJson.id);
        newApplication.append(
          "dateSecondReviewIUCEA",
          JSON.stringify(currentTime)
        );
        newApplication.append("comment_secondReview", rejectionReason);
        newApplication.append("status_secondReview", '2');
        newApplication.append("status_application", "11");
      } else if (valueValidation > 0) {
        newApplication.append("userSecondReviewIUCEA", userJson.id);
        newApplication.append(
          "dateSecondReviewIUCEA",
          JSON.stringify(currentTime)
        );
        newApplication.append("comment_secondReview", acceptanceReason);
        newApplication.append("status_secondReview", '1');
        newApplication.append("status_application", "11");

      }

      ApplicationService.editApplication(
        initialApplicationID,
        newApplication,
        localStorage.getItem("access"),
        emailSubmission,
        userApplicationUsername,
      )
        .then((response) => {
          setStatus("Saved correctly...");
          setSuccessGot("OKAY");
          console.log("Access: ");
          let data = {
            status: 1,
            title: "Okay",
            message: "Saved with Success ... Wait for feedback please!!!",
          };
          history.push("/application/list", { data: data });
        })
        .catch((error) => {
          setSuccessGot("Not");
          setStatus("Error Saving data");
          console.log("Error : ", error);
          if (error.response.status === 401) {
            // Redirect to the login page
            history.push("/login");
          }
          let data = {
            status: 0,
            title: "Error",
            message: "An Error happened !",
          };
          history.push(`/application/${initialApplicationID}/view`, {
            data: data,
          });
        });
    } else {
      console.log("expertId: ", expertId);
    }
  };

  const {
    values,
    errors,
    touched,
    isSubmitting,
    setFieldValue,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      designation_application: "",
      title_program_application: initialTitle_program_application,
      university_id_application: initialUniversity_id_application,

      evidence_for_accreditation_at_national_level: "",
      confirmation_letter_national_agency: "",
      involvment_of_the_relevant_national_bodies: "",

      otherInfor_application: initialOtherInfos,
      level_id_application: initialLevel_id_application,

      otherInfor_application: initialOtherInfos,
      level_id_application: initialLevel_id_application,
    },
    onSubmit,
  });

  let allUniveData = JSON.parse(localStorage.getItem("universityData"));
  let universityName = "no got";
  allUniveData.map((dataUniv) => {
    if (dataUniv.id === Number(initialUniversity_id_application)) {
      universityName = dataUniv.university_name;
    }
  });

  let levelGot = "";
  levelData.map((levelDB) => {
    if (levelDB.id_level === level) {
      levelGot = levelDB.designation_level;
    }
  });

  useEffect(() => {
    // getThematic
    let url = `${process.env.REACT_APP_API_URL}/api/user/list/expert`;
    UserService.getExpert(url)
      .then((response) => {
        console.log("response");
        console.log(response.data);
        setExpertList(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 401) {
          // Redirect to the login page
          history.push("/login");
        }
      });
  }, []);

  let listSelectedExpert = [];

  if (typeOperation !== "statusEdit") {
    if (expertList == null) {
      expertList.map((expert) => {
        listSelectedExpert.push(Number(expert.id_expert_applicationExpert));
      });
    }
  }

  function isInArray(value, array) {
    return array.indexOf(value) > -1;
  }

  const showInputTextArea = (event) => {
    if (event.target.checked) {
      setHiddenTextAreas("hidden");
    } else {
      setHiddenTextAreas("");
    }
  };

  const disableObjection = (event) => {
    if (event.target.checked) {
      setHiddenTextAreas("hidden");
      setRemoveObjectionReview(false);
      setValueRejection(0);
      setValueValidation(1);
      console.log("Okay");
    } else {
      setRemoveObjectionReview(true);
      console.log("Test");
      setHiddenTextAreas("");
      setValueRejection(1);
      setValueValidation(0);
    }
  };

  let numberOfExpert = expertList.length;
  let hiddenExpert = 0;

  useEffect(() => {
    // getThematic
    let url = `${process.env.REACT_APP_API_URL}/api/thematicArea/`;
    UserService.getThematic(url)
      .then((response) => {
        console.log("response");
        console.log(response.data);
        setThematic(response.data);
      })
      .catch((errors) => {
        console.log("errors");
        console.log(errors);
        if (errors.response.status === 401) {
          // Redirect to the login page
          history.push("/login");
        }
      });
  }, []);

  const tabs = [
    {
      title: "Document Submitted",
      content: (
        <div className="flex flex-wrap pt-4">
          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label
              className="block capitalize text-md tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
              htmlFor="grid-city"
            >
              Evidence for accreditation at national level
              <span className="text-red-600">*</span>
            </label>
            {initialevidence_for_accreditation_at_national_level ? (
              <a
                href={`${process.env.REACT_APP_API_URL}/documents/${initialevidence_for_accreditation_at_national_level}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>{" "}
                <span className="text-blue-600 font-semibold">
                  on{" "}
                  {moment(initalevidenceNationalLevelDate).format(
                    "Do MMMM YYYY HH:mm"
                  )}
                </span>
              </a>
            ) : (
              ""
            )}
          </div>
          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label
              className="block text-md capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
              htmlFor="grid-city"
            >
              Confirmation letter from the national agency
              <span className="text-red-600">*</span>
            </label>
            {initialconfirmation_letter_national_agency ? (
              <a
                href={`${process.env.REACT_APP_API_URL}/documents/${initialconfirmation_letter_national_agency}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>
              </a>
            ) : (
              ""
            )}
          </div>
          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md">
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
              htmlFor="grid-city"
            >
              Involvment of the relevant national bodies
              <span className="text-red-600">*</span>
            </label>
            {initialinvolvment_of_the_relevant_national_bodies ? (
              <a
                href={`${process.env.REACT_APP_API_URL}/documents/${initialinvolvment_of_the_relevant_national_bodies}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>
              </a>
            ) : (
              ""
            )}
          </div>

          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md pt-3">
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
              htmlFor="grid-city"
            >
              Curriculum development<span className="text-red-600">*</span>
            </label>
            {initialcurriculum_development ? (
              <a
                href={`${process.env.REACT_APP_API_URL}/documents/${initialcurriculum_development}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>
              </a>
            ) : (
              ""
            )}
          </div>
          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md pt-3 ">
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
              htmlFor="grid-city"
            >
              Postgraduate supervision guidelines
              <span className="text-red-600">*</span>
            </label>
            {initialpostgraduate_supervision_guidelines ? (
              <a
                href={`${process.env.REACT_APP_API_URL}/documents/${initialpostgraduate_supervision_guidelines}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>
              </a>
            ) : (
              ""
            )}
          </div>
          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md pt-3">
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
              htmlFor="grid-city"
            >
              Quality Assurance policy<span className="text-red-600">*</span>
            </label>
            {initialquality_assurence_policy ? (
              <a
                href={`${process.env.REACT_APP_API_URL}/documents/${initialquality_assurence_policy}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>
              </a>
            ) : (
              ""
            )}
          </div>

          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md pt-3 ">
            <label
              className="block capitalize text-md tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
              htmlFor="grid-city"
            >
              Credit transfer policy<span className="text-gray-600">*</span>
            </label>
            {initialcredit_transfer_policy ? (
              <a
                href={`${process.env.REACT_APP_API_URL}/documents/${initialcredit_transfer_policy}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>
              </a>
            ) : (
              ""
            )}
          </div>
          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md pt-3 ">
            <label
              className="block  capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
              htmlFor="grid-city"
            >
              Summary of staff capacity<span className="text-red-600">*</span>
            </label>
            {initialsummary_of_staff_capacity ? (
              <a
                href={`${process.env.REACT_APP_API_URL}/documents/${initialsummary_of_staff_capacity}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>
              </a>
            ) : (
              ""
            )}
          </div>
          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md pt-3 ">
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
              htmlFor="grid-city"
            >
              Inventory of facilities<span className="text-red-600">*</span>
            </label>
            {initialinventory_of_facilities ? (
              <a
                href={`${process.env.REACT_APP_API_URL}/documents/${initialinventory_of_facilities}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>
              </a>
            ) : (
              ""
            )}
          </div>

          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md pt-3 ">
            <label
              className="block capitalize text-md tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
              htmlFor="grid-city"
            >
              Annual survey report<span className="text-red-600">*</span>
            </label>
            {initialannual_survey_report ? (
              <a
                href={`${process.env.REACT_APP_API_URL}/documents/${initialannual_survey_report}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>
              </a>
            ) : (
              ""
            )}
          </div>
          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md pt-3 ">
            <label
              className="block text-md capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
              htmlFor="grid-city"
            >
              Tracer study report<span className="text-gray-600">*</span>
            </label>
            {initialtracer_study_report ? (
              <a
                href={`${process.env.REACT_APP_API_URL}/documents/${initialtracer_study_report}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>
              </a>
            ) : (
              ""
            )}
          </div>
          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md pt-3 ">
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
              htmlFor="grid-city"
            >
              Examination policy<span className="text-red-600">*</span>
            </label>
            {initialexamination_policy ? (
              <a
                href={`${process.env.REACT_APP_API_URL}/documents/${initialexamination_policy}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>
              </a>
            ) : (
              ""
            )}
          </div>

          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md pt-3">
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
              htmlFor="grid-city"
            >
              Admission Policy<span className="text-red-600">*</span>
            </label>
            {initialadmission_policy ? (
              <a
                href={`${process.env.REACT_APP_API_URL}/documents/${initialadmission_policy}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>
              </a>
            ) : (
              ""
            )}
          </div>
          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md pt-3 ">
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
              htmlFor="grid-city"
            >
              Program review report<span className="text-red-600">*</span>
            </label>
            {initialprogram_review_report ? (
              <a
                href={`${process.env.REACT_APP_API_URL}/documents/${initialprogram_review_report}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>
              </a>
            ) : (
              ""
            )}
          </div>
          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md pt-3 ">
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
              htmlFor="grid-city"
            >
              Other not specified<span className="text-gray-600">*</span>
            </label>
            {initialother_not_specified ? (
              <a
                href={`${process.env.REACT_APP_API_URL}/documents/${initialother_not_specified}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>
              </a>
            ) : (
              ""
            )}
          </div>

          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md pt-3 ">
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
              htmlFor="grid-city"
            >
              <span className="text-green-600">programme document</span>{" "}
              <span className="text-gray-600">*</span>
            </label>
            {initialprogramme_file ? (
              <a
                href={`${process.env.REACT_APP_API_URL}/documents/${initialprogramme_file}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span className="text-green-600">View</span>
              </a>
            ) : (
              ""
            )}
          </div>

          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md pt-3 ">
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
              htmlFor="grid-city"
            >
              <span className="text-blue-600">evidence of payment </span>{" "}
              <span className="text-gray-600">*</span>
            </label>
            {initialevidence_of_payment ? (
              <a
                href={`${process.env.REACT_APP_API_URL}/documents/${initialevidence_of_payment}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span className="text-blue-600">View</span>
              </a>
            ) : (
              ""
            )}
          </div>

          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md pt-3 ">
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
              htmlFor="grid-city"
            >
              audit Report of the programme
              <span className="text-gray-600">*</span>
            </label>
            {initialauditReport_report ? (
              <a
                href={`${process.env.REACT_APP_API_URL}/documents/${initialauditReport_report}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>
              </a>
            ) : (
              ""
            )}
          </div>

          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md pt-3 ">
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
              htmlFor="grid-city"
            >
              confirmation letter from stake Holder
              <span className="text-gray-600">*</span>
            </label>
            {initialconfirmation_letter_of_key_stackHolder ? (
              <a
                href={`${process.env.REACT_APP_API_URL}/documents/${initialconfirmation_letter_of_key_stackHolder}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>
              </a>
            ) : (
              ""
            )}
          </div>

          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md pt-3 ">
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
              htmlFor="grid-city"
            >
              curriculum development and guidelines
              <span className="text-gray-600">*</span>
            </label>
            {initialcurriculum_development_and_guidelines ? (
              <a
                href={`${process.env.REACT_APP_API_URL}/documents/${initialcurriculum_development_and_guidelines}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>
              </a>
            ) : (
              ""
            )}
          </div>

          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md pt-3 ">
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
              htmlFor="grid-city"
            >
              evidence of participation of stackHolder
              <span className="text-gray-600">*</span>
            </label>
            {initialevidence_of_participation_of_stackHolder ? (
              <a
                href={`${process.env.REACT_APP_API_URL}/documents/${initialevidence_of_participation_of_stackHolder}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>
              </a>
            ) : (
              ""
            )}
          </div>

          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 text-md pt-3 ">
            <label
              className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-0"
              htmlFor="grid-city"
            >
              market survey report<span className="text-gray-600">*</span>
            </label>
            {initialmarket_survey_report ? (
              <a
                href={`${process.env.REACT_APP_API_URL}/documents/${initialmarket_survey_report}`}
                target="_blank"
                className="
                                      hover:bg-gray-400 
                                        font-bold  
                                        py-2 px-4 
                                        rounded inline-flex 
                                        items-center"
              >
                <svg
                  className="fill-current w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>View</span>
              </a>
            ) : (
              ""
            )}
          </div>
        </div>
      ),
    },
    {
      title: preliminaryAssessmentForDeskReviewVisualization(
        JSON.parse(dataSent)
      ).point1[0].bigHeader,
      content: (
        <div>
          <TabContent
            content={
              preliminaryAssessmentForDeskReviewVisualization(
                JSON.parse(dataSent)
              ).point1[0]
            }
          />
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="w-full p-8">
        {disableSaveBtn && <Loading />}

        {history.location.state == null ||
        history.location.state == undefined ? (
          ""
        ) : (
          <>
            {!(history.location.state.data == null) ? (
              <>
                {history.location.state.data.status === 1 ? (
                  <Success
                    delay="5000"
                    valueFromParent={history.location.state.data}
                  />
                ) : (
                  <Error
                    delay="7000"
                    valueFromParent={history.location.state.data}
                  />
                )}
              </>
            ) : (
              <></>
            )}
          </>
        )}
        <form onSubmit={handleSubmit} autoComplete="off">
          <div className="grid grid-cols-3">
            <div className="mb-4 text-md pt-4 grid grid-cols-1">
              <div className=" md:mb-0">
                <label
                  className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-2"
                  htmlFor="grid-first-name"
                >
                  Title of the Programme :
                </label>
                <label className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2">
                  {values.title_program_application}
                </label>
              </div>
              <div className=" md:mb-0 text-md ">
                <label
                  className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-2"
                  htmlFor="grid-first-name"
                >
                  Level :
                </label>
                <div className="relative">
                  <label
                    className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2"
                    htmlFor="grid-first-name"
                  >
                    {levelGot}
                  </label>
                </div>
              </div>
            </div>
            <div className="mb-4 text-md pt-4 grid grid-cols-1">
              <div className="w-full px-3 mb-4 text-md ">
                <label
                  className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-2"
                  htmlFor="grid-last-name"
                >
                  Other Informations :
                </label>
                <label
                  className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2"
                  htmlFor="grid-last-name"
                >
                  {values.otherInfor_application}
                </label>
              </div>

              <div className="w-full text-md px-3 mb-4">
                <label
                  className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-light mb-2"
                  htmlFor="grid-password"
                >
                  University :
                </label>
                <label
                  className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2"
                  htmlFor="grid-password"
                >
                  {universityName}
                </label>
              </div>
            </div>

            <div className="flex mb-4 text-md pt-4">
              {typeOperation === "statusEdit" ? (
                <div className="capitalize">
                  <div className="w-full px-3 mb-4 text-md ">
                    <label
                      className="block capitalize 
                                          tracking-wide 
                                          dark:text-[var(--color-dark-font)] 
                                          text-[var(--color-light-font)] 
                                          text-md text-green-700 font-light mb-2"
                      htmlFor="grid-last-name"
                    >
                      Check for Acceptable/
                      <span className="text-red-600">
                        {" "}
                        Or Uncheck for Unacceptable :
                      </span>
                    </label>

                    <div className="flex items-center mb-2">
                      <input
                        id="checkboxExpert"
                        type="checkbox"
                        value={`1`}
                        name="checkboxExpert"
                        defaultChecked={true}
                        onChange={(e) => disableObjection(e)}
                        className="w-4 h-4 
                                                        text-blue-600 
                                                        bg-gray-100 
                                                        border-gray-300 
                                                        rounded 
                                                        focus:ring-blue-500 
                                                        dark:focus:ring-blue-600 
                                                        dark:ring-offset-gray-800 
                                                        focus:ring-2 
                                                        dark:bg-gray-700 
                                                        dark:border-gray-600"
                      />
                      <label
                        for="default-checkbox"
                        className="ml-2 
                                                            text-md 
                                                            font-medium 
                                                            dark:text-[var(--color-dark-font)] 
                                                            text-[var(--color-light-font)]"
                      >
                        {" "}
                        Acceptable for first review
                      </label>
                    </div>

                    <div className="w-full px-3 mb-4 text-md ">
                      <textarea
                        name="acceptanceReason"
                        id="acceptanceReason"
                        onChange={(e) => setAcceptanceReason(e.target.value)}
                        placeholder="Please provide a recommandation "
                        cols="30"
                        rows="3"
                      ></textarea>
                    </div>
                  </div>
                  <div
                    className={`rejectionReason w-full px-3 mb-4 text-md  ${hiddenTextAreas}`}
                  >
                    <label
                      className="block capitalize 
                                          tracking-wide 
                                          dark:text-[var(--color-dark-font)] 
                                          text-[var(--color-light-font)] 
                                          text-md text-red-700 font-light mb-2"
                      htmlFor="grid-last-name"
                    >
                      Unacceptable
                    </label>

                    <div className="flex items-center mb-2">
                      <input
                        id="rejection"
                        type="checkbox"
                        value={`1`}
                        checked={removeObjectionReview}
                        name="checkboxRejection"
                        onChange={(e) => showInputTextArea(e)}
                        className="w-4 h-4 
                                                        text-blue-600 
                                                        bg-gray-100 
                                                        border-gray-300 
                                                        rounded 
                                                        focus:ring-blue-500 
                                                        dark:focus:ring-blue-600 
                                                        dark:ring-offset-gray-800 
                                                        focus:ring-2 
                                                        dark:bg-gray-700 
                                                        dark:border-gray-600"
                      />
                      <label
                        for="default-checkbox"
                        className="ml-2 
                                                            text-md 
                                                            font-medium 
                                                            dark:text-[var(--color-dark-font)] 
                                                            text-[var(--color-light-font)]"
                      >
                        {" "}
                        Deferred
                      </label>
                    </div>

                    <div className="w-full px-3 mb-4 text-md ">
                      <textarea
                        className={`rejectionReason ${hiddenTextAreas}`}
                        name="rejectionReason"
                        id="rejectionReason"
                        onChange={(e) => setRejectionReason(e.target.value)}
                        placeholder="Please provide a recommandation"
                        cols="30"
                        rows="3"
                      ></textarea>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="w-full px-3 mb-4 text-md ">
                  <label
                    className="block capitalize tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2"
                    htmlFor="grid-last-name"
                  >
                    Add Experts :
                  </label>

                  {
                    expertList.map((expert) => {
                      let status = false;
                      let showOrHideExpert = "";
                      if (isInArray(Number(expert.id), dataOfAddedExpert)) {
                        status = true;
                      } else {
                        status = false;
                      }
                      let expertQualification = expert.thematicArea;

                      if (
                        isInArray(
                          initialstatus_thematicArea_application,
                          expertQualification.split(",")
                        )
                      ) {
                        showOrHideExpert = "";
                      } else {
                        showOrHideExpert = "hidden";
                        hiddenExpert = hiddenExpert + 1;
                      }

                      if (hiddenExpert === numberOfExpert) {
                        return (
                          <span className="text-red-500">
                            {expertQualification} No expert found for that
                            application in: "{" "}
                            {thematic.map((area) =>
                              initialstatus_thematicArea_application ===
                              area.id_thematicsArea
                                ? area.designation_thematicsArea
                                : ""
                            )}
                            "
                          </span>
                        );
                      } else {
                        return (
                          <div
                            className={`flex items-center mb-2 ${showOrHideExpert}`}
                          >
                            <input
                              id="checkboxExpert"
                              type="checkbox"
                              value={`${expert.id}`}
                              name="checkboxExpert"
                              disabled={status}
                              onChange={onAddingItem}
                              defaultChecked={status}
                              className="w-4 h-4 
                                                             text-blue-600 
                                                             bg-gray-100 
                                                             border-gray-300 
                                                             rounded 
                                                             focus:ring-blue-500 
                                                             dark:focus:ring-blue-600 
                                                             dark:ring-offset-gray-800 
                                                             focus:ring-2 
                                                             dark:bg-gray-700 
                                                             dark:border-gray-600"
                            />
                            <label
                              for="default-checkbox"
                              className="ml-2 
                                                                 text-md 
                                                                 font-medium 
                                                                 dark:text-[var(--color-dark-font)] 
                                                                 text-[var(--color-light-font)]"
                            >
                              {expert.name}{" "}
                              {status ? (
                                <span className="text-green-600">
                                  {" "}
                                  -Added already
                                </span>
                              ) : (
                                ""
                              )}
                            </label>
                          </div>
                        );
                      }
                    })
                    //   })
                  }
                </div>
              )}
            </div>
          </div>

          <Tab tabs={tabs} submitButton={``} partialSubmission={``} />

          <div className="text-center p-5">
            {Number(initialstatus_application) === 3 ? (
              <>
                <span className="text-red-600">Rejected !!</span>
              </>
            ) : (
              <>
                {" "}
                <button
                  type="submit"
                  className="dark:bg-[var(--color-dark-buttonBackground)] 
                        bg-[var(--color-light-buttonBackground)] 
                        dark:text-[var(--color-dark-fontButton)] 
                        text-[var(--color-light-fontButton)]  
                        w-[100px]  py-3 rounded-md font-medium"
                >
                  {" "}
                  {typeOperation == "AddExpert" ? (
                    <span>Add Experts</span>
                  ) : (
                    "Review"
                  )}
                </button>
              </>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { login })(ApplicationSecondReview);
