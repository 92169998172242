import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

const TimeLineChart = ({ data }) => {
  return ( 
      <LineChart width={600} height={300} data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="time"
          angle={-60}
          textAnchor="end"
          interval={0}
          tick={{ fontSize: 10 }}
        />
        <YAxis />
        <Tooltip />
        <Legend verticalAlign="top" height={36} />
        <Line type="monotone" dataKey="dataset1" stroke="#8884d8" />
        <Line type="monotone" dataKey="dataset2" stroke="#82ca9d" />
        <Line type="monotone" dataKey="dataset3" stroke="#ffc658" />
      </LineChart>
    
  );
};

export default TimeLineChart;
