import React, {useState, useEffect} from 'react' 
import { Link, Redirect, useHistory } from 'react-router-dom'
import UserService from '../../../services/UserService';
import moment from 'moment';
import { connect } from 'react-redux'; 
import { FaCheckSquare, FaTimes, FaUserCircle } from 'react-icons/fa';
import Success from '../../alerts/Success';
import Error from '../../alerts/Error';
import { reset_password, reset_password_confirm } from '../../actions/auth';
import FooterLoggedIn from '../../general/FooterLoggedIn';


const UserView = ({match, reset_password, reset_password_confirm}) => { 

  let editing = false;
  let initialName = "";
  let initialEmail = "";
  let initialPassword = "";
  let initialRepassword = "";
  let initialUniversity = 0;
  let initialIsStaff = false;
  let initialIsActive = false;


  console.log("isAuthenticated: ", localStorage.getItem("connected"));
  console.log("userConnected: ",  localStorage.getItem("userId"));
 
  const [email, setEmail] = useState(initialEmail);
  const [new_password, setNew_password] = useState(initialPassword);
  const [re_new_password, setRe_new_password] = useState(initialRepassword);
  const [is_active, setIs_active] = useState(initialIsActive);
  const [is_staff, setIs_staff] = useState(initialIsStaff);
  const [university, setUniversity] = useState(initialUniversity);
  const [name, setName] = useState(initialName);
  const [status, setStatus] = useState(null); 
  const [requestSent, setRequestSent] = useState(false);
  const history = useHistory();


  let univData = JSON.parse(localStorage.getItem('universityData')); 
  

  let userConnectInfo = localStorage.getItem('userInfo');
  let userConnected = JSON.parse(userConnectInfo);
  console.log("User");
 console.log(userConnected);

 const uid = match.params.uid;
 const token = match.params.token;
 console.log("uid");
 console.log(uid);

    const saveUser = () => {

        const newUser = new FormData(); 
        newUser.append('email',userConnected.email); 
        newUser.append('new_password',userConnected.email); 
        newUser.append('re_new_password',userConnected.email); 
       
                if (uid != null) {
                  reset_password_confirm(uid, token, new_password, re_new_password);
                  setRequestSent(true);
                }else{
                  reset_password(userConnected.email);  
                  setRequestSent(true);
                }
                 
    }
    
      if(requestSent){
        if (uid != null) {
          let data = {
            "status" : 1,
            "title" : "Okay",
            "message" : "Password changed successfully!"
            }
           history.push(`/login`, {data: data}); 
        }else{
          let data = {
            "status" : 1,
            "title" : "Half way",
            "message" : "An Email have been send, Check in box for reset your password!"
            }
           history.push(`/dashboard`, {data: data}); 
        }
      }


  return (
    <div >
      <div className="w-full p-8">
      {history?.location?.state === null ? (
        ""
      ) : history?.location?.state?.data?.status === 1 ? (
        <Success delay="5000" valueFromParent={history?.location?.state?.data} />
      ) : history?.location?.state?.data?.status === 0 ?(
        <Error delay="7000" valueFromParent={history?.location?.state?.data} />
      ) : <span></span>}
           <div className='grid grid-cols-2'>
               <div><div className="w-full px-3 mb-4 grid grid-cols-2">
                        <label className="block uppercase 
                        tracking-wide dark:text-[var(--color-dark-font)] 
                        text-[var(--color-light-font)] 
                        text-md font-bold mb-2" htmlFor="grid-password">
                        Name : 
                        </label>
                        <label className="block  
                          tracking-wide dark:text-[var(--color-dark-font)] 
                          text-[var(--color-light-font)] 
                          text-md font-bold mb-2" htmlFor="grid-password">
                          {userConnected.name}
                        </label>
                    </div> 

                    <div className="w-full px-3 mb-4 grid grid-cols-2">
                        <label className="block uppercase tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2" htmlFor="grid-password">
                        E-mail:
                        </label>
                        <label className="block  
                          tracking-wide dark:text-[var(--color-dark-font)] 
                          text-[var(--color-light-font)] 
                          text-md font-bold mb-2" htmlFor="grid-password">
                          {userConnected.email}
                        </label>
                  </div> 

                  
                    <div className="w-full px-3 mb-4 grid grid-cols-2">
                        <label className="block uppercase tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2" htmlFor="grid-first-name">
                            University:
                        </label> 
                        <label className="block  
                          tracking-wide dark:text-[var(--color-dark-font)] 
                          text-[var(--color-light-font)] 
                          text-md font-bold mb-2" htmlFor="grid-password">
                         
                          {univData.map((universityDB) => { 
                          
                                  if (universityDB.id === Number(userConnected.university)) {
                                    return <span>{universityDB.university_name}</span>
                                  }     
                          })}
                        </label>
                        
                     </div>
                    
               
 
                    <div className="w-full px-3 mb-4 grid grid-cols-2">
                        <label className="block uppercase tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2" htmlFor="grid-first-name">
                            Status:
                        </label>
                        <label className="block  
                          tracking-wide dark:text-[var(--color-dark-font)] 
                          text-[var(--color-light-font)] 
                          text-md font-bold mb-2" htmlFor="grid-password">
                          {userConnected.is_active ? <span className='grid grid-cols-2 text-green-700'>{`Yes`} <FaCheckSquare /></span> : <span className='grid grid-cols-2 text-red-700'>{`No`} <FaTimes /></span> }
                        </label>
                         
                    </div>
                     
                    <div className="w-full px-3 mb-4 grid grid-cols-2">
                        <label className="block uppercase tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2" htmlFor="grid-first-name">
                            Staff:
                        </label>
                        <label className="block  
                          tracking-wide dark:text-[var(--color-dark-font)] 
                          text-[var(--color-light-font)] 
                          text-md font-bold mb-2" htmlFor="grid-password">
                          {userConnected.is_staff ? <span className='grid grid-cols-2 text-green-700'>{`Yes`} <FaCheckSquare /></span> : <span className='grid grid-cols-2 text-red-700'>{`No`} <FaTimes /></span> }
                        </label>
                         
                    </div> 
                    <div className="w-full px-3 mb-4 grid grid-cols-2">
                        <label className="block uppercase tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2" htmlFor="grid-first-name">
                            Last Loggin:
                        </label>
                        <label className="block  
                          tracking-wide dark:text-[var(--color-dark-font)] 
                          text-[var(--color-light-font)] 
                          text-md font-bold mb-2" htmlFor="grid-password"> 
                          {moment(userConnected.last_login ).format(
                          "Do MMMM YYYY HH:mm:ss"
                        )}
                        </label>
                         
                    </div> 
               </div>
               <div className='block p-9'>
                      <FaUserCircle size={200} className='float-right'/>
               </div>
                   
           </div>
 

                    {uid == null ?
                   <div className="text-center pt-1 pb-4">
                                <button variant='info'
                                  className='dark:bg-[var(--color-dark-buttonBackground)] 
                                          bg-[var(--color-light-buttonBackground)] 
                                          dark:text-[var(--color-dark-fontButton)] 
                                          text-[var(--color-light-fontButton)] 
                                          w-[200px]  py-3 rounded-md font-medium'
                                  onClick={() => {saveUser()}} > {editing ? "Edit" : "Change "} Password?</button>
                          </div>  :
                                
                            <div className='border border-gray-500'>
                            <div className="w-full px-3 mb-4">
                                <label className="block uppercase tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2" htmlFor="grid-password">
                                Password
                                </label>
                                <input className="appearance-none block w-full dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                                    id="grid-password" 
                                    type="password" 
                                    value={new_password}
                                    onChange={(event) => {setNew_password(event.target.value)}}
                                    placeholder="Password" /> 
                                <p className="text-red-500 text-md italic">Please fill out this field.</p>
                            </div> 

                          
                            <div className="w-full px-3 mb-4">
                                <label className="block uppercase tracking-wide dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] text-md font-bold mb-2" htmlFor="grid-password">
                                Retape Password
                                </label>
                                <input className="appearance-none block w-full dark:bg-[var(--color-dark-backgroundMain)] bg-[var(--color-light-backgroundMain)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)] border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                                    id="grid-password" 
                                    type="password" 
                                    value={re_new_password}
                                    onChange={(event) => {setRe_new_password(event.target.value)}}
                                    placeholder="Retape Password" /> 
                                <p className="text-red-500 text-md italic">Please fill out this field.</p>
                            </div>

                            <div className="text-center pt-1 pb-4">
                              <button variant='info'
                                className='dark:bg-[var(--color-dark-buttonBackground)] 
                                        bg-[var(--color-light-buttonBackground)] 
                                        dark:text-[var(--color-dark-fontButton)] 
                                        text-[var(--color-light-fontButton)] 
                                        w-[200px]  py-3 rounded-md font-medium'
                                onClick={() => {saveUser()}} > {editing ? "Edit" : "Reset "} Password</button>
                        </div>  
                </div> 
             
                   }
                     
            </div>
                {uid == null ? "" : <FooterLoggedIn />}  
      </div>
  )
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    userConnected: state.auth.user,
  });
  

export default connect(mapStateToProps, {reset_password, reset_password_confirm}) (UserView) ;