import React, { useState } from "react";

const InputNumberForScore = (props) => {

  const [value, setValue] = useState("");
 

  const handleChange = (event) => {
    const newValue = event.target.value;

    // Check if the new value is a valid number using the isNaN function
    if (!isNaN(newValue)) {
      // Convert the value to a number
      const numericValue = Number(newValue);

      // Check if the numericValue is less than or equal to the maximum value
      if (numericValue <= props.maximum) {
        // Update the state with the new value
        setValue(newValue);

        // Call the onChangeNumber prop to handle the number change
        props.onChangeNumber(event.target.name,newValue); // Pass the event object to the parent component's function
      } else {
        // If the numericValue is greater than the maximum value, set it to the maximum
        setValue(props.maximum.toString());

        // Call the onChangeNumber prop with the maximum value
        props.onChangeNumber(event.target.name,props.maximum.toString()); // Pass the event object to the parent component's function
      }
    } else {
      // If the new value is not a valid number, set the value to an empty string
      setValue("");

      // Call the onChangeNumber prop with null or some other value to handle the invalid input
      props.onChangeNumber(event.target.name,'');// Pass the event object to the parent component's function
    }
  };
   
  const handleKeyDown = (event) => {
    // Allow only valid number inputs and the backspace key
    if (
      !(
        event.key === "Backspace" ||
        /^[0-9]+$/.test(event.key) // Check if the pressed key is a valid number
      )
    ) {
      event.preventDefault();
    }
  };
  return (
    <div className="">
      <div className="mb-[0.125rem] mr-4 inline-block min-h-[1.5rem] pl-[1.5rem]">
        <input
          type="number"
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          value={value}
          max={props.maximum}
          min={props.minimum}
          name={props.name}
          placeholder={`Marks :${props.maximum}`}
          id={props.name}
          style={{
            width: "150px", // Set the width as per your preference
            height: "30px", // Set the height as per your preference
            padding: "5px", // Set the padding as per your preference
            border: "1px solid #ccc", // Add a border to make the input visible
            borderRadius: "4px", // Add some border radius for a rounded look
          }}
        />
        <br />
        <label className="text-gray-700 font-light text-xs">{props.placeholder}</label>
      </div>
    </div>
  );
};

export default InputNumberForScore;
