import React, { useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";

function SignatureCapture(props) {
  const signatureRef = useRef(null);
  const [capturedData, setCapturedData] = useState(null);

  const emitDataToParent = () => {
    if (signatureRef.current.isEmpty()) {
      alert("Please provide a signature.");
    } else {
      const signatureData = signatureRef.current.toDataURL(); 
      const blob = dataURLtoBlob(signatureData); 
      const file = new File([blob], "signatureExpertReview.png", { type: "image/png" }); 
      props.onDataCapture(file);
      alert('saved')
    }
  };

  function dataURLtoBlob(dataURL) {
    const parts = dataURL.split(';base64,');
    const contentType = parts[0].split(':')[1];
    const raw = window.atob(parts[1]);
    const array = new Uint8Array(new ArrayBuffer(raw.length));
    for (let i = 0; i < raw.length; i++) {
      array[i] = raw.charCodeAt(i);
    }
    return new Blob([array], { type: contentType });
  }
  

  const handleClear = () => {
    signatureRef.current.clear();
    setCapturedData(null);
  };

  return (
    <div>
      <div
        className="text-center"
        style={{
          border: "2px solid black",
          width: "200px",
          height: "100px",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <SignatureCanvas
          ref={signatureRef}
          penColor="black"
          canvasProps={{ width: 200, height: 100 }}
        />
      </div>
      <br />
      <span
        className="cursor-pointer font-semibold text-red-600"
        onClick={handleClear}
      >
        Clear Signature{" "}
      </span>
      <span
        className="cursor-pointer font-semibold text-blue-600"
        onClick={emitDataToParent}
      >
        {" "}
        Save Signature
      </span>
    </div>
  );
}

export default SignatureCapture;
