import axios from "axios";


class LevelService{

    async getAll(access, url){ 
        axios.defaults.headers.common["Authorization"] = "JWT "+ access;
        // axios.defaults.headers.common["X-Frame-Options"] = "sameorigin";
        axios.defaults.headers.common["Content-type"] = "multipart/form-data";
        axios.defaults.headers.common["Accept-Encoding"] = "gzip, deflate, br";
        const  result = await axios.get(`${url}`);
        return result;
    } 

    createlevel(data, access) {
        axios.defaults.headers.common["Authorization"] = "JWT " + access;
        axios.defaults.headers.common["Content-type"] = "multipart/form-data";
        axios.defaults.headers.common["Accept-Encoding"] = "gzip, deflate, br";
      // console.log("userEmail SEr",userEmail);
        return axios.post(
          `${process.env.REACT_APP_API_URL}/api/level/create`,
          data
        );
      }

    getSmallData(access) {
      axios.defaults.headers.common["Authorization"] = "JWT " + access;
      axios.defaults.headers.common["Content-type"] = "multipart/form-data";
      axios.defaults.headers.common["Accept-Encoding"] = "gzip, deflate, br";
 
      return axios.get(
        `${process.env.REACT_APP_API_URL}/api/getSmallComponent/list`, 
      );
    } 
 
    getCountries(access) {
      axios.defaults.headers.common["Authorization"] = "JWT " + access;
      axios.defaults.headers.common["Content-type"] = "multipart/form-data";
      axios.defaults.headers.common["Accept-Encoding"] = "gzip, deflate, br";
 
      return axios.get(
        `${process.env.REACT_APP_API_URL}/api/getListCountries/list`, 
      );
    } 

    updatelevel(id,data, access){ 
        axios.defaults.headers.common["Authorization"] = "JWT "+ access;
        axios.defaults.headers.common["Content-type"] = "multipart/form-data";
        axios.defaults.headers.common["Accept-Encoding"] = "gzip, deflate, br";
         
        return axios.put(`${process.env.REACT_APP_API_URL}/api/level/${id}`, data)
    } 

    deletelevel(id, access){
        axios.defaults.headers.common["Authorization"] = "JWT "+ access;
        axios.defaults.headers.common["Content-type"] = "multipart/form-data";
        axios.defaults.headers.common["Accept-Encoding"] = "gzip, deflate, br";
       
        return axios.put(`${process.env.REACT_APP_API_URL}/api/level/${id}/delete`);
    }
}
export default new LevelService()