import React, { useEffect, useState, Fragment } from "react";
import NavbarLoggedIn from "../NavbarLoggedIn";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { checkAuthenticated, load_user } from "../actions/auth";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";

import { FaChevronCircleDown } from "react-icons/fa";
import FooterLoggedIn from "../general/FooterLoggedIn";
import { Redirect } from "react-router";
import { logout } from "../actions/auth";
import {
  MenusAdmin,
  MenusApplicants,
  MenusAssessors,
  MenusIUCEADesk,
} from "./MenuConstants";
import "./Layout.css";

const Layout = ({
  checkAuthenticated,
  isAuthenticated,
  load_user,
  children,
}) => {
  const [open, setOpen] = useState(true);
  const [toggle, setToggle] = useState([]);
  const [submenuOpen, setSubmenuOpen] = useState([]);
  const [clickedMenu, setClickedMenu] = useState("");

  // set info in local storage

  // get info from localstorage
  let userInfo = JSON.parse(localStorage.getItem("userInfo"));
  let statusUser = false;
  let is_admin = false;
  if (userInfo) {
    statusUser = userInfo.is_staff;
    is_admin = userInfo.is_superuser;
  }

  useEffect(() => {
    checkAuthenticated();
    load_user();
  }, []);

  if (!isAuthenticated) {
    console.log("Bad not connected layout.js");
  } else {
    console.log("Good connected layout.js ");
  }

  let Menus = [];
  if (statusUser && is_admin) {
    Menus = MenusAdmin;
  } else if (statusUser) {
    Menus = MenusAssessors;
  } else {
    Menus = MenusApplicants;
  }

  let size = useWindowWide(200);

  const reduceMenu = (value = "") => {
    if (value === "clicked") {
      setOpen(!open);
    } else {
      if (size <= 620) {
        setOpen(false);
      } else {
        setOpen(false);
      }
    }
  };

  const clickHandler = (menuId) => {
    if (toggle.includes(menuId)) {
      // Close the clicked submenu if it's already open
      setToggle((prevState) => prevState.filter((item) => item !== menuId));
    } else {
      // Open the clicked submenu
      setToggle((prevState) => [...prevState, menuId]);
    }
  };
  const getTitleMenu = (nameTitle) => {
    setClickedMenu(nameTitle);
    // console.log(nameTitle)
  };

  const handleMenuClose = () => {
    setSubmenuOpen(false);
  };

  // console.log('clickedMenu --- ',clickedMenu)

  const handleMouseEnter = (menuId) => {
    if (!toggle.includes(menuId)) {
      clearTimeout(submenuOpen);
      // Set a delay before opening the submenu
      const timeoutId = setTimeout(() => {
        setToggle((prevState) => [...prevState, menuId]);
      }, 500);
      setSubmenuOpen(timeoutId);
    }
  };

  const handleMouseLeave = () => {
    clearTimeout(submenuOpen);
    setSubmenuOpen(null);
  };

  return (
    <div className="flex min-h-screen text-md">
      {/* <div className={`flex relative  min-h-screen`}> */}
      <div
        className={`
              min-h-screen
              bg-[var(--color-light-backgroundMain)]
              dark:bg-[var(--color-dark-backgroundMain)]
              dark:text-[var(--color-dark-font)] 
              text-[var(--color-light-font)]
              p-5 pt-8 ${open ? "w-72" : "w-20"} 
              relative duration-300`}
        size={26}
      >
        <BsFillArrowLeftCircleFill
          className={`text-2xl absolute 
                           top-9 
                           cursor-pointer ${
                             !open ? "rotate-180 -right-1" : "-right-0"
                           }`}
          onClick={() => reduceMenu("clicked")}
        />
        {/* the icon is here */}
        <div className="inline-flex">
          <img
            src="/assets/images/iuceaLogo.png"
            className={`h-8
                   bg-white dark:bg-white
                   text-4xl rounded-sm 
                       cursor-pointer 
                       block float-left mr-2 duration-500
                    ${open && "rotate-[360deg]"}  
                    `}
          />
          {/* the text content is here */}
          <h1 className={`text-2xl duration-300 ${!open && "scale-0"}`}>
            R.P.A
          </h1>
        </div>

        {/* menu items here */}
        <ul className="pt-2">
          {Menus.map((menu, index) => (
            // <Link className='nav-link p-4' to={'/alert'}>  <button className='dark:bg-[var(--color-dark-buttonBackground)]  bg-[var(--color-light-buttonBackground)] dark:text-[var(--color-dark-fontButton)] text-[var(--color-light-fontButton)]  w-[100px]  py-3 rounded-md font-medium'> Apply now</button></Link>
            <>
              <li
                key={index}
                onMouseEnter={() => handleMouseEnter(menu.title)}
                onMouseLeave={handleMouseLeave}
              >
                {menu.link ? (
                  <Link
                    key={index}
                    to={menu.link ? menu.link : "!"}
                    onClick={() => {
                      if (menu.submenu && open) {
                        clickHandler(menu.title);
                        getTitleMenu(menu.nametitle);
                      } else {
                        getTitleMenu(menu.nametitle);
                      }
                    }}
                    className={`dark:text-[var(--color-dark-font)] 
                                text-[var(--color-light-font)]
                                text-md flex 
                                items-center 
                                gap-x-4 
                                cursor-pointer
                                p-2
                                hover:bg-[var(--color-light-backgroundSecond)]
                                hover:dark:bg-[var(--color-dark-backgroundSecond)]
                                rounded
                                ${menu.spacing ? "mt-6" : "mt-2"} 
                                `}
                  >
                    <span>{menu.icon}</span>
                    <span
                      style={{
                        transitionDelay: `${index + 5}00ms`,
                      }}
                      className={`whitespace-pre duration-500 ${
                        !open &&
                        "opacity-0 translate-x-28 overflow-hidden font-bold "
                      }  `}
                    >
                      {menu.title}
                    </span>
                    {/* work on submenus */}
                    {menu.submenu && open && (
                      <FaChevronCircleDown
                        className={`absolute right-5 duration-500 ${
                          toggle.includes(menu.title) ? "rotate-180" : ""
                        }`}
                      />
                    )}
                  </Link>
                ) : (
                  <span
                    key={index}
                    onClick={() => {
                      if (menu.submenu && open) {
                        clickHandler(menu.title);
                        getTitleMenu(menu.nametitle);
                      } else {
                        getTitleMenu(menu.nametitle);
                      }
                    }}
                    className={`dark:text-[var(--color-dark-font)] 
                                text-[var(--color-light-font)]
                                text-md flex 
                                items-center 
                                gap-x-4 
                                cursor-pointer
                                p-2
                                hover:bg-[var(--color-light-backgroundSecond)]
                                hover:dark:bg-[var(--color-dark-backgroundSecond)]
                                rounded
                                ${menu.spacing ? "mt-6" : "mt-2"} 
                                `}
                  >
                    <span>{menu.icon}</span>
                    <span
                      style={{
                        transitionDelay: `${index + 5}00ms`,
                      }}
                      className={`whitespace-pre duration-500 ${
                        !open &&
                        "opacity-0 translate-x-28 overflow-hidden font-bold "
                      }  `}
                    >
                      {menu.title}
                    </span>
                    {/* work on submenus */}
                    {menu.submenu && open && (
                      <FaChevronCircleDown
                        className={`absolute right-5 duration-500 ${
                          toggle.includes(menu.title) ? "rotate-180" : ""
                        }`}
                      />
                    )}
                  </span>
                )}
              </li>
              {/* work on submenus */}
              {menu.submenu && open && toggle.includes(menu.title) && (
                <SubMenu
                  dropDownItem={menu.submenuItems}
                  setClickedMenu={setClickedMenu}
                />
              )}
            </>
          ))}
        </ul>
      </div>

      <div className="flex flex-col flex-1">
        <div className="font-bold dark:bg-[var(--color-dark-backgroundSecond)] bg-[var(--color-light-backgroundSecond)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)]">
          <NavbarLoggedIn clickedMenu={clickedMenu} />
        </div>
        <div className="flex-1 overflow-auto">
          {/* table content */}
          <div className="p-3">{children}</div>
        </div>
        <div className="font-bold dark:bg-[var(--color-dark-backgroundSecond)] bg-[var(--color-light-backgroundSecond)] dark:text-[var(--color-dark-font)] text-[var(--color-light-font)]">
          <FooterLoggedIn />
        </div>
      </div>
      {!isAuthenticated ? <Redirect to="/" /> : <Fragment></Fragment>}
    </div>
  );
};

const SubMenu = ({ dropDownItem, setClickedMenu }) => {
  const [toggle, setToggle] = useState([]);
  const [submenuOpen, setSubmenuOpen] = useState([]);

  const handleMouseEnter = (menuId) => {
    if (!toggle.includes(menuId)) {
      clearTimeout(submenuOpen);
      // Set a delay before opening the submenu
      const timeoutId = setTimeout(() => {
        setToggle((prevState) => [...prevState, menuId]);
      }, 500);
      setSubmenuOpen(timeoutId);
    }
  };

  const handleMouseLeave = () => {
    clearTimeout(submenuOpen);
    setSubmenuOpen(null);
  };

  const clickHandler = (menuId) => {
    if (toggle.includes(menuId)) {
      // Close the clicked submenu if it's already open
      setToggle((prevState) => prevState.filter((item) => item !== menuId));
    } else {
      // Open the clicked submenu
      setToggle((prevState) => [...prevState, menuId]);
    }
  };

  const getTitleMenu = (nameTitle) => {
    setClickedMenu(nameTitle);
    // console.log(nameTitle)
  };
  return (
    <div className="drop-down text-md">
      <ul>
        {dropDownItem.map((submenuItem, index) => (
          <li
            onMouseEnter={() => handleMouseEnter(submenuItem.title)}
            key={`submenu${index}`}
          >
            <Link
              to={submenuItem.link ? submenuItem.link : "#"}
              onClick={() => {
                // Toggle the submenu on click
                clickHandler(submenuItem.title);
                getTitleMenu(submenuItem.nametitle);
              }}
              className={`dark:text-[var(--color-dark-font)] 
                text-[var(--color-light-font)]
                text-sm flex 
                items-center 
                gap-x-4 
                cursor-pointer
                p-2
                hover:bg-[var(--color-light-backgroundSecond)]
                hover:dark:bg-[var(--color-dark-backgroundSecond)]
                rounded
                px-5 
                duration-1000
              `}
            >
              <span className="text-1xl block float-left">
                {submenuItem.icon && submenuItem.icon}
              </span>
              {submenuItem.title}
              {/* Render sub-submenu items recursively */}
              {submenuItem.submenuItems && (
                <FaChevronCircleDown
                  className={`absolute right-5 duration-500 ${
                    toggle.includes(submenuItem.title) ? "rotate-180" : ""
                  }`}
                />
              )}
            </Link>
            {/* Recursively render sub-submenu */}
            {submenuItem.submenuItems && toggle.includes(submenuItem.title) && (
              <span className="right-5">
                {" "}
                <SubMenu
                  dropDownItem={submenuItem.submenuItems}
                  setClickedMenu={setClickedMenu}
                />
              </span>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

const useWindowWide = (size) => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setWidth]);

  return width;
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  userConnected: state.auth.user ?? "",
});

export default connect(mapStateToProps, {
  checkAuthenticated,
  load_user,
  logout,
})(Layout);
